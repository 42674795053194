import React, { useEffect, useState } from "react";
import DatePicker from "../../../datePicker/DatePicker";
import DropdownSelect from "../../../dropdown/Dropdown";
import TextInput from "../../../UIComponent/TextInput";
import moment from "moment";
import Validation from "../../../../utils/validation/ValidationText";
import ButtonLoader from "../../../common/buttonLoader/ButtonLoader";
import { getAuthProps } from "../../../../utils/AuthenticationLibrary";


function SIISCorporateAccountInformation(props) {
  return (
    <div>
      <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
        <div className="grid grid-cols-12 gap-6 w-full ">
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Account Status
                  <span className="text-[#c00000]">*</span>
                </h2>
                <DropdownSelect
                  disabled={true}
                  drpIdentity={"AccountStatus"}
                  optionArray={props.status}
                  setFilterParameters={props.setFilterParameters}
                  value={props.siisAccountInformation.accountStatus}
                // onBlur={(e) => props.validate("isAccountActive", props.corporateSIISAccount)}
                />
                {/* <Validation error={props.validState.error.isAccountActive} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Reasons for Leaving
                </h2>

                <DropdownSelect
                  drpIdentity={"corporateReasonLeaving"}
                  optionArray={props.reason}
                  setFilterParameters={props.setFilterParameters}
                  value={props.siisAccountInformation.corporateReasonLeavingId ? props.siisAccountInformation.corporateReasonLeavingId : 0}
                // onBlur={(e) => props.validate("corporateReasonLeavingId", props.corporateSIISAccount)}
                />
                {/* <Validation error={props.validState.error.corporateReasonLeavingId} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Joined Date
                </h2>
                <div className="">
                  <DatePicker
                    name="corporatejoinedDate"
                    handleChange={props.handleChange}
                    identity="SIISInformation"
                    value={props.siisAccountInformation.corporatejoinedDate ? moment(props.siisAccountInformation.corporatejoinedDate).format("yyyy-MM-DD") : ''}
                    isCurserAllowed={true}
                    isDisable={true}
                  // onBlur={(e) => props.validate("corporateJoinedDate", props.corporateSIISAccount)}
                  />
                </div>
                {/* <Validation error={props.validState.error.corporateJoinedDate} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Updated Date
                </h2>
                <div className="disabled-input">
                  {props.siisAccountInformation.updatedAt === "" || props.siisAccountInformation.updatedAt === null ?

                    <TextInput
                      disabled={true}
                      placeholder=""
                      type="text"
                      name="linkedIn"
                      value="Unavailable"
                      identity="BasicInformation"
                    />
                    :
                    <DatePicker
                      isDisable={true}
                      name="updatedAt"
                      handleChange={props.handleChange}
                      identity="updatedAt"
                      value={props.siisAccountInformation.updatedAt ? moment(props.siisAccountInformation.updatedAt).format("yyyy-MM-DD") : ''}
                    />

                  }
                </div>
                {/* <div className="disabled-input">
                  <DatePicker
                    name="updatedAt"
                    isDisable={true}
                    handleChange={props.handleChange}
                    identity="SIISInformation"
                    value={moment(props.siisAccountInformation.updatedAt).format("yyyy-MM-DD")}
                  />
                </div> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                  Updated by
                </h2>
                <DropdownSelect
                  disabled={false}
                  drpIdentity={"updatedById"}
                  optionArray={props._by}
                  setFilterParameters={props.setFilterParameters}
                  value={props.siisAccountInformation.createdBy ? props.siisAccountInformation.createdBy : 1}
                // onBlur={(e) => props.validate("updatedById", props.corporateSIISAccount)}
                />
                {/* <Validation error={props.validState.error.updatedById} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  SLA Corporate ID
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="disabled-input dropdwn-input-email flex items-center">
                  <TextInput
                    placeholder=""
                    type="text"
                    name="Updated"
                    value={props.siisAccountInformation.siisAccountId ? props.siisAccountInformation.siisAccountId : ""}
                    disabled={true}
                  // identity="BasicInformation"
                  // handleChange={props.handleChange}
                  // onBlur={(e) =>
                  //   props.validate(
                  //     "areaofExpertise",
                  //     props.corporateBasicInformation
                  //   )
                  // }

                  // value={this.state.searchbyParent}
                  // handleChange={(event) => {
                  //   this.setState({ searchByName: event.target.value });
                  // }}
                  />

                  {/* <Validation error={props.validState.error.areaofExpertise} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Engagement Score
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="disabled-input dropdwn-input-email flex items-center">
                  <TextInput
                    placeholder=""
                    type="text"
                    name="LastUpdated"
                    disabled={true}
                  // value={
                  //   props.corporateBasicInformation.areaofExpertise
                  // }
                  // identity="BasicInformation"
                  // handleChange={props.handleChange}
                  // onBlur={(e) =>
                  //   props.validate(
                  //     "areaofExpertise",
                  //     props.corporateBasicInformation
                  //   )
                  // }

                  // value={this.state.searchbyParent}
                  // handleChange={(event) => {
                  //   this.setState({ searchByName: event.target.value });
                  // }}
                  />

                  {/* <Validation error={props.validState.error.areaofExpertise} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Renewal Notes
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="dropdwn-input-email flex items-center">
                  <TextInput
                    placeholder=""
                    type="text"
                    name="renewalNotes"
                    value={props.siisAccountInformation.renewalNotes ? props.siisAccountInformation.renewalNotes : ""}
                    identity="SIISInformation"
                    handleChange={props.handleChange}
                  // onBlur={(e) =>
                  //   props.validate(
                  //     "areaofExpertise",
                  //     props.corporateBasicInformation
                  //   )
                  // }

                  // value={this.state.searchbyParent}
                  // handleChange={(event) => {
                  //   this.setState({ searchByName: event.target.value });
                  // }}
                  />

                  {/* <Validation error={props.validState.error.areaofExpertise} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Internal Flag
                </h2>

                <DropdownSelect
                  drpIdentity={"corporateInternalFlags"}
                  optionArray={props.corporateInternalFlags}
                  setFilterParameters={props.setFilterParameters}
                  value={props.siisAccountInformation.internalFlagId ? props.siisAccountInformation.internalFlagId : 0}
                />
                {/* <Validation error={props.validState.error.corporateProspectSourceId}/> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Domain Information
                  <span className="text-[#c00000]">*</span>
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="dropdwn-input-email flex items-center">
                  <TextInput
                    placeholder=""
                    type="text"
                    name="companyDomain"
                    disabled={false}
                    value={props.siisAccountInformation.companyDomain ? props.siisAccountInformation.companyDomain : ""}
                    identity="SIISInformation"
                    handleChange={props.handleChange}
                    isCurserAllowed={false}
                  // onBlur={(e) => props.validate("companyDomain", props.siisAccountInformation)}
                  // onBlur={(e) =>
                  //   props.validate(
                  //     "areaofExpertise",
                  //     props.corporateBasicInformation
                  //   )
                  // }

                  // value={this.state.searchbyParent}
                  // handleChange={(event) => {
                  //   this.setState({ searchByName: event.target.value });
                  // }}
                  />

                  {/* <Validation error={props.validState.error.areaofExpertise} /> */}
                </div>
                <div>
                  {/* <Validation error={props.validState.error.companyDomain} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-12 md:col-span-12 col-span-12 my-3 mt-5 ">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-12 md:col-span-12 col-span-12">
                <div className="flex items-center justify-between">
                  <div className="check-box-sec flex items-center">
                    {/* <input
                        className="form-checkbox"
                        type="checkbox"
                        name="isFeatured"
                        id="featured" 
                        onChange={(event) => props.handleChange(event,'SIISInformation')}
                        checked={props.siisAccountInformation.featured}
                        value={props.siisAccountInformation.featured}
                      /> */}
                    <input
                      id="isFeatured"
                      name="isFeatured"
                      type="checkbox"
                      className="form-checkbox"
                      checked={props.siisAccountInformation.isFeatured ? props.siisAccountInformation.isFeatured : false}
                      value={props.siisAccountInformation.isFeatured ? props.siisAccountInformation.isFeatured : false}
                      onChange={(event) => props.handleChangeCheckBoxSIIS(event)}
                    //onChange={(e) => props.handleChange(e)}
                    />
                    <span className="filter-lable font-bold text-[#757575] pl-4">
                      Featured
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >

      <div className="grid grid-cols-12 gap-6 pb-10">
        <div className="xl:col-span-12 lg:col-span-12 gap-4 col-span-12 px-10 flex justify-between items-center">
          <div className="flex items-center space-x-4">
            {props.siisAccountInformation.accountStatus === 13 || props.siisAccountInformation.accountStatus === 14 ? (
              <>
                {props.isReinstatementLoader ? (
                  <ButtonLoader className="buttonload group relative py-2 text-xl font-bold text-white btn bg-[#6cbf6c] w-[200px] h-[50px]" />
                ) : (
                  <div className="flex flex-col">
                    <button
                      className={`py-2 text-xl font-bold text-white btn w-[200px] h-[50px] ${props.siisAccountInformation.isAppliedForReinstatement ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#6cbf6c]'
                        }`}
                      disabled={props.siisAccountInformation.isAppliedForReinstatement}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.addReinstatementMembershipDetails(props.siisAccountInformation.memberId);
                      }}
                    >
                      Reinstatement
                    </button>
                    {props.siisAccountInformation.isAppliedForReinstatement ? (
                      <p className="font-semibold mt-1 text-red-600">Application is undergoing Reinstatement</p>
                    ) : null}
                  </div>
                )}
              </>
            ) : null}
          </div>


          <div className="flex space-x-4">
            <button
              className="w-132 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold h-[50px]"
              onClick={(e) => {
                e.stopPropagation();
                props.handleClick();
              }}
            >
              Login to Super User
            </button>
            {props.isCorporateAccountLoading ? (
              <ButtonLoader className="buttonload group relative rounded-md btn btn-red text-xl text-white focus:outline-none h-[50px]" />
            ) : (
              <button
                className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold h-[50px]"
                onClick={() => props.onSave()}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </div>



    </div >
  );
}

export default SIISCorporateAccountInformation;
