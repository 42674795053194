import React, { useEffect, useState } from "react";
import ToggleButton from "../../common/ToggleButton";
import SwalServices from "../../../services/swalServices/SwalServices";
import EntryManagementServices from "../../../services/axiosServices/apiServices/EntryManagementServices";
import CommonServices from "../../../services/axiosServices/apiServices/CommonServices";
import ValidationText from "../../../utils/validation/ValidationText";
import ImageCropperWithUpload from "../../ImageUpload/UploadImage";
import { CloseButtonIcon } from "../../SVGConstants";
import ButtonLoader from "../../common/buttonLoader/ButtonLoader";

const entryServices = new EntryManagementServices();
const swalServices = new SwalServices();
const commonServices = new CommonServices();

const otherDetailsObj = {
  eventId: 0,
  isDietaryRequirement: "false",
  isQuestion: "false",
  notificationEmail: "",
  questions: [],
};

const questionObj = {
  eventQuestionId: 0,
  eventId: 0,
  isMandatoryQuestion: false,
  eventQuestion: "",
  eventQuestionTypeId: 0,
  isFeedbackQuestion: false,
  answers: [],
};

const answerObj = {
  eventQuestionAnswerId: 0,
  eventQuestionId: 0,
  eventQuestionAnswer: "",
  isOther: false,
};
const partnersObj = {
  eventSectionPartnerId: 0,
  eventSectionId: 0,
  partnerName: "",
  websiteURL: "",
  partnerLogo: "",
};
const validStateObj = {
  isValid: true,
  error: [],
};
const questionArray = [
  { label: "Textbox", questionTypeId: 1 },
  { label: "Radio Buttons", questionTypeId: 2 },
  { label: "Checkboxes", questionTypeId: 3 },
  { label: "Drop-down", questionTypeId: 4 },
  { label: "Multi-Select Drop-down", questionTypeId: 5 },
];

const Others = (props) => {
  const [questionArr, setQuestionArr] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);
  const [otherDetails, setOtherDetailsObj] = useState(otherDetailsObj);
  const [addNewQuestionFeedback, setAddNewQuestionFeedback] = useState(false);
  const [addNewQuestion, setAddNewQuestion] = useState(false);
  const [entryDietary, setEntryDietary] = useState([]);
  const [validStateQuestion, setValidStateQuestion] = useState(validStateObj);
  const [validStatePartnership, setValidStatePartnership] =
    useState(validStateObj);
  const [isLoading, setLoading] = useState(false);
  const addSection = () => {
    let sections = [...sectionArr];
    const obj = {
      eventSectionId: 0,
      eventId: props.eventId,
      sectionName: "",
      eventSectionPartners: [],
    };
    sections.push(obj);
    setSectionArr(sections);
  };

  const addPartners = (partnerArr, sectionIndex) => {
    let sectionsArr = [...sectionArr];
    let partners = [...partnerArr];

    partners.push(partnersObj);

    sectionsArr[sectionIndex].eventSectionPartners = partners;
    setSectionArr(sectionsArr);
  };

  const addQuestion = (isFeedbackQuestion, questionType) => {
    let question = [...questionArr];
    let isFeedback = isFeedbackQuestion;
    const obj = {
      eventQuestionId: 0,
      eventId: 0,
      isMandatoryQuestion: false,
      eventQuestion: "",
      eventQuestionTypeId: 1,
      isFeedbackQuestion: isFeedback,
      answers: [],
      isOther: false,
    };
    obj.answers.push(answerObj);

    if (questionType === 1) {
      const obj = {
        eventQuestionId: 0,
        eventId: 0,
        isMandatoryQuestion: false,
        eventQuestion: "",
        eventQuestionTypeId: 1,
        isFeedbackQuestion: isFeedback,
        answers: [],
        isOther: false,
      };
      obj.answers.push(answerObj);

      question.push(obj);
    } else if (questionType === 2) {
      const obj = {
        eventQuestionId: 0,
        eventId: 0,
        isMandatoryQuestion: false,
        eventQuestion: "",
        eventQuestionTypeId: 2,
        isFeedbackQuestion: isFeedback,
        answers: [],
        isOther: false,
      };

      obj.answers.push(answerObj);
      obj.answers.push(answerObj);
      question.push(obj);
    } else if (questionType === 3) {
      const obj = {
        eventQuestionId: 0,
        eventId: 0,
        isMandatoryQuestion: false,
        eventQuestion: "",
        eventQuestionTypeId: 3,
        isFeedbackQuestion: isFeedback,
        answers: [],
        isOther: false,
      };
      obj.answers.push(answerObj);
      obj.answers.push(answerObj);
      question.push(obj);
    } else if (questionType === 4) {
      const obj = {
        eventQuestionId: 0,
        eventId: 0,
        isMandatoryQuestion: false,
        eventQuestion: "",
        eventQuestionTypeId: 4,
        isFeedbackQuestion: isFeedback,
        answers: [],
        isOther: false,
      };
      obj.answers.push(answerObj);
      obj.answers.push(answerObj);
      question.push(obj);
    } else if (questionType === 5) {
      const obj = {
        eventQuestionId: 0,
        eventId: 0,
        isMandatoryQuestion: false,
        eventQuestion: "",
        eventQuestionTypeId: 5,
        isFeedbackQuestion: isFeedback,
        answers: [],
        isOther: false,
      };
      obj.answers.push(answerObj);
      obj.answers.push(answerObj);
      question.push(obj);
    }
    setAddNewQuestionFeedback(false);
    setAddNewQuestion(false);
    setQuestionArr(question);
  };

  const addAnswer = (ansArr, index, identity) => {
    let question = [...questionArr];
    let answer = [...ansArr];

    if (identity === "CheckBox") {
      if (question[index].isOther === true) {
        swalServices.Error(
          "You cant't insert new answer until other is enabled."
        );
      } else {
        answer.push(answerObj);
      }
    } else {
      answer.push(answerObj);
    }

    question[index].answers = answer;
    setQuestionArr(question);
  };

  const deletePartner = (partnerArr, secIndex, partIndex) => {
    let partnerArray = partnerArr;
    partnerArray.splice(partIndex, 1);
    let secArr = [...sectionArr];
    secArr[secIndex].eventSectionPartners = partnerArr;
    setSectionArr(secArr);
  };

  const deleteSection = (secIndex) => {
    let secArr = [...sectionArr];
    secArr.splice(secIndex, 1);
    setSectionArr(secArr);
  };

  const handleRadioButtons = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    const name = e.target.name;
    let detail = { ...otherDetails };

    detail[id] = value;
    detail[name] = value;
    setOtherDetailsObj(detail);
  };
  const handleChange = (e, type, parentIndex, childIndex) => {
    const value = e.target.value;
    const name = e.target.name;
    if (type === "Question") {
      let detail = [...questionArr];
      detail[parentIndex][name] = value;
      setQuestionArr(detail);
    } else if (type === "OtherDetails") {
      let detail = { ...otherDetails };
      detail[name] = value;
      setOtherDetailsObj(detail);
    } else if (type === "EventAnswer") {
      let queArr = [...questionArr];
      let ansArr = [...queArr[parentIndex].answers];

      ansArr[childIndex] = {
        ...ansArr[childIndex],
        eventQuestionAnswer: value,
      };

      queArr[parentIndex].answers = ansArr;
      setQuestionArr([...queArr]);
    } else if (type === "Section") {
      let sections = [...sectionArr];
      sections[parentIndex][name] = value;
      setSectionArr(sections);
    } else if (type === "Partner") {
      let sections = [...sectionArr];
      let partners = [...sections[parentIndex].eventSectionPartners];
      partners[childIndex] = { ...partners[childIndex], [name]: value };
      sections[parentIndex].eventSectionPartners = partners;
      setSectionArr([...sections]);
    }
  };
  const handleChangeToggle = (value, e, questionIndex, identity) => {
    let detail = [...questionArr];
    const ansObj = {
      eventQuestionAnswerId: 0,
      eventQuestionId: 0,
      eventQuestionAnswer: "",
      isOther: true,
    };

    if (identity === "Others") {
      var fields = questionIndex.split("/");
      let index = fields[1];
      detail[index].isOther = e.target.checked;
      if (e.target.checked === true) {
        detail[index].answers.push(ansObj);
      } else {
        let answer = detail[index].answers;
        let removedAns = answer.slice(0, -1);
        detail[index].answers = removedAns;
      }
    } else {
      detail[questionIndex].isMandatoryQuestion = e.target.checked;
    }
    setQuestionArr(detail);
  };
  const addEditOtherEntryDetails = () => {
    let isValidQues = validateQuestions();
    let isValidPartner = validatePartnership();
    if (isValidQues && isValidPartner) {
      let request = { ...otherDetails };
      request.eventId = props.eventId;
      request.isDietaryRequirement =
        request.isDietaryRequirement === "true" ? true : false;
      request.isQuestion = request.isQuestion === "true" ? true : false;
      request.questions = [...questionArr];
      setLoading(true);
      //this.setState({ isLoading: true });
      entryServices.addEditOtherEntryDetails(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {


          let partnerRequest = [...sectionArr];
          let data = {};
          data.eventId = props.eventId;
          data.eventSectionDetails = [...partnerRequest];
          entryServices
            .addEditEventSectionDetails(data)
            .then((response) => {
              if (
                response.statusCode === 200 &&
                response.responseItem != null
              ) {
                if (response.responseItem.responseContent.errorType === 'Exists') {
                  swalServices.Error(response.responseItem.responseContent.errorMessage);
                } else {
                  swalServices.Success("Entry has been submitted successfully");
                  props.navigateTo("/ViewEditEntries");
                }
                //swalServices.Success("Entry has been submitted successfully");
                //props.navigateTo("/ViewEditEntries");
                // this.setState({ route: '/ViewEditEntries' });
              } else {
                swalServices.Error(response.message);
              }
            });
        } else {
          swalServices.Error(response.message);
        }
      });
    }
  };
  const getAllDropDownForEntryManagement = (eventId) => {
    entryServices.getAllDropDownForEntryManagement(eventId).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        var entryDietary = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "EntryDietary"
        );
        setEntryDietary(entryDietary);
      } else {
        swalServices.Error(response.message);
      }
    });
  };
  const getOtherEntryDetailsByEventId = (eventId) => {
    entryServices.getOtherEntryDetailsByEventId(eventId).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let details = { ...response.responseItem.responseContent };
        details.isDietaryRequirement =
          response.responseItem.responseContent.isDietaryRequirement === true
            ? "true"
            : "false";
        details.isQuestion =
          response.responseItem.responseContent.isQuestion === true
            ? "true"
            : "false";
        setOtherDetailsObj(details);
        setQuestionArr(response.responseItem.responseContent.questions);

        entryServices.getEventSectionDetails(eventId).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent.length > 0) {
              setSectionArr(response.responseItem.responseContent);
            }
          } else {
            swalServices.Error(response.message);
          }
        });
      } else {
        swalServices.Error(response.message);
      }
    });
  };
  const saveMainImage = (
    file,
    keyId,
    imageIdentity,
    parentIndex,
    childIndex
  ) => {
    if (imageIdentity === "\\Events\\EventPartners") {
      file.storagePath = "Events\\EventPartners";
      commonServices.storeBase64Image(file).then((response) => {
        if (response != null) {
          swalServices.Success("File Uploaded");
          if (file.storagePath === "Events\\EventPartners") {
            let sections = [...sectionArr];
            let partners = [...sections[parentIndex].eventSectionPartners];
            partners[childIndex] = {
              ...partners[childIndex],
              partnerLogo: response.fileName,
            };
            sections[parentIndex].eventSectionPartners = partners;
            setSectionArr([...sections]);
          }
        } else {
          swalServices.Error(response.message);
        }
      });
    }
  };
  const validateQuestions = () => {
    var message = "";
    var field = "";
    var valState = { ...validStateQuestion };
    valState.isValid = true;
    var valError = [];
    questionArr.forEach((que, queIndex) => {
      if (que.eventQuestion === "") {
        message = "Please enter question " + (queIndex + 1);
        field = "question[" + (queIndex + 1) + "]";
        valError[field] = message;
        valState.isValid = false;
      }
      if (que.answers.length > 0) {
        que.answers.forEach((ans, ansIndex) => {
          if (que.eventQuestionTypeId !== 1) {
            if (ans.eventQuestionAnswer === "") {
              message =
                "Please enter answer " +
                (ansIndex + 1) +
                " of question " +
                (queIndex + 1);
              field =
                "eventQuestionAnswer[" +
                (queIndex + 1) +
                "][" +
                (ansIndex + 1) +
                "]";
              valError[field] = message;
              valState.isValid = false;
            }
          }
        });
      }
    });
    valState.error = Object.values(valError);
    setValidStateQuestion(valState);
    return valState.isValid;
  };
  const validatePartnership = () => {
    var message = "";
    var field = "";
    var valState = { ...validStatePartnership };
    valState.isValid = true;
    var valError = [];
    sectionArr.forEach((sec, secIndex) => {
      if (sec.sectionName === "") {
        message = "Please enter section name " + (secIndex + 1);
        field = "sectionName[" + (secIndex + 1) + "]";
        valError[field] = message;
        valState.isValid = false;
      }
      if (sec.eventSectionPartners.length > 0) {
        sec.eventSectionPartners.forEach((partner, partIndex) => {
          if (partner.partnerName === "") {
            message =
              "Please enter partner name " +
              (partIndex + 1) +
              " of section " +
              (secIndex + 1);
            field =
              "partnerName[" + (secIndex + 1) + "][" + (partIndex + 1) + "]";
            valError[field] = message;
            valState.isValid = false;
          }
          if (partner.websiteURL === "") {
            message =
              "Please enter websiteURL " +
              (partIndex + 1) +
              " of section " +
              (secIndex + 1);
            field =
              "websiteURL[" + (secIndex + 1) + "][" + (partIndex + 1) + "]";
            valError[field] = message;
            valState.isValid = false;
          }
          if (partner.partnerLogo === "") {
            message =
              "Please select partner logo " +
              (partIndex + 1) +
              " of section " +
              (secIndex + 1);
            field =
              "partnerLogo[" + (secIndex + 1) + "][" + (partIndex + 1) + "]";
            valError[field] = message;
            valState.isValid = false;
          }
        });
      }
    });
    valState.error = Object.values(valError);
    setValidStatePartnership(valState);
    return valState.isValid;
  };

  useEffect(() => {
    getAllDropDownForEntryManagement(props.eventId);
    if (props.eventId && props.eventId > 0) {
      getOtherEntryDetailsByEventId(props.eventId);
    }
  }, []);

  const deleteEventQuestionById = (index, eventQuestionId, section) => {
    if (Number(eventQuestionId) > 0) {
      entryServices.deleteEventQuestionById(eventQuestionId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let questionList = [...questionArr];
          questionList.splice(index, 1);
          setQuestionArr(questionList);
          swalServices.Success('Question deleted successfully');
        } else {
          swalServices.Error(response.message);
        }
      });
    }
    else {
      let questionList = [...questionArr];
      questionList.splice(index, 1);
      setQuestionArr(questionList);
    }
  }

  const deleteAnswer = (ansArr, index, identity) => {
    if (ansArr.length > 2) {
      let answer = [...ansArr];
      let question = [...questionArr];
      let removedAns = [];
      const answerId = Number(ansArr[(ansArr.length - 1)].eventQuestionAnswerId);
      if (answerId > 0) {
        entryServices.deleteEventAnswerById(answerId).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (identity === "CheckBox") {
              if (question[index].isOther === true) {
                swalServices.Error("Please disable other toggle to add new options.");
                removedAns = answer;
              } else {
                removedAns = answer.slice(0, -1);
              }
            } else {
              removedAns = answer.slice(0, -1);
            }
            question[index].answers = removedAns;
            setQuestionArr(question);
            swalServices.Success("Option has been deleted successfully.");
          } else {
            swalServices.Error(response.message);
          }
        });
      }
      else {
        if (identity === "CheckBox") {
          if (question[index].isOther === true) {
            swalServices.Error(
              "Please disable other toggle to add new options."
            );
            removedAns = answer;
          } else {
            removedAns = answer.slice(0, -1);
          }
        } else {
          removedAns = answer.slice(0, -1);
        }

        question[index].answers = removedAns;
        setQuestionArr(question);
      }
    } else {
      swalServices.Error("You can't remove options.");
    }
  };

  // const deleteAnswer = (ansArr, index, identity) => {
  //   if (ansArr.length > 2) {
  //     let answer = [...ansArr];
  //     let question = [...questionArr];
  //     let removedAns = [];
  //     if (identity === "CheckBox") {
  //       if (question[index].isOther === true) {
  //         swalServices.Error(
  //           "You cant't insert new answer until other is enabled."
  //         );
  //         removedAns = answer;
  //       } else {
  //         removedAns = answer.slice(0, -1);
  //       }
  //     } else {
  //       removedAns = answer.slice(0, -1);
  //     }

  //     question[index].answers = removedAns;
  //     setQuestionArr(question);
  //   } else {
  //     swalServices.Error("You can't remove options.");
  //   }
  // };

  return (
    <div>
      {validStateQuestion.error.length > 0 ||
        validStatePartnership.error.length > 0 ? (
        <div className="bg-white" id="ErrorList">
          <div className="heading-part pl-7 py-4 border-b mb-5">
            <div className="flex items-center justify-between">
              <h4 className="small-title font-bold theme-color">
                The following field(s) are required:
              </h4>
            </div>
            <ul>
              {validStateQuestion.error.map((x, key) => {
                return <li key={key} className=" text-[#c00000]">{x}</li>;
              })}
              {validStatePartnership.error.map((x, key) => {
                return <li key={key} className=" text-[#c00000]">{x}</li>;
              })}
            </ul>
          </div>
        </div>
      ) : null}

      {/* Dietary Requirement Start */}
      <div className="bg-white">
        <div className="heading-part px-8 py-4 border-b">
          <div className="flex items-center justify-between">
            <h4 className="small-title font-bold theme-color">
              Dietary Requirement
            </h4>
          </div>
        </div>
        <div className="px-9 py-7">
          <div className="grid grid-cols-12 gap-6">
            <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className=" col-span-9 w-full">
                      <div className="flex flex-col justify-start">
                        <div className="my-3">
                          <label className="flex items-center">
                            <input
                              type="radio"
                              name="isDietaryRequirement"
                              id="isDietaryRequirement"
                              className="form-radio"
                              value={false}
                              onChange={(event) => handleRadioButtons(event)}
                              checked={
                                otherDetails.isDietaryRequirement === "false"
                              }
                            />
                            <span className="text-xl  font-medium theme-color ml-2">
                              No dietary requirement
                            </span>
                          </label>
                        </div>

                        <div className="my-3">
                          <label className="flex items-start">
                            <input
                              type="radio"
                              name="isDietaryRequirement"
                              id="isDietaryRequirement"
                              className="form-radio"
                              value={true}
                              onChange={(event) => handleRadioButtons(event)}
                              checked={
                                otherDetails.isDietaryRequirement === "true"
                              }
                            />
                            <span className="text-xl  font-medium theme-color ml-2">
                              There is dietary requirement
                              <div className="list-items">
                                {otherDetails.isDietaryRequirement ===
                                  "true" ? (
                                  <ul className="list-disc pl-[20px] mt-5">
                                    {entryDietary.map((dietary, key) => {
                                      return <li key={key}>{dietary.name}</li>;
                                    })}
                                  </ul>
                                ) : null}
                              </div>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Dietary Requirement End */}
      {/* Additional Questions Start */}
      <div className="bg-white mt-12">
        <div className="heading-part px-8 py-4 border-b">
          <div className="flex items-center justify-between">
            <h4 className="small-title font-bold theme-color">
              Additional Questions
            </h4>
          </div>
        </div>
        <div className="px-9 py-7">
          <div className="grid grid-cols-12 gap-6">
            <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-9 w-full">
                      <div className="flex flex-col justify-start">
                        <div className="my-3">
                          <label className="flex items-center">
                            <input
                              type="radio"
                              name="isQuestion"
                              id="isQuestion"
                              className="form-radio"
                              value={false}
                              onChange={(event) => handleRadioButtons(event)}
                              checked={otherDetails.isQuestion === "false"}
                            />
                            <span className="text-xl  font-medium theme-color ml-2">
                              I do not require any additional information from
                              the attendee.
                            </span>
                          </label>
                        </div>

                        <div className="my-3">
                          <label className="flex items-start">
                            <input
                              type="radio"
                              name="isQuestion"
                              id="isQuestion"
                              className="form-radio"
                              value={true}
                              onChange={(event) => handleRadioButtons(event)}
                              checked={otherDetails.isQuestion === "true"}
                            />
                            <span className="text-xl  font-medium theme-color ml-2">
                              I would like to ask the attendee some additional
                              questions before they complete their booking.
                              Maximum 5 questions.
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {otherDetails.isQuestion === "true" ? (
                    <div className="grid grid-cols-12 gap-6 mt-7 pl-8">
                      <div className="col-span-8">
                        <div className="question-sec">
                          <div className="flex items-center justify-between">
                            <label
                              className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                              htmlFor="default"
                            >
                              Question
                              {/* <span className="text-[#C00000]">*</span> */}
                            </label>
                            <div className="custom-checkbox-set hidden">
                              <label className="flex items-center">
                                <input
                                  type="checkbox"
                                  name="radio-buttons"
                                  className="cursor-pointer h-4 w-4 text-[#C00000] focus:ring-[#c00000] bg-white rounded check-box-custom"
                                />
                                <span className="text-xl  font-medium theme-color ml-2">
                                  Required
                                </span>
                              </label>
                            </div>
                          </div>
                          {/* <textarea className="w-full h-[150px] text-area-space"></textarea> */}
                          <div className="question-main-sec">
                            {questionArr.map((que, key) => {
                              return (
                                <>
                                  {que.eventQuestionTypeId === 1 &&
                                    que.isFeedbackQuestion === false ? (
                                    <>
                                      {/* <div className="custom-checkbox-set">
                                          <label className="flex items-last">
                                            <input
                                              type="checkbox"
                                              name="radio-buttons"
                                              className="cursor-pointer h-4 w-4 text-[#C00000] focus:ring-[#c00000] bg-white rounded check-box-custom"
                                            />
                                            <span className="text-xl  font-medium theme-color ml-2">
                                              Required
                                            </span>
                                          </label>
                                        </div> */}

                                      <div className="question-types text-boxes mb-7 last:mb-0 relative" key={key}>
                                        <div className="close-sign absolute top-[-10px] right-[-10px]">
                                          <button className="h-7 w-7 flex items-center justify-center text-[#757575] hover:text-[#3a3a3a] rounded-full border-2 border-[#757575]"
                                            onClick={() => deleteEventQuestionById(key, que.eventQuestionId, 'AdditionalQuestions')}>
                                            <CloseButtonIcon />
                                          </button>
                                        </div>
                                        <div className="flex items-start w-full">
                                          <div className="question-sec border border-[#e5e7eb] w-full p-6 pt-4">
                                            <div className="flex items-center justify-between mb-3">
                                              <div>
                                                {
                                                  questionArray.find(
                                                    (x) =>
                                                      x.questionTypeId ===
                                                      que.eventQuestionTypeId
                                                  ).label
                                                }
                                              </div>
                                              <div
                                                className="required-btn text-[#757575] font-medium text-xl theme-color ml-2"
                                                key={key}
                                              >
                                                <ToggleButton
                                                  onHandleToggle={
                                                    handleChangeToggle
                                                  }
                                                  label="Required"
                                                  setToggle={
                                                    que.isMandatoryQuestion
                                                  }
                                                  index={key}
                                                />
                                              </div>
                                            </div>
                                            <div className="question-answer-sec text-[#757575] w-full">
                                              <div className="question-input">
                                                <input
                                                  className="w-full border border-[#e5e7eb] font-bold text-[24px]"
                                                  type="text"
                                                  name="eventQuestion"
                                                  value={que.eventQuestion}
                                                  onChange={(e) =>
                                                    handleChange(
                                                      e,
                                                      "Question",
                                                      key
                                                    )
                                                  }
                                                  placeholder={`Question ${key + 1
                                                    }`}
                                                />
                                              </div>
                                              {/* {que.answers.map((ans, index) => {
                                                return (
                                                  <>
                                                    <div
                                                      className="answer-input"
                                                      key={index}
                                                    >
                                                      <input
                                                        className="w-full mt-3 border border-[#e5e7eb] font-bold text-[24px]"
                                                        type="text"
                                                        name="eventQuestionAnswer"
                                                        value={
                                                          ans.eventQuestionAnswer
                                                        }
                                                        onChange={(e) =>
                                                          handleChange(
                                                            e,
                                                            "EventAnswer",
                                                            key,
                                                            index
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </>
                                                );
                                              })} */}
                                              {/* <div className="answer-input">
                                                  <input
                                                    className="w-full mt-3 border border-[#e5e7eb] font-bold text-[24px]"
                                                    type="text"
                                                    value=""
                                                  />
                                                </div> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                  {que.eventQuestionTypeId === 2 &&
                                    que.isFeedbackQuestion === false ? (
                                    <>
                                      {/* <div className="custom-checkbox-set">
                                          <label className="flex items-center">
                                            <input
                                              type="checkbox"
                                              name="radio-buttons"
                                              className="cursor-pointer h-4 w-4 text-[#C00000] focus:ring-[#c00000] bg-white rounded check-box-custom"
                                            />
                                            <span className="text-xl  font-medium theme-color ml-2">
                                              Required
                                            </span>
                                          </label>
                                        </div> */}

                                      <div className="question-types radio-buttons mb-7 last:mb-0 relative">
                                        <div className="close-sign absolute top-[-10px] right-[-10px]">
                                          <button className="h-7 w-7 flex items-center justify-center text-[#757575] hover:text-[#3a3a3a] rounded-full border-2 border-[#757575]"
                                            onClick={() => deleteEventQuestionById(key, que.eventQuestionId, 'AdditionalQuestions')}>
                                            <CloseButtonIcon />
                                          </button>
                                        </div>
                                        <div className="flex items-start w-full">
                                          <div className="question-sec border border-[#e2e2e2] w-full p-6 pt-4">
                                            <div className="flex items-center justify-between mb-3">
                                              <div>
                                                {
                                                  questionArray.find(
                                                    (x) =>
                                                      x.questionTypeId ===
                                                      que.eventQuestionTypeId
                                                  ).label
                                                }
                                              </div>
                                              <div
                                                className="required-btn  text-[#757575] font-medium text-xl theme-color ml-2"
                                                key={key}
                                              >
                                                <ToggleButton
                                                  onHandleToggle={
                                                    handleChangeToggle
                                                  }
                                                  label="Required"
                                                  setToggle={
                                                    que.isMandatoryQuestion
                                                  }
                                                  index={key}
                                                />
                                              </div>
                                            </div>
                                            <div className="question-answer-sec text-[#757575] w-full">
                                              <div className="question-input">
                                                <input
                                                  className="w-full border border-[#e2e2e2] font-bold text-[24px]"
                                                  type="text"
                                                  name="eventQuestion"
                                                  value={que.eventQuestion}
                                                  onChange={(e) =>
                                                    handleChange(
                                                      e,
                                                      "Question",
                                                      key
                                                    )
                                                  }
                                                  placeholder={`Question ${key + 1
                                                    }`}
                                                />
                                              </div>
                                              <div className="answer-input">
                                                <div className="radio-answer">
                                                  <div className="mt-5">
                                                    <div className="grid grid-cols-12 gap-6 items-end">
                                                      <div className="col-span-10">
                                                        <div className="grid grid-cols-12 gap-6">
                                                          {que.answers.map(
                                                            (ans, index) => {
                                                              return (
                                                                <>
                                                                  <div
                                                                    className="2xl:col-span-6 lg:col-span-6 col-span-12"
                                                                    key={index}
                                                                  >
                                                                    <label className="flex items-center w-full">
                                                                      <input
                                                                        type="radio"
                                                                        name="radio1"
                                                                        id="isPage"
                                                                        className="form-radio"
                                                                      //   value={props.resource.isPage}
                                                                      //   onChange={(event) =>
                                                                      //     props.handleRadioButtons(event)
                                                                      //   }
                                                                      //   checked={props.resource.isPage === true}
                                                                      />
                                                                      <div className="text-xl  font-medium theme-color ml-3 w-full">
                                                                        <input
                                                                          id={
                                                                            "Ans" +
                                                                            index
                                                                          }
                                                                          className="h-[40px] border border-[#e2e2e2] text-[#757575] w-full"
                                                                          type="text"
                                                                          name="eventQuestionAnswer"
                                                                          value={
                                                                            ans.eventQuestionAnswer
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) =>
                                                                            handleChange(
                                                                              e,
                                                                              "EventAnswer",
                                                                              key,
                                                                              index
                                                                            )
                                                                          }
                                                                          placeholder={`Radio Answer ${index +
                                                                            1
                                                                            }`}
                                                                        />
                                                                      </div>
                                                                    </label>
                                                                  </div>
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      </div>
                                                      <div className="col-span-2 mb-1 pr-2">
                                                        <div className="add-buttons flex items-center justify-end w-full ml-2">
                                                          <button
                                                            className="text-white btn-gray bg-[#b3b3b3] min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] rounded-[7px] text-center mr-2"
                                                            onClick={() =>
                                                              deleteAnswer(
                                                                que.answers,
                                                                key
                                                              )
                                                            }
                                                          >
                                                            <span className="text-[25px] leading-[28px]">
                                                              -
                                                            </span>
                                                          </button>
                                                          <button
                                                            className="text-white btn-lighblue bg-[#81a3b8] min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] rounded-[7px] text-center"
                                                            onClick={() =>
                                                              addAnswer(
                                                                que.answers,
                                                                key
                                                              )
                                                            }
                                                          >
                                                            <span className="text-[25px] leading-[28px]">
                                                              +
                                                            </span>
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="required-btn text-[#3b4356] hidden">
                                              <ToggleButton
                                                onHandleToggle={
                                                  props.filterByFeatured
                                                }
                                                label="Required"
                                                // setToggle={props.feedbackQuestions.required}
                                                setToggle={true}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                  {que.eventQuestionTypeId === 3 &&
                                    que.isFeedbackQuestion === false ? (
                                    <>
                                      {/* <div className="custom-checkbox-set">
                                          <label className="flex items-center">
                                            <input
                                              type="checkbox"
                                              name="radio-buttons"
                                              className="cursor-pointer h-4 w-4 text-[#C00000] focus:ring-[#c00000] bg-white rounded check-box-custom"
                                            />
                                            <span className="text-xl  font-medium theme-color ml-2">
                                              Required
                                            </span>
                                          </label>
                                        </div> */}

                                      <div className="question-types check-boxes mb-7 last:mb-0 relative">
                                        <div className="close-sign absolute top-[-10px] right-[-10px]">
                                          <button className="h-7 w-7 flex items-center justify-center text-[#757575] hover:text-[#3a3a3a] rounded-full border-2 border-[#757575]"
                                            onClick={() => deleteEventQuestionById(key, que.eventQuestionId, 'AdditionalQuestions')}>
                                            <CloseButtonIcon />
                                          </button>
                                        </div>
                                        <div className="flex items-start w-full">
                                          <div className="question-sec border border-[#e2e2e2] w-full p-6 pt-4">
                                            <div className="flex items-center justify-between mb-3">
                                              <div>
                                                {
                                                  questionArray.find(
                                                    (x) =>
                                                      x.questionTypeId ===
                                                      que.eventQuestionTypeId
                                                  ).label
                                                }
                                              </div>
                                              <div className="flex items-center justify-between">
                                                <div
                                                  className="required-btn text-[#757575] font-medium text-xl theme-color ml-2"
                                                  key={key}
                                                >
                                                  <ToggleButton
                                                    onHandleToggle={
                                                      handleChangeToggle
                                                    }
                                                    label="Required"
                                                    setToggle={
                                                      que.isMandatoryQuestion
                                                    }
                                                    index={key}
                                                  />
                                                </div>
                                                <div className="required-btn text-[#3b4356]">
                                                  <div>
                                                    <ToggleButton
                                                      onHandleToggle={
                                                        handleChangeToggle
                                                      }
                                                      label="Others"
                                                      setToggle={que.isOther}
                                                      index={`Others/${key}`}
                                                      identity="Others"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="question-answer-sec text-[#757575] w-full">
                                              <div className="question-input">
                                                <input
                                                  className="w-full border border-[#e2e2e2] font-bold text-[24px]"
                                                  type="text"
                                                  name="eventQuestion"
                                                  value={que.eventQuestion}
                                                  onChange={(e) =>
                                                    handleChange(
                                                      e,
                                                      "Question",
                                                      key
                                                    )
                                                  }
                                                  placeholder={`Question ${key + 1
                                                    }`}
                                                />
                                              </div>
                                              <div className="answer-input">
                                                <div className="radio-answer">
                                                  <div className="mt-5">
                                                    <div className="grid grid-cols-12 gap-6 items-end">
                                                      <div className="col-span-10">
                                                        <div className="grid grid-cols-12 gap-6">
                                                          {que.answers.map(
                                                            (ans, index) => {
                                                              return (
                                                                <>
                                                                  {ans.isOther ===
                                                                    true ? (
                                                                    <div className="2xl:col-span-12 lg:col-span-12 col-span-12 other-checkbox">
                                                                      <label className="flex items-center w-full custom-checkbox-set">
                                                                        <input
                                                                          type="checkbox"
                                                                          name="checkbox-buttons"
                                                                          className="cursor-pointer h-4 w-4 text-[#C00000] focus:ring-[#c00000] bg-white rounded check-box-custom"
                                                                        />
                                                                        <div className="text-xl  font-medium theme-color ml-3 w-full">
                                                                          <input
                                                                            className="h-[40px] border border-[#e2e2e2] text-[#757575] w-full"
                                                                            type="text"
                                                                            name="eventQuestionAnswer"
                                                                            value={
                                                                              ans.eventQuestionAnswer
                                                                            }
                                                                            onChange={(
                                                                              e
                                                                            ) =>
                                                                              handleChange(
                                                                                e,
                                                                                "EventAnswer",
                                                                                key,
                                                                                index
                                                                              )
                                                                            }
                                                                            placeholder="Others"
                                                                          />
                                                                        </div>
                                                                      </label>
                                                                    </div>
                                                                  ) : (
                                                                    <div
                                                                      className="2xl:col-span-6 lg:col-span-6 col-span-12"
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      <label className="flex items-center w-full custom-checkbox-set">
                                                                        <input
                                                                          type="checkbox"
                                                                          name="checkbox-buttons"
                                                                          className="cursor-pointer h-4 w-4 text-[#C00000] focus:ring-[#c00000] bg-white rounded check-box-custom"
                                                                        />
                                                                        <div className="text-xl  font-medium theme-color ml-3 w-full">
                                                                          <input
                                                                            className="h-[40px] border border-[#e2e2e2] text-[#757575] w-full"
                                                                            type="text"
                                                                            name="eventQuestionAnswer"
                                                                            value={
                                                                              ans.eventQuestionAnswer
                                                                            }
                                                                            onChange={(
                                                                              e
                                                                            ) =>
                                                                              handleChange(
                                                                                e,
                                                                                "EventAnswer",
                                                                                key,
                                                                                index
                                                                              )
                                                                            }
                                                                            placeholder={`CheckBox ${index +
                                                                              1
                                                                              }`}
                                                                          />
                                                                        </div>
                                                                      </label>
                                                                    </div>
                                                                  )}
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      </div>
                                                      <div className="col-span-2 mb-1 pr-2">
                                                        <div className="add-buttons flex items-center justify-end w-full ml-2">
                                                          <button
                                                            className="text-white btn-gray bg-[#b3b3b3] min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] rounded-[7px] text-center mr-2"
                                                            onClick={() =>
                                                              deleteAnswer(
                                                                que.answers,
                                                                key,
                                                                "CheckBox"
                                                              )
                                                            }
                                                          >
                                                            <span className="text-[25px] leading-[28px]">
                                                              -
                                                            </span>
                                                          </button>
                                                          <button
                                                            className="text-white btn-lighblue bg-[#81a3b8] min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] rounded-[7px] text-center"
                                                            onClick={() =>
                                                              addAnswer(
                                                                que.answers,
                                                                key,
                                                                "CheckBox"
                                                              )
                                                            }
                                                          >
                                                            <span className="text-[25px] leading-[28px]">
                                                              +
                                                            </span>
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                  {que.eventQuestionTypeId === 4 &&
                                    que.isFeedbackQuestion === false ? (
                                    <>
                                      <div className="question-types check-boxes mb-7 last:mb-0 relative">
                                        <div className="close-sign absolute top-[-10px] right-[-10px]">
                                          <button className="h-7 w-7 flex items-center justify-center text-[#757575] hover:text-[#3a3a3a] rounded-full border-2 border-[#757575]"
                                            onClick={() => deleteEventQuestionById(key, que.eventQuestionId, 'AdditionalQuestions')}>
                                            <CloseButtonIcon />
                                          </button>
                                        </div>
                                        <div className="flex items-start w-full">
                                          <div className="question-sec border border-[#e2e2e2] w-full p-6 pt-4">
                                            <div className="flex items-center justify-between mb-3">
                                              <div>
                                                {
                                                  questionArray.find(
                                                    (x) =>
                                                      x.questionTypeId ===
                                                      que.eventQuestionTypeId
                                                  ).label
                                                }
                                              </div>
                                              <div
                                                className="required-btn text-[#757575] font-medium text-xl theme-color ml-2"
                                                key={key}
                                              >
                                                <ToggleButton
                                                  onHandleToggle={
                                                    handleChangeToggle
                                                  }
                                                  label="Required"
                                                  setToggle={
                                                    que.isMandatoryQuestion
                                                  }
                                                  index={key}
                                                />
                                              </div>
                                            </div>
                                            <div className="question-answer-sec text-[#757575] w-full">
                                              <div className="question-input dropdown-question">
                                                <input
                                                  className="w-full border border-[#e2e2e2] font-bold text-[24px]"
                                                  type="text"
                                                  name="eventQuestion"
                                                  value={que.eventQuestion}
                                                  onChange={(e) =>
                                                    handleChange(
                                                      e,
                                                      "Question",
                                                      key
                                                    )
                                                  }
                                                  placeholder={`Question ${key + 1
                                                    }`}
                                                />
                                              </div>
                                              <div className="answer-input">
                                                <div className="radio-answer">
                                                  <div className="mt-5">
                                                    <div className="grid grid-cols-12 gap-6 items-end">
                                                      <div className="col-span-10">
                                                        <div className="grid grid-cols-12 gap-6">
                                                          {que.answers.map(
                                                            (ans, index) => {
                                                              return (
                                                                <>
                                                                  <div
                                                                    className="2xl:col-span-6 lg:col-span-6 col-span-12"
                                                                    key={index}
                                                                  >
                                                                    <label className="flex items-center w-full custom-checkbox-set">
                                                                      <span>
                                                                        {index +
                                                                          1}
                                                                        .
                                                                      </span>
                                                                      <div className="text-xl  font-medium theme-color ml-3 w-full">
                                                                        <input
                                                                          className="h-[40px] border border-[#e2e2e2] text-[#757575] w-full"
                                                                          type="text"
                                                                          name="eventQuestionAnswer"
                                                                          value={
                                                                            ans.eventQuestionAnswer
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) =>
                                                                            handleChange(
                                                                              e,
                                                                              "EventAnswer",
                                                                              key,
                                                                              index
                                                                            )
                                                                          }
                                                                          placeholder={`Answer ${index +
                                                                            1
                                                                            }`}
                                                                        />
                                                                      </div>
                                                                    </label>
                                                                  </div>
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      </div>
                                                      <div className="col-span-2 mb-1 pr-2">
                                                        <div className="add-buttons flex items-center justify-end w-full ml-2">
                                                          <button
                                                            className="text-white btn-gray bg-[#b3b3b3] min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] rounded-[7px] text-center mr-2"
                                                            onClick={() =>
                                                              deleteAnswer(
                                                                que.answers,
                                                                key
                                                              )
                                                            }
                                                          >
                                                            <span className="text-[25px] leading-[28px]">
                                                              -
                                                            </span>
                                                          </button>
                                                          <button
                                                            className="text-white btn-lighblue bg-[#81a3b8] min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] rounded-[7px] text-center"
                                                            onClick={() =>
                                                              addAnswer(
                                                                que.answers,
                                                                key
                                                              )
                                                            }
                                                          >
                                                            <span className="text-[25px] leading-[28px]">
                                                              +
                                                            </span>
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                  {que.eventQuestionTypeId === 5 &&
                                    que.isFeedbackQuestion === false ? (
                                    <>
                                      <div className="question-types check-boxes mb-7 last:mb-0 relative">
                                        <div className="close-sign absolute top-[-10px] right-[-10px]">
                                          <button className="h-7 w-7 flex items-center justify-center text-[#757575] hover:text-[#3a3a3a] rounded-full border-2 border-[#757575]"
                                            onClick={() => deleteEventQuestionById(key, que.eventQuestionId, 'AdditionalQuestions')}>
                                            <CloseButtonIcon />
                                          </button>
                                        </div>
                                        <div className="flex items-start w-full">
                                          <div className="question-sec border border-[#e2e2e2] w-full p-6 pt-4">
                                            <div className="flex items-center justify-between mb-3">
                                              <div>
                                                {
                                                  questionArray.find(
                                                    (x) =>
                                                      x.questionTypeId ===
                                                      que.eventQuestionTypeId
                                                  ).label
                                                }
                                              </div>
                                              <div
                                                className="required-btn  text-[#757575] font-medium text-xl theme-color ml-2"
                                                key={key}
                                              >
                                                <ToggleButton
                                                  onHandleToggle={
                                                    handleChangeToggle
                                                  }
                                                  label="Required"
                                                  setToggle={
                                                    que.isMandatoryQuestion
                                                  }
                                                  index={key}
                                                />
                                              </div>
                                            </div>
                                            <div className="question-answer-sec text-[#757575] w-full">
                                              <div className="question-input dropdown-multi-question">
                                                <input
                                                  className="w-full border border-[#e2e2e2] font-bold text-[24px]"
                                                  type="text"
                                                  name="eventQuestion"
                                                  value={que.eventQuestion}
                                                  onChange={(e) =>
                                                    handleChange(
                                                      e,
                                                      "Question",
                                                      key
                                                    )
                                                  }
                                                  placeholder={`Question ${key + 1
                                                    }`}
                                                />
                                              </div>
                                              <div className="answer-input">
                                                <div className="radio-answer">
                                                  <div className="mt-5">
                                                    <div className="grid grid-cols-12 gap-6 items-end">
                                                      <div className="col-span-10">
                                                        <div className="grid grid-cols-12 gap-6">
                                                          {que.answers.map(
                                                            (ans, index) => {
                                                              return (
                                                                <>
                                                                  <div
                                                                    className="2xl:col-span-6 lg:col-span-6 col-span-12"
                                                                    key={index}
                                                                  >
                                                                    <label className="flex items-center w-full custom-checkbox-set">
                                                                      <span>
                                                                        {index +
                                                                          1}
                                                                        .
                                                                      </span>
                                                                      <div className="text-xl  font-medium theme-color ml-3 w-full">
                                                                        <input
                                                                          className="h-[40px] border border-[#e2e2e2] text-[#757575] w-full"
                                                                          type="text"
                                                                          name="eventQuestionAnswer"
                                                                          value={
                                                                            ans.eventQuestionAnswer
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) =>
                                                                            handleChange(
                                                                              e,
                                                                              "EventAnswer",
                                                                              key,
                                                                              index
                                                                            )
                                                                          }
                                                                          placeholder={`Answer ${index +
                                                                            1
                                                                            }`}
                                                                        />
                                                                      </div>
                                                                    </label>
                                                                  </div>
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      </div>
                                                      <div className="col-span-2 mb-1 pr-2">
                                                        <div className="add-buttons flex items-center justify-end w-full ml-2">
                                                          <button
                                                            className="text-white btn-gray bg-[#b3b3b3] min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] rounded-[7px] text-center mr-2"
                                                            onClick={() =>
                                                              deleteAnswer(
                                                                que.answers,
                                                                key
                                                              )
                                                            }
                                                          >
                                                            <span className="text-[25px] leading-[28px]">
                                                              -
                                                            </span>
                                                          </button>
                                                          <button
                                                            className="text-white btn-lighblue bg-[#81a3b8] min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] rounded-[7px] text-center"
                                                            onClick={() =>
                                                              addAnswer(
                                                                que.answers,
                                                                key
                                                              )
                                                            }
                                                          >
                                                            <span className="text-[25px] leading-[28px]">
                                                              +
                                                            </span>
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="col-span-4 pt-11">
                        <div className="add-question relative">
                          <button
                            className="py-3 px-5 btn btn-sm btn-red text-lg font-bold text-white active-question-type"
                            onClick={() => {
                              setAddNewQuestion(true);
                            }}
                          >
                            Add a Question
                            <span className="ml-4 fa fa-angle-up"></span>
                          </button>
                          {addNewQuestion === true ? (
                            <div
                              className="down-option-section border bg-white border-[#e2e2e2] absolute w-full top-[150%] z-10"
                              onMouseLeave={() => setAddNewQuestion(false)}
                            >
                              <ul>
                                <li className="transition-[0.8s] hover:transition-[0.8s] hover:bg-[#e6ebf1] 2xl:p-[15px] lg:p-[10px] p-[7px]">
                                  <button
                                    className="flex items-center"
                                    onClick={() => addQuestion(false, 1)}
                                  >
                                    <div className="icon-left">
                                      <img
                                        src="/textbox.jpg"
                                        className="min-h-[70px] min-w-[70px] max-h-[70px] max-w-[70px]"
                                        alt=""
                                      />
                                    </div>
                                    <div className="right-menu-tab pl-4 text-[#3b4356]">
                                      <div className="title-txt text-left text-[16px]">
                                        Textbox
                                      </div>
                                      <div className="title-desc text-[12px]">
                                        Provide an open-text response
                                      </div>
                                    </div>
                                  </button>
                                </li>
                                <li className="transition-[0.8s] hover:transition-[0.8s] hover:bg-[#e6ebf1] 2xl:p-[15px] lg:p-[10px] p-[7px]">
                                  <button
                                    className="flex items-center"
                                    onClick={() => addQuestion(false, 4)}
                                  >
                                    <div className="icon-left">
                                      <img
                                        src="/dropdown.jpg"
                                        className="min-h-[70px] min-w-[70px] max-h-[70px] max-w-[70px]"
                                        alt=""
                                      />
                                    </div>
                                    <div className="right-menu-tab pl-4 text-[#3b4356]">
                                      <div className="title-txt text-left text-[16px]">
                                        Drop-down
                                      </div>
                                      <div className="title-desc text-[12px]">
                                        Select a single answer from a list of
                                        options
                                      </div>
                                    </div>
                                  </button>
                                </li>
                                <li className="transition-[0.8s] hover:transition-[0.8s] hover:bg-[#e6ebf1] 2xl:p-[15px] lg:p-[10px] p-[7px]">
                                  <button
                                    className="flex items-center"
                                    onClick={() => addQuestion(false, 5)}
                                  >
                                    <div className="icon-left">
                                      <img
                                        src="/dropdown.jpg"
                                        className="min-h-[70px] min-w-[70px] max-h-[70px] max-w-[70px]"
                                        alt=""
                                      />
                                    </div>
                                    <div className="right-menu-tab pl-4 text-[#3b4356]">
                                      <div className="title-txt text-left text-[16px]">
                                        Multi-Select Drop-down
                                      </div>
                                      <div className="title-desc text-[12px]">
                                        Select a multiple answer from a list of
                                        options
                                      </div>
                                    </div>
                                  </button>
                                </li>
                                <li className="hover:bg-[#e6ebf1] p-[15px]">
                                  <button
                                    className="flex items-center"
                                    onClick={() => addQuestion(false, 2)}
                                  >
                                    <div className="icon-left">
                                      <img
                                        src="/radio.jpg"
                                        className="min-h-[70px] min-w-[70px] max-h-[70px] max-w-[70px]"
                                        alt=""
                                      />
                                    </div>
                                    <div className="right-menu-tab pl-4 text-[#3b4356]">
                                      <div className="title-txt text-left text-[16px]">
                                        Radio Buttons
                                      </div>
                                      <div className="title-desc text-[12px]">
                                        Select a single answer from a list of
                                        options
                                      </div>
                                    </div>
                                  </button>
                                </li>
                                <li className="hover:bg-[#e6ebf1] p-[15px]">
                                  <button
                                    className="flex items-center"
                                    onClick={() => addQuestion(false, 3)}
                                  >
                                    <div className="icon-left">
                                      <img
                                        src="/checkbox.jpg"
                                        className="min-h-[70px] min-w-[70px] max-h-[70px] max-w-[70px]"
                                        alt=""
                                      />
                                    </div>
                                    <div className="right-menu-tab pl-4 text-[#3b4356]">
                                      <div className="title-txt text-left text-[16px]">
                                        Checkboxes
                                      </div>
                                      <div className="title-desc text-[12px]">
                                        Select multiple answer from a list of
                                        options
                                      </div>
                                    </div>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Additional Questions End */}
      {/* Feedback Questions Start */}
      <div className="bg-white mt-12">
        <div className="heading-part px-8 py-4 border-b">
          <div className="flex items-center justify-between">
            <h4 className="small-title font-bold theme-color">
              Feedback Questions
            </h4>
          </div>
        </div>
        <div className="2xl:px-9 2xl:py-7 lg:pr-5 lg:py-5">
          <div className="grid grid-cols-12 gap-6">
            <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <div className="grid grid-cols-12 gap-6 mt-7 pl-8">
                    <div className="lg:col-span-8 col-span-7">
                      <div className="question-main-sec">
                        {questionArr.map((que, key) => {
                          return (
                            <>
                              {que.eventQuestionTypeId === 1 &&
                                que.isFeedbackQuestion === true ? (
                                <>
                                  <div
                                    className="question-types text-boxes mb-7 last:mb-0 relative"
                                    key={key}
                                  >
                                    <div className="close-sign absolute top-[-10px] right-[-10px]">
                                      <button className="h-7 w-7 flex items-center justify-center text-[#757575] hover:text-[#3a3a3a] rounded-full border-2 border-[#757575]"
                                        onClick={() => deleteEventQuestionById(key, que.eventQuestionId, 'FeedbackQuestions')}>
                                        <CloseButtonIcon />
                                      </button>
                                    </div>
                                    <div className="flex items-start w-full">
                                      <div className="arrow-btns w-10">
                                        <button className="text-[#757575]">
                                          <img
                                            className="w-5"
                                            src="/awipe-arrows.png"
                                            alt=""
                                          />
                                        </button>
                                      </div>
                                      <div className="question-sec border border-[#e5e7eb] w-full p-6 pt-4">
                                        <div className="flex items-center justify-between mb-3">
                                          <div>
                                            {
                                              questionArray.find(
                                                (x) =>
                                                  x.questionTypeId ===
                                                  que.eventQuestionTypeId
                                              ).label
                                            }
                                          </div>
                                          <div className="required-btn text-[#3b4356]">
                                            <ToggleButton
                                              onHandleToggle={
                                                handleChangeToggle
                                              }
                                              label="Required"
                                              setToggle={
                                                que.isMandatoryQuestion
                                              }
                                              index={key}
                                            />
                                          </div>
                                        </div>

                                        <div className="question-answer-sec text-[#757575] w-full">
                                          <div className="question-input">
                                            <input
                                              className="w-full border border-[#e5e7eb] font-bold text-[24px]"
                                              type="text"
                                              name="eventQuestion"
                                              value={que.eventQuestion}
                                              onChange={(e) =>
                                                handleChange(e, "Question", key)
                                              }
                                              placeholder={`Question ${key + 1
                                                }`}
                                            />
                                          </div>

                                          {/* {que.answers.map((ans, index) => {
                                            return (
                                              <>
                                                <div
                                                  className="answer-input"
                                                  key={index}
                                                >
                                                  <input
                                                    className="w-full mt-3 border border-[#e5e7eb] font-bold text-[24px]"
                                                    type="text"
                                                    name="eventQuestionAnswer"
                                                    value={
                                                      ans.eventQuestionAnswer
                                                    }
                                                    onChange={(e) =>
                                                      handleChange(
                                                        e,
                                                        "EventAnswer",
                                                        key,
                                                        index
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </>
                                            );
                                          })} */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                              {que.eventQuestionTypeId === 2 &&
                                que.isFeedbackQuestion === true ? (
                                <>
                                  <div
                                    className="question-types radio-buttons mb-7 last:mb-0 relative"
                                    key={key}
                                  >
                                    <div className="close-sign absolute top-[-10px] right-[-10px]">
                                      <button className="h-7 w-7 flex items-center justify-center text-[#757575] hover:text-[#3a3a3a] rounded-full border-2 border-[#757575]"
                                        onClick={() => deleteEventQuestionById(key, que.eventQuestionId, 'FeedbackQuestions')}>
                                        <CloseButtonIcon />
                                      </button>
                                    </div>
                                    <div className="flex items-start w-full">
                                      <div className="arrow-btns w-10">
                                        <button className="text-[#757575]">
                                          <img
                                            className="w-5"
                                            src="/awipe-arrows.png"
                                            alt=""
                                          />
                                        </button>
                                      </div>
                                      <div className="question-sec border border-[#e5e7eb] w-full p-6 pt-4">
                                        <div className="flex items-center justify-between mb-3">
                                          <div>
                                            {
                                              questionArray.find(
                                                (x) =>
                                                  x.questionTypeId ===
                                                  que.eventQuestionTypeId
                                              ).label
                                            }
                                          </div>
                                          <div className="required-btn text-[#3b4356]">
                                            <ToggleButton
                                              onHandleToggle={
                                                handleChangeToggle
                                              }
                                              label="Required"
                                              setToggle={
                                                que.isMandatoryQuestion
                                              }
                                              index={key}
                                            />
                                          </div>
                                        </div>
                                        <div className="question-answer-sec text-[#757575] w-full">
                                          <div className="question-input">
                                            <input
                                              className="w-full border border-[#e2e2e2] font-bold text-[24px]"
                                              type="text"
                                              name="eventQuestion"
                                              value={que.eventQuestion}
                                              onChange={(e) =>
                                                handleChange(e, "Question", key)
                                              }
                                              placeholder={`Question ${key + 1
                                                }`}
                                            />
                                          </div>
                                          <div className="answer-input">
                                            <div className="radio-answer">
                                              <div className="mt-5">
                                                <div className="grid grid-cols-12 gap-6 items-end">
                                                  <div className="col-span-10">
                                                    <div className="grid grid-cols-12 gap-6">
                                                      {que.answers.map(
                                                        (ans, index) => {
                                                          return (
                                                            <>
                                                              <div
                                                                className="2xl:col-span-6 lg:col-span-6 col-span-12"
                                                                key={index}
                                                              >
                                                                <label className="flex items-center w-full">
                                                                  <input
                                                                    type="radio"
                                                                    name="radio1"
                                                                    id="isPage"
                                                                    className="form-radio"
                                                                  //   value={props.resource.isPage}
                                                                  //   onChange={(event) =>
                                                                  //     props.handleRadioButtons(event)
                                                                  //   }
                                                                  //   checked={props.resource.isPage === true}
                                                                  />
                                                                  {/* Akshay */}
                                                                  <div className="text-xl  font-medium theme-color ml-3 w-full">
                                                                    <input
                                                                      id={
                                                                        "Ans" +
                                                                        index
                                                                      }
                                                                      className="h-[40px] border border-[#e2e2e2] text-[#757575] w-full"
                                                                      type="text"
                                                                      name="eventQuestionAnswer"
                                                                      value={
                                                                        ans.eventQuestionAnswer
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChange(
                                                                          e,
                                                                          "EventAnswer",
                                                                          key,
                                                                          index
                                                                        )
                                                                      }
                                                                      placeholder={`Radio Answer ${index +
                                                                        1
                                                                        }`}
                                                                    />
                                                                  </div>
                                                                </label>
                                                              </div>
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="col-span-2 mb-1 pr-2">
                                                    <div className="add-buttons flex items-center justify-end w-full ml-2">
                                                      <button
                                                        className="text-white btn-gray bg-[#b3b3b3] min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] rounded-[7px] text-center mr-2"
                                                        onClick={() =>
                                                          deleteAnswer(
                                                            que.answers,
                                                            key
                                                          )
                                                        }
                                                      >
                                                        <span className="text-[25px] leading-[28px]">
                                                          -
                                                        </span>
                                                      </button>
                                                      <button
                                                        className="text-white btn-lighblue bg-[#81a3b8] min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] rounded-[7px] text-center"
                                                        onClick={() =>
                                                          addAnswer(
                                                            que.answers,
                                                            key
                                                          )
                                                        }
                                                      >
                                                        <span className="text-[25px] leading-[28px]">
                                                          +
                                                        </span>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                              {que.eventQuestionTypeId === 3 &&
                                que.isFeedbackQuestion === true ? (
                                <>
                                  <div
                                    className="question-types check-boxes mb-7 last:mb-0 relative"
                                    key={key}
                                  >
                                    <div className="close-sign absolute top-[-10px] right-[-10px]">
                                      <button className="h-7 w-7 flex items-center justify-center text-[#757575] hover:text-[#3a3a3a] rounded-full border-2 border-[#757575]"
                                        onClick={() => deleteEventQuestionById(key, que.eventQuestionId, 'FeedbackQuestions')}>
                                        <CloseButtonIcon />
                                      </button>
                                    </div>
                                    <div className="flex items-start w-full">
                                      <div className="arrow-btns w-10">
                                        <button className="text-[#757575]">
                                          <img
                                            className="w-5"
                                            src="/awipe-arrows.png"
                                            alt=""
                                          />
                                        </button>
                                      </div>
                                      <div className="question-sec border border-[#e5e7eb] w-full p-6 pt-4">
                                        <div className="flex items-center justify-between mb-3">
                                          <div>
                                            {
                                              questionArray.find(
                                                (x) =>
                                                  x.questionTypeId ===
                                                  que.eventQuestionTypeId
                                              ).label
                                            }
                                          </div>
                                          <div className="required-btn text-[#3b4356] flex items-center">
                                            <div>
                                              <ToggleButton
                                                onHandleToggle={
                                                  handleChangeToggle
                                                }
                                                label="Required"
                                                setToggle={
                                                  que.isMandatoryQuestion
                                                }
                                                index={key}
                                              />
                                            </div>
                                            <div>
                                              <ToggleButton
                                                onHandleToggle={
                                                  handleChangeToggle
                                                }
                                                label="Others"
                                                setToggle={que.isOther}
                                                index={`Others/${key}`}
                                                identity="Others"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="question-answer-sec text-[#757575] w-full">
                                          <div className="question-input">
                                            <input
                                              className="w-full border border-[#e2e2e2] font-bold text-[24px]"
                                              type="text"
                                              name="eventQuestion"
                                              value={que.eventQuestion}
                                              onChange={(e) =>
                                                handleChange(e, "Question", key)
                                              }
                                              placeholder={`Question ${key + 1
                                                }`}
                                            />
                                          </div>
                                          <div className="answer-input">
                                            <div className="radio-answer">
                                              <div className="mt-5">
                                                <div className="grid grid-cols-12 gap-6 items-end">
                                                  <div className="col-span-10">
                                                    <div className="grid grid-cols-12 gap-6">
                                                      {que.answers.map(
                                                        (ans, index) => {
                                                          return (
                                                            <>
                                                              {ans.isOther ===
                                                                true ? (
                                                                <div className="2xl:col-span-12 lg:col-span-12 col-span-12 other-checkbox" key={index}>
                                                                  <label className="flex items-center w-full custom-checkbox-set">
                                                                    <input
                                                                      type="checkbox"
                                                                      name="checkbox-buttons"
                                                                      className="cursor-pointer h-4 w-4 text-[#C00000] focus:ring-[#c00000] bg-white rounded check-box-custom"
                                                                    />
                                                                    <div className="text-xl  font-medium theme-color ml-3 w-full">
                                                                      <input
                                                                        className="h-[40px] border border-[#e2e2e2] text-[#757575] w-full"
                                                                        type="text"
                                                                        name="eventQuestionAnswer"
                                                                        value={
                                                                          ans.eventQuestionAnswer
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleChange(
                                                                            e,
                                                                            "EventAnswer",
                                                                            key,
                                                                            index
                                                                          )
                                                                        }
                                                                        placeholder="Others"
                                                                      />
                                                                    </div>
                                                                  </label>
                                                                </div>
                                                              ) : (
                                                                <>
                                                                  <div
                                                                    className="2xl:col-span-6 lg:col-span-6 col-span-12"
                                                                    key={index}
                                                                  >
                                                                    <label className="flex items-center w-full custom-checkbox-set">
                                                                      <input
                                                                        type="checkbox"
                                                                        name="checkbox-buttons"
                                                                        className="cursor-pointer h-4 w-4 text-[#C00000] focus:ring-[#c00000] bg-white rounded check-box-custom"
                                                                      />
                                                                      <div className="text-xl  font-medium theme-color ml-3 w-full">
                                                                        <input
                                                                          className="h-[40px] border border-[#e2e2e2] text-[#757575] w-full"
                                                                          type="text"
                                                                          name="eventQuestionAnswer"
                                                                          value={
                                                                            ans.eventQuestionAnswer
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) =>
                                                                            handleChange(
                                                                              e,
                                                                              "EventAnswer",
                                                                              key,
                                                                              index
                                                                            )
                                                                          }
                                                                          placeholder={`CheckBox ${index +
                                                                            1
                                                                            }`}
                                                                        />
                                                                      </div>
                                                                    </label>
                                                                  </div>
                                                                </>
                                                              )}
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="col-span-2 mb-1 pr-2">
                                                    <div className="add-buttons flex items-center justify-end w-full ml-2">
                                                      <button
                                                        className="text-white btn-gray bg-[#b3b3b3] min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] rounded-[7px] text-center mr-2"
                                                        onClick={() =>
                                                          deleteAnswer(
                                                            que.answers,
                                                            key,
                                                            "CheckBox"
                                                          )
                                                        }
                                                      >
                                                        <span className="text-[25px] leading-[28px]">
                                                          -
                                                        </span>
                                                      </button>
                                                      <button
                                                        className="text-white btn-lighblue bg-[#81a3b8] min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] rounded-[7px] text-center"
                                                        onClick={() =>
                                                          addAnswer(
                                                            que.answers,
                                                            key,
                                                            "CheckBox"
                                                          )
                                                        }
                                                      >
                                                        <span className="text-[25px] leading-[28px]">
                                                          +
                                                        </span>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                              {que.eventQuestionTypeId === 4 &&
                                que.isFeedbackQuestion === true ? (
                                <>
                                  <div
                                    className="question-types check-boxes mb-7 last:mb-0 relative"
                                    key={key}
                                  >
                                    <div className="close-sign absolute top-[-10px] right-[-10px]">
                                      <button className="h-7 w-7 flex items-center justify-center text-[#757575] hover:text-[#3a3a3a] rounded-full border-2 border-[#757575]"
                                        onClick={() => deleteEventQuestionById(key, que.eventQuestionId, 'FeedbackQuestions')}>
                                        <CloseButtonIcon />
                                      </button>
                                    </div>
                                    <div className="flex items-start w-full">
                                      <div className="arrow-btns w-10">
                                        <button className="text-[#757575]">
                                          <img
                                            className="w-5"
                                            src="/awipe-arrows.png"
                                            alt=""
                                          />
                                        </button>
                                      </div>
                                      <div className="question-sec border border-[#e5e7eb] w-full p-6 pt-4">
                                        <div className="flex items-center justify-between mb-3">
                                          <div>
                                            {
                                              questionArray.find(
                                                (x) =>
                                                  x.questionTypeId ===
                                                  que.eventQuestionTypeId
                                              ).label
                                            }
                                          </div>
                                          <div className="required-btn text-[#3b4356]">
                                            <div>
                                              <ToggleButton
                                                onHandleToggle={
                                                  handleChangeToggle
                                                }
                                                label="Required"
                                                setToggle={
                                                  que.isMandatoryQuestion
                                                }
                                                index={key}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="question-answer-sec text-[#757575] w-full">
                                          <div className="question-input dropdown-question">
                                            <input
                                              className="w-full border border-[#e2e2e2] font-bold text-[24px]"
                                              type="text"
                                              name="eventQuestion"
                                              value={que.eventQuestion}
                                              onChange={(e) =>
                                                handleChange(e, "Question", key)
                                              }
                                              placeholder={`Question ${key + 1
                                                }`}
                                            />
                                          </div>
                                          <div className="answer-input">
                                            <div className="radio-answer">
                                              <div className="mt-5">
                                                <div className="grid grid-cols-12 gap-6 items-end">
                                                  <div className="col-span-10">
                                                    <div className="grid grid-cols-12 gap-6">
                                                      {que.answers.map(
                                                        (ans, index) => {
                                                          return (
                                                            <>
                                                              <div
                                                                className="2xl:col-span-6 lg:col-span-6 col-span-12"
                                                                key={index}
                                                              >
                                                                <label className="flex items-center w-full custom-checkbox-set">
                                                                  <span>
                                                                    {index + 1}.
                                                                  </span>
                                                                  <div className="text-xl  font-medium theme-color ml-3 w-full">
                                                                    <input
                                                                      className="h-[40px] border border-[#e2e2e2] text-[#757575] w-full"
                                                                      type="text"
                                                                      name="eventQuestionAnswer"
                                                                      value={
                                                                        ans.eventQuestionAnswer
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChange(
                                                                          e,
                                                                          "EventAnswer",
                                                                          key,
                                                                          index
                                                                        )
                                                                      }
                                                                      placeholder={`Answer ${index +
                                                                        1
                                                                        }`}
                                                                    />
                                                                  </div>
                                                                </label>
                                                              </div>
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="col-span-2 mb-1 pr-2">
                                                    <div className="add-buttons flex items-center justify-end w-full ml-2">
                                                      <button
                                                        className="text-white btn-gray bg-[#b3b3b3] min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] rounded-[7px] text-center mr-2"
                                                        onClick={() =>
                                                          deleteAnswer(
                                                            que.answers,
                                                            key
                                                          )
                                                        }
                                                      >
                                                        <span className="text-[25px] leading-[28px]">
                                                          -
                                                        </span>
                                                      </button>
                                                      <button
                                                        className="text-white btn-lighblue bg-[#81a3b8] min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] rounded-[7px] text-center"
                                                        onClick={() =>
                                                          addAnswer(
                                                            que.answers,
                                                            key
                                                          )
                                                        }
                                                      >
                                                        <span className="text-[25px] leading-[28px]">
                                                          +
                                                        </span>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                              {que.eventQuestionTypeId === 5 &&
                                que.isFeedbackQuestion === true ? (
                                <>
                                  <div
                                    className="question-types check-boxes mb-7 last:mb-0 relative"
                                    key={key}
                                  >
                                    <div className="close-sign absolute top-[-10px] right-[-10px]">
                                      <button className="h-7 w-7 flex items-center justify-center text-[#757575] hover:text-[#3a3a3a] rounded-full border-2 border-[#757575]"
                                        onClick={() => deleteEventQuestionById(key, que.eventQuestionId, 'FeedbackQuestions')}>
                                        <CloseButtonIcon />
                                      </button>
                                    </div>
                                    <div className="flex items-start w-full">
                                      <div className="arrow-btns w-10">
                                        <button className="text-[#757575]">
                                          <img
                                            className="w-5"
                                            src="/awipe-arrows.png"
                                            alt=""
                                          />
                                        </button>
                                      </div>
                                      <div className="question-sec border border-[#e5e7eb] w-full p-6 pt-4">
                                        <div className="flex items-center justify-between mb-3">
                                          <div>
                                            {
                                              questionArray.find(
                                                (x) =>
                                                  x.questionTypeId ===
                                                  que.eventQuestionTypeId
                                              ).label
                                            }
                                          </div>
                                          <div className="required-btn text-[#3b4356]">
                                            <div>
                                              <ToggleButton
                                                onHandleToggle={
                                                  handleChangeToggle
                                                }
                                                label="Required"
                                                setToggle={
                                                  que.isMandatoryQuestion
                                                }
                                                index={key}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="question-answer-sec text-[#757575] w-full">
                                          <div className="question-input dropdown-multi-question">
                                            <input
                                              className="w-full border border-[#e2e2e2] font-bold text-[24px]"
                                              type="text"
                                              name="eventQuestion"
                                              value={que.eventQuestion}
                                              onChange={(e) =>
                                                handleChange(e, "Question", key)
                                              }
                                              placeholder={`Question ${key + 1
                                                }`}
                                            />
                                          </div>
                                          <div className="answer-input">
                                            <div className="radio-answer">
                                              <div className="mt-5">
                                                <div className="grid grid-cols-12 gap-6 items-end">
                                                  <div className="col-span-10">
                                                    <div className="grid grid-cols-12 gap-6">
                                                      {que.answers.map(
                                                        (ans, index) => {
                                                          return (
                                                            <>
                                                              <div
                                                                className="2xl:col-span-6 lg:col-span-6 col-span-12"
                                                                key={index}
                                                              >
                                                                <label className="flex items-center w-full custom-checkbox-set">
                                                                  <span>
                                                                    {index + 1}.
                                                                  </span>
                                                                  <div className="text-xl  font-medium theme-color ml-3 w-full">
                                                                    <input
                                                                      className="h-[40px] border border-[#e2e2e2] text-[#757575] w-full"
                                                                      type="text"
                                                                      name="eventQuestionAnswer"
                                                                      value={
                                                                        ans.eventQuestionAnswer
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChange(
                                                                          e,
                                                                          "EventAnswer",
                                                                          key,
                                                                          index
                                                                        )
                                                                      }
                                                                      placeholder={`Answer ${index +
                                                                        1
                                                                        }`}
                                                                    />
                                                                  </div>
                                                                </label>
                                                              </div>
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="col-span-2 mb-1 pr-2">
                                                    <div className="add-buttons flex items-center justify-end w-full ml-2">
                                                      <button
                                                        className="text-white btn-gray bg-[#b3b3b3] min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] rounded-[7px] text-center mr-2"
                                                        onClick={() =>
                                                          deleteAnswer(
                                                            que.answers,
                                                            key
                                                          )
                                                        }
                                                      >
                                                        <span className="text-[25px] leading-[28px]">
                                                          -
                                                        </span>
                                                      </button>
                                                      <button
                                                        className="text-white btn-lighblue bg-[#81a3b8] min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] rounded-[7px] text-center"
                                                        onClick={() =>
                                                          addAnswer(
                                                            que.answers,
                                                            key
                                                          )
                                                        }
                                                      >
                                                        <span className="text-[25px] leading-[28px]">
                                                          +
                                                        </span>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className="lg:col-span-4 col-span-5 flex justify-end">
                      <div className="add-question relative w-full flex justify-end">
                        <button
                          className="p-3 btn btn-sm btn-red text-lg font-bold text-white"
                          onClick={() => setAddNewQuestionFeedback(true)}
                        >
                          Add a Question
                          <span className="ml-4 fa fa-angle-up"></span>
                        </button>
                        {addNewQuestionFeedback === true ? (
                          <div
                            className="add-question-part down-option-section border bg-white border-[#e2e2e2] absolute w-full top-[70px] z-10"
                            onMouseLeave={() =>
                              setAddNewQuestionFeedback(false)
                            }
                          >
                            <ul>
                              <li className="transition-[0.8s] hover:transition-[0.8s] hover:bg-[#e6ebf1] 2xl:p-[15px] lg:p-[10px] p-[7px]">
                                <button
                                  className="flex 2xl:items-center lg:items-start items-start"
                                  onClick={() => addQuestion(true, 1)}
                                >
                                  <div className="icon-left">
                                    <img
                                      src="/textbox.jpg"
                                      className="min-h-[70px] min-w-[70px] max-h-[70px] max-w-[70px]"
                                      alt=""
                                    />
                                  </div>
                                  <div className="right-menu-tab pl-4 text-[#3b4356]">
                                    <div className="title-txt text-left 2xl:text-[16px] lg:text-[14px] text-[14px]">
                                      Textbox
                                    </div>
                                    <div className="title-desc text-[12px]">
                                      Provide an open-text response
                                    </div>
                                  </div>
                                </button>
                              </li>
                              <li className="transition-[0.8s] hover:transition-[0.8s] hover:bg-[#e6ebf1] 2xl:p-[15px] lg:p-[10px] p-[7px]">
                                <button
                                  className="flex 2xl:items-center lg:items-start items-start"
                                  onClick={() => addQuestion(true, 4)}
                                >
                                  <div className="icon-left">
                                    <img
                                      src="/dropdown.jpg"
                                      className="min-h-[70px] min-w-[70px] max-h-[70px] max-w-[70px]"
                                      alt=""
                                    />
                                  </div>
                                  <div className="right-menu-tab pl-4 text-[#3b4356]">
                                    <div className="title-txt text-left 2xl:text-[16px] lg:text-[14px] text-[14px]">
                                      Drop-down
                                    </div>
                                    <div className="title-desc text-[12px]">
                                      Select a single answer from a list of
                                      options
                                    </div>
                                  </div>
                                </button>
                              </li>
                              <li className="transition-[0.8s] hover:transition-[0.8s] hover:bg-[#e6ebf1] 2xl:p-[15px] lg:p-[10px] p-[7px]">
                                <button
                                  className="flex 2xl:items-center lg:items-start items-start"
                                  onClick={() => addQuestion(true, 5)}
                                >
                                  <div className="icon-left">
                                    <img
                                      src="/dropdown.jpg"
                                      className="min-h-[70px] min-w-[70px] max-h-[70px] max-w-[70px]"
                                      alt=""
                                    />
                                  </div>
                                  <div className="right-menu-tab pl-4 text-[#3b4356]">
                                    <div className="title-txt text-left 2xl:text-[16px] lg:text-[14px] text-[14px]">
                                      Multi-Select Drop-down
                                    </div>
                                    <div className="title-desc text-[12px]">
                                      Select a multiple answer from a list of
                                      options
                                    </div>
                                  </div>
                                </button>
                              </li>
                              <li className="hover:bg-[#e6ebf1] p-[15px]">
                                <button
                                  className="flex 2xl:items-center lg:items-start items-start"
                                  onClick={() => addQuestion(true, 2)}
                                >
                                  <div className="icon-left">
                                    <img
                                      src="/radio.jpg"
                                      className="min-h-[70px] min-w-[70px] max-h-[70px] max-w-[70px]"
                                      alt=""
                                    />
                                  </div>
                                  <div className="right-menu-tab pl-4 text-[#3b4356]">
                                    <div className="title-txt text-left 2xl:text-[16px] lg:text-[14px] text-[14px]">
                                      Radio Buttons
                                    </div>
                                    <div className="title-desc text-[12px]">
                                      Select a single answer from a list of
                                      options
                                    </div>
                                  </div>
                                </button>
                              </li>
                              <li className="hover:bg-[#e6ebf1] p-[15px]">
                                <button
                                  className="flex 2xl:items-center lg:items-start items-start"
                                  onClick={() => addQuestion(true, 3)}
                                >
                                  <div className="icon-left">
                                    <img
                                      src="/checkbox.jpg"
                                      className="min-h-[70px] min-w-[70px] max-h-[70px] max-w-[70px]"
                                      alt=""
                                    />
                                  </div>
                                  <div className="right-menu-tab pl-4 text-[#3b4356]">
                                    <div className="title-txt text-left 2xl:text-[16px] lg:text-[14px] text-[14px]">
                                      Checkboxes
                                    </div>
                                    <div className="title-desc text-[12px]">
                                      Select multiple answer from a list of
                                      options
                                    </div>
                                  </div>
                                </button>
                              </li>
                            </ul>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Feedback Questions End */}
      {/* Partnership Start */}

      <div className="bg-white mt-12">
        <div className="heading-part px-7 py-4 border-b">
          <div className="flex items-center justify-between">
            <h4 className="small-title font-bold theme-color">Partnership</h4>
          </div>
        </div>
        <div className="px-8 py-7">
          <div className="grid grid-cols-12 gap-6">
            <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
              <div className="grid grid-cols-12 gap-6">
                {/* Section Map Start */}
                {sectionArr.map((section, sectionIndex) => {
                  return (
                    <div
                      className="2xl:col-span-12 lg:col-span-12 col-span-12 "
                      key={sectionIndex}
                    >
                      <div className="border-b-2 border-dashed pb-10">
                        <div className="grid grid-cols-12 gap-6">
                          <div className="2xl:col-span-6 lg:col-span-7 col-span-12">
                            <label
                              className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                              htmlFor="address"
                            >
                              Section Name{" "}
                              <span className="text-[#C00000]">*</span>
                            </label>
                            <input
                              id="address"
                              className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                              type="text"
                              name="sectionName"
                              value={section.sectionName}
                              onChange={(event) =>
                                handleChange(event, "Section", sectionIndex)
                              }
                            // disabled={
                            //   eventTimeSlot.isSGTech === true ? true : false
                            // }
                            //   onBlur={() => props.validateField("resourceName")}
                            />
                            <div>
                              {" "}
                              <ValidationText
                              // error={props.validState.error.resourceName}
                              />{" "}
                            </div>
                          </div>
                          <div className="2xl:col-span-6 lg:col-span-7 col-span-12 flex justify-end mt-8">
                            <div className="flex flex-col">
                              <button
                                className="px-10 py-3 btn-red text-xl border text-white"
                                onClick={() =>
                                  addPartners(
                                    section.eventSectionPartners,
                                    sectionIndex
                                  )
                                }
                              >
                                <div className="flex items-center font-semibold">
                                  <span className="mr-3">
                                    <img src="../plus-round-icon.svg" alt="" />
                                  </span>
                                  <span className="">Add Partner</span>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-12 gap-6 mt-10">
                          <div className="col-span-12 flex justify-start">
                            <button
                              className="px-10 py-3 btn-lighblue text-xl border text-white"
                              onClick={() => deleteSection(sectionIndex)}
                            >
                              <div className="flex items-center font-semibold">
                                <span className="mr-3">
                                  <img src="../minus-round-icon.svg" alt="" />
                                </span>
                                <span className="">Delete Section</span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Partner map Start */}

                      <div className="grid grid-cols-12 gap-6 border-b-2 border-dashed py-10">
                        {section.eventSectionPartners.map(
                          (partner, partnerIndex) => {
                            return (
                              <div
                                className="2xl:col-span-3 lg:col-span-3 col-span-12"
                                key={partnerIndex}
                              >
                                <div className="partner-logo-sec">
                                  <ImageCropperWithUpload
                                    key={`EventPartner_S/${sectionIndex}_P/${partnerIndex}`}
                                    keyId={`EventPartnerImg_S/${sectionIndex}_P/${partnerIndex}`}
                                    height={226}
                                    width={350}
                                    isCheckValidation={true}
                                    MaxFileSize={2097152}
                                    MaxFileSizeMessage={"2MB"}
                                    onSaveImage={(file, id, imageIdentity) =>
                                      saveMainImage(
                                        file,
                                        id,
                                        imageIdentity,
                                        sectionIndex,
                                        partnerIndex
                                      )
                                    }
                                    imageIdentity={"\\Events\\EventPartners"}
                                    intitImage={partner.partnerLogo}
                                    aspactRatio={350 / 226}
                                    uploadBtn={"Upload Partner Logo"}
                                  />
                                </div>
                                <div className="partner-desc-sec mt-5">
                                  <div className="grid grid-cols-12 gap-6">
                                    <div className="col-span-12">
                                      <label
                                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                        htmlFor="address"
                                      >
                                        Name of Partner
                                        <span className="text-[#C00000]">*</span>
                                      </label>
                                      <input
                                        id="address"
                                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                        type="text"
                                        name="partnerName"
                                        value={partner.partnerName}
                                        onChange={(event) =>
                                          handleChange(
                                            event,
                                            "Partner",
                                            sectionIndex,
                                            partnerIndex
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="col-span-12">
                                      <label
                                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                        htmlFor="address"
                                      >
                                        Website URL
                                        <span className="text-[#C00000]">*</span>
                                      </label>
                                      <input
                                        id="address"
                                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                        type="text"
                                        name="websiteURL"
                                        value={partner.websiteURL}
                                        onChange={(event) =>
                                          handleChange(
                                            event,
                                            "Partner",
                                            sectionIndex,
                                            partnerIndex
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="grid grid-cols-12 gap-6 mt-10 mb-4">
                                    <div className="col-span-12 flex justify-center">
                                      <button
                                        className="px-10 py-3 btn-lighblue text-xl border text-white"
                                        onClick={() =>
                                          deletePartner(
                                            section.eventSectionPartners,
                                            sectionIndex,
                                            partnerIndex
                                          )
                                        }
                                      >
                                        <div className="flex items-center font-semibold">
                                          <span className="mr-3">
                                            <img
                                              src="../minus-round-icon.svg"
                                              alt=""
                                            />
                                          </span>
                                          <span className="">Delete Partner</span>
                                        </div>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>

                      {/* Partner map End */}
                    </div>
                  );
                })}

                {/* Section Map End */}
              </div>
              <div className="grid grid-cols-12 gap-6 mt-7">
                <div className="col-span-12 flex justify-end">
                  <button
                    className="px-10 py-3 btn-red text-xl border text-white"
                    onClick={() => addSection()}
                  >
                    <div className="flex items-center font-semibold">
                      <span className="mr-3">
                        <img src="../plus-round-icon.svg" alt="" />
                      </span>
                      <span className="">Add Section</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Partnership End */}

      {/* Notification To Attendees Start */}
      <div className="bg-white mt-12">
        <div className="heading-part px-8 py-4 border-b">
          <div className="flex items-center justify-between">
            <h4 className="small-title font-bold theme-color">
              Notification To Attendees (Email)
            </h4>
          </div>
        </div>
        <div className="px-9 py-7">
          <div className="grid grid-cols-12 gap-6">
            <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
              <div className="question-sec">
                <div className="flex items-center justify-between">
                  <label
                    className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                    htmlFor="default"
                  >
                    After completing their booking, the customer will get a
                    confirmation email. Below is where you type text for this
                    email. E.g. include information about parking / where to
                    meet / finish time / what to bring / what to wear etc.
                    {/* <span className="text-[#C00000]">*</span> */}
                  </label>
                </div>
                <textarea
                  className="w-full h-[150px]"
                  name="notificationEmail"
                  onChange={(e) => handleChange(e, "OtherDetails")}
                  value={otherDetails.notificationEmail}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Notification To Attendees End */}

      {/* footer Button Start*/}
      <div className="grid grid-cols-12 gap-6">
        <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
          <div className="flex flex-wrap justify-between space-x-10 mt-12">
            <div>
              <button
                className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white hover:text-white"
                onClick={() => {
                  props.backFromOthers();
                  props.executeScroll();
                }}
              >
                {" "}
                Back
              </button>
              {/* <button className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white">Back</button> */}
            </div>
            <div>
              {isLoading ? (
                <ButtonLoader />
              ) : (
                <button
                className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white"
                onClick={() => {
                  addEditOtherEntryDetails();
                  props.executeScroll();
                }}
              // onClick={() => {
              //   props.isValidateAllBasicInformation();
              //   goToViolation("ErrorList");
              // }}
              // disabled={props.isLoaderEnabled}
              >
                Submit
              </button>
              )}
              {/* {props.resource.isPage ? ( */}
              {/* <button
                className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white"
                onClick={() => {
                  addEditOtherEntryDetails();
                  props.executeScroll();
                }}
              // onClick={() => {
              //   props.isValidateAllBasicInformation();
              //   goToViolation("ErrorList");
              // }}
              // disabled={props.isLoaderEnabled}
              >
                Submit
              </button> */}
            </div>
          </div>
        </div>
      </div>
      {/* footer Button End*/}
    </div>
  );
};

export default Others;
