import React from "react";

function EventsBookings() {
  return (
    <div className="w-full">
      <div className="pb-10">
        <div className="grid grid-cols-12 gap-6 w-full">
          <div className="2xl:col-span-10 lg:col-span-9 col-span-12 2xl:pt-7 lg:mt-4 items-center">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                <div className="flex items-center justify-between">
                  <div className="left-side w-[50%]">
                    <h2 className="text-xl font-bold text-[#c00000] 2xl:mb-3">
                      Workshop Name
                    </h2>
                    <h2 className="text-xl theme-color 2xl:mb-3">
                      Trainer Company Name
                    </h2>
                  </div>
                  <div className="right-side w-[50%]">
                    <h2 className="text-xl theme-color 2xl:mb-3">
                      3 Jan 2022 (Mon), 9:00AM - 1:00PM
                    </h2>
                    <h2 className="text-xl font-bold text-[#c00000] 2xl:mb-3">
                      <div>+ View session details</div>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="2xl:col-span-2 lg:col-span-3 col-span-12">
            <div className="relative flex">
              <div className="right-status-label absolute 2xl:top-2 2xl:right-[-20px] lg:top-[-20px] lg:right-[-28px]">
                <span className="text-xl text-white font-semibold bg-[#7a9763] px-12 py-3">
                  Booked
                </span>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-2 border-t border-t-[#c9c9c9]"></hr>
        <div className="grid grid-cols-12 gap-6 w-full items-center">
          <div className="2xl:col-span-10 lg:col-span-9 col-span-12 2xl:pt-7 lg:mt-4 items-center">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                    <div className="flex items-center">
                      <h2 className="text-xl theme-color w-[50%]">
                        Booking Ref:
                      </h2>
                      <h2 className="text-xl theme-color w-[50%]">
                        SGTECH1020
                      </h2>
                    </div>
                    <div className="flex items-center">
                      <h2 className="text-xl theme-color w-[50%]">
                        Booking Date:
                      </h2>
                      <h2 className="text-xl theme-color w-[50%]">
                        01 Jan 2022 (Sat)
                      </h2>
                    </div>
                    <div className="flex items-center">
                      <h2 className="text-xl theme-color w-[50%]">Type:</h2>
                      <h2 className="text-xl theme-color w-[50%]">Workshop</h2>
                    </div>
                    <div className="flex items-center">
                      <h2 className="text-xl theme-color w-[50%]">
                        Optional Add-on:
                      </h2>
                      <h2 className="text-xl theme-color w-[50%]">Textbook</h2>
                    </div>
                  </div>
                  <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                    <div className="flex items-center">
                      <h2 className="text-xl theme-color w-[50%]">
                        Original Price:
                      </h2>
                      <h2 className="text-xl theme-color w-[50%]">$349.40</h2>
                    </div>
                    <div className="flex items-center">
                      <h2 className="text-xl theme-color w-[50%]">Discount:</h2>
                      <h2 className="text-xl theme-color w-[50%]">N.A</h2>
                    </div>
                    <div className="flex items-center">
                      <h2 className="text-xl theme-color w-[50%]">
                        Price Paid:
                      </h2>
                      <h2 className="text-xl theme-color w-[50%]">$349.40</h2>
                    </div>
                    <div className="flex items-center">
                      <h2 className="text-xl theme-color w-[50%]">
                        Attendee Name:
                      </h2>
                      <h2 className="text-xl theme-color w-[50%]">John Low</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="2xl:col-span-2 lg:col-span-3 col-span-12">
            <div className="right-btn w-full mt-5">
              <div className="flex items-center justify-end mb-5">
                <button className="2xl:px-7 lg:px-5 pt-3 py-2 btn-red-border text-xl border text-[#C00000]">
                  <span className="text-xl text-[#c00000] font-semibold tracking-normal underline">
                    View Invoice
                  </span>
                </button>
              </div>
              <div className="flex items-center justify-end">
                <button className="2xl:px-7 lg:px-5 pt-3 py-2 btn-red-border text-xl border text-[#C00000]">
                  <span className="text-xl text-[#c00000] font-semibold tracking-normal underline">
                    View Receipt
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default EventsBookings;