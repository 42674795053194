import React, { useEffect, useState } from "react";
import ModalBasic from "../../component/FormModal";
import SectionWithBorder from "../../../components/membership/membershipTaskList/SectionWithBorder";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import moment from "moment";
import ValidationText from "../../../utils/validation/ValidationText";
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";
import MultiSelectDropdown from "../../../components/inputs/MultiSelectDropdown";
import Option from "../../../components/dropdown/multiSelectDropdownOption/MultiSelectOption";
import TextInput from "../../../components/UIComponent/TextInput";



const UpgradeChangeRequestTaskListProcess = (props) => {
  const handleChangeInPriceTable = (value, name, year, tableName) => {
    props.handleChangeInPriceTable(value, name, year, tableName);
  };

  const [terminationDateArr, setTerminationDateArr] = useState([]);
  const [terminationTypeArr, setTerminationTypeArr] = useState([]);

  const updateTerminationDurationArr = () => {
    const subscriptionEndDate = props.taskDetails.subscriptionEndDate;
    const formattedEndDate = subscriptionEndDate ? moment(subscriptionEndDate).format('DD MMMM YYYY') : "Membership End Date";
    const newArr = [
      { id: 1, name: "Immediate" },
      { id: 2, name: subscriptionEndDate ? `Membership End Date (${formattedEndDate})` : "Membership End Date" }
    ];
    setTerminationDateArr(newArr);
  };

  const updateTerminationTypeArr = () => {
    // const isMTORegistry = props.taskDetails.isMTORegistry;
    let newArr = [
      { id: 3, name: "Membership Termination" },
      { id: 4, name: "MTO Termination" }
    ];
    // if (isMTORegistry) {
    //   newArr.push({ id: 4, name: "MTO Termination" });
    // }
    setTerminationTypeArr(newArr);
  };

  useEffect(() => {
    updateTerminationDurationArr();
    updateTerminationTypeArr();
  }, [props.taskDetails.subscriptionEndDate, props.taskDetails.isMTORegistry]);

  return (
    <ModalBasic
      id="add-edit-modal"
      className="membership-add-edit-modal"
      modalOpen={props.setAddeditModalOpen}
      setModalOpen={props.setModalOpen}
      title="Upgrade/Change Request TaskList Process"
    >

      <div className="2xl:px-14 lg:px-3 pt-4">
        <div className="">
          {/* Account Information Section */}
          <SectionWithBorder title="Account Information">
            <div className="">
              <div className="grid grid-cols-12 gap-6 w-full items-center pt-7 pb-0">
                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >  Membership Type <span className="text-[#C00000]">*</span>
                      </label>
                      <DropdownSelect
                        drpIdentity={"MembershipType"}
                        optionArray={props.membershipType}
                        value={props.taskDetails.membershipTypeId ? props.taskDetails.membershipTypeId : 0}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                        Billing Category <span className="text-[#C00000]"> *</span>
                      </label>
                      <DropdownSelect
                        drpIdentity={"BillingCategories"}
                        optionArray={props.billingCategories}
                        value={props.taskDetails.billingCategoryId ? props.taskDetails.billingCategoryId : 1}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30 "
                        htmlFor="default"
                      >
                        Subscription Start Date
                        <span className="text-[#C00000]"> *</span>
                      </label>
                      <div className="grid grid-cols-12 gap-6 items-center w-full">
                        <div className=" 2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                          <input
                            id="default"
                            className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg cursor-not-allowed bg-[#e9e9ea]  border-[#757575]"
                            type="date"
                            name="subscriptionStartDate"
                            max={moment(new Date()).format("yyyy-MM-DD")}
                            value={props.taskDetails.subscriptionStartDate ? moment(
                              props.taskDetails.subscriptionStartDate
                            ).format("yyyy-MM-DD") : ""}
                            onChange={(event) => props.handleChange(event)}
                            disabled={true}
                          />
                          <div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                        Subscription End Date
                      </label>
                      <div className="grid grid-cols-12 gap-6 items-center w-full">
                        <div className=" 2xl:col-span-12 lg:col-span-12 col-span-12 w-full ">
                          <input
                            id="default"
                            name="subscriptionEndDate"
                            className={`date-pickers form-input rounded-none w-full py-3 text-lg cursor-not-allowed bg-[#e9e9ea]  border-[#757575]`}
                            type="date"
                            value={props.taskDetails.subscriptionEndDate ? moment(
                              props.taskDetails.subscriptionEndDate
                            ).format("yyyy-MM-DD") : ""}
                            onChange={(event) => props.handleChange(event)}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable flex w-full font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                        Last Updated Date
                      </label>
                      <div className="grid grid-cols-12 gap-6 items-center w-full">
                        <div className=" 2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                          <input
                            id="default"
                            name="lastUpdatedDate"
                            className={`date-pickers form-input rounded-none w-full py-3 text-lg cursor-not-allowed bg-[#e9e9ea]  border-[#757575]`}
                            type="date"
                            value={props.taskDetails.lastUpdatedDate ? props.taskDetails.lastUpdatedDate : new Date().toISOString().split('T')[0]}
                            onChange={(event) => props.handleChange(event)}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12 flex flex-col items-end">
                      <label
                        className="filter-lable flex w-full font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                      </label>
                      <button
                        className={`py-2 w-[350px] btn btn-red text-lg text-white font-bold`}
                        onClick={() => props.updateCorporateMemberUpdate(props.taskDetails.memberId)}
                      >
                        View Corporate Information
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SectionWithBorder>

          {/* Change Company Name and Address Section */}
          {props.requestTypeId && props.requestTypeId === 1 || props.requestTypeId === 2 ?
            <SectionWithBorder title={props.requestTypeId === 1 ? "Change Company Name" : "Change Registered Address"}>

              {props.requestTypeId === 1 ? (
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 mt-5">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                      <h2 className="lg:text-[16px] 2xl:text-[16px] xl:text-[16px] md:text-[16px] sm:text-[16px] mobile-14 text-[#292929] font-bold mb-3">
                        Old Company Name:{" "}
                        <span className="text-[#292E8F]">
                          {props.taskDetails.oldCompanyName}
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 mt-5">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                      <h2 className="lg:text-[16px] font-bold 2xl:text-[16px] xl:text-[16px] md:text-[16px] sm:text-[16px] mobile-14] mb-3">
                        Old Registered Address:{" "}
                      </h2>
                    </div>
                    <>

                      <div className="xl:col-span-6 md:col-span-6 col-span-12">
                        <div className="grid grid-cols-12 gap-6">
                          <div className="xl:col-span-12 md:col-span-12 col-span-12">
                            <label
                              className="filter-lable flex w-full font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                              htmlFor="default"
                            >
                              Block No & Street Name
                            </label>
                            <div className="grid grid-cols-12 gap-6 items-center w-full">
                              <div className=" 2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                                <input
                                  id="default"
                                  name="oldStreetName"
                                  className={`date-pickers form-input rounded-none w-full py-3 text-lg cursor-not-allowed bg-[#e9e9ea]  border-[#757575]`}
                                  type="text"
                                  value={props.taskDetails.oldStreetName}
                                  // onChange={(event) => props.handleChange(event)}
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="xl:col-span-6 md:col-span-6 col-span-12">
                        <div className="grid grid-cols-12 gap-6">
                          <div className="xl:col-span-12 md:col-span-12 col-span-12">
                            <label
                              className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                              htmlFor="default"
                            >  Unit Number & Building Name{" "} <span className="text-[#C00000]">*</span>
                            </label>

                            <input
                              id="default"
                              name="oldBuildingName"
                              className={`date-pickers form-input rounded-none w-full py-3 text-lg cursor-not-allowed bg-[#e9e9ea]  border-[#757575]`}
                              type="text"
                              value={
                                props.taskDetails.oldNoUnitNumber
                                  ? ""
                                  : props.taskDetails.oldBuildingName
                              }
                              // onChange={(event) => props.handleChange(event)}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12 padding-left-0">
                        <div className="flex items-start register-desc 2xl:mt-5 lg:mt-5 md:mt-5 sm:mt-5 mt-3" style={{ alignItems: "center" }}>
                          <input
                            id=""
                            name="oldNoUnitNumber"
                            type="checkbox"
                            className="cursor-pointer h-4 w-4 text-[#f93549] focus:ring-[#f93549] rounded check-box-custom"
                            value={
                              props.taskDetails.oldNoUnitNumber
                                ? props.taskDetails.oldNoUnitNumber
                                : false
                            }
                            disabled={true}
                            onChange={(e) => props.handleChangeCheckBox(e)}
                            checked={props.taskDetails.oldNoUnitNumber ? props.taskDetails.oldNoUnitNumber : false}
                          />
                          <label
                            htmlFor="isRequireQuotation"
                            className="ml-5 block text-[#212529] text-justify inner-title-font-small-button mobile-14"
                          >
                            No Unit Number
                          </label>
                        </div>
                      </div>


                      <div className="xl:col-span-6 md:col-span-6 col-span-12">
                        <div className="grid grid-cols-12 gap-6">
                          <div className="xl:col-span-12 md:col-span-12 col-span-12">
                            <label
                              className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                              htmlFor="default"
                            >  Postal Code<span className="text-[#C00000]">*</span>
                            </label>

                            <input
                              id="default"
                              name="oldPostalCode"
                              className={`date-pickers form-input rounded-none w-full py-3 text-lg cursor-not-allowed bg-[#e9e9ea]  border-[#757575]`}
                              type="number"
                              value={props.taskDetails.oldPostalCode}
                              // onChange={(event) => props.handleChange(event)}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="xl:col-span-6 md:col-span-6 col-span-12">
                        <div className="grid grid-cols-12 gap-6">
                          <div className="xl:col-span-12 md:col-span-12 col-span-12">
                            <label
                              className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                              htmlFor="default"
                            >  Country <span className="text-[#C00000]">*</span>
                            </label>
                            <DropdownSelect
                              drpIdentity={"oldCountry"}
                              optionArray={props.oldCountry}
                              // setFilterParameters={props.setFilterParameters}
                              value={153}
                              disabled="true"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              )}
              {props.requestTypeId === 0 ? null : (
                <>
                  {props.requestTypeId === 1 ? (
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 custon-padding-add">
                      <div className="grid grid-cols-12 gap-6">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                          <h2 className="filter-lable flex w-full font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30">
                            New Company Name
                          </h2>
                          <TextInput
                            placeholder=""
                            type="text"
                            name="newCompanyName"
                            value={props.taskDetails.newCompanyName}
                            identity="newCompanyName"
                            handleChange={props.handleChange}
                          // disabled={props.isEmployeeModalEditable !== true}
                          // isCurserAllowed={props.isEmployeeModalEditable !== true}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 mt-5">
                      <div className="container mt-8">
                        <hr
                          style={{
                            border: "none",
                            borderTop: "2px solid red",
                            margin: "20px 0",
                          }}
                        />
                      </div>
                      <div className="grid grid-cols-12 gap-6">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                          <h2 className="lg:text-[16px] font-bold 2xl:text-[16px] xl:text-[16px] md:text-[16px] sm:text-[16px] mobile-14] mb-3">
                            New Registered Address:{" "}
                          </h2>
                        </div>
                        <>

                          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                              <div className="col-span-12">
                                <h2 className="filter-lable flex w-full font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30">
                                  Block No & Street Name{" "}<span className="text-[#c00000]">*</span>
                                </h2>
                                <TextInput
                                  placeholder=""
                                  type="text"
                                  name="newStreetName"
                                  value={props.taskDetails.newStreetName}
                                  handleChange={props.handleChange}
                                  identity="RegisteredStreetName"
                                  validateField={() =>
                                    props.validateField("newStreetName")
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                              <div className="col-span-12">
                                <h2 className="filter-lable flex w-full font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30">
                                  Unit Number & Building Name<span className="text-[#c00000]">*</span>
                                </h2>
                                <TextInput
                                  placeholder=""
                                  type="text"
                                  name="newBuildingName"
                                  value={
                                    props.taskDetails.newNoUnitNumber
                                      ? ""
                                      : props.taskDetails.newBuildingName
                                  }
                                  handleChange={props.handleChange}
                                  identity="RegisteredBuildingName"
                                  validateField={() =>
                                    props.validateField("newBuildingName")
                                  }
                                  disabled={
                                    props.taskDetails.newNoUnitNumber
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12 padding-left-0">
                            <div className="flex items-start register-desc 2xl:mt-5 lg:mt-5 md:mt-5 sm:mt-5 mt-3 " style={{ alignItems: "center" }}>
                              <input
                                id=""
                                name="newNoUnitNumber"
                                type="checkbox"
                                className="cursor-pointer h-4 w-4 text-[#f93549] focus:ring-[#f93549] rounded check-box-custom"
                                value={
                                  props.taskDetails.newNoUnitNumber
                                    ? props.taskDetails.newNoUnitNumber
                                    : false
                                }
                                onChange={(e) => props.handleChangeCheckBox(e)}
                                checked={
                                  props.taskDetails.newNoUnitNumber
                                    ? props.taskDetails.newNoUnitNumber
                                    : false
                                }
                              />
                              <label
                                htmlFor="isRequireQuotation"
                                className="ml-5 block text-[#212529] text-justify inner-title-font-small-button mobile-14"
                              >
                                No Unit Number
                              </label>
                            </div>
                          </div>

                          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                              <div className="col-span-12">
                                <h2 className="filter-lable flex w-full font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30">

                                  Postal Code<span className="text-[#c00000]">*</span>
                                </h2>
                                <TextInput
                                  placeholder=""
                                  type="number"
                                  name="newPostalCode"
                                  value={props.taskDetails.newPostalCode}
                                  handleChange={props.handleChange}
                                  identity="RegisteredPostalCode"
                                  validateField={() =>
                                    props.validateField("newPostalCode")
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts padding-left-0">
                            <div className="grid grid-cols-12 gap-6">
                              <div className="col-span-12">
                                <h2 className="filter-lable flex w-full font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30">
                                  Country<span className="text-[#c00000]">*</span>
                                </h2>
                                <DropdownSelect
                                  drpIdentity={"newCountry"}
                                  optionArray={props.oldCountry}
                                  setFilterParameters={props.setFilterParameters}
                                  value={props.taskDetails.newCountry}
                                // disabled="true"
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                  )}
                  <div className="xl:col-span-6 lg:col-span-6 col-span-12" style={{ marginTop: "20px" }}>
                    <div className="grid-cols-12 gap-6 w-full ">
                      <div className="col-span-12">
                        <div className="">
                          <label
                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                            htmlFor="default"
                          >
                            Latest ACRA
                            <span className="text-[#C00000]">*</span>{" "}
                          </label>
                          <div className="flex item-center">
                            <div className="file-uploaded-txt flex items-center">
                              <button
                                className={`text-xl font-bold text-white bg-[#6C757D] w-[200px] bg-blue-500 p-4 rounded-md hover:bg-[#8C959D] focus:outline-none`}
                                onClick={() => props.getInvoiceReceiptPdfByBookingId(props.taskDetails.reqId, "ChangeCompanyNameAddressACRA")}
                              >
                                Download
                              </button>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </>
              )}
            </SectionWithBorder>
            :
            null
          }


          {/* Termination Section */}
          {props.requestTypeId && props.requestTypeId === 3 || props.requestTypeId === 4 ?
            <SectionWithBorder title="Request Termination">
              <div className="">
                <div className="grid grid-cols-12 gap-6  w-full items-center pt-7 ">
                  <div className="xl:col-span-12 md:col-span-12 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-6 md:col-span-6 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="default"
                        >
                          Type of Termination
                          <span className="text-[#C00000]">*</span>{" "}
                        </label>
                        <DropdownSelect
                          drpIdentity="TerminationType"
                          optionArray={terminationTypeArr}
                          setFilterParameters={(id, drpIdentity) => props.setTerminationFilterParameters(id, drpIdentity)}
                          value={props.taskDetails.changeReqTypeId ? props.taskDetails.changeReqTypeId : 0}
                          disabled={true}
                        />
                      </div>
                      <div className="xl:col-span-6 md:col-span-6 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="default"
                        >
                          Termination Date
                          <span className="text-[#C00000]">*</span>{" "}
                        </label>
                        <DropdownSelect
                          drpIdentity="TerminationDate"
                          optionArray={terminationDateArr}
                          setFilterParameters={(id, drpIdentity) => props.setTerminationFilterParameters(id, drpIdentity)}
                          value={props.taskDetails.terminationDate}
                        />
                      </div>
                      <div className="xl:col-span-12 md:col-span-6 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="default"
                        >
                          Reason for Termination
                          <span className="text-[#C00000]">*</span>{" "}
                        </label>
                        <textarea
                          className="w-full h-32 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-red-600"
                          type="text"
                          name="terminationReason"
                          value={props.taskDetails.terminationReason}
                          onChange={(event) => props.handleChange(event)}
                        // onBlur={() => props.validateTermination("terminationReason")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SectionWithBorder>
            :
            null
          }

          {/* Upgrade Membership Section */}
          {props.requestTypeId && props.requestTypeId === 5 ?
            <SectionWithBorder title="Upgrade Membership">
              <div className="grid grid-cols-12 gap-6  w-full items-center pt-7 ">
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                        First Name

                      </h2>
                      <div className="flex items-center left-border-none">
                        <div className="w-[130px]">
                          <DropdownSelect
                            drpIdentity={"NamePrefix"}
                            optionArray={props.mrMrs}
                            //setFilterParameters={props.setFilterParameters}
                            value={props.ceoDetails.mrMrsId}
                            disabled={true}
                          />
                        </div>
                        <TextInput
                          placeholder=""
                          type="text"
                          name="firstName"
                          value={props.ceoDetails.firstName}
                          disabled={true}
                          isCurserAllowed={true}
                        />
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                        Last Name

                      </h2>
                      <TextInput
                        placeholder=""
                        type="text"
                        name="lastName"
                        value={props.ceoDetails.lastName}
                        disabled={true}
                        isCurserAllowed={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                        Job Position
                      </h2>
                      <TextInput
                        placeholder=""
                        type="text"
                        name="designation"
                        value={props.ceoDetails.designation}
                        isCurserAllowed={true}
                        disabled={true} />
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                        Email Address
                      </h2>
                      <div className="dropdwn-input flex items-center">
                        <TextInput
                          placeholder=""
                          type="text"
                          name="emailAddress"
                          value={props.ceoDetails.emailAddress}
                          isCurserAllowed={true}
                          disabled={true} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                        Business Mobile
                      </h2>
                      <TextInput
                        placeholder=""
                        type="text"
                        name="bussinessMobile"
                        value={props.ceoDetails.bussinessMobile}
                        isCurserAllowed={true}
                        disabled={true} />
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                        Office DID
                      </h2>
                      <TextInput
                        placeholder=""
                        type="text"
                        name="officeDID"
                        value={props.ceoDetails.officeDID}
                        isCurserAllowed={true}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                        Previous Years Of Cumulative Experiences in Logistics Service
                      </h2>
                      <TextInput
                        placeholder=""
                        name="ceoCurrentExp"
                        value={props.taskDetails.ceoCurrentExp}
                        identity="ceoCurrentExp"
                        handleChange={props.handleChange}
                        isCurserAllowed={true}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <h2 className="filter-lable flex w-full font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30">
                        Updated Years Of Cumulative Experiences in Logistics Service <span className="text-[#c00000]">*</span>
                      </h2>
                      <TextInput
                        placeholder=""
                        name="ceoNewExp"
                        value={props.taskDetails.ceoNewExp}
                        identity="ceoNewExp"
                        handleChange={props.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                        Requested Membership Type
                      </h2>
                      <TextInput
                        placeholder=""
                        type="text"
                        name="newData"
                        value={props.taskDetails.newData === "1" ? "Ordinary Member" : "Qualifying Member"}
                        disabled={true}
                        isCurserAllowed={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                        Latest ACRA business Profile
                      </h2>
                      <div className="flex item-center">

                        <div className="file-uploaded-txt flex items-center">
                          <button
                            className={`text-xl font-bold text-white bg-[#6C757D] w-[200px] bg-blue-500 p-4 rounded-md hover:bg-[#8C959D] focus:outline-none`}
                            onClick={() => props.getInvoiceReceiptPdfByBookingId(props.taskDetails.reqId, "UpgradeLatestACRA")}
                          >
                            Download
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SectionWithBorder>
            :
            null
          }
          {props.requestTypeId && props.requestTypeId === 6 ?
            <SectionWithBorder title="Change Key Position Information">
              <div className="grid grid-cols-12 gap-6  w-full items-center pt-5 ">
                <h3 className="text-xl font-bold text-[#212529] mb-4 col-span-12">
                  Change Key Position Type: {props.taskDetails.contactRoleName}
                </h3>
                <h4 className="text-2xl font-bold theme-color col-span-12">
                  Current Person
                </h4>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts padding-lr-0">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <h2 className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30">
                        First Name
                        <span className="text-[#c00000]">*</span>
                      </h2>
                      <div className="flex items-center left-border-none">
                        <div className="w-[130px]">
                          <DropdownSelect
                            drpIdentity={"NamePrefix"}
                            optionArray={props.mrMrs}
                            //setFilterParameters={props.setFilterParameters}
                            value={props.taskDetails.mrMrsId}
                            disabled={true}
                          />
                        </div>
                        <TextInput
                          placeholder=""
                          type="text"
                          name="firstName"
                          value={props.taskDetails.firstName}
                          disabled={true}
                          isCurserAllowed={true}
                        />
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts padding-lr-0">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <h2 className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30">
                        Last Name
                        <span className="text-[#c00000]">*</span>
                      </h2>
                      <TextInput
                        placeholder=""
                        type="text"
                        name="lastName"
                        value={props.taskDetails.lastName}
                        disabled={true}
                        isCurserAllowed={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts padding-lr-0">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <h2 className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30">
                        Job Position <span className="text-[#c00000]">*</span>
                      </h2>
                      <TextInput
                        placeholder=""
                        type="text"
                        name="designation"
                        value={props.taskDetails.designation}
                        isCurserAllowed={true}
                        disabled={true} />
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts padding-lr-0">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <h2 className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30">
                        Email Address  <span className="text-[#c00000]">*</span>
                      </h2>
                      <div className="dropdwn-input flex items-center">
                        <TextInput
                          placeholder=""
                          type="text"
                          name="emailAddress"
                          value={props.taskDetails.emailAddress}
                          isCurserAllowed={true}
                          disabled={true} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts padding-lr-0">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <h2 className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30">
                        Business Mobile<span className="text-[#c00000]">*</span>
                      </h2>
                      <div className="dropdwn-input flex items-center">
                        <TextInput
                          placeholder=""
                          type="text"
                          name="newBussinessMobile"
                          value={props.taskDetails.bussinessMobile}
                          isCurserAllowed={true}
                          disabled={true} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts padding-lr-0">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <h2 className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30">
                        Office DID<span className="text-[#c00000]">*</span>
                      </h2>
                      <div className="dropdwn-input flex items-center">
                        <TextInput
                          placeholder=""
                          type="text"
                          name="newOfficeDID"
                          value={props.taskDetails.officeDID}
                          isCurserAllowed={true}
                          disabled={true} />
                      </div>
                    </div>
                  </div>
                </div>


                {props.taskDetails.contactRoleId === 1 ?

                  <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts padding-lr-0">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-12">
                        <h2 className="filter-lable flex w-full font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30">
                          Years Of Cumulative Experiences in Logistics Service  <span className="text-[#c00000]">*</span>
                        </h2>
                        <div className="dropdwn-input flex items-center">
                          <TextInput
                            placeholder=""
                            identity="ceoNewExp"
                            type="text"
                            name="newExperienceYears"
                            value={props.taskDetails.experienceYears}
                            isCurserAllowed={true}
                            disabled={true} />
                        </div>
                      </div>
                    </div>
                  </div>
                  : null}
                <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12 padding-lr-0">
                  <div className="flield-sec">
                    <label className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30">
                      Subscribe to Email Circulars <span className="text-[#F93549]">*</span>
                    </label>
                    {/* <TextInput
                                placeholder=""
                                type="text"
                                name="existingemailSubscriptionLabels"
                                value={data1.emailSubscriptionLabels ? data1.emailSubscriptionLabels : ''}
                                disabled={true} /> */}
                    <MultiSelectDropdown
                      drpIdentity="emailSubscription"
                      options={props.emailSubscription}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{ Option, }}
                      onChange={props.handleChangeMultiDropdown}
                      allowSelectAll={true}
                      value={props.oldsubscriptionArray}
                      disabled={true}
                    />


                  </div>
                </div>


              </div>
              <div className="grid grid-cols-12 gap-6  w-full items-center pt-7 ">
                <h4 className="text-2xl font-bold theme-color col-span-12">
                  New Person
                </h4>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts padding-left-0">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <h2 className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30">
                        First Name
                        <span className="text-[#c00000]">*</span>
                      </h2>
                      <div className="flex items-center left-border-none">
                        <div className="w-[130px]">
                          <DropdownSelect
                            drpIdentity={"NamePrefix"}
                            optionArray={props.mrMrs}
                            //setFilterParameters={props.setFilterParameters}
                            value={props.taskDetails.newMrMrsId}
                            disabled={true}
                          />
                        </div>
                        <TextInput
                          placeholder=""
                          type="text"
                          name="firstName"
                          value={props.taskDetails.newFirstName}
                          disabled={true}
                          isCurserAllowed={true}
                        />
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts padding-lr-0">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <h2 className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30">
                        Last Name
                        <span className="text-[#c00000]">*</span>
                      </h2>
                      <TextInput
                        placeholder=""
                        type="text"
                        name="lastName"
                        value={props.taskDetails.newLastName}
                        disabled={true}
                        isCurserAllowed={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts padding-left-0">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <h2 className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30">
                        Job Position <span className="text-[#c00000]">*</span>
                      </h2>
                      <TextInput
                        placeholder=""
                        type="text"
                        name="designation"
                        value={props.taskDetails.newDesignation}
                        isCurserAllowed={true}
                        disabled={true} />
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts padding-lr-0">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <h2 className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30">
                        Email Address  <span className="text-[#c00000]">*</span>
                      </h2>
                      <div className="dropdwn-input flex items-center">
                        <TextInput
                          placeholder=""
                          type="text"
                          name="emailAddress"
                          value={props.taskDetails.newEmailAddress}
                          isCurserAllowed={true}
                          disabled={true} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts padding-left-0">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <h2 className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30">
                        Updated Business Mobile<span className="text-[#c00000]">*</span>
                      </h2>
                      <TextInput
                        placeholder=""
                        type="text"
                        name="newBussinessMobile"
                        value={props.taskDetails.newBussinessMobile}
                        handleChange={props.handleChange}
                      // isCurserAllowed={true}
                      // disabled={true} 
                      />
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts padding-lr-0">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <h2 className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30">
                        Updated Office DID <span className="text-[#c00000]">*</span>
                      </h2>
                      <TextInput
                        placeholder=""
                        type="text"
                        name="newOfficeDID"
                        value={props.taskDetails.newOfficeDID}
                        handleChange={props.handleChange}
                      // isCurserAllowed={true}
                      // disabled={true}
                      />
                    </div>
                  </div>
                </div>

                {props.taskDetails.contactRoleId === 1 ?
                  <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts padding-left-0">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-12">
                        <h2 className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30">
                          Updated Years Of Cumulative Experiences in Logistics Service <span className="text-[#c00000]">*</span>
                        </h2>
                        <TextInput
                          placeholder=""
                          name="newExperienceYears"
                          value={props.taskDetails.newExperienceYears}
                          identity="ceoNewExp"
                          handleChange={props.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  : null}
                <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12 padding-lr-0">
                  <div className="flield-sec">
                    <label className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30">
                      Updated Subscribe to Email Circulars <span className="text-[#F93549]">*</span>
                    </label>
                    {/* <TextInput
                                placeholder=""
                                type="text"
                                name="existingemailSubscriptionLabels"
                                value={data1.emailSubscriptionLabels ? data1.emailSubscriptionLabels : ''}
                                disabled={true} /> */}
                    <MultiSelectDropdown
                      drpIdentity="emailSubscription"
                      options={props.emailSubscription}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{ Option, }}
                      onChange={props.handleChangeMultiDropdown}
                      allowSelectAll={true}
                      value={props.subscriptionArray}
                    />


                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts padding-left-0">
                  <div className="grid-cols-12 gap-6 w-full ">
                    <div className="2xl:col-span-12 lg:col-span-12 col-span-12 ml-auto">
                      <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="default"
                        >
                          Latest Business Card
                          <span className="text-[#C00000]">*</span>{" "}
                        </label>
                        <div className="flex item-center">
                          <div className="file-uploaded-txt flex items-center">
                            <button
                              className={`text-xl font-bold text-white bg-[#6C757D] w-[200px] bg-blue-500 p-4 rounded-md hover:bg-[#8C959D] focus:outline-none`}
                              onClick={() => props.getInvoiceReceiptPdfByBookingId(props.taskDetails.contactInfoId, "BussinessCard")}
                            >
                              Download
                            </button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                        Latest ACRA business Profile
                        <span className="text-[#c00000]">*</span>
                      </h2>
                      <div className="flex item-center">

                        <div className="file-uploaded-txt flex items-center">
                          <button
                            className={`text-xl font-bold text-white bg-[#6C757D] w-[200px] bg-blue-500 p-4 rounded-md hover:bg-[#8C959D] focus:outline-none`}
                            onClick={() => props.getInvoiceReceiptPdfByBookingId(props.taskDetails.reqId, "UpgradeLatestACRA")}
                          >
                            Download
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </SectionWithBorder>
            :
            null
          }
          {/* //Council Approval */}
          <SectionWithBorder title="Council Approval">
            <div className="">
              <div className="grid grid-cols-12 gap-6 w-full items-center pt-3 pb-7 mt-5">
                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                  <label
                    className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                    htmlFor="default"
                  >
                    Approval Date{" "}
                    <span className="text-[#C00000]">*</span>
                  </label>
                  <input
                    id="default"
                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                    type="date"
                    name="approvalDate"
                    //max={moment(new Date()).format("yyyy-MM-DD")}
                    value={props.taskDetails.approvalDate ? moment(
                      props.taskDetails.approvalDate
                    ).format("yyyy-MM-DD") : moment(new Date()).format("yyyy-MM-DD")}
                    onChange={(event) => props.handleChange(event)}
                  //onBlur={() => props.validateField("incorporationDate")}
                  />
                  <div>
                    {" "}
                    <ValidationText
                      error={props.validState.error.incorporationDate}
                    />{" "}
                  </div>
                  <ValidationText
                    error={
                      props.validState.error
                        .incorporationDate
                    }
                  />
                </div>
              </div>
            </div>
          </SectionWithBorder>
        </div>

        <div className="grid grid-cols-12 gap-6">
          <div className="2xl:col-span-12 lg:col-span-12 col-span-12 gap-6 pb-10 ">
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-between">

                {props.isLoadingClarification ? (
                  <div className="py-2 w-[250px] btn btn-lighblue text-lg text-white font-bold ">
                    <ButtonLoader className="buttonload group relative rounded-md btn btn-lighblue text-xl text-white focus:outline-none" />
                  </div>
                ) : (
                  <button
                    className={`py-2 w-[250px] btn btn-lighblue text-lg rounded-md text-white font-bold `}
                    // onClick={() => props.sendClarificationMailToMember()}
                    onClick={() => {
                      props.setSendClarificationsFlag();
                    }}
                  >
                    Send Clarification
                  </button>
                )}
              </div>

              <div className="flex items-center justify-between">
                {props.isLoadingReject ? (
                  <div className="w-[233px] quatation-send-btn">
                    <ButtonLoader className="buttonload group relative rounded-md btn btn-red text-xl text-white focus:outline-none" />
                  </div>
                ) : (
                  <button
                    className={`py-2 w-[250px] btn btn-red col-span-2 text-lg text-xl rounded-md text-white font-bold`}
                    onClick={() => props.rejectUpgradeChangeRequestById()}
                  >
                    Reject
                  </button>
                )}
              </div>


              <div className="flex items-center justify-between">
                {props.isLoadingApprove ? (
                  <div className="w-[233px] quatation-send-btn">
                    <ButtonLoader className="buttonload group relative rounded-md btn bg-[#6cbf6c] text-xl text-white focus:outline-none" />
                  </div>
                ) : (
                  <button
                    className={`col-end-9 py-2 col-span-2 text-xl rounded-md font-bold text-white btn bg-[#6cbf6c] text-red w-[250px]`}
                    onClick={() => props.approveUpgradeChangeRequest()}
                  >
                    Approve
                  </button>
                )}
              </div>

            </div>
          </div>
        </div>

        {/*Footer Button End*/}
        <div className="flex items-center justify-between pb-6">
          <button
            className="text-lg py-2 font-bold btn btn-gray w-auto text-white"
            onClick={() => props.closeModalOnBack()}
          >
            Back
          </button>
        </div>
        {/*Footer Button End*/}

      </div>
    </ModalBasic>

  );
};
export default UpgradeChangeRequestTaskListProcess;