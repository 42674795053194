import React, { Component } from "react";
import Table from "../../../components/table/Table";
import { Navigate } from "react-router-dom";
import SwalServices from "../../../services/swalServices/SwalServices";
import ActionDeleteButton from "../../../components/UIComponent/ActionTableComponent/ActionDeleteButton";
import TextInput from "../../../components/UIComponent/TextInput";
import ExportButton from "../../../components/UIComponent/ActionTableComponent/ActionExportButton";
import AddExcoModal from "../../../components/membership/chapterXCommittee/AddExcoModal";
import SelectExcoMemberModal from "../../../components/membership/chapterXCommittee/SelectExcoMemberModal";
import ActionDropdownButton from "../../../components/UIComponent/ActionTableComponent/ActionDropdownButton";
import ChapterXCommitteeWorkgroupServices from "../../../services/axiosServices/apiServices/ChapterXCommitteeWorkgroupServices";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import { CommonValidationMessages, ErrorMessage } from "../../../utils/Messages";
import {
  isValidForm,
  validate,
} from "../../../utils/validation/CommonValidator";
import ValidationText from "../../../utils/validation/ValidationText";

export default class ChapterXCommitteeWorkgroupMapping extends Component {
  constructor(props) {
    super(props);
    this.chapterXCommitteeWorkgroupServices =
      new ChapterXCommitteeWorkgroupServices();
    this.swalServices = new SwalServices();
    this.state = {
      request: {},
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        searchParentName: "",
        searchGroupName: "",
        searchGroupType: "",
        status: 0,
        filterByType: 0,
        filterByStatus: 0,
      },
      paginationExcoMembers: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        id: 0,
      },
      totalResultes: "",
      totalResultesExco: "",
      getXCommitteeWorkgroupList: [],
      WorkGroupMappingId: 0,
      // excoMemberDetails: {
      //   excoMemberId: 0,
      //   firstName: "",
      //   lastName: "",
      //   email: "",
      //   excoPositionId: 0,
      //   workGroupMappingId: 0,
      //   corporateMemberId: 0,
      //   description : "",
      //   orderingId: 0,
      // },
      excoMemberDetails: {
        individualMemberId: 0,
        excoMemberId: 0,
        firstName: "",
        lastName: "",
        email: "",
        excoPositionId: 0,
        workGroupMappingId: 0,
        corporateMemberId: 0,
        description: "",
        orderingId: 1,
      },
      workGroupDetails: {
        groupName: "",
        groupTypeId: 0,
        parentRelationshipId: 0,
        workgroupMappingId: 0,
      },
      isLoading: false,
      redirect: null,
      setEXCOMemberModal: false,
      setSelectExcoMemberModal: false,
      searchbyParent: "",
      searchByGroupName: "",
      searchByGroupType: "",
      groupName: "",
      groupType: [],
      parentRelationship: [],
      excoPosition: [],
      organisationList: [],
      excoMemberList: [],
      parentList: [],
      groupTypeList: [],
      chepterList: [],
      committeeList: [],
      selectExcoMemberChecked: false,
      validationRule: {
        groupName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "membership type"
            ),
          },
        ],
        groupTypeId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "group type"
            ),
          },
        ],
        parentRelationshipId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "parent relationshipId"
            ),
          },
        ],
      },
      excoMemberValidationRule: {
        email: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "email address"
            ),
          },
          {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              " valid email address"
            ),
          },
        ],
        firstName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "first name"
            ),
          },
        ],
        lastName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "last name"
            ),
          },
        ],
        excoPositionId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "exco position"
            ),
          },
        ],
        // corporateMemberId: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.SelectRequired.replace(
        //       "{0}",
        //       "company name"
        //     ),
        //   },
        // ],
        description: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "description"
            ),
          },
        ],
        // orderingId: [
        //   {
        //     type: 'require',
        //     message: CommonValidationMessages.SelectRequired.replace('{0}', 'ordering')
        //   }
        // ],
      },
      searchEmailValidationRule: {
        email: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "email address"
            ),
          },
          {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              " valid email address"
            ),
          },
        ],
      },

      validState: {
        isValid: true,
        error: {},
      },
      // ordering: [
      //   {
      //     id: 1,
      //     name: 1,
      //   },
      //   {
      //     id: 2,
      //     name: 2,
      //   },
      //   {
      //     id: 3,
      //     name: 3,
      //   },
      //   {
      //     id: 4,
      //     name: 4,
      //   },
      //   {
      //     id: 5,
      //     name: 5,
      //   },
      //   {
      //     id: 6,
      //     name: 6,
      //   },
      //   {
      //     id: 7,
      //     name: 7,
      //   },
      //   {
      //     id: 8,
      //     name: 8,
      //   },
      // ],
      tableIdentity: 0,
    };
    this.wrapperRef = React.createRef();
    //this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  searchExcoMemberByEmail = () => {
    if (this.isValidateEmail()) {
      this.chapterXCommitteeWorkgroupServices.searchExcoMemberByEmail(this.state.excoMemberDetails)
        .then((response) => {
          if (
            response.statusCode === 200 &&
            response.responseItem != null &&
            response.responseItem.responseContent.error === "Already Joined"
          ) {
            this.swalServices.Error(
              "Individual member already joined this workgroup."
            );
          } else if (
            response.statusCode === 200 &&
            response.responseItem != null &&
            response.responseItem.responseContent.error != null
          ) {
            this.swalServices.Error(
              "Individual member not exist with " +
              response.responseItem.responseContent.error
            );
          } else if (
            response.statusCode === 200 &&
            response.responseItem != null
          ) {
            this.setState({
              excoMemberDetails: response.responseItem.responseContent,
            });
          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };

  isValidateEmail = () => {
    const newValidState = isValidForm(
      this.state.excoMemberDetails,
      this.state.searchEmailValidationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.workGroupDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };
  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.workGroupDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };
  validateFieldExcoMember = (key) => {
    const newValidState = validate(
      key,
      this.state.excoMemberDetails,
      this.state.excoMemberValidationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };
  isValidateAllFieldsExcoMember = () => {
    const newValidState = isValidForm(
      this.state.excoMemberDetails,
      this.state.excoMemberValidationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };
  addEditExcoMember = () => {
    // let data = this.state.excoMemberDetails;
    // data.orderingId = 1;
    if (this.isValidateAllFieldsExcoMember()) {
      let request = this.state.excoMemberDetails;
      request.workGroupMappingId = this.state.WorkGroupMappingId; //Ordering
      request.orderingId = 1;
      request.corporateMemberId = request.corporateMemberId > 0 ? request.corporateMemberId : 0;
      this.chapterXCommitteeWorkgroupServices
        .addEditExcoMember(this.state.excoMemberDetails)
        .then((response) => {
          // if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.errorType === "Ordering") {
          //   this.swalServices.Error(response.responseItem.responseContent.errorMessage);
          // }
          // else
          if (
            response.statusCode === 200 &&
            response.responseItem != null &&
            response.responseItem.responseContent.errorType === "EXISTS"
          ) {
            this.swalServices.Error(
              "Member already exists with same workgroup."
            );
          } else if (
            response.statusCode === 200 &&
            response.responseItem != null
          ) {
            this.swalServices.Success("Exco member inserted successfully.");
            this.resetField();
            this.setState({ setEXCOMemberModal: false });
            this.getChapterXCommitteeList();
          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };
  addEditWorkgroupMapping = () => {
    if (this.isValidateAllFields()) {
      this.chapterXCommitteeWorkgroupServices
        .addEditWorkgroupMapping(this.state.workGroupDetails)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent.errorType === "EXISTS") {
              this.swalServices.Warning(ErrorMessage.Already_Exist.replace("{0}", "SLA workgroup mapping"));
            }
            else {
              this.swalServices.Success(
                "New workgroup mapping inserted successfully."
              );
              //this.resetField();
              this.getChapterXCommitteeList();
              this.resetField();
            }

          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };
  getExcoMember = (pagination = this.state.paginationExcoMembers) => {
    this.chapterXCommitteeWorkgroupServices
      .getExcoMemberList(pagination)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let details = response.responseItem.responseContent.itemList;
          details.map((x) => {
            x.checkedValue = false;
            return x;
          })
          this.setState({
            excoMemberList: response.responseItem.responseContent.itemList,
            totalResultesExco: response.responseItem.responseContent.totalCount,
          });
        }
      });
  };
  setHandleCheckboxForRemoveExco = (id, index, checked) => {
    let det = [...this.state.excoMemberList];
    if (det.length > 0) {
      let found = det.find(x => x.excoMemberId === id);
      if (found) {
        found.checkedValue = checked;
      }
      det[index] = found;

      this.setState({ excoMemberList: det });
    }
  }
  getExcoMemberById = (id, e) => {
    e.stopPropagation();
    this.setState({ setSelectExcoMemberModal: false });
    this.setState({ setEXCOMemberModal: true });

    this.chapterXCommitteeWorkgroupServices
      .getExcoMemberById(id)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.setState({
            excoMemberDetails: response.responseItem.responseContent,
          });
        }
      });
  };
  // getExcoPosition = () => {
  //   this.chapterXCommitteeWorkgroupServices
  //     .getExcoPosition()
  //     .then((response) => {
  //       if (response.statusCode === 200 && response.responseItem != null) {
  //         this.setState({
  //           excoPosition: response.responseItem.responseContent,
  //         });
  //       }
  //     });
  // };
  getChapterXCommitteeList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });
    this.chapterXCommitteeWorkgroupServices
      .getChapterXCommitteeList(pagination)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.setState(
            {
              getXCommitteeWorkgroupList:
                response.responseItem.responseContent.itemList,
            },
            () => this.getAllDropDown()
          );
          this.setState({
            totalResultes: response.responseItem.responseContent.totalCount,
          });
        }
        this.setState({ isLoading: false });
      });
  };

  getAllDropDown = (id) => {
    this.chapterXCommitteeWorkgroupServices
      .getAllDropdownForChapterXCommittee()
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let parent = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "ParentRelation"
          );
          let group = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "GroupType"
          );
          let chapters = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Chapters"
          );
          let committee = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Committee"
          );
          let org = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Organisation"
          );
          let role = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "ContributionRole"
          );

          // var parent = []
          // var groups = chapters.concat(committee);
          // parent.push(...groups);
          this.setState({
            parentList: parent,
            chepterList: chapters,
            committeeList: committee,
            groupTypeList: group,
            organisationList: org,
            excoPosition: role,
          });
          // this.setState({ parentList: parent});
          // this.setState({ chepterList: chapters });
          // this.setState({ committeeList: committee });
          // this.setState({ groupTypeList: group });
          // this.setState({ organisationList: org });
        }
      });
  };

  // getOrganizationList = () => {
  //   this.chapterXCommitteeWorkgroupServices
  //     .getOrganisationList()
  //     .then((response) => {
  //       if (response.statusCode === 200 && response.responseItem != null) {
  //         this.setState({
  //           organisationList: response.responseItem.responseContent,
  //         });
  //       }
  //     });
  // };
  editChapterXCommittee = (value, e) => {
    this.onClickss();
    let details = this.state.getXCommitteeWorkgroupList.find(
      (x) => x.workgroupMappingId === value
    );

    let setData = this.state.workGroupDetails;
    setData.workgroupMappingId = details.workgroupMappingId;
    setData.groupTypeId = details.groupTypeId;
    setData.parentRelationshipId = details.parentRelationshipId;
    setData.groupName = details.groupName;
    this.setState({ workGroupDetails: setData });
  };

  resetField = () => {
    let reset = {
      ...this.state.workGroupDetails,
      groupName: "",
      groupTypeId: 0,
      parentRelationshipId: 0,
      workgroupMappingId: 0,
      description: "",
      orderingId: 0,
    };
    this.setState({ workGroupDetails: reset });
  };

  resetExcoMemberDetails = () => {
    let reset = this.state.excoMemberDetails;
    reset.corporateMemberId = 0;
    reset.firstName = "";
    reset.lastName = "";
    reset.email = "";
    reset.excoPositionId = 0;
    reset.orderingId = 0;
    reset.description = "";
    reset.companyName = '';
    this.setState({ excoMemberDetails: reset });

    let res = this.state.validState;
    res.error = {};
    this.setState({ validState: res });
  };

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.excoMemberDetails;
    detail[name] = value;
    this.setState({ excoMemberDetails: { ...detail } });
  }

  searchByName = (value, identity) => {
    let detail = this.state.pagination;
    detail.searchParentName = "";
    detail.searchGroupName = "";
    detail.searchGroupType = "";
    this.setState({ pagination: { ...detail } });
    if (identity === "Parent") {
      if (value.length > 2) {
        detail.pageNo = 1;
        detail.searchParentName = value;
        this.setState({ pagination: { ...detail } });
      }
    }
    if (identity === "GroupName") {
      if (value.length > 2) {
        detail.pageNo = 1;
        detail.searchGroupName = value;
        this.setState({ pagination: { ...detail } });
      }
    }
    if (identity === "GroupType") {
      if (value.length > 2) {
        detail.pageNo = 1;
        detail.searchGroupType = value;
        this.setState({ pagination: { ...detail } });
      }
    }
    this.getChapterXCommitteeList();
  };

  selectExcoMember = (value, e) => {
    let pagination = this.state.paginationExcoMembers;
    pagination.id = value;
    this.getExcoMember(pagination);
    e.stopPropagation();
    this.setState(
      { setSelectExcoMemberModal: true },
      this.setState({ tableIdentity: value })
    );
  };

  // onEdit = (value, index) => {
  //   let editDetails = [...this.state.excoMemberDetails];
  //   let editId = editDetails[index].excoMemberId;
  //   this.getExcoMemberById(editId);
  // };

  addExcoMember = (value, e) => {
    this.resetExcoMemberDetails();
    e.stopPropagation();
    this.setState({ setEXCOMemberModal: true });
    let excodetails = this.state.excoMemberDetails;
    excodetails.workGroupMappingId = value;
    this.setState({ excoMemberDetails: excodetails });
    this.setState({ WorkGroupMappingId: value });
  };
  setFilterParameters = (id, drpIdentity) => {
    let exco = this.state.excoMemberDetails;
    let workGroup = this.state.workGroupDetails;
    if (drpIdentity === "ExcoPosition") {
      exco.excoPositionId = id;
      this.validateFieldExcoMember("excoPositionId");
    }
    if (drpIdentity === "CorporateID") {
      exco.corporateMemberId = id;
      //this.validateFieldExcoMember("corporateMemberId");
    }
    // if (drpIdentity === "Ordering") {
    //   exco.orderingId = id;
    //   this.validateFieldExcoMember("orderingId");
    // }
    if (drpIdentity === "GroupType") {
      workGroup.groupTypeId = id;
      //this.validateField("groupTypeId")}
      this.validateField("groupTypeId");
    }
    if (drpIdentity === "ParentRelation") {
      workGroup.parentRelationshipId = id;
      this.validateField("parentRelationshipId");
    }
    this.setState({ excoMemberDetails: { ...exco } });
    this.setState({ workGroupDetails: { ...workGroup } });
  };

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getChapterXCommitteeList();
  };
  setPaginationExco = (newPagination) => {
    this.setState({ paginationExcoMembers: newPagination });
    this.getExcoMember(this.state.paginationExcoMembers);
  };

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ setDropdownOpen: false });
    }
  }
  onDropDownChange = (index) => {
    this.setState({ setDropdownOpen: true });
    this.setState({ setDropdownIndex: index });
  };

  onClose = () => {
    this.setState({ setDropdownOpen: false });
  };

  removeExcoMember = (array) => {
    let request = this.state.request;
    if (array.length > 0) {
      let arr1 = array.map(function (item) {
        delete item.bad;
        return item;
      });
      request.ids = arr1.join([","]);
    } else {
      request.ids = " ";
    }

    this.chapterXCommitteeWorkgroupServices
      .deleteEXCOMembers(request)
      .then((response) => {
        if (response.statusCode === 200) {
          this.swalServices.Success("Delete successfully");
          this.getExcoMember();
          this.getChapterXCommitteeList();
          this.setState({ selectExcoMemberChecked: true });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    //this.getExcoPosition();
    this.getChapterXCommitteeList();
    //this.getOrganizationList();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }


  onDelete = (value) => {
    let request = {
      id: value,
      deletedBy: 115,
      appId: 15,
    };
    this.chapterXCommitteeWorkgroupServices
      .deleteCommitteeWorkgroupMapping(request)
      .then((response) => {
        if (response.statusCode === 200) {
          this.swalServices.Success("Delete successfully");
          this.getChapterXCommitteeList();
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  actions = (element, index, value) => {
    return element !== "workgroupMappingId" ? null : (
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap pointer">
        <div className="flex items-center relative">
          <ExportButton redirect="/" text="Export" />

          <ActionDropdownButton
            value={value}
            id={index}
            index={index}
            wrapperRef={this.wrapperRef}
            setDropdownIndex={this.state.setDropdownIndex}
            setDropdownOpen={this.state.setDropdownOpen}
            onDropdownAction={this.onDropDownChange}
            onActionClick1={this.selectExcoMember}
            onActionClick2={this.addExcoMember}
            //onActionClick1={(e) => { e.stopPropagation(); this.setState({ setSelectExcoMemberModal: true }) }}
            //onActionClick2={(e) => { e.stopPropagation(); this.setState({ setEXCOMemberModal: true }) }}
            // text1="Select EXCO "
            // text2="Add EXCO "
            onActionClick3={this.editChapterXCommittee}
            text1="Select Individual Record"
            text2="Add Individual Record"
            text3="Edit"
          />

          <ActionDeleteButton
            value={value}
            id={index}
            index={index}
            onActionClick={this.onDelete}
            text="Delete"
          />
        </div>
      </td>
    );
  };

  onClickss = () => {
    window.location.href = "#xcommittee";
  };

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <>
        <div className="main-body-section">
          <div className="bg-white p-12">
            {/* <div className="EXE_NAV">
  <nav>
    <ul>
      <li><a href="">Blue</a> </li>
      <li><a href="#EXE_DIV2">Pink</a> </li>
      <li><a href="#EXE_DIV3">Green</a> </li>
    </ul>
  </nav>
</div> */}

            <div className="grid 2xl:grid-cols-3 lg:grid-cols-2 gap-12 w-full">
              <div className="">
                <h2 className="text-xl text-zinc-500">Search by Parent</h2>
                <input
                  id="default"
                  className="form-input rounded-none w-full shadow-red py-3 text-lg"
                  type="text"
                  onChange={(e) => this.searchByName(e.target.value, "Parent")}
                />
              </div>
              <div className=" ">
                <h2 className="text-xl text-zinc-500">Search by Group Name</h2>
                <input
                  id="default"
                  className="form-input rounded-none w-full shadow-red py-3 text-lg"
                  type="text"
                  onChange={(e) =>
                    this.searchByName(e.target.value, "GroupName")
                  }
                />
              </div>
              <div className="">
                <h2 className="text-xl theme-color">Search by Group Type</h2>
                <input
                  id="default"
                  className="form-input rounded-none w-full shadow-red py-3 text-lg"
                  type="text"
                  onChange={(e) =>
                    this.searchByName(e.target.value, "GroupType")
                  }
                />
              </div>
            </div>
          </div>

          <main className="pb-10">
            <div className="w-full mx-auto">
              <div className="relative">
                <div className="overflow-x-auto chapter-x-committee-w-group-tbl">
                  <div className=" mt-6" onMouseLeave={() => this.onClose()}>
                    <Table
                      columns={[
                        { name: "parentRelationshipName", title: "Parent" },
                        { name: "groupName", title: "Group Name" },
                        { name: "groupTypeName", title: "Type" },
                        { name: "excoMember", title: "EXCO Member" },
                        { name: "workgroupMappingId", title: "Action" },
                      ]}
                      pagination={this.state.pagination}
                      rows={this.state.getXCommitteeWorkgroupList}
                      sortingColumns={[
                        "parentRelationshipName",
                        "groupName",
                        "groupTypeName",
                      ]}
                      isLoading={this.state.isLoading}
                      totalResultes={this.state.totalResultes}
                      totalCount={this.state.totalResultes}
                      setPagination={this.setPagination.bind(this)}
                      customScope={[
                        {
                          column: "workgroupMappingId",
                          renderTableData: this.actions.bind(this),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </main>

          <div className="bg-white" id="xcommittee">
            <div className="heading-part py-3 border-b">
              <div className="flex items-center justify-between pl-8">
                <h4 className="small-title font-bold theme-color ">
                  Chapter X Committee Workgroup Mapping
                </h4>
              </div>
            </div>
            <div className="px-8 pt-10 pb-12">
              <div className="grid grid-cols-12 gap-6 w-full">
                <div className="col-span-12">
                  <div className="grid grid-cols-12 gap-6 w-full">
                    <div className="2xl:col-span-5 lg:col-span-5 col-span-12 pr-7">
                      <h2 className="text-xl font-bold text-zinc-500">
                        Group Name<span className="text-[#C00000]">*</span>
                      </h2>
                      <TextInput
                        placeholder=""
                        type="text"
                        name="groupName"
                        value={this.state.workGroupDetails.groupName}
                        handleChange={(event) => {
                          let data = this.state.workGroupDetails;
                          data.groupName = event.target.value;
                          this.setState({ workgroupName: data });
                        }}
                        onBlur={() => this.validateField("groupName")}
                      />
                      <div>
                        <ValidationText
                          error={this.state.validState.error.groupName}
                        />
                      </div>
                    </div>
                    <div className="2xl:col-span-5 lg:col-span-5 col-span-12 pr-7">
                      <h2 className="text-xl font-bold text-zinc-500">
                        Group Type<span className="text-[#C00000]">*</span>
                      </h2>
                      <DropdownSelect
                        drpIdentity={"GroupType"}
                        optionArray={this.state.groupTypeList}
                        setFilterParameters={this.setFilterParameters.bind(
                          this
                        )}
                        value={this.state.workGroupDetails.groupTypeId}
                      />
                      <div>
                        <ValidationText
                          error={this.state.validState.error.groupTypeId}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-12">
                  <div className="grid grid-cols-12 gap-6 w-full">
                    <div className="2xl:col-span-5 lg:col-span-4 col-span-12 pr-7">
                      <h2 className="text-xl text-zinc-500 font-bold">
                        Parent Relationship
                        <span className="text-[#C00000]">*</span>
                      </h2>
                      { }
                      <DropdownSelect
                        drpIdentity={"ParentRelation"}
                        // optionArray={ this.state.workGroupDetails.groupTypeId == 1 ? this.state.chepterList : this.state.committeeList }
                        optionArray={this.state.parentList}
                        setFilterParameters={this.setFilterParameters.bind(
                          this
                        )}
                        value={this.state.workGroupDetails.parentRelationshipId}
                        onBlur={() =>
                          this.validateField("parentRelationshipId")
                        }
                      />
                      <div>
                        <ValidationText
                          error={
                            this.state.validState.error.parentRelationshipId
                          }
                        />
                      </div>
                    </div>
                    <div className="2xl:col-span-7 lg:col-span-4 col-span-12 pr-7 pt-6">
                      <div className="flex items-center justify-end">
                        <button
                          className="text-xl font-bold btn-red-border border text-[#C00000] text-red p-3"
                          onClick={() => this.addEditWorkgroupMapping()}
                        >
                          Add/Save Grouping
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="add-exco-member-popup">
            <AddExcoModal
              setEXCOMemberModal={this.state.setEXCOMemberModal}
              setOpenModal={() => this.setState({ setEXCOMemberModal: false })}
              onClose={(e) => {
                e.stopPropagation();
                this.setState({ setEXCOMemberModal: false });
              }}
              excoMemberData={this.state.excoMemberDetails}
              handleChange={this.handleChange.bind(this)}
              setFilterParameters={this.setFilterParameters.bind(this)}
              saveButtonClick={this.addEditExcoMember.bind(this)}
              searchExcoMember={this.searchExcoMemberByEmail.bind(this)}
              excoPosition={this.state.excoPosition}
              organisationList={this.state.organisationList}
              // ordering={this.state.ordering}
              validateField={this.validateFieldExcoMember.bind(this)}
              validState={this.state.validState}
            />
          </div>

          <SelectExcoMemberModal
            setSelectExcoMemberModal={this.state.setSelectExcoMemberModal}
            setOpenModal={() =>
              this.setState({ setSelectExcoMemberModal: false })
            }
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ setSelectExcoMemberModal: false });
            }}
            columns={[
              { name: "selectRow", title: "" },
              { name: "email", title: "Email" },
              { name: "firstName", title: "First Name" },
              { name: "lastName", title: "Last Name" },
              { name: "companyName", title: "Company" },
              { name: "excoPosition", title: "EXCO Position" },
              // { name: "orderingId", title: "Ordering" },
              { name: "joinDate", title: "Join Date" },
              { name: "excoMemberId", title: "Actions" },
            ]}
            pagination={this.state.paginationExcoMembers}
            rows={this.state.excoMemberList}
            sortingColumns={[""]}
            isLoading={this.state.isLoading}
            totalResultes={this.state.totalResultesExco}
            totalCount={this.state.totalResultesExco}
            tableIdentity={this.state.tableIdentity}
            setPagination={this.setPaginationExco}
            getExcoMemberById={this.getExcoMemberById.bind(this)}
            removeExcoMember={this.removeExcoMember.bind(this)}
            selectExcoMemberChecked={this.state.selectExcoMemberChecked}
            setHandleCheckboxForRemoveExco={this.setHandleCheckboxForRemoveExco.bind(this)}
          //onEdit={this.editDetails}
          />
        </div>
      </>
    );
  }
}
