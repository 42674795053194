import React from "react";
import moment from "moment";

function CorporateChapterInformation(props) {
    return (
        <div>
            <div className="w-full">
                <div className="py-5">
                    <div className="grid grid-cols-12 gap-6 w-full ">
                        <div className="xl:col-span-12 lg:col-span-12 col-span-12">

                            {props.chapterList.map((chapter, key) => (
                                // <h1 className="col-span-2 text-white text-[16px] font-bold ml-14 mb-2" key={key}>
                                <div
                                    key={key}
                                    className="slected-chapter flex items-start justify-between table-bg p-5 lg:py-3 mt-1"
                                >
                                    <div className="left-name font-bold text-white text-lg text-left w-[50%]">
                                        <div className="flex">
                                            <span className="w-[180px]">
                                                Selected Chapter:
                                            </span>
                                            <span className="flex break-all w-[70%] pr-3">
                                                {chapter.groupName}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="left-name font-bold text-white text-lg text-left w-[20%]">
                                        <div className="flex flex-col justify-start">
                                            <span>
                                                Joined at:
                                            </span>
                                            <span>
                                                {moment(chapter.joinDate).format("DD/MM/yyyy")}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="left-name font-bold text-white text-lg text-left w-[20%]">
                                        {/* <div className="flex flex-col justify-start">
                                        <span>
                                            Left at:
                                        </span>
                                        <span>
                                            -
                                        </span>
                                        </div> */}
                                    </div>
                                    <div className="right-action w-[10%]">
                                        {/* <div className="flex justify-center items-center pt-2">
                                            <ActionDeleteButton
                                                value=""
                                                id=""
                                                index=""
                                                onActionClick={() =>
                                                    props.deleteChapter(chapter.corporateChapterId)
                                                }
                                                text="Delete"
                                            />
                                        </div> */}
                                    </div>
                                </div>
                                // </h1>
                            ))}
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                            <h2 className="text-xl text-[#757575] font-bold 2xl:mb-3">
                                Number of Chapter:{" "}
                                <span className="ml-5">
                                    {props.chapterInformation.availableChapters ? props.chapterInformation.availableChapters : "0"} Available
                                </span>
                            </h2>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                            <h2 className="text-xl text-[#757575] font-bold 2xl:mb-3">
                                Joined Chapters:{" "}
                                <span className="ml-5">
                                    {props.chapterInformation.joinedChapters ? props.chapterInformation.joinedChapters : "0"} Available
                                </span>
                            </h2>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                            <h2 className="text-xl text-[#757575] font-bold 2xl:mb-3">
                                Complimentary Chapters:{" "}
                                <span className="ml-5">
                                    {(props.chapterInformation.joinedChapters + props.chapterInformation.availableChapters) - props.chapterInformation.purchasedChapters} Available
                                </span>
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="pb-10">
                    <div className="grid grid-cols-12 gap-6 w-full ">
                        {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="2xl:col-span-11 lg:col-span-12 col-span-12">
                                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                        Chapter Name
                                        <span className="text-[#c00000]">*</span>
                                    </h2>

                                    <DropdownSelect
                                        drpIdentity={"Chapters"}
                                        optionArray={props.chaptersList}
                                        setFilterParameters=""
                                        value={props.chapterInformation.workgroupMappingId}
                                        disabled={true}

                                    // onBlur={(e) =>props.validate("workgroupMappingId",props.corporateSIISAccount)}
                                    />
                                    <Validation error={props.validState.error.workgroupMappingId}/>
                                </div>
                            </div>
                        </div> */}


                        {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="2xl:col-span-11 lg:col-span-12 col-span-12">
                                    <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                                        Updated By
                                    </h2>
                                    <DropdownSelect
                                        disabled={true}
                                    // drpIdentity={"updatedById"}
                                    // optionArray={props._by}
                                    // setFilterParameters={props.setFilterParameters}
                                    // value={props.corporateSIISAccount.updatedById}
                                    // onBlur={(e) => props.validate("updatedById", props.corporateSIISAccount)}
                                    />
                                    <Validation error={props.validState.error.updatedById} />
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="2xl:col-span-11 lg:col-span-12 col-span-12">
                                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                        Last Updated Date
                                    </h2>
                                    <div className="disabled-input">
                                        {props.chapterInformation.updatedAt === "" || props.chapterInformation.updatedAt === null ?

                                            <TextInput
                                                disabled={true}
                                                placeholder=""
                                                type="text"
                                                name="linkedIn"
                                                value="Unavailable"
                                                identity="BasicInformation"
                                            />
                                            :
                                            <DatePicker
                                                isDisable={true}
                                                name="updatedAt"
                                                handleChange={props.handleChange}
                                                identity="updatedAt"
                                                value={moment(props.chapterInformation.updatedAt).format("yyyy-MM-DD")}
                                            />

                                        }
                                    </div>                                   
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="xl:col-span-12 lg:col-span-12 col-span-12 px-10 2xl:mt-5 lg:mt-0 pt-6">
                                    <div className="flex items-center justify-end">
                                        <button
                                            className="btn btn-red text-white py-3 px-12"
                                            onClick={() => {
                                                props.save();
                                            }}
                                        >
                                            <span className="text-xl text-white font-semibold tracking-normal">
                                                Join
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                            <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                    {`History : ${props.deletedChapterList?.length === 0 ? '-' : ''}`}
                                </h2>
                            </div>
                            {props.deletedChapterList.map((chapter, key) => (
                                // <h1 className="col-span-2 text-white text-[16px] font-bold ml-14 mb-2" key={key}>
                                <div
                                    key={key}
                                    className="slected-chapter flex items-start justify-between table-bg p-5 lg:py-3 mt-1"
                                >
                                    <div className="left-name font-bold text-white text-lg text-left w-[50%]">
                                        <div className="flex">
                                            <span className="w-[180px]">
                                                Selected Chapter:
                                            </span>
                                            <span className="flex break-all w-[70%] pr-3">
                                                {chapter.groupName}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="left-name font-bold text-white text-lg text-left w-[20%]">
                                        <div className="flex flex-col justify-start">
                                            <span>
                                                Joined at:
                                            </span>
                                            <span>
                                                {moment(chapter.joinDate).format("DD/MM/yyyy")}
                                            </span>
                                        </div>
                                    </div>
                                    {/* {chapter.leftDate ? */}
                                    <div className="left-name font-bold text-white text-lg text-left w-[20%]">
                                        <div className="flex flex-col justify-start">
                                            <span>
                                                Left at:
                                            </span>
                                            <span>
                                                {moment(chapter.leftDate).format("DD/MM/yyyy")}
                                            </span>
                                        </div>
                                    </div>
                                    {/* :
                                        <div className="left-name font-bold text-white text-lg text-left w-[20%]">
                                        <div className="flex flex-col justify-start">
                                        <span>
                                            Left at:
                                        </span>
                                        <span>
                                            -
                                        </span>
                                        </div>
                                    </div>
                                    } */}
                                    <div className="right-action w-[10%]">
                                        <div className="flex justify-center items-center pt-2">
                                            {/* <ActionDeleteButton
                                                value=""
                                                id=""
                                                index=""
                                                onActionClick={() =>
                                                props.deleteChapter(chapter.corporateChapterId)
                                                }
                                                text="Delete"
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                                // </h1>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
export default CorporateChapterInformation;