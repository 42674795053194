import moment from "moment";
import React from "react";
import TextInput from "../../../components/UIComponent/TextInput";
import Validation from "../../../utils/validation/ValidationText";
import ButtonLoader from "../../common/buttonLoader/ButtonLoader";
import DatePicker from "../../datePicker/DatePicker";
import DropdownSelect from "../../dropdown/Dropdown";
import MultiSelectDropdown from "../../dropdown/MultiSelectDropdown";
import Option from "../../dropdown/multiSelectDropdownOption/MultiSelectOption";

function OrganisationBasicInformation(props) {
  return (
    <div>
      <div className="w-full">
        <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
          <div className="grid grid-cols-12 gap-6 w-full ">
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Types of Services<span className="text-[#c00000]">*</span>
                  </h2>
                  <MultiSelectDropdown
                    drpIdentity="ServiceType"
                    options={props.serviceType}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option, }}
                    onChange={props.handleChangeMultiDropdown}
                    allowSelectAll={true}
                    value={props.organizationBusinessDetails.typesOfServices}
                  />
                  {/* <Validation
                    error={props.validState.error.typesOfServices}
                  /> */}
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Types of Industries Served<span className="text-[#c00000]">*</span>
                  </h2>
                  <MultiSelectDropdown
                    drpIdentity="IndustriesServed"
                    options={props.industriesServed}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option, }}
                    onChange={props.handleChangeMultiDropdown}
                    allowSelectAll={true}
                    value={props.organizationBusinessDetails.typesOfIndustriesServed}
                  />
                  {/* <Validation
                    error={props.validState.error.typesOfIndustriesServed}
                  /> */}
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Countries Presence<span className="text-[#c00000]">*</span>
                  </h2>
                  <MultiSelectDropdown
                    drpIdentity="CountriesWithPresence"
                    options={props.countriesWithPresence}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option, }}
                    onChange={props.handleChangeMultiDropdown}
                    allowSelectAll={true}
                    value={props.organizationBusinessDetails.countriesPresence}
                  />
                  {/* <Validation
                    error={props.validState.error.countriesPresence}
                  /> */}
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    How did you hear about us? <span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <DropdownSelect
                        drpIdentity={"GetToKnow"}
                        optionArray={props.getToKnow}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organizationBusinessDetails.getToKnowId}
                      />
                    </div>
                  </div>
                  {/* <Validation
                    error={props.validState.error.getToKnowId}
                  /> */}
                </div>
              </div>
            </div>
            {props.organizationBusinessDetails.getToKnowId && props.getToKnow.find(x => x.id === props.organizationBusinessDetails.getToKnowId).name.includes('Other') ?
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Other Source <span className="text-[#c00000]">*</span>
                    </h2>
                    <div className="flex items-center">
                      <div className="w-full outline-none appearance-none">
                        <TextInput
                          placeholder=""
                          type="text"
                          name="getToKnowOthers"
                          value={props.organizationBusinessDetails.getToKnowOthers}
                          identity="organizationBusinessDetails"
                          handleChange={props.handleChange}
                        // onBlur={(e) =>
                        //   props.validate("getToKnowOthers", props.organizationBusinessDetails)
                        // }
                        />
                      </div>
                    </div>
                    {/* <Validation
                      error={props.validState.error.getToKnowOthers}
                    /> */}
                  </div>
                </div>
              </div>
              : null}
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    How does your organisation wish to benefit from joining the association?<span className="text-[#c00000]">*</span>
                  </h2>
                  <MultiSelectDropdown
                    drpIdentity="AssociationBenefits"
                    options={props.associationBenefits}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option, }}
                    onChange={props.handleChangeMultiDropdown}
                    allowSelectAll={true}
                    value={props.organizationBusinessDetails.associationBenefits}
                  />
                  {/* <Validation
                    error={props.validState.error.associationBenefits}
                  /> */}
                </div>
              </div>
            </div>
            {props.organizationBusinessDetails.associationBenefits && props.organizationBusinessDetails.associationBenefits.find(x => x.label.includes('Others')) ?
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Other Association Benefits <span className="text-[#c00000]">*</span>
                    </h2>
                    <div className="flex items-center">
                      <div className="w-full outline-none appearance-none">
                        <TextInput
                          placeholder=""
                          type="text"
                          name="associationBenefitsOthers"
                          value={props.organizationBusinessDetails.associationBenefitsOthers}
                          identity="organizationBusinessDetails"
                          handleChange={props.handleChange}
                        // onBlur={(e) =>
                        //   props.validate("associationBenefitsOthers", props.organizationBusinessDetails)
                        // }
                        />
                      </div>
                    </div>
                    {/* <Validation
                      error={props.validState.error.associationBenefitsOthers}
                    /> */}
                  </div>
                </div>
              </div>
              : null}
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Others
                    <span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="others"
                        value={props.organizationBusinessDetails.others}
                        identity="organizationBusinessDetails"
                        handleChange={props.handleChange}
                      // onBlur={(e) =>
                      //   props.validate("others", props.organizationBusinessDetails)
                      // }
                      />
                    </div>
                  </div>
                  {/* <Validation
                    error={props.validState.error.others}
                  /> */}
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Updated By
                  </h2>
                  <div className="disabled-input flex items-center">
                    <DropdownSelect
                      disabled={true}
                      drpIdentity={"updatedById"}
                      optionArray={props._by}
                      setFilterParameters={props.setFilterParameters}
                      value={props.organizationBusinessDetails.updatedBy}
                    />
                    {/* <Validation
                      error={props.validState.error.areaofExpertise}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Last Updated Date
                  </h2>
                  <div className="disabled-input">
                    {props.organizationBusinessDetails.updatedAt === "" || props.organizationBusinessDetails.updatedAt === null ?
                      <TextInput
                        disabled={true}
                        placeholder=""
                        type="text"
                        name="linkedIn"
                        value="None"
                        identity="BasicInformation"
                      />
                      :
                      <DatePicker
                        isDisable={true}
                        name="updatedAt"
                        handleChange={props.handleChange}
                        identity="updatedAt"
                        value={moment(props.organizationBusinessDetails.updatedAt).format("yyyy-MM-DD")}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Save Button */}
        <div className="grid grid-cols-12 gap-6 pb-10">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12 px-10">
            <a
              className="flex items-center justify-end"
            >
              {
                props && props.isOtherInfoLoading === true ?
                  (
                    <ButtonLoader className="buttonload group relative rounded-md btn btn-red text-xl text-white focus:outline-none" />
                  )
                  :
                  (
                    <button
                      className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold"
                      onClick={() => props.onSave()}
                    >
                      Save
                    </button>
                  )
              }
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default OrganisationBasicInformation;