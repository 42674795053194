import React, { Component } from 'react'
import { Navigate } from "react-router-dom";
import ButtonLoader from '../../components/common/buttonLoader/ButtonLoader';
import LogoImage from "../../images/logo.png";
import { isValidForm, validate } from '../../utils/validation/CommonValidator';
import { ErrorMessage } from '../../utils/Messages';
import ValidationText from '../../utils/validation/ValidationText';
import AuthenticationServices from '../../services/axiosServices/apiServices/AuthenticationServices';
import SwalServices from '../../services/swalServices/SwalServices';

export default class ForgetPassword extends Component {
    constructor(props) {
        super(props);
        this.adminServices = new AuthenticationServices();
        this.swalServices = new SwalServices();
        this.state = {
            isLoading: false,
            redirect: null,
            emailDetails: {
                email: "",
            },

            validationRules: {
                email: [
                    {
                        type: "require",
                        message: ErrorMessage.FieldRequired.replace("{0}", "Email address"),
                    },
                    {
                        type: "email",
                        message: ErrorMessage.NOTVALIDPATTERN.replace("{0}", "Email address"),
                    },
                ],
            },
            validState: {
                isValid: true,
                error: {},
            },
        }
    }

    validateField = (key) => {
        const newValidState = validate(
            key,
            this.state.emailDetails,
            this.state.validationRules,
            this.state.validState
        );
        this.setState({ validState: newValidState });
    };

    isValidateAllFields = () => {
        const newValidState = isValidForm(
            this.state.emailDetails,
            this.state.validationRules,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };
    forgotPassword = () => {
        if (this.isValidateAllFields()) {
            let request = this.state.emailDetails;
            request.isAdmin = 1;
            request.isSLAMember = 0;
            request.isIndividualMember = 0;
            this.setState({ isLoading: true });
            this.adminServices.forgotPassword(request).then((response) => {
                if (response.statusCode === 200 && response.responseItem.responseContent == null) {
                    this.swalServices.Error("No records found for this email.");
                    this.setState({ isLoading: false });
                }
                else if (response.statusCode === 200 && response.responseItem != null) {
                    this.swalServices
                        .SuccessWithAction("Please check your email to get reset password link.", "Ok")
                        .then((response) => {
                            this.setState({ route: "/Login" });
                        });
                        this.setState({ isLoading: false });
                }
                else {
                    this.swalServices.Error(response.message);
                }
                this.setState({ isLoading: false });
            })
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        let details = this.state.emailDetails;
        details[name] = value;
        this.setState({ emailDetails: details });
    }

    render() {
        if (this.state.route != null) {
            return <Navigate to={this.state.route} />;
        }
        return (
            <div>
                <div className="main-login-sec">
                    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-white w-full">
                        <div className="login-main-bg max-w-md w-full space-y-8 p-8 rounded-sm  shadow-blue ">
                            <div className="">
                                <img
                                    className="mx-auto w-[280px]"
                                    src="/logo.png"
                                    alt="logo"
                                />
                                <h2 className="mt-9 text-center text-3xl font-extrabold text-gray-900">
                                    Reset Password
                                </h2>
                                <p className='text-center text-xl text-[#212529] mt-4'>Enter the email to retrieve your account</p>
                            </div>
                            <input type="hidden" name="remember" value="true" />
                            <div className="rounded-md -space-y-px relative">
                                <div>
                                    <label htmlFor="email-address" className="sr-only">
                                        Email address
                                    </label>
                                    <input
                                        id="email-address"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-[#af0c0c] placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#c71212] focus:border-[#c71212] focus:z-10 sm:text-sm bg-white"
                                        placeholder="Email address"
                                        value={this.state.emailDetails.email}
                                        onChange={(event) => this.handleChange(event)}
                                        onBlur={() => this.validateField("email")}
                                    />
                                    <ValidationText
                                        className="absolute left-0 top-[100%]"
                                        error={this.state.validState.error.email}
                                    />
                                </div>
                            </div>

                            <div className="flex justify-center">
                                {this.state.isLoading ? (
                                    <ButtonLoader />
                                ) : (

                                    <button
                                        type="submit"
                                        className="group relative w-full flex items-center justify-center py-3 px-4 h-[48px] border border-transparent text-base font-medium text-white bg-[#3778b4] hover:bg-[#3778b4] focus:outline-none"
                                        onClick={() => this.forgotPassword()}
                                    >
                                        Reset your password
                                    </button>

                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
