import { axiosGetAuthorize, axiosPostAuthorize } from '../AxiosRequests';
import { AddLeadershipPositions, GetLeadershipPositionsList, GetLeadershipPositionsById, DeleteLeadershipPositions, UpdateLeadershipPositions, GetLeadershipChapters, GetLeadershipCommittee } from '../ApiEndPoints'

export default class LeadershipPositionsServices {

    async addLeadershipPositions(request) {
        return axiosPostAuthorize(AddLeadershipPositions, request)
    }

    async getLeadershipPositionsList(request) {
        return axiosPostAuthorize(GetLeadershipPositionsList, request)
    }

    async getLeadershipPositionsById(request) {
        return axiosGetAuthorize(GetLeadershipPositionsById, request)
    }

    async deleteLeadershipPositions(request) {
        return axiosPostAuthorize(DeleteLeadershipPositions, request)
    }

    async updateLeadershipPositions(request) {
        return axiosPostAuthorize(UpdateLeadershipPositions, request)
    }
    async getLeadershipChapters() {
        return axiosGetAuthorize(GetLeadershipChapters);
    }
    async getLeadershipCommittee() {
        return axiosGetAuthorize(GetLeadershipCommittee);
    }
}