import DatePicker from "../datePicker/DatePicker";
import moment from "moment";
const SearchBar = (props) => {
  return (
    <>
      <div className="searchbar-section border border-[#757575] bg-white w-full 2xl:h-14  lg:h-[20px] xl:flex lg:flex items-center justify-between">
        <form
          role="form"
          className="relative flex z-50 justify-between bg-white w-full "
        >
          <DatePicker
            //   handleChange={this.handleChange.bind(this)}
            value={moment().format("yyyy-MM-DD")}
            name="Date"
            minDate={moment().format("yyyy-MM-DD")}
          />
        </form>
      </div>
    </>
  );
};
export default SearchBar;
