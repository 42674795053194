import React from "react";
import ValidationText from "../../../utils/validation/ValidationText";
import DropdownSelect from "../../dropdown/Dropdown";

function Endorsers(props) {
    return (
        <div>
            <div className="custom-checkbox-set">
                <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
                    <div className="grid grid-cols-12 gap-6 w-full ">
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                                        Endorser Company 1 (Ordinary Member)
                                        <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <DropdownSelect
                                        drpIdentity={"CreditTerms"}
                                        optionArray={props.ordinaryEndorsers}
                                        // setFilterParameters={props.setFilterParameters}
                                        value={props.endorsersDetails.endorsersId1 ? props.endorsersDetails.endorsersId1 : 0}
                                        disabled = {true}
                                    />
                                    {/* <ValidationText error={props.validState.error.endorsersId1} /> */}
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                                        Representative
                                        <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <DropdownSelect
                                        drpIdentity={"CreditTerms"}
                                        optionArray={props.ordinaryRepresentative}
                                        // setFilterParameters={props.setFilterParameters}
                                        value={props.endorsersDetails.representativeId1 ? props.endorsersDetails.representativeId1 : 0}
                                        disabled = {true}
                                    />
                                    {/* <ValidationText error={props.validState.error.representativeId1} /> */}
                                </div>
                            </div>
                        </div>


                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                                        Endorser Company 2
                                        <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <DropdownSelect
                                        drpIdentity={"CreditTerms"}
                                        optionArray={props.allEndorsers}
                                        // setFilterParameters={props.setFilterParameters}
                                        value={props.endorsersDetails.endorsersId2 ? props.endorsersDetails.endorsersId2 : 0}
                                        disabled = {true}
                                    />
                                    {/* <ValidationText error={props.validState.error.endorsersId2} /> */}
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                                        Representative
                                        <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <DropdownSelect
                                        drpIdentity={"CreditTerms"}
                                        optionArray={props.allRepresentative}
                                        // setFilterParameters={props.setFilterParameters}
                                        value={props.endorsersDetails.representativeId2 ? props.endorsersDetails.representativeId2 : 0}
                                        disabled = {true}
                                    />
                                    {/* <ValidationText error={props.validState.error.representativeId2} /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Endorsers;
