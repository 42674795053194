import { axiosGetAuthorize, axiosPost, axiosPostAuthorize } from '../AxiosRequests';
import {Changepasswordforadminmember,AddEditAdminDetails, AdminLogin, AzureLogin, ChangePasswordForAdmin, DeleteAdminDetailsById, ForgotPassword, GetAdminDetailById, GetAdminDetailList, GetRolesForAdmin, ResetPassword } from '../ApiEndPoints';

export default class AuthenticationServices {

    async adminLogin(request) {
        return axiosPost(AdminLogin, request);
    }
    async addEditAdminDetails(request) {
        return axiosPostAuthorize(AddEditAdminDetails, request);
        
    }
    async changepasswordforadmin(request) {
        return axiosPostAuthorize(ChangePasswordForAdmin, request);
        
    }
    async getRolesForAdmin(request) {
        return axiosGetAuthorize(GetRolesForAdmin, request);
    }
    async getAdminDetailList(request) {
        return axiosPostAuthorize(GetAdminDetailList, request);
    }
    async getAdminDetailById(request) {
        return axiosGetAuthorize(GetAdminDetailById, request);
    }
    async deleteAdminDetailsById(request) {
        return axiosPostAuthorize(DeleteAdminDetailsById, request);
    }
    async forgotPassword(request) {
        return axiosPost(ForgotPassword, request);
    }
    async resetPassword(request) {
        return axiosPost(ResetPassword, request);
    }
    async azureLogin(request) {
        return axiosPost(AzureLogin, request);
    }
    async changePasswordForAdmin(request) {
        return axiosPostAuthorize(ChangePasswordForAdmin, request);
    }
}