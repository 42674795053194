import React, { useEffect, useState } from "react";
import ModalBasic from "../../component/FormModal";
import SectionWithBorder from "../../../components/membership/membershipTaskList/SectionWithBorder";
import CalculationChart from "../../../components/membership/membershipTaskList/CalculationChart";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import moment from "moment";
import ValidationText from "../../../utils/validation/ValidationText";
import { StatusType } from "../../../utils/Constants";
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";
import { encryptAES } from "../../../utils/Encryption";
import MultiSelectDropdown from "../../../components/inputs/MultiSelectDropdown";
import Option from "../../../components/dropdown/multiSelectDropdownOption/MultiSelectOption";
import Table from "../../../components/table/Table";
import MoneyInput from "../../../components/UIComponent/MoneyInput";



const MTOTaskListProcess = (props) => {
  const handleChangeInPriceTable = (value, name, year, tableName) => {
    props.handleChangeInPriceTable(value, name, year, tableName);
  };
  // const [endorserBothValid, setEndorserBothValid] = useState(false);
  // useEffect(() => {
  //   if (props.taskDetails.endorsementSatusForEndorser1 && props.taskDetails.endorsementSatusForEndorser2 === 'Approved'
  //   ) {
  //     setEndorserBothValid(true);
  //   } else {
  //     setEndorserBothValid(false);
  //   }
  // }, [props.taskDetails.endorsementSatusForEndorser1, props.taskDetails.endorsementSatusForEndorser2]);
  return (
    <ModalBasic
      id="add-edit-modal"
      className="membership-add-edit-modal"
      modalOpen={props.setAddeditModalOpen}
      setModalOpen={props.setModalOpen}
      title="MTO Task List Process"
    >
      {/* Modal content */}
      <div className="2xl:px-14 lg:px-3 pt-4">
        <div className="">
          <SectionWithBorder title="Account Information">
            <div className="">
              <div className="grid grid-cols-12 gap-6 w-full items-center pt-7 pb-0">
                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >  Membership Type <span className="text-[#C00000]">*</span>
                      </label>
                      <DropdownSelect
                        drpIdentity={"MembershipType"}
                        optionArray={props.membershipType}
                        setFilterParameters={props.setFilterParameters}
                        value={props.taskDetails.membershipTypeId ? props.taskDetails.membershipTypeId : 0}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                        Transaction Type <span className="text-[#C00000]"> *</span>
                      </label>
                      <DropdownSelect
                        drpIdentity={"TransactionTypes"}
                        optionArray={props.transactionTypes}
                        setFilterParameters={props.setFilterParameters}
                        value={2}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30 "
                        htmlFor="default"
                      >
                        Subscription Start Date
                        <span className="text-[#C00000]"> *</span>
                      </label>
                      <div className="grid grid-cols-12 gap-6 items-center w-full">
                        <div className=" 2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                          <input
                            id="default"
                            className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg cursor-not-allowed bg-[#e9e9ea]  border-[#757575] "
                            type="date"
                            name="subscriptionStartDate"
                            max={moment(new Date()).format("yyyy-MM-DD")}
                            value={props.taskDetails.approvalDate ? moment(props.taskDetails.approvalDate).format("yyyy-MM-DD") : moment().format("YYYY-MM-DD")}
                            onChange={(event) => props.handleChange(event)}
                            disabled={true}
                          />
                          <div>
                            <ValidationText error={props.validState.error.subscriptionStartDate} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                        Subscription End Date
                        {/* <span className="text-[#C00000]"> *</span> */}
                      </label>
                      <div className="grid grid-cols-12 gap-6 items-center w-full">
                        <div className=" 2xl:col-span-12 lg:col-span-12 col-span-12 w-full ">
                          <input
                            id="default"
                            name="subscriptionEndDate"
                            className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg cursor-not-allowed bg-[#e9e9ea]  border-[#757575] "
                            type="date"
                            value={`${new Date().getFullYear()}-12-31`}
                            onChange={(event) => props.handleChange(event)}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable flex w-full font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                        Last Updated Date
                        {/* <span className="text-[#C00000]"> *</span> */}
                      </label>
                      <div className="grid grid-cols-12 gap-6 items-center w-full">
                        <div className=" 2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                          <input
                            id="default"
                            name="lastUpdatedDate"
                            className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg cursor-not-allowed bg-[#e9e9ea]  border-[#757575] "
                            type="date"
                            value={props.taskDetails.lastUpdatedDate ? props.taskDetails.lastUpdatedDate : new Date().toISOString().split('T')[0]}
                            onChange={(event) => props.handleChange(event)}
                            disabled={true}
                          // onBlur={() => props.validateField("publishDate")}
                          />
                          {/* <div>
                          <ValidationText error={props.validState.error.publishDate} />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12 flex flex-col items-end">
                      <label
                        className="filter-lable flex w-full font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                      </label>
                      {props.isLoadingReject ? (
                        <div className="py-2 w-[350px] btn btn-red text-lg text-white font-bold">
                          <ButtonLoader className="buttonload group relative btn btn-red text-xl text-white focus:outline-none" />
                        </div>
                      ) : (
                        <button
                          className={`py-2 w-[350px] btn btn-red text-lg text-white font-bold`}
                          onClick={() => props.updateCorporateMemberUpdate(props.taskDetails.memberId)}
                        >
                          View Corporate Information
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SectionWithBorder>

          <SectionWithBorder title="Application Information">
            <div className="grid grid-cols-12 gap-6  w-full items-center pt-7">

              <div className="xl:col-span-6 md:col-span-6 col-span-12">
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-12 md:col-span-12 col-span-12">
                    <label
                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                      htmlFor="default"
                    >
                      Organisation Name <span className="text-[#C00000]">*</span>
                    </label>
                    <input
                      id="default"
                      className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575] cursor-not-allowed bg-[#e9e9ea]  border-[#757575]"
                      type="text"
                      name="organisationName"
                      value={props.taskDetails.organisationName}
                      onChange={(event) => props.handleChange(event)}
                      validateField={() => props.validateField("organisationName")}
                      disabled={true}
                    />
                    <ValidationText error={props.validState.error.organisationName} />
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 md:col-span-6 col-span-12">
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-12 md:col-span-12 col-span-12">
                    <label
                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                      htmlFor="default"
                    >
                      Organisation UEN <span className="text-[#C00000]">*</span>
                    </label>
                    <input
                      id="default"
                      className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575] cursor-not-allowed bg-[#e9e9ea]  border-[#757575]"
                      type="text"
                      name="organisationUEN"
                      value={props.taskDetails.organisationUEN}
                      onChange={(event) => props.handleChange(event)}
                      validateField={() => props.validateField("organisationUEN")}
                      disabled={true}
                    />
                    <div>
                      <ValidationText error={props.validState.error.organisationUEN} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 md:col-span-6 col-span-12">
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-12 md:col-span-12 col-span-12">
                    <label
                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                      htmlFor="default"
                    >
                      Paid Up Capital
                    </label>
                    <input
                      id="default"
                      className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575] cursor-not-allowed bg-[#e9e9ea]  border-[#757575]"
                      type="text"
                      name="paidUpCapital"
                      value={props.taskDetails.paidUpCapital ? props.taskDetails.paidUpCapital : ""}
                      onChange={(event) => props.handleChange(event)}
                      validateField={() => props.validateField("paidUpCapital")}
                      disabled={true}
                    />
                  </div>
                  <ValidationText error={props.validState.error.paidUpCapital} />
                </div>
              </div>
              <div className="xl:col-span-6 md:col-span-6 col-span-12">
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-12 md:col-span-12 col-span-12">
                    <label
                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                      htmlFor="default">
                      Incorporation Date <span className="text-[#C00000]">*</span>
                    </label>
                    <input
                      id="default"
                      className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg cursor-not-allowed bg-[#e9e9ea]  border-[#757575]"
                      type="date"
                      name="incorporationDate"
                      max={moment(new Date()).format("yyyy-MM-DD")}
                      value={props.taskDetails.incorporationDate ? moment(
                        props.taskDetails.incorporationDate
                      ).format("yyyy-MM-DD") : ""}
                      onChange={(event) => props.handleChange(event)}
                      onBlur={() => props.validateField("incorporationDate")}
                      disabled={true}
                    />
                    <div>
                      <ValidationText error={props.validState.error.incorporationDate} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="xl:col-span-6 md:col-span-6 col-span-12">
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-12 md:col-span-12 col-span-12">
                    <label
                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                      htmlFor="default"
                    > Types of Services <span className="text-[#C00000]">*</span>
                    </label>
                    <MultiSelectDropdown
                      drpIdentity="ServicesType"
                      options={props.serviceType}
                      isMulti
                      closeMenuOnSelect={false}
                      components={{ Option }}
                      hideSelectedOptions={false}
                      onChange={props.handleChangeMultiDropdown}
                      allowSelectAll={true}
                      value={props.servicesTypeData}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 md:col-span-6 col-span-12">
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-12 md:col-span-12 col-span-12">
                    <label
                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                      htmlFor="default"
                    >
                      Types of Industries Served
                      <span className="text-[#C00000]">*</span>
                    </label>
                    <MultiSelectDropdown
                      drpIdentity="IndustriesServed"
                      options={props.industriesServed}
                      isMulti
                      closeMenuOnSelect={false}
                      components={{ Option }}
                      hideSelectedOptions={false}
                      onChange={props.handleChangeMultiDropdown}
                      allowSelectAll={true}
                      value={props.industriesServedData}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 md:col-span-6 col-span-12">
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-12 md:col-span-12 col-span-12">
                    <label
                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                      htmlFor="default"
                    >
                      How does your organisation wish to benefit from joining the association
                      <span className="text-[#C00000]">*</span>
                    </label>
                    {/* <DropdownSelect
                      drpIdentity={"AssociationBenefits"}
                      optionArray={props.associationBenefits}
                      setFilterParameters={props.setFilterParameters}
                      value={props.taskDetails.associationBenefitsId}
                    /> */}
                    <MultiSelectDropdown
                      drpIdentity="AssociationBenefits"
                      options={props.associationBenefits}
                      isMulti
                      closeMenuOnSelect={false}
                      components={{ Option }}
                      hideSelectedOptions={false}
                      onChange={props.handleChangeMultiDropdown}
                      allowSelectAll={true}
                      value={props.associationBenefitsData}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </SectionWithBorder>

          <SectionWithBorder title="MTO Information">
            <div className="">
              <div className="grid grid-cols-12 gap-6  w-full items-center pt-7 ">
                <div className="xl:col-span-12 md:col-span-12 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-6 md:col-span-6 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                        Insurance Company Name
                        <span className="text-[#C00000]">*</span>{" "}
                      </label>
                      <input
                        id="default"
                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                        type="text"
                        name="insuranceCompanyName"
                        value={props.taskDetails.insuranceCompanyName ? props.taskDetails.insuranceCompanyName : ""}
                        onChange={(event) => props.handleChange(event)}
                      //validateField={() => props.validateField("insuranceCompanyName")}
                      />
                    </div>
                    <div className="xl:col-span-6 md:col-span-6 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                        Policy Number
                        <span className="text-[#C00000]">*</span>{" "}
                      </label>
                      <input
                        id="default"
                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                        type="text"
                        name="policyNumber"
                        value={props.taskDetails.policyNumber ? props.taskDetails.policyNumber : ""}
                        onChange={(event) => props.handleChange(event)}
                      //validateField={() => props.validateField("policyNumber")}
                      />
                    </div>

                    <div className="xl:col-span-6 md:col-span-6 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                        Expiry Date
                        <span className="text-[#C00000]">*</span>{" "}
                      </label>
                      <input
                        id="default"
                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                        type="date"
                        name="expiryDate"
                        max={moment(new Date()).format("yyyy-MM-DD")}
                        value={props.taskDetails.expiryDate ? moment(
                          props.taskDetails.expiryDate
                        ).format("yyyy-MM-DD") : ""}
                        onChange={(event) => props.handleChange(event)}
                      //onBlur={() => props.validateField("expiryDate")}
                      />
                      {/* <ValidationText error={ props.validState.error.transactionTypeId } /> */}
                    </div>
                    <div className="xl:col-span-6 md:col-span-6 col-span-12">
                      <div className="grid-cols-12 gap-6 w-full ">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12 ml-auto">
                          <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
                            <label
                              className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                              htmlFor="default"
                            >
                              Copy of Liability Insurance Policy
                              <span className="text-[#C00000]">*</span>{" "}
                            </label>
                            <div className="flex item-center">
                              <div className="file-uploaded-txt flex items-center">
                                <button
                                  className={`text-xl font-bold text-white bg-[#6C757D] w-[200px] bg-blue-500 p-4 rounded-md hover:bg-[#8C959D] focus:outline-none`}
                                  onClick={() => props.getInvoiceReceiptPdfByBookingId(props.taskDetails.memberId, "MTOInsurancePolicy")}
                                >
                                  Download
                                </button>
                                {/* <span className="text-[#757575] big-bullet-points theme-color min-h-30 text-sm ml-3">
                                    {props.taskDetails.insurancePolicyFilePath}
                                  </span> */}
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SectionWithBorder>
          <hr></hr>


          <div className="">
            <div className="grid grid-cols-12 w-full items-center pt-7">
              <div className="xl:col-span-2 md:col-span-6 col-span-12">
                <label
                  className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                  htmlFor="default"
                >
                  {props.taskDetails.membershipTypeId === 1
                    ? "Ordinary Member"
                    : "Qualifying Member"}
                </label>
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-[#757575] text-xl pointer-events-none">
                    $
                  </span>
                  <input
                    id="default"
                    className="form-input rounded-none w-full shadow-red py-3 pl-8 text-lg text-[#757575]"
                    type="text"
                    name="mtoRegistryFee"
                    value={props.taskDetails.mtoRegistryFee}
                    onChange={(event) => props.handleChange(event)}
                  //validateField={() => props.validateField("mtoRegistryFee")}
                  //disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>


          {/* //Council Approval */}
          <SectionWithBorder title="Council Approval">
            <div className="">
              <div className="grid grid-cols-12 gap-6 w-full items-center pt-3 pb-7 mt-5">
                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                  <label
                    className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                    htmlFor="default"
                  >
                    Approval Date{" "}
                    <span className="text-[#C00000]">*</span>
                  </label>
                  <input
                    id="default"
                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                    type="date"
                    name="approvalDate"
                    //max={moment(new Date()).format("yyyy-MM-DD")}
                    value={props.taskDetails.approvalDate ? moment(props.taskDetails.approvalDate).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD")}
                    onChange={(event) => props.handleChange(event)}
                  //onBlur={() => props.validateField("approvalDate")}
                  />
                  <div>
                    <ValidationText error={props.validState.error.approvalDate} />
                  </div>
                </div>
              </div>
            </div>
          </SectionWithBorder>
        </div>

        <div className="grid grid-cols-12 gap-6">
          <div className="2xl:col-span-12 lg:col-span-12 col-span-12 gap-6 pb-10 ">
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-between">
                {props.isLoadingClarification ? (
                  <div className="py-2 w-[250px] btn btn-lighblue text-lg text-white font-bold ">
                    <ButtonLoader className="buttonload group relative rounded-md btn btn-lighblue text-xl text-white focus:outline-none" />
                  </div>
                ) : (
                  <button
                    className={`py-2 w-[250px] btn btn-lighblue text-lg rounded-md text-white font-bold `}
                    //onClick={() => props.sendClarificationMailToMember()}
                    onClick={() => {
                      props.setSendClarificationsFlag();
                    }}
                  >
                    Send Clarification
                  </button>
                )}
              </div>

              <div className="flex items-center justify-between">
                {props.isLoadingReject ? (
                  <div className="w-[233px] quatation-send-btn">
                    <ButtonLoader className="buttonload group relative rounded-md btn btn-red text-xl text-white focus:outline-none" />
                  </div>
                ) : (
                  <button
                    className={`py-2 w-[250px] btn btn-red col-span-2 text-lg text-xl rounded-md text-white font-bold`}
                    onClick={() => props.rejectApplicationForMember()}
                  >
                    Reject
                  </button>
                )}
              </div>


              <div className="flex items-center justify-between">
                {props.isLoadingApprove ? (
                  <div className="w-[233px] quatation-send-btn">
                    <ButtonLoader className="buttonload group relative rounded-md btn bg-[#6cbf6c] text-xl text-white focus:outline-none" />
                  </div>
                ) : (
                  <button
                    className={`col-end-9 py-2 col-span-2 text-xl rounded-md font-bold text-white btn bg-[#6cbf6c] text-red w-[250px]`}
                    onClick={() => props.addEditMembershipTaskAndSendPaymentLink()}
                  >
                    Send Payment Link
                  </button>
                )}
              </div>

              {/* <div className="flex items-center justify-between">
                {props.isLoadingApprove ? (
                  <div className="w-[233px] quatation-send-btn">
                    <ButtonLoader />
                  </div>
                ) : (
                  <button
                    className={`col-end-9 py-2 col-span-2 text-xl font-bold text-white btn bg-[#6cbf6c] text-red w-[250px] ${!endorserBothValid
                      ? "opacity-50 cursor-not-allowed"
                      : null
                      }`}
                    onClick={() => props.addEditMembershipTaskAndSendPaymentLink()}
                    disabled={!endorserBothValid}
                  >
                    Send Payment Link
                  </button>
                )}
              </div> */}
            </div>
          </div>
        </div>

        {/*Footer Button End*/}
        <div className="flex items-center justify-between pb-6">
          <button
            className="text-lg py-2 font-bold btn btn-gray w-auto text-white"
            onClick={() => props.closeModalOnBack()}
          >
            Back
          </button>
        </div>
        {/*Footer Button End*/}

      </div>
    </ModalBasic>

  );
};
export default MTOTaskListProcess;