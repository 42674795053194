export default function ActionTagButton(props) {
    return (
        <button className='action-btn flex items-center' aria-controls="add-edit-modal" id={props.index}
            onClick={(e) => props.onActionClick(props.value, props.index,e)}
        >
           <svg className="menu-svg" version="1.1" id="Layer_1"  width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 442.688 442.688">
				<g>
					<g>
						<g>
							<path d="M442.666,178.822l-4.004-145.078c-0.447-16.222-13.504-29.279-29.727-29.728l-145.08-4.004
							c-8.475-0.237-16.493,2.97-22.468,8.945L8.954,241.391c-11.924,11.924-11.924,31.325,0,43.249l149.083,149.082
							c11.951,11.953,31.296,11.956,43.25,0.001L433.721,201.29C439.636,195.374,442.897,187.184,442.666,178.822z M412.507,180.078
							L180.073,412.51c-0.056,0.056-0.171,0.171-0.411,0.171s-0.355-0.115-0.412-0.171L30.167,263.427
							c-0.227-0.227-0.227-0.597,0-0.823L262.601,30.17c0.108-0.108,0.257-0.17,0.426-0.17c0,0,0,0,0.001,0l145.079,4.004
							c0.309,0.009,0.558,0.257,0.566,0.566l4.004,145.079C412.682,179.809,412.619,179.965,412.507,180.078z"/>
							<path d="M302.699,66.44c-20.275,20.274-20.275,53.264,0,73.539c20.323,20.323,53.214,20.324,73.539,0
							c20.274-20.275,20.274-53.265,0-73.539C355.915,46.117,323.023,46.116,302.699,66.44z M355.025,118.766
							c-8.596,8.596-22.514,8.599-31.112,0c-8.578-8.578-8.578-22.535,0-31.113c8.597-8.597,22.514-8.599,31.113,0
							C363.602,96.231,363.602,110.188,355.025,118.766z"/>
						</g>
					</g>
				</g>
			</svg>
            <p className='ml-2 tool-tip-txt text-[#757575]'>
                {props.text}
            </p>
        </button>
    )
}




