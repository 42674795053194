import React from 'react'
import ModalBasic from '../../../pages/component/ModalBasic'
import ValidationText from '../../../utils/validation/ValidationText'
import Selector from '../../dropdown/commonDropdown/Selector'
import DropdownSelect from '../../dropdown/Dropdown'
import TextInput from '../../UIComponent/TextInput'
import ButtonLoader from '../../common/buttonLoader/ButtonLoader'
import MultiSelectDropdown from '../../dropdown/MultiSelectDropdown'

function AddEditProfile(props) {
  const onDelete = (id) => {
    props.markIndivisualMemberAsNonMember(id);
  }


  // function shouldDisableButton(organisationRole) {
  //   if (organisationRole === 1 || organisationRole === 2 || organisationRole === 3) {
  //     return true;
  //   }
  //   return false;
  // }

  return (
    <ModalBasic
      id="show-Add-edit-profile-modal"
      modalOpen={props.addEditModel}
      setModalOpen={props.setOpenModal}
      title=""
    >
      <div className="bg-white mb-10">
        <div className="">
          <div className="grid grid-cols-12 gap-6 w-full px-10">
            <div className="xl:col-span-12 lg:col-span-12 col-span-12">
              <h1 className="text-4xl text-[#c00000] font-bold leading-loose py-4">
                {props.isCreateNewOrgRoleForEmployee === true ? "Add New Employee Profile" : "Update Profile"}
              </h1>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-6 w-full px-10">

            {
              props.isCreateNewOrgRoleForEmployee === true ?
                <>
                  <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Email Address <span className="text-[#c00000]">*</span>
                    </h2>
                    <TextInput
                      placeholder=""
                      type="text"
                      name="searchByEmail"
                      value={props.employeeDetails.searchByEmail}
                      identity="Employee"
                      handleChange={props.handleChange}
                      onBlur={(e) => props.validate("searchByEmail", props.employeeDetails)}
                    />
                    <ValidationText error={props.validState.error.searchByEmail} />

                  </div>
                  <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                    <div className='flex justify-end'>
                      <div>
                        <h2 className="text-xl mb-1">&nbsp;</h2>
                        <button className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white font-bold"
                          onClick={() => props.searchIndividualMemberByEmailForOrgRole("Organisarion Employees")}
                        >Search</button>
                      </div>
                    </div>
                  </div>
                </>
                : null}

            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                First Name <span className="text-[#c00000]">*</span>
              </h2>
              <div className="flex items-center">
                <div className="w-[130px] add-edite-f-name">
                  <DropdownSelect
                    drpIdentity={"mrMrsId"}
                    optionArray={props.mrMrs}
                    setFilterParameters={props.setFilterParameters}
                    value={props.employeeDetails.mrMrsId}
                    disabled={props.isCreateNewOrgRoleForEmployee === true && props.isEmployeeModalEditable !== true}
                  />
                </div>
                <div className="w-full">
                  <TextInput
                    placeholder=""
                    type="text"
                    name="firstName"
                    value={props.employeeDetails.firstName}
                    identity="Employee"
                    handleChange={props.handleChange}
                    onBlur={(e) => props.validate("firstName", props.employeeDetails)}
                    disabled={props.isCreateNewOrgRoleForEmployee === true && props.isEmployeeModalEditable !== true}
                    isCurserAllowed={props.isCreateNewOrgRoleForEmployee === true && props.isEmployeeModalEditable !== true}
                    // disabled={props.isEmployeeModalEditable !== true}
                    // isCurserAllowed={props.isEmployeeModalEditable !== true}
                  />
                </div>
              </div>
              <ValidationText error={props.validState.error.mrMrsId} />
              <ValidationText error={props.validState.error.firstName} />
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                Last Name <span className="text-[#c00000]">*</span>
              </h2>
              <TextInput
                placeholder=""
                type="text"
                name="lastName"
                value={props.employeeDetails.lastName}
                identity="Employee"
                handleChange={props.handleChange}
                onBlur={(e) => props.validate("lastName", props.employeeDetails)}
                disabled={props.isCreateNewOrgRoleForEmployee === true && props.isEmployeeModalEditable !== true}
                isCurserAllowed={props.isCreateNewOrgRoleForEmployee === true && props.isEmployeeModalEditable !== true}

              />
              <ValidationText error={props.validState.error.lastName} />

            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                Email (please enter company email){" "}
                <span className="text-[#c00000]">*</span>
              </h2>
              <div className="flex items-center">
                <div className="w-full right-border-none">
                  <TextInput
                    placeholder=""
                    type="text"
                    name="emailText"
                    value={props.employeeDetails.emailText}
                    identity="Employee"
                    handleChange={props.handleChange}
                    onBlur={(e) => props.validate("emailText", props.employeeDetails)}
                    disabled={true}
                    isCurserAllowed={props.isCreateNewOrgRoleForEmployee === true && props.isEmployeeModalEditable !== true}
                  />

                </div>
                {/* <div className="w-[55%] add-edit-profile-email">
                      <DropdownSelect
                        disabled={true}
                        drpIdentity={"EmailDomain"}
                        optionArray={props.domainArr}
                        setFilterParameters={props.setFilterParameters}
                        value={props.employeeDetails.emailDomainId}
                      />
                    </div> */}
              </div>
              <ValidationText error={props.validState.error.emailText} />
              <ValidationText error={props.validState.error.emailString} />

            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                Business Mobile
              </h2>
              <TextInput
                placeholder=""
                type="number"
                name="bussinessMobile"
                value={props.employeeDetails.bussinessMobile}
                identity="Employee"
                handleChange={props.handleChange}
                disabled={props.isCreateNewOrgRoleForEmployee === true && props.isEmployeeModalEditable !== true}
                isCurserAllowed={props.isCreateNewOrgRoleForEmployee === true && props.isEmployeeModalEditable !== true}
              />

            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                {/* Designation */}
                Job Function
                <span className="text-[#c00000]">*</span>
              </h2>
              <TextInput
                placeholder=""
                type="text"
                name="designation"
                value={props.employeeDetails.designation}
                identity="Employee"
                handleChange={props.handleChange}
                disabled={props.isCreateNewOrgRoleForEmployee === true && props.isEmployeeModalEditable !== true}
                isCurserAllowed={props.isCreateNewOrgRoleForEmployee === true && props.isEmployeeModalEditable !== true}
              />
              <ValidationText error={props.validState.error.designation} />
            </div>

            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                Office DID <span className="text-[#c00000]">*</span>
              </h2>
              <TextInput
                placeholder=""
                type="text"
                name="officeDID"
                value={props.employeeDetails.officeDID}
                identity="Employee"
                handleChange={props.handleChange}
                onBlur={(e) => props.validate("officeDID", props.employeeDetails)}
                disabled={props.isCreateNewOrgRoleForEmployee === true && props.isEmployeeModalEditable !== true}
                isCurserAllowed={props.isCreateNewOrgRoleForEmployee === true && props.isEmployeeModalEditable !== true}
              />
              <ValidationText error={props.validState.error.officeDID} />

            </div>

            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                Subscribe to Email Circulars <span className="text-[#c00000]">*</span>
              </h2>
              <Selector
                drpIdentity="EmailSubscriptionEmployee"
                options={props.emailSubscription}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={props.handleChangeMultiDropdown}
                allowSelectAll={true}
                value={props.employeeDetails.emailSubscription}
                disabled={props.isCreateNewOrgRoleForEmployee === true && props.isEmployeeModalEditable !== true}
                isCurserAllowed={props.isCreateNewOrgRoleForEmployee === true && props.isEmployeeModalEditable !== true}
              />
              <ValidationText error={props.validState.error.emailSubscription} />
            </div>

            {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                Organisation Role
                <span className="text-[#c00000]">*</span>
              </h2>
              <DropdownSelect
                drpIdentity={"Employee_OrganisationRole"}
                // optionArray={props.contactRoles.filter(option => option.id !== 1 && option.id !== 2 && option.id !== 3)}
                optionArray={props.contactRoles}
                setFilterParameters={props.setFilterParameters}
                value={props.employeeDetails.contactRoleId}
                disabled={props.isCreateNewOrgRoleForEmployee === true && props.isEmployeeModalEditable !== true}
              />
              <ValidationText error={props.validState.error.contactRoleId} />
            </div>

            {props.employeeDetails.contactRoleId === 7 ?
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Birth Year
                  {/* <span className="text-[#c00000]">*</span> */}
               {/* </h2>
                <DropdownSelect
                  drpIdentity={"Years"}
                  optionArray={props.years}
                  setFilterParameters={props.setFilterParameters}
                  value={props.employeeDetails.yearsId}
                  disabled={props.isCreateNewOrgRoleForEmployee === true && props.isEmployeeModalEditable !== true}
                />
                {/* <ValidationText error={props.validState.error.yearsId} /> */}
             {/* </div>
              : null
            } */}
          </div>
        </div>
        {/* Delete and Save Button */}
        <div className="grid grid-cols-12 gap-6 w-full pt-16 px-10">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="flex items-center justify-between">
              <div className="dltbtn">
                {
                  props.employeeDetails && props.employeeDetails.individualMemberId > 0 ?
                    <>
                      {
                        props.isLoaderOn ?
                          (
                            <ButtonLoader />
                          )
                          :
                          (
                            <button
                              className="btn btn-gray text-xl text-white font-semibold"
                              // onClick={() => markIndividualAsNonMember(props.employeeDetails.individualMemberId)}
                              onClick={() => onDelete(props.employeeDetails.individualMemberId)}
                            >
                              Delete Profile
                            </button>
                          )
                      }
                    </>
                    :
                    null
                }
              </div>
              <div className="savebtn">
                {
                  props.isAddEditProfileButtonLoader && props.isAddEditProfileButtonLoader === true ?
                    (
                      <div className="savebtn">
                        <ButtonLoader />
                      </div>
                    )
                    :
                    (
                      <button className="btn btn-red text-xl text-white font-semibold px-10"
                        onClick={() => { props.saveEmployee() }}
                        // disabled={shouldDisableButton(props.employeeDetails.contactRoleId)}
                        // style={{
                        //   backgroundColor: shouldDisableButton(props.employeeDetails.contactRoleId) ? 'gray' : '',
                        //   cursor: shouldDisableButton(props.employeeDetails.contactRoleId) ? 'not-allowed' : 'pointer'
                        // }}
                      >
                        Save
                      </button>
                    )
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalBasic>
  )
}

export default AddEditProfile