import { UpdateMembershipPricingData,AddMembershipPricing, GetMembershipPricing,GetAllMembershipProRatedPricing } from "../ApiEndPoints";
import { axiosGetAuthorize, axiosPostAuthorize } from "../AxiosRequests";

export default class MembershipPricingServices {
    async addEditMembershipPricing(request) {
        return axiosPostAuthorize(AddMembershipPricing, request);
    }
    async updateMembershipPricingData(request) {
        return axiosPostAuthorize(UpdateMembershipPricingData, request);
    }
    async getMembershipPricing() {
        return axiosGetAuthorize(GetMembershipPricing, 1);
    }
    async getAllMembershipProRatedPricing() {
        return axiosGetAuthorize(GetAllMembershipProRatedPricing, 1);
    }
}