import {  axiosGetAuthorize,  axiosPostAuthorize, axiosPostWithoutEncryption } from '../AxiosRequests';
import { AddEditBanners, ClearAllBannersByPositionId,  GetBanners, StoreBase64Image } from '../ApiEndPoints';

export default class BannersServices {

    async addEditBanners(request) {

        return axiosPostAuthorize(AddEditBanners, request);
    }

    async getBanners(request) {
        return axiosGetAuthorize(GetBanners, request);
    }
    async storeBase64Image(request) {
        return axiosPostWithoutEncryption(StoreBase64Image, request, false);
    }
    async clearAllBannersByPositionId(request){
        return axiosGetAuthorize(ClearAllBannersByPositionId,request);
    }
}