import { GetAllPendingApprovalTasks, GetAllSources, GetPriceTablesForMembershipFeeApproval, UpdatePriceTablesForMembershipFeeApproval } from "../ApiEndPoints";
import {  axiosGetAuthorize,  axiosPostAuthorize } from "../AxiosRequests";

export default class MembershipFeeApprovalServices {

    async getSourceLists(){
        return axiosGetAuthorize(GetAllSources);
    }
    async getAllPendingFeeApprovalList(request){
        return axiosPostAuthorize(GetAllPendingApprovalTasks,request)
    }
    async getPriceTablesForMembershipFeeApproval(request) {
        return axiosGetAuthorize(GetPriceTablesForMembershipFeeApproval, request)
    }
    async updatePriceTablesForMembershipFeeApproval(request) {
        return axiosPostAuthorize(UpdatePriceTablesForMembershipFeeApproval, request)
    }
}