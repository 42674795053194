import React, { Component } from 'react'
import CreateResources from './CreateResources'
import PreviewResources from './PreviewResources'
import SwalServices from '../../services/swalServices/SwalServices';
import ResourceServices from '../../services/axiosServices/apiServices/ResourceServices';
import { getAuthProps } from '../../utils/AuthenticationLibrary';
import { CommonValidationMessages, ErrorMessage } from '../../utils/Messages';
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import CommonServices from '../../services/axiosServices/apiServices/CommonServices';
import { maxLength, required } from '../../utils/validation/ValidateField';
import { SuccessMessage } from "../../utils/Messages";
import { Navigate } from "react-router-dom"
import PageLoader from "../../components/common/pageLoader/PageLoader"

export default class CreatePreviewResource extends Component {


    constructor(props) {
        super(props)
        this.ResourceServices = new ResourceServices();
        this.swalServices = new SwalServices();
        this.commonServices = new CommonServices();
        this.authUser = getAuthProps()
        this.state = {
            resFile: {
                filename: ""
            },
            resourceTypes: [],
            resourceTopics: [],
            isLoading: false,
            route: null,
            resourceItem: {
                resourceId: 0,
                resourceName: "",
                resourceTypeId: 0,
                resourceImage: "",
                videoUrl: "",
                report: "",
                publishDate: "",
                publishTime: "12:00 AM",
                unpublishDate: "",
                unpublishTime: "12:00 AM",
                statusId: 0,
                resourceTypeName: "",
                resourceTopicIds: 0,
                isVideo: true,
                isReport: false,
                isPage: false,
                selectedFile: "",
                visibilityStatusId: 1,
                pageDescription: [{
                    resourceDescriptionId: 1,
                    resourceDescription: '',
                    resourceDescriptionImage: '',
                    validate: {
                        description: false,
                        descriptionLength: false,
                        // resourceDescriptionImage: false
                    }
                },],
                optionSelected: [],
                // reportValidation: true,
                // videoValidation: true,
                pdfDisplayName: ""
            },
            unpublishDateValidationMsg: "",
            validationRule: {
                resourceName: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'Resource Name'),

                    },
                    {
                        type: 'maxLength',
                        maxLength: 100,
                        message: CommonValidationMessages.MaxCharacter.replace('{0}', 'Resource name')

                    },

                ],
                optionSelected: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'Resource Topics')
                    }
                ],
                resourceTypeId: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'resource type ')
                    }
                ],
                unpublishDate: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'unpublish date')
                    }
                ],
                publishDate: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'publish date')
                    }
                ],
            },
            validState: {
                isValid: true,
                error: {}
            },
            preview: false,
            isLoaderEnabled: false,
        }
        this.handleRadioButtons = this.handleRadioButtons.bind(this);
        this.handleChangeInDropdown = this.handleChangeInDropdown.bind(this);
        this.setFilterParameters = this.setFilterParameters.bind(this);
        this.addNewDec = this.addNewDec.bind(this);
        this.removeSection = this.removeSection.bind(this);
        this.setImageDescription = this.setImageDescription.bind(this);
        this.setData = this.setData.bind(this);
        this.saveMainImage = this.saveMainImage.bind(this);
    }

    getAllResourceTypes = () => {
        this.ResourceServices.getAllResourceTypes().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ resourceTypes: response.responseItem.responseContent }, () => this.getAllResourceTopics());
            }
            else {
                this.getAllResourceTopics();
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    getAllResourceTopics = () => {
        this.ResourceServices.getAllResourceTopics().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ resourceTopics: response.responseItem.responseContent });
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    getResourceById = (ResourceId) => {
        this.ResourceServices.getResourceById(ResourceId).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                response.responseItem.responseContent.resourceTypeId = response.responseItem.responseContent.resourceTypeId.toString(10).replace(/\D/g, '0').split('').map(Number);

                let res = { ...this.state.resourceItem };
                res.resourceId = response.responseItem.responseContent.resourceId;
                res.resourceName = response.responseItem.responseContent.resourceName;
                res.resourceTypeId = response.responseItem.responseContent.resourceTypeId[0];
                res.resourceImage = response.responseItem.responseContent.resourceImage;
                res.resourceImage = response.responseItem.responseContent.resourceImage;
                res.videoUrl = response.responseItem.responseContent.videoUrl;
                res.report = response.responseItem.responseContent.report;
                res.publishDate = response.responseItem.responseContent.publishDate;
                res.publishTime = response.responseItem.responseContent.publishTime;
                res.unpublishDate = response.responseItem.responseContent.unpublishDate;
                res.unpublishTime = response.responseItem.responseContent.unpublishTime;
                res.statusId = response.responseItem.responseContent.statusId;
                res.resourceTypeName = response.responseItem.responseContent.resourceTypeName;
                res.resourceTopicIds = response.responseItem.responseContent.resourceTopicIds;
                res.isVideo = response.responseItem.responseContent.isVideo;
                res.isReport = response.responseItem.responseContent.isReport;
                res.selectedFile = response.responseItem.responseContent.selectedFile;
                res.isPage = response.responseItem.responseContent.isPage;
                res.visibilityStatusId = 1;
                res.pdfDisplayName = response.responseItem.responseContent.report;
                res.pageDescription = [];
                response.responseItem.responseContent.resourceDescription.forEach(dec => {
                    const obj = {
                        resourceDescriptionId: dec.resourceDescriptionId,
                        resourceDescription: dec.resourceDescription,
                        resourceDescriptionImage: dec.resourceDescriptionImage,
                        validate: {
                            description: true,
                            descriptionLength: true,
                            // resourceDescriptionImage: true
                        }
                    };
                    res.pageDescription.push(obj);
                });

                var array = response.responseItem.responseContent.resourceTopicIds.split(',');
                const options = [];
                array.forEach(topic => {
                    options.push(this.state.resourceTopics.find(topics => topics.value === Number(topic)));
                });
                res.optionSelected = options;

                this.setState({ resourceItem: { ...res } }, () => this.getAllResourceTypes());
                // this.setState({ resourceItem: {...res} },
                //     () => {
                //         this.bindMultiSelect(response.responseItem.responseContent.resourceTopicIds);
                //     });

            }
            else {
                this.getAllResourceTypes();
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    bindMultiSelect = (topicIds) => {
        var array = topicIds.split(',');
        const options = [];
        array.forEach(topic => {
            options.push(this.state.resourceTopics.find(topics => topics.value === Number(topic)));
        });
        var selected = this.state.resourceItem
        selected.optionSelected = options
        this.setState({ resourceItem: selected });
    }


    addEditResources = () => {
        let isValid = this.validateResource();
        let isValidInput = this.isValidateAllFields()
        let isValidUnpublishDate = this.unpublishDateValidation();
        if (isValid && isValidInput && isValidUnpublishDate) {
            var resource = this.state.resourceItem;
            var descriptions = [];
            if (this.state.resourceItem.pageDescription.length > 0) {
                this.state.resourceItem.pageDescription.forEach(dec => {
                    dec.resourceDescriptionImage = (dec.resourceDescriptionImage === null || dec.resourceDescriptionImage === undefined) ? '' : dec.resourceDescriptionImage;
                    if (dec.resourceDescriptionImage.includes("/")) {
                        var arr = resource.resourceImage.split("/");
                        dec.resourceDescriptionImage = arr[arr.length - 1];
                    }
                    const obj = {
                        resourceDescriptionId: dec.resourceDescriptionId,
                        resourceDescription: dec.resourceDescription,
                        resourceDescriptionImage: dec.resourceDescriptionImage
                    };
                    descriptions.push(obj);
                });
            }
            resource.resourceDescription = descriptions;
            if (resource.resourceTypeId.length === 1) {
                resource.resourceTypeId = resource.resourceTypeId[0];
            }
            if (this.state.resourceItem.optionSelected.length > 0) {
                var arr1 = this.state.resourceItem.optionSelected.map(function (item) {
                    delete item.bad;
                    return item.value;
                });
                resource.resourceTopicIds = arr1.join([',']);
            }
            this.setState({ isLoading: true });
            resource.resourceImage = (resource.resourceImage === null || resource.resourceImage === undefined) ? '' : resource.resourceImage;
            if (resource.resourceImage.includes("/")) {
                var arr = resource.resourceImage.split("/");
                resource.resourceImage = arr[arr.length - 1];
            }
            this.ResourceServices.addEditResources(resource).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                    if (response.responseItem.responseContent.errorMessage === '') {
                        this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Your resource"));
                        this.setState({ route: "/ViewResource" });
                    }
                    else {
                        this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Resource"));
                    }
                    // }
                }
                else {
                    this.swalServices.Error(ErrorMessage.DefaultMessage);
                }
                this.setState({ isLoading: false });
            })
        }
    }


    validatePageDescription = () => {
        let pageDesc = [...this.state.resourceItem.pageDescription]
        let isValid = true;

        pageDesc.forEach(element => {
            element.validate.description = true;
            element.validate.descriptionLength = true;
            // element.validate.resourceDescriptionImage = true;
            if (!required(element["resourceDescription"])) {
                isValid = false;
                element.validate.description = false;
            }
            if (!maxLength(element["resourceDescription"], 5000)) {
                isValid = false;
                element.validate.descriptionLength = false;
            }
            // if(!required(element["resourceDescriptionImage"]))
            // {
            //     isValid = false;
            //     element.validate.resourceDescriptionImage = false;
            // }
        });

        return isValid;
    }

    validateResource = () => {
        let isValid = true;
        let resourceItem = { ...this.state.resourceItem }
        if (resourceItem.isReport) {

            resourceItem.reportValidation = true;
            if (!required(resourceItem["report"])) {
                resourceItem.reportValidation = false;
                isValid = false;

                return isValid;
            }
            this.setState({ resourceItem: { ...resourceItem } });
        }
        if (resourceItem.isVideo) {

            resourceItem.videoValidation = true;
            // TODO : check for the video format
            if (!required(resourceItem["videoUrl"])) // Check the proper property name 
            {
                resourceItem.videoValidation = false; // write the proper validation flag
                isValid = false;

                return isValid;
            }
            this.setState({ resourceItem: { ...resourceItem } });
        }
        if (resourceItem.isPage || resourceItem.isVideo) {
            return this.validatePageDescription();
        }
        resourceItem.resourceImageValidation = true;
        if (!required(resourceItem["resourceImage"])) {
            resourceItem.resourceImageValidation = false;
            isValid = false;
            return isValid;
        }
        return isValid;
    }

    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = this.state.resourceItem;
        detail[name] = value;
        if (name === "videoUrl") {
            const urlFormate = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?(?=.*v=((\w|-){11}))(?:\S+)?$/;
            if (value && value !== "") {
                if (value.match(urlFormate)) {
                    let splitUrl = value.split("=");
                    let embedUrl = `https://www.youtube.com/embed/${splitUrl[1]}`;
                    if (embedUrl.includes("&")) {
                        let splitUrl = embedUrl.split("&");
                        embedUrl = splitUrl[0];
                    }
                    detail[name] = embedUrl;
                }
                else {
                    this.swalServices.Error("Please enter correct youtube url formate");
                    detail[name] = value;
                }
            }
        }
        this.setState({ resourceItem: { ...detail } });
    }

    handleRadioButtons(event) {
        const value = event.target.checked;
        const id = event.target.id;
        let detail = this.state.resourceItem;
        detail['isVideo'] = false;
        detail['isReport'] = false;
        detail['isPage'] = false;
        detail[id] = value;
        this.setState({ resourceItem: { ...detail } });
    }

    handleChangeInDropdown = selected => {
        var resource = this.state.resourceItem
        resource.optionSelected = selected
        this.setState({ resourceItem: resource });
        this.validateField("optionSelected");
    };

    setFilterParameters = (id, drpIdentity) => {
        let detail = this.state.resourceItem;
        if (drpIdentity === "Types") {
            detail['resourceTypeId'] = id
            this.validateField("resourceTypeId");
        }
        this.setState({ resourceItem: { ...detail } });
    }

    saveMainImage = (file, keyId, imageIdentity) => {
        this.setState({ isLoaderEnabled: true });
        file.storagePath = imageIdentity
        this.commonServices.storeBase64Image(file).then((response) => {
            if (response != null) {
                this.swalServices.Success("File Uploaded");
                let detail = this.state.resourceItem;
                if (file.storagePath === "Resource") {
                    detail["resourceImage"] = response.fileName;
                    this.setState({ resourceItem: { ...detail } }, () => this.setState({ isLoaderEnabled: false }));
                }
                else if (file.storagePath === "ResourceDescription") {
                    var resource = this.state.resourceItem;
                    resource.pageDescription[keyId].resourceDescriptionImage = response.fileName;
                    this.setState({ resourceItem: resource }, () => this.setState({ isLoaderEnabled: false }));
                }
            }
            else {
                this.swalServices.Error(response.message);
            }
        })
    }

    addNewDec = () => {

        if (this.validatePageDescription()) {
            var Index = this.state.resourceItem.pageDescription[this.state.resourceItem.pageDescription.length - 1].resourceDescriptionId;
            var dec =
            {
                resourceDescriptionId: Index + 1,
                ResourceDescription: '',
                resourceDescriptionImage: '',
                validate: {
                    description: false,
                    descriptionLength: false,
                    // resourceDescriptionImage: false
                }
            };
            let resource = { ...this.state.resourceItem }
            var ImageArray = [...this.state.resourceItem.pageDescription];
            ImageArray.push(dec);
            resource.pageDescription = ImageArray;
            this.setState({ resourceItem: resource });
        }
    }

    removeSection = (id) => {
        if (id > 0) {
            var desc = this.state.resourceItem.pageDescription.filter(arr => arr.resourceDescriptionId !== id);
            if (desc.length) {
                var resource = this.state.resourceItem;
                resource.pageDescription = desc;
                this.setState({ resourceItem: resource });
            }
        }
    }

    unpublishDateValidation = () => {
        let request = { ...this.state.resourceItem };
        let isValid = true;
        if (request.publishDate > request.unpublishDate) {
            this.setState({ unpublishDateValidationMsg: "Can't select UnPublish Date before Publish Date" });
            isValid = false;
        }
        else {
            this.setState({ unpublishDateValidationMsg: "" });
        }
        return isValid;
    }

    validateField = (key) => {
        const newValidState = validate(key, this.state.resourceItem, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
    }

    isValidateAllFields = () => {
        const newValidState = isValidForm(this.state.resourceItem, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    }


    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let ResourceId = params.get("");


        if (ResourceId != null) {
            this.getResourceById(ResourceId);
        } else {
            this.getAllResourceTypes();
        }
    }

    onFileChange = event => {

        const file = event.target.files[0];
        if (file && file.size < 48000000) {
            if (file && file.type === "application/pdf") {
                let uploadFile = { ...this.state.resFile };
                uploadFile.filename = file.name;
                uploadFile["file"] = file;
                uploadFile.storagePath = "Resource";
                this.commonServices.uploadPDF(uploadFile).then((response) => {
                    if (response != null) {
                        var resource = this.state.resourceItem;
                        resource.pdfDisplayName = uploadFile.filename;
                        resource.report = response.fileName;
                        this.setState({ resourceItem: resource });

                    }
                    else {
                        this.swalServices.Error(response.message);
                    }
                })
            }
            else {

                this.swalServices.Error("Please Upload only PDF file !");
            }
        }
        else {
            this.swalServices.Error("File size must be less than 48 MB");
            this.onFileCancel();
        }

    };

    onFileCancel = () => {
        var resource = this.state.resourceItem;
        resource.pdfDisplayName = "";
        resource.report = "";
        this.setState({ resourceItem: resource });
    }

    onFileUpload = () => {

        let request = { ...this.state.resFile }
        this.commonServices.saveFileToAmazon(request).then((response) => {
            if (response != null) {
                this.swalServices.Success("File Uploaded");
            }
            else {
                this.swalServices.Error(response.message);
            }
        })

    };

    onPreview = () => {

        this.setState({ preview: true });
    }

    onPreviewClose = () => {
        this.setState({ preview: false })
    }

    setData = (value) => {
        this.setState({ data: value });
    }

    setImageDescription = (array, index) => {
        var resource = this.state.resourceItem;
        resource.pageDescription[index] = array;
        this.setState({ resourceItem: resource });
    }

    uploadImage = (array, index) => {
        // $("#ClassImg").click();
    }

    setTime = (time) => {
        let detail = this.state.resourceItem;
        if (time.identity === "PublishTime") {
            detail['publishTime'] = time.selectedTime;
        }
        else if (time.identity === "UnpublishTime") {
            detail['unpublishTime'] = time.selectedTime;
        }
        this.setState({ resourceItem: { ...detail } });
    }


    render() {

        if (this.state.route != null) {
            return <Navigate to={this.state.route} />;
        }

        return (
            <div>
                {/* <div className='heading-part pl-7 py-4 border-b'>

                </div> */}
                <div>
                    {this.state.preview ?

                        <PreviewResources
                            resource={this.state.resourceItem}
                            optionSelected={this.state.resourceItem.optionSelected}
                            pageDescription={this.state.resourceItem.pageDescription}
                            addEditResources={this.addEditResources.bind(this)}
                            onClosePreview={this.onPreviewClose.bind(this)}
                        />
                        :

                        <div>
                            {this.state.isLoading ? <PageLoader /> :
                                <CreateResources
                                    handleChange={this.handleChange.bind(this)}
                                    handleRadioButtons={this.handleRadioButtons.bind(this)}
                                    handleChangeInDropdown={this.handleChangeInDropdown.bind(this)}
                                    setFilterParameters={this.setFilterParameters.bind(this)}
                                    addNewDec={this.addNewDec.bind(this)}
                                    removeSection={this.removeSection.bind(this)}
                                    setImageDescription={this.setImageDescription.bind(this)}
                                    addEditResources={this.addEditResources.bind(this)}
                                    setData={this.setData.bind(this)}
                                    uploadImage={this.uploadImage.bind(this)}
                                    onFileUpload={this.onFileUpload.bind(this)}
                                    onFileChange={this.onFileChange.bind(this)}
                                    onFileCancel={this.onFileCancel.bind(this)}
                                    saveMainImage={this.saveMainImage.bind(this)}
                                    setTime={this.setTime.bind(this)}
                                    resource={this.state.resourceItem}
                                    authUser={this.authUser}
                                    ResourceServices={this.ResourceServices}
                                    swalServices={this.SwalServices}
                                    isLoading={this.state.isLoading}
                                    resourceTypes={this.state.resourceTypes}
                                    resourceTopics={this.state.resourceTopics}
                                    optionSelected={this.state.resourceItem.optionSelected}
                                    pageDescription={this.state.resourceItem.pageDescription}
                                    Option={this.Option}
                                    preview={this.state.preview}
                                    isLoaderEnabled={this.state.isLoaderEnabled}
                                    onPreviewOpen={this.onPreview.bind(this)}
                                    validState={this.state.validState}
                                    validateField={this.validateField.bind(this)}
                                    unpublishDateValidationMsg={this.state.unpublishDateValidationMsg}
                                />

                            }
                        </div>

                    }

                </div>
            </div>
        )
    }
}
