import React, { Component } from 'react';
import Table from '../../components/table/Table';
import { Link } from 'react-router-dom';
import DropdownSelect from '../../components/dropdown/Dropdown';
import moment from 'moment';
import SGTechTVServices from '../../services/axiosServices/apiServices/SGTechTVServices';
import SwalServices from '../../services/swalServices/SwalServices';
import { Navigate } from 'react-router';
import ActionContainer from '../../components/UIComponent/ActionTableComponent/actionContainer';
import ActionEditButton from '../../components/UIComponent/ActionTableComponent/ActionEditButton';
import ActionDeleteButton from '../../components/UIComponent/ActionTableComponent/ActionDeleteButton';
import { CommonSuccessMessages } from '../../utils/Messages';
import { encryptAES } from '../../utils/Encryption';

export default class SGTechTv extends Component {
    constructor(props) {
        super(props)
        this.sgtechServices = new SGTechTVServices();
        this.swalServices = new SwalServices();
        this.state = {
            isLoading: false,
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "",
                orderFlag: 0,
                searchText: "",
                statusId: 0,
                // sgTechTVCategoryId: 0,
                // filterByCategory: 0,
                filterByStatus: 0,
                optionSelected: [],
            },
            status: [],
            category: [],
            getSGTechTvList: [],
            categoryList: [
                {
                    id: 1,
                    label: "Label1"
                },
                {
                    id: 2,
                    label: "Label2"
                },
                {
                    id: 3,
                    label: "Label3"
                },
            ],
            totalResultes: 0,
        }
    }

    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getSGTechTVList();
    }

    // Method declaration section
    componentDidMount() {
        this.getAllStatus();
        // this.getAllSGTechTVCategory();
    }

    getSGTechTVList = (pagination = this.state.pagination) => {
        this.setState({ isLoading: true });
        this.sgtechServices.getSGTechTVList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let newdata = response.responseItem.responseContent.itemList;
                newdata.forEach((key, index) => {
                    key.sgTechTVPublishDate = moment(key.sgTechTVPublishDate).format('DD MMM YYYY');
                })
                newdata.forEach((key, index) => {
                    if (key.isFeatured === true) {
                        key.featured = <span className='text-[#C00000] star-added'> <img src='/star.png' className='w-[20px] mx-auto my-0' alt=""></img></span>;
                    }
                })
                this.setState({ getSGTechTvList: newdata });
                this.setState({ totalResultes: response.responseItem.responseContent.totalCount })

            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    getAllStatus = () => {
        this.sgtechServices.getAllStatus().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                // var TvStatusList = response.responseItem.responseContent;
                // TvStatusList = TvStatusList.map(p => ({
                //     value: p.id, label: p.name
                // }));
                // this.setState({ status: TvStatusList });
                this.setState({ status: response.responseItem.responseContent }, () => this.getSGTechTVList());
            }
            else {
                this.getSGTechTVList()
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    setFilterParameters = (id, drpIdentity) => {
        let detail = this.state.pagination;

        if (drpIdentity === "Statuses") {
            detail.statusId = id;
            detail.pageNo = 1;
        }
        if (drpIdentity === "Category") {
            detail.sgTechTVCategoryId = id;
        }
        this.setState({ pagination: { ...detail } });
        this.getSGTechTVList();
    }


    handleChangeEvent = (value) => {
        let detail = this.state.pagination;
        detail.searchText = "";
        if (value.length > 2) {
            detail.searchText = value;
            detail.pageNo = 1;
        }
        this.setState({ pagination: { ...detail } })
        this.getSGTechTVList();
    }

    onEdit = (value, index) => {
        let editSGTechTV = [...this.state.getSGTechTvList];
        this.setState({ redirect: '/UploadSGTechTV/sgTechTVId?=' + encryptAES(editSGTechTV[index].sgTechTVId) });
    }

    onDelete = (value, index) => {
        let editSGTechTV = [...this.state.getSGTechTvList];
        let request = {
            id: editSGTechTV[index].sgTechTVId,
            deletedBy: 115,
            appId: 15
        }
        this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Member"), CommonSuccessMessages.Confirm_Delete.replace("{0}", ""),
            CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
                if (response) {
                    this.sgtechServices.deleteSGTechTV(request).then((response) => {
                        if (response.statusCode === 200) {
                            this.swalServices.Success("Delete successfully.");
                            this.getSGTechTVList();
                        } else {
                            this.swalServices.Error(response.message);
                        }
                        this.setState({ isLoading: false });
                    });
                }
            });
    }

    handleChangeInDropdown = (selected, drpIdentity) => {
        var details = this.state.pagination;
        if (drpIdentity === "TvCategory") {
            details.filterByCategory = "";
            var dataArr = this.state.category;
            dataArr.category = selected;
            if (dataArr.category.length > 0) {
                var arr1 = dataArr.category.map(function (item) {
                    //delete item.label;
                    return item.value;
                });
                details.filterByCategory = arr1.join([',']);
            }
        }
        else if (drpIdentity === "TvStatus") {
            details.filterByStatus = "";

            var dataArr1 = this.state.status;
            dataArr1.status = selected;
            if (dataArr1.status.length > 0) {
                var arr2 = dataArr1.status.map(function (item) {
                    //delete item.label;
                    return item.value;
                });
                details.filterByStatus = arr2.join([',']);
            }
        }
        this.setState({ pagination: details },
        );
        this.getSGTechTVList();
    };

    actions = (element, index, value) => {
        return (element !== "sgTechTVId") ? null
            : 
            <td>
            <ActionContainer>
                <ActionEditButton
                    value={value}
                    id={index}
                    index={index}
                    // icon={EditIcon}
                    onActionClick={this.onEdit.bind(this)}
                    text="Edit"
                />
                <ActionDeleteButton
                    value={value}
                    id={index}
                    index={index}
                    //icon={EditIcon}
                    onActionClick={this.onDelete.bind(this)}
                    text="Delete"              
                />
            </ActionContainer>
            </td>
    }

    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <>
                <div className='main-body-section'>
                    <div className="flex">
                        <div className="relative flex flex-col flex-1 ">
                            <main className='pb-10'>
                                <div className='custom-card shadow-lg mb-10'>
                                    <div className='filter-info grid grid-cols-12 gap-6'>
                                        <div className='filter-types xl:col-span-4'>
                                            <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Search by Name</label>
                                            <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" type="text" onChange={(e) => this.handleChangeEvent(e.target.value)} />
                                        </div>
                                        <div className='filter-types xl:col-span-4'>
                                            <label className="block text-xl font-medium  mb-3 theme-color min-h-30" htmlFor="default">Status</label>
                                            <DropdownSelect
                                                drpIdentity={"Statuses"}
                                                optionArray={this.state.status}
                                                value={this.state.pagination.statusId}
                                                setFilterParameters={this.setFilterParameters.bind(this)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full mx-auto">
                                    <div className="sm:flex sm:justify-between sm:items-center mb-8">
                                        <div className="mb-4 sm:mb-0">
                                            <h1 className="text-3xl md:text-4xl theme-color font-bold">
                                                {this.state.totalResultes} Videos
                                            </h1>
                                        </div>
                                        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3">
                                            <Link to='/UploadSGTechTV' >
                                                <button className="btn btn-red text-white" >
                                                    <span className="text-xl text-white font-semibold tracking-normal"> Upload New Video</span>
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="overflow-x-auto">
                                            <Table
                                                columns={[
                                                    { name: 'featured', title: ' ' },
                                                    { name: 'videoTitle', title: 'Video Name' },
                                                    // { name: 'categoryName', title: 'Category' }, 
                                                    { name: 'sgTechTVPublishDate', title: 'Date Posted' },
                                                    { name: 'statusName', title: 'Status' },
                                                    { name: 'sgTechTVId', title: 'Action' },
                                                ]}
                                                pagination={this.state.pagination}
                                                isLoading={this.state.isLoading}
                                                rows={this.state.getSGTechTvList}
                                                sortingColumns={["statusName"]}
                                                totalResultes={this.state.totalResultes}
                                                totalCount={this.state.totalResultes}
                                                setPagination={this.setPagination.bind(this)}
                                                customScope={[
                                                    {
                                                        column: "sgTechTVId",
                                                        renderTableData: this.actions.bind(this),
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}