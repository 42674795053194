import React from "react";
import FormModal from "../../../pages/component/FormModal";
import CalculationChart from "../membershipTaskList/CalculationChart";

const MembershipFeeApprovalModal = (props) => {

  const handleChangeInPriceTable = (value, name, year, tableName) => {
    props.handleChangeInPriceTable(value, name, year, tableName);
  }

  const grantApproval = (name, year, tableName, approval) => {
    props.grantApproval(name, year, tableName, approval);
  }

  return (
    <>
      <FormModal
        id="membership-pricing-modal"
        className="membership-add-edit-modal"
        modalOpen={props.setFeeApprovalModal}
        setModalOpen={props.setModalOpen}
        title=""
      >
        <div>
          <div className="mx-7 border-b border-[#757575]">
            <div className="grid grid-cols-1 gap-6 px-8 pr-[17%]">
              <CalculationChart
                    title="Base Pricing/ Market Campaign Pricing"
                    disabled={true}
                    tableData={props.basePriceTable}
                    showButtons={false}
                    handleChangeInPriceTable={(value, name, year, tableName) => handleChangeInPriceTable(value, name, year, tableName)}
                    grantApproval={(name, year, tableName, approval) => grantApproval(name, year, tableName, approval)}
                    showStatus={false}
              />
            </div>
          </div>
          <div className="px-7 pt-8">
            <div className="grid grid-cols-1 gap-6 px-8 pr-[17%] active-inputs">
              <CalculationChart
                title="Percentage Discount Setting"
                disabled={true}
                showButtons={true}
                tableData={props.percentageDiscountTable}
                handleChangeInPriceTable={(value, name, year, tableName) => handleChangeInPriceTable(value, name, year, tableName)}
                grantApproval={(name, year, tableName, approval) => grantApproval(name, year, tableName, approval)}
                showStatus={true}
              />
            </div>
            <div className="flex justify-end mb-10">
                <button className="text-base text-white font-bold btn btn-red px-10" onClick={() => props.UpdatePriceTablesForMembershipFeeApproval()}>
                  Submit
                </button>
              </div>
          </div>
        </div>
      </FormModal>
    </>
  );
};
export default MembershipFeeApprovalModal;
