import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "./EventCalender.scss";
import EventPopup from "./EventPopup";
import ModalBasic from "../../pages/component/ModalBasic";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const EventCalender = (props) => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [creatingEvent, setCreatingEvent] = useState(false);
  const [slotInfo, setSlotInfo] = useState(null);

  useEffect(() => {
    if (props.eventList && props.eventList.length > 0) {
      const formattedEvents = props.eventList.map((event) => ({
        ...event,
        title: event.eventName,
        start: new Date(event.eventDate), // Ensure that start and end are valid Date objects
        end: new Date(event.eventDate),
      }));
      setEvents(formattedEvents);
    }
  }, [props.eventList]);

  // const handleSelectSlot = (slotInfo) => {
  //     // setSlotInfo(slotInfo);
  //     // setCreatingEvent(true);
  //     // setShowPopup(true);
  // };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setCreatingEvent(false);
    setShowPopup(true);
  };

  // const handleEventDrop = ({ event, start, end }) => {
  //   const updatedEvents = events.map((existingEvent) =>
  //     existingEvent === event ? { ...existingEvent, start, end } : existingEvent
  //   );
  //   setEvents(updatedEvents);
  // };

  // const handleEventResize = ({ event, start, end }) => {
  //   const updatedEvents = events.map((existingEvent) =>
  //     existingEvent === event ? { ...existingEvent, start, end } : existingEvent
  //   );
  //   setEvents(updatedEvents);
  // };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedEvent(null);
    setSlotInfo(null);
  };

  const setModalOpen = () => {
    setShowPopup(false)
  }
  const handleCreateOrUpdateEvent = (eventDetails) => {
    if (creatingEvent) {
      setEvents([
        ...events,
        {
          start: eventDetails.start,
          end: eventDetails.end,
          title: eventDetails.title,
        },
      ]);
    } else {
      const updatedEvents = events.map((existingEvent) =>
        existingEvent === selectedEvent
          ? { ...existingEvent, ...eventDetails }
          : existingEvent
      );
      setEvents(updatedEvents);
    }
    closePopup();
  };

  return (
    <div className="calendar-container">
      <DnDCalendar
        scrollToTime={new Date()}
        defaultView="month"
        views={["month"]}
        step={15} // 30-minute intervals
        timeslots={1} // Number of slots per interval
        localizer={localizer}
        events={events}
        selectable
        onSelectSlot={null}
        onSelectEvent={handleSelectEvent}
        onEventDrop={null}
        onEventResize={null}
        resizable = {false}
        draggableAccessor={() => false}
        // style={{ height: "100vh" }}
      />
      <>
        {/* Add popup model Code hear  */}
        
          <ModalBasic
            id="event-booking-modal"
            className="event-booking-modal"
            modalOpen={showPopup}
            setModalOpen={() => setModalOpen()}
          >
            <EventPopup
              event={selectedEvent}
              onSave={handleCreateOrUpdateEvent}
              onClose={closePopup}
              onTagging = {props.onTagging}
              onDelete = {props.onDelete}
              actionDropdown = {props.actionDropdown}
              actionDropdown2 = {props.actionDropdown2}
              actionDropdown3 = {props.actionDropdown3}
              actionClick = {props.actionClick}
            ></EventPopup>
          </ModalBasic>
        </>
    </div>
  );
};

export default EventCalender;
