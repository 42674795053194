import moment from "moment";
import React from "react";
import { NavLink } from "react-router-dom";

function Events(props) {
  return (
    <div>
      <div className="grid gap-8 py-4 grid-cols-1">
        <div className="relative justify-center detailedBlock p-4 pt-8 events rounded-lg">
          <div className="flex flex-row items-center justify-between title pl-24  ">
            <div className="icon">Events</div>
          </div>
          <div className="mb-14">
            {props.dashboardDetails.adminDashboardEventDetailList && props.dashboardDetails.adminDashboardEventDetailList.length > 0 ?
              <>
                {props.dashboardDetails.adminDashboardEventDetailList.map((event, key) => {
                  return (
                    <div className="grid gap-0 grid-cols-6 items-center text-center py-4 border-b border-[#606060] pl-24" key={key}>
                      <div className="col-span-2 text-left">
                        {event.eventName}
                      </div>
                      <div className="py-2 border-[#606060] flex justify-center align-center relative">
                        <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%]  translate-y-[-50%]"></div>
                        {moment(event.eventLessonDate).format("DD MMM YYYY")}
                      </div>
                      <div className="py-2 border-[#606060] flex justify-center align-center relative">
                        <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
                        <div>
                          <div className="inner-number">{event.bookingCount}</div>
                          <div>Bookings</div>
                        </div>
                      </div>
                      <div className="py-2 border-[#606060] flex justify-center align-center relative">
                        <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
                        <div>
                          <div className="inner-number">{event.bookingCancelledCount}</div>
                          <div>Cancellations</div>
                        </div>
                      </div>
                      <div className="py-2 border-[#606060] flex justify-center align-center relative">
                        <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
                        <div>
                          {event.availableSeats !== 0 ?
                            <div className="inner-number">{event.availableSeats}</div>
                            :
                            <div className="text-[22px] leading-10">N/A</div>
                          }
                          <div>Total Seats</div>
                        </div>
                      </div>
                    </div>
                  )
                })}

              </>
              : null}

            {/* <div className="grid gap-0 grid-cols-6 items-center text-center py-4 mb-14 pl-24">
            <div className="col-span-2 text-left">
              AI Business Matching Event
            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%]  translate-y-[-50%]"></div>
              {`18 Nov 2021`}
            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
              <div>
                <div className="inner-number">50</div>
                <div>Bookings</div>
              </div>
            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
              <div>
                <div className="inner-number">{0}</div>
                <div>Cancellations</div>
              </div>
            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
              <div>
                <div className="inner-number">{0}</div>
                <div>Available</div>
              </div>
            </div>
          </div> */}
          </div>
          <div className="bottom-btns absolute right-0 bottom-0 m-3">
            <NavLink className="btn-gray bg-gray-600 text-white px-4 py-2 rounded-lg inline-flex items-center" to="/ViewEditEntries">
              View Details
              <span className="pl-2">&#62;</span>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
