import React, { Component } from 'react'
import { Link } from "react-router-dom";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import DropdownAction from "../../../components/dropdown/DropdownAction";
import SwalServices from '../../../services/swalServices/SwalServices';
import MembershipCorporateServices from '../../../services/axiosServices/apiServices/MembershipCorporateServices';
import Table from '../../../components/table/Table';
import ActionContainer from '../../../components/UIComponent/ActionTableComponent/actionContainer';
import { Navigate } from 'react-router-dom';
import { CommonSuccessMessages } from "../../../utils/Messages";
import { encryptAES } from '../../../utils/Encryption';
import CorporateMemberServices from '../../../services/axiosServices/apiServices/CorporateMemberServices';
import moment from 'moment/moment';
import { saveAs } from "file-saver";
import { getDate } from 'date-fns';
import DatePicker from 'react-flatpickr';
import YearPicker from '../../../components/datePicker/YearPicker';
import RepresentativeDetailsModal from './RepresentativeDetailsModal';
//import CommonServices from "../../../services/axiosServices/apiServices/CommonServices";


export default class MembershipRenewalDashboard extends Component {
    constructor(props) {
        super(props)
        this.membershipCorporateServices = new MembershipCorporateServices();
        this.corporateMemberServices = new CorporateMemberServices();
        //this.CommonServices = new CommonServices();
        this.swalServices = new SwalServices();
        this.state = {
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "",
                orderFlag: 0,
                searchText: "",
                searchByUENNumber: "",
                paymentStatusId: 0,
                formStatusId: 0,
                membershipTypeId: 0,
                renewalYear: 0
            },
            isLoadingcancelInvoice:false,
            councilIndex: 0,
            setRepresentativeDetailsModal: false,
            receiverOrganisationName: "",
            renewalMemberList: [],
            totalMembersFullyRenewed: 0,
            totalMembersPaid: 0,
            totalMembersDeclared: 0,
            renewalYear: 0,
            dataGetting: false,
            represenativesData: [],
            totalResultes: 0,
            paymentStatus: [{ id: 1, name: 'Pending' }, { id: 3, name: 'Submitted' }, { id: 2, name: 'Paid' }],
            formStatus: [{ id: 1, name: 'Pending' }, { id: 3, name: 'Submitted' }, { id: 2, name: 'Updated' }],
            membershipTypes: [],
            actionDropdown: ['Activate', 'Deactivate', 'Update', 'Delete']
        }
    }


    setFilterParameters = (id, drpIdentity) => {
        let detail = this.state.pagination;
        detail.pageNo = 1;

        if (drpIdentity === "MembershipType") {
            detail["membershipTypeId"] = id;
        }

        if (drpIdentity === "PaymentStatus") {
            detail["paymentStatusId"] = id;
        }

        if (drpIdentity === "FormStatus") {
            detail["formStatusId"] = id;
        }

        if (drpIdentity === "RenewalYear") {
            detail["renewalYear"] = id === null ? 0 : id;
        }

        this.setState({ pagination: { ...detail } });
        this.getAllRenewalList();
    }

    searchByName = (value, identity) => {
        let detail = this.state.pagination;
        if (identity === "searchByCompanyName") {
            detail.pageNo = 1;
            detail.pageSize = 10;
            detail.searchText = value;
            this.setState({ pagination: { ...detail } });
        }
        else if (identity === "searchByUENNumber") {
            detail.pageNo = 1;
            detail.pageSize = 10;
            detail.searchByUENNumber = value;
            this.setState({ pagination: { ...detail } });
        }
        this.getAllRenewalList();
    }

    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getAllRenewalList();
    }

    onClose = () => {
        this.setState({ setDropdownOpen: false })
    }

    actionClick = (index, value, option) => {
        if (value && value > 0 && option === 'Deactivate') {
            this.deActivateCorporateMemnber(value);
        }
        if (value && value > 0 && option === 'Activate') {
            this.activeInActiveCorporateMember(value);
        }
        if (value && value > 0 && option === 'Update') {
            this.updateCorporateMemberUpdate(value);
        }
        if (value && value > 0 && option === 'Delete') {
            this.deleteCorporateMember(value);
        }
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ setDropdownOpen: false })
        }
    }
    deActivateCorporateMemnber = (value) => {
        let request = {
            id: value,
            statusId: 7
        }
        this.setState({ isLoading: true });
        this.membershipCorporateServices.activeInActiveCorporateMember(request).then((response) => {
            if (response.statusCode === 200) {
                this.swalServices.Success("Corporate member deactivated Successfully.");
                this.getAllRenewalList();
            } else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    activeInActiveCorporateMember = (value) => {
        let request = {
            id: value,
            statusId: 8
        }
        this.setState({ isLoading: true });
        this.membershipCorporateServices.activeInActiveCorporateMember(request).then((response) => {
            if (response.statusCode === 200) {
                this.swalServices.Success("Corporate member activated Successfully.");
                this.getAllRenewalList();
            } else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }
    cancelInvoiceAndRegenerateRenewalInvoice = (id,id2) => {
        if (id > 0 && id != null) {
            let request = [id, id2 ];
            this.setState({ isLoadingcancelInvoice: true });
          this.corporateMemberServices.cancelInvoiceAndRegenerateRenewalInvoice(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem.responseContent != null) {
                this.swalServices.Success("Invoice regenerated Successfully");
              
                this.setState({ setRepresentativeDetailsModal: false });
                this.setState({ isLoadingcancelInvoice: false });
            }
            else {
              this.swalServices.Warning("Invoice not regenerated");
              this.setState({ setRepresentativeDetailsModal: false });
              this.setState({ isLoadingcancelInvoice: false });
            }
            
          })
        }
      }
    updateCorporateMemberUpdate = (value) => {
        if (value && value > 0) {
            this.setState({ route: '/CreateCorporateMembers/CorporateMemberId?=' + encryptAES(value) });
        }
    }

    deleteCorporateMember = (value) => {
        let request = {
            id: value,
            deletedBy: 1,
            appId: 115
        }
        this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Member"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "Member"),
            CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
                if (response) {
                    this.membershipCorporateServices.deleteCorporateMember(request).then((response) => {
                        if (response.statusCode === 200) {
                            this.swalServices.Success("Deleted");
                            this.getAllRenewalList();
                        } else {
                            this.swalServices.Error(response.message);
                        }
                        this.setState({ isLoading: false });
                    });
                }
            });
    }

    actions = (element, index, value) => {
        return element !== "corporateMemberId" ? null : (
            <td>
                <ActionContainer>
                    <DropdownAction
                        key={index}
                        index={index}
                        value={value}
                        options={this.state.actionDropdown}
                        align="right"
                        onOptionClick={this.actionClick.bind(this)}
                    />
                </ActionContainer>
            </td>
        );
    };

    actions2 = (element, index, value) => {
        return element !== "organisationName" ? null : (
            <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt break-all text-center">
                <button
                    className="flex items-center justify-center"
                    onClick={(e) => {
                        e.preventDefault();
                        this.setState({ receiverOrganisationName: this.state.renewalMemberList[index].organisationName, });
                        this.showRepresentativesDetails(e, value, index);
                    }}
                >
                    <span className="text-[#3e397c] underline font-bold mr-8 2xl:text-[15px]">
                        {this.state.renewalMemberList[index].organisationName}
                    </span>
                </button>
            </td>
        );
    }

    showRepresentativesDetails = (e, id, index) => {
        this.setState({ councilIndex: index });
        e.stopPropagation();
        this.setState({ setRepresentativeDetailsModal: true });
    };

    getAllCorporateStatus = () => {
        this.membershipCorporateServices.getAllCorporateStatus().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                //this.setState({ statuses: response.responseItem.responseContent }, () => this.getAllRenewalList());
                this.getAllRenewalList();
            }
            else {
                this.getAllRenewalList();
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    getAllDropdownForCorporateMember = () => {
        this.corporateMemberServices.getAllDropdownForCorporateMember().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let membershipType = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "MembershipType");
                this.setState({ membershipTypes: membershipType });
            }
        });
    };

    getAllRenewalList = (pagination = this.state.pagination) => {
        pagination.renewalYear = this.state.pagination.renewalYear === 0 ? new Date().getFullYear() + 1 : this.state.pagination.renewalYear
        this.setState({ isLoading: true });
        this.corporateMemberServices.getAllRenewalList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let details = response.responseItem.responseContent.itemList;

                let totalMembersFullyRenewed = 0;
                let totalMembersPaid = 0;
                let totalMembersDeclared = 0;
                let renewalYear = "";

                details.map((key, index) => {
                    key.renewalDate = key.renewalDate === "Invalid date" ? "-" : moment(key.renewalDate).format("DD MMM YYYY");
                    key.paidDate = key.paidDate === "Invalid date" || !moment(key.paidDate, moment.ISO_8601, true).isValid() ? "-" : moment(key.paidDate).format("DD MMM YYYY");
                    if (key.membershipTypeName === "Associate") {
                        key.formDate = "NA";
                    } else {
                        key.formDate = key.formDate === "Invalid date" || !moment(key.formDate, moment.ISO_8601, true).isValid() ? "-" : moment(key.formDate).format("DD MMM YYYY");
                    }
                    if (key.organisationName === null || key.organisationName === "") {
                        key.organisationName = "-";
                    }
                    if (key.organisationUEN === null || key.organisationUEN === "") {
                        key.organisationUEN = "-";
                    }
                    totalMembersFullyRenewed = key.totalMembersFullyRenewed || 0;
                    totalMembersPaid = key.totalMembersPaid || 0;
                    totalMembersDeclared = key.totalMembersDeclared || 0;
                    renewalYear = key.renewalYear;
                    return key;
                });
                this.setState({ totalMembersFullyRenewed, totalMembersPaid, totalMembersDeclared, renewalYear });
                this.setState({ renewalMemberList: details }, () => this.setState({ dataGetting: true }));
                this.setState({ totalResultes: response.responseItem.responseContent.totalCount })
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    componentDidMount() {
        this.getAllCorporateStatus();
        this.getAllDropdownForCorporateMember();
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    getDateForFileName() {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}${month}${day}`;
    }

    downloadExcelForRenewalMembersRecord = () => {
        let request = { ...this.state.pagination };
        request.pageSize = 100000
        this.corporateMemberServices.downloadExcelForRenewalMembersRecord(request).then((response) => {
            if (response.status === 200 && response.data != null) {
                let blob = new Blob([response.data])
                if (blob.size > 300) {
                    saveAs(blob, `RenewalReport_${this.getDateForFileName()}.xlsx`);
                } else {
                    this.swalServices.Error("No Records Found!");
                }
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    }

    render() {
        if (this.state.route != null) {
            return <Navigate to={this.state.route} />;
        }
        return (
            <div className="main-body-section" >
                <div className="flex">
                    <div className="relative flex flex-col flex-1 "></div>
                </div>
                <main className="pb-10">
                    <div className="custom-card shadow-lg mb-4 rounded-md">
                        <div className="filter-info grid grid-cols-12 gap-6">
                            <div className="filter-types 2xl:col-span-4 lg:col-span-4 col-span-12">
                                <h2 className="block text-xl font-medium  theme-color min-h-30 " htmlFor="default" >
                                    Search by Company Name
                                </h2>
                                <input
                                    id="default"
                                    className="form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="text"
                                    onChange={(e) => this.searchByName(e.target.value, 'searchByCompanyName')}
                                />
                            </div>
                            <div className="filter-types 2xl:col-span-4 lg:col-span-4 col-span-12">
                                <h2 className="block text-xl font-medium  theme-color min-h-30 " htmlFor="default" >
                                    Search by UEN Number
                                </h2>
                                <input
                                    id="default"
                                    className="form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="text"
                                    onChange={(e) => this.searchByName(e.target.value, "searchByUENNumber")}
                                />
                            </div>

                            <div className="filter-types 2xl:col-span-4 lg:col-span-4 col-span-12">
                                <h2 className="pl-6 block text-xl font-medium  theme-color min-h-30 " htmlFor="default"  >
                                    Membership Type
                                </h2>
                                <div className="pl-6 border-l-[1px] border-[#757575]">
                                    <DropdownSelect
                                        drpIdentity={"MembershipType"}
                                        optionArray={this.state.membershipTypes.filter(option => option.id !== 4)}
                                        setFilterParameters={this.setFilterParameters}
                                        value={this.state.pagination.membershipTypeId}
                                    />
                                </div>
                            </div>

                            <div className="filter-types 2xl:col-span-3 lg:col-span-4 col-span-12">
                                <h2 className="block text-xl font-medium  theme-color min-h-30 " htmlFor="default"  >
                                    Payment Status
                                </h2>
                                <div className="">
                                    <DropdownSelect
                                        drpIdentity={"PaymentStatus"}
                                        optionArray={this.state.paymentStatus}
                                        setFilterParameters={this.setFilterParameters}
                                        value={this.state.pagination.paymentStatusId}
                                    />
                                </div>
                            </div>

                            <div className="filter-types 2xl:col-span-3 lg:col-span-4 col-span-12">
                                <h2 className="pl-6 block text-xl font-medium  theme-color min-h-30 " htmlFor="default" >
                                    Declaration Status
                                </h2>
                                <div className="pl-6 border-l-[1px] border-[#757575]">
                                    <DropdownSelect
                                        drpIdentity={"FormStatus"}
                                        optionArray={this.state.formStatus}
                                        setFilterParameters={this.setFilterParameters}
                                        value={this.state.pagination.formStatusId}
                                    />
                                </div>
                            </div>

                            <div className="filter-types 2xl:col-span-3 lg:col-span-4 col-span-12">
                                <h2 className="pl-6 block text-xl font-medium  theme-color min-h-30 " htmlFor="default" >
                                    Renewal Year
                                </h2>
                                <div className="pl-6 border-l-[1px] border-[#757575]">
                                    <YearPicker
                                        drpIdentity={"RenewalYear"}
                                        setFilterParameters={this.setFilterParameters}
                                        value={this.state.pagination.renewalYear === 0 ? new Date().getFullYear()+1 : this.state.pagination.renewalYear}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full mx-auto">
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-6 mb-4">

                            <div className="custom-card border border-gray-300 rounded-md p-4 flex items-center shadow-sm">
                                <div className="mr-4">
                                    <h3 className="text-sm font-medium text-gray-500">Renewal Year</h3>
                                    <p className="text-2xl font-semibold text-gray-800">
                                        {this.state.pagination.renewalYear ? this.state.pagination.renewalYear : (this.state.renewalYear ? this.state.renewalYear : new Date().getFullYear())}
                                    </p>
                                </div>
                            </div>

                            <div className="custom-card border border-gray-300 rounded-md p-4 flex items-center shadow-sm">
                                <div className="mr-4">
                                    <h3 className="text-sm font-medium text-gray-500">Members for Renewal</h3>
                                    <p className="text-2xl font-semibold text-gray-800">{this.state.totalResultes}</p>
                                </div>
                            </div>

                            <div className="custom-card border border-gray-300 rounded-md p-4 flex items-center shadow-sm">
                                <div className="mr-4">
                                    <h3 className="text-sm font-medium text-gray-500">Fully Renewed Members</h3>
                                    <p className="text-2xl font-semibold text-gray-800">{this.state.totalMembersFullyRenewed}</p>
                                </div>
                            </div>

                            <div className="custom-card border border-gray-300 rounded-md p-4 flex items-center shadow-sm">
                                <div className="mr-4">
                                    <h3 className="text-sm font-medium text-gray-500">Paid Members</h3>
                                    <p className="text-2xl font-semibold text-gray-800">{this.state.totalMembersPaid}</p>
                                </div>
                            </div>

                            <div className="custom-card border border-gray-300 rounded-md p-4 flex items-center shadow-sm">
                                <div className="mr-4">
                                    <h3 className="text-sm font-medium text-gray-500">Declared Members</h3>
                                    <p className="text-2xl font-semibold text-gray-800">{this.state.totalMembersDeclared}</p>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-end mb-4">
                            <button className="btn btn-red text-white flex items-center" onClick={() => this.downloadExcelForRenewalMembersRecord()}>
                                <span className="text-xl font-semibold">Export</span>
                            </button>
                        </div>



                        <div className="relative">
                            <div>
                                <div className="" onMouseLeave={() => this.onClose()} >
                                    <Table columns={[{ name: 'organisationName', title: 'Company Name' }, { name: 'membershipTypeName', title: 'Membership Type' }, { name: 'mtoRegistry', title: 'MTO Registry' },
                                    { name: 'renewalDate', title: 'Renewal Date' }, { name: 'paymentStatus', title: 'Payment Status' },
                                    { name: 'paidDate', title: 'Paid Date' }, { name: 'formStatus', title: 'Declaration Status' }, { name: 'formDate', title: 'Declaration Date' }]}
                                        rows={this.state.renewalMemberList}
                                        isLoading={this.state.isLoading}
                                        sortingColumns={[]}
                                        pagination={this.state.pagination}
                                        totalResultes={this.state.totalResultes}
                                        totalCount={this.state.totalResultes}
                                        setPagination={this.setPagination.bind(this)}
                                        customScope={[
                                            // {
                                            //     column: 'corporateMemberId',
                                            //     renderTableData: this.actions.bind(this)
                                            // },
                                            {
                                                column: "organisationName",
                                                renderTableData: this.actions2.bind(this)
                                            },
                                        ]} />
                                </div>
                            </div>
                        </div>
                        <div className="members-modal-sec membersdirectoery">
                            {this.state.dataGetting === true &&
                                this.state.renewalMemberList.length > 0 ? (
                                <RepresentativeDetailsModal
                                    councilIndex={this.state.councilIndex}
                                    getMemberDirectoryData={this.state.renewalMemberList}
                                    setRepresentativeDetailsModal={this.state.setRepresentativeDetailsModal}
                                    charLimit={this.state.charLimit}
                                    setOpenModal={() => this.setState({ setRepresentativeDetailsModal: false })}
                                    onClose={(e) => { e.stopPropagation(); this.setState({ setRepresentativeDetailsModal: false }); }}
                                    cancelInvoiceAndRegenerateRenewalInvoice= {this.cancelInvoiceAndRegenerateRenewalInvoice.bind(this)}
                                    isLoadingcancelInvoice = {this.state.isLoadingcancelInvoice}
                                />
                            ) : null}

                        </div>
                    </div>
                </main>
            </div>
        )
    }
}