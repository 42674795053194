import { axiosGetAuthorize, axiosPostAuthorize } from '../AxiosRequests';
import { GetPrivilegesList, GetPrivilegesById, AddEditPrivileges, DeletePrivileges } from '../ApiEndPoints';

export default class PrivilegesServices {

    async getPrivilegesList(request) {
        return axiosPostAuthorize(GetPrivilegesList, request);
    }

    async getPrivilegesById(request) {
        return axiosGetAuthorize(GetPrivilegesById, request);
    }

    async addEditPrivileges(request) {
        return axiosPostAuthorize(AddEditPrivileges, request);
    }

    async deletePrivileges(request) {
        return axiosPostAuthorize(DeletePrivileges, request);
    }
}
