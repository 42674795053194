import {  axiosGetAuthorize } from '../AxiosRequests';
import { GetAllEntries, GetAdminDashboardDetail } from '../ApiEndPoints';

export default class DashboardServices {

    async getAllEntries(request) {
        return axiosGetAuthorize(GetAllEntries, request);
    }

    async getAdminDashboardDetail(request) {
        return axiosGetAuthorize(GetAdminDashboardDetail, request);
    }
}