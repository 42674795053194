// Import react libraries and components
import React, { Component } from 'react';
import Table from '../../components/table/Table';
import moment from 'moment';

//import API services
import GoGlobalServices from '../../services/axiosServices/apiServices/GoGlobalService';
import SwalServices from '../../services/swalServices/SwalServices';

export default class ClickAnalytics extends Component {
    constructor(props) {
        super(props);
        this.GoGlobalServices = new GoGlobalServices();
        this.SwalServices = new SwalServices();
        this.state = {
            redirect: null,
            pagination: {
                orderByColumn: "CreatedAt",
                orderFlag: 0,
                pageNo: 1,
                pageSize: 10,
                searchText: "",
                dateOfClicks: "",
            },
            totalResultes: "",
            councilIndex: 0,
            isLoading: false,
            setMembershipDirectoryEnquiry: false,
            sendEscalation: false,
            clickAnalyticsList: [],
        }
    }

    //Method declaration section
    componentDidMount() {
        this.getAllGoGlobalClickAnalyticsList();
    }

    //API method declaration start

    // Get all go global click analytics list
    getAllGoGlobalClickAnalyticsList = (pagination = this.state.pagination) => {
        this.setState({ isLoading: true });
        this.GoGlobalServices.getAllGoGlobalClickAnalyticsList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let newClickAnalyticsList = response.responseItem.responseContent.itemList;
                newClickAnalyticsList.forEach((key, index) => {
                    key.companyProfileVisitedDate = moment(key.companyProfileVisitedDate).format("DD MMM YYYY ");
                });
                this.setState({ clickAnalyticsList: newClickAnalyticsList });

                this.setState({ totalResultes: response.responseItem.responseContent.totalCount })
            } else {
                this.SwalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    };

    //Internal method declaration section start
    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getAllGoGlobalClickAnalyticsList();
    };

    // Handle change for search company name
    searchByName = (value, identity) => {
        let detail = this.state.pagination;
        if (identity === "companyName") {
            detail.pageNo = 1;
            detail.pageSize = 10;
            detail.searchText = value;
            this.setState({ pagination: { ...detail } });
        }
    };

    // Handle change for request date
    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = this.state.pagination;
        detail[name] = value;
        this.setState({
            pagination: { ...detail }
        });
    }

    render() {
        return (
            <div className="main-body-section">
                <div className="custom-card shadow-lg">
                    <div className="filter-info grid grid-cols-12 gap-6">
                        <div className="xl:col-span-12 lg:col-span-6 col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="filter-types xl:col-span-3 lg:col-span-5 col-span-12 pr-12">
                                    <label
                                        className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                        htmlFor="default"
                                    >
                                        Company Name
                                    </label>
                                    <input
                                        id="senderEmail"
                                        name="senderEmail"
                                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                        type="text"
                                        onChange={(event) => this.searchByName(event.target.value, "companyName")}
                                    />
                                </div>
                                <div className="filter-types xl:col-span-3 lg:col-span-5 col-span-12 pr-12">
                                    <label
                                        className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                        htmlFor="default"
                                    >
                                        Date
                                    </label>
                                    <input
                                        id="dateOfClicks"
                                        name="dateOfClicks"
                                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                        type="date"
                                        value={this.state.pagination.dateOfClicks}
                                        onChange={(event) => this.handleChange(event)}
                                    />
                                </div>
                                <div className="filter-types xl:col-span-3 lg:col-span-5 col-span-12 pr-12">
                                </div>
                                <div className="xl:col-span-3 lg:col-span-6 col-span-12">
                                    <div className=" patner-search flex items-center justify-between pt-11">
                                        <div className="search">
                                            <button
                                                className="btn btn-red text-xl text-white font-bold"
                                                onClick={() => {
                                                    this.getAllGoGlobalClickAnalyticsList();
                                                }}
                                            >
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <main className="pb-10 pt-8 click-analytics-table">
                    <div className="w-full mx-auto">
                        <div className="relative">
                            <div>
                                <div className=" mt-6">
                                    <Table
                                        columns={[
                                            { name: "companyName", title: "Company Name", },
                                            { name: "companyProfileVisitedCount", title: "Total Number of Clicks" },
                                            { name: "companyProfileVisitedDate", title: "Date of Clicks" },
                                        ]}
                                        pagination={this.state.pagination}
                                        rows={this.state.clickAnalyticsList}
                                        sortingColumns={["date"]}
                                        isLoading={this.state.isLoading}
                                        totalResultes={this.state.totalResultes}
                                        totalCount={this.state.totalResultes}
                                        setPagination={this.setPagination.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}