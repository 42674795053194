import { axiosGetAuthorize, axiosPostAuthorize } from '../AxiosRequests';
import {
    GetAllRecipientsDropdown,
    GetEmailById,
    AddEditEmail,
    GetEmailHistoryList,
    DeleteEmailDetailsById,
} from '../ApiEndPoints';

export default class EmailManagementServices {


    async getEmailHistoryList(request) {
        return axiosPostAuthorize(GetEmailHistoryList, request);
    }

    async deleteEmailDetailsById(request) {
        return axiosGetAuthorize(DeleteEmailDetailsById, request)
    }



    async getAllRecipientsDropdown(request) {
        return axiosPostAuthorize(GetAllRecipientsDropdown, request)
    }


    async getEmailById(request) {
        return axiosGetAuthorize(GetEmailById, request);
    }

    async addEditEmail(request) {
        return axiosPostAuthorize(AddEditEmail, request)
    }

}