import React, { useState } from "react";
import PageLoader from "../common/pageLoader/PageLoader";
import { isNullBoolean } from "../../utils/Utils";

const defaultScopeAction = (element, value) => {
  return value ? (
    <td className="text-lg px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div
        className={`inline-flex font-medium rounded-full text-center bg-emerald-500 px-2.5 py-0.5 `}
      >
        Active
      </div>
    </td>
  ) : (
    <td className=" text-lg px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div
        className={`inline-flex font-medium rounded-full text-center  bg-red-500 px-2.5 py-0.5 `}
      >
        In Active
      </div>
    </td>
  );
};
const availabilityAction = (element, value) => {
  return (
    <>
      {(() => {
        switch (value) {
          case 1:
            return (
              <td className="text-lg px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div
                  className={`inline-flex rounded-full text-center bg-emerald-500 px-2.5 py-0.5 `}
                >
                  <i className=" icon-sm fal fa-check-circle fw-bold mt-1 mr-1"></i>{" "}
                  In Stock
                </div>
              </td>
            );
          case 2:
            return (
              <td className="text-lg px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div
                  className={`inline-flex rounded-full text-center bg-emerald-500 px-2.5 py-0.5 `}
                >
                  <i className=" icon-sm fal fa-history fw-bold mt-1 mr-1"></i>{" "}
                  Backordered
                </div>
              </td>
            );

          case 3:
            return (
              <td className="text-lg px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div
                  className={`inline-flex rounded-full text-center bg-emerald-500 px-2.5 py-0.5 `}
                >
                  <i className="icon-sm fal fa-ban fw-bold mt-1 mr-1"></i>{" "}
                  Suspended
                </div>
              </td>
            );

          case 4:
            return (
              <td className="text-lg px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div
                  className={`inline-flex rounded-full text-center bg-emerald-500 px-2.5 py-0.5 `}
                >
                  <i className=" icon-sm fal fa-conveyor-belt-alt fw-bold mt-1 mr-1"></i>{" "}
                  In Production
                </div>
              </td>
            );
          default:
            return null;
        }
      })()}
    </>
  );
};

const defaultScope = [
  {
    column: "isActive",
    renderTableData: defaultScopeAction.bind(this),
  },
  {
    column: "availabilityId",
    renderTableData: availabilityAction.bind(this),
  },
];

//Dynamic row binding into the table
export function Rows(props) {
  return (
    props?.columns &&
    props.columns.map((element, index) => {
      return (
        <React.Fragment key={`${props.index}_${index}`}>{


          props.customScope &&
            props.customScope.filter(
              (c) => c.column.toLowerCase() === element.name.toLowerCase()
            ).length > 0 ? (
            props.customScope
              .filter(
                (c) => c.column.toLowerCase() === element.name.toLowerCase()
              )[0]
              .renderTableData(
                element.name,
                props.index,
                props.tableData[
                Object.keys(props.tableData).find((objKey) =>
                  objKey.toLowerCase() === element.valueColumnName
                    ? element.valueColumnName.toLowerCase()
                    : element.name.toLowerCase()
                )
                ]
              )
          ) : props.defaultScope &&
            props.defaultScope.filter(
              (c) => c.column.toLowerCase() === element.name.toLowerCase()
            ).length > 0 ? (
            props.defaultScope
              .filter(
                (c) => c.column.toLowerCase() === element.name.toLowerCase()
              )[0]
              .renderTableData(
                element.name,
                props.tableData[
                Object.keys(props.tableData).find(
                  (objKey) => objKey.toLowerCase() === element.name.toLowerCase()
                )
                ]
              )
          ) : (
            <td
              key={`${props.index}_${index}`}
              className={
                props.isCollapsedSection
                  ? "px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575]"
                  : "px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575]"
              }
              onClick={() =>
                props.isCollapsedSection
                  ? props.setCollapse(props.index, props.isCollapsed)
                  : null
              }
            >
              {
                props.tableData[
                Object.keys(props.tableData).find(
                  (objKey) => objKey.toLowerCase() === element.name.toLowerCase()
                )
                ]
              }
            </td>
          )}
        </React.Fragment>
      )


    })
  );
}

const Table = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [basePageNo, setBasePageNo] = useState(1);
  // Sorting handler
  const handleSorting = (columnName, value) => {
    //props.pagination.pageNo = 1;
    props.pagination.orderByColumn = columnName;
    props.pagination.orderFlag = value ? 1 : 0;
    props.setPagination(props.pagination, props.tableIdentity);
  };

  const handlePageChange = (pageNumber, isButton = false) => {
    if (
      props.pagination.pageNo !== pageNumber &&
      pageNumber > 0 &&
      pageNumber <= Math.ceil(props.totalCount / props.pagination.pageSize)
    ) {
      if (pageNumber > props.pagination.pageNo && pageNumber - basePageNo > 1) {
        setBasePageNo(pageNumber + 1);
      } else if (
        pageNumber < props.pagination.pageNo &&
        pageNumber > 1 &&
        basePageNo - pageNumber === 0
      ) {
        setBasePageNo(pageNumber - 1);
      } else if (isButton && pageNumber > 1) {
        setBasePageNo(pageNumber - 1);
      }
      props.pagination.pageNo = pageNumber;
      props.setPagination(props.pagination, props.tableIdentity);
      setInputValue(pageNumber);
    }
  };

  const [inputValue, setInputValue] = React.useState(props.pagination.pageNo);

  const onChangeHandler = (event) => {
    const re = /^[0-9\b]+$/;
    if (inputValue === "" || re.test(inputValue)) {
      setInputValue(inputValue);
      if (event.key === "Enter") {
        handlePageChange(Number(inputValue), true);
        // alert(`You must have pressed Enter - ${event.target.value} `);
      }
      // handlePageChange(Number(5), true);
      // handlePageChange(event.target.value + inputValue, true);
    }
  };

  return (
    <div>
      {props.isLoading ? (
        <PageLoader />
      ) : (
        <div key={props?.tableIdentity ? props.tableIdentity : null}>
          <div className="overflow-x-auto main-table-section-section">
            <table className="w-full bg-white shadow-lg rounded-sm border border-slate-200">
              {/* Table header */}
              <thead className="table-bg text-xs font-semibold capitalize text-[#757575] border-t border-b border-slate-200 ">
                <tr>
                  {
                    props?.columns.map((value, key) => {
                      return (
                        <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white font-bold tble-th-txt text-left" key={key}>
                          {value.title}
                          {props.sortingColumns.includes(value.name) ? (
                            props.pagination.orderFlag === 1 &&
                              props.pagination.orderByColumn.toLowerCase() ===
                              value.name.toLowerCase() ? (
                              <i
                                className="fas fa-angle-double-down pl-3"
                                onClick={() =>
                                  handleSorting(
                                    value.name,
                                    !props.pagination.orderFlag
                                  )
                                }
                              ></i>
                            ) : (
                              <i
                                className="fas fa-angle-double-up pl-3"
                                onClick={() =>
                                  handleSorting(
                                    value.name,
                                    !props.pagination.orderFlag
                                  )
                                }
                              ></i>
                            )
                          ) : null}
                        </th>
                      );
                    })}
                </tr>
              </thead>
              {/* Table body */}
              <tbody className="text-sm custom-tbody">
                {props?.rows && props.rows.length > 0 ? (
                  props.rows.map((value, key) => {
                    return (
                      <React.Fragment key={key}>
                        <tr className="custom-tbl-td">
                          <Rows
                            // key={key}
                            columns={props.columns}
                            tableData={value}
                            customScope={props.customScope}
                            defaultScope={defaultScope}
                            index={key}
                            setCollapse={props.setCollapse}
                            isCollapsedSection={props.isCollapsedSection}
                            isCollapsed={value.isCollapsed}
                          />
                        </tr>
                        {value.isCollapsed
                          ? props.collapsibleSection(value)
                          : null}
                      </React.Fragment>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan={props.columns.length}
                      className="text-center p-4 text-lg"
                    >
                      <i className="fas fa-empty-set pr-2"></i>No Records Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* Approve Buttons Start*/}


          {props.isRequiredButton1 === true ? (
            <div className="w-full mt-5">
              <div className="pt-7 flex items-center justify-between w-full">
                <button className={`btn text-xl border text-white ${props.button1Disable ? 'cursor-not-allowed bg-[#e9e9ea]' : 'btn-red'}`}
                  onClick={props.button1Click}
                  disabled={!!props.button1Disable}
                >
                  {props.buttonText1}
                </button>
                {props.isRequiredButton2 === true ? (
                  <button className={`btn text-xl border text-white ${props.button2Disable ? 'cursor-not-allowed bg-[#e9e9ea]' : 'btn-red '}`}
                    onClick={props.button2Click}
                    disabled={!!props.button2Disable}
                  >
                    {props.buttonText2}
                  </button>
                ) : null}

              </div>
            </div>
          ) : null}
          {/* Approve Buttons End*/}
          {isNullBoolean(props.isPaginationNotRequired) === false ?
            <>
              <div className="mt-5">
                <div className="text-sm text-[#757575] text-center sm:text-left mb-5">
                  <div className="no-of-page-title flex justify-end my-10 mx-5">
                    <p className="text-xl">
                      Showing{" "}
                      <span className="font-medium text-slate-600">
                        {props.pagination.pageNo * props.pagination.pageSize -
                          props.pagination.pageSize +
                          1}
                      </span>{" "}
                      to{" "}
                      <span className="font-medium text-slate-600">
                        {props.totalResultes < (props.pagination.pageNo * props.pagination.pageSize)
                          ? (props.pagination.pageNo * props.pagination.pageSize) + (props.totalResultes - (props.pagination.pageNo * props.pagination.pageSize))
                          // (props.pagination.pageSize - (props.pagination.pageNo * props.pagination.pageSize - props.totalResultes))
                          : (props.pagination.pageNo * props.pagination.pageSize)}
                      </span>{" "}
                      of{" "}
                      <span className="font-medium text-slate-600">
                        {props.totalResultes}
                      </span>{" "}
                      results
                    </p>
                  </div>
                </div>
                <nav
                  className="flex justify-between"
                  role="navigation"
                  aria-label="Navigation"
                >
                  <div className="grow text-center w-full">
                    <div>
                      <nav
                        className="flex items-center justify-around"
                        role="navigation"
                        aria-label="Navigation"
                      >
                        <div className="mr-2">
                          {/* className="btn bg-white border-slate-200 theme-color hover:text-[#C00000] py-4 pagination-btn" */}
                          <a
                            href="#/"
                            className={`btn bg-white border-slate-200  py-4 2xl:min-w-[350px] lg:min-w-[200px] min-w-[100px] ${props.pagination.pageNo > 1
                              ? "btn bg-white  theme-color hover:text-[#C00000] py-4 pagination-btn"
                              : "text-slate-300 cursor-not-allowed hover:text-slate-300"
                              }`}
                            disabled={props.pagination.pageNo <= 1}
                            onClick={() =>
                              handlePageChange(props.pagination.pageNo - 1, true)
                            }
                          >
                            <div className="flex items-center justify-between">
                              <span className="mr-4">
                                <svg
                                  width="17px"
                                  className="rotate-180"
                                  height="17px"
                                  viewBox="-5 0 23 23"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g
                                    id="icons"
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="#cbd5e1"
                                    fillRule="evenodd"
                                  >
                                    <g
                                      id="ui-gambling-website-lined-icnos-casinoshunter"
                                      transform="translate(-484.000000, -159.000000)"
                                    >
                                      <g
                                        id="square-filled"
                                        transform="translate(50.000000, 120.000000)"
                                      >
                                        <path
                                          d="M435.76062,39.2902857 L446.638635,49.7417043 L446.699713,49.7959169 C446.885605,49.9745543 446.985701,50.2044182 447,50.4382227 L447,50.5617773 C446.985701,50.7955818 446.885605,51.0254457 446.699713,51.2040831 L446.646163,51.2479803 L435.76062,61.7097143 C435.357854,62.0967619 434.704841,62.0967619 434.302075,61.7097143 C433.899308,61.3226668 433.899308,60.6951387 434.302075,60.3080911 L444.451352,50.5617773 L434.302075,40.6919089 C433.899308,40.3048613 433.899308,39.6773332 434.302075,39.2902857 C434.704841,38.9032381 435.357854,38.9032381 435.76062,39.2902857 Z"
                                          id="right"
                                        ></path>
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </span>
                              <span className="2xl:text-xl lg:text-lg text-base">
                                Previous
                              </span>
                            </div>
                          </a>
                        </div>
                        <div className="">
                          <ul className="flex items-center text-sm font-medium -space-x-px">
                            <li className="flex item-center">
                              <input
                                className="inline-flex items-center justify-center leading-5 px-2 text-center h-[40px] w-[80px] text-xl bg-white border border-slate-200 theme-color shadow-sm"
                                type="text"
                                name="name"
                                onChange={(event) => setInputValue(event.target.value)}
                                onKeyPress={(e) => onChangeHandler(e)}
                                value={inputValue}
                              />
                            </li>

                            <li className="flex item-center">
                              <span className="inline-flex items-center justify-center leading-5 px-2 py-2 text-xl mx-4 theme-color">
                                Of
                              </span>
                            </li>
                            <li className="flex item-center">
                              <a
                                className="inline-flex items-center justify-center leading-5 px-2 py-2 text-xl text-[#C00000] hover:text-[#aa0000]"
                                href="#0"
                              >
                                <span className="w-5">
                                  {" "}
                                  {Math.ceil(
                                    props.totalCount / props.pagination.pageSize
                                  )}
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="ml-2">
                          <a
                            href="#/"
                            className={`btn bg-white border-slate-200 theme-color py-4 2xl:min-w-[350px] lg:min-w-[200px] min-w-[100px] ${Math.ceil(
                              props.totalCount / props.pagination.pageSize
                            ) > props.pagination.pageNo
                              ? "btn bg-white  theme-color hover:text-[#C00000] py-4 pagination-btn"
                              : "text-slate-300 cursor-not-allowed hover:text-slate-300"
                              }`}
                            onClick={() =>
                              handlePageChange(props.pagination.pageNo + 1, true)
                            }
                          >
                            <div className="flex items-center justify-between">
                              <span className="2xl:text-xl lg:text-lg text-base">
                                Next
                              </span>
                              <span className="ml-4">
                                <svg
                                  width="17px"
                                  height="17px"
                                  viewBox="-5 0 23 23"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g
                                    id="icons"
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="#cbd5e1"
                                    fillRule="evenodd"
                                  >
                                    <g
                                      id="ui-gambling-website-lined-icnos-casinoshunter"
                                      transform="translate(-484.000000, -159.000000)"
                                    >
                                      <g
                                        id="square-filled"
                                        transform="translate(50.000000, 120.000000)"
                                      >
                                        <path
                                          d="M435.76062,39.2902857 L446.638635,49.7417043 L446.699713,49.7959169 C446.885605,49.9745543 446.985701,50.2044182 447,50.4382227 L447,50.5617773 C446.985701,50.7955818 446.885605,51.0254457 446.699713,51.2040831 L446.646163,51.2479803 L435.76062,61.7097143 C435.357854,62.0967619 434.704841,62.0967619 434.302075,61.7097143 C433.899308,61.3226668 433.899308,60.6951387 434.302075,60.3080911 L444.451352,50.5617773 L434.302075,40.6919089 C433.899308,40.3048613 433.899308,39.6773332 434.302075,39.2902857 C434.704841,38.9032381 435.357854,38.9032381 435.76062,39.2902857 Z"
                                          id="right"
                                        ></path>
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </span>
                            </div>
                          </a>
                        </div>
                      </nav>
                    </div>
                  </div>
                </nav>
              </div>

              {/* <div className="mt-1">
                <Pagination
                  className="pagination"
                  currentPage={currentPage}
                  totalCount={props.columns.length}
                  pageSize={props.pagination.pageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div> */}
            </>
            : null}
        </div>
      )}
    </div>
  );
};

export default Table;
