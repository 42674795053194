import React, { Component } from 'react'
import PrivilegesServices from '../../../services/axiosServices/apiServices/PrivilegesServices';
import SwalServices from '../../../services/swalServices/SwalServices';
import { Link, Navigate } from "react-router-dom";
import Table from '../../../components/table/Table'
import { getAuthProps } from '../../../utils/AuthenticationLibrary';
import moment from 'moment';
import CommonServices from '../../../services/axiosServices/apiServices/CommonServices';
import DropdownSelect from '../../../components/dropdown/Dropdown'
import ActionDeleteButton from '../../../components/UIComponent/ActionTableComponent/ActionDeleteButton';
import ActionContainer from '../../../components/UIComponent/ActionTableComponent/actionContainer';
import ActionDropdownButton from '../../../components/UIComponent/ActionTableComponent/ActionDropdownButton';

export default class Privileges extends Component {
    constructor(props) {
        super(props)
        this.privilegeServices = new PrivilegesServices();
        this.CommonServices = new CommonServices();
        this.swalServices = new SwalServices();
        this.authUser = getAuthProps()
        this.state = {
            setDropdownOpen: false,
            setDropdownIndex: 0,
            totalResultes: "",
            statusId: [0],
            getPrivileges: [],
            status: [],
            isLoading: false,
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "",
                orderFlag: 0,
                searchText: "",
                status: 0,
                name: "Privileges"
            },
        }
        this.wrapperRef = React.createRef();

    }

    actions = (element, index, value) => {
        return (element !== "privilegesId") ? null
            : 
            <td>
            <ActionContainer>
   
                <ActionDropdownButton
                    value={value}
                    id={index}
                    index={index}
                    wrapperRef={this.wrapperRef}
                    setDropdownIndex={this.state.setDropdownIndex}
                    setDropdownOpen={this.state.setDropdownOpen}
                    onDropdownAction={this.onDropDownChange}
                    onActionClick1={this.redirectToCreateResources}
                    //onActionClick2={this.redirectToApproveResources}
                    text1="View/Edit"
                    text2="Post Approval"
                />
                <ActionDeleteButton
                    value={value}
                    id={index}
                    index={index}
                    onActionClick={this.onDelete}
                    text="Delete"
                />
            </ActionContainer>
            </td>

    }




    getPrivlegesList = (pagination = this.state.pagination) => {
        this.setState({ isLoading: true });
        this.privilegeServices.getPrivilegesList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ getPrivileges: response.responseItem.responseContent.itemList });
                this.setState({ totalResultes: response.responseItem.responseContent.totalCount })
                let newgGetPrivileges = response.responseItem.responseContent.itemList;
                newgGetPrivileges.map((key, index) => {
                    key.startDate = moment(key.startDate).format('DD MMM YYYY');
                    key.endDate = moment(key.endDate).format('DD MMM YYYY');
                    return key;
                })
                this.setState({ getPrivileges: newgGetPrivileges });
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    redirectToCreateResources = (value) => {
        this.setState({ redirect: '/CreatePrivilegesPost/privilegesId?=' + value });
    }
    onEdit = (value, index) => {
        let editPrivileges = [...this.state.getPrivileges];
        this.setState({ redirect: '/CreatePrivilegesPost/privilegesId?=' + editPrivileges[index].privilegesId });
    }

    onDelete = (privilegesId) => {
        let request = {
            id: privilegesId,
            deletedBy: 2,
            appId: 3
        }
        this.privilegeServices.deletePrivileges(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.swalServices.Success("Deleted");
                this.getPrivlegesList();
            } else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    getAllStatus = () => {
        this.CommonServices.getAllStatus().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ status: response.responseItem.responseContent });
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    onDropDownChange = (index) => {
        this.setState({ setDropdownOpen: true });
        this.setState({ setDropdownIndex: index });
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ setDropdownOpen: false })
        }
    }
    onClose = () =>{
        this.setState({ setDropdownOpen: false })
      }
    handleChangeEvent = (value) => {

        let detail = this.state.pagination;
        detail.searchText = "";
        this.setState({ pagination: { ...detail } });
        if (value.length > 2) {
            detail.searchText = value;
            this.setState({ pagination: { ...detail } });

        }
        this.getPrivlegesList();
    }

    setFilterParameters = (id, drpIdentity) => {
        let detail = this.state.pagination;

        if (drpIdentity === "Statuses") {
            detail.status = id;
            this.setState({ pagination: { ...detail } });


        }
        this.getPrivlegesList();
    }

    setPagination = (newPagination) => {

        this.setState({ pagination: newPagination });
        this.getPrivlegesList();
    }

    componentDidMount() {
        this.getPrivlegesList();
        this.getAllStatus();
        document.addEventListener("mousedown", this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
      }

    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <div className='main-body-section'>
                <div className="flex">
                    <div className="relative flex flex-col flex-1 ">
                    </div>
                </div>
                <main className='pb-10'>
                    <div className='custom-card shadow-lg mb-10'>
                        <div className='filter-info grid grid-cols-12 gap-6'>
                            <div className='filter-types xl:col-span-4'>
                                <label className="block text-xl font-medium mb-3 theme-color min-h-30" htmlFor="default"  >Search by Name</label>
                                <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" type="text" onChange={(e) => this.handleChangeEvent(e.target.value)} />
                            </div>

                            <div className='filter-types xl:col-span-4'>
                                <label className="block text-xl font-medium  mb-3 theme-color min-h-30" htmlFor="default">Status</label>
                                <DropdownSelect
                                    drpIdentity={"Statuses"}
                                    optionArray={this.state.status}
                                    setFilterParameters={this.setFilterParameters.bind(this)}
                                    value={this.state.statusId} />
                            </div>
                        </div>
                    </div>
                    <div className="w-full mx-auto">
                        <div className="sm:flex sm:justify-between sm:items-center mb-8">
                            <div className="mb-4 sm:mb-0">
                                <h1 className="table-title-txt theme-color font-bold">
                                    {this.state.totalResultes} Positions
                                </h1>
                            </div>
                            <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3">
                                <Link to='/CreatePrivilegesPost' >
                                    <button className="btn btn-red text-white" >
                                        <span className="text-xl text-white font-semibold tracking-normal"> Create New </span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                        {/* Table */}
                        <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative pb-8">
                            <div>
                                {/* Table */}
                                <div className="overflow-x-auto" onMouseLeave={() => this.onClose()}>
                                    <Table columns={[{ name: 'title', title: 'post Title' }, { name: 'startDate', title: 'Start Date' }, { name: 'endDate', title: 'End Date' }, { name: 'statusName', title: 'Status' }, { name: 'privilegesId', title: 'Actions' }]}
                                        isLoading={this.state.isLoading}
                                        rows={this.state.getPrivileges}
                                        sortingColumns={["postTitle", "startDate", "endDate", "statusName"]}
                                        pagination={this.state.pagination}
                                        totalResultes={this.state.totalResultes}
                                        totalCount={this.state.totalResultes}
                                        setPagination={this.setPagination.bind(this)}
                                        customScope={[
                                            {
                                                column: 'privilegesId',
                                                renderTableData: this.actions.bind(this)
                                            },
                                        ]} />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}