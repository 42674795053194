import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import CreateAdmin from "../../components/accountManagement/createAdminAccount/CreateAdmin";
import AuthenticationServices from "../../services/axiosServices/apiServices/AuthenticationServices";
import CommonServices from "../../services/axiosServices/apiServices/CommonServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { getAuthProps } from "../../utils/AuthenticationLibrary";
import { CommonValidationMessages } from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import ChangePassword from "../authentication/ChangePassword"
import { checkParameters } from "../../utils/Encryption";

export default class CreateAdminAccount extends Component {
  constructor(props) {
    super(props);
    this.adminServices = new AuthenticationServices();
    this.commonServices = new CommonServices();
    this.swalServices = new SwalServices();
    this.state = {
      openChangePasswordModal: false,
      adminId: 0,
      adminDetails: {
        adminID: 0,
        name: "",
        lastName: "",
        email: "",
        password: "",
        roleId: "",
        profilePic: "",
        selectedRole: [],
        createdBy: 0,
        createdAppId: 0,
      },
      route: "",
      adminRoleList: [],
      createdBy: 0,
      createdAppId: 0,
      updatedBy: 0,
      updatedAppId: 0,
      adminName: '',
      validationRules: {
        name: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "first name"),
          },
        ],
        lastName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "last name"),
          },
        ],
        email: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "email address"),
          },
          {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid email address"),
          },
        ],
        password: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "password"),
          },
          {
            type: "password",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid password"),
          },
        ],
        selectedRole: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "admin role"),
          },
        ],
        // profilePic: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.SelectRequired.replace("{0}", "profile pic"),
        //   },
        // ],
      },
      validState: {
        isValid: true,
        error: {},
      },
    };
  }

  ValidateAdminDetails = (key, object) => {
    const returnValidState = validate(key, this.state.adminDetails, this.state.validationRules, this.state.validState);
    this.setState({ validState: returnValidState, });
  };

  isValidateAllDetails = () => {
    if (this.state.adminId > 0) {
      let valRule = { ...this.state.validationRules };
      delete valRule.password;
      this.setState({ validationRules: valRule });

    }
    const returnValidState = isValidForm(this.state.adminDetails, this.state.validationRules, this.state.validState);
    this.setState({ validState: returnValidState });
    return returnValidState.isValid;
  };

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let adminId = params.get("");
    if (adminId && (adminId = checkParameters(adminId, "Number"))) {
      //this.setState({ adminId: adminId });
      // For Set Updated by and Created by based on admin login
      let cookie = getAuthProps();
      const adminUserId = cookie?.adminUserId ? Number(cookie.adminUserId) : 0;
      const adminName = cookie?.name ? cookie.name : '';
      if (adminId > 0) {
        this.setState({ adminId: adminId, updatedBy: adminUserId, updatedAppId: 114, adminName: adminName });
      } else {
        this.setState({ adminId: adminId, createdBy: adminUserId, createdAppId: 114, adminName: adminName });
      }
      // END

      // this.getAdminDetailById(1);
    }
    this.getRolesForAdmin();
  }
  addEditAdminDetails = () => {
    if (this.isValidateAllDetails()) {
      let request = this.state.adminDetails;
      if (request.adminID && request.adminID > 0) {
        request.createdBy = this.state.updatedBy;
        request.createdAppId = this.state.updatedAppId;
      } else {
        request.createdBy = this.state.createdBy;
        request.createdAppId = this.state.createdAppId;
      }
      if (this.state.adminDetails.selectedRole.length > 0) {
        var arr1 = this.state.adminDetails.selectedRole.map(function (item) { delete item.bad; return item.value; });
        request.roleId = arr1.join([","]);
      }

      this.adminServices.addEditAdminDetails(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.errorColumn === "Email") {
          this.swalServices.Error("Email address already exists.");
        }
        else if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success("Admin details updated successfully.");
          this.setState({ route: "/ViewEditAdminAccount" });
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }
  getAdminDetailById = (id) => {
    this.adminServices.getAdminDetailById(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
            let res = { ...this.state.adminDetails };
            let ids2 = response.responseItem.responseContent.roleId;
            var array = (ids2 && ids2 !== '0') ? ids2.split(',') : [];
            res.selectedRole = [];

            array.forEach(topic => {
                const foundRole = this.state.adminRoleList.find(topics => topics.value === Number(topic));
                if (foundRole) {
                    res.selectedRole.push(foundRole);
                }
            });

            let details = { ...response.responseItem.responseContent, selectedRole: res.selectedRole };
            this.setState({ adminDetails: details });
        } else {
            this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
    });
};

closeBuyChapterModal = () => {
  this.setState({ openChangePasswordModal: false })
};

openBuyChapterModal = () => {
  this.setState({ openChangePasswordModal: true })
};


  handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    let detail = { ...this.state.adminDetails }
    detail[name] = value;
    this.setState({ adminDetails: detail });
  };

  getRolesForAdmin = () => {
    this.adminServices.getRolesForAdmin().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let array = [];
        array = response.responseItem.responseContent;
        array = array.map((p) => ({
          value: p.id,
          label: p.name,
        }));

        this.setState({ adminRoleList: array }, () => {
          if (this.state.adminId && this.state.adminId > 0) {
            this.getAdminDetailById(this.state.adminId);
          }
        });

      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }

  saveMainImage = (file, keyId, imageIdentity) => {
    file.storagePath = "Admin";
    this.commonServices.storeBase64Image(file).then((response) => {
      if (response != null) {
        this.swalServices.Success("File Uploaded");
        if (file.storagePath === "Admin") {
          let detail = this.state.adminDetails;
          detail["profilePic"] = response.fileName;
          this.setState({ adminDetails: { ...detail } });
        }
      }
      else {
        this.swalServices.Error(response.message);
      }
    })
  }

  handleChangeMultiDropdown(selected, drpIdentity) {
    let profile = this.state.adminDetails;
    if (selected.length > 0) {
      profile.selectedRole = selected;
    } else {
      profile.selectedRole = 0
    }
    profile.roleId = selected.value;
    this.setState({ adminDetails: profile });
    this.ValidateAdminDetails("selectedRole");
   }

  render() {
    if (this.state.route != null && this.state.route !== "") {
      return <Navigate to={this.state.route} />;
    }
    return (
      <div className="main-body-section">
        <div className="custom-card shadow-lg bg-white">
          <CreateAdmin
            adminDetails={this.state.adminDetails}
            handleChange={this.handleChange.bind(this)}
            adminRoleList={this.state.adminRoleList}
            handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
            saveMainImage={this.saveMainImage.bind(this)}
            ValidateAdminDetails={this.ValidateAdminDetails.bind(this)}
            validState={this.state.validState}
            adminId={this.state.adminId}
          />
          
        </div>
       
        <div className="grid grid-cols-12 gap-6 mt-14">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <a href="#."
              className="flex items-center justify-end"
            >
              {this.state.adminId > 0 ?
                <button className="w-32 px-7 py-3 btn-sm btn btn-red text-xl text-white font-semibold"
                  onClick={() => this.addEditAdminDetails()}
                >
                  Update
                </button>
                :
                <button className="w-32 px-7 py-3 btn-sm btn btn-red text-xl text-white font-semibold"
                  onClick={() => this.addEditAdminDetails()}
                >
                  Create
                </button>
              }


            </a>
          </div>
        </div>
      </div>
    );
  }
}
