import React from 'react'
import PageLoader from '../common/pageLoader/PageLoader';
import ActionDeleteButton from '../UIComponent/ActionTableComponent/ActionDeleteButton';

export default function PartnerTable(props) {
    return (
        <div className='mb-2 overflow-x-auto'>
            {props.isLoading ? <PageLoader /> :
                <table className="partner-tbl w-full mx-auto my-5 fixed_header">
                    <thead >
                        <tr className="text-base font-bold flex">
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[25%]">
                                <div className="font-bold tble-th-txt text-left">Government Partners</div>
                            </th>
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[10%]">
                                <div className="font-bold tble-th-txt text-center">Key Agency</div>
                            </th>
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[20%]">
                                <div className="font-bold tble-th-txt text-center">Number Of Project</div>
                            </th>
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[20%]">
                                <div className="font-bold tble-th-txt text-center">Strategic Member</div>
                            </th>
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[15%]">
                                <div className="font-bold tble-th-txt text-center">SGTech OIC</div>
                            </th>
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[10%]">
                                <div className="font-bold tble-th-txt text-center">Action</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="scrollit text-sm custom-tbody ">
                        {
                            props?.govtPartnersList && props.govtPartnersList.length > 0 ?
                                (
                                    props.govtPartnersList.map((data, key) => {
                                        return (
                                            <tr className='custom-tbl-td flex' key={key}>
                                                <td className=" px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[25%]">
                                                    {data.agencyName}
                                                </td>
                                                <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[10%]">
                                                    <div className="dollar-sign-icon inline-block">
                                                        {data.isKeyAgency}
                                                    </div>
                                                </td>
                                                <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                                                    <div className="dollar-sign-icon inline-block">
                                                        {data.noOfProject}
                                                    </div>
                                                </td>
                                                <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                                                    {data.noOfStrategicMember}
                                                </td>
                                                <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[15%]">
                                                    {data.sgTechSecretariat}
                                                </td>
                                                <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[10%]">
                                                    <ActionDeleteButton
                                                        onActionClick={() => props.onDeleteGovernmentPartner(data.agencyId)}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                )
                                :
                                (
                                    <tr className='grid justify-items-center'>
                                        <td className="text-lg text-[#757575]	">
                                            No Records Found
                                        </td>
                                    </tr>
                                )
                        }
                        {/* {
                        props.govtPartnersList.map((data, key) => {
                            return (
                                <tr className='custom-tbl-td flex'>
                                    <td className=" px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[25%]">
                                        {data.agencyName}
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[10%]">
                                        <div className="dollar-sign-icon inline-block">
                                            {data.isKeyAgency}
                                        </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                                        <div className="dollar-sign-icon inline-block">
                                            {data.noOfProject}
                                        </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                                        {data.noOfStrategicMember}
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[15%]">
                                        {data.sgTechSecretariat}
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[10%]">
                                        <ActionDeleteButton
                                            onActionClick={() => props.onDeleteGovernmentPartner(data.agencyId)}
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    } */}
                    </tbody>
                </table>
            }
        </div>
    );
}