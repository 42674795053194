import React, { Component } from "react";
import SwalServices from "../../services/swalServices/SwalServices";
import ValidationText from "../../utils/validation/ValidationText";
import FinanceServices from "../../services/axiosServices/apiServices/FinanceServices";
import moment from "moment";
import SelectorWithoutDefaultValue from "../../components/dropdown/commonDropdown/SelectorWithoutDefaultValue";
import SelectMemberModel from "../../components/financial/SelectMemberModel";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import { CommonValidationMessages } from "../../utils/Messages";
import { Navigate } from "react-router-dom";
import { checkParameters } from "../../utils/Encryption";

export default class CreateVoucherCode extends Component {
  constructor(props) {
    super(props);

    this.swalServices = new SwalServices();
    this.financeServices = new FinanceServices();

    this.state = {
      voucherCodeId: 0,
      selectAllMembersPopup: false,
      allMemberListingData: [],
      selectedMemberListingData: [],
      voucherCodeDetails: {
        voucherCodeId: 0,
        voucherCode: "",
        voucherCodeType: "Percentage",
        voucherCodeTypeValue: "",
        voucherCodeStartDate: "",
        voucherCodeEndDate: "",
        voucherCodeUsedPerMembership: 1,
        voucherCodeUsedPerEmail: 1,
        isUnlimited: "false",
        voucherCodeUserLimit: "",
        isDiscountToEntireOrder: "true",
        isDiscountToMainRegistrant: "",
        isDiscountToEachRegistrantInEntireOrder: "",
        voucherCodeUsedPerAccount: "",
        minimumNumberToApply: 1,
        minimumAmountToSpend: 1,
        isForOrdinaryMember: true,
        isForAssociateMember: true,
        isForQualifyingMember: true,
        isForNonMember: true,
        isActiveVoucherCode: false,
        isAllOrdinaryMember: "true",
        isAllAssociateMember: "true",
        isAllQualifyingMember: "true",
        isAllChapterMember: "true",
        voucherValidMemberList: [],
        voucherValidChapterList: [],
        isForMembership: true,
        isForIndividual: false,
        isForDTSME: false,
        isDisabled: false,
        voucherCodeUsedCount: 0,
        voucherType: "isForMembership",
      },
      minimumAmountToSpendMsg: "",
      minimumNumberToApplyMsg: "",
      voucherCodeTypeValueMsg: "",
      numberOfTimePerMembershipArray: [
        {
          value: 1,
          label: "1",
        },
        {
          value: 2,
          label: "2",
        },
        {
          value: 3,
          label: "3",
        },
        {
          value: 4,
          label: "4",
        },
        {
          value: 5,
          label: "5",
        },
        {
          value: 6,
          label: "6",
        },
        {
          value: 7,
          label: "No Limits",
        },
      ],

      allAssociateMember: [],
      allQualifyingMember: [],
      allChapter: [],

      allOrdinaryMember: [],
      selectedOrdinaryMember: [],

      selectedAssociateMember: [],
      selectedQualifyingMember: [],
      selectedChapter: [],

      memberId: 0,
      validState: {
        isValid: true,
        error: {},
      },
      validationRule: {
        voucherCode: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "voucher code"
            ),
          },
        ],
        voucherCodeTypeValue: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              `value`
            ),
          },
        ],
        voucherCodeStartDate: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "start date for voucher code"
            ),
          },
        ],
        voucherCodeEndDate: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "end date for voucher code"
            ),
          },
        ],
        voucherCodeUsedPerMembership: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "how many times voucher code can be used"
            ),
          },
        ],
        voucherCodeUsedPerEmail: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "how many times voucher code can be used"
            ),
          },
        ],
        // voucherCodeUserLimit: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "user limits"
        //     ),
        //   },
        // ],
        // voucherCodeUsedPerAccount: [
        //   {
        //     type: 'require',
        //     message: CommonValidationMessages.FieldRequired.replace('{0}', 'how many times voucher code can be used per account'),

        //   },],
        // minimumAmountToSpend: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "minimum number of guest to apply"
        //     ),
        //   },
        // ],
        // minimumNumberToApply: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "minimum amount to apply"
        //     ),
        //   },
        // ],
      },
      redirect: "",
      isValidForAllMembers: true,
      allMemberSearchValue: "",
      selectedMemberSearchValue: "",
    };
  }
  componentDidMount() {
    let idString = window.location.href;
    let str_array = idString.split("/");
    let id = str_array[str_array.length - 1];
    let voucherId = 0;

    if (
      id &&
      id !== "CreateVoucherCode" &&
      (voucherId = checkParameters(id, "Number"))
    ) {
      if (voucherId && voucherId > 0) {
        this.setState({ voucherCodeId: voucherId });
      }
    }
    this.getAllMemberListForVoucher();
  }

  getVoucherCodeDetailsById = (id) => {
    this.financeServices.getVoucherCodeDetailsById(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent != null) {
          let details = { ...response.responseItem.responseContent };
          if (details.voucherCodeUsedCount > 0) {
            details.isDisabled = true;
          } else {
            details.isDisabled = false;
          }

          if (
            details.isForOrdinaryMember === true &&
            details.isForAssociateMember === true &&
            details.isForQualifyingMember === true &&
            details.isForNonMember === true
          ) {
            this.setState({ isValidForAllMembers: true });
          } else {
            this.setState({ isValidForAllMembers: false });
          }

          let selOrdiArr = [];
          let selAssoArr = [];
          let selQualiArr = [];
          let selChaptArr = [];

          let allOrdArr = [...this.state.allOrdinaryMember];
          let allAssoArr = [...this.state.allAssociateMember];
          let allQualiArr = [...this.state.allQualifyingMember];
          let allchaptArr = [...this.state.allChapter];
          if (details.voucherValidMemberList.length > 0) {
            details.voucherValidMemberList.map((mem) => {
              mem.isSelected = false;
              mem.isAllSelected = false;
              mem.isShowData = true;
              if (mem.membershipTypeId === 1) {
                selOrdiArr.push(mem);
                allOrdArr = allOrdArr.filter(
                  (x) => x.memberId !== mem.memberId
                );
              } else if (mem.membershipTypeId === 2) {
                selAssoArr.push(mem);
                allAssoArr = allAssoArr.filter(
                  (x) => x.memberId !== mem.memberId
                );
              } else if (mem.membershipTypeId === 3) {
                selQualiArr.push(mem);
                allQualiArr = allQualiArr.filter(
                  (x) => x.memberId !== mem.memberId
                );
              }
           
              return mem;
            });
          }
          if (details.voucherValidChapterList.length > 0) {
            details.voucherValidChapterList.map((mem) => {
              mem.isSelected = false;
              mem.isAllSelected = false;
              mem.isShowData = true;

              selChaptArr.push(mem);
              allchaptArr = allchaptArr.filter(
                (x) => x.workgroupMappingId !== mem.workgroupMappingId
              );
              return mem;
            });
          }
          if (details.isUnlimited === true) {
            details.isUnlimited = "true";
          } else {
            details.isUnlimited = "false";
          }
          if (details.isForMembership === true) {
            details.voucherType = "isForMembership";
          } else if (details.isForDTSME === true) {
            details.voucherType = "isForDTSME";
          } else {
            details.voucherType = "isForIndividual";
          }
          if (details.isDiscountToEntireOrder === true) {
            details.isDiscountToEntireOrder = "true";
          } else {
            details.isDiscountToEntireOrder = "false";
          }
          if (details.isDiscountToMainRegistrant === true) {
            details.isDiscountToMainRegistrant = "true";
          } else {
            details.isDiscountToMainRegistrant = "false";
          }
          if (details.isDiscountToEachRegistrantInEntireOrder === true) {
            details.isDiscountToEachRegistrantInEntireOrder = "true";
          } else {
            details.isDiscountToEachRegistrantInEntireOrder = "false";
          }
          if (details.isAllOrdinaryMember === true) {
            details.isAllOrdinaryMember = "true";
          } else {
            details.isAllOrdinaryMember = "false";
          }
          if (details.isAllAssociateMember === true) {
            details.isAllAssociateMember = "true";
          } else {
            details.isAllAssociateMember = "false";
          }
          if (details.isAllQualifyingMember === true) {
            details.isAllQualifyingMember = "true";
          } else {
            details.isAllQualifyingMember = "false";
          }
          if (details.isAllChapterMember === true) {
            details.isAllChapterMember = "true";
          } else {
            details.isAllChapterMember = "false";
          }

          details.voucherCodeUsedPerEmail =
            details.voucherCodeUsedPerEmail === 1000
              ? "No Limits"
              : details.voucherCodeUsedPerEmail;
          details.voucherCodeUsedPerMembership =
            details.voucherCodeUsedPerMembership === 1000
              ? "No Limits"
              : details.voucherCodeUsedPerMembership;

          this.setState({
            voucherCodeDetails: details,
            allOrdinaryMember: allOrdArr,
            selectedOrdinaryMember: selOrdiArr,
            allAssociateMember: allAssoArr,
            selectedAssociateMember: selAssoArr,
            allQualifyingMember: allQualiArr,
            selectedQualifyingMember: selQualiArr,
            allChapter: allchaptArr,
            selectedChapter: selChaptArr,
          });
        }
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  getAllMemberListForVoucher = () => {
    this.financeServices.getAllMemberListForVoucher().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent != null) {
          let detail = response.responseItem.responseContent;

          let allOrdinaryMemberArr = [];
          let allAssociateMemberArr = [];
          let allQualifyingMemberArr = [];

          detail.map((key, index) => {
            key.isSelected = false;
            key.isAllSelected = false;
            key.isShowData = true;
            if (key.membershipTypeName === "Ordinary Member") {
              allOrdinaryMemberArr.push(key);
            }
            if (key.membershipTypeName === "Associate Member") {
              allAssociateMemberArr.push(key);
            }
            if (key.membershipTypeName === "Qualifying Member") {
              allQualifyingMemberArr.push(key);
            }
          return key;
          });

          this.setState(
            {
              allMemberListingData: response.responseItem.responseContent,
              allOrdinaryMember: allOrdinaryMemberArr,
              allAssociateMember: allAssociateMemberArr,
              allQualifyingMember: allQualifyingMemberArr,
            },
            () => {
              this.getAllChapterListForVoucher();

              if (this.state.voucherCodeId && this.state.voucherCodeId > 0) {
                this.getVoucherCodeDetailsById(this.state.voucherCodeId);
              }
            }
          );
        }
      } else {
        this.getAllChapterListForVoucher();
        this.swalServices.Error(response.message);
      }
    });
  };

  getAllChapterListForVoucher = () => {
    this.financeServices.getAllChapterListForVoucher().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent != null) {
          let detail = response.responseItem.responseContent;

          detail.map((key, index) => {
            key.isSelected = false;
            key.isAllSelected = false;
            key.isShowData = true;
          return key;
          });

          this.setState({
            allMemberListingData: response.responseItem.responseContent,
            allChapter: detail,
          });
        }
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  isValidIndividualMember = () => {
    let request = { ...this.state.voucherCodeDetails };
    let isValid = true;
    if (request.voucherType === "isForIndividual") {
      if (
        request.minimumAmountToSpend === null ||
        request.minimumAmountToSpend === "0" ||
        request.minimumAmountToSpend === ""
      ) {
        this.setState({
          minimumAmountToSpendMsg: "Please enter minimum amount",
        });
        isValid = false;
      } else {
        this.setState({ minimumAmountToSpendMsg: "" });
      }
      if (
        request.minimumNumberToApply === null ||
        request.minimumNumberToApply === "0" ||
        request.minimumNumberToApply === ""
      ) {
        this.setState({
          minimumNumberToApplyMsg: "Please enter minimum number",
        });
        isValid = false;
      } else {
        this.setState({ minimumNumberToApplyMsg: "" });
      }
    }
    return isValid;
  };

  isUnlimitedCheck = () => {
    let request = { ...this.state.voucherCodeDetails };
    let isValid = true;
    if (request.isUnlimited === "false" || request.isUnlimited === false) {
      if (
        request.voucherCodeUserLimit === null ||
        request.voucherCodeUserLimit === "0" ||
        request.voucherCodeUserLimit === ""
      ) {
        this.setState({ voucherCodeUserLimitMsg: "Please enter user limit" });
        isValid = false;
      } else {
        this.setState({ voucherCodeUserLimitMsg: "" });
      }
    }
    return isValid;
  };
  isCheckPercentage = () => {
    let request = { ...this.state.voucherCodeDetails };
    let isValid = true;
    // let num = Number(request.voucherCodeTypeValue);
    if (
      request.voucherCodeType === "Percentage" &&
      (Number(request.voucherCodeTypeValue) > 100 ||
        parseInt(request.voucherCodeTypeValue) < 0)
    ) {
      this.setState({
        voucherCodeTypeValueMsg: "Please enter value between 1 to 100",
      });
      isValid = false;
    } else if (
      request.voucherCodeType === "Amount" &&
      request.voucherCodeTypeValue < 0
    ) {
      this.setState({ voucherCodeTypeValueMsg: "Please enter positive value" });
      isValid = false;
    } else if (
      request.voucherCodeType === "Fixed Price" &&
      request.voucherCodeTypeValue < 0
    ) {
      this.setState({ voucherCodeTypeValueMsg: "Please enter positive value" });
      isValid = false;
    } else {
      this.setState({ voucherCodeTypeValueMsg: "" });
    }

    return isValid;
  };
  addEditVoucherCodeDetails = () => {

    let request = { ...this.state.voucherCodeDetails };

    let isIndividual = this.isValidIndividualMember();
    let unlimited = this.isUnlimitedCheck();
    let percentage = this.isCheckPercentage();

    if (this.isValidateAllFields() && isIndividual && unlimited && percentage) {

      request.voucherCodeId = this.state.voucherCodeId;
      request.voucherCodeUsedPerEmail = request.voucherCodeUsedPerEmail === "No Limits" ? 1000 : request.voucherCodeUsedPerEmail;
      request.voucherCodeUsedPerMembership = request.voucherCodeUsedPerMembership === "No Limits" ? 1000 : request.voucherCodeUsedPerMembership;
      if (request.isUnlimited === "true") {
        request.isUnlimited = true;
        request.voucherCodeUserLimit = "";
      } else {
        request.isUnlimited = false;
      }
      if (request.voucherType === "isForMembership") {
        request.isForMembership = true;
      } else if (request.voucherType === "isForDTSME") {
        request.isForDTSME = true;
        request.isForMembership = false
      }
      else {
        request.isForMembership = false;
      }
      if (request.isDiscountToEntireOrder === "true") {
        request.isDiscountToEntireOrder = true;
      } else {
        request.isDiscountToEntireOrder = false;
      }
      if (request.isDiscountToMainRegistrant === "true") {
        request.isDiscountToMainRegistrant = true;
      } else {
        request.isDiscountToMainRegistrant = false;
      }
      if (request.isDiscountToEachRegistrantInEntireOrder === "true") {
        request.isDiscountToEachRegistrantInEntireOrder = true;
      } else {
        request.isDiscountToEachRegistrantInEntireOrder = false;
      }
      if (request.isAllOrdinaryMember === "true") {
        request.isAllOrdinaryMember = true;
      } else {
        request.isAllOrdinaryMember = false;
      }
      if (request.isAllAssociateMember === "true") {
        request.isAllAssociateMember = true;
      } else {
        request.isAllAssociateMember = false;
      }
      if (request.isAllQualifyingMember === "true") {
        request.isAllQualifyingMember = true;
      } else {
        request.isAllQualifyingMember = false;
      }
      if (request.isAllChapterMember === "true") {
        request.isAllChapterMember = true;
      } else {
        request.isAllChapterMember = false;
      }
      request.voucherValidMemberList = [];
      request.voucherValidChapterList = [];
      if (
        request.isForOrdinaryMember === true &&
        request.isAllOrdinaryMember === false
      ) {
        if (this.state.selectedOrdinaryMember.length > 0) {
          request.voucherValidMemberList =
            request.voucherValidMemberList.concat(
              this.state.selectedOrdinaryMember
            );
        }
      }
      if (
        request.isForAssociateMember === true &&
        request.isAllAssociateMember === false
      ) {
        if (this.state.selectedAssociateMember.length > 0) {
          request.voucherValidMemberList =
            request.voucherValidMemberList.concat(
              this.state.selectedAssociateMember
            );
        }
      }
      if (
        request.isForQualifyingMember === true &&
        request.isAllQualifyingMember === false
      ) {
        if (this.state.selectedQualifyingMember.length > 0) {
          request.voucherValidMemberList =
            request.voucherValidMemberList.concat(
              this.state.selectedQualifyingMember
            );
        }
      }
      if (
        request.isForChapterMember === true &&
        request.isAllChapterMember === false
      ) {
        if (this.state.selectedChapter.length > 0) {
          request.voucherValidChapterList =
            request.voucherValidChapterList.concat(this.state.selectedChapter);
        }
      }
      request.createdBy = 1;
      request.updatedBy = 1;
      request.createdAppId = 115;
      request.updatedAppId = 115;

      if (request.voucherValidMemberList.length > 0) {
        let arr1 = request.voucherValidMemberList.map(function (item) {
          if (
            item.voucherUsedForMemberId &&
            item.voucherUsedForMemberId != null
          ) {
            return item.voucherUsedForMemberId;
          }
          return item;
        });
        request.previouslySelectedMemberIds = arr1.join([","]);
      } else {
        request.previouslySelectedMemberIds = "";
      }

      if (request.voucherValidChapterList.length > 0) {
        let arr1 = request.voucherValidChapterList.map(function (item) {
          if (
            item.voucherUsedForChapterId &&
            item.voucherUsedForChapterId != null
          ) {
            return item.voucherUsedForChapterId;
          }
          return item;
        });
        request.previouslySelectedChapterIds = arr1.join([","]);
      } else {
        request.previouslySelectedChapterIds = "";
      }

      if (this.state.voucherCodeUserLimit === "") {
        request.voucherCodeUserLimit = 1
      }
      this.financeServices
        .addEditVoucherCodeDetails(request)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (
              response.responseItem.responseContent.errorColumn ===
              "VoucherCode"
            ) {
              this.swalServices.Warning("Voucher code already exist.");
            } else if (response.responseItem.responseContent != null) {
              if (this.state.voucherCodeId > 0) {
                this.swalServices.Success("Voucher details updated successfully");
              } else {
                this.swalServices.Success("Voucher details inserted successfully");
                this.setState({ voucherCodeId: response.responseItem.responseContent.keyId })
              }

              this.getVoucherCodeDetailsById(
                response.responseItem.responseContent.keyId
              );
            } else {
              this.swalServices.Error(response.message);
            }
          } else {
            this.swalServices.Error(response.messsage);
          }
        });

    }
  };



  handleChange = (event, identity) => {
    const value = event.target.value;
    const name = event.target.name;
    let detail = { ...this.state.voucherCodeDetails };
    detail[name] = value;
    this.setState({ voucherCodeDetails: detail });
  };

  handleRadioButtons(event, type) {
    const value = event.target.value;
    const id = event.target.id;
    const name = event.target.name;
    let detail = this.state.voucherCodeDetails;
    if (
      name === "isDiscountToEntireOrder" ||
      name === "isDiscountToMainRegistrant" ||
      name === "isDiscountToEachRegistrantInEntireOrder"
    ) {
      detail["isDiscountToEntireOrder"] = false;
      detail["isDiscountToMainRegistrant"] = false;
      detail["isDiscountToEachRegistrantInEntireOrder"] = false;

      detail[id] = value;
      detail[name] = value;
    }
    if (type === "isForMembership") {
      detail["voucherType"] = "isForMembership";
    } else if (type === "isForIndividual") {
      detail["voucherType"] = "isForIndividual";
    }
    // else if (type === 'isForDTSME') {
    //   detail['isForDTSME'] = value;
    // }
    else {
      detail[name] = value;
    }
    if (name === "isUnlimited" && (value === "true" || value === true)) {
      this.setState({ voucherCodeUserLimitMsg: "" });
    }
    this.setState({ voucherCodeDetails: detail });
  }

  handleChangeInDropdown = (selected, drpIdentity) => {
    let detail = this.state.voucherCodeDetails;
    if (drpIdentity === "VoucherCodeUsedPerMembership") {
      if (selected.value === 0) {
        detail.voucherCodeUsedPerMembership = "";
      } else {
        detail.voucherCodeUsedPerMembership = selected.label;
      }
      this.setState({ voucherCodeDetails: detail }, () => {
        this.validateField("voucherCodeUsedPerMembership");
      });
    } else if (drpIdentity === "VoucherCodeUsedPerEmail") {
      if (selected.value === 0) {
        detail.voucherCodeUsedPerEmail = "";
      } else {
        detail.voucherCodeUsedPerEmail = selected.label;
      }
      this.setState({ voucherCodeDetails: detail }, () => {
        this.validateField("voucherCodeUsedPerEmail");
      });
    }
  };
  handleChangeCheckBox = (e) => {
    const { name, checked } = e.target;
    let det = { ...this.state.voucherCodeDetails };
    det[name] = checked;
    this.setState({ voucherCodeDetails: det }, () => {
      let voucherDetails = { ...this.state.voucherCodeDetails };
      if (
        voucherDetails.isForOrdinaryMember === true &&
        voucherDetails.isForAssociateMember === true &&
        voucherDetails.isForQualifyingMember === true &&
        voucherDetails.isForChapterMember === true &&
        voucherDetails.isForNonMember === true
      ) {
        this.setState({ isValidForAllMembers: true });
      } else {
        this.setState({ isValidForAllMembers: false }, () => {
          if (
            voucherDetails.isDiscountToEntireOrder === true ||
            voucherDetails.isDiscountToEntireOrder === "true"
          ) {
            voucherDetails["isDiscountToEntireOrder"] = false;
            voucherDetails["isDiscountToMainRegistrant"] = false;
            voucherDetails["isDiscountToEachRegistrantInEntireOrder"] = false;

            voucherDetails["isDiscountToMainRegistrant"] = "true";
            this.setState({ voucherCodeDetails: voucherDetails });
          }
        });
      }
    });
  };
  handleChangeCheckBoxForMembers = (e, identity, index, type) => {
    const { checked } = e.target;

    if (identity === "Ordinary Members") {
      if (type === "All") {
        let allMemberArr = [...this.state.allOrdinaryMember];

        if (checked === true) {
          allMemberArr[index].isAllSelected = true;
        } else {
          allMemberArr[index].isAllSelected = false;
        }
        this.setState({ allOrdinaryMember: allMemberArr });
      } else if (type === "Selected") {
        let selectedMemberArr = [...this.state.selectedOrdinaryMember];

        if (checked === true) {
          selectedMemberArr[index].isSelected = true;
        } else {
          selectedMemberArr[index].isSelected = false;
        }
        this.setState({ selectedOrdinaryMember: selectedMemberArr });
      }
    } else if (identity === "Associate Members") {
      if (type === "All") {
        let allMemberArr = [...this.state.allAssociateMember];

        if (checked === true) {
          allMemberArr[index].isAllSelected = true;
        } else {
          allMemberArr[index].isAllSelected = false;
        }
        this.setState({ allAssociateMember: allMemberArr });
      } else if (type === "Selected") {
        let selectedMemberArr = [...this.state.selectedAssociateMember];

        if (checked === true) {
          selectedMemberArr[index].isSelected = true;
        } else {
          selectedMemberArr[index].isSelected = false;
        }
        this.setState({ selectedAssociateMember: selectedMemberArr });
      }
    } else if (identity === "Qualifying Members") {
      if (type === "All") {
        let allMemberArr = [...this.state.allQualifyingMember];

        if (checked === true) {
          allMemberArr[index].isAllSelected = true;
        } else {
          allMemberArr[index].isAllSelected = false;
        }
        this.setState({ allQualifyingMember: allMemberArr });
      } else if (type === "Selected") {
        let selectedMemberArr = [...this.state.selectedQualifyingMember];

        if (checked === true) {
          selectedMemberArr[index].isSelected = true;
        } else {
          selectedMemberArr[index].isSelected = false;
        }
        this.setState({ selectedQualifyingMember: selectedMemberArr });
      }
    } else if (identity === "Chapter Members") {
      if (type === "All") {
        let allChapterArr = [...this.state.allChapter];

        if (checked === true) {
          allChapterArr[index].isAllSelected = true;
        } else {
          allChapterArr[index].isAllSelected = false;
        }
        this.setState({ allChapter: allChapterArr });
      } else if (type === "Selected") {
        let selectedChapterArr = [...this.state.selectedChapter];

        if (checked === true) {
          selectedChapterArr[index].isSelected = true;
        } else {
          selectedChapterArr[index].isSelected = false;
        }
        this.setState({ selectedChapter: selectedChapterArr });
      }
    }
  };

  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.voucherCodeDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.voucherCodeDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  assignMembers = (identity) => {
    if (identity === "Ordinary Members") {
      let allOrdiMemberArr = [...this.state.allOrdinaryMember];
      let selectedMemberArr = [...this.state.selectedOrdinaryMember];
      let notSelArr = allOrdiMemberArr;

      let selArr = [];
      allOrdiMemberArr.map((all) => {
        if (all.isAllSelected === true) {
          selArr.push(all);
          notSelArr = notSelArr.filter(
            (x) => x.corporateMemberId !== all.corporateMemberId
          );
        }
        return all;
      });
      let array3 = selectedMemberArr.concat(selArr);
      array3.map((x) => {
        x.isSelected = false;
        x.isAllSelected = false;
        x.isShowData = true;
        return x;
      });
      this.setState({
        selectedOrdinaryMember: array3,
        allOrdinaryMember: notSelArr,
      });
    } else if (identity === "Associate Members") {
      let allOrdiMemberArr = [...this.state.allAssociateMember];
      let selectedMemberArr = [...this.state.selectedAssociateMember];
      let notSelArr = allOrdiMemberArr;

      let selArr = [];
      allOrdiMemberArr.map((all) => {
        if (all.isAllSelected === true) {
          selArr.push(all);
          notSelArr = notSelArr.filter(
            (x) => x.corporateMemberId !== all.corporateMemberId
          );
        }
        return all;
      });
      let array3 = selectedMemberArr.concat(selArr);
      array3.map((x) => {
        x.isSelected = false;
        x.isAllSelected = false;
        x.isShowData = true;
        return x;
      });
      this.setState({
        selectedAssociateMember: array3,
        allAssociateMember: notSelArr,
      });
    } else if (identity === "Qualifying Members") {
      let allOrdiMemberArr = [...this.state.allQualifyingMember];
      let selectedMemberArr = [...this.state.selectedQualifyingMember];
      let notSelArr = allOrdiMemberArr;

      let selArr = [];
      allOrdiMemberArr.map((all) => {
        if (all.isAllSelected === true) {
          selArr.push(all);
          notSelArr = notSelArr.filter(
            (x) => x.corporateMemberId !== all.corporateMemberId
          );
        }
        return all;
      });
      let array3 = selectedMemberArr.concat(selArr);
      array3.map((x) => {
        x.isSelected = false;
        x.isAllSelected = false;
        x.isShowData = true;
        return x;
      });
      this.setState({
        selectedQualifyingMember: array3,
        allQualifyingMember: notSelArr,
      });
    } else if (identity === "Chapter Members") {
      let allChapterArr = [...this.state.allChapter];
      let selectedChapterArr = [...this.state.selectedChapter];
      let notSelArr = allChapterArr;

      let selArr = [];
      allChapterArr.map((all) => {
        if (all.isAllSelected === true) {
          selArr.push(all);
          notSelArr = notSelArr.filter(
            (x) => x.workgroupMappingId !== all.workgroupMappingId
          );
        }
        return all;
      });
      let array3 = selectedChapterArr.concat(selArr);
      array3.map((x) => {
        x.isSelected = false;
        x.isAllSelected = false;
        x.isShowData = true;
        return x;
      });
      this.setState({
        selectedChapter: array3,
        allChapter: notSelArr,
        allSelectedOrdinaryIds: [],
      });
    }
  };
  UnassignMembers = (identity) => {
    if (identity === "Ordinary Members") {
      let selectedMemberArr = [...this.state.selectedOrdinaryMember];
      let allOrdiMemberArr = [...this.state.allOrdinaryMember];
      let notSelArr = selectedMemberArr;

      let selArr = [];
      selectedMemberArr.map((all) => {
        if (all.isSelected === true) {
          selArr.push(all);
          notSelArr = notSelArr.filter(
            (x) => x.corporateMemberId !== all.corporateMemberId
          );
        }
        return all;
      });
      let array3 = allOrdiMemberArr.concat(selArr);
      array3.map((x) => {
        x.isSelected = false;
        x.isAllSelected = false;
        x.isShowData = true;
        return x;
      });
      this.setState({
        selectedOrdinaryMember: notSelArr,
        allOrdinaryMember: array3,
      });
    } else if (identity === "Associate Members") {
      let selectedMemberArr = [...this.state.selectedAssociateMember];
      let allOrdiMemberArr = [...this.state.allAssociateMember];
      let notSelArr = selectedMemberArr;

      let selArr = [];
      selectedMemberArr.map((all) => {
        if (all.isSelected === true) {
          selArr.push(all);
          notSelArr = notSelArr.filter(
            (x) => x.corporateMemberId !== all.corporateMemberId
          );
        }
        return all;
      });
      let array3 = allOrdiMemberArr.concat(selArr);
      array3.map((x) => {
        x.isSelected = false;
        x.isAllSelected = false;
        x.isShowData = true;
        return x;
      });
      this.setState({
        selectedAssociateMember: notSelArr,
        allAssociateMember: array3,
      });
    } else if (identity === "Qualifying Members") {
      let selectedMemberArr = [...this.state.selectedQualifyingMember];
      let allOrdiMemberArr = [...this.state.allQualifyingMember];
      let notSelArr = selectedMemberArr;

      let selArr = [];
      selectedMemberArr.map((all) => {
        if (all.isSelected === true) {
          selArr.push(all);
          notSelArr = notSelArr.filter(
            (x) => x.corporateMemberId !== all.corporateMemberId
          );
        }
        return all;
      });
      let array3 = allOrdiMemberArr.concat(selArr);
      array3.map((x) => {
        x.isSelected = false;
        x.isAllSelected = false;
        x.isShowData = true;
        return x;
      });
      this.setState({
        selectedQualifyingMember: notSelArr,
        allQualifyingMember: array3,
      });
    } else if (identity === "Chapter Members") {
      let selectedChapterArr = [...this.state.selectedChapter];
      let allChapterArr = [...this.state.allChapter];
      let notSelArr = selectedChapterArr;

      let selArr = [];
      selectedChapterArr.map((all) => {
        if (all.isSelected === true) {
          selArr.push(all);
          notSelArr = notSelArr.filter(
            (x) => x.workgroupMappingId !== all.workgroupMappingId
          );
        }
        return all;
      });
      let array3 = allChapterArr.concat(selArr);
      array3.map((x) => {
        x.isSelected = false;
        x.isAllSelected = false;
        x.isShowData = true;
        return x;
      });
      this.setState({ selectedChapter: notSelArr, allChapter: array3 });
    }
  };

  searchByName = (value, memberType, type, isClearValue = false) => {
    if (memberType === "Ordinary Members") {
      if (type === "All" || isClearValue === true) {
        let memberArr = this.getSearchedArray(value, [
          ...this.state.allOrdinaryMember,
        ]);
        this.setState({
          allOrdinaryMember: memberArr,
          allMemberSearchValue: value,
        });
      }
      if (type === "Selected" || isClearValue === true) {
        let memberArr = this.getSearchedArray(value, [
          ...this.state.selectedOrdinaryMember,
        ]);
        this.setState({
          selectedOrdinaryMember: memberArr,
          selectedMemberSearchValue: value,
        });
      }
    } else if (memberType === "Associate Members") {
      if (type === "All" || isClearValue === true) {
        let memberArr = this.getSearchedArray(value, [
          ...this.state.allAssociateMember,
        ]);
        this.setState({
          allAssociateMember: memberArr,
          allMemberSearchValue: value,
        });
      }
      if (type === "Selected" || isClearValue === true) {
        let memberArr = this.getSearchedArray(value, [
          ...this.state.selectedAssociateMember,
        ]);
        this.setState({
          selectedAssociateMember: memberArr,
          selectedMemberSearchValue: value,
        });
      }
    } else if (memberType === "Qualifying Members") {
      if (type === "All" || isClearValue === true) {
        let memberArr = this.getSearchedArray(value, [
          ...this.state.allQualifyingMember,
        ]);
        this.setState({
          allQualifyingMember: memberArr,
          allMemberSearchValue: value,
        });
      }
      if (type === "Selected" || isClearValue === true) {
        let memberArr = this.getSearchedArray(value, [
          ...this.state.selectedQualifyingMember,
        ]);
        this.setState({
          selectedQualifyingMember: memberArr,
          selectedMemberSearchValue: value,
        });
      }
    } else if (memberType === "Chapter Members") {
      if (type === "All" || isClearValue === true) {
        let memberArr = this.getSearchedArray(
          value,
          [...this.state.allChapter],
          true
        );
        this.setState({ allChapter: memberArr, allMemberSearchValue: value });
      }
      if (type === "Selected" || isClearValue === true) {
        let memberArr = this.getSearchedArray(
          value,
          [...this.state.selectedChapter],
          true
        );
        this.setState({
          selectedChapter: memberArr,
          selectedMemberSearchValue: value,
        });
      }
    }
  };

  getSearchedArray = (value, allMemberArr, isChapter = false) => {
    let memberArr = [...allMemberArr];

    if (isChapter === true) {
      memberArr.map((x) => {
        if (x.chapterName.toLowerCase().includes(value.toLowerCase())) {
          x.isShowData = true;
        } else {
          x.isShowData = false;
        }
        return x;
      });
    } else {
      memberArr.map((x) => {
        if (x.organisationName !== null && x.organisationName.toLowerCase().includes(value.toLowerCase())) {
          x.isShowData = true;
        } else {
          x.isShowData = false;
        }
        return x;
      });
    }
    return memberArr;
  };
  render() {
    if (this.state.redirect !== null && this.state.redirect !== "") {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <>
        <div className="main-body-section finance-body-sec">
          <div className="bg-white pb-2">
            <div className="heading-part pl-7 py-4 border-b">
              <div className="flex items-center justify-between">
                <h4 className="small-title font-bold theme-color">
                  General Details
                </h4>
              </div>
            </div>
            <div className="grid grid-cols-12 2xl:gap-6 gap-3 w-full items-center px-8 pt-10 pb-5">
              <div className="col-span-12 w-full mb-3">
                <h2 className="text-xl theme-color mb-3 font-bold">
                  Voucher Code and Discounts
                </h2>
              </div>
            </div>
            <div className="grid grid-cols-12 gap-6 w-full items-start px-8 pb-4">
              {/* Left Side Section Start*/}
              <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                <div className="grid grid-cols-12 2xl:gap-6 gap-3 2xl:pr-10">
                  <div className="col-span-12">
                    <h2 className="text-xl theme-color mb-3">
                      Voucher Code
                      <span className="text-[#c00000]">*</span>
                    </h2>
                    <input
                      className={`input-texts form-input rounded-none w-full shadow-red py-3 text-lg ${this.state.voucherCodeDetails.isDisabled
                          ? "disableVouchercode"
                          : ""
                        }`}
                      type="text"
                      name="voucherCode"
                      value={this.state.voucherCodeDetails.voucherCode}
                      onChange={(event) => this.handleChange(event)}
                      onBlur={() => this.validateField("voucherCode")}
                    ></input>
                    <ValidationText
                      error={this.state.validState.error.voucherCode}
                    />
                  </div>

                  <div className="col-span-12 mt-5">
                    <label
                      className="block text-xl mb-3 theme-color"
                      htmlFor="default"
                    >
                      {" "}
                      Voucher Type<span className="text-[#C00000]">*</span>
                    </label>
                    <div className="grid grid-cols-12 2xl:gap-6 gap-3 items-center w-full">
                      <div className="col-span-12 ">
                        <div className="mt-5 flex items-center w-full">
                          <label
                            className={`flex items-center w-[40%] ${this.state.voucherCodeDetails.isDisabled
                                ? "disableVouchercode"
                                : ""
                              }`}
                          >
                            <input
                              type="radio"
                              className="form-radio "
                              name="voucherCodeType"
                              id="voucherCodeType"
                              value={"Percentage"}
                              onChange={(event) =>
                                this.handleRadioButtons(event, "Percentage")
                              }
                              checked={
                                this.state.voucherCodeDetails
                                  .voucherCodeType === "Percentage"
                              }
                            />
                            <span className="text-xl  theme-color ml-2">
                              By Percentage
                            </span>
                          </label>
                          {this.state.voucherCodeDetails.voucherCodeType ===
                            "Percentage" ? (
                            <div className={`w-[60%] ${this.state.voucherCodeDetails.isDisabled ? 'disableVouchercode' : ''}`}>
                              <div className="prasantage-input with-border-line">
                                <input
                                  className="input-texts form-input rounded-none shadow-red py-3 pr-[70px] text-lg w-full "
                                  type="text"
                                  name="voucherCodeTypeValue"
                                  value={
                                    this.state.voucherCodeDetails
                                      .voucherCodeTypeValue
                                  }
                                  onChange={(event) => this.handleChange(event)}
                                  onBlur={() =>
                                    this.validateField("voucherCodeTypeValue")
                                  }
                                ></input>
                              </div>
                              <>
                                <ValidationText
                                  error={
                                    this.state.validState.error
                                      .voucherCodeTypeValue
                                  }
                                />
                                {this.state.voucherCodeDetails
                                  .voucherCodeType === "Percentage" ? (
                                  <ValidationText
                                    error={this.state.voucherCodeTypeValueMsg}
                                  />
                                ) : null}
                              </>
                            </div>
                          ) : (
                            <div
                              className={`prasantage-input with-border-line w-[60%] disabled-input ${this.state.voucherCodeDetails.isDisabled
                                  ? "disableVouchercode"
                                  : ""
                                }`}
                            >
                              <input
                                className="input-texts form-input rounded-none shadow-red py-3 pr-[70px] text-lg w-full "
                                type="text"
                                name="voucherCodeTypeValue"
                                value={""}
                                onChange={(event) => this.handleChange(event)}
                                // onBlur={() => props.validateField("voucherCodeTypeValue")}
                                disabled
                              ></input>
                            </div>
                          )}
                        </div>
                      </div>
                      {this.state.voucherCodeDetails.voucherType !==
                        "isForDTSME" ? (
                        <>
                          <div className={` col-span-12 w-full ${this.state.voucherCodeDetails.isDisabled ? 'disableVouchercode' : ''}`}>
                            <div className="flex items-center ">
                              <label className="flex items-center w-[40%]">
                                <input
                                  type="radio"
                                  className="form-radio"
                                  name="voucherCodeType"
                                  id="voucherCodeType"
                                  value={"Amount"}
                                  onChange={(event) =>
                                    this.handleRadioButtons(event, "Amount")
                                  }
                                  checked={
                                    this.state.voucherCodeDetails
                                      .voucherCodeType === "Amount"
                                  }
                                />
                                <span className="text-xl  theme-color ml-2">
                                  By Amount
                                </span>
                              </label>

                              {this.state.voucherCodeDetails.voucherCodeType ===
                                "Amount" ? (
                                <div
                                  className={`w-[60%] ${this.state.voucherCodeDetails.isDisabled
                                      ? "disableVouchercode"
                                      : ""
                                    }`}
                                >
                                  <div className="dollor-sign dollor-sign-custom">
                                    <input
                                      className="custom-input pl-[70px] w-full"
                                      type="text"
                                      name="voucherCodeTypeValue"
                                      value={
                                        this.state.voucherCodeDetails
                                          .voucherCodeTypeValue
                                      }
                                      onChange={(event) =>
                                        this.handleChange(event)
                                      }
                                      onBlur={() =>
                                        this.validateField(
                                          "voucherCodeTypeValue"
                                        )
                                      }
                                    ></input>
                                  </div>
                                  <>
                                    <ValidationText
                                      error={
                                        this.state.validState.error
                                          .voucherCodeTypeValue
                                      }
                                    />
                                    {this.state.voucherCodeDetails
                                      .voucherCodeType === "Amount" ? (
                                      <ValidationText
                                        error={
                                          this.state.voucherCodeTypeValueMsg
                                        }
                                      />
                                    ) : null}
                                  </>
                                </div>
                              ) : (
                                <div className="dollor-sign dollor-sign-custom w-[60%] disabled-input">
                                  <input
                                    className="custom-input pl-[70px] w-full"
                                    type="text"
                                    name="voucherCodeTypeValue"
                                    value={""}
                                    onChange={(event) =>
                                      this.handleChange(event)
                                    }
                                    // onBlur={() => props.validateField("voucherCodeTypeValue")}
                                    disabled
                                  ></input>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className={`col-span-12 w-full ${this.state.voucherCodeDetails.isDisabled ? 'disableVouchercode' : ''}`}>
                            <div className="flex items-center">
                              <label
                                className={`flex items-center w-[40%] ${this.state.voucherCodeDetails.isDisabled
                                    ? "disableVouchercode"
                                    : ""
                                  }`}
                              >
                                <input
                                  type="radio"
                                  className="form-radio"
                                  name="voucherCodeType"
                                  id="voucherCodeType"
                                  value={"Fixed Price"}
                                  onChange={(event) =>
                                    this.handleRadioButtons(
                                      event,
                                      "Fixed Price"
                                    )
                                  }
                                  checked={
                                    this.state.voucherCodeDetails
                                      .voucherCodeType === "Fixed Price"
                                  }
                                />
                                <span className="text-xl  theme-color ml-2">
                                  By Fixed Price
                                </span>
                              </label>
                              {this.state.voucherCodeDetails.voucherCodeType ===
                                "Fixed Price" ? (
                                <div
                                  className={` w-[60%] ${this.state.voucherCodeDetails.isDisabled
                                      ? "disableVouchercode"
                                      : ""
                                    }`}
                                >
                                  <div className="dollor-sign dollor-sign-custom">
                                    <input
                                      className="custom-input pl-[70px] w-full"
                                      type="text"
                                      name="voucherCodeTypeValue"
                                      value={
                                        this.state.voucherCodeDetails
                                          .voucherCodeTypeValue
                                      }
                                      onChange={(event) =>
                                        this.handleChange(event)
                                      }
                                      onBlur={() =>
                                        this.validateField(
                                          "voucherCodeTypeValue"
                                        )
                                      }
                                    ></input>
                                  </div>
                                  <>
                                    <ValidationText
                                      error={
                                        this.state.validState.error
                                          .voucherCodeTypeValue
                                      }
                                    />
                                    {this.state.voucherCodeDetails
                                      .voucherCodeType === "Fixed Price" ? (
                                      <ValidationText
                                        error={
                                          this.state.voucherCodeTypeValueMsg
                                        }
                                      />
                                    ) : null}
                                  </>
                                </div>
                              ) : (
                                <div className="dollor-sign dollor-sign-custom w-[60%] disabled-input">
                                  <input
                                    className="custom-input pl-[70px] w-full"
                                    type="text"
                                    name="voucherCodeTypeValue"
                                    value={""}
                                    onChange={(event) =>
                                      this.handleChange(event)
                                    }
                                    // onBlur={() => props.validateField("voucherCodeTypeValue")}
                                    disabled
                                  ></input>
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-span-12 mt-5 voucher-sec">
                    <div className="flex grid-cols-12 mb-8 2xl:2xl:gap-6 gap-3 gap-3">
                      <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12">
                        <label
                          className="block text-xl 2xl:mb-1 lg:[0.25rem] theme-color min-h-30 "
                          htmlFor="default"
                        >
                          Start Date<span className="text-[#C00000]">*</span>
                        </label>
                        <div
                          className={`grid grid-cols-12 2xl:gap-6 gap-3 items-center w-full ${this.state.voucherCodeDetails.isDisabled
                              ? "disableVouchercode"
                              : ""
                            }`}
                        >
                          <div className="col-span-12 w-full">
                            <input
                              id="default"
                              className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                              type="date"
                              name="voucherCodeStartDate"
                              value={moment(
                                this.state.voucherCodeDetails
                                  .voucherCodeStartDate
                              ).format("yyyy-MM-DD")}
                              onChange={(event) => this.handleChange(event)}
                              onBlur={() =>
                                this.validateField("voucherCodeStartDate")
                              }
                            />
                            <div>
                              <ValidationText
                                error={
                                  this.state.validState.error
                                    .voucherCodeStartDate
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                        <label
                          className="block text-xl 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="default"
                        >
                          End Date<span className="text-[#C00000]">*</span>
                        </label>
                        <div className="grid grid-cols-12 2xl:gap-6 gap-3 items-center w-full">
                          <div className="col-span-12 w-full">
                            <input
                              id="default"
                              className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                              type="date"
                              name="voucherCodeEndDate"
                              value={moment(
                                this.state.voucherCodeDetails.voucherCodeEndDate
                              ).format("yyyy-MM-DD")}
                              onChange={(event) => this.handleChange(event)}
                              onBlur={() =>
                                this.validateField("voucherCodeEndDate")
                              }
                            />
                            <div>
                              <ValidationText
                                error={
                                  this.state.validState.error.voucherCodeEndDate
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`col-span-12 ${this.state.voucherCodeDetails.isDisabled
                        ? "disableVouchercode"
                        : ""
                      }`}
                  >
                    <div className="flex items-center">
                      <label className="flex items-center w-[100%]">
                        <input
                          type="radio"
                          name="membershipType"
                          id="isForMembership"
                          className="form-radio"
                          value={this.state.voucherCodeDetails.isForMembership}
                          onChange={(event) =>
                            this.handleRadioButtons(event, "isForMembership")
                          }
                          checked={
                            this.state.voucherCodeDetails.voucherType ===
                            "isForMembership"
                          }
                        />
                        <span className="text-xl  theme-color ml-2">
                          Membership
                        </span>
                      </label>
                      <label className="flex items-center w-[100%]">
                        <input
                          type="radio"
                          name="individualType"
                          id="isForIndividual"
                          className="form-radio"
                          value={this.state.voucherCodeDetails.isForIndividual}
                          onChange={(event) =>
                            this.handleRadioButtons(event, "isForIndividual")
                          }
                          checked={
                            this.state.voucherCodeDetails.voucherType ===
                            "isForIndividual"
                          }
                        />
                        <span className="text-xl  theme-color ml-2">
                          Individual Members
                        </span>
                      </label>

                      {/* Voucher code for DT-SME Start*/}
                      {/* <label className="flex items-center w-[100%]">
                        <input
                          type="radio"
                          name="dtSmeType"
                          id="isForDTSME"
                          className="form-radio"
                          value={this.state.voucherCodeDetails.isForDTSME}
                          onChange={(event) =>
                            this.handleRadioButtons(event, "isForDTSME")
                          }
                          checked={this.state.voucherCodeDetails.voucherType === "isForDTSME"}

                        />
                        <span className="text-xl  theme-color ml-2">
                          DT-SME
                        </span>
                      </label> */}
                      {/* Voucher code for DT-SME End */}
                    </div>
                  </div>
                  {this.state.voucherCodeDetails.voucherType !==
                    "isForDTSME" ? (
                    this.state.voucherCodeDetails.voucherType ===
                      "isForMembership" ? (
                      <div className={`col-span-12 mt-0 `}>
                        <h2 className="text-xl theme-color mb-3">
                          Number of times used per membership
                          <span className="text-[#c00000]">*</span>
                        </h2>
                        <SelectorWithoutDefaultValue
                          options={this.state.numberOfTimePerMembershipArray}
                          // isMulti
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          // components={{
                          //   Option,
                          // }}
                          onChange={this.handleChangeInDropdown.bind(this)}
                          allowSelectAll={false}
                          value={this.state.numberOfTimePerMembershipArray.find(
                            (item) =>
                              item.label ===
                              this.state.voucherCodeDetails
                                .voucherCodeUsedPerMembership
                          )}
                          drpIdentity="VoucherCodeUsedPerMembership"
                          disabled={this.state.voucherCodeDetails.isDisabled}
                        />
                        <ValidationText
                          error={
                            this.state.validState.error
                              .voucherCodeUsedPerMembership
                          }
                        />
                      </div>
                    ) : (
                      <div
                        className={`col-span-12 mt-0 ${this.state.voucherCodeDetails.isDisabled
                            ? "disableVouchercode"
                            : ""
                          }`}
                      >
                        <h2 className="text-xl theme-color mb-3">
                          Number of times used per email address
                          <span className="text-[#c00000]">*</span>
                        </h2>
                        <SelectorWithoutDefaultValue
                          options={this.state.numberOfTimePerMembershipArray}
                          // isMulti
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          // components={{
                          //   Option,
                          // }}
                          onChange={this.handleChangeInDropdown.bind(this)}
                          allowSelectAll={false}
                          value={this.state.numberOfTimePerMembershipArray.find(
                            (item) =>
                              item.label ===
                              this.state.voucherCodeDetails
                                .voucherCodeUsedPerEmail
                          )}
                          drpIdentity="VoucherCodeUsedPerEmail"
                        />
                        <ValidationText
                          error={
                            this.state.validState.error.voucherCodeUsedPerEmail
                          }
                        />
                      </div>
                    )
                  ) : null}

                  <div className="col-span-12 mt-5">
                    <label
                      className="block text-xl mb-3 theme-color"
                      htmlFor="default"
                    >
                      Total number of times voucher can be used
                      {/* <span className="text-[#C00000]">*</span> */}
                    </label>
                    <div className="grid grid-cols-12 2xl:gap-6 gap-3 items-center w-full">
                      <div className="col-span-12 ">
                        <div
                          className={`flex items-center ${this.state.voucherCodeDetails.isDisabled
                              ? "disableVouchercode"
                              : ""
                            }`}
                        >
                          <label className="flex items-center w-[100%]">
                            <input
                              type="radio"
                              name="isUnlimited"
                              id="isUnlimited"
                              className="form-radio"
                              value={"true"}
                              onChange={(event) =>
                                this.handleRadioButtons(event)
                              }
                              checked={
                                this.state.voucherCodeDetails.isUnlimited ===
                                "true"
                              }
                            />
                            <span className="text-xl  theme-color ml-2">
                              Unlimited
                            </span>
                          </label>
                        </div>
                      </div>
                      <div
                        className={`col-span-12 ${this.state.voucherCodeDetails.isDisabled
                            ? "disableVouchercode"
                            : ""
                          }`}
                      >
                        <div className="flex items-center flex-wrap">
                          <label className="flex items-center w-full">
                            <input
                              type="radio"
                              name="isUnlimited"
                              id="isUnlimited"
                              className="form-radio"
                              value={"false"}
                              onChange={(event) =>
                                this.handleRadioButtons(event)
                              }
                              checked={
                                this.state.voucherCodeDetails.isUnlimited ===
                                "false"
                              }
                            />
                            <span className="text-xl  theme-color ml-2">
                              Set User Limit
                            </span>
                          </label>
                          {this.state.voucherCodeDetails.isUnlimited ===
                            "false" ||
                            this.state.voucherCodeDetails.isUnlimited ===
                            false ? (
                            <input
                              className="input-texts form-input rounded-none  shadow-red py-3 text-lg mt-5"
                              type="text"
                              name="voucherCodeUserLimit"
                              value={
                                this.state.voucherCodeDetails
                                  .voucherCodeUserLimit
                              }
                              onChange={(event) => this.handleChange(event)}
                            // onBlur={() =>
                            //   this.validateField("voucherCodeUserLimit")
                            // }
                            ></input>
                          ) : // <div className="disabled-input">
                            //   <input
                            //     className="input-texts form-input rounded-none w-full shadow-red py-3 text-lg mt-5 "
                            //     type="text"
                            //     name="voucherCodeUserLimit"
                            //     value={""}
                            //     onChange={(event) => this.handleChange(event)}
                            //     disabled
                            //   ></input>
                            // </div>
                            null}
                        </div>
                        <ValidationText
                          error={
                            this.state.voucherCodeUserLimitMsg
                              ? this.state.voucherCodeUserLimitMsg
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Left Side Section End*/}
              {/* Right Side Section  Start*/}
              {this.state.voucherCodeDetails.voucherType !== "isForDTSME" ? (
                <div className="2xl:col-span-6 lg:col-span-6 col-span-12 w-full input-sec-texts">
                  <div className="grid grid-cols-12 2xl:gap-6 gap-3">
                    <div className="col-span-12">
                      <h2 className="text-xl theme-color font-bold mb-3">
                        Voucher valid for
                        {/* <span className="text-[#c00000]">*</span> */}
                      </h2>
                    </div>
                    <div
                      className={`col-span-12 my-3 ${this.state.voucherCodeDetails.isDisabled
                          ? "disableVouchercode"
                          : ""
                        }`}
                    >
                      <div className="grid grid-cols-12 2xl:gap-6 gap-3 mb-5">
                        <div className="col-span-12">
                          <div className="flex items-center justify-between">
                            <div className="check-box-sec flex items-center">
                              <input
                                id="isForOrdinaryMember"
                                name="isForOrdinaryMember"
                                type="checkbox"
                                className="form-checkbox"
                                checked={
                                  this.state.voucherCodeDetails
                                    .isForOrdinaryMember
                                }
                                value={
                                  this.state.voucherCodeDetails
                                    .isForOrdinaryMember
                                }
                                onChange={(event) =>
                                  this.handleChangeCheckBox(event)
                                }
                              //onChange={(e) => props.handleChange(e)}
                              />
                              <span className="block text-xl theme-color pl-4">
                                Ordinary Member
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.voucherCodeDetails.isForOrdinaryMember ? (
                        <div className="grid grid-cols-12 2xl:gap-3 gap-3">
                          <div className="col-span-12 mt-2">
                            <div className="flex items-center">
                              <label className="flex items-center w-[100%]">
                                <input
                                  type="radio"
                                  name="isAllOrdinaryMember"
                                  id="isAllOrdinaryMember"
                                  className="form-radio"
                                  value={"true"}
                                  onChange={(event) =>
                                    this.handleRadioButtons(event)
                                  }
                                  checked={
                                    this.state.voucherCodeDetails
                                      .isAllOrdinaryMember === "true"
                                  }
                                />
                                <span className="block text-xl theme-color pl-4">
                                  All Ordinary Members
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="col-span-12 mt-2">
                            <div className="flex items-center">
                              <label className="flex items-center w-[100%]">
                                <input
                                  type="radio"
                                  name="isAllOrdinaryMember"
                                  id="isAllOrdinaryMember"
                                  className="form-radio"
                                  value={"false"}
                                  onChange={(event) =>
                                    this.handleRadioButtons(event)
                                  }
                                  checked={
                                    this.state.voucherCodeDetails
                                      .isAllOrdinaryMember === "false"
                                  }
                                />
                                <span className="block text-xl theme-color pl-4">
                                  Selected Ordinary Members{" "}
                                  <span
                                    className="text-[#c00000]"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.setState({
                                        selectAllMembersPopup: true,
                                        memberId: 1,
                                      });
                                      // this.setState({ setAddEditModel: true });
                                    }}
                                  >
                                    (<span className="underline">View</span>)
                                  </span>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>


                    <div
                      className={`col-span-12 my-3 ${this.state.voucherCodeDetails.isDisabled
                          ? "disableVouchercode"
                          : ""
                        }`}
                    >
                      <div className="grid grid-cols-12 2xl:gap-6 gap-3 mb-5">
                        <div className="col-span-12">
                          <div className="flex items-center justify-between">
                            <div className="check-box-sec flex items-center">
                              <input
                                id="isForQualifyingMember"
                                name="isForQualifyingMember"
                                type="checkbox"
                                className="form-checkbox"
                                checked={
                                  this.state.voucherCodeDetails
                                    .isForQualifyingMember
                                }
                                value={
                                  this.state.voucherCodeDetails
                                    .isForQualifyingMember
                                }
                                onChange={(event) =>
                                  this.handleChangeCheckBox(event)
                                }
                              //onChange={(e) => props.handleChange(e)}
                              />
                              <span className="block text-xl theme-color pl-4">
                                Qualifying Member
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {this.state.voucherCodeDetails.isForQualifyingMember ? (
                        <div className="grid grid-cols-12 2xl:gap-3 gap-3">
                          <div className="col-span-12 mt-2">
                            <div className="flex items-center">
                              <label className="flex items-center w-[100%]">
                                <input
                                  type="radio"
                                  name="isAllQualifyingMember"
                                  id="isAllQualifyingMember"
                                  className="form-radio"
                                  value={"true"}
                                  onChange={(event) =>
                                    this.handleRadioButtons(event)
                                  }
                                  checked={
                                    this.state.voucherCodeDetails
                                      .isAllQualifyingMember === "true"
                                  }
                                />
                                <span className="block text-xl theme-color pl-4">
                                  All Qualifying Members
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="col-span-12 mt-2">
                            <div className="flex items-center">
                              <label className="flex items-center w-[100%]">
                                <input
                                  type="radio"
                                  name="isAllQualifyingMember"
                                  id="isAllQualifyingMember"
                                  className="form-radio"
                                  value={"false"}
                                  onChange={(event) =>
                                    this.handleRadioButtons(event)
                                  }
                                  checked={
                                    this.state.voucherCodeDetails
                                      .isAllQualifyingMember === "false"
                                  }
                                />
                                <span className="block text-xl theme-color pl-4">
                                  Selected Qualifying Members{" "}
                                  <span
                                    className="text-[#c00000]"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.setState({
                                        selectAllMembersPopup: true,
                                        memberId: 3,
                                      });
                                      // this.setState({ setAddEditModel: true });
                                    }}
                                  >
                                    (<span className="underline">View</span>)
                                  </span>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div
                      className={`col-span-12 my-3 ${this.state.voucherCodeDetails.isDisabled
                          ? "disableVouchercode"
                          : ""
                        }`}
                    >
                      <div className="grid grid-cols-12 2xl:gap-6 gap-3 mb-5">
                        <div className="col-span-12">
                          <div className="flex items-center justify-between">
                            <div className="check-box-sec flex items-center">
                              <input
                                id="isForAssociateMember"
                                name="isForAssociateMember"
                                type="checkbox"
                                className="form-checkbox"
                                checked={
                                  this.state.voucherCodeDetails
                                    .isForAssociateMember
                                }
                                value={
                                  this.state.voucherCodeDetails
                                    .isForAssociateMember
                                }
                                onChange={(event) =>
                                  this.handleChangeCheckBox(event)
                                }
                              //onChange={(e) => props.handleChange(e)}
                              />
                              <span className="block text-xl theme-color pl-4">
                                Associate Member
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.voucherCodeDetails.isForAssociateMember ? (
                        <div className="grid grid-cols-12 2xl:gap-3 gap-3">
                          <div className="col-span-12 mt-2">
                            <div className="flex items-center">
                              <label className="flex items-center w-[100%]">
                                <input
                                  type="radio"
                                  name="isAllAssociateMember"
                                  id="isAllAssociateMember"
                                  className="form-radio"
                                  value={"true"}
                                  onChange={(event) =>
                                    this.handleRadioButtons(event)
                                  }
                                  checked={
                                    this.state.voucherCodeDetails
                                      .isAllAssociateMember === "true"
                                  }
                                />
                                <span className="block text-xl theme-color pl-4">
                                  All Associate Members
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="col-span-12 mt-2">
                            <div className="flex items-center">
                              <label className="flex items-center w-[100%]">
                                <input
                                  type="radio"
                                  name="isAllAssociateMember"
                                  id="isAllAssociateMember"
                                  className="form-radio"
                                  value={"false"}
                                  onChange={(event) =>
                                    this.handleRadioButtons(event)
                                  }
                                  checked={
                                    this.state.voucherCodeDetails
                                      .isAllAssociateMember === "false"
                                  }
                                />
                                <span className="block text-xl theme-color pl-4">
                                  Selected Associate Members{" "}
                                  <span
                                    className="text-[#c00000]"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.setState({
                                        selectAllMembersPopup: true,
                                        memberId: 2,
                                      });
                                      // this.setState({ setAddEditModel: true });
                                    }}
                                  >
                                    (<span className="underline">View</span>)
                                  </span>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>

                    {/* <div className="col-span-12 my-3 hidden">
                      <div className="grid grid-cols-12 2xl:gap-6 gap-3">
                        <div className="col-span-12">
                          <div className="flex items-center justify-between">
                            <div className="check-box-sec flex items-center">
                              <input
                                id="alumniMember"
                                name="alumniMember"
                                type="checkbox"
                                className="form-checkbox"
                                // checked={props.siisAccountInformation.isFeatured}
                                // value={props.siisAccountInformation.isFeatured}
                                // onChange={(event) => props.handleChangeCheckBoxSIIS(event)}
                                //onChange={(e) => props.handleChange(e)}
                              />
                              <span className="block text-xl theme-color pl-4">
                                Alumni Member
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12 mt-2">
                          <div className="flex items-center">
                            <label className="flex items-center w-[100%]">
                              <input
                                type="radio"
                                name="radio-alumniMember"
                                id="isPage"
                                className="form-radio"
                                // value={props.resource.isPage}
                                // onChange={(event) => props.handleRadioButtons(event)}
                                // checked={props.resource.isPage === true}
                              />
                              <span className="block text-xl theme-color pl-4">
                                All Alumni Members
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="col-span-12 mt-2">
                          <div className="flex items-center">
                            <label className="flex items-center w-[100%]">
                              <input
                                type="radio"
                                name="radio-alumniMember"
                                id="isPage"
                                className="form-radio"
                                // value={props.resource.isPage}
                                // onChange={(event) => props.handleRadioButtons(event)}
                                // checked={props.resource.isPage === true}
                              />
                              <span className="block text-xl theme-color pl-4">
                                Selected Alumni Members{" "}
                                <span
                                  className="text-[#c00000]"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.setState({
                                      selectAllMembersPopup: true,
                                    });
                                    // this.setState({ setAddEditModel: true });
                                  }}
                                >
                                  (<span className="underline">View</span>)
                                </span>
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* <div
                      className={`col-span-12 my-3 ${
                        this.state.voucherCodeDetails.isDisabled
                          ? "disableVouchercode"
                          : ""
                      }`}
                    > */}
                    {/* <div className="grid grid-cols-12 2xl:gap-6 gap-3 mb-5">
                        <div className="col-span-12">
                          <div className="flex items-center justify-between">
                            <div className="check-box-sec flex items-center">
                              <input
                                id="isForChapterMember"
                                name="isForChapterMember"
                                type="checkbox"
                                className={`form-checkbox  ${
                                  this.state.voucherCodeDetails.isDisabled
                                    ? "disableVouchercode"
                                    : ""
                                }`}
                                checked={
                                  this.state.voucherCodeDetails
                                    .isForChapterMember
                                }
                                value={
                                  this.state.voucherCodeDetails
                                    .isForChapterMember
                                }
                                onChange={(event) =>
                                  this.handleChangeCheckBox(event)
                                }
                                //onChange={(e) => props.handleChange(e)}
                              />
                              <span className="block text-xl theme-color pl-4">
                                Chapter Member
                              </span>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    {/* {this.state.voucherCodeDetails.isForChapterMember ? (
                        <div className="grid grid-cols-12 2xl:gap-3 gap-3">
                          <div className="col-span-12 mt-2">
                            <div className="flex items-center">
                              <label className="flex items-center w-[100%]">
                                <input
                                  type="radio"
                                  name="isAllChapterMember"
                                  id="isAllChapterMember"
                                  className={`form-radio  ${
                                    this.state.voucherCodeDetails.isDisabled
                                      ? "disableVouchercode"
                                      : ""
                                  }`}
                                  value={"true"}
                                  onChange={(event) =>
                                    this.handleRadioButtons(event)
                                  }
                                  checked={
                                    this.state.voucherCodeDetails
                                      .isAllChapterMember === "true"
                                  }
                                />
                                <span className="block text-xl theme-color pl-4">
                                  All Chapter Members
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="col-span-12 mt-2">
                            <div className="flex items-center">
                              <label className="flex items-center w-[100%]">
                                <input
                                  type="radio"
                                  name="isAllChapterMember"
                                  id="isAllChapterMember"
                                  className={`form-radio  ${
                                    this.state.voucherCodeDetails.isDisabled
                                      ? "disableVouchercode"
                                      : ""
                                  }`}
                                  value={"false"}
                                  onChange={(event) =>
                                    this.handleRadioButtons(event)
                                  }
                                  checked={
                                    this.state.voucherCodeDetails
                                      .isAllChapterMember === "false"
                                  }
                                />
                                <span className="block text-xl theme-color pl-4">
                                  Selected Chapter Members{" "}
                                  <span
                                    className="text-[#c00000] "
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.setState({
                                        selectAllMembersPopup: true,
                                        memberId: 4,
                                      });
                                    }}
                                  >
                                    (<span className="underline">View</span>)
                                  </span>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      ) : null} */}
                    {/* </div> */}
                    {this.state.voucherCodeDetails.voucherType ===
                      "isForIndividual" ? (
                      <div
                        className={`col-span-12 my-3 ${this.state.voucherCodeDetails.isDisabled
                            ? "disableVouchercode"
                            : ""
                          }`}
                      >
                        <div className="grid grid-cols-12 2xl:gap-6 gap-3 mb-5">
                          <div className="col-span-12">
                            <div className="flex items-center justify-between">
                              <div className="check-box-sec flex items-center">
                                <input
                                  id="isForNonMember"
                                  name="isForNonMember"
                                  type="checkbox"
                                  className="form-checkbox"
                                  checked={
                                    this.state.voucherCodeDetails.isForNonMember
                                  }
                                  value={
                                    this.state.voucherCodeDetails.isForNonMember
                                  }
                                  onChange={(event) =>
                                    this.handleChangeCheckBox(event)
                                  }
                                //onChange={(e) => props.handleChange(e)}
                                />
                                <span className="block text-xl theme-color pl-4">
                                  Non Member
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
              {/* Right Side Section End*/}
            </div>
            {this.state.voucherCodeDetails.voucherType === "isForIndividual" ? (
              <div className="grid grid-cols-12 gap-6 w-full items-start px-8 pb-14">
                <div className="2xl:col-span-6 lg:col-span-6 col-span-12 w-full input-sec-texts">
                  <div className="col-span-12 mt-5">
                    <label
                      className="block text-xl mb-3 theme-color"
                      htmlFor="default"
                    >
                      Individual
                      {/* <span className="text-[#C00000]">*</span> */}
                    </label>
                    <div className="grid grid-cols-12 2xl:gap-6 gap-3 items-center w-full">
                      <div className="col-span-12">
                        <div className="flex items-center">
                          <label className="flex items-center w-[100%]">
                            <input
                              type="radio"
                              name="isDiscountToEntireOrder"
                              id="isDiscountToEntireOrder"
                              className={`form-radio ${this.state.isValidForAllMembers === false || this.state.voucherCodeDetails.isDisabled === true
                                  ? "disabled-radio"
                                  : false
                                }`}
                              value={"true"}
                              onChange={(event) =>
                                this.handleRadioButtons(event)
                              }
                              checked={
                                this.state.voucherCodeDetails
                                  .isDiscountToEntireOrder === "true"
                              }
                              disabled={
                                this.state.isValidForAllMembers === false || this.state.voucherCodeDetails.isDisabled === true
                                  ? true
                                  : false
                              }
                            />
                            <span className="text-xl  theme-color ml-2">
                              Apply Discount to Entire Order
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="col-span-12 ">
                        <div className="flex items-center">
                          <label className="flex items-center w-full">
                            <input
                              type="radio"
                              name="isDiscountToMainRegistrant"
                              id="isDiscountToMainRegistrant"
                              className={`form-radio ${this.state.voucherCodeDetails.isDisabled
                                ? "disabled-radio"
                                : ""}`}
                              value={"true"}
                              onChange={(event) =>
                                this.handleRadioButtons(event)
                              }
                              checked={
                                this.state.voucherCodeDetails
                                  .isDiscountToMainRegistrant === "true"
                              }
                              disabled={this.state.voucherCodeDetails.isDisabled}
                            />
                            <span className="text-xl  theme-color ml-2">
                              Apply Discount to Main Registrant only
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="col-span-12 ">
                        <div className="flex items-center">
                          <label className="flex items-center w-full">
                            <input
                              type="radio"
                              name="isDiscountToEachRegistrantInEntireOrder"
                              id="isDiscountToEachRegistrantInEntireOrder"
                              className={`form-radio ${this.state.voucherCodeDetails.isDisabled
                                ? "disabled-radio"
                                : ""}`}
                              value={"true"}
                              onChange={(event) =>
                                this.handleRadioButtons(event)
                              }
                              checked={
                                this.state.voucherCodeDetails
                                  .isDiscountToEachRegistrantInEntireOrder ===
                                "true"
                              }
                              disabled={this.state.voucherCodeDetails.isDisabled}
                            />
                            <span className="text-xl  theme-color ml-2">
                              Apply Discount to Each Registrant in Entire Order
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-span-12 mt-5">
        <label
          className="block text-xl mb-3 theme-color"
          htmlFor="default"
        >
          Corporate
          <span className="text-[#C00000]">*</span>
        </label>
        <div className="grid grid-cols-12 2xl:gap-6 gap-3 items-center w-full">
          <div className="col-span-12 ">
            <div className="flex items-center">
              <label className="flex items-center w-[100%]">
                <input
                  type="radio"
                  name="radio-corpotrate"
                  id="isPage"
                  className="form-radio"
                  // value={props.resource.isPage}
                  // onChange={(event) => props.handleRadioButtons(event)}
                  // checked={props.resource.isPage === true}
                />
                <span className="text-xl  theme-color ml-2">
                  Apply Discount to Entire Order
                </span>
              </label>
            </div>
          </div>
          <div className="col-span-12 ">
            <div className="flex items-center">
              <label className="flex items-center w-full">
                <input
                  type="radio"
                  name="radio-corpotrate"
                  id="isPage"
                  className="form-radio"
                  // value={props.resource.isPage}
                  // onChange={(event) => props.handleRadioButtons(event)}
                  // checked={props.resource.isPage === true}
                />
                <span className="text-xl  theme-color ml-2">
                  Apply Discount to Each Registrant in Entire Order
                </span>
              </label>
            </div>
          </div>
        </div>
      </div> */}
                  {/* <div className="col-span-12 mt-10">
    <h2 className="text-xl theme-color mb-3">
      Number of usage per account
      <span className="text-[#c00000]">*</span>
    </h2>
    <input
      className="input-texts form-input rounded-none shadow-red py-3 text-lg"
      type="text"
      name="voucherCodeUsedPerAccount"
      value={this.state.voucherCodeDetails.voucherCodeUsedPerAccount}
      onChange={(event) => this.handleChange(event)}
      onBlur={() => this.validateField("voucherCodeUsedPerAccount")}
    ></input>
    <ValidationText error={this.state.validState.error.voucherCodeUsedPerAccount} />
  </div> */}
                </div>
                <div className="2xl:col-span-6 lg:col-span-6 col-span-12 w-full input-sec-texts">
                  <div className="col-span-12 mt-5">
                    <h2 className="text-xl theme-color mb-3">
                      Minimum number to apply
                      {/* <span className="text-[#c00000]">*</span> */}
                    </h2>
                    <input
                      className={`input-texts form-input rounded-none shadow-red py-3 text-lg 
                      ${this.state.voucherCodeDetails.isDisabled
                          ? "disableVouchercode"
                          : ""}`}
                      type="number"
                      name="minimumNumberToApply"
                      value={this.state.voucherCodeDetails.minimumNumberToApply}
                      onChange={(event) => this.handleChange(event)}
                    // onBlur={() => this.validateField("minimumNumberToApply")}
                    ></input>
                    <ValidationText
                      error={
                        this.state.minimumNumberToApplyMsg
                          ? this.state.minimumNumberToApplyMsg
                          : ""
                      }
                    />
                  </div>

                  <div className="col-span-12 mt-5">
                    <h2 className="text-xl theme-color mb-3">
                      Minimum amount to spend
                      {/* <span className="text-[#c00000]">*</span> */}
                    </h2>
                    <input
                      className={`input-texts form-input rounded-none shadow-red py-3 text-lg
                      ${this.state.voucherCodeDetails.isDisabled
                          ? "disableVouchercode"
                          : ""}
                      `}
                      type="number"
                      name="minimumAmountToSpend"
                      value={this.state.voucherCodeDetails.minimumAmountToSpend}
                      onChange={(event) => this.handleChange(event)}
                    // onBlur={() => this.validateField("minimumAmountToSpend")}
                    ></input>
                    <ValidationText
                      error={
                        this.state.minimumAmountToSpendMsg
                          ? this.state.minimumAmountToSpendMsg
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="footer-btn mt-12">
            <div className="flex items-center justify-between">
              <button
                className="btn btn-gray text-xl text-white font-semibold"
                onClick={() =>
                  this.setState({
                    redirect: "/financial/VoucherCodeManagement",
                  })
                }
              >
                Back
              </button>
              {this.state.voucherCodeId > 0 ? (
                <button
                  className="btn btn-red text-xl text-white font-semibold"
                  onClick={() => this.addEditVoucherCodeDetails()}
                >
                  Update
                </button>
              ) : (
                <button
                  className="btn btn-red text-xl text-white font-semibold"
                  onClick={() => this.addEditVoucherCodeDetails()}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>

        <SelectMemberModel
          selectAllMembersPopup={this.state.selectAllMembersPopup}
          setOpenModal={(membertype) => {
            this.setState({ selectAllMembersPopup: false });
            this.searchByName("", membertype, "", true);
          }}
          memberId={this.state.memberId}
          allOrdinaryMember={this.state.allOrdinaryMember}
          allAssociateMember={this.state.allAssociateMember}
          allQualifyingMember={this.state.allQualifyingMember}
          allChapter={this.state.allChapter}
          selectedOrdinaryMember={this.state.selectedOrdinaryMember}
          selectedAssociateMember={this.state.selectedAssociateMember}
          selectedQualifyingMember={this.state.selectedQualifyingMember}
          selectedChapter={this.state.selectedChapter}
          handleChangeCheckBoxForMembers={this.handleChangeCheckBoxForMembers.bind(
            this
          )}
          assignMembers={this.assignMembers.bind(this)}
          UnassignMembers={this.UnassignMembers.bind(this)}
          searchByName={this.searchByName.bind(this)}
          allMemberSearchValue={this.state.allMemberSearchValue}
          selectedMemberSearchValue={this.state.selectedMemberSearchValue}
        />
      </>
    );
  }
}
