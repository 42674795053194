import React from "react";
import ModalBasic from "../../pages/component/ModalBasic";
import TextInput from "../UIComponent/TextInput";

const GoGlobalContactRequestDetailsModal = (props) => {
    return (
        <ModalBasic
            id="go-global-contact-request-modal"
            modalOpen={props.goGlobalContactRequestModal}
            setModalOpen={props.setOpenModal}
            title="Details"
        >
            <div className="pb-2 border-t border-[#757575] ">
                <div className="filter-info grid grid-cols-12 gap-6 p-8">
                    <div className="filter-types xl:col-span-6 lg:col-span-6 col-span-12 md:pr-12">
                        <label
                            className="filter-lable block text-xl font-bold  mb-1 theme-color min-h-30"
                            htmlFor="default"
                        >
                            Request Company Contact Name
                        </label>
                        <TextInput
                            placeholder=""
                            type="text"
                            name="cancellationReason"
                            value={props.goGlobalContactRequestList[props.contactRequestIndex]?.requestCompanyContactName ? props.goGlobalContactRequestList[props.contactRequestIndex]?.requestCompanyContactName : ""}
                            identity="CreditNote"
                            disabled={true}
                        // handleChange={props.handleChangeForCreditNote}
                        //onBlur={(e) => props.validate2("cancellationReason", props.addEditCreditNote)}
                        />
                    </div>
                    <div className="filter-types xl:col-span-6 lg:col-span-6 col-span-12 md:pl-12">
                        <label
                            className="filter-lable block text-xl font-bold mb-1 theme-color min-h-30"
                            htmlFor="default"
                        >
                            Request Company Contact Email
                        </label>
                        <TextInput
                            placeholder=""
                            type="text"
                            name="cancellationReason"
                            value={props.goGlobalContactRequestList[props.contactRequestIndex]?.requestCompanyContactEmail ? props.goGlobalContactRequestList[props.contactRequestIndex]?.requestCompanyContactEmail : ""}
                            identity="CreditNote"
                            disabled={true}
                        // handleChange={props.handleChangeForCreditNote}
                        //onBlur={(e) => props.validate2("cancellationReason", props.addEditCreditNote)}
                        />
                    </div>
                    <div className="filter-types xl:col-span-6 lg:col-span-6 col-span-12 md:pr-12">
                        <label
                            className="filter-lable block text-xl font-bold mb-1 theme-color min-h-30"
                            htmlFor="default"
                        >
                            Target Company Contact Name
                        </label>
                        <TextInput
                            placeholder=""
                            type="text"
                            name="cancellationReason"
                            value={props.goGlobalContactRequestList[props.contactRequestIndex]?.targetCompanyContactName ? props.goGlobalContactRequestList[props.contactRequestIndex]?.targetCompanyContactName : ""}
                            identity="CreditNote"
                            disabled={true}
                        // handleChange={props.handleChangeForCreditNote}
                        //onBlur={(e) => props.validate2("cancellationReason", props.addEditCreditNote)}
                        />
                    </div>
                    <div className="filter-types xl:col-span-6 lg:col-span-6 col-span-12 md:pl-12">
                        <label
                            className="filter-lable block text-xl font-bold mb-1 theme-color min-h-30"
                            htmlFor="default"
                        >
                            Target Company Contact Email
                        </label>
                        <TextInput
                            placeholder=""
                            type="text"
                            name="cancellationReason"
                            value={props.goGlobalContactRequestList[props.contactRequestIndex]?.targetCompanyContactEmail ? props.goGlobalContactRequestList[props.contactRequestIndex]?.targetCompanyContactEmail : ""}
                            identity="CreditNote"
                            disabled={true}
                        // handleChange={props.handleChangeForCreditNote}
                        //onBlur={(e) => props.validate2("cancellationReason", props.addEditCreditNote)}
                        />
                    </div>
                    <div className="filter-types xl:col-span-12 lg:col-span-12 col-span-12">
                        <label
                            className="filter-lable block text-xl font-bold mb-1 theme-color min-h-30"
                            htmlFor="default"
                        >
                            Message Details
                        </label>
                        <textarea
                            className="w-full h-[120px]"
                            placeholder=""
                            id="meta_description"
                            name="message"
                            rows="4"
                            cols="4"
                            value={props.goGlobalContactRequestList[props.contactRequestIndex]?.message ? props.goGlobalContactRequestList[props.contactRequestIndex]?.message : ""}
                            disabled={true}
                        // onChange={(event) => this.handleChange(event)}
                        // onBlur={() => this.validateField("message")}
                        />
                    </div>
                </div>
            </div >
        </ModalBasic>
    );
}
export default GoGlobalContactRequestDetailsModal;