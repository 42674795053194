import React, { Component } from "react";
import CommunitySpotlightServices from "../../../services/axiosServices/apiServices/CommunitySpotlightServices";
import SwalServices from "../../../services/swalServices/SwalServices";
import moment from "moment";

export default class CommunitySpotlight extends Component {
  constructor(props) {
    super(props);
    this.CommunitySpotlightServices = new CommunitySpotlightServices();
    this.swalServices = new SwalServices();
    this.state = {
      communitySpotlightDetails: {
        title: "",
        description: "",
        startDate: "",
        endDate: "",
      },

      isLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  addEditCommunitySpotlight = () => {
    let request = {
      communitySpotlightId:
        this.state.communitySpotlightDetails.communitySpotlightId === undefined
          ? 0
          : this.state.communitySpotlightDetails.communitySpotlightId,
      title: this.state.communitySpotlightDetails.title,
      description: this.state.communitySpotlightDetails.description,
      startDate: this.state.communitySpotlightDetails.startDate,
      endDate: this.state.communitySpotlightDetails.endDate,
      CreatedBy: 0,
      CreatedAppId: 0,
      UpdatedBy: 8,
      UpdatedAppId: 88,
    };

    this.CommunitySpotlightServices.addEditCommunitySpotlight(request).then(
      (response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.setState({
            addEditCommunitySpotlight: response.AddEditCommunitySpotlight,
          });
          this.swalServices.Success(response.message);
        } else {
          this.swalServices.Error(response.message);
        }
      }
    );
  };

  getCommunitySpotlight = () => {
    this.CommunitySpotlightServices.getCommunitySpotlight().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent != null) {
          this.setState({
            communitySpotlightDetails: response.responseItem.responseContent,
          });
        }
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.communitySpotlightDetails;
    detail[name] = value;
    this.setState({ communitySpotlightDetails: { ...detail } });
  }
  componentDidMount() {
    this.getCommunitySpotlight();
  }

  render() {
    return (
      <div className="main-body-section">
        <div className="bg-white">
          <div className="heading-part px-7 py-4 border-b flex items-center justify-between">
            <h4 className="small-title font-bold theme-color">
              Community Spotlight Information
            </h4>
            <button className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white">
              Save
            </button>
          </div>
          <div className="px-7 py-4 pt-10">
            <div className="mb-16">
              <div className="grid grid-cols-12 gap-6">
                <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 mb-2 input-sec-texts">
                      <div className="filter-types xl:col-span-7">
                        <label
                          className="filter-lable block font-medium mb-3 theme-color min-h-30"
                          htmlFor="title"
                        >
                          Title <span className="text-[#C00000]">*</span>
                        </label>
                        <input
                          id="title"
                          className="form-input rounded-none w-full shadow-red py-3 text-lg"
                          name="title"
                          type="text"
                          onChange={(e) => this.handleChange(e)}
                          value={this.state.communitySpotlightDetails.title}
                        />
                      </div>
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 mb-2 input-sec-texts">
                      <div className="filter-types xl:col-span-12">
                        <label
                          className="filter-lable block font-medium mb-3 theme-color min-h-30"
                          htmlFor="description"
                        >
                          Description <span className="text-[#C00000]">*</span>
                        </label>
                        <input
                          id="default"
                          className="form-input rounded-none w-full shadow-red py-3 text-lg"
                          placeholder="Add summer Note hear"
                          name="description"
                          type="text"
                          onChange={(e) => this.handleChange(e)}
                          value={
                            this.state.communitySpotlightDetails.description
                          }
                        />
                      </div>
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                            <label
                              className="block text-xl font-medium mb-6 theme-color min-h-30"
                              htmlFor="startDate"
                            >
                              Start Date
                              <span className="text-[#C00000]">*</span>
                            </label>
                            <div className="grid grid-cols-12 gap-6 items-center w-full">
                              <div className=" xl:col-span-10 w-full">
                                <input
                                  id="default"
                                  className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                  type="date"
                                  name="startDate"
                                  onChange={(e) => this.handleChange(e)}
                                  value={moment(
                                    this.state.communitySpotlightDetails
                                      .startDate
                                  ).format("yyyy-MM-DD")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                            <label
                              className="block text-xl font-medium mb-6 theme-color min-h-30"
                              htmlFor="endDate"
                            >
                              End Date <span className="text-[#C00000]">*</span>
                            </label>
                            <div className="grid grid-cols-12 gap-6 items-center w-full">
                              <div className=" xl:col-span-10 w-full">
                                <input
                                  id="default"
                                  className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                  type="date"
                                  name="endDate"
                                  onChange={(e) => this.handleChange(e)}
                                  value={moment(
                                    this.state.communitySpotlightDetails.endDate
                                  ).format("yyyy-MM-DD")}
                                />
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal footer Button*/}
        <div className="flex flex-wrap justify-between space-x-10 mt-12">
          <div>
            <button className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white">
              Back
            </button>
          </div>
          <div>
            <button
              className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white"
              onClick={() => this.addEditCommunitySpotlight()}
            >
              Publish
            </button>
          </div>
        </div>
      </div>
    );
  }
}
