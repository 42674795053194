import { AddEditChapterDetails, AddEditExcoMember, DeleteCommitteeWorkgroupMapping, DeleteEXCOMembers, GetAllDropdownForChapterXCommittee, GetAllExcoPosition, GetAllOrganisationName, GetCommitteeWorkGroupList, GetExcoMemberById, GetExcoMemberList, SearchExcoMemberByEmail } from "../ApiEndPoints";
import { axiosGetAuthorize, axiosPostAuthorize } from "../AxiosRequests";

export default class ChapterXCommitteeWorkgroupServices {
    async addEditExcoMember(request) {
        return axiosPostAuthorize(AddEditExcoMember, request);
    }
    async getExcoPosition() {
        return axiosGetAuthorize(GetAllExcoPosition);
    }
    async getChapterXCommitteeList(request) {
        return axiosPostAuthorize(GetCommitteeWorkGroupList, request);
    }
    async getOrganisationList() {
        return axiosGetAuthorize(GetAllOrganisationName);
    }
    async getExcoMemberList(request) {
        return axiosPostAuthorize(GetExcoMemberList, request);
    }
    async getExcoMemberById(request) {
        return axiosGetAuthorize(GetExcoMemberById, request);
    }
    async getAllDropdownForChapterXCommittee() {
        return axiosGetAuthorize(GetAllDropdownForChapterXCommittee)
    }
    async addEditWorkgroupMapping(request) {
        return axiosPostAuthorize(AddEditChapterDetails, request);
    }
    async deleteCommitteeWorkgroupMapping(request) {
        return axiosPostAuthorize(DeleteCommitteeWorkgroupMapping, request);
    }
    async searchExcoMemberByEmail(request) {
        return axiosPostAuthorize(SearchExcoMemberByEmail, request);
    }
    async deleteEXCOMembers(request) {
        return axiosPostAuthorize(DeleteEXCOMembers, request);
    }


}