import { axiosGetAuthorize, axiosPostAuthorize } from '../AxiosRequests';
import {
    GetALLMembershipEnquiryList,
    GetMembershipEnquiryById,
} from '../ApiEndPoints';

export default class WebMembershipCorporateServices {

    async getALLMembershipEnquiryList(request) {
        return axiosPostAuthorize(GetALLMembershipEnquiryList, request);
    }

    async getMembershipEnquiryById(request) {
        return axiosGetAuthorize(GetMembershipEnquiryById, request);
    }

}