import React, { Component } from "react";
import CreateAccountCodeTable from "../../components/financial/CreateAccountCodeTable";
import CreateClassCodeTable from "../../components/financial/CreateClassCodeTable";
import GstTable from "../../components/financial/GstTable";
import ApprovalProcessTable from "../../components/financial/ApprovalProcessTable";
import { CommonValidationMessages, CommonSuccessMessages } from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import ValidationText from "../../utils/validation/ValidationText";
import moment from "moment/moment";

// import API services
import FinanceServices from "../../services/axiosServices/apiServices/FinanceServices";
import SwalServices from "../../services/swalServices/SwalServices";
import PageLoader from "../../components/common/pageLoader/PageLoader";

export default class GlobalSettingFinance extends Component {
  constructor(props) {
    super(props);
    this.financeServices = new FinanceServices();
    this.swalServices = new SwalServices();
    this.state = {
      gstSettingList: [],
      classCodeList: [],
      accountCodeList: [],
      creditNoteApprovalList: [],
      addEditGST: {
        financeGSTId: 0,
        gstStartDate: '',
        gstEndDate: '',
        gstPercentage: 0.0,
      },
      addEditClassCode: {
        financeClassCodeId: 0,
        financeClassCode: "",
        financeClassCodeDescription: "",
      },
      addEditAccountCode: {
        financeAccountCodeId: 0,
        financeAccountCode: "",
        financeAccountCodeDescription: "",
      },
      setUpApprovalProcess: {
        financeCreditNoteApprovalId: 0,
        financeStartingAmount: 0,
        financeEndingAmount: 0,
        financeApprovalEmail: "",
      },
      isLoading: false,
      validState: {
        isValid: true,
        error: {},
      },
      gSTValidationRule: {
        gstStartDate: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "start date"
            ),
          },
        ],
        gstEndDate: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "end date"
            ),
          },
        ],
        gstPercentage: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "percentage"
            ),
          },
        ],
      },
      classCodeValidationRule: {
        financeClassCode: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "class code"
            ),
          },
        ],
        financeClassCodeDescription: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "class code description"
            ),
          },
        ],
      },
      accountCodeValidationRule: {
        financeAccountCode: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "account code"
            ),
          },
        ],
        financeAccountCodeDescription: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "account code description"
            ),
          },
        ],
      },
      approvalProcessValidationRule: {
        financeStartingAmount: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "approve process starting amount"
            ),
          },
        ],
        financeEndingAmount: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "approve process ending amount"
            ),
          },
        ],
        financeApprovalEmail:
          [
            {
              type: 'email',
              message: CommonValidationMessages.EmailAddressNotValid,
            },
            {
              type: 'require',
              message: CommonValidationMessages.FieldRequired.replace('{0}', 'approve process email address'),
            },
          ],
      },
    };
  };

  // API method declaration start
  componentDidMount() {
    this.getFinanceGSTSettingList();

  }

  addEditFinanceGST = () => {
    if (this.isValidateAllFields('gSTValidationRule')) {
      let request = this.state.addEditGST;
      request.createdBy = 115;
      request.createdAppId = 15;
      this.financeServices.addEditFinanceGST(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.errorType === 'EXISTS') {
            this.swalServices.Error('GST value already exist');
          }
          else {
            this.swalServices.Success("GST details added successfully.");
            this.resetAllFields('resetGST');
            this.getFinanceGSTSettingList();
          }
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  };

  addEditFinanceClassCode = () => {
    if (this.isValidateAllFields('classCodeValidationRule')) {
      let request = this.state.addEditClassCode;
      request.createdBy = 115;
      request.createdAppId = 15;
      this.financeServices.addEditFinanceClassCode(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.errorType === 'EXISTS') {
            this.swalServices.Error("Class code setting already exist.");
          }
          else {
            this.swalServices.Success("Finance class code details added successfully.");
            this.getFinanceClassCodeSettingList();
          }
        } else {
          this.swalServices.Error(response.message);
        }
        this.resetAllFields('resetClassCode');
      });
    }
  };

  addEditFinanceAccountCode = () => {
    if (this.isValidateAllFields('accountCodeValidationRule')) {
      let request = this.state.addEditAccountCode;
      request.createdBy = 115;
      request.createdAppId = 15;
      this.financeServices.addEditFinanceAccountCode(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.errorType === 'EXISTS') {
            this.swalServices.Error("Account code setting already exist.");
          }
          else {
            this.swalServices.Success("Finance account code details added successfully.");
            this.getFinanceAccountCodeSettingList();
          }
        } else {
          this.swalServices.Error(response.message);
        }
        this.resetAllFields('resetAccountCode');
      });
    }
  };

  addEditFinanceCreditNoteApproval = () => {
    // if (this.isValidateAllFields('approvalProcessValidationRule')) {
    let request = this.state.setUpApprovalProcess;
    request.createdBy = 115;
    request.createdAppId = 15;
    this.financeServices.addEditFinanceCreditNoteApproval(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        // if (response.responseItem.responseContent.errorType === 'Exists') {
        //   this.swalServices.Error("GST price already exists.");
        // }
        // else {
        this.swalServices.Success("Finance approval process details added successfully.");
        this.getFinanceCreditNoteApprovalList();
        // }
      } else {
        this.swalServices.Error(response.message);
      }
      this.resetAllFields('resetApprovalProcess');
    });
    // }
  };

  getFinanceGSTSettingList = () => {
    this.setState({ isLoading: true });
    this.financeServices.getFinanceGSTSettingList().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let financeGstSetting = response.responseItem.responseContent;
        this.setState({ gstSettingList: financeGstSetting }, () => {
          this.getFinanceClassCodeSettingList();

        });
      } else {
        this.getFinanceClassCodeSettingList();

        this.swalServices.error("Error Occurred..!");
      }
      this.setState({ isLoading: false });
    });
  };

  getFinanceClassCodeSettingList = () => {
    this.setState({ isLoading: true });
    this.financeServices.getFinanceClassCodeSettingList().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let financeClassCode = response.responseItem.responseContent;
        financeClassCode.map((x) => {
          if (!x.financeClassCodeDescription) {
            x.financeClassCodeDescription = '-';
          }
          x.updatedAt = moment(x.updatedAt).format('D MMM YYYY')
          return x;
        })
        this.setState({ classCodeList: financeClassCode }, () => {
          this.getFinanceAccountCodeSettingList();

        });
      } else {
        this.getFinanceAccountCodeSettingList();

        this.swalServices.error("Error Occurred..!");
      }
      this.setState({ isLoading: false });
    });
  };

  getFinanceAccountCodeSettingList = () => {
    this.setState({ isLoading: true });
    this.financeServices.getFinanceAccountCodeSettingList().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let financeAccountCode = response.responseItem.responseContent;
        financeAccountCode.map((x) => {
          if (!x.financeAccountCodeDescription) {
            x.financeAccountCodeDescription = '-';
          }
          x.updatedAt = moment(x.updatedAt).format('D MMM YYYY')
          return x;});
        this.setState({ accountCodeList: financeAccountCode }, () => {
          this.getFinanceCreditNoteApprovalList();
        });
      } else {
        this.getFinanceCreditNoteApprovalList();
        this.swalServices.error("Error Occurred..!");
      }
      this.setState({ isLoading: false });
    });
  };

  getFinanceCreditNoteApprovalList = () => {
    this.setState({ isLoading: true });
    this.financeServices.getFinanceCreditNoteApprovalList().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let financeApprovalProcessList = response.responseItem.responseContent;
        this.setState({ creditNoteApprovalList: financeApprovalProcessList });
      } else {
        this.swalServices.error("Error Occurred..!");
      }
      this.setState({ isLoading: false });
    });
  };

  // Internal method declaration section start

  resetAllFields = (identity) => {
    if (identity === 'resetGST') {
      let details = this.state.addEditGST;
      details.financeGSTId = 0;
      details.gstStartDate = "";
      details.gstEndDate = "";
      details.gstPercentage = 0.0;
      this.setState({ addEditGST: details });
    }
    else if (identity === 'resetClassCode') {
      let details = this.state.addEditClassCode;
      details.financeClassCodeId = 0;
      details.financeClassCode = "";
      details.financeClassCodeDescription = "";
      this.setState({ addEditClassCode: details });
    }
    else if (identity === 'resetAccountCode') {
      let details = this.state.addEditAccountCode;
      details.financeAccountCodeId = 0;
      details.financeAccountCode = "";
      details.financeAccountCodeDescription = "";
      this.setState({ addEditAccountCode: details });
    }
    else if (identity === 'resetApprovalProcess') {
      let details = this.state.setUpApprovalProcess;
      details.financeStartingAmount = 0.00;
      details.financeEndingAmount = 0.00;
      details.financeApprovalEmail = "";
      this.setState({ setUpApprovalProcess: details });

    }
  };

  handleChange(event, identity) {
    if (identity === 'gstHandle') {
      const value = event.target.value;
      const name = event.target.name;
      let detail = this.state.addEditGST;
      detail[name] = value;
      this.setState({ addEditGST: { ...detail } });
    }
    else if (identity === 'classCodeHandle') {
      const value = event.target.value;
      const name = event.target.name;
      let detail = this.state.addEditClassCode;
      detail[name] = value;
      this.setState({ addEditClassCode: { ...detail } });
    }
    else if (identity === 'accountCodeHandle') {
      const value = event.target.value;
      const name = event.target.name;
      let detail = this.state.addEditAccountCode;
      detail[name] = value;
      this.setState({ addEditAccountCode: { ...detail } });
    }
    else if (identity === 'approveProcessHandle') {
      const value = event.target.value;
      const name = event.target.name;
      let detail = this.state.setUpApprovalProcess;
      detail[name] = value;
      this.setState({ setUpApprovalProcess: { ...detail } });
    }
  };

  isValidateAllFields = (ruleName) => {
    if (ruleName === "gSTValidationRule") {
      const newValidState = isValidForm(
        this.state.addEditGST,
        this.state.gSTValidationRule,
        this.state.validState
      );
      this.setState({ validState: newValidState });
      return newValidState.isValid;
    } else if (ruleName === "classCodeValidationRule") {
      const newValidState = isValidForm(
        this.state.addEditClassCode,
        this.state.classCodeValidationRule,
        this.state.validState
      );
      this.setState({ validState: newValidState });
      return newValidState.isValid;
    } else if (ruleName === "accountCodeValidationRule") {
      const newValidState = isValidForm(
        this.state.addEditAccountCode,
        this.state.accountCodeValidationRule,
        this.state.validState
      );
      this.setState({ validState: newValidState });
      return newValidState.isValid;
    } else if (ruleName === "approvalProcessValidationRule") {
      const newValidState = isValidForm(
        this.state.setUpApprovalProcess,
        this.state.accountCodeValidationRule,
        this.state.validState
      );
      this.setState({ validState: newValidState });
      return newValidState.isValid;
    }
  };

  validateField = (key, ruleName) => {
    if (ruleName === "gSTValidationRule") {
      const newValidState = validate(
        key,
        this.state.addEditGST,
        this.state.gSTValidationRule,
        this.state.validState
      );
      this.setState({ validState: newValidState });
    } else if (ruleName === "classCodeValidationRule") {
      const newValidState = validate(
        key,
        this.state.addEditClassCode,
        this.state.classCodeValidationRule,
        this.state.validState
      );
      this.setState({ validState: newValidState });
    } else if (ruleName === "accountCodeValidationRule") {
      const newValidState = validate(
        key,
        this.state.addEditAccountCode,
        this.state.accountCodeValidationRule,
        this.state.validState
      );
      this.setState({ validState: newValidState });
    }
    else if (ruleName === "approvalProcessValidationRule") {
      const newValidState = validate(
        key,
        this.state.setUpApprovalProcess,
        this.state.approvalProcessValidationRule,
        this.state.validState
      );
      this.setState({ validState: newValidState });
    }
  };

  getGstDataById = (index) => {
    let gstSettingObjectData = this.state.gstSettingList;
    let setData = gstSettingObjectData[index];
    setData.gstStartDate = moment(setData.gstStartDate).format('YYYY-MM-DD');
    setData.gstEndDate = moment(setData.gstEndDate).format('YYYY-MM-DD');
    this.setState({ addEditGST: setData });
  };

  getClassCodeDataById = (index) => {
    let classCodeObjectData = this.state.classCodeList;
    let setData = classCodeObjectData[index];
    this.setState({ addEditClassCode: setData })
  };

  getAccountCodeDataById = (index) => {
    let accountCodeObjectData = this.state.accountCodeList;
    let setData = accountCodeObjectData[index];
    this.setState({ addEditAccountCode: setData })
  };

  getCreditNoteDataById = (index) => {
    let creditNoteObjectData = this.state.creditNoteApprovalList;
    let setData = creditNoteObjectData[index];
    this.setState({ setUpApprovalProcess: setData });
  }
  // Method declaration section start

  gstDelete = (id) => {
    let request = {
      id: id,
      deletedBy: 1,
      deletedAt: moment().format("yyyy-MM-DD"),
      appId: 114,
      deleteFrom: 'FinanceGSTSetting'
    };
    this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "GST Percentage"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "gst percentage"),
      CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
        if (response) {
          this.financeServices.deleteFinanceGST(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              this.swalServices.Success("Finance GST delete successfully.");
              this.getFinanceGSTSettingList();
            } else {
              this.swalServices.Error(response.message);
            }
          });
        }
      });
  };


  deleteClassCode = (id) => {
    let request = {
      id: id,
      deletedBy: 1,
      deletedAt: moment().format("yyyy-MM-DD"),
      appId: 114,
      deleteFrom: 'FinanceClassCodeSetting'
    };
    this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Class Code"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "class code"),
      CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
        if (response) {
          this.financeServices.deleteFinanceGST(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              this.swalServices.Success("Finance class code delete successfully.");
              this.getFinanceClassCodeSettingList();
            } else {
              this.swalServices.Error(response.message);
            }
          });
        }
      });
  }

  deleteAccountCode = (id) => {
    let request = {
      id: id,
      deletedBy: 1,
      deletedAt: moment().format("yyyy-MM-DD"),
      appId: 114,
      deleteFrom: 'FinanceAccountCodeSetting'
    };
    this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Account Code"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "account Code"),
      CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
        if (response) {
          this.financeServices.deleteFinanceGST(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              this.swalServices.Success("Finance account code delete successfully.");
              this.getFinanceAccountCodeSettingList();
            } else {
              this.swalServices.Error(response.message);
            }
          });
        }
      });
  }

  deleteCreditNote = (id) => {
    let request = {
      id: id,
      deletedBy: 1,
      deletedAt: moment().format("yyyy-MM-DD"),
      appId: 114,
      deleteFrom: 'FinanceCreditNoteApprovalProcessSetting'
    };
    this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Credit Note"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "credit note"),
      CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
        if (response) {
          this.financeServices.deleteFinanceGST(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              this.swalServices.Success("Finance credit note delete successfully.");
              this.getFinanceCreditNoteApprovalList();
            } else {
              this.swalServices.Error(response.message);
            }
          });
        }
      });
  }

  render() {
    return (
      <div className="main-body-section finance-body-sec">
        {/* Add/Edit GST Start */}
        <div className="bg-white pb-2">
          <div className="heading-part pl-7 py-4 border-b">
            <div className="flex items-center justify-between">
              <h4 className="small-title font-bold theme-color">
                Add/Edit GST
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pt-10">
            <div className=" 2xl:col-span-3 lg:col-span-3 col-span-12 w-full pr-14">
              <h2 className="text-xl theme-color mb-3">Start Date</h2>
              <input
                id="gstStartDate"
                name="gstStartDate"
                className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                type="date"
                value={this.state.addEditGST.gstStartDate}
                onChange={(event) => this.handleChange(event, "gstHandle")}
                onBlur={() =>
                  this.validateField("gstStartDate", "gSTValidationRule")
                }
              />
              <ValidationText
                error={this.state.validState.error.gstStartDate}
              />
            </div>
            <div className=" 2xl:col-span-3 lg:col-span-3 col-span-12 w-full pr-14">
              <h2 className="text-xl theme-color mb-3">End Date</h2>
              <input
                id="gstEndDate"
                name="gstEndDate"
                className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                type="date"
                value={this.state.addEditGST.gstEndDate}
                onChange={(event) => this.handleChange(event, "gstHandle")}
                onBlur={() =>
                  this.validateField("gstEndDate", "gSTValidationRule")
                }
              />
               <ValidationText
                error={this.state.validState.error.gstEndDate}
              />
            </div>
            <div className="2xl:col-span-3 lg:col-span-3 col-span-12 w-full pr-14">
              <h2 className="text-xl theme-color mb-3">GST Percentage</h2>
              <div className="percentage-sign with-border-line w-full">
                <input
                  id="gstPercentage"
                  className="input-texts form-input rounded-none w-full shadow-red py-3 pr-10 text-lg custom-percentage-input"
                  type="number"
                  name="gstPercentage"
                  value={this.state.addEditGST.gstPercentage}
                  onChange={(event) => this.handleChange(event, "gstHandle")}
                  onBlur={() =>
                    this.validateField("gstPercentage", "gSTValidationRule")
                  }
                />
              </div>
              <ValidationText
                error={this.state.validState.error.gstPercentage}
              />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 border-b mt-5 pb-10">
            <div className="col-span-12 w-full px-8">
              <div className="pt-7 flex items-center justify-between w-full">
                <button
                  className="btn btn-lighblue text-xl border text-white"
                  onClick={() => this.resetAllFields('resetGST')}
                >
                  Reset
                </button>
                <button
                  className="btn btn-red text-xl border text-white"
                  onClick={() => this.addEditFinanceGST()}
                >
                  Add New
                </button>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full items-center px-8">
            <div className="col-span-12">
              <div className="table-sec">
                <div className="w-full mx-auto">
                  <div className="relative">
                    <div className="mt-6">
                      <h2 className="small-title font-bold theme-color">
                        GST Table
                      </h2>
                      <div>
                        {this.state.isLoading ?
                          <PageLoader /> :
                          <GstTable
                            gstTableData={this.state.gstSettingList}
                            getGstDataById={this.getGstDataById.bind(this)}
                            gstDelete={this.gstDelete.bind(this)}
                          />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Add/Edit GST End */}

        {/* Create Class Code Start */}
        <div className="bg-white pb-2 mt-10">
          <div className="heading-part pl-7 py-4 border-b">
            <div className="flex items-center justify-between">
              <h4 className="small-title font-bold theme-color">
                Create Class Code
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pt-10 pb-12">
            <div className=" 2xl:col-span-4 lg:col-span-4 col-span-12 w-full pr-14">
              <h2 className="text-xl theme-color mb-3">Class Code</h2>
              <input
                id="financeClassCode"
                name="financeClassCode"
                className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                value={this.state.addEditClassCode.financeClassCode}
                onChange={(event) => this.handleChange(event, "classCodeHandle")}
                onBlur={() =>
                  this.validateField("financeClassCode", "classCodeValidationRule")
                }
              />
              <ValidationText
                error={this.state.validState.error.financeClassCode}
              />
            </div>
            <div className="2xl:col-span-4 lg:col-span-4 col-span-12 w-full pr-14">
              <h2 className="text-xl theme-color mb-3">Class Description</h2>
              <div className="with-border-line w-full">
                <input
                  id="financeClassCodeDescription"
                  className="form-input rounded-none w-full shadow-red py-3 text-lg"
                  type="text"
                  name="financeClassCodeDescription"
                  value={
                    this.state.addEditClassCode.financeClassCodeDescription
                  }
                  onChange={(event) => this.handleChange(event, "classCodeHandle")}
                  onBlur={() =>
                    this.validateField("financeClassCodeDescription", "classCodeValidationRule")
                  }
                />
              </div>
              <ValidationText
                error={this.state.validState.error.financeClassCodeDescription}
              />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 border-b pb-10">
            <div className="col-span-12 w-full px-8">
              <div className="flex items-center justify-between w-full">
                <button
                  className="btn btn-lighblue text-xl border text-white"
                  onClick={() => this.resetAllFields('resetClassCode')}
                >
                  Reset
                </button>
                <button
                  className="btn btn-red text-xl border text-white"
                  onClick={() => this.addEditFinanceClassCode()}
                >
                  Add New
                </button>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full items-center px-8">
            <div className="col-span-12">
              <div className="table-sec">
                <div className="w-full mx-auto">
                  <div className="relative">
                    <div className="mt-6">
                      <div>
                        {this.state.isLoading ?
                          <PageLoader /> :
                          <CreateClassCodeTable
                            classCodeTable={this.state.classCodeList}
                            getClassCodeDataById={this.getClassCodeDataById.bind(this)}
                            deleteClassCode={this.deleteClassCode.bind(this)}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Create Class Code End */}

        {/* Create Account Code Start */}
        <div className="bg-white pb-2 mt-10">
          <div className="heading-part pl-7 py-4 border-b">
            <div className="flex items-center justify-between">
              <h4 className="small-title font-bold theme-color">
                Create Account Code
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pt-10 pb-12">
            <div className=" 2xl:col-span-4 lg:col-span-4 col-span-12 w-full pr-14">
              <h2 className="text-xl theme-color mb-3">Account Code</h2>
              <input
                id="financeAccountCode"
                name="financeAccountCode"
                className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                value={this.state.addEditAccountCode.financeAccountCode}
                onChange={(event) => this.handleChange(event, "accountCodeHandle")}
                onBlur={() =>
                  this.validateField("financeAccountCode", "accountCodeValidationRule")
                }
              />
              <ValidationText
                error={this.state.validState.error.financeAccountCode}
              />
            </div>
            <div className="2xl:col-span-4 lg:col-span-4 col-span-12 w-full pr-14">
              <h2 className="text-xl theme-color mb-3">Account Description</h2>
              <div className="with-border-line w-full">
                <input
                  id="financeAccountCodeDescription"
                  name="financeAccountCodeDescription"
                  className="form-input rounded-none w-full shadow-red py-3 text-lg"
                  type="text"
                  value={
                    this.state.addEditAccountCode.financeAccountCodeDescription
                  }
                  onChange={(event) => this.handleChange(event, "accountCodeHandle")}
                  onBlur={() =>
                    this.validateField("financeAccountCodeDescription", "accountCodeValidationRule")
                  }
                />
                <ValidationText
                  error={this.state.validState.error.financeAccountCodeDescription}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 border-b pb-10">
            <div className="col-span-12 w-full px-8">
              <div className="flex items-center justify-between w-full">
                <button
                  className="btn btn-lighblue text-xl border text-white"
                  onClick={() => this.resetAllFields('resetAccountCode')}
                >
                  Reset
                </button>
                <button
                  className="btn btn-red text-xl border text-white"
                  onClick={() => this.addEditFinanceAccountCode()}
                >
                  Add New
                </button>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full items-center px-8">
            <div className="col-span-12">
              <div className="table-sec">
                <div className="w-full mx-auto">
                  <div className="relative">
                    <div className="mt-6">
                      <div>
                        {this.state.isLoading ?
                          <PageLoader /> :
                          <CreateAccountCodeTable
                            accountCodeTable={this.state.accountCodeList}
                            getAccountCodeDataById={this.getAccountCodeDataById.bind(this)}
                            deleteAccountCode={this.deleteAccountCode.bind(this)}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Create Account Code End */}

        {/*Set Up Approval Process Start */}
        <div className="bg-white pb-2 mt-10">
          <div className="heading-part pl-7 py-4 border-b">
            <div className="flex items-center justify-between">
              <h4 className="small-title font-bold theme-color">
                Credit Note Approval Process
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pt-10 pb-12">
            <div className=" 2xl:col-span-4 lg:col-span-4 col-span-12 w-full pr-14">
              <h2 className="text-xl theme-color mb-3">Starting Amount</h2>
              <input
                id="financeStartingAmount"
                name="financeStartingAmount"
                className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                type="number"
                value={this.state.setUpApprovalProcess.financeStartingAmount}
                onChange={(event) => this.handleChange(event, "approveProcessHandle")}
                onBlur={() =>
                  this.validateField("financeStartingAmount", "approvalProcessValidationRule")
                }
              />
              <ValidationText
                error={this.state.validState.error.financeStartingAmount}
              />
            </div>
            <div className="2xl:col-span-4 lg:col-span-4 col-span-12 w-full pr-14">
              <h2 className="text-xl theme-color mb-3">Ending Amount</h2>
              <div className="with-border-line w-full">
                <input
                  id="financeEndingAmount"
                  name="financeEndingAmount"
                  className="form-input rounded-none w-full shadow-red py-3 text-lg"
                  type="number"
                  value={
                    this.state.setUpApprovalProcess.financeEndingAmount
                  }
                  onChange={(event) => this.handleChange(event, "approveProcessHandle")}
                  onBlur={() =>
                    this.validateField("financeEndingAmount", "approvalProcessValidationRule")
                  }
                />
                <ValidationText
                  error={this.state.validState.error.financeEndingAmount}
                />
              </div>
            </div>
            <div className=" 2xl:col-span-4 lg:col-span-4 col-span-12 w-full pr-14">
              <h2 className="text-xl theme-color mb-3">Approval Email</h2>
              <input
                id="financeApprovalEmail"
                name="financeApprovalEmail"
                className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                value={this.state.setUpApprovalProcess.financeApprovalEmail}
                onChange={(event) => this.handleChange(event, "approveProcessHandle")}
                onBlur={() =>
                  this.validateField("financeApprovalEmail", "approvalProcessValidationRule")
                }
              />
              <ValidationText
                error={this.state.validState.error.financeApprovalEmail}
              />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 border-b pb-10">
            <div className="col-span-12 w-full px-8">
              <div className="flex items-center justify-between w-full">
                <button
                  className="btn btn-lighblue text-xl border text-white"
                  onClick={() => this.resetAllFields('resetApprovalProcess')}
                >
                  Reset
                </button>
                <button
                  className="btn btn-red text-xl border text-white"
                  onClick={() => this.addEditFinanceCreditNoteApproval()}
                >
                  Add New
                </button>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full items-center px-8">
            <div className="col-span-12">
              <div className="table-sec">
                <div className="w-full mx-auto">
                  <div className="relative">
                    <div className="mt-6">
                      <div>
                        {this.state.isLoading ?
                          <PageLoader /> :
                          <ApprovalProcessTable
                            creditNoteApprovalList={this.state.creditNoteApprovalList}
                            getCreditNoteDataById={this.getCreditNoteDataById.bind(this)}
                            deleteCreditNote={this.deleteCreditNote.bind(this)}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Set Up Approval Process End */}
      </div>
    );
  }
}