import moment from "moment";
import React, { Component } from 'react'
import FinanceServices from "../../services/axiosServices/apiServices/FinanceServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { saveAs } from "file-saver";
import PageLoader from "../../components/common/pageLoader/PageLoader";
import Selector from "../../components/dropdown/commonDropdown/Selector";
import { CommonValidationMessages } from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import ValidationText from "../../utils/validation/ValidationText";
import Table from "../../components/table/Table";



export class GenAICyberConnectReport extends Component {
    constructor(props) {
        super(props);
        this.financeServices = new FinanceServices();
        this.swalServices = new SwalServices();
        this.state = {
            options: [
                {
                    value: 1,
                    label: "GenAI Report",
                },
                {
                    value: 2,
                    label: "CyberConnect Report",
                }    
            ],
            genAICyberConnectRecordList: [],
            genAICyberConnectRecordRequest: {
                startDate: "",
                endDate: "",
                reportFor: ""
            },
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "CreatedAt",
                orderFlag: 0,
                searchText: "",
            },
            isLoading: false,
            totalCount: 0,
            totalResultes: 0,
            previousReportFor: "",
            previousReportStartDate: "",
            previousReportEndDate: "",
            validState: {
                isValid: true,
                error: {},
            },
            validationRule: {
                startDate: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "Start date for report"),
                    },
                ],
                endDate: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "End date for report"),
                    },
                    {
                        type: "minEndDate",
                        minEndDate: "startDate",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "greater end date")
                    }
                ],
                reportFor: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "type of report"),
                    },
                ],
            },
            genAIData :[
                { name: "downloadDate", title: "Date of Download", },
                { name: "name", title: "Name" },
                { name: "designation", title: "Designation" },
                { name: "companyName", title: "Company Name" },
                { name: "workEmail", title: "Work Email" },
                
            ],
            cyberConnectData :[
                { name: "downloadDate", title: "Date of Download", },
                { name: "name", title: "Name" },
                { name: "companyName", title: "Company Name" },
                { name: "workEmail", title: "Work Email" },
                
            ],
        }
    }
    validateField = (key) => {
        const newValidState = validate(
            key,
            this.state.genAICyberConnectRecordRequest,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
    };

    isValidateAllFields = () => {
        const newValidState = isValidForm(
            this.state.genAICyberConnectRecordRequest,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };

    handleChangeInDropdown(selected, drpIdentity) {
        if (drpIdentity === "ReportType") {
            let dataArr = this.state.genAICyberConnectRecordRequest
            if (selected.value > 0) {
                dataArr.reportFor = selected.label;
            } else {
                dataArr.reportFor = "";
            }
            this.setState({ genAICyberConnectRecordRequest: dataArr }, () => {
                this.validateField("reportFor");
            })
        }
    }
    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'startDate') {
            let date = this.state.genAICyberConnectRecordRequest
            date.startDate = value
            this.setState({ startDate: date })
        }
        if (name === 'endDate') {
            let date = this.state.genAICyberConnectRecordRequest
            date.endDate = value
            this.setState({ endDate: date })
        }
    }
    getGenAICyberConnectRecordList = ()=>{
        let isValidateAllFields = this.isValidateAllFields()
        if (isValidateAllFields === true) {
            if (this.state.genAICyberConnectRecordRequest.startDate && this.state.genAICyberConnectRecordRequest.endDate) {
                this.setState({ isLoading: true });
                let request = { ...this.state.pagination };
                request.startDate = moment(this.state.genAICyberConnectRecordRequest.startDate).format("DD MMM YYYY");
                request.endDate = moment(this.state.genAICyberConnectRecordRequest.endDate).format("DD MMM YYYY");
                request.reportFor = this.state.genAICyberConnectRecordRequest.reportFor;
                this.financeServices.getGenAICyberConnectRecordList(request).then((response) => {
                    if (response.statusCode === 200 && response.responseItem !== null) {
                        if (response.responseItem.responseContent !== null) {
                            let details = response.responseItem.responseContent.itemList
                            if (details.length > 0) {
                                details.map((date) => {
                                    date.downloadDate = date.downloadDate ? moment(date.downloadDate).format("DD MMM YYYY HH:mm:ss") : ""
                                    return date;
                                })
                            }
                            this.setState({
                                genAICyberConnectRecordList: details,
                                totalCount: response.responseItem.responseContent.totalCount,
                                totalResultes: response.responseItem.responseContent.totalCount,
                                previousReportFor: this.state.genAICyberConnectRecordRequest.reportFor,
                                previousReportStartDate: this.state.genAICyberConnectRecordRequest.startDate,
                                previousReportEndDate: this.state.genAICyberConnectRecordRequest.endDate
                            })
                        }
                    }
                    else if (response.statusCode === 409) {
                        this.swalServices.Error(response.message);
                    }
                    else {
                        this.swalServices.Error(response.message);
                    }
                    this.setState({ isLoading: false });
                });
            }
        }
    }
    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getGenAICyberConnectRecordList()
    };


    downloadSheetForGenAICyberConnectRecord = () => {
        let isValidateAllFields = this.isValidateAllFields()
        if (isValidateAllFields) {
            if (this.state.genAICyberConnectRecordRequest.startDate && this.state.genAICyberConnectRecordRequest.endDate) {
                let request = { ...this.state.pagination };
                request.startDate = moment(this.state.genAICyberConnectRecordRequest.startDate).format("DD MMM YYYY");
                request.endDate = moment(this.state.genAICyberConnectRecordRequest.endDate).format("DD MMM YYYY");
                request.reportFor = this.state.genAICyberConnectRecordRequest.reportFor;
                this.financeServices.downloadSheetForGenAICyberConnectRecord(request).then((response) => {
                    if (response.status === 200 && response.data != null) {
                        this.setState({
                            previousReportFor: this.state.genAICyberConnectRecordRequest.reportFor,
                            previousReportStartDate: this.state.genAICyberConnectRecordRequest.startDate,
                            previousReportEndDate: this.state.genAICyberConnectRecordRequest.endDate
                        })
                        let blob = new Blob([response.data])
                        // saveAs(blob, `${request.reportFor}_GenAICyberConnectReport` + ".xlsx");
                        saveAs(blob, `${request.reportFor}_GenAICyberConnectReport.xlsx`);
                    }
                    else {
                        this.swalServices.Error(response.message);
                    }
                    this.setState({ isLoading: false });
                });
            }
        }
    }

  render() {

    // const showDesignationColumn = this.state.genAICyberConnectRecordRequest.reportFor === "GenAI Report";
        
    return (
        <div className="main-body-section finance-body-sec">
            <div className="bg-white">
                <div className="grid grid-cols-12 gap-6 w-full items-center px-8 py-10">
                        <div className="col-span-3">
                            <label
                                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default"
                            >
                                Start Date<span className="text-[#C00000]">*</span>
                            </label>
                            <div className="">
                                <input
                                    id="startDate"
                                    name="startDate"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="date"
                                    value={moment(this.state.genAICyberConnectRecordRequest.startDate).format("yyyy-MM-DD")}
                                    onChange={(event) => this.handleChange(event)}
                                    onBlur={() => this.validateField("startDate")}
                                />
                            </div>
                            <ValidationText error={this.state.validState.error.startDate} />
                        </div>
                        <div className="col-span-3">
                            <label
                                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default"
                            >
                                End Date<span className="text-[#C00000]">*</span>
                            </label>
                            <div className="">
                                <input
                                    id="endDate"
                                    name="endDate"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="date"
                                    value={moment(this.state.genAICyberConnectRecordRequest.endDate).format("yyyy-MM-DD")}
                                    onChange={(event) => this.handleChange(event)}
                                    onBlur={() => this.validateField("endDate")}
                                />
                            </div>
                            <ValidationText error={this.state.validState.error.endDate} />
                        </div>

                        <div className="col-span-3">
                            <label
                                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default"
                            >
                                Report For<span className="text-[#C00000]">*</span>
                            </label>
                            <div className="">
                                <Selector
                                    options={this.state.options}
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    onChange={this.handleChangeInDropdown.bind(this)}
                                    allowSelectAll={false}
                                    value={this.state.options.filter(item => item.label === this.state.genAICyberConnectRecordRequest.reportFor)}
                                    drpIdentity="ReportType"
                                />
                            </div>
                            <ValidationText error={this.state.validState.error.reportFor} />
                        </div>

                        <div className="col-span-4">
                            <button className="btn btn-red text-xl border text-white mt-8" onClick={() => this.getGenAICyberConnectRecordList()}>
                                Show Record
                            </button>
                        </div>

                        {this.state.genAICyberConnectRecordList &&
                            this.state.genAICyberConnectRecordList.length > 0 &&
                            this.state.previousReportFor === this.state.genAICyberConnectRecordRequest.reportFor &&
                            this.state.previousReportStartDate === this.state.genAICyberConnectRecordRequest.startDate &&
                            this.state.previousReportEndDate === this.state.genAICyberConnectRecordRequest.endDate ?
                            <div className="col-span-4">
                                <button className="btn btn-red text-xl border text-white mt-8" onClick={() => this.downloadSheetForGenAICyberConnectRecord()}>
                                    Download Excel
                                </button>
                            </div>
                        : null}
                </div>

                <div className="grid grid-cols-12 gap-6 w-full items-center justify-center px-8 py-10">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                            {this.state.isLoading ? <PageLoader /> :
                                <Table
                                    columns={this.state.genAICyberConnectRecordRequest.reportFor === "CyberConnect Report" ? this.state.cyberConnectData : this.state.genAIData }
                                    pagination={this.state.pagination}
                                    rows={this.state.genAICyberConnectRecordList}
                                    sortingColumns={[""]}
                                    isLoading={this.state.isLoading}
                                    totalResultes={this.state.totalResultes}
                                    totalCount={this.state.totalCount}
                                    setPagination={this.setPagination.bind(this)}
                                />}
                        </div>
                    </div>
            </div>
        </div>
    )
  }
}

export default GenAICyberConnectReport