import React, { Component } from 'react'
import TimePicker from "../../components/TimePicker.js/TimePicker";
import { Navigate } from 'react-router-dom';
import SwalServices from '../../services/swalServices/SwalServices';
import SGTechTVServices from '../../services/axiosServices/apiServices/SGTechTVServices';
import CommonServices from '../../services/axiosServices/apiServices/CommonServices';
import moment from 'moment';
import MyEditor from '../../components/ckEditor/Editor';
import ValidationText from '../../utils/validation/ValidationText';
import ImageCropperWithUpload from '../../components/ImageUpload/UploadImage';
import { components } from 'react-select';
import { CommonValidationMessages, ErrorMessage, SuccessMessage } from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import MultiSelectDropdown from '../../components/dropdown/MultiSelectDropdown';
import ResourceServices from '../../services/axiosServices/apiServices/ResourceServices';
import ToggleButton from '../../components/common/ToggleButton';
import { checkParameters } from '../../utils/Encryption';

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

export default class UploadSGTechTV extends Component {
    constructor(props) {
        super(props)
        this.sgtechServices = new SGTechTVServices();
        this.commonServices = new CommonServices();
        this.swalServices = new SwalServices();
        this.ResourceServices = new ResourceServices();
        this.state = {
            redirect: null,
            route: null,
            isLoading: false,
            sgTechTVId: 0,
            sgTechTVItem: {
                sgTechTVId: 0,
                videoTitle: "",
                description: "",
                uploadThumbnailPath: "",
                videoEmbedLink: "",
                //videoEmbedLink: "https://www.youtube.com/watch?v=rwU64fYIsSI",
                videoFilePath: "",
                //sgTechTVCategoryId: 0,
                topicIds: 0,
                SGTechTVVisibilityId: 1,
                sgTechTVStatusId: 0,
                sgTechTVPublishDate: moment(new Date().toLocaleDateString()).format("ddd, DD MMM yyyy"),
                sgTechTVPublishTime: "12:00 AM",
                isFeatured: false,
                member: true,
                nonMember: false,
                isPublishNow: false,
                isSchedule: true,
                optionSelected: [],
                videoValidation: true,
                // validate: {
                //     description: false,
                //     descriptionLength: false,
                //     // resourceDescriptionImage: false
                // }

            },
            sgTechTopics: [],
            checkFeatured: false,
            categoryList: [],
            validationRule: {
                videoTitle: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'video title')
                    }
                ],
                sgTechTVPublishDate: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.SelectRequired.replace('{0}', 'publish date')
                    }
                ],
                sgTechTVPublishTime: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.SelectRequired.replace('{0}', 'time')
                    }
                ],
                // sgTechTVCategoryId: [
                //     {
                //         type: 'require',
                //         message: CommonValidationMessages.SelectRequired.replace('{0}', 'video category ')
                //     }
                // ],
                optionSelected: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.SelectRequired.replace('{0}', ' topic')
                    }
                ],
                videoEmbedLink: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.SelectRequired.replace('{0}', ' video embed link')
                    }
                ]
            },
            validState: {
                isValid: true,
                error: {}
            },
        };
    }

    // validateSgTechTV = () => {
    //     let isValid = true;
    //     let item = { ...this.state.sgTechTVItem }
    //    // if (item.videoEmbedLink) {
    //         item.videoValidation = true;
    //         // TODO : check for the video format
    //         if (!required(item["videoEmbedLink"])) // Check the proper property name 
    //         {
    //             item.videoValidation = false; // write the proper validation flag
    //             isValid = false;

    //             return isValid;
    //         }
    //         this.setState({ sgTechTVItem: { ...item } });
    //    // }

    // }

    // Method declaration section
    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let sgTechTVId = params.get("");

        //this.sgTechTVFeaturedCheck();
        if (sgTechTVId && (sgTechTVId = checkParameters(sgTechTVId, "Number"))) {
            if (sgTechTVId > 0) {
                this.setState({ sgTechTVId: sgTechTVId }, () => this.getAllTopics());
            }
            else {
                this.getAllTopics();
            }
        } else {
            this.getAllTopics();
        }

        //this.getAllSGTechTVCategory();
    }

    sgTechTVFeaturedCheck = () => {
        this.sgtechServices.sgTechTVFeaturedCheck().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ checkFeatured: response.responseItem.responseContent.isFeatured });
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    getSGTechTVById = (id) => {
        this.sgtechServices.getSGTechTVById(id).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let detail = this.state.sgTechTVItem;
                if (response.responseItem.responseContent.sgTechTVVisibilityId === 4) {
                    detail.member = true;
                    detail.nonMember = false;
                } else if (response.responseItem.responseContent.sgTechTVVisibilityId === 5) {
                    detail.nonMember = true;
                    detail.member = false;
                }
                if (response.responseItem.responseContent.isPublishNow === true) {
                    detail.isPublishNow = true;
                    detail.isSchedule = false;

                } else if (response.responseItem.responseContent.isPublishNow === false) {
                    detail.isSchedule = true;
                    detail.isPublishNow = false;
                }

                detail.sgTechTVPublishDate = response.responseItem.responseContent.sgTechTVPublishDate;
                detail.sgTechTVPublishTime = response.responseItem.responseContent.sgTechTVPublishTime;
                detail.sgTechTVId = response.responseItem.responseContent.sgTechTVId;
                detail.videoTitle = response.responseItem.responseContent.videoTitle;
                detail.description = response.responseItem.responseContent.description;
                detail.videoEmbedLink = response.responseItem.responseContent.videoEmbedLink;
                detail.videoFilePath = response.responseItem.responseContent.videoFilePath;
                detail.uploadThumbnailPath = response.responseItem.responseContent.uploadThumbnailPath;
                detail.sgTechTVCategoryId = response.responseItem.responseContent.sgTechTVCategoryId;
                detail.isFeatured = response.responseItem.responseContent.isFeatured;
                // if (response.responseItem.responseContent.isFeatured === true) {
                //     this.setState({ checkFeatured: false });
                //     //detail.isFeatured = true ;
                // } 
                var array = response.responseItem.responseContent.topicIds.split(',');
                const options = [];
                array.forEach(topic => {
                    options.push(this.state.sgTechTopics.find(topics => topics.value === Number(topic)));
                });
                detail.optionSelected = options;
                this.setState({ sgTechTVItem: detail });

            } else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    getAllSGTechTVCategory = () => {
        this.sgtechServices.getAllSGTechTVCategory().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ categoryList: response.responseItem.responseContent });
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    addEditSGTechTv = (id) => {

        let detail = this.state.sgTechTVItem;
        if (detail['member'] === true) {
            detail['SGTechTVVisibilityId'] = 4;
        }
        else {
            detail['SGTechTVVisibilityId'] = 5;
        }
        if (detail['isPublishNow'] === true) {
            detail['isPublishNow'] = true;
            if (this.state.sgTechTVId > 0) {

            } else {
                detail['sgTechTVPublishDate'] = moment(new Date().toLocaleDateString()).format("yyyy-MM-DD");
                detail['sgTechTVPublishTime'] = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            }
        }
        else {
            detail['isPublishNow'] = false;
        }
        if (id > 0) {
            detail['sgTechTVStatusId'] = id;
        }

        if (detail.optionSelected.length > 0) {
            var arr1 = this.state.sgTechTVItem.optionSelected.map(function (item) {
                delete item.bad;
                return item.value;
            });
            detail.topicIds = arr1.join([',']);
            //this.validateField("resourceTypeId");
        }

        this.setState({ sgTechTVItem: { ...detail } });
        let request = this.state.sgTechTVItem;
        let isValidInput = this.isValidateAllFields()
        if (isValidInput) {
            this.sgtechServices.addEditSGTechTv(request).then(
                (response) => {
                    if (response.statusCode === 200 && response.responseItem != null) {
                        if (response.responseItem.responseContent.errorType === "EXISTS") {
                            this.swalServices.Warning(ErrorMessage.Already_Exist.replace("{0}", "SLA TV details"));
                        }
                        else {
                            this.swalServices.Success(
                                SuccessMessage.Insert_Success.replace("{0}", "Your SLA TV details"));
                            // this.setState({ route: "/SGTechTV" });
                            this.setState({ redirect: "/SGTechTV" });
                        }
                        // }
                    } else {
                        this.swalServices.Error(ErrorMessage.DefaultMessage);
                    }
                    this.setState({ isLoading: false });
                }
            );
        }
    };

    isValidateAllFields = () => {
        const newValidState = isValidForm(this.state.sgTechTVItem, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    }
    validateField = (key) => {
        const newValidState = validate(key, this.state.sgTechTVItem, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
    }

    getAllTopics = () => {
        this.ResourceServices.getAllResourceTopics().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ sgTechTopics: response.responseItem.responseContent });
                if (this.state.sgTechTVId > 0) {
                    this.getSGTechTVById(this.state.sgTechTVId)
                }
            }
            else {
                if (this.state.sgTechTVId > 0) {
                    this.getSGTechTVById(this.state.sgTechTVId)
                }
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }


    handleChangeInDropdown = selected => {
        var res = this.state.sgTechTVItem;
        res.optionSelected = selected
        this.setState({ sgTechTVItem: res });
        this.validateField("optionSelected");
    };

    saveMainImage = (file, keyId, imageIdentity) => {
        // this.setState({ isLoaderEnabled: true });
        file.storagePath = imageIdentity
        this.commonServices.storeBase64Image(file).then((response) => {
            if (response != null) {
                this.swalServices.Success("File Uploaded");
                let detail = this.state.sgTechTVItem;
                if (file.storagePath === "SGTechTV") {
                    detail["uploadThumbnailPath"] = response.fileName;
                    this.setState({ sgTechTVItem: { ...detail } },
                    );
                }
            }
            else {
                this.swalServices.Error(response.message);
            }
        })
    }

    handleRadioButtons(event) {
        const value = event.target.checked;
        const id = event.target.id;
        let detail = this.state.sgTechTVItem;
        detail['member'] = false;
        detail['nonMember'] = false;
        detail[id] = value;
        this.setState({ sgTechTVItem: { ...detail } });
    }
    handleRadioButtons2(event) {
        const value = event.target.checked;
        const id = event.target.id;
        let detail = this.state.sgTechTVItem;
        detail['isPublishNow'] = false;
        detail['isSchedule'] = false;
        detail[id] = value;
        this.setState({ sgTechTVItem: { ...detail } });
    }

    setTime = (time) => {
        let detail = this.state.sgTechTVItem;
        if (time.identity === "PublishTime") {
            detail['sgTechTVPublishTime'] = time.selectedTime;
        }
        this.setState({ sgTechTVItem: { ...detail } });
    }

    filterByFeatured = (value, e) => {
        let detail = this.state.sgTechTVItem;
        this.setState({ setToggle: e.target.checked });
        detail.isFeatured = e.target.checked;
        // this.swalServices.Warning("If featured type true then remove your previous featured type video into normal video.");
        this.setState({ sgTechTVItem: { ...detail } });
    };


    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = this.state.sgTechTVItem;
        detail[name] = value;
        if (name === "videoEmbedLink") {
            const urlFormate = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?(?=.*v=((\w|-){11}))(?:\S+)?$/;
            if (value && value !== "") {
                if (value.match(urlFormate)) {
                    let splitUrl = value.split("=");
                    let embedUrl = `https://www.youtube.com/embed/${splitUrl[1]}`;
                    if (embedUrl.includes("&")) {
                        let splitUrl = embedUrl.split("&");
                        embedUrl = splitUrl[0];
                    }
                    detail[name] = embedUrl;
                }
                else {
                    this.swalServices.Error("Please enter correct youtube url formate");
                    detail[name] = value;
                }
            }
        }
        this.setState({ sgTechTVItem: { ...detail } });
    }

    setVideoDescription = (data) => {
        let detail = this.state.sgTechTVItem;
        var re = new RegExp("<a href=\"([^\"]+)\"", "g");
        var result = data.replace(re, "<a href='$1' target=\"_blank\" rel=\"noopener noreferrer\"");
        detail["description"] = result;
        this.setState({ sgTechTVItem: { ...detail } });
    };

    handleVideoEditorChange(event, editor) {
        const data = editor.getData();
        this.setVideoDescription(data);
    }

    back = () => {
        this.setState({ redirect: "/SGTechTV" });
    }
    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <div className='main-body-section'>

                <div className='bg-white'>
                    <div className='heading-part px-7 py-4 border-b'>
                        <h4 className='text-2xl font-bold theme-color'>Video Information</h4>
                    </div>
                    <div className="px-14 py-4">
                        <div className='my-5'>
                            <div className='grid grid-cols-12 gap-6'>
                                <div className='xl:col-span-8'>
                                    <div className='grid grid-cols-12 pr-14 gap-6'>
                                        <div className=' xl:col-span-8'>
                                            <label className="block text-xl font-normal mb-3 theme-color min-h-30" htmlFor="default">Title<span className='text-[#C00000]'>*</span></label>
                                            <input
                                                id="default"
                                                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                                                type="text"
                                                name="videoTitle"
                                                value={this.state.sgTechTVItem.videoTitle}
                                                onChange={(event) => this.handleChange(event)}
                                                onBlur={() => this.validateField("videoTitle")}
                                            />
                                            <div>
                                                <ValidationText error={this.state.validState.error.videoTitle} />
                                            </div>
                                        </div>
                                        <div className=' xl:col-span-12 mt-5'>
                                            <label className="block text-xl font-normal mb-3 theme-color min-h-30" htmlFor="default">Description <span className='text-[#C00000]'>*</span></label>
                                            <div className="ck-editor-sec">
                                                <MyEditor
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        this.setVideoDescription(data);
                                                    }}
                                                    // data={value.resourceDescription}
                                                    data={this.state.sgTechTVItem.description}
                                                />
                                                {/* {
                                                    (this.state.sgTechTVItem.validate && this.state.sgTechTVItem.validate.description === true) ? <ValidationText
                                                        error={"Please enter description"}
                                                    /> : null
                                                } */}
                                                {(!this.state.sgTechTVItem.description) ?
                                                    <ValidationText
                                                        error={"Please enter description"}
                                                    />
                                                    :
                                                    null
                                                }
                                            </div>
                                            {/* <input id="description" className="mt-4 form-input rounded-none w-full shadow-red py-3 text-lg h-52" placeholder='Add summer note hear' type="text"
                                                value={this.state.sgTechTV.description}
                                                onChange={(event) => this.handleChange(event)}
                                            /> */}
                                        </div>

                                    </div>
                                    <div className='grid grid-cols-12 pr-14 mt-10 gap-6'>
                                        <div className=' xl:col-span-7'>
                                            <div className="flex items-center space-x-2">
                                                <label className="block text-xl font-normal mb-4 theme-color min-h-30" htmlFor="default">Video Embed Link</label>
                                                <div className="video-tooltip-sec">
                                                    <img className="w-[25px] mb-4" src="/tooltip.png" alt="" />
                                                    <div className="tool-tip ml-5">
                                                        <div className="tool-tip-inner">
                                                            <div className="popover-heading font-bold">
                                                                Instruction
                                                            </div>
                                                            <div className="popover-body">
                                                                <p>
                                                                    <strong>1.</strong> On a computer, go to the YouTube video you
                                                                    want to embed.
                                                                </p>
                                                                <p>
                                                                    <strong>2.</strong> Copy Browser Link and Paste.
                                                                    (e.g.https://www.youtube.com/watch?v=Ke90Tje7VS0)
                                                                </p>
                                                                <p>
                                                                    <strong>3.</strong> Link will be Automatically convert into
                                                                    embed url
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <input
                                                id="default"
                                                name="videoEmbedLink"
                                                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                                                type="text"
                                                placeholder=""
                                                value={this.state.sgTechTVItem.videoEmbedLink}
                                                onChange={(event) => this.handleChange(event)}
                                                onBlur={() => this.validateField("videoEmbedLink")}
                                            />
                                            <div>
                                                {!this.state.sgTechTVItem.videoEmbedLink ? (
                                                    <ValidationText
                                                        // error={
                                                        //     "Please enter Video URL with proper format."
                                                        // }
                                                        error={this.state.validState.error.videoEmbedLink}
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className=' xl:col-span-2 text-center'>
                                            <label className="block text-xl font-normal mb-6 theme-color min-h-30" htmlFor="default"></label>
                                            <span className='text-2xl theme-color'>Or</span>
                                        </div>
                                        <div className=' xl:col-span-3'>
                                            <label className="block text-xl font-normal mb-4 theme-color min-h-30" htmlFor="default">Upload Video</label>
                                            <button className="px-5 w-full py-2 btn-red-border text-xl border text-[#C00000]">
                                                Upload file
                                            </button>
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-12 pr-14  mt-10 gap-6'>
                                        <div className=' xl:col-span-7'>
                                            <label className="block text-xl font-normal mb-3 theme-color min-h-30" htmlFor="default">
                                                {/* Category */}
                                                Topics
                                                <span className='text-[#C00000]'>*</span>
                                            </label>
                                            {/* <DropdownSelect
                                                drpIdentity={"TvCategory"}
                                                optionArray={this.state.categoryList}
                                                setFilterParameters={this.setFilterParameters}
                                                value={this.state.sgTechTVItem.sgTechTVCategoryId}
                                            />
                                            <div>
                                                <ValidationText error={this.state.validState.error.sgTechTVCategoryId} />
                                            </div> */}
                                            <MultiSelectDropdown
                                                options={this.state.sgTechTopics}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                components={{
                                                    Option,
                                                }}
                                                onChange={this.handleChangeInDropdown.bind(this)}
                                                allowSelectAll={true}
                                                value={this.state.sgTechTVItem.optionSelected}
                                            />
                                            <div>
                                                {/* <ValidationText
                                                    error={props.validState.error.optionSelected}
                                                /> */}
                                                {this.state.sgTechTVItem.optionSelected ?
                                                    <ValidationText error={this.state.validState.error.optionSelected} />
                                                    :
                                                    null
                                                }
                                                {/* <ValidationText error={this.state.validState.error.optionSelected} /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='grid pr-14 mt-10 '>
                                        <ToggleButton
                                            label="Featured"
                                            setToggle={this.state.sgTechTVItem.isFeatured}
                                            onHandleToggle={this.filterByFeatured.bind(this)}
                                            index={0}
                                        //disabled={this.state.checkFeatured}
                                        />
                                    </div>
                                    {/* <div className='grid grid-cols-12 pt-8'>
                                        <ToggleButton
                                            label="Featured"
                                            setToggle={this.state.sgTechTVItem.isFeatured}
                                            onHandleToggle={this.filterByFeatured.bind(this)}
                                            //disabled={this.state.checkFeatured}
                                        />
                                        
                                    </div> */}
                                    <div className='grid grid-cols-12 pr-14  mt-8 gap-6'>
                                        <div className=' xl:col-span-7'>
                                            <label className="block text-xl font-normal mb-3 theme-color min-h-30" htmlFor="default">Access<span className='text-[#C00000]'>*</span></label>
                                            <div className='grid grid-cols-12 pr-14  gap-6'>
                                                <div className=' xl:col-span-6'>
                                                    <label className="flex items-center">
                                                        <input
                                                            type="radio"
                                                            name="member"
                                                            id="member"
                                                            className="form-radio"
                                                            value={this.state.sgTechTVItem.member}
                                                            onChange={(event) =>
                                                                this.handleRadioButtons(event)
                                                            }
                                                            checked={this.state.sgTechTVItem.member === true}
                                                        />
                                                        <span className="text-xl  font-normal theme-color ml-2">Member</span>
                                                    </label>
                                                </div>
                                                <div className=' xl:col-span-6'>
                                                    <label className="flex items-center">
                                                        <input
                                                            type="radio"
                                                            name="nonMember"
                                                            id="nonMember"
                                                            className="form-radio"
                                                            value={this.state.sgTechTVItem.nonMember}
                                                            onChange={(event) =>
                                                                this.handleRadioButtons(event)
                                                            }
                                                            checked={this.state.sgTechTVItem.nonMember === true}
                                                        />
                                                        <span className="text-xl  font-normal theme-color ml-2">Non-Member</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-12 pr-14  mt-10 gap-6'>
                                        <div className=' xl:col-span-7'>
                                            <label className="block text-xl font-normal mb-3 theme-color min-h-30" htmlFor="default">Publish Date & Time <span className='text-[#C00000]'>*</span></label>
                                            <div className='grid grid-cols-12 pr-14  gap-6'>
                                                <div className=' xl:col-span-6'>
                                                    <label className="flex items-center">
                                                        <input
                                                            type="radio"
                                                            name="publish-now"
                                                            id="isPublishNow"
                                                            className="form-radio"
                                                            value={this.state.sgTechTVItem.isPublishNow}
                                                            onChange={(event) =>
                                                                this.handleRadioButtons2(event)
                                                            }
                                                            checked={this.state.sgTechTVItem.isPublishNow === true}
                                                        />
                                                        <span className="text-xl  font-normal theme-color ml-2">Publish Now</span>
                                                    </label>
                                                </div>
                                                <div className=' xl:col-span-6'>
                                                    <label className="flex items-center">
                                                        <input
                                                            type="radio"
                                                            name="schedule-now"
                                                            id="isSchedule"
                                                            className="form-radio"
                                                            value={this.state.sgTechTVItem.isSchedule}
                                                            onChange={(event) =>
                                                                this.handleRadioButtons2(event)
                                                            }
                                                            checked={this.state.sgTechTVItem.isSchedule === true}
                                                        />
                                                        <span className="text-xl  font-normal theme-color ml-2">Schedule</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.sgTechTVItem.isPublishNow === true ?
                                        null
                                        :
                                        <div className='grid grid-cols-12 pr-14  mt-7 gap-6'>
                                            <div className=' xl:col-span-9'>
                                                <div className='grid grid-cols-12 gap-6 items-center w-full'>
                                                    <div className=' xl:col-span-6 pr-3 w-full'>
                                                        <label className="block text-xl font-normal mb-3 theme-color min-h-30" htmlFor="default">Date<span className='text-[#C00000]'>*</span></label>
                                                        <input
                                                            id="default"
                                                            name="sgTechTVPublishDate"
                                                            className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                                            type="date"
                                                            // value={
                                                            //     this.state.sgTechTVItem.isSchedule === true ?
                                                            //         moment(this.state.sgTechTVItem.sgTechTVPublishDate).format("yyyy-MM-DD")
                                                            //         :
                                                            //         moment(new Date().toLocaleDateString()).format("yyyy-MM-DD")

                                                            // }
                                                            value={moment(this.state.sgTechTVItem.sgTechTVPublishDate).format("yyyy-MM-DD")}
                                                            onChange={(event) => this.handleChange(event)}
                                                            onBlur={() => this.validateField("sgTechTVPublishDate")}
                                                        />
                                                        <div>
                                                            <ValidationText error={this.state.validState.error.sgTechTVPublishDate} />
                                                        </div>
                                                    </div>
                                                    <div className=' xl:col-span-6 pl-3 w-full '>
                                                        <label className="block text-xl font-normal mb-3 theme-color min-h-30" htmlFor="default">Time <span className='text-[#C00000]'>*</span></label>
                                                        <TimePicker
                                                            identity="PublishTime"
                                                            setTime={this.setTime.bind(this)}
                                                            value={this.state.sgTechTVItem.sgTechTVPublishTime}
                                                        // value={
                                                        //     this.state.sgTechTVItem.isSchedule === true ?
                                                        //         this.state.sgTechTVItem.sgTechTVPublishTime
                                                        //         :
                                                        //         new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })                                                                    
                                                        // }

                                                        />
                                                        <ValidationText error={this.state.validState.error.sgTechTVPublishTime} />

                                                    </div>
                                                </div>
                                                <div className='grid grid-cols-12 mt-7 gap-6 items-center w-full'>
                                                    <div className=' xl:col-span-12 pr-3 w-full'>
                                                        {this.state.sgTechTVItem.isSchedule === true ?
                                                            <p className='text-xl font-normal mb-3 theme-color'>
                                                                Scheduled for {moment(this.state.sgTechTVItem.sgTechTVPublishDate).format("ddd, DD MMM yyyy")} at {(this.state.sgTechTVItem.sgTechTVPublishTime)}.
                                                            </p>
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className='xl:col-span-4'>
                                    <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                                        <ImageCropperWithUpload
                                            key="Main"
                                            height={460}
                                            width={814}
                                            isCheckValidation={true}
                                            MaxFileSize={2097152}
                                            MaxFileSizeMessage={"2MB"}
                                            onSaveImage={this.saveMainImage}
                                            imageIdentity="SGTechTV"
                                            intitImage={this.state.sgTechTVItem.uploadThumbnailPath}
                                            aspactRatio={550 / 260}
                                            uploadBtn={"Upload Thumbnail"}
                                            dimensionText={"(Dimension: 1280px by 720px Maximum Size: 1MB)"}
                                        />
                                    </div>
                                    {/* <div className='grid grid-cols-12 gap-6'>
                                        <div className='xl:col-span-3'>
                                        </div>
                                        <div className='xl:col-span-12 '>
                                            <div className='img-logo-upload text-center relative'>
                                                <img src='./default-img.png' className=' h-52 w-full object-center object-cover border border-slate-300 my-0 mx-auto' alt=''></img>
                                                <span className='close-sign absolute font-extralight fa fa-times theme-color border rounded-full bg-zinc-200 w-6 flex items-center justify-center h-6 p-1'></span>
                                                <p className='mt-3 px-7 text-center font-normal text-xl theme-color'>
                                                    (Dimension: 1280px by 720px Maximum Size: 1MB)
                                                </p>
                                                <button className="px-10 mt-3 py-2 btn-red-border text-xl border text-[#C00000]">
                                                    Upload Thumbnail
                                                </button>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Footer Button End*/}
                <div className="flex flex-wrap justify-between space-x-10 mt-12">
                    <div>
                        <button className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white" onClick={() => this.back()} >Back</button>
                    </div>
                    <div>
                        <button
                            className="w-40 px-5 mr-16 py-2 btn-sm btn-lighblue text-lg text-white"
                            onClick={() => this.addEditSGTechTv(1)}
                        >
                            Save as Draft
                        </button>
                        {this.state.sgTechTVItem.isPublishNow && this.state.sgTechTVItem.isPublishNow === true ?
                            (<button
                                className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white"
                                onClick={() => this.addEditSGTechTv(6)}
                            >
                                Publish
                            </button>)
                            :
                            //(<button className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white">Schedule</button>)
                            (<button className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white"
                                onClick={() => this.addEditSGTechTv(11)}
                            >
                                Schedule
                            </button>)
                        }
                    </div>
                </div>
                {/*Footer Button End*/}
            </div>
        )
    }
}