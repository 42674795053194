import { axiosGetAuthorize, axiosPostAuthorize } from '../AxiosRequests';
import { GetAllResourcesList, GetResourceById, DeleteResources, AddEditResources, GetAllResourceTypes, GetAllResourceTopics, AddEditResourceTaggingDetails, GetResourceTaggingDetails } from '../ApiEndPoints'

export default class ResourceServices {

    async getAllResourcesList(request) {
        return axiosPostAuthorize(GetAllResourcesList, request)
    }

    async getResourceById(request) {
        return axiosGetAuthorize(GetResourceById, request)
    }

    async deleteResources(request) {
        return axiosPostAuthorize(DeleteResources, request)
    }

    async addEditResources(request) {
        return axiosPostAuthorize(AddEditResources, request)
    }

    async getAllResourceTypes() {
        return axiosGetAuthorize(GetAllResourceTypes);
    }

    async getAllResourceTopics() {
        return axiosGetAuthorize(GetAllResourceTopics);
    }

    async addEditResourceTaggingDetails(request) {
        return axiosPostAuthorize(AddEditResourceTaggingDetails, request)
    }

    async getResourceTaggingDetails(request) {
        return axiosGetAuthorize(GetResourceTaggingDetails, request)
    }


}
