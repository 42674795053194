import {
    GetAllGovernmentPartnersList,
    GetAllGovernmentProject,
    GetAllStrategicMembersList,
    DeleteGovernmentAgency,
    DeleteGovernmentProject,
    DeleteGovernmentStrategicMember,
    DeleteGovernmentAgencyOfficer,
    GetAllProjectReviewList,
    GetAllAgencyDropdown,
    GetAllMemberDropdown,
    GetAllChapterDropdown,
    GetAllAgencyOfficerList,
    AddEditAgencyOfficer,
    GetAllStrategicMemberList,
    GetCompanyOfficerByCompanyForStrategicMember,
    GetAllCompanyForStrategicMember,
    AddStrategicMember,
    GetAgencyOfficerById,
    AddEditAgencyPartner,
    GetGovernmentAgencyById,
    GetAllSecretariatList,
    // GetAllAgencyListForAddEditProject,
    GetAllGovernmentAgencyList,
    AddEditProject,
    // GetProjectDetailsById,
    AddAgencyOfficerToAddEditProject,
    GetAgencyOfficerListForAddEditProject,
    GetGovernmentProjectById,
    GetStrategicMemberListForAddEditProject,
    UpdateAgencyOfficerKeyStatusForAddeEditProject,
    DeleteAgencyOfficerFromAddEditProject,
    DeleteStrategicMemberFromAddEditProject,
} from "../ApiEndPoints";
import { axiosGetMultiParams, axiosPostAuthorize, axiosGetAuthorize } from "../AxiosRequests";

export default class PartnershipsServices {
    // All get list methods
    async getGovernmentActionPartnerList(request) {
        return axiosPostAuthorize(GetAllGovernmentPartnersList, request);
    }
    async getAllGovernmentProject(request) {
        return axiosPostAuthorize(GetAllGovernmentProject, request);
    }
    async getStrategicMemberList(request) {
        return axiosPostAuthorize(GetAllStrategicMembersList, request);
    }
    async getAllProjectReviewList(request) {
        return axiosPostAuthorize(GetAllProjectReviewList, request);
    }
    //All delete methods
    async deleteGovernmentAgency(request) {
        return axiosPostAuthorize(DeleteGovernmentAgency, request);
    }
    async deleteGovernmentProject(request) {
        return axiosPostAuthorize(DeleteGovernmentProject, request);
    }
    async deleteGovernmentStrategicMember(request) {
        return axiosPostAuthorize(DeleteGovernmentStrategicMember, request);
    }
    async deleteGovernmentAgencyOfficer(request) {
        return axiosPostAuthorize(DeleteGovernmentAgencyOfficer, request);
    }
    //All dropdown methods
    async getAllAgencyDropdown(request) {
        return axiosGetAuthorize(GetAllAgencyDropdown, request);
    }
    async getAllMemberDropdown(request) {
        return axiosGetAuthorize(GetAllMemberDropdown, request);
    }
    async getAllChapterDropdown(request) {
        return axiosGetAuthorize(GetAllChapterDropdown, request);
    }
    async getAllAgencyOfficerList(request) {
        return axiosGetAuthorize(GetAllAgencyOfficerList, request);
    }
    async getAllSecretariatList(request) {
        return axiosGetAuthorize(GetAllSecretariatList, request);
    }
    //All add edit methods
    async addEditAgencyOfficer(request) {
        return axiosPostAuthorize(AddEditAgencyOfficer, request);
    }
    async getAllStrategicMemberList(request) {
        return axiosGetAuthorize(GetAllStrategicMemberList, request);
    }
    async getAllCompanyForStrategicMember(request) {
        return axiosGetAuthorize(GetAllCompanyForStrategicMember, request);
    }
    async getCompanyOfficerByCompanyForStrategicMember(request) {
        return axiosGetMultiParams(GetCompanyOfficerByCompanyForStrategicMember, request);
    }
    async addStrategicMember(request) {
        return axiosPostAuthorize(AddStrategicMember, request);
    }
    async getAgencyOfficerById(request) {
        return axiosGetAuthorize(GetAgencyOfficerById, request);
    }
    async addEditAgencyPartner(request) {
        return axiosPostAuthorize(AddEditAgencyPartner, request);
    }
    async getGovernmentAgencyById(request) {
        return axiosGetAuthorize(GetGovernmentAgencyById, request);
    }
    async getAllGovernmentAgencyList(request) {
        return axiosGetAuthorize(GetAllGovernmentAgencyList, request);
    }
    async addEditProject(request) {
        return axiosPostAuthorize(AddEditProject, request);
    }
    async getGovernmentProjectById(request) {
        return axiosGetAuthorize(GetGovernmentProjectById, request);
    }
    async addAgencyOfficerToAddEditProject(request) {
        return axiosPostAuthorize(AddAgencyOfficerToAddEditProject, request);
    }
    async getAgencyOfficerListForAddEditProject(request) {
        return axiosGetAuthorize(GetAgencyOfficerListForAddEditProject, request);
    }
    async getStrategicMemberListForAddEditProject(request) {
        return axiosGetAuthorize(GetStrategicMemberListForAddEditProject, request);
    }
    async updateAgencyOfficerKeyStatusForAddeEditProject(request) {
        return axiosPostAuthorize(UpdateAgencyOfficerKeyStatusForAddeEditProject, request);
    }
    async deleteAgencyOfficerFromAddEditProject(request) {
        return axiosPostAuthorize(DeleteAgencyOfficerFromAddEditProject, request);
    }
    async deleteStrategicMemberFromAddEditProject(request) {
        return axiosPostAuthorize(DeleteStrategicMemberFromAddEditProject, request);
    }

}