import React, { useEffect, useState } from 'react'
import ModalBasic from '../../../pages/component/ModalBasic'
import ValidationText from '../../../utils/validation/ValidationText'
import DropdownSelect from '../../dropdown/Dropdown'
import TextInput from '../../UIComponent/TextInput'
import ButtonLoader from '../../common/buttonLoader/ButtonLoader'
import Selector from '../../dropdown/commonDropdown/Selector'

function AddEditNewProfile(props) {
  const [roles, setRoles] = useState([...props.contactRoles]);
  const [showDeleteButton, setShowDeleteButton] = useState(false);

  function shouldDisableButton(organisationRole) {
    if (organisationRole === 1 || organisationRole === 2 || organisationRole === 3) {
      return true;
    }
    return false;
  }

  // useEffect(() => {
  //   const contactRoleIdsArray = Array.isArray(props.organisationDetails.contactRoleId)
  //     ? props.organisationDetails.contactRoleId
  //     : [props.organisationDetails.contactRoleId];
  //   const excludedIds = [1, 2, 3];
  //   const filteredRoles = props.contactRoles.filter(role =>
  //     !excludedIds.includes(role.id) || !contactRoleIdsArray.includes(role.id)
  //   );
  //   setRoles(filteredRoles);
  // }, [props.isCreateNewOrgRole === true && props.isOrganisationModalEditable !== true]);

  useEffect(() => {
    if (props.isCreateNewOrgRole === true) {
      const contactRoleIdsArray = [];

      props.Organisationrole.forEach(orgDetail => {
        if (orgDetail.role) {
          if (Array.isArray(orgDetail.role)) {
            contactRoleIdsArray.push(...orgDetail.role);
          } else {
            contactRoleIdsArray.push(orgDetail.role);
          }
        }
      });

      const excludedIds = new Set(['CEO/Director', 'First Representative', 'Second Representative']);
      const filteredRoles = props.contactRoles.filter(role =>
        !excludedIds.has(role.name) || !contactRoleIdsArray.includes(role.name)
      );

      if (props.isOrganisationModalEditable !== true) {
        setRoles(props.contactRoles);
      } else {
        setRoles(filteredRoles);
      }
    } else {
      setRoles(props.contactRoles);
    }
  }, [props]);


  return (
    <ModalBasic
      id="show-individual-profile-modal"
      modalOpen={props.addEditNewProfile}
      setModalOpen={props.setOpenModal}
    >
      <div className="bg-white mb-10">
        <div className="">
          <div className="grid grid-cols-12 gap-6 w-full 2xl:px-10 lg:px-8 pr-0">
            <div className="xl:col-span-12 lg:col-span-12 col-span-12">
              <h1 className="text-4xl text-[#c00000] font-bold leading-loose py-4">
                {props.isCreateNewOrgRole === true ? "Add Individual Profile" : "Update Individual Profile"}
              </h1>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full 2xl:px-10 lg:px-8 pr-0 mb-8">
            {
              props.isCreateNewOrgRole === true ?
                <div className="col-span-12">
                  <div className='grid grid-cols-12 gap-6'>
                    <div className='xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts'>
                      <h2 className="text-xl mb-1">Email Address<span className='text-[#C00000]'>*</span></h2>
                      <TextInput
                        placeholder=""
                        type="text"
                        name="searchByEmail"
                        value={props.organisationDetails.searchByEmail}
                        handleChange={props.handleChange}
                        onBlur={(e) => props.validate("searchByEmail", props.organisationDetails)}
                        identity="Organisation"
                      />
                      <ValidationText error={props.validState.error.searchByEmail} />
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                      <div className='flex justify-end'>
                        <div>
                          <h2 className="text-xl mb-1">&nbsp;</h2>
                          <button className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white font-bold"
                            onClick={() => props.searchIndividualMemberByEmailForOrgRole("Organisarion Key Roles")}
                          >Search</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                :
                null
            }
          </div>
          <div className="grid grid-cols-12 gap-6 w-full 2xl:px-10 lg:px-8 pr-0">
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <h2 className="2xl:text-xl lg:text-md text-[#757575] font-bold 2xl:mb-1">
                First Name <span className="text-[#c00000]">*</span>
              </h2>
              <div className="flex items-center">
                <div className="w-[130px] individual-f-name">
                  <DropdownSelect
                    drpIdentity={"Org_MrMrs"}
                    optionArray={props.mrMrs}
                    setFilterParameters={props.setFilterParameters}
                    value={props.organisationDetails.mrMrs}
                    disabled={props.isCreateNewOrgRole === true && props.isOrganisationModalEditable !== true}
                  />
                </div>
                <div className="w-full">
                  <TextInput
                    placeholder=""
                    type="text"
                    name="firstName"
                    value={props.organisationDetails.firstName}
                    identity="Organisation"
                    handleChange={props.handleChange}
                    onBlur={(e) => props.validate("firstName", props.organisationDetails)}
                    disabled={props.isCreateNewOrgRole === true && props.isOrganisationModalEditable !== true}
                    isCurserAllowed={props.isCreateNewOrgRole === true && props.isOrganisationModalEditable !== true}
                  />
                </div>
              </div>
              <ValidationText error={props.validState.error.mrMrs} />
              <ValidationText error={props.validState.error.firstName} />
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <h2 className="2xl:text-xl lg:text-md text-[#757575] font-bold 2xl:mb-1">
                Last Name <span className="text-[#c00000]">*</span>
              </h2>
              <TextInput
                placeholder=""
                type="text"
                name="lastName"
                value={props.organisationDetails.lastName}
                identity="Organisation"
                handleChange={props.handleChange}
                onBlur={(e) => props.validate("lastName", props.organisationDetails)}
                disabled={props.isCreateNewOrgRole === true && props.isOrganisationModalEditable !== true}
                isCurserAllowed={props.isCreateNewOrgRole === true && props.isOrganisationModalEditable !== true}
              />
              <ValidationText error={props.validState.error.lastName} />
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <h2 className="2xl:text-xl lg:text-md text-[#757575] font-bold 2xl:mb-1">
                Business Email{" "}
                <span className="text-[#c00000]">*</span>
              </h2>
              <div className="flex items-center">
                <div className="w-full right-border-none">
                  <TextInput
                    placeholder=""
                    type="text"
                    name="emailText"
                    value={props.organisationDetails.emailText}
                    identity="Organisation"
                    handleChange={props.handleChange}
                    onBlur={(e) => props.validate("emailText", props.organisationDetails)}
                    autoComplete={"off"}
                    disabled={true}
                    isCurserAllowed={true}
                  />
                </div>
              </div>
              <ValidationText error={props.validState.error.emailText} />
              <ValidationText error={props.validState.error.emailString} />
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <h2 className="2xl:text-xl lg:text-md text-[#757575] font-bold 2xl:mb-1">
                Business Mobile
              </h2>
              <TextInput
                placeholder=""
                type="number"
                name="bussinessMobile"
                value={props.organisationDetails.bussinessMobile}
                identity="Organisation"
                handleChange={props.handleChange}
                disabled={props.isCreateNewOrgRole === true && props.isOrganisationModalEditable !== true}
                isCurserAllowed={props.isCreateNewOrgRole === true && props.isOrganisationModalEditable !== true}
              />
              <ValidationText error={props.validState.error.contactNo} />
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <h2 className="2xl:text-xl lg:text-md text-[#757575] font-bold 2xl:mb-1">
                Job Function
                <span className="text-[#c00000]">*</span>
              </h2>
              <div className="dropdwn-input-email flex items-center">
              </div>
              <TextInput
                placeholder=""
                type="text"
                name="designation"
                value={props.organisationDetails.designation}
                identity="Organisation"
                handleChange={props.handleChange}
                disabled={props.isCreateNewOrgRole === true && props.isOrganisationModalEditable !== true}
                isCurserAllowed={props.isCreateNewOrgRole === true && props.isOrganisationModalEditable !== true}
              />
              <ValidationText error={props.validState.error.designation} />
            </div>

            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                Office DID <span className="text-[#c00000]">*</span>
              </h2>
              <TextInput
                placeholder=""
                type="text"
                name="officeDID"
                value={props.organisationDetails.officeDID}
                identity="Organisation"
                handleChange={props.handleChange}
                onBlur={(e) => props.validate("officeDID", props.organisationDetails)}
                disabled={props.isCreateNewOrgRole === true && props.isOrganisationModalEditable !== true}
                isCurserAllowed={props.isCreateNewOrgRole === true && props.isOrganisationModalEditable !== true}
              />
              <ValidationText error={props.validState.error.officeDID} />

            </div>

            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                Subscribe to Email Circulars <span className="text-[#c00000]">*</span>
              </h2>
              <Selector
                drpIdentity="EmailSubscriptionOrg"
                options={props.emailSubscription}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={props.handleChangeMultiDropdown}
                allowSelectAll={true}
                value={props.organisationDetails.emailSubscription}
                disabled={props.isCreateNewOrgRole === true && props.isOrganisationModalEditable !== true}
                isCurserAllowed={props.isCreateNewOrgRole === true && props.isOrganisationModalEditable !== true}
              />
              <ValidationText error={props.validState.error.emailSubscription} />
            </div>

            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <h2 className="2xl:text-xl lg:text-md text-[#757575] font-bold 2xl:mb-1">
                Organisation Role
                <span className="text-[#c00000]">*</span>
              </h2>
              <DropdownSelect
                drpIdentity={"Org_OrganisationRoles"}
                // optionArray={props.contactRoles.filter(option => option.id !== 1 && option.id !== 2 && option.id !== 3)}
                // optionArray={props.isCreateNewOrgRole === true  ? props.contactRoles  : roles}
                optionArray={roles}
                setFilterParameters={props.setFilterParameters}
                value={props.isCreateNewOrgRole === true && props.isOrganisationModalEditable !== true ? props.organisationDetails.contactRoleId : props.individualOrganisationRole}
                disabled={([1, 2, 3].includes(props.individualOrganisationRole) && props.isOrganisationModalEditable === true)}
              // disabled={true}
              />
              <ValidationText error={props.validState.error.contactRoleId} />
            </div>

            {props.individualOrganisationRole === 7 || props.organisationDetails.contactRoleId === 7 ?
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Birth Year
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <DropdownSelect
                  drpIdentity={"Years"}
                  optionArray={props.years}
                  setFilterParameters={props.setFilterParameters}
                  value={props.organisationDetails.yearsId}
                //disabled={props.isCreateNewOrgRole === true && props.isOrganisationModalEditable !== true}
                />
                {/* <ValidationText error={props.validState.error.yearsId} /> */}
              </div>
              : null
            }

            {props.individualOrganisationRole === 1 || props.organisationDetails.contactRoleId === 1 ?
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Experience Years
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="experienceYears"
                  value={props.organisationDetails.experienceYears}
                  identity="Organisation"
                  handleChange={props.handleChange}
                  //onBlur={(e) => props.validate("experienceYears", props.organisationDetails)}
                  //disabled={props.isCreateNewOrgRole === true && props.isOrganisationModalEditable !== true}
                  //isCurserAllowed={props.isCreateNewOrgRole === true && props.isOrganisationModalEditable !== true}
                />
                {/* <ValidationText error={props.validState.error.yearsId} /> */}
              </div>
              : null
            }
          </div>
        </div>
        <div className="grid grid-cols-12 gap-6 w-full pt-16 px-10">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="flex items-center justify-between">
              <div className="">
                {
                  // (((props.isCreateNewOrgRole === true && (([1, 2, 3, 0].includes(props.organisationDetails.contactRoleId))))) ||
                  //   ((props.isCreateNewOrgRole === false && props.isOrganisationModalEditable === false && (([1, 2, 3, 0, null].includes(props.individualOrganisationRole)))))
                  // ) ?
                  //   null : 
                  <button
                    className="btn btn-gray text-lg text-white font-bold"
                    onClick={() => { props.deleteOrganisationRole(props.organisationDetails.memberId, props.organisationDetails.contactInfoId, props.individualOrganisationRole) }}
                  >
                    Delete Profile
                  </button>
                }

              </div>

              {
                props.isAddEditAOrganisation && props.isAddEditAOrganisation === true ?
                  (
                    <div className="savebtn">
                      <ButtonLoader />
                    </div>
                  )
                  :
                  (
                    <div className="savebtn">
                      <button
                        className="btn btn-red text-xl text-white font-semibold px-10"
                        onClick={() => props.addEditOraganisation("Save")}
                      // disabled={shouldDisableButton(props.organisationDetails.contactRoleId)}
                      // style={{
                      //   backgroundColor: shouldDisableButton(props.organisationDetails.contactRoleId) ? 'gray' : '',
                      //   cursor: shouldDisableButton(props.organisationDetails.contactRoleId) ? 'not-allowed' : 'pointer'
                      // }}
                      >
                        Save
                      </button>
                    </div>
                  )

              }
            </div>
          </div>
        </div>
      </div>
    </ModalBasic>
  )
}
export default AddEditNewProfile