import React from "react";

function ProspectsCorporateMember(props) {
  return (
    <div>
      <div className="grid gap-8 py-4 grid-cols-2">
        <div className="relative justify-center prospects p-4 pl-24 rounded-lg">
          <div className="flex flex-row items-center justify-between title">
            <div className="icon">Prospects</div>
            <div className="number">{props.dashboardDetails.totalProspects}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div>Not Started</div>
            <div className="underline">{props.dashboardDetails.notStarted}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div>In Progress</div>
            <div className="underline">{props.dashboardDetails.inProgress}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div>Closed - Successful</div>
            <div className="underline">{props.dashboardDetails.closedSuccessful}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div>Closed - To Revisit</div>
            <div className="underline">{props.dashboardDetails.closedToRevisit}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div>Closed - Lost</div>
            <div className="underline">{props.dashboardDetails.closedLost}</div>
          </div>
        </div>
        <div className="relative justify-center corporateMember p-4 pl-24 rounded-lg">
          <div className="flex flex-row items-center justify-between title">
            <div className="icon">Corporate Member</div>
            <div className="number">{props.dashboardDetails.totalCorporateMember}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="font-bold">Active</div>
            <div className="underline font-bold">{props.dashboardDetails.totalActiveCorporateMembers}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="ml-6">Expiry within 60 days</div>
            <div className="underline">{props.dashboardDetails.expiryWithin60}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="ml-6">Expiry within 45 days</div>
            <div className="underline">{props.dashboardDetails.expiryWithin45}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="ml-6">Expiry within 30 days</div>
            <div className="underline">{props.dashboardDetails.expiryWithin30}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="ml-6">Ongoing</div>
            <div className="underline">{props.dashboardDetails.totalActiveCorporateMembers - (props.dashboardDetails.expiryWithin30 +
              props.dashboardDetails.expiryWithin45 +
              props.dashboardDetails.expiryWithin60)}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="font-bold">Inactive</div>
            <div className="underline font-bold">{props.dashboardDetails.totalInactiveCorporateMembers}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="ml-6">Membership Task</div>
            <div className="underline">{props.dashboardDetails.totalInactiveCorporateMembersinTaskList}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="ml-6">Not in Membership Task</div>
            <div className="underline">{props.dashboardDetails.totalInactiveCorporateMembers - props.dashboardDetails.totalInactiveCorporateMembersinTaskList}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProspectsCorporateMember;
