import React, { useState } from 'react'
import TextInput from '../UIComponent/TextInput';
import { DeleteButtonIcon } from '../SVGConstants';
import ValidationText from '../../utils/validation/ValidationText';

function EditFieldTable(props) {
    const [, setAddEditView] = useState(false);
    const [agencyOfficerId, setAgencyOfficerId] = useState(0);
    return (
        <div className='w-full overflow-x-auto'>
            <table className="w-full bg-white shadow-lg rounded-sm border border-slate-200 ">
                {/* Table header */}
                <thead className="table-bg text-xs font-semibold capitalize text-[#757575] border-t border-b border-slate-200 ">
                    <tr>
                        <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white border border-[#757575]">
                            <div className="font-bold tble-th-txt text-left">
                                Agent Name
                            </div>
                        </th>
                        <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white border border-[#757575]">
                            <div className="font-bold tble-th-txt text-left">
                                Designation
                            </div>
                        </th>
                        <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white border border-[#757575]">
                            <div className="font-bold tble-th-txt text-left">
                                Email Address
                            </div>
                        </th>
                        <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white border border-[#757575]">
                            <div className="font-bold tble-th-txt text-left">
                                Contact Information
                            </div>
                        </th>
                        <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white border border-[#757575]">
                            <div className="font-bold tble-th-txt text-left">
                                Updated Date
                            </div>
                        </th>
                        <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white border border-[#757575]">
                            <div className="font-bold tble-th-txt text-left">
                                Actions
                            </div>
                        </th>
                    </tr>
                </thead>
                {/* Table body */}
                <tbody className="text-sm custom-tbody">
                    {props.agencyOfficerDetails.map((officer, key) => (
                        <tr key={key} className={`${props && props.addEditOfficer === true ? null : null}`}>
                            <td className='px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] border border-[#757575]'>
                                <div className='flex flex-col'>
                                    {
                                        props && props.addEditOfficer === true && agencyOfficerId === officer.agencyOfficerId ?
                                            <div key={key} className='flex flex-col relative'>
                                                <div className={`edit-field ${props.validState.error.agentName ? 'border-red' : null}`}>
                                                    <TextInput
                                                        placeholder=""
                                                        type="text"
                                                        name="agentName"
                                                        maxLength={50}
                                                        value={props.addEditAgencyOfficerDetails.agentName}
                                                        handleChange={props.handleChange}
                                                        onBlur={() => props.validateField("agentName")}
                                                        identity="AgentName"
                                                    />
                                                </div>
                                                <div className='absolute bottom-[-22px]'>
                                                    <ValidationText
                                                        error={props.validState.error.agentName}
                                                    />
                                                </div>
                                            </div>
                                            :
                                            <div className='current-text'>
                                                {officer.agentName}
                                            </div>
                                    }
                                </div>
                            </td>
                            <td className='px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] border border-[#757575]'>
                                <div className='flex flex-col'>
                                    {
                                        props && props.addEditOfficer === true && agencyOfficerId === officer.agencyOfficerId ?
                                            <div className='flex flex-col relative'>
                                                <div className={`edit-field ${props.validState.error.designation ? 'border-red' : null} `}>
                                                    <TextInput
                                                        placeholder=""
                                                        type="text"
                                                        name="designation"
                                                        maxLength={50}
                                                        value={props.addEditAgencyOfficerDetails.designation}
                                                        handleChange={props.handleChange}
                                                        onBlur={() => props.validateField("designation")}
                                                        identity="Designation"
                                                    />
                                                </div>
                                                <div className='absolute bottom-[-22px]'>
                                                    <ValidationText
                                                        error={props.validState.error.designation}
                                                    />
                                                </div>
                                            </div>
                                            :
                                            <div className='current-text'>
                                                {officer.designation}
                                            </div>
                                    }
                                </div>
                            </td>
                            <td className='px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] border border-[#757575]'>
                                <div className='flex flex-col'>
                                    {
                                        props && props.addEditOfficer === true && agencyOfficerId === officer.agencyOfficerId ?
                                            <div className='flex flex-col relative'>
                                                <div className={`edit-field ${props.validState.error.emailAddress ? 'border-red' : null} `}>
                                                    <TextInput
                                                        placeholder=""
                                                        type="email"
                                                        name="emailAddress"
                                                        value={props.addEditAgencyOfficerDetails.emailAddress}
                                                        handleChange={props.handleChange}
                                                        onBlur={() => props.validateField("emailAddress")}
                                                        identity="EamilAddress"
                                                    />
                                                </div>
                                                <div className='absolute bottom-[-22px]'>
                                                    <ValidationText
                                                        error={props.validState.error.emailAddress}
                                                    />
                                                </div>
                                            </div>
                                            :
                                            <div className='current-text'>
                                                {officer.emailAddress}
                                            </div>
                                    }
                                </div>
                            </td>
                            <td className='px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] border border-[#757575]'>
                                <div className='flex flex-col'>
                                    {
                                        props && props.addEditOfficer === true && agencyOfficerId === officer.agencyOfficerId ?
                                            <div className='flex flex-col relative'>
                                                <div className={`edit-field ${props.validState.error.contactNo ? 'border-red' : null} `}>
                                                    <TextInput
                                                        placeholder=""
                                                        type="text"
                                                        name="contactNo"
                                                        maxLength={15}
                                                        value={props.addEditAgencyOfficerDetails.contactNo}
                                                        handleChange={props.handleChange}
                                                        // onBlur={() => props.validateField("contactNo")}
                                                        identity="ContactNumber"
                                                    />
                                                </div>
                                                {/* <div className='absolute bottom-[-22px]'>
                                                    <ValidationText
                                                        error={props.validState.error.contactNo}
                                                    />
                                                </div> */}
                                            </div>
                                            :
                                            <div className='current-text'>
                                                {officer.contactNo}
                                            </div>
                                    }
                                </div>
                            </td>
                            <td className='px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] border border-[#757575]'>
                                <div className='flex flex-col'>
                                    <div className='current-text'>
                                        {officer.updatedAt}
                                    </div>
                                </div>
                            </td>
                            <td className='px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] border border-[#757575]'>
                                {
                                    props && props.addEditOfficer === true && agencyOfficerId === officer.agencyOfficerId ?
                                        (
                                            <div className='flex items-center justify-center edit-field'>
                                                <button
                                                    className='action-btn mr-4 flex items-center' aria-controls="add-edit-modal"
                                                    onClick={() => { props.addEditAgencyOfficer(); setAddEditView(false) }}
                                                >
                                                    <p className='ml-2 tool-tip-txt text-[#c00000] underline cursor-pointer'>
                                                        Save
                                                    </p>
                                                </button>
                                                <button
                                                    className='action-btn mr-4 flex items-center' aria-controls="add-edit-modal"
                                                    onClick={() => { props.cancelAddEditOfficer() }}
                                                >
                                                    <img src='/cancel-icon.png' className='w-[22px] mx-auto my-0' alt=""></img>
                                                </button>
                                            </div>
                                        )
                                        :
                                        (
                                            <div id={officer.agencyOfficerId} className='flex items-center justify-center'>
                                                <button
                                                    className='action-btn mr-4 flex items-center' aria-controls="add-edit-modal"
                                                    onClick={() => {
                                                        props.getAgencyOfficerById(officer.agencyOfficerId);
                                                        setAgencyOfficerId(officer.agencyOfficerId)
                                                    }}
                                                >

                                                    <p className='ml-2 tool-tip-txt underline text-[#757575]'>
                                                        Edit
                                                    </p>
                                                </button>
                                                <button className='action-btn right-icon'
                                                    onClick={() => props.deleteAgencyOfficer(officer.agencyOfficerId)}
                                                >
                                                    <DeleteButtonIcon />
                                                </button>
                                            </div>
                                        )
                                }
                            </td>
                        </tr>
                    ))}
                    {props && props.addNewOfficer === true ?
                        (
                            <tr key="new-agency-key" >
                                <td className='px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] border border-[#757575]'>
                                    <div className='flex flex-col'>
                                        <div className={`edit-field ${props.validState.error.agentName ? 'border-2 border-red-500' : null}`}>
                                            <TextInput
                                                placeholder=""
                                                type="text"
                                                name="agentName"
                                                maxLength={50}
                                                value={props.addEditAgencyOfficerDetails.agentName}
                                                handleChange={props.handleChange}
                                                onBlur={props.validateField}
                                                identity="agentName"
                                            />
                                        </div>
                                        <div>
                                            <ValidationText
                                                error={props.validState.error.agentName}
                                            />
                                        </div>
                                    </div>
                                </td>
                                <td className='px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] border border-[#757575]'>
                                    <div className='flex flex-col'>
                                        <div className={`edit-field ${props.validState.error.designation ? 'border-2 border-red-500' : null}`}>
                                            <TextInput
                                                placeholder=""
                                                type="text"
                                                name="designation"
                                                maxLength={50}
                                                value={props.addEditAgencyOfficerDetails.designation}
                                                handleChange={props.handleChange}
                                                onBlur={() => props.validateField("designation")}
                                                identity="Designation"
                                            />
                                        </div>
                                        <div>
                                            <ValidationText
                                                error={props.validState.error.designation}
                                            />
                                        </div>
                                    </div>
                                </td>
                                <td className='px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] border border-[#757575]'>
                                    <div className='flex flex-col'>
                                        <div className={`edit-field ${props.validState.error.emailAddress ? 'border-2 border-red-500' : null}`}>
                                            <TextInput
                                                placeholder=""
                                                type="email"
                                                name="emailAddress"
                                                value={props.addEditAgencyOfficerDetails.emailAddress}
                                                handleChange={props.handleChange}
                                                onBlur={() => props.validateField("emailAddress")}
                                                identity="EamilAddress"
                                            />
                                        </div>
                                        <div>
                                            <ValidationText
                                                error={props.validState.error.emailAddress}
                                            />
                                        </div>
                                    </div>
                                </td>
                                <td className='px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] border border-[#757575]'>
                                    <div className='flex flex-col'>                                        
                                            <TextInput
                                                placeholder=""
                                                type="text"
                                                name="contactNo"
                                                maxLength={15}
                                                value={props.addEditAgencyOfficerDetails.contactNo}
                                                handleChange={props.handleChange}
                                                // onBlur={() => props.validateField("contactNo")}
                                                identity="ContactNumber"
                                            />
                                        {/* <div>
                                            <ValidationText
                                                error={props.validState.error.contactNo}
                                            />
                                        </div> */}
                                    </div>
                                </td>
                                <td className='px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] border border-[#757575]'>
                                    <div className='flex flex-col'>
                                    </div>
                                </td>
                                <td className='px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] border border-[#757575]'>

                                    <div className='flex items-center justify-center edit-field'>
                                        <button
                                            className='action-btn mr-4 flex items-center' aria-controls="add-edit-modal"
                                            onClick={() => { props.addEditAgencyOfficer(); setAddEditView(false) }}
                                        >
                                            <p className='ml-2 tool-tip-txt text-[#c00000] underline cursor-pointer'>
                                                Save
                                            </p>
                                        </button>
                                        <button
                                            className='action-btn mr-4 flex items-center' aria-controls="add-edit-modal"
                                            onClick={() => { props.cancelAddEditOfficer() }}
                                        >
                                            <img src='/cancel-icon.png' className='w-[22px] mx-auto my-0' alt=""></img>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ) :
                        (
                            null
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}
export default EditFieldTable