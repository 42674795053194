import React from "react";
import DropdownSelect from "../../components/dropdown/Dropdown";
import MultiSelectDropdown from "../../components/dropdown/MultiSelectDropdown";
import moment from "moment";
import { components } from "react-select";
import ResourceImageDescription from "../../components/resourceImageDescription/ResourceImageDescription";
import ImageCropperWithUpload from "../../components/ImageUpload/UploadImage";
import { NavLink } from "react-router-dom";
import ValidationText from "../../utils/validation/ValidationText";
import TimePicker from "../../components/TimePicker.js/TimePicker";
import { APIURL } from "../../services/axiosServices/ApiEndPoints";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};


const CreateResources = (props) => {
  return (
    <div className="main-body-section">
      <div className="bg-white">
        <div className="heading-part pl-7 py-4 border-b">
          <div className="flex items-center justify-between">
            <h4 className="small-title font-bold theme-color">
              Basic Information
            </h4>
            <div className="right-top-btn">
              {props.resource.isPage ? (
                <button
                  className="w-32 px-7 mr-12 py-2 btn-sm btn-lighblue text-lg text-white"
                  onClick={() => props.onPreviewOpen()}
                >
                  Preview
                </button>
              ) : null}
            </div>
          </div>
        </div>
        <div className="px-5 py-4 pt-10">
          <div className="mb-16">
            <div className="grid grid-cols-12 pr-8 pl-4 gap-6">
              <div className="2xl:col-span-8 lg:col-span-8 col-span-12">
                <div className="grid grid-cols-12 gap-6">
                  <div className="2col-span-12 lg:col-span-12 col-span-12">
                    <div className="grid grid-cols-12 gap-6 mb-10">
                      <div className="filter-types 2xl:col-span-7 lg:col-span-9 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="default"
                        >
                          Resource Name{" "}
                          <span className="text-[#C00000]">*</span>
                        </label>
                        <input
                          id="default"
                          className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                          type="text"
                          name="resourceName"
                          value={props.resource.resourceName}
                          onChange={(event) => props.handleChange(event)}
                          onBlur={() => props.validateField("resourceName")}
                        />
                        <div>
                          {" "}
                          <ValidationText
                            error={props.validState.error.resourceName}
                          />{" "}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6  mb-10">
                      <div className="filter-types 2xl:col-span-7 lg:col-span-9 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="default"
                        >
                          {" "}
                          Resource Type{" "}
                          <span className="text-[#C00000]">*</span>{" "}
                        </label>
                        <DropdownSelect
                          drpIdentity={"Types"}
                          optionArray={props.resourceTypes}
                          setFilterParameters={props.setFilterParameters}
                          value={props.resource.resourceTypeId}
                        />
                        <div>
                          <ValidationText
                            error={props.validState.error.resourceTypeId}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6 mb-10">
                      <div className="filter-types 2xl:col-span-7 lg:col-span-9 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="default"
                        >
                          Topics{" "}
                          <span className="text-[#C00000]">*</span>
                        </label>
                        <MultiSelectDropdown
                          options={props.resourceTopics}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{
                            Option,
                          }}
                          onChange={props.handleChangeInDropdown}
                          allowSelectAll={true}
                          value={props.optionSelected}
                        />
                        <div>
                          <ValidationText
                            error={props.validState.error.optionSelected}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                <ImageCropperWithUpload
                  key="Main"
                  height={460}
                  width={814}
                  isCheckValidation={true}
                  MaxFileSize={2097152}
                  MaxFileSizeMessage={"2MB"}
                  onSaveImage={props.saveMainImage}
                  imageIdentity="Resource"
                  intitImage={props.resource.resourceImage}
                  aspactRatio={814 / 460}
                  uploadBtn={"Upload Feature Image"}
                  dimensionText={
                    "(Dimension: 814px by 460px Maximum Size: 2MB)"
                  }
                ></ImageCropperWithUpload>
              </div>
            </div>

            <div className="grid grid-cols-12 mb-8 pl-4 gap-6">
              <div className="2xl:col-span-11 lg:col-span-12 col-span-12 ">
                <label
                  className="block text-xl font-medium mb-6 theme-color min-h-30"
                  htmlFor="default"
                >
                  {" "}
                  Format<span className="text-[#C00000]">*</span>
                </label>
                <div className="grid grid-cols-12 gap-6 items-center w-full">
                  <div className=" col-span-12 w-full">
                    <div className="m-3 mt-7">
                      <label className="flex items-center">
                        <input
                          type="radio"
                          name="radio-buttons"
                          id="isPage"
                          className="form-radio"
                          value={props.resource.isPage}
                          onChange={(event) => props.handleRadioButtons(event)}
                          checked={props.resource.isPage === true}
                        />
                        <span className="text-xl  font-medium theme-color ml-2">
                          Page
                        </span>
                      </label>
                    </div>

                    <div className="m-3">
                      <div className="flex items-center">
                        <label className="flex items-center mr-10">
                          <input
                            type="radio"
                            name="radio-buttons"
                            id="isVideo"
                            className="form-radio"
                            value={props.resource.isVideo}
                            onChange={(event) =>
                              props.handleRadioButtons(event)
                            }
                            checked={props.resource.isVideo === true}
                          />
                          <span className="text-xl  font-medium theme-color ml-2">
                            Video
                          </span>
                        </label>
                        <div className="video-tooltip-sec">
                          <img className="w-[25px]" src="/tooltip.png" alt=""
                          ></img>
                          <div className="tool-tip ml-5">
                            <div className="tool-tip-inner">
                              <div className="popover-heading font-bold">
                                Instruction
                              </div>
                              <div className="popover-body">
                                <p>
                                  <strong>1.</strong> On a computer, go to the YouTube video you
                                  want to embed.
                                </p>
                                <p>
                                  <strong>2.</strong> Copy Browser Link and Paste.
                                  (e.g.https://www.youtube.com/watch?v=Ke90Tje7VS0)
                                </p>
                                <p>
                                  <strong>3.</strong> Link will be Automatically convert into
                                  embed url
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {props.resource.isVideo ? (
                      <>
                        <div className="ml-3">
                          <div className="pl-6 flex items-center url-part">
                            <span className="text-xl  font-medium theme-color mr-5">
                              URL
                            </span>
                            <div className="flex item-center flex-col min-w-[35%]">
                              <input
                                id="default"
                                name="videoUrl"
                                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                                type="text"
                                placeholder=""
                                value={props.resource.videoUrl}
                                onChange={(event) => props.handleChange(event)}
                              // onBlur={() => props.validateField("videoUrl")}
                              />
                              <div>
                                {!props.resource.videoValidation && !props.resource.videoUrl ? (
                                  <ValidationText
                                    error={
                                      "Please enter Video URL with proper format."
                                    }
                                  />
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}

                    <div className="m-3">
                      <label className="flex items-center">
                        <input
                          type="radio"
                          name="radio-buttons"
                          id="isReport"
                          className="form-radio"
                          value={props.resource.isReport}
                          onChange={(event) => props.handleRadioButtons(event)}
                          checked={props.resource.isReport === true}
                        />
                        <span className="text-xl  font-medium theme-color ml-2">
                          Report
                        </span>
                      </label>
                    </div>

                    {props.resource.isReport === true ? (
                      <div className="ml-3">
                        <div className="pl-6 flex items-center url-part">
                          <span className="text-xl  font-medium theme-color mr-5">
                            PDF Upload
                          </span>
                          <div className="flex item-center flex-col">
                            <form className="custom-uploader border flex items-center border-[#757575] text-center px-14 py-3">
                              <label
                                htmlFor="upload"
                                className="text-uplod block text-sm text-[#757575]"
                              >
                                Upload PDF
                              </label>
                              {/* <svg
                                className="uplod-svg inline-flex w-4 h-4 fill-[#757575] ml-4"
                                viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M8 4c-.3 0-.5.1-.7.3L1.6 10 3 11.4l4-4V16h2V7.4l4 4 1.4-1.4-5.7-5.7C8.5 4.1 8.3 4 8 4ZM1 2h14V0H1v2Z" />
                              </svg> */}
                              <img
                                className="uplod-svg inline-flex w-5 h-5 fill-[#757575] ml-4"
                                src="/pdf-upload.svg"
                                alt=""
                              ></img>
                              <input
                                className="sr-only"
                                id="upload"
                                type="file"
                                // onBlur={() => props.validateField("report")}
                                onChange={(event) => props.onFileChange(event)}
                              />
                            </form>
                            {props.resource.pdfDisplayName !== "" ? (
                             
                              
                              <div>
                                <div className="relative mt-5">
                                  {/* <a className="text-[#313984] big-bullet-points font-bold mb-5 inline-block mobile-14"
                                    onClick={() => window.open(APIURL + "Common/GetPDF?type=Resource&&fileName=" + props.resource.report, '_blank')}
                                    >
                                    {props.resource.report}
                                    
                                  </a> */}
                                  {props.resource.report}
                                  <span
                                    className="absolute top-[-5px] right-[-18px] bg-red-700 h-4 w-4 rounded-full flex items-center justify-center cursor-pointer"
                                    onClick={() => props.onFileCancel()}
                                    >
                                    <p className="text-white text-[10px]">
                                      &#10006;
                                    </p>
                                  </span>
                                </div>
                              </div>
                            ) : null}
                            <div>
                              {!props.resource.reportValidation ? (
                                <ValidationText error={"Please upload PDF"} />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            {props.resource.isPage || props.resource.isVideo ? (
              <div className="grid grid-cols-12 gap-6 mt-10 mb-10 border border-[#757575] p-4 pr-7">
                <div className="filter-types col-span-12">
                  <ResourceImageDescription
                    pageDescription={props.pageDescription}
                    setImageDescription={props.setImageDescription}
                    removeSection={props.removeSection}
                    onSaveImage={props.saveMainImage}
                  />

                  <button
                    className=" px-10 mt-3 my-3 py-3 btn-red text-xl border text-white"
                    onClick={() => props.addNewDec()}
                  >
                    <div className="flex items-center font-semibold">
                      <span className="mr-3">
                        <img src="../plus-round-icon.svg" alt=""></img>
                      </span>
                      <span className="">Add Description</span>
                    </div>
                  </button>
                </div>
              </div>
            ) : null}

            <div className="grid grid-cols-12 gap-6 date-time-sec-resources">
              <div className="2xl:col-span-6 lg:col-span-8 col-span-12">
                <div className="grid grid-cols-12 mb-8 pl-4 gap-6">
                  <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12">
                    <label
                      className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                      htmlFor="default"
                    >
                      Publish Date<span className="text-[#C00000]">*</span>
                    </label>
                    <div className="grid grid-cols-12 gap-6 items-center w-full">
                      <div className=" 2xl:col-span-10 lg:col-span-10 col-span-12 w-full">
                        <input
                          id="default"
                          name="publishDate"
                          className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                          type="date"
                          value={moment(props.resource.publishDate).format(
                            "yyyy-MM-DD"
                          )}
                          onChange={(event) => props.handleChange(event)}
                          onBlur={() => props.validateField("publishDate")}
                        />
                        <div>

                          <ValidationText
                            error={props.validState.error.publishDate}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                    <label
                      className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                      htmlFor="default"
                    >
                      Publish Time <span className="text-[#C00000]">*</span>
                    </label>
                    <div className="grid grid-cols-12 gap-6 items-center w-full">
                      <div className="2xl:col-span-10 lg:col-span-10 col-span-12 w-full">
                        {/* <CommonTimepicker 
                        identity = "PublishTime"
                        setTime={props.setTime}
                        value={props.publishTime}
                        /> */}
                        <TimePicker
                          identity="PublishTime"
                          setTime={props.setTime}
                          value={props.resource.publishTime}
                        />
                      </div>
                      {/* <ValidationText
                        error={props.validState.error.unpublishDate}/> */}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-12 pl-4 gap-6">
                  <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                    <label
                      className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                      htmlFor="default"
                    >
                      Unpublish Date<span className="text-[#C00000]">*</span>
                    </label>
                    <div className="grid grid-cols-12 gap-6 items-center w-full">
                      <div className="2xl:col-span-10 lg:col-span-10 col-span-12 w-full">
                        <input
                          id="unpublishDate"
                          name="unpublishDate"
                          className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                          type="date"
                          value={moment(props.resource.unpublishDate).format(
                            "yyyy-MM-DD"
                          )}
                          onChange={(event) => props.handleChange(event)}
                          onBlur={() => props.validateField("unpublishDate")}
                          min={props.resource.publishDate}
                        />
                        <div>
                          {/* {props.resource.publishDate ? 
                          <ValidationText
                          error={props.validState.error.unpublishDate}
                        /> : 
                          <ValidationText
                           error={"Can't select UnPublish Date before Publish Date"} 
                          />} */}
                          <ValidationText
                            error={props.validState.error.unpublishDate}
                          />
                          <ValidationText
                            error={props.unpublishDateValidationMsg}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                    <label
                      className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                      htmlFor="default"
                    >
                      Unpublish Time <span className="text-[#C00000]">*</span>
                    </label>
                    <div className="grid grid-cols-12 gap-6 items-center w-full">
                      <div className="2xl:col-span-10 lg:col-span-10 col-span-12 w-full">
                        {/* <CommonTimepicker 
                        identity = "UnpublishTime"
                        setTime={props.setTime}
                        value={props.unpublishTime}/> */}
                        <TimePicker
                          identity="UnpublishTime"
                          setTime={props.setTime}
                          value={props.resource.unpublishTime}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal footer Button*/}
      <div className="flex flex-wrap justify-between space-x-10 mt-12">
        <div>
          <NavLink
            to="/ViewResource"
            className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white hover:text-white"
          >
            {" "}
            Back
          </NavLink>
          {/* <button className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white">Back</button> */}
        </div>
        <div>
          {props.resource.isPage ? (
            <button
              className="w-32 px-7 mr-12 py-2 btn-sm btn-lighblue text-lg text-white"
              onClick={() => props.onPreviewOpen()}
            >
              Preview
            </button>
          ) : null}
          {/* {props.isLoaderEnabled ? (
            <ButtonLoader />
          ) : (
            <button
              className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white"
              onClick={() => props.addEditResources()}
            >
              Save
            </button>
          )} */}
          <button
            className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white"
            onClick={() => props.addEditResources()}
            disabled={props.isLoaderEnabled}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateResources;
