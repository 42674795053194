import React, { Component } from 'react'
import Table from '../../../components/table/Table'
import CommonServices from '../../../services/axiosServices/apiServices/CommonServices';
import MembershipCampaignServices from '../../../services/axiosServices/apiServices/MembershipCampaignServices';
import SwalServices from '../../../services/swalServices/SwalServices';
import moment from 'moment';
import ActionToggleButton from '../../../components/UIComponent/ActionTableComponent/ActionToggleButton';
import CreateButton from '../../../components/UIComponent/Buttons/CreateButton';
import ActionContainer from '../../../components/UIComponent/ActionTableComponent/actionContainer'
import { Navigate } from 'react-router-dom';
import ActionEditButton from '../../../components/UIComponent/ActionTableComponent/ActionEditButton';
import ActionDeleteButton from '../../../components/UIComponent/ActionTableComponent/ActionDeleteButton';
import { CommonSuccessMessages } from '../../../utils/Messages';
import { encryptAES } from '../../../utils/Encryption';
/**
 * TODO : 
 *  Move Action related code to the Action component
 */
export default class MembershipCampaign extends Component {

  constructor(props) {
    super(props)
    this.membershipCampaignServices = new MembershipCampaignServices();
    this.CommonServices = new CommonServices();
    this.swalServices = new SwalServices();
    this.state = {
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        status: 0,
        filterByType: 0,
        filterByStatus: 0
      },
      totalResultes: "",
      getMembershipCampaign: [],
      isLoading: false,
      redirect: null,
      toggle: false,

    }

  }

  ActiveInActiveCampaign = (request) => {
    this.membershipCampaignServices.activeInActiveMembershipCampaign(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let newGetmembershipCampaign = this.state.getMembershipCampaign;
        newGetmembershipCampaign.statusId = response.responseItem.responseContent.statusId;
        this.setState({ getMembershipCampaign: { ...newGetmembershipCampaign } });
        this.GetMembershipCampaignList();
        // window.location.reload(false);
      }
      else {
        this.swalServices.Error(response.message);

      }
    })

  }
  onToggleChage = (index, event) => {
    let membershipCampaignData = this.state.getMembershipCampaign;
    let request = this.state.getMembershipCampaign[index];
    request.MembershipCampaignId = membershipCampaignData[index].MembershipCampaignId;
    if (request.statusId === 7) {
      request.statusId = 8;
    }
    else if (request.statusId === 8) {
      request.statusId = 7;
    }
    this.ActiveInActiveCampaign(request);
  }

  GetMembershipCampaignList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });
    this.membershipCampaignServices.getMembershipCampaignList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ getMembershipCampaign: response.responseItem.responseContent.itemList });
        this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
        let newGetmembershipCampaign = response.responseItem.responseContent.itemList;
        newGetmembershipCampaign.map((key, index) => {
          key.campaignStartDate = moment(key.campaignStartDate).format('DD MMM YYYY');
          key.campaignEndDate = moment(key.campaignEndDate).format('DD MMM YYYY');
          key.createdAt = moment(key.createdAt).format('DD MMM YYYY');
          key.campaignMinimumPaymentTerm = key.campaignMinimumPaymentTerm + " Year";
          if (key.statusId === 7) {
            key.IsActive = false;
          }
          else if (key.statusId === 8) {
            key.IsActive = true;
          }
       
       return key; });
        this.setState({ getMembershipCampaign: newGetmembershipCampaign });
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }
  onEdit = (value, index) => {
    let editMembershipCampaign = [...this.state.getMembershipCampaign];
    this.setState({ redirect: '/AddEditMembershipCampaign/membershipCampaignId?=' + encryptAES(editMembershipCampaign[index].membershipCampaignId) });
  }
  actions = (element, index, value) => {
    return (element !== "MembershipCampaignId") ? null:
    <td>
     <ActionContainer>
        <ActionEditButton
          value={value}
          id={index}
          index={index}
          // icon={EditIcon}
          onActionClick={this.onEdit.bind(this)}
          text="Edit"
        />
        <ActionToggleButton
          className="mr-2"
          index={index}
          // toggle={this.state.toggle}
          data={this.state.getMembershipCampaign}
          onToggleChage={this.onToggleChage.bind(this)}
        />
        <ActionDeleteButton
          value={value}
          id={index}
          index={index}
          onActionClick={this.onDelete.bind(this)}
        />
      </ActionContainer>
      </td>
  }

  onDelete = (value) => {
    let request = {
      id: value,
      deletedBy: 115,
      appId: 15
    }
    this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Campaign"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "Campaign"),
      CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
        if (response) {
          this.membershipCampaignServices.deleteMembershipCampaign(request).then((response) => {
            if (response.statusCode === 200) {
              this.swalServices.Success("Campaign deleted successfully");
              this.GetMembershipCampaignList();
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
          })
        }
      });
  }

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.GetMembershipCampaignList();
  }

  componentDidMount() {
    this.GetMembershipCampaignList();
  }

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (

      <div className="main-body-section">
        <div className="flex">
          <div className="relative flex flex-col flex-1 ">
            <div className="mb-4 sm:mb-0">
              <h1 className="table-title-txt theme-color font-bold">
                Membership Campaign Table
              </h1>
              <CreateButton
                redirect='/AddEditMembershipCampaign'
                text='Create New'
              />
            </div>
          </div>
        </div>

        <main className="pb-10">
          <div className="w-full mx-auto">

            <div className="relative">
              <div>
                <div className=" mt-6">
                  <Table columns={[{ name: 'CampaignStartDate', title: 'Start Date' }, { name: 'CampaignEndDate', title: 'End Date' }, { name: 'campaignMinimumPaymentTerm', title: 'Payment Term' }, { name: 'CreatedBy', title: 'Created By' }, { name: 'CreatedAt', title: 'Created Date' }, { name: 'statusName', title: 'Status' }, { name: 'MembershipCampaignId', title: 'Action' }]}
                    pagination={this.state.pagination}
                    rows={this.state.getMembershipCampaign}
                    sortingColumns={['statusName']}
                    isLoading={this.state.isLoading}
                    totalResultes={this.state.totalResultes}
                    totalCount={this.state.totalResultes}
                    setPagination={this.setPagination.bind(this)}
                    customScope={[
                      {
                        column: 'MembershipCampaignId',
                        renderTableData: this.actions.bind(this)
                      },
                    ]}

                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    )
  }
}
