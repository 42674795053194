import React, { Component } from "react";
import DropdownSelect from "../../components/dropdown/Dropdown";
import Components from "../../components/pageManagement/Components";
import withParams from "../../HOC/withParams";
import CommonServices from "../../services/axiosServices/apiServices/CommonServices";
import PagesManagementServices from "../../services/axiosServices/apiServices/PagesManagementServices";
import SwalServices from "../../services/swalServices/SwalServices";

class ManagePages extends Component {

  constructor(props) {
    super(props);
    this.PagesManagementServices = new PagesManagementServices();
    this.swalServices = new SwalServices();
    this.commonServices = new CommonServices();
    this.state = {
      componentsArr: [],
      pageDetailsId: 0,
      partialComponentDetails: {
        partialPageId: 0,
        componentId: 0,
        componentName: "",
        componentTypeId: 0,
        componentContent: "",
        pagesDetailsId: 0,
        selectedComponent: 0,
      },
      pageData: {
        selectedComponent: 0,
        components: [],
      },
      // validationRule: {
      //   companyName: [
      //     {
      //       type: "require",
      //       message: CommonValidationMessages.FieldRequired.replace(
      //         "{0}",
      //         "company name"
      //       ),
      //     },
      //   ],
      // },
      components: [],
      pageName: "",
      listTypes: [{ id: 1, name: 'Icon List' }, { id: 2, name: 'Bullet List' }],
      iconArray: [{ id: 1, name: '<img className="menu-svg  2xl:mr-10 lg:ml-6  mr-7 w-[35px] h-[25px] fill-[#6e6e6e]" src="/bulletList_dot.svg" alt=""></img>' },
      { id: 2, name: '<img className="menu-svg  2xl:mr-10 lg:ml-6  mr-7 w-[35px] h-[25px] fill-[#6e6e6e]" src="/bulletList_square.svg" alt=""></img>' },
      { id: 3, name: '<img className="menu-svg  2xl:mr-10 lg:ml-6  mr-7 w-[35px] h-[25px] fill-[#6e6e6e]" src="/bulletList_triangle.svg" alt=""></img>' }]
    };
  }

  componentDidMount() {
    // let params = new URLSearchParams(window.location.search);
    // let pageDetailsId = params.get("");
    let pageDetailsId = this.props.params.pagesDetailsId;
    pageDetailsId = isNaN(pageDetailsId) ? 0 : Number(pageDetailsId);
    this.setState({ pageDetailsId: pageDetailsId });

    if (pageDetailsId > 0) {
      this.getPartialPageComponentsById(pageDetailsId);
    } else {
      this.getAllDropdownsForPages();
    }
  }

  getAllDropdownsForPages = () => {
    this.PagesManagementServices.getAllDropdownsForPages().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        var componentsArr = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "PageManagementComponents"
        );
        componentsArr.map(function (item) { delete item["dropdownName"]; return item; });
        this.setState({ componentsArr: componentsArr });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  setFilterParameters = (id, drpIdentity) => {
    let detail = this.state.pageData;
    if (drpIdentity === "Components") {
      detail["selectedComponent"] = id;
    }
    this.setState({ pageData: { ...detail } });
  };

  addEditPartialPage = () => {
    var request = {}
    request.pagesDetailsId = this.state.pageDetailsId;
    request.componentList = [...this.state.components];
    request.componentList.forEach((value, index) => {
      if (value.componentTypeId === 3 || value.componentTypeId === 4 || value.componentTypeId === 5) {
        value.componentContent = JSON.stringify(value.componentContent);
      }
    });
    this.PagesManagementServices.addEditPartialPage(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.swalServices.Success("Components inserted successfully.");
        this.getPartialPageComponentsById(this.state.pageDetailsId);
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  }

  getPartialPageComponentsById = (pageDetailsId) => {
    this.PagesManagementServices.getPartialPageComponentsById(pageDetailsId).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        response.responseItem.responseContent.forEach((value, index) => {
          if (value.componentTypeId === 3 || value.componentTypeId === 4 || value.componentTypeId === 5) {
            value.componentContent = JSON.parse(value.componentContent);
          }
        });
        var pageTitle = response.responseItem.responseContent[0].pageName.replace(/\s/g, '');
        this.setState({ pageName: pageTitle })
        this.setState({ components: response.responseItem.responseContent },
          () => {
            this.getAllDropdownsForPages();
            this.setState({ components: response.responseItem.responseContent })
          });
      }
      else {
        this.getAllDropdownsForPages();
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  }

  saveMainImage = (file, listIndex, imageIdentity, componentIndex) => {
    this.setState({ isLoaderEnabled: true });
    file.storagePath = imageIdentity
    this.commonServices.storeBase64Image(file).then((response) => {
      if (response != null) {
        this.swalServices.Success("File Uploaded");
        let detail = this.state.components;
        if (file.storagePath === "\\PageManagement\\ListComponent") {
          detail[componentIndex].componentContent[listIndex].image = response.fileName;
        }
        else if (file.storagePath === "\\PageManagement\\ImageComponent") {
          let arr = listIndex.split("_")
          if (arr.length === 2) {
            detail[componentIndex].componentContent[arr[(arr.length - 1)]].image = response.fileName;
          }
        }
        else if (file.storagePath === "\\PageManagement\\ObjectComponent") {
          let arr = listIndex.split("_")
          if (arr.length === 2) {
            detail[componentIndex].componentContent[arr[(arr.length - 1)]].value = response.fileName;
          }
        }
        this.setState({ components: detail }, () => this.setState({ isLoaderEnabled: false }));
      }
      else {
        this.swalServices.Error(response.message);
      }
    })
  }

  addComponent = () => {
    var componentArr = [...this.state.components];
    if (this.state.pageData.selectedComponent > 0) {
      let componentType = "";
      if (this.state.pageData.selectedComponent === 3) {
        componentType = [{ image: "", listName: "" }];
      }
      else if (this.state.pageData.selectedComponent === 4) {
        componentType = [{ image: "", altTag: "", width: 100, height: 100 }];
      }
      else if (this.state.pageData.selectedComponent === 5) {
        componentType = [{ fieldName: '', fieldType: 'Text', value: '' }];
      }
      var component = {
        partialPageId: 0,
        componentId: 0,
        componentName: `${this.state.pageName}_`,
        componentTypeId: this.state.pageData.selectedComponent,
        componentContent: componentType,
        pagesDetailsId: this.state.pageDetailsId,
      }
      componentArr.push(component);
      this.setState({ components: componentArr });
    }
  }

  handleChange = (event, identity) => {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.components;
    detail[identity][name] = value;
    this.setState({ components: detail });
  }

  handleChangeInList = (event, identity, listIndex) => {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.components;
    detail[identity].componentContent[listIndex][name] = value;
    this.setState({ components: detail });
  }

  handleChangeInImageComp = (event, identity, imageIndex) => {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.components;
    detail[identity].componentContent[imageIndex][name] = value;
    this.setState({ components: detail });
  }

  setEditorData = (data, index) => {
    let detail = this.state.components;
    detail[index].componentContent = data;
    this.setState({ components: detail });
  }

  addNewListItem = (componentIndex) => {
    var componentArr = [...this.state.components];
    var newListItem = { image: "", listName: "" };
    componentArr[componentIndex].componentContent.push(newListItem);
    this.setState({ components: componentArr });
  }

  addNewImage = (componentIndex) => {
    var componentArr = [...this.state.components];
    var newImageObj = { image: "", altTag: "", width: 100, height: 100 };
    componentArr[componentIndex].componentContent.push(newImageObj);
    this.setState({ components: componentArr });
  }

  addNewFieldInObject = (listIndex) => {
    var componentArr = [...this.state.components];
    var newField = { fieldName: '', fieldType: 'Text', value: '' };
    componentArr[listIndex].componentContent.push(newField);
    this.setState({ components: componentArr });
  }

  removeFieldInObject = (fieldIndex, listIndex) => {
    var componentArr = [...this.state.components];
    componentArr[listIndex].componentContent.splice(fieldIndex, 1);
    this.setState({ components: componentArr });
  }

  handleChangeInObject = (name, value, fieldIndex, listIndex) => {
    var componentArr = [...this.state.components];
    componentArr[listIndex].componentContent[fieldIndex][name] = value;
    this.setState({ components: componentArr });
  }

  render() {
    return (
      <div className="main-body-section">
        <div className="bg-white">
          <div className=" heading-part px-7 py-4 border-b">
            <h4 className="small-title font-bold theme-color">
              Add Components
            </h4>
          </div>
          <div className="px-6 py-10">
            <div className="grid grid-cols-12 gap-6 w-full">
              <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12">
                <h2 className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30">
                  Select Component Type
                </h2>
                <DropdownSelect
                  drpIdentity={"Components"}
                  optionArray={this.state.componentsArr}
                  setFilterParameters={this.setFilterParameters}
                  value={this.state.pageData.selectedComponent}
                />
                {/* <div>
                  {!this.state.pageData.pagesMainModuleId ? (
                    <ValidationText error={"Please select main module"} />
                  ) : null}
                </div> */}
              </div>
              <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 pt-12">
                <div className="flex justify-end">
                  <button className=" px-7 py-3 btn-red text-xl border text-white" onClick={() => this.addComponent()}>
                    <div className="flex items-center font-semibold">
                      <span className="mr-3">
                        <img src="/plus-round-icon.svg" alt="" />
                      </span>
                      <span className="">Add Component</span>
                    </div>
                  </button>
                </div>
              </div>
              <div className="col-span-12">
                {/* Reach */}
                {/* {this.state.pageData.components.map((value, index) => {
                  return (
                  <RichText 
                  key={index}
                  componentDetails={value}
                  />
                  )
                })} */}
                <Components
                  componentsArray={this.state.components}
                  iconArray={this.state.iconArray}
                  listTypes={this.state.listTypes}
                  handleChange={this.handleChange.bind(this)}
                  setEditorData={this.setEditorData.bind(this)}
                  addEditPartialPage={this.addEditPartialPage.bind(this)}
                  handleChangeInList={this.handleChangeInList.bind(this)}
                  addNewListItem={this.addNewListItem.bind(this)}
                  saveMainImage={this.saveMainImage.bind(this)}
                  handleChangeInImageComp={this.handleChangeInImageComp.bind(this)}
                  addNewImage={this.addNewImage.bind(this)}
                  addNewFieldInObject={this.addNewFieldInObject.bind(this)}
                  handleChangeInObject={this.handleChangeInObject.bind(this)}
                  removeFieldInObject={this.removeFieldInObject.bind(this)}
                />

              </div>
              {/* <div className="col-span-12">
                <RichText/>
                <SimpleText/>
                <List/>
                <Images/>
              </div> */}
            </div>
          </div>

          <div className="flex flex-wrap justify-between space-x-10 mt-s5">
            <button
              className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white"
              onClick={() => this.addEditPartialPage()}
            >
              Save
            </button>
          </div>
        </div>

      </div>
    );
  }
}
export default withParams(ManagePages)
