import { CommonValidationMessages } from "../../../utils/Messages";

export const siisAccountInformationValRules = {
    // accountStatus: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace(
    //             "{0}",
    //             "account status"
    //         ),
    //     },
    // ],
    companyDomain: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "domain"
            ),
        },
        {
            type: "emailDomain",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "valid domain"
            ),
        },
    ],
    // companyDomain: [
    //     {
    //         type: "emailDomain",
    //         message: CommonValidationMessages.FieldRequired.replace(
    //             "{0}",
    //             "proper format of domain"
    //         ),
    //     },
    // ],
};

export const engagementTrackingValRules = {
    corporateEngagementTypeId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "engagement type"
            ),
        },
    ],
    corporateEngagementRecordedById: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "recorded by"
            ),
        },
    ],

    engagementDate: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "engagement date"
            ),
        },
    ],
    engagementDescription: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "description"
            ),
        },
    ],
};

export const prospectInformationValRules = {
    source1Id: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "source 1"
            ),
        },
    ],

    source2Id: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "source 2"),
        },
    ],

    pic1Id: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "pic 1"
            ),
        },
    ],
};

export const financeCardValRules = {
    creditTermsId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "credit terms"),
        },
    ],
};

// export const financeCardValRules = {
//     renewalNotes: [
//         {
//             type: "require",
//             message: CommonValidationMessages.FieldRequired.replace(
//                 "{0}",
//                 "company name"
//             ),
//         },
//     ],
//     internalFlagId: [
//         {
//             type: "require",
//             message: CommonValidationMessages.FieldRequired.replace(
//                 "{0}",
//                 "company UEN"
//             ),
//         },
//     ],
// };

export const governmentProgrammesValRules = {
    renewalNotes: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company name"
            ),
        },
    ],
    internalFlagId: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company UEN"
            ),
        },
    ],
};

export const memberSubscriptionValRules = {
    membershipTypeId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "membership type"
            ),
        },
    ],
    billingCategoryId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "billing category"
            ),
        },
    ],
};

export const chapterInformationValRules = {
    workgroupMappingId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "chapter name"
            ),
        },
    ],
    // renewalNotes: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace(
    //             "{0}",
    //             "company name"
    //         ),
    //     },
    // ],
    // internalFlagId: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace(
    //             "{0}",
    //             "company UEN"
    //         ),
    //     },
    // ],
};

export const purchaseChapterValidationRule = {
    noOfAdditionalChapters: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "additional number of chapter"),
        },
    ],
    paymentVia: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "payment via"),
        },
    ],
};

export const organizationBasicInformationValRules = {
    companyUEN: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "company uen"),
        },
    ],
    mrMrsId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "name prefix"),
        },
    ],
    companyName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company name"
            ),
        },
    ],
    incorporationDate: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "incorporating date"
            ),
        },
    ],
    firstName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "representative's first name"
            ),
        },
    ],
    lastName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "representative's last name"
            ),
        },
    ],
    contactNo: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "business contact number"),
        },
        {
            type: 'maxLength',
            maxLength: 20,
            message: CommonValidationMessages.MaxLength.replace("{0}", "20"),
        },
    ],
    designation: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "designation"
            ),
        },
    ],
    email: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "email address"
            ),
        },
    ],
};

export const organizationProfileValRules = {
    paidUpCapital: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "paidUp capital"),
        },
    ],
    orgGrossTurnoverId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "annual sales turnover"),
        },
    ],
    staffStrength: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "employment size"),
        },
    ],
    countryId: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "country"),
        },
    ],
    generalBusinessTelephone: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "general business telephone"),
        },
        {
            type: 'maxLength',
            maxLength: 20,
            message: CommonValidationMessages.MaxLength.replace("{0}", "20"),
        },
    ],
    generalBusinessEmail: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "general business email"),
        },
    ],
    // organisationWebsite: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "organisation website"),
    //     },
    // ],
    orgLogoFileName: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "company logo"),
        },
    ],
    registeredStreetName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "block no & street name of regeistered address"),
        },
    ],
    registeredPostalCode: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "registered postalcode"),
        },
    ],
    mailingStreetName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "block no & street name of mailing address"),
        },
    ],
    mailingPostalCode: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "mailing postalcode"),
        },
    ],
    acraFilePath: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "ACRA file"),
        },
    ],


};

export const organizationBusinessDetailsValRules = {
    typesOfServices: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "service type"
            ),
        },
    ],
    typesOfIndustriesServed: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "industries served"
            ),
        },
    ],
    countriesPresence: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "countries presence"
            ),
        },
    ],
    associationBenefits: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "association benefits"
            ),
        },
    ],
    getToKnowId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "how did you hear about us"
            ),
        },
    ],
    others: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "other information"
            ),
        },
    ],
};

export const sgTechInterestValRules = {
    renewalNotes: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company name"
            ),
        },
    ],
    internalFlagId: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company UEN"
            ),
        },
    ],
};

export const communicationValRules = {
    renewalNotes: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company name"
            ),
        },
    ],
    internalFlagId: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company UEN"
            ),
        },
    ],
};

export const organizationEmployeesValRules = {
    firstName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "first name"),
        },
    ],
    lastName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "last name"),
        },
    ],
    emailText: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "email address"),
        },
    ],
    emailString: [
        {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid email address"),
        },
    ],
    designation: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "designation"),
        },
    ],
    // contactRoleId: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace("{0}", "Organisation Role"),
    //     },
    // ],
    officeDID: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "Office DID"),
        },
    ],
    emailSubscription: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "Email Subscription"),
        },
    ],
    searchByEmail: [
        {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid email address"),
        },
        {
            type: "maxLength",
            maxLength: 150,
            message: CommonValidationMessages.FieldRequired.replace("{0}", "less than 150 characters"),
        },

    ],

};

export const engagementvalidationRule = {
    // corporateEngagementTypeId: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace(
    //             "{0}",
    //             "engagement type"
    //         ),
    //     },
    // ],

    // engagementDate: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace(
    //             "{0}",
    //             "engagement date"
    //         ),
    //     },
    // ],
    // engagementDescription: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace(
    //             "{0}",
    //             "description"
    //         ),
    //     },
    // ],
    // corporateEngagementRecordedById: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace(
    //             "{0}",
    //             "recorded by"
    //         ),
    //     },
    // ],
    // corporateEngagementFollowUpById: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace(
    //             "{0}",
    //             "follow up by"
    //         ),
    //     },
    // ],
};

export const organisationKeyRolesValRules = {
    mrMrs: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "name prefix"),
        },
    ],
    firstName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "first name"),
        },
    ],
    lastName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "last name"),
        },
    ],
    emailText: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "email address"),
        },
    ],
    emailString: [
        {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid email address"),
        },
        {
            type: "maxLength",
            maxLength: 150,
            message: CommonValidationMessages.FieldRequired.replace("{0}", "less than 150 characters"),
        },

    ],
    designation: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "designation"),
        },
    ],
    contactRoleId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "organisation Role"
            ),
        },
    ],
    officeDID: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "Office DID"),
        },
    ],
    searchByEmail: [
        {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid email address"),
        },
        {
            type: "maxLength",
            maxLength: 150,
            message: CommonValidationMessages.FieldRequired.replace("{0}", "less than 150 characters"),
        },

    ],
    // organisationRole: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace("{0}", "organisation role"),
    //     },
    // ]
}

export const addEditCreditNoteValRules = {
    cancellationReason: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "cancellation reason"),
        },
    ],

};

export const addEditCreditNoteValRules2 = {
    cancellationReason: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "other cancellation reason"),
        },
    ],
    entranceRefund: [
        {
            type: "maxNumber",
            compareEle: "compareEntranceRefund",
            message: CommonValidationMessages.MaxAmount.replace("{0}", "entrance amount"),
        },
    ],
    annualRefund: [
        {
            type: "maxNumber",
            compareEle: "compareAnnualRefund",
            message: CommonValidationMessages.MaxAmount.replace("{0}", "annual amount"),
        },
    ],
    chapterRefund: [
        {
            type: "maxNumber",
            compareEle: "compareChapterRefund",
            message: CommonValidationMessages.MaxAmount.replace("{0}", "chapter amount"),
        },
    ],
    creditNoteMonth: [{
        type: "require",
        message: CommonValidationMessages.SelectRequired.replace("{0}", "effective date"),

    }]
};

export const validationRuleForGoGlobal = {
    companyTypeId: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company type"
            ),
        },
    ],
    companyName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company name"
            ),
        },
    ],
    companyWebsite: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company website"
            ),
        },
        {
            type: "isValidateURL",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "proper website url"),
        },
    ],
    // selectedRegion: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace(
    //             "{0}",
    //             "region"
    //         ),
    //     },
    // ],
    selectedCountryWithPresence: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "country"
            ),
        },
    ],
    // selectedCity: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace(
    //             "{0}",
    //             "city"
    //         ),
    //     },
    // ],
    selectedBusinessSector: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "primary sector(s) of main business activity"
            ),
        },
    ],
    selectedServiceProvided: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "list of services"
            ),
        },
    ],
    selectedPartnerType: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "type of business activity"
            ),
        },
    ],
    companyDescription: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company description"
            ),
        },
    ],
    firstName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "first name"
            ),
        },
    ],
    lastName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "last name"
            ),
        },
    ],
    goGlobalContactEmail: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "go global contact email"
            ),
        },
    ],
    companyLogo: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "company logo"),
        }
    ],
    organisationUENLicenseNumberVATID: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "organisation UEN"),
        }
    ],
    businessContactNumber: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "business contact number"),
        }
    ],
    companyAddress: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "company address"),
        }
    ]
}

export const searchByEmailValRule = {
    searchByEmail: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "valid email address"
            ),
        },
        {
            type: "maxLength",
            maxLength: 150,
            message: CommonValidationMessages.FieldRequired.replace("{0}", "less than 150 characters"),
        },
    ],
}