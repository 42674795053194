import { CommonValidationMessages } from "../../../utils/Messages";


// individualSIISAccountId: 0,
//         individualMemberId: 0,
//         accountStatus: 0,
//         individualJoinedDate: "",
//         updatedBy: 0,
//         updatedAt: "",
//         markForDeleted: false,
//         deletionReasonId: 0,
//         deletedAt: "",
//         deletionDate: "",
//         engagementScore: 0,
//         sIISIndividualId: "",
export const SIISIndividualAccountValRules = {
    accountStatus: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "account status"),
        }
    ],
    individualJoinedDate: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "joined date"),
        }
    ],
    // updatedAt: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace("{0}", "updated date"),
    //     }
    // ],
    updatedBy: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "updated by"),
        }
    ],
}
export const SIISIndividualAccountValRulesMarkForDelete = {
    deletionReasonId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "deletion Reason"),
        }
    ],
    deletionDate: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "deletion date"),
        }
    ],
}

export const IndividualProfileValRules = {
    firstName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "first name"),
        }
    ],
    lastName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "last name"),
        }
    ],
    designation: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "designation"),
        }
    ],
    contactNo: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "contact no"),
        },
        // {
        //     type: "number",
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "only digits"),
        // },
        // {
        //     type: "maxLength",
        //     maxLength: 10,
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "between 8 and 10 digits"),
        // },
        // {
        //     type: "minLength",
        //     minLength: 8,
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "between 8 and 10 digits"),
        // },
        {
            type: 'maxLength',
            maxLength: 20,
            message: CommonValidationMessages.MaxLength.replace("{0}", "20"),
        },
    ],
    // corporateMemberId: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "organisation"),
    //     }
    // ],
    companyName: [
        {
            type: 'require',
            message: CommonValidationMessages.FieldRequired.replace('{0}', 'organisation name'),
        },
    ],
    emailText: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "email"),
        }
    ],
    emailString: [
        {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid email address"),
        }
    ],
    // companyAddress: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "company address line 1"),
    //     }
    // ],
    // companyAddress2: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "company address line 2"),
    //     }
    // ],
    // companyAddress3: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "company address line 3"),
    //     }
    // ],
    individualStatusId: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "status"),
        }
    ],
    mrMrsId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "name prefix"),
        }
    ],
    businessContactNumber: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "business contact number"),
        },
        // {
        //     type: "number",
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "only digits"),
        // },
        // {
        //     type: "maxLength",
        //     maxLength: 10,
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "between 8 and 10 digits"),
        // },
        // {
        //     type: "minLength",
        //     minLength: 8,
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "between 8 and 10 digits"),
        // },
        {
            type: 'maxLength',
            maxLength: 20,
            message: CommonValidationMessages.MaxLength.replace("{0}", "20"),
        },
    ],
    mailingAddress1: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "mailing address line 1"),
        }
    ],
    // mailingAddress2: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "mailing address line 2"),
    //     }
    // ],
    // mailingAddress3: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "mailing address line 3"),
    //     }
    // ],
    postalCode: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "postal code"),
        },
        // {
        //     type: "number",
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "valid postal code"),
        // },
        // {
        //     type: "maxLength",
        //     maxLength: 6,
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "valid postal code"),
        // },
        // {
        //     type: "minLength",
        //     minLength: 6,
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "valid postal code"),
        // },
        {
            type: 'maxLength',
            maxLength: 20,
            message: CommonValidationMessages.MaxLength.replace("{0}", "20"),
        },
    ],
    photo: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "photo"),
        }
    ],
    employementTitle:[
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "designation"),
        }
    ]
}

export const EmploymentInformationValRules = {
    employmentCompanyName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "company name"),
        }
    ],
    // employmentJobFunctionId: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace("{0}", "job function"),
    //     }
    // ],
    // employmentJobLevelId: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace("{0}", "job level"),
    //     }
    // ],
    title: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "title"),
        }
    ],

}

export const IndividualContributionValRules = {
    roleId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "organisation role"),
        }
    ],
    startDate: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "start date"),
        }
    ],
    workgroupMappingId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "chapter/committee name"),
        }
    ],

}

export const CommitteeCardValRules = {
    workgroupMappingId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "chapter/committee name"),
        }
    ],
}

export const EngagementTrackingValRules = {
    engagementDate: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "engagement date"),
        }
    ],
    corporateEngagementTypeId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "engagement type"),
        }
    ],
    engagementDescription: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "engagement description"),
        }
    ],



}







