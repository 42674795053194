import { ActiveInActiveCampaign, AddEditMembershipCampaign, DeleteMembershipCampaign, GetMembershipCampaignById, GetMembershipCampaignList } from "../ApiEndPoints";
import { axiosGetAuthorize, axiosPostAuthorize } from "../AxiosRequests";


export default class MembershipCampaignServices {

    async getMembershipCampaignList(request) {
        return axiosPostAuthorize(GetMembershipCampaignList, request);
    }
    async activeInActiveMembershipCampaign(request) {
        return axiosPostAuthorize(ActiveInActiveCampaign, request);
    }
    async addEditMembershipCampaign(request) {
        return axiosPostAuthorize(AddEditMembershipCampaign, request);
    }
    async getMembershipCampaign(request) {
        return axiosGetAuthorize(GetMembershipCampaignById, request);
    }
    async deleteMembershipCampaign(request) {
        return axiosPostAuthorize(DeleteMembershipCampaign, request)
    }
}
