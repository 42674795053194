import React from 'react';
import ReactDOM from 'react-dom/client';
import UnderMaintenance from '../src/shared/UnderMaintenance'
import './index.css';
import '../node_modules/font-awesome/css/font-awesome.min.css'
import './UnderMaintenance.scss'
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap/scss/bootstrap.scss"; // Or the one above.
import "font-awesome/css/font-awesome.css";
// import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";
// import "tempusdominus-bootstrap/src/sass/tempusdominus-bootstrap-build.scss"; // Or the one above.




import App from './App';
import reportWebVitals from './reportWebVitals';
import { IsProdMode } from './utils/AppSetting';

const IsUnderMaintenance = false;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  IsProdMode === true ?
    <>
      {
        IsUnderMaintenance === true ? <UnderMaintenance /> : <App />
      }
    </>
    :
    <>
      {
        IsUnderMaintenance === true ? <UnderMaintenance /> :
          <React.StrictMode>
            <App />
          </React.StrictMode>
      }

    </>


);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
