import React, { Component } from "react";

export default class PageNotFound extends Component {
  render() {
    return (
      <div>
        <div className="flex items-center flex-col justify-center absolute w-full h-full">
          <img src="../page_not_found.png" alt=""/>
          <h1 className="mt-14 text-4xl font-bold theme-color">
              This <span className="theme-active-color">Page </span> Is Under Construction
          </h1>
        </div>
      </div>
    );
  }
}
