import React from "react";
import ModalBasic from "../../component/ModalBasic";

const SendPaymentLink = (props) => {
    return (
        <ModalBasic
            id="send-payment-link-modal"
            modalOpen={props.paymentLinkModal}
            setModalOpen={props.setOpenModal}
            title=""
            noCloseIcon={true}
        >
            <div className="text-center pb-2">
                <h1 className="text-4xl text-[#6cbf6c] font-bold leading-loose py-4">
                    Payment Link Sent
                </h1>
                <h1 className="text-xl text-[#757575] font-normal leading-loose p-8 py-4">
                    {/* Email has been sent to applicant's company business email {props.email}. */}
                    Payment Link has been Sent Successfully.
                </h1>
                <button
                    className="bg-[#6cbf6c] text-base text-[#fff] font-bold px-12 py-2 my-4"
                    onClick={props.onClose}
                >
                    Ok
                </button>
            </div >
        </ModalBasic>
    );
}
export default SendPaymentLink;