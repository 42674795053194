import React, { Component } from "react";

import DtsmeServices from "../../services/axiosServices/apiServices/DtsmeServices";
import SwalServices from "../../services/swalServices/SwalServices";
// import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader";
import Table from "../../components/table/Table";
import PageLoader from "../../components/common/pageLoader/PageLoader";
import moment from "moment";
import { saveAs } from "file-saver";
import { CommonValidationMessages } from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";



export class DTSMEPurchaseReport extends Component {
    constructor(props) {
        super(props);
        this.dtsmeServices = new DtsmeServices();
        this.swalServices = new SwalServices();
        this.state = {
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "",
                orderFlag: 0,
                searchText: "",
                statusId: 0,
                optionSelected: [],
                hiddenSearch: "",
              },
              getDTSMEPurchaseRecordList : [],
            DTSMEPurchaseReportRequest: {
                startDate: "",
                endDate: "",
            },
            isLoading: false,
            validState: {
                isValid: true,
                error: {},
            },
            validationRule: {
                startDate: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "Start date for transaction report"),
                    },
                ],
                endDate: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "End date for transaction report"),
                    },
                    {
                        type: "minEndDate",
                        minEndDate: "startDate",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "greater end date")
                    }
                ],
            },
            
        }
    }

    validateField = (key) => {
        const newValidState = validate(
            key,
            this.state.DTSMEPurchaseReportRequest,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
    };

    isValidateAllFields = () => {
        const newValidState = isValidForm(
            this.state.DTSMEPurchaseReportRequest,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };
    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = this.state.DTSMEPurchaseReportRequest
        data[name] = value;
        this.setState({ DTSMEPurchaseReportRequest: data })
    }
    getDTSMEPurchaseRecordList = ()=>{
        let isValidateAllFields = this.isValidateAllFields()
        if (isValidateAllFields === true) {
            if (this.state.DTSMEPurchaseReportRequest.startDate && this.state.DTSMEPurchaseReportRequest.endDate) {
                this.setState({ isLoading: true });
                let request = { ...this.state.pagination };
                request.startDate = moment(this.state.DTSMEPurchaseReportRequest.startDate).format("DD MMM YYYY");
                request.endDate = moment(this.state.DTSMEPurchaseReportRequest.endDate).format("DD MMM YYYY");
                this.dtsmeServices.getDTSMEPurchaseRecordList(request).then((response) => {
                    if (response.statusCode === 200 && response.responseItem !== null) {
                        if (response.responseItem.responseContent !== null) {
                            let details = response.responseItem.responseContent.itemList
                            if (details.length > 0) {
                                details.map((date) => {
                                    date.incorporationDate = date.incorporationDate ? moment(date.incorporationDate).format("DD MMM YYYY") : "";
                                    date.name= date.firstName + " " + date.lastName
                                    return date;
                                })
                            }
                            this.setState({
                                getDTSMEPurchaseRecordList: details,
                                totalCount: response.responseItem.responseContent.totalCount,
                                totalResultes: response.responseItem.responseContent.totalCount,
                            })
                        }
                    }
                    else if (response.statusCode === 409) {
                        this.swalServices.Error(response.message);
                    }
                    else {
                        this.swalServices.Error(response.message);
                    }
                    this.setState({ isLoading: false });
                });
            }
        }
    }
    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getDTSMEPurchaseRecordList()
    };
    downloadSheetForDTSMEPurchaseReport = () => {
        let isValidateAllFields = this.isValidateAllFields()
        if (isValidateAllFields) {
            if (this.state.DTSMEPurchaseReportRequest.startDate && this.state.DTSMEPurchaseReportRequest.endDate) {
                this.setState({ isLoading: true });
                let request = {};
                request.startDate = moment(this.state.DTSMEPurchaseReportRequest.startDate).format("DD MMM YYYY");
                request.endDate = moment(this.state.DTSMEPurchaseReportRequest.endDate).format("DD MMM YYYY");
                this.dtsmeServices.downloadSheetForDTSMEPurchaseReport(request).then((response) => {
                    if (response.status === 200 && response.data != null) {
                        if (response.data?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                            let blob = new Blob([response.data])
                            // saveAs(blob, `DTSMEPurchaseReport_${request.startDate}_${request.endDate}` + ".xlsx");
                            saveAs(blob, `DTSMEPurchaseReport_${request.startDate}_${request.endDate}.xlsx`);
                            this.setState({ isLoading: false });
                            this.swalServices.Success("DTSME Purchase Report Downloaded Successfully !.");
                        }
                        else {
                            this.setState({ isLoading: false });
                            this.swalServices.Error("No records available between these dates.");
                        }
                    }
                    else {
                        this.swalServices.Error(response.message);
                        this.setState({ isLoading: false });
                    }

                });
            }
        } else {
            this.swalServices.Error("Please select date")
        }
    }



    render() {
        return (
            <>
                <div className="main-body-section">
                    <div className="custom-card shadow-lg">
                        <div className="grid grid-cols-12 gap-6 w-full items-center px-8 py-10">
                            <div className="col-span-3">
                                <label
                                    className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                    htmlFor="default"
                                >
                                    Start Date<span className="text-[#C00000]">*</span>
                                </label>
                                <div className="">
                                    <input
                                        id="startDate"
                                        name="startDate"
                                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                        type="date"
                                        value={moment(this.state.DTSMEPurchaseReportRequest.startDate).format("yyyy-MM-DD")}
                                        onChange={(event) => this.handleChange(event)}
                                        onBlur={() => this.validateField("startDate")}
                                    />
                                </div>

                            </div>
                            <div className="col-span-3">
                                <label
                                    className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                    htmlFor="default"
                                >
                                    End Date<span className="text-[#C00000]">*</span>
                                </label>
                                <div className="">
                                    <input
                                        id="endDate"
                                        name="endDate"
                                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                        type="date"
                                        value={moment(this.state.DTSMEPurchaseReportRequest.endDate).format("yyyy-MM-DD")}
                                        onChange={(event) => this.handleChange(event)}
                                        onBlur={() => this.validateField("endDate")}
                                    // min={this.state.transactionRecordRequest.startDate}
                                    />
                                </div>

                            </div>
                            <div className="col-span-4">
                            <button className="btn btn-red text-xl border text-white mt-8" onClick={() => this.getDTSMEPurchaseRecordList()}>
                                Show Record
                            </button>
                        </div>
                            {this.state.getDTSMEPurchaseRecordList &&
                            this.state.getDTSMEPurchaseRecordList.length > 0  ? 
                            <div className="col-span-4">
                            <button className="btn btn-red text-xl border text-white mt-8" onClick={() => this.downloadSheetForDTSMEPurchaseReport()}>
                                Download Excel
                            </button>
                        </div>
                            :
                             null
                            }
                        </div>

                        <div className="grid grid-cols-12 gap-6 w-full items-center justify-center px-8 py-10">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                            {this.state.isLoading ? <PageLoader /> :
                                <Table
                                columns={[
                                    { name: "companyName", title: "Company Name", },
                                    { name: "uEN", title: "UEN" },
                                    { name: "incorporationDate", title: "Incorporation Date" },
                                    { name: "membershipType", title: "Membership Type" },
                                    
                                    { name: "name", title: "Name" },
                                    { name: "contactNumber", title: "Contact Number" },
                                    { name: "email", title: "Email" },
                                    { name: "packageType", title: "Package Type" },
                                    { name: "paymentMethod", title: "Payment Method" },
                                    { name: "isTACMember", title: "IsTACMember" },
                                ]}
                                pagination={this.state.pagination}
                                rows={this.state.getDTSMEPurchaseRecordList}
                                sortingColumns={[""]}
                                isLoading={this.state.isLoading}
                                totalResultes={this.state.totalResultes}
                                totalCount={this.state.totalCount}
                                setPagination={this.setPagination.bind(this)}
                                />}
                        </div>
                    </div>
                    </div>
                </div>
            </>
        )
    }
}

export default DTSMEPurchaseReport

