import React from "react";
import DropdownSelect from "../../dropdown/Dropdown";
import TextInput from "../../UIComponent/TextInput";
import Validation from '../../../utils/validation/ValidationText';
import ImageCropperWithUpload from "../../ImageUpload/UploadImage";
import moment from "moment";
import SingleSelectWithSearch from "../../dropdown/SingleSelectWithSearch/SingleSelectWithSearch";
import Selector from "../../dropdown/commonDropdown/Selector";
import ImageUploadTest from "../../ImageUpload3/ImageUploadTest";


function IndividualBasicInformation(props) {
  return (
    <div className="w-full">
      <div className="2xl:py-5 2xl:pt-5 lg:py-5 lg:pt-5">
        <div className="grid grid-cols-12 gap-6 w-full items-center">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="grid grid-cols-12 gap-6 w-full ">
              <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                <div className="grid grid-cols-12 gap-6 w-full ">
                  <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                        <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                          First Name <span className="text-[#c00000]">*</span>
                        </h2>
                        <div className="dropdwn-input flex items-center">
                          <DropdownSelect
                            drpIdentity={"mrMrsId"}
                            optionArray={props.mrMrs}
                            setFilterParameters={props.setFilterParameters}
                            value={props.individualProfile.mrMrsId}
                          />
                          <TextInput
                            placeholder=""
                            type="text"
                            name="firstName"
                            value={props.individualProfile.firstName}
                            identity="BasicInformation"
                            handleChange={props.handleChange}
                          // onBlur={(e) => props.validate("firstName", props.individualProfile)}

                          // value={this.state.searchbyParent}
                          // handleChange={(event) => {
                          //   this.setState({ searchByName: event.target.value });
                          // }}
                          />
                        </div>
                        <Validation error={props.validState.error.mrMrsId} />

                        <Validation error={props.validState.error.firstName} />
                      </div>
                    </div>

                  </div>

                  <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="2xl:col-span-11 lg:col-span-12 col-span-12">
                        <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                          Last Name <span className="text-[#c00000]">*</span>
                        </h2>
                        <TextInput
                          placeholder=""
                          type="text"
                          name="lastName"
                          value={props.individualProfile.lastName}
                          identity="BasicInformation"
                          handleChange={props.handleChange}
                        // onBlur={(e) => props.validate("lastName", props.individualProfile)}

                        // value={this.state.searchbyParent}
                        // handleChange={(event) => {
                        //   this.setState({ searchByName: event.target.value });
                        // }}
                        />
                        <Validation error={props.validState.error.lastName} />
                      </div>
                    </div>
                  </div>

                  <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="2xl:col-span-11 lg:col-span-12 col-span-12">
                        <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                          Job Function <span className="text-[#c00000]">*</span>
                        </h2>
                        <TextInput
                          placeholder=""
                          type="text"
                          name="designation"
                          value={props.individualProfile.designation}
                          identity="BasicInformation"
                          handleChange={props.handleChange}
                        // onBlur={(e) => props.validate("title", props.individualProfile)}
                        />
                        <Validation error={props.validState.error.selectedDesignation} />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                <div className="company-logo-cropper ml-auto mr-auto">

                  <div className="individual-profile-create-cropper">
                    <ImageUploadTest
                      key="Main"
                      height={400}
                      width={400}
                      isCheckValidation={true}
                      MaxFileSize={2097152}
                      MaxFileSizeMessage={"2MB"}
                      onSaveImage={props.saveMainImage}
                      aspactRatio={400 / 400}
                      imageIdentity="orgLogoFileName"
                      intitImage={props.individualProfile.profileImagePath}
                      uploadBtn={"Upload Photo"}
                    // dimensionText={"(Dimension: 400px by 200px Maximum Size: 2MB)"}
                    />
                    <div className=" text-center">
                      <Validation error={props.validState.error.photo} />
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>

          {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Designation <span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="employementTitle"
                  value={props.individualProfile.employementTitle}
                  identity="BasicInformation"
                  handleChange={props.handleChange}
                  onBlur={(e) => props.validate("employementTitle", props.individualProfile)}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                />
                <Validation error={props.validState.error.employementTitle} />
              </div>
            </div>
          </div> */}
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Business Mobile <span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="bussinessMobile"
                  value={props.individualProfile.bussinessMobile}
                  identity="BasicInformation"
                  handleChange={props.handleChange}
                // onBlur={(e) => props.validate("contactNo", props.individualProfile)}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                />
                <Validation error={props.validState.error.contactNo} />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  {/* Email (please enter company email) */}
                  Email Address
                  <span className="text-[#c00000]">*</span>
                </h2>
                {
                  (props.individualProfile.isNonMember === true) ?
                    <div className="dropdwn-input-email flex items-center">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="emailText"
                        value={props.individualProfile.emailAddress}
                        identity="BasicInformation"
                        handleChange={props.handleChange}
                      // onBlur={(e) => props.validate("emailText", props.individualProfile)}
                      />
                    </div>
                    :
                    <div className="dropdwn-input-email flex items-center">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="emailText"
                        value={props.individualProfile.emailAddress}
                        identity="BasicInformation"
                        handleChange={props.handleChange}
                      // onBlur={(e) => props.validate("emailText", props.individualProfile)}
                      />
                      {/* <DropdownSelect
                        disabled={true}
                        drpIdentity={"EmailDomain"}
                        optionArray={props.domainArr}
                        setFilterParameters={props.setFilterParameters}
                        value={props.individualProfile.emailDomainId}
                      /> */}
                    </div>
                }
                <Validation error={props.validState.error.emailText} />
                <Validation error={props.validState.error.emailString} />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  {/* Email (please enter company email) */}
                  Office DID
                  <span className="text-[#c00000]">*</span>
                </h2>
                {
                  (props.individualProfile.isNonMember === true) ?
                    <div className="dropdwn-input-email flex items-center">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="emailText"
                        value={props.individualProfile.officeDID}
                        identity="BasicInformation"
                        handleChange={props.handleChange}
                      // onBlur={(e) => props.validate("emailText", props.individualProfile)}
                      />
                    </div>
                    :
                    <div className="dropdwn-input-email flex items-center">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="emailText"
                        value={props.individualProfile.officeDID}
                        identity="BasicInformation"
                        handleChange={props.handleChange}
                      // onBlur={(e) => props.validate("emailText", props.individualProfile)}
                      />
                      {/* <DropdownSelect
                        disabled={true}
                        drpIdentity={"EmailDomain"}
                        optionArray={props.domainArr}
                        setFilterParameters={props.setFilterParameters}
                        value={props.individualProfile.emailDomainId}
                      /> */}
                    </div>
                }
                <Validation error={props.validState.error.emailText} />
                <Validation error={props.validState.error.emailString} />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                  Organisation Name <span className="text-[#c00000]">*</span>
                </h2>
                {/* <DropdownSelect
                  drpIdentity={"Organisation"}
                  optionArray={props.organisation}
                  setFilterParameters={props.setFilterParameters}
                  value={props.individualProfile.corporateMemberId}
                /> */}
                {/* <SingleSelectWithSearch
                  drpIdentity={"Organisation"}
                  optionArray={props.filteredOrganisation}
                  value={props.individualProfile.corporateMemberId}
                  setFilterParameters={(id, name, drpIdentity) => props.setFilterParametersForSelector(id, name, drpIdentity)}
                  handleChangeTest={(e, identity) => props.handleChangeForSelector(e, identity)}
                  searchText={props.individualProfile.companyName}
                /> */}
                <SingleSelectWithSearch
                  drpIdentity={"Organisation"}
                  optionArray={props.filteredOrganisation}
                  value={props.individualProfile.organisationName}
                  setFilterParameters={(id, drpIdentity, name) => props.setFilterParametersForSelector(id, name, drpIdentity)}
                  handleChange={(e, identity) => props.handleChangeForSelector(e, identity)}
                  searchText={props.individualProfile.organisationName}
                />
                <Validation error={props.validState.error.corporateMemberId} />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="xl:col-span-12 lg:col-span-12 col-span-12 input-sec-texts">
              <div className="left-tiitle">
                <h4 className="small-title 2xl:text-[22px] font-bold text-[#757575] ">
                  Organisation Registered Address&nbsp;<span className="text-[#F93549]">*</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts"></div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                  Block No & Street Name
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="companyAddress"
                  value={props.individualProfile.registeredStreetName}
                  identity="BasicInformation"
                  handleChange={props.handleChange}
                  //onBlur={(e) => props.validate("companyAddress", props.individualProfile)}
                  disabled={true}
                  isCurserAllowed={true}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                />
                {/* <Validation error={props.validState.error.companyAddress} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                  Unit Number & Building Name
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="companyAddress2"
                  value={props.individualProfile.registeredBuildingName}
                  identity="BasicInformation"
                  handleChange={props.handleChange}
                  // onBlur={(e) => props.validate("companyAddress2", props.individualProfile)}
                  disabled={true}
                  isCurserAllowed={true}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                />
                {/* <Validation error={props.validState.error.companyAddress2} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="flex items-start register-desc 2xl:mt-5 lg:mt-5 md:mt-5 sm:mt-5 mt-3">
                <input
                  id=""
                  name="registeredNoUnitNumber"
                  type="checkbox"
                  className="cursor-pointer h-4 w-4 text-[#C00000] focus:ring-[#C00000] rounded check-box-custom"
                  value={props.individualProfile.registeredNoUnitNumber ? props.individualProfile.registeredNoUnitNumber : false}
                  onChange={(e) => props.handleChangeCheckBox(e)}
                  checked={props.individualProfile.registeredNoUnitNumber ? props.individualProfile.registeredNoUnitNumber : false}
                />
                <label
                  htmlFor="isRequireQuotation"
                  className="ml-5 block text-[#212529] text-justify inner-title-font-small-button mobile-14"
                >
                  No Unit Number
                </label>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                  Postal Code
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="companyAddress3"
                  value={props.individualProfile.registeredPostalCode}
                  identity="BasicInformation"
                  handleChange={props.handleChange}
                  // onBlur={(e) => props.validate("companyAddress3", props.individualProfile)}
                  disabled={true}
                  isCurserAllowed={true}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                />
                {/* <Validation error={props.validState.error.companyAddress3} /> */}
              </div>
            </div>
          </div>
          {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                 
                  Business Contact Number
                  <span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="businessContactNumber"
                  value={props.individualProfile.businessContactNumber}
                  identity="BasicInformation"
                  handleChange={props.handleChange}
                  onBlur={(e) => props.validate("businessContactNumber", props.individualProfile)}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                />
                <Validation error={props.validState.error.businessContactNumber} />
              </div>
            </div>
          </div> */}


          {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Company Member History (Email changes)
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="memberHistory"
                  value={props.individualBasicInformation.linkedIn}
                  identity="MemberHistory"
                  handleChange={props.handleChange}
                // onBlur={(e) => props.validate("linkedIn", props.individualBasicInformation)}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                />
                <Validation error={props.validState.error.linkedIn} />
              </div>
            </div>
          </div> */}

          {/* Mailing address 1 and postal code new added */}
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="xl:col-span-12 lg:col-span-12 col-span-12 input-sec-texts">
              <div className="left-tiitle">
                <h4 className="small-title 2xl:text-[22px] font-bold text-[#757575] ">
                  Mailing Address
                </h4>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts"></div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Block No & Street Name<span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="mailingAddress1"
                  value={props.individualProfile.mailingStreetName}
                  identity="mailingAddress1"
                  handleChange={props.handleChange}
                // onBlur={(e) => props.validate("mailingAddress1", props.individualProfile)}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                />
                <Validation error={props.validState.error.mailingAddress1} />
                {/* <Validation error={props.validState.error.mailingAddress} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Unit Number & Building Name
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="mailingAddress2"
                  value={props.individualProfile.mailingBuildingName}
                  identity="mailingAddress2"
                  handleChange={props.handleChange}
                // onBlur={(e) => props.validate("mailingAddress2", props.individualProfile)}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                />
                {/* <Validation error={props.validState.error.mailingAddress2} /> */}
                {/* <Validation error={props.validState.error.mailingAddress} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="flex items-start register-desc 2xl:mt-5 lg:mt-5 md:mt-5 sm:mt-5 mt-3">
                <input
                  id=""
                  name="registeredNoUnitNumber"
                  type="checkbox"
                  className="cursor-pointer h-4 w-4 text-[#C00000] focus:ring-[#C00000] rounded check-box-custom"
                  value={props.individualProfile.mailingNoUnitNumber ? props.individualProfile.mailingNoUnitNumber : false}
                  onChange={(e) => props.handleChangeCheckBox(e)}
                  checked={props.individualProfile.mailingNoUnitNumber ? props.individualProfile.mailingNoUnitNumber : false}
                />
                <label
                  htmlFor="isRequireQuotation"
                  className="ml-5 block text-[#212529] text-justify inner-title-font-small-button mobile-14"
                >
                  No Unit Number
                </label>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Postal Code
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="mailingAddress3"
                  value={props.individualProfile.mailingPostalCode}
                  identity="mailingAddress3"
                  handleChange={props.handleChange}
                // onBlur={(e) => props.validate("mailingAddress3", props.individualProfile)}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                />
                {/* <Validation error={props.validState.error.mailingAddress3} /> */}
                {/* <Validation error={props.validState.error.mailingAddress} /> */}
              </div>
            </div>
          </div>
          {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Mailing Address 2
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="mailingAddress2"
                  // value={props.individualBasicInformation.mailingAddress}
                  identity="mainingAddress2"
                // handleChange={props.handleChange}
                // onBlur={(e) => props.validate("linkedIn", props.individualBasicInformation)}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                />
              </div>
            </div>
          </div> */}

          {/* End mailing address 1 and postal code*/}

          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Status<span className="text-[#c00000]">*</span>
                </h2>
                <DropdownSelect
                  drpIdentity={"IndividualStatus"}
                  optionArray={props.individualStatus}
                  setFilterParameters={props.setFilterParameters}
                  value={props.individualProfile.individualStatusId}
                />
                <Validation error={props.validState.error.individualStatusId} />
              </div>
            </div>
          </div>
          {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  LinkedIn
                 
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="linkedIn"
                  value={props.individualProfile.linkedIn}
                  identity="BasicInformation"
                  handleChange={props.handleChange}
                // onBlur={(e) => props.validate("linkedIn", props.individualProfile)}
                // value={this.state.searchbyParent}
                // handleChange={(event) => {
                //   this.setState({ searchByName: event.target.value });
                // }}
                />
                
              </div>
            </div>
          </div> */}

          <div className="xl:col-span-12 lg:col-span-12 col-span-12 pr-16">
            <div className="flex items-center justify-between table-bg p-5 lg:py-3">
              <div className="font-bold text-xl text-white tble-th-txt text-left w-[5%]">
                No.
              </div>
              <div className="font-bold text-xl text-white tble-th-txt text-left w-[55%]">
                Email
              </div>
              <div className="font-bold text-xl text-white tble-th-txt w-[20%] text-left">
                Start Date
              </div>
              <div className="font-bold text-xl text-white tble-th-txt text-right w-[20%]">
                End Date
              </div>
            </div>
            {
              props.emailHistory.map((emailList, key) => (
                <div className="flex items-center justify-between p-5 lg:py-3" key={key}>
                  <div className="font-bold text-lg text-[#757575] tble-th-txt text-left w-[5%]">
                    {key + 1}
                  </div>
                  <div className="font-bold text-lg text-[#757575] tble-th-txt text-left w-[55%]">
                    {emailList.previousEmail}
                  </div>
                  <div className="font-bold text-lg text-[#757575] tble-th-txt text-left w-[20%]">
                    {moment(emailList.createdAt).format("DD/MM/YYYY")}
                    {/* {emailList.createdAt} */}
                  </div>
                  <div className="font-bold text-lg text-[#757575] tble-th-txt text-right w-[20%]">
                    {emailList.deletedAt ?
                      moment(emailList.deletedAt).format("DD/MM/YYYY")

                      : null}
                    {/* {emailList.deletedAt} */}
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      {/* Save Button */}
      <div className="grid grid-cols-12 gap-6 pb-10">
        <div className="xl:col-span-12 lg:col-span-12 col-span-12 px-10">
          <a
            className="flex items-center justify-end"
            href="#"
          >
            <button className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold"
              onClick={() => props.onSave()}
            >
              Save
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
export default IndividualBasicInformation;