import React, { Component } from "react";
import CommonServices from "../../services/axiosServices/apiServices/CommonServices";
import SwalServices from "../../services/swalServices/SwalServices";
import ActionContainer from "../../components/UIComponent/ActionTableComponent/actionContainer";
import ActionEditButton from "../../components/UIComponent/ActionTableComponent/ActionEditButton";
import AuthorityLimitServices from "../../services/axiosServices/apiServices/AuthorityLimitServices";
import Table from "../../components/table/Table";
import { Navigate } from "react-router-dom";
import ModalBasic from "../component/ModalBasic";
import DropdownSelect from "../../components/dropdown/Dropdown";
import ActionDeleteButton from "../../components/UIComponent/ActionTableComponent/ActionDeleteButton";
import { CommonValidationMessages, CommonSuccessMessages } from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import ValidationText from "../../utils/validation/ValidationText";

export default class AuthorityLimit extends Component {
  constructor(props) {
    super(props);
    //this.CorporateMemberServices = new CorporateMemberServices();
    // this.CommonServices = new CommonServices();
    this.authorityLimitServices = new AuthorityLimitServices();
    this.CommonServices = new CommonServices();
    this.swalServices = new SwalServices();

    this.state = {
      membershipType: [],
      membershipRevenue: [],
      membershipCategory: [],
      membershipFeeType: [],
      membershipTenure: [],
      percentageDiscount: [
        { id: 1, name: 10 },
        { id: 2, name: 20 },
        { id: 3, name: 30 },
        { id: 4, name: 40 },
        { id: 5, name: 50 },
      ],
      value: 10,
      pagination: {
        authorityLimitId: 0,
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        status: 0,
        filterbyMembershipType: 0,
        filterbyMembershipTenure: 0,
        filterByRevenue: 0,
        filterByCategory: 0,
        //filterByPercentageDiscount: 0,
        filterByMembershipFeeType: 0,
      },
      addData: {
        authorityLimitId: 0,
        membershipTypeId: 0,
        membershipRevenueId: 0,
        membershipFeeTypeId: 1,
        membershipCategoryId: 0,
        discountPercentage: 0,
        chapterPrice: "",
        membershipTenureId: 0,
        freeChapter: "",
      },
      totalResultes: "",
      getAuthorityLimitList: [],
      // getPagesIds: [],
      isLoading: false,
      editId: null,
      deleteId: null,
      setAddeditModalOpen: false,
      chapters: [
        { id: 1, name: 1 },
        { id: 2, name: 2 },
        { id: 3, name: 3 },
        { id: 4, name: 4 },
        { id: 5, name: 5 },
      ],
      validationRule: {
        membershipTypeId: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "Membership Type"
            ),
          },
        ],
        membershipRevenueId: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "Membership Revenue"
            ),
          },
        ],
        membershipTenureId: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "Membership Tenure"
            ),
          },
        ],
        membershipCategoryId: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "Membership Category"
            ),
          },
        ],
        // discountPercentage:[
        //   {
        //     type: 'require',
        //     message: CommonValidationMessages.FieldRequired.replace('{0}', 'discountPercentage')
        //   }
        // ]
      },
      validState: {
        isValid: true,
        error: {},
      },
    };
  }

  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.addData,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };
  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.addData,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getAuthorityLimitList();
  };

  getAllMembershipType = () => {
    this.authorityLimitServices.getAllMembershipType().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({
          membershipType: response.responseItem.responseContent,
        }, () => {
          this.getAuthorityLimitList();

        });
      } else {
        this.getAuthorityLimitList();

        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };
  getAllMembershipRevenue = () => {
    this.authorityLimitServices.getAllMembershipRevenue().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({
          membershipRevenue: response.responseItem.responseContent,
        }, () => {
          this.getAllMembershipCategory();

        });
      } else {
        this.getAllMembershipCategory();

        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };
  getAllMembershipCategory = () => {
    this.authorityLimitServices.getAllMembershipCategory().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({
          membershipCategory: response.responseItem.responseContent,
        }, () => {
          this.getAllMembershipFeeType();

        });
      } else {
        this.getAllMembershipFeeType();

        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  getAllMembershipFeeType = () => {
    this.authorityLimitServices.getAllMembershipFeeType().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({
          membershipFeeType: response.responseItem.responseContent,
        }, () => {
          this.getAllMembershipTenure();
        });
      } else {
        this.getAllMembershipTenure();
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  getAllMembershipTenure = () => {
    this.authorityLimitServices.getAllMembershipTenure().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({
          membershipTenure: response.responseItem.responseContent,
        });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  getAuthorityLimitById = (id) => {
    this.authorityLimitServices.getAuthorityLimitById(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        // this.state.addData = response.responseItem.responseContent;
        this.setState({ addData: response.responseItem.responseContent });
        this.setState({ setAddeditModalOpen: true });
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  // deleteAuthorityLimit = (id) => {
  //     this.authorityLimitServices.deleteAuthorityLimit(id).then((response) => {
  //         if (response.statusCode === 200 && response.responseItem != null) {
  //             this.swalServices.Success(response.message);
  //             this.getAuthorityLimitList();
  //         }
  //         else {
  //             this.swalServices.Error(response.message);
  //         }
  //     })
  // }

  getAuthorityLimitList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });
    this.authorityLimitServices
      .getAuthorityLimitList(pagination)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let newGetAuthorityLimitList =
            response.responseItem.responseContent.itemList;
          this.setState({ getAuthorityLimitList: newGetAuthorityLimitList });
          this.setState({
            totalResultes: response.responseItem.responseContent.totalCount,
          }, () => {
            this.getAllMembershipRevenue();

          });
        } else {
          this.getAllMembershipRevenue();

          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  addAuthorityLimits = () => {
    let isValidInput = this.isValidateAllFields();
    if (isValidInput) {
      this.authorityLimitServices
        .addEditAuthorityLimit(this.state.addData)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent.errorType === 'EXISTS') {
              this.swalServices.Error('This type of authority limit is already exist.');
            }
            else {
              this.swalServices.Success(response.message);
              this.setState({ setAddeditModalOpen: false });
              this.getAuthorityLimitList();
            }
          } else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoading: false });
        });
    }
  };

  setFilterParameters = (id, drpIdentity) => {
    let detail = this.state.pagination;
    if (drpIdentity === "MembershipType") {
      detail["filterbyMembershipType"] = id;
    }
    if (drpIdentity === "MembershipTenure") {
      detail["filterbyMembershipTenure"] = id;
    }
    if (drpIdentity === "MembershipRevenue") {
      detail["filterByRevenue"] = id;
    }
    if (drpIdentity === "MembershipTenure") {
      detail["filterbyMembershipTenure"] = id;
    }
    if (drpIdentity === "MembershipCategory") {
      detail["filterByCategory"] = id;
    }
    if (drpIdentity === "filterMembershipFeeType") {
      detail["filterByMembershipFeeType"] = id;
    }
    this.setState({ pagination: { ...detail } });
  };

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.addData;
    detail[name] = value;
    this.setState({ addData: { ...detail } });
  }

  handleChangePercentage(event) {
    var value = Number(event.target.value);
    const name = event.target.name;
    if (isNaN(value)) {
      value = 0;
    }
    let detail = this.state.addData;
    detail[name] = value;
    this.setState({ addData: { ...detail } });
  }

  addAuthorityLimit = (id, drpIdentity) => {
    let detail = this.state.addData;
    if (drpIdentity === "MembershipType") {
      detail["membershipTypeId"] = id;
      this.validateField("membershipTypeId");
    }
    if (drpIdentity === "MembershipRevenue") {
      detail["membershipRevenueId"] = id;
      this.validateField("membershipRevenueId");
    }
    if (drpIdentity === "MembershipTenureType") {
      detail["membershipTenureId"] = id;
      this.validateField("membershipTenureId");
    }
    if (drpIdentity === "MembershipFeeType") {
      detail["membershipFeeTypeId"] = id;
    }
    if (drpIdentity === "MembershipCategory") {
      detail["membershipCategoryId"] = id;
      this.validateField("membershipCategoryId");
    }
    if (drpIdentity === "Chapters") {
      detail["chapterPrice"] = id;
    }
    this.setState({ addData: { ...detail } });
  };

  onEdit = (value, index) => {
    let editDetails = [...this.state.getAuthorityLimitList];
    this.handleReset();
    this.resetValidStatetAddeditModal();
    // this.state.editId = editDetails[index].authorityLimitId;
    this.setState({ editId: editDetails[index].authorityLimitId }, () => {
      this.getAuthorityLimitById(this.state.editId);
    });

  };

  onDelete = (value) => {
    let request = {
      id: value,
      deletedBy: 115,
      appId: 15,
    };

    this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Authority limit"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "Authority limit"),
      CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
        if (response) {
          this.authorityLimitServices
            .deleteAuthorityLimit(request)
            .then((response) => {
              if (response.statusCode === 200) {
                this.swalServices.Success("Deleted");
                this.getAuthorityLimitList();
              } else {
                this.swalServices.Error(response.message);
              }
              this.setState({ isLoading: false });
            });

        }
      })
  };

  actions = (element, index, value) => {
    return element !== "authorityLimitId" ? null : (
      <td>
        <ActionContainer>
          <ActionEditButton
            value={value}
            id={index}
            index={index}
            onActionClick={this.onEdit.bind(this)}
            text="Edit"
          />
          <ActionDeleteButton
            value={value}
            id={index}
            index={index}
            onActionClick={this.onDelete}
            text="Delete"
          />
        </ActionContainer>
      </td>
    );
  };

  handleReset = () => {
    let reset = {
      ...this.state.addData,
      authorityLimitId: 0,
      membershipTypeId: 0,
      membershipRevenueId: 0,
      membershipFeeTypeId: 1,
      membershipCategoryId: 0,
      discountPercentage: 0,
      chapterPrice: "",
      membershipTenureId: 0,
      freeChapter: "",
    };
    this.setState({ addData: reset });
  };

  resetValidStatetAddeditModal = () => {
    let state = this.state.validState;
    state.isValid = true;
      state.error = {};
    this.setState({ validState: state })
  }

  saveEditAuthority = () => {
    this.addAuthorityLimits();
    // this.handleReset();
  };

  filterByParameters = () => {
    let detail = this.state.pagination;
    detail.pageNo = 1;
    detail.pageSize = 10;
    this.setState({ pagination: { ...detail } }, () =>
      this.getAuthorityLimitList()
    );
  };

  componentDidMount() {

    this.getAllMembershipType();
    // this.getAuthorityLimitList();
    // this.getAllMembershipRevenue();
    // this.getAllMembershipCategory();
    // this.getAllMembershipFeeType();
    // this.getAllMembershipTenure();
  }

  change = (event) => {
    this.setState({ value: event.target.value });
  };
  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="main-body-section">
        <div className="px-6 pt-10 pb-12 bg-white">
          <div className="grid grid-cols-12 gap-6 w-full">
            <div className="2xl:col-span-3 lg:col-span-6 col-span-12">
              <h2 className="text-xl theme-color mb-1">
                Filter by Membership Type
              </h2>
              <DropdownSelect
                drpIdentity={"MembershipType"}
                optionArray={this.state.membershipType}
                setFilterParameters={this.setFilterParameters}
                value={this.state.pagination.filterbyMembershipType}
              />
            </div>
            <div className="2xl:col-span-3 lg:col-span-6 col-span-12">
              <h2 className="text-xl theme-color mb-1">
                Filter by Membership Tenure
              </h2>
              <DropdownSelect
                drpIdentity={"MembershipTenure"}
                optionArray={this.state.membershipTenure}
                setFilterParameters={this.setFilterParameters}
                value={this.state.pagination.filterbyMembershipTenure}
              />
            </div>
            <div className="2xl:col-span-3 lg:col-span-6 col-span-12">
              <h2 className="text-xl theme-color mb-1">
                Filter by Revenue
              </h2>
              <DropdownSelect
                drpIdentity={"MembershipRevenue"}
                optionArray={this.state.membershipRevenue}
                setFilterParameters={this.setFilterParameters}
                value={this.state.pagination.filterByRevenue}
              />
            </div>
            <div className="2xl:col-span-3 lg:col-span-6 col-span-12 pt-10 pl-10">
              <button
                className="btn btn-red text-xl text-white font-bold"
                onClick={() => {
                  this.filterByParameters();
                }}
              >
                Search
              </button>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full pt-12">
            <div className="2xl:col-span-3 lg:col-span-6 col-span-12">
              <h2 className="text-xl theme-color mb-1">
                Filter by Category
              </h2>
              <DropdownSelect
                drpIdentity={"MembershipCategory"}
                optionArray={this.state.membershipCategory}
                setFilterParameters={this.setFilterParameters}
                value={this.state.pagination.filterByCategory}
              />
            </div>
            <div className="2xl:col-span-3 lg:col-span-6 col-span-12">
              {/* <h2 className="text-xl theme-color mb-1">
                Percentage Discount
              </h2>
              <DropdownSelect
                drpIdentity={"PercentageDiscount"}
                optionArray={this.state.percentageDiscount}
                setFilterParameters={this.setFilterParameters}
                value={this.state.pagination.filterByPercentageDiscount}
              /> */}
              <h2 className="text-xl theme-color mb-1">
                Filter by Fee
              </h2>
              <DropdownSelect
                drpIdentity={"filterMembershipFeeType"}
                optionArray={this.state.membershipFeeType}
                setFilterParameters={this.setFilterParameters}
                value={this.state.pagination.filterByMembershipFeeType}
              />


            </div>
            <div className="2xl:col-span-3 lg:col-span-6 col-span-12">
              {/* <h2 className="text-xl theme-color mb-1">
                Percentage Discount
              </h2>
              <select className="w-full" id="cars" onChange={this.change} value={this.state.value}
              {this.state.percentageDiscount.map((item,i)=>(
              <option key={i} value={item.id}>{item.discount}</option>
              ))}
              </select> */}
            </div>
          </div>
        </div>
        <main className="pb-10">
          <div className="w-full mx-auto">
            <div className="relative">
              <div className="pt-10 flex justify-end">
                <button
                  className="btn btn-red text-xl text-white font-bold"
                  aria-controls="add-edit-modal"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ setAddeditModalOpen: true });
                    this.handleReset();
                    this.resetValidStatetAddeditModal();
                  }}
                >
                  Add New Record
                </button>
              </div>
              <div className=" mt-6">
                <Table
                  columns={[
                    {
                      name: "membershipTypeName",
                      title: "Membership Type",
                    },
                    {
                      name: "membershipTenureName",
                      title: "Membership Tenure",
                    },
                    {
                      name: "membershipRevenueName",
                      title: "Revenue",
                    },
                    {
                      name: "membershipFeeTypeName",
                      title: "Fee Type",
                    },
                    {
                      name: "membershipCategoryName",
                      title: "Category",
                    },
                    {
                      name: "discountPercentage",
                      title: "Percentage Discount",
                    },
                    {
                      name: "authorityLimitId",
                      title: "Actions",
                    },
                  ]}
                  pagination={this.state.pagination}
                  rows={this.state.getAuthorityLimitList}
                  sortingColumns={[
                    "membershipTypeName",
                    "membershipTenureName",
                    "membershipRevenueName",
                    "membershipFeeTypeName",
                    "membershipCategoryName",
                    "discountPercentage",
                    "",
                  ]}
                  isLoading={this.state.isLoading}
                  totalResultes={this.state.totalResultes}
                  totalCount={this.state.totalResultes}
                  setPagination={this.setPagination.bind(this)}
                  customScope={[
                    {
                      column: "authorityLimitId",
                      renderTableData: this.actions.bind(this),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="authority-limit-new-model">
            <ModalBasic
              id="add-edit-modal"
              modalOpen={this.state.setAddeditModalOpen}
              setModalOpen={() => this.setState({ setAddeditModalOpen: false })}
              title="New Authority Limit"
            >
              <div className="border-t border-[#757575]-300"></div>
              <div className="px-14 py-4">
                <div className="grid grid-cols-12 gap-10">
                  <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                    <h2 className="text-xl theme-color mb-1">
                      Type of Membership
                    </h2>
                    <DropdownSelect
                      drpIdentity={"MembershipType"}
                      optionArray={this.state.membershipType}
                      setFilterParameters={this.addAuthorityLimit.bind(this)}
                      value={this.state.addData.membershipTypeId}
                      onBlur={() => this.validateField("membershipTypeId")}
                    />
                    <div>
                      <ValidationText
                        error={this.state.validState.error.membershipTypeId}
                      />
                    </div>
                  </div>
                  <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                    <h2 className="text-xl theme-color mb-1">
                      Membership Tenure
                    </h2>
                    <DropdownSelect
                      drpIdentity={"MembershipTenureType"}
                      optionArray={this.state.membershipTenure}
                      setFilterParameters={this.addAuthorityLimit.bind(this)}
                      value={this.state.addData.membershipTenureId}
                      onBlur={() => this.validateField("membershipTenureId")}
                    />
                    <div>
                      <ValidationText
                        error={this.state.validState.error.membershipTenureId}
                      />
                    </div>
                  </div>

                  <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                    <h2 className="text-xl theme-color mb-1">
                      Revenue
                    </h2>
                    <DropdownSelect
                      drpIdentity={"MembershipRevenue"}
                      optionArray={this.state.membershipRevenue}
                      setFilterParameters={this.addAuthorityLimit.bind(this)}
                      value={this.state.addData.membershipRevenueId}
                      onBlur={() => this.validateField("membershipRevenueId")}
                    />
                    <div>
                      <ValidationText
                        error={this.state.validState.error.membershipRevenueId}
                      />
                    </div>
                  </div>
                  <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                    <h2 className="text-xl theme-color mb-1">
                      Type of Fee
                    </h2>
                    <DropdownSelect
                      drpIdentity={"MembershipFeeType"}
                      optionArray={this.state.membershipFeeType}
                      setFilterParameters={this.addAuthorityLimit.bind(this)}
                      value={this.state.addData.membershipFeeTypeId}
                    />
                  </div>
                  <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                    <h2 className="text-xl theme-color mb-1">
                      Category
                    </h2>
                    <DropdownSelect
                      drpIdentity={"MembershipCategory"}
                      optionArray={this.state.membershipCategory}
                      setFilterParameters={this.addAuthorityLimit.bind(this)}
                      value={this.state.addData.membershipCategoryId}
                      onBlur={() => this.validateField("membershipCategoryId")}
                    />
                    <div>
                      <ValidationText
                        error={this.state.validState.error.membershipCategoryId}
                      />
                    </div>
                  </div>

                  {this.state.addData.membershipFeeTypeId === 1 ||
                    this.state.addData.membershipFeeTypeId === 2 ? (
                    <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                      <h2 className="text-xl theme-color mb-1">
                        Discount Percentage
                      </h2>
                      <div className="prasantage-input">
                        <input
                          type="text"
                          className=" form-input w-full position-reletive rounded-none"
                          name="discountPercentage"
                          onChange={(e) => this.handleChangePercentage(e)}
                          value={this.state.addData.discountPercentage}
                          placeholder=""
                          aria-label=""
                        />
                      </div>
                    </div>
                  ) : null}

                  {this.state.addData.membershipFeeTypeId === 3 ? (
                    <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                      <h2 className="text-xl theme-color mb-1">
                        Number of Chapters
                      </h2>
                      {/* <input
                      type="text"
                      className=" form-input w-full position-reletive rounded-none"
                      name="freeChapter"
                      onChange={(e) => this.handleChange(e)}
                      value={this.state.addData.freeChapter}

                      placeholder=""
                      aria-label=""
                    /> */}
                      <DropdownSelect
                        drpIdentity={"Chapters"}
                        optionArray={this.state.chapters}
                        setFilterParameters={this.addAuthorityLimit}
                        value={this.state.addData.chapterPrice}
                      />
                    </div>
                  ) : null}

                  {this.state.addData.membershipFeeTypeId === 4 ? (
                    <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                      <h2 className="text-xl theme-color mb-1">
                        Chapters Price
                      </h2>
                      <div className="sign-icon-input">
                        {/* <div className="dollar-input">
                          <input
                            type="text"
                            className=" form-input w-full position-reletive rounded-none"
                            name="chapterPrice"
                            onChange={(e) => this.handleChange(e)}
                            value={this.state.addData.chapterPrice}
                            placeholder=""
                            aria-label=""
                          />
                          </div> */}
                        <div className="prasantage-input">
                          <input
                            type="text"
                            className=" form-input w-full position-reletive rounded-none"
                            name="chapterPrice"
                            onChange={(e) => this.handleChangePercentage(e)}
                            value={this.state.addData.chapterPrice}
                            placeholder=""
                            aria-label=""
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="flex flex-wrap justify-center space-x-10 mt-12 mb-2">
                  <button
                    className="w-32 px-7 py-2 btn btn-red text-base text-white font-bold"
                    onClick={() => {
                      this.saveEditAuthority();
                    }}
                  >
                    Add/Save
                  </button>
                </div>
              </div>
            </ModalBasic>
          </div>
        </main>
      </div>
    );
  }
}