import React from 'react'
import { DeleteButtonIcon } from '../SVGConstants';

export default function StrategicMemberTable(props) {

    return (
        <div className='w-full overflow-x-auto'>
            <table className="w-full bg-white shadow-lg rounded-sm border border-slate-200">
                {/* Table header */}
                <thead className="table-bg text-xs font-semibold capitalize text-[#757575] border-t border-b border-slate-200 ">
                    <tr>
                        <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white border border-[#757575]">
                            <div className="font-bold tble-th-txt text-left">
                                Strategic Member
                            </div>
                        </th>
                        <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white border border-[#757575]">
                            <div className="font-bold tble-th-txt text-left">
                                Contact Person
                            </div>
                        </th>
                        <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white border border-[#757575]">
                            <div className="font-bold tble-th-txt text-left">
                                Designation
                            </div>
                        </th>
                        <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white border border-[#757575]">
                            <div className="font-bold tble-th-txt text-left">
                                Email Address
                            </div>
                        </th>
                        <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white border border-[#757575]">
                            <div className="font-bold tble-th-txt text-left">
                                Contact Information
                            </div>
                        </th>
                        <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white border border-[#757575]">
                            <div className="font-bold tble-th-txt text-left">
                                Actions
                            </div>
                        </th>
                    </tr>
                </thead>
                {/* Table body */}
                <tbody className="text-sm custom-tbody">
                    {props.strategicMemberList.map((officer, key) => (
                        <tr key={key}>
                            <td className='px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] border border-[#757575]'>
                                <div className='flex flex-col'>
                                    <div className='current-text'>
                                        {officer.companyName}
                                    </div>
                                </div>
                            </td>
                            <td className='px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] border border-[#757575]'>
                                <div className='flex flex-col'>
                                    <div className='current-text'>
                                        {/* {officer.firstName + officer.lastName} */}
                                        {officer.keyPerson}
                                    </div>
                                </div>
                            </td>
                            <td className='px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] border border-[#757575]'>
                                <div className='flex flex-col'>
                                    <div className='current-text'>
                                        {officer.designation}
                                    </div>
                                </div>
                            </td>
                            <td className='px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] border border-[#757575]'>
                                <div className='flex flex-col'>
                                    <div className='current-text'>
                                        {officer.email}
                                    </div>
                                </div>
                            </td>
                            <td className='px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] border border-[#757575]'>
                                <div className='flex flex-col'>
                                    <div className='current-text'>
                                        {officer.contactNo}

                                    </div>
                                </div>
                            </td>
                            <td className='px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] border border-[#757575]'>
                                <div className='flex items-center justify-center'>

                                    <button className='action-btn right-icon'
                                        onClick={() => props.delete(officer.projectStrategycMemberMappingId,officer.strategicMemberId, officer.projectId)}
                                    >
                                        <DeleteButtonIcon />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}

                    {/* <tr>
                        <td
                            // colSpan={props.columns.length}
                            className="text-center p-4 text-lg"
                        >
                            <i className="fas fa-empty-set pr-2"></i>No Records Found
                        </td>
                    </tr>  */}
                </tbody>
            </table>
        </div>
    )
}
