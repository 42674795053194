import React, { Component } from 'react'
import Table from '../../components/table/Table';
import ExportButton from '../../components/UIComponent/ActionTableComponent/ActionExportButton';
import ActionEditButton from '../../components/UIComponent/ActionTableComponent/ActionEditButton';
import ActionDeleteButton from '../../components/UIComponent/ActionTableComponent/ActionDeleteButton';
import { CommonSuccessMessages } from "../../utils/Messages";
import DropdownSelect from '../../components/dropdown/Dropdown';
import { Navigate } from 'react-router-dom';
import moment from 'moment';

//Import API services
import EmailManagementServices from '../../services/axiosServices/apiServices/EmailManagementServices';
import SwalServices from '../../services/swalServices/SwalServices';


export default class EmailHistory extends Component {
  constructor(props) {
    super(props);
    this.EmailManagementServices = new EmailManagementServices();
    this.SwalServices = new SwalServices();
    this.state = {
      totalResultes: 0,
      isLoading: false,
      redirect: null,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        status: 0,
        dateFrom: null,
        dateTo: null,
        filterByRecipient: 0,
      },
      emailHistoryList: [],
      recipientList: [],
    }
  }

  componentDidMount() {
    this.getEmailHistoryList();
  }

  getEmailHistoryList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });
    this.EmailManagementServices.getEmailHistoryList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ totalResultes: response.responseItem.responseContent.totalCount });

        let listArr = response.responseItem.responseContent.itemList;
        const swapValue = (details) => {
          Object.keys(details).forEach(key => {
            if (details[key].createdAt !== null) {
              details[key].createdAt = moment(details[key].createdAt).format("DD MMM YYYY");
            }
          });
        };
        swapValue(listArr);
        this.setState({ emailHistoryList: listArr });

      } else {
        this.SwalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };


  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getAllTaskList(newPagination);
  };

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.pagination;
    detail[name] = value;
    this.setState({ pagination: { ...detail } });
    this.getEmailHistoryList();
  };



  onDropdown = (id, drpIdentity) => {
    let details = this.state.pagination
    if (drpIdentity === 'Recipient') {
      details["filterByRecipient"] = id;
    }
    this.setState({ pagination: { ...details } });
    // this.getAllEmailList();
  };

  searchByName = (value) => {
    let detail = this.state.pagination;
    detail.searchText = "";
    this.setState({ pagination: { ...detail } });
    if (value.length > 2) {
      detail.searchText = value;
      this.setState({ pagination: { ...detail } });
    }
    this.getEmailHistoryList();
  };

  onEdit = (value, index) => {
    let editEmailHistory = [...this.state.emailHistoryList];
    this.setState({ redirect: '/CreateEmail/' + editEmailHistory[index].emailManagementId });
  };
  // onDelete = (value, index) => {

  // }
  onDelete = (id) => {

    if (id !== 1) { // Todo : remove this condition in production.
      this.SwalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Email Details"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "email details"),
        CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
          if (response) {
            this.EmailManagementServices.deleteEmailDetailsById(id).then((response) => {
              if (response.statusCode === 200 && response.responseItem != null) {
                this.SwalServices.Success("Email details deleted successfully.");
                this.getEmailHistoryList();
              }
              else {
                this.SwalServices.Error(response.message);
              }
              this.setState({ isLoading: false });
            })
          }
        })
    }
    else {
      this.SwalServices.Error("This is main email details you can't delete it.");
    }

  }
  //Method declaration section end

  actions = (element, index, value) => {
    return element !== "emailManagementId" ? null : (
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap pointer">
        <div className="flex items-center relative">
          <ExportButton redirect="/" text="Export" />

          <ActionEditButton
            value={value}
            id={index}
            index={index}
            onActionClick={this.onEdit.bind(this)}
            text="Edit"
          />

          <ActionDeleteButton
            value={value}
            id={index}
            index={index}
            onActionClick={this.onDelete.bind(this)}
            text="Delete"
          />
        </div>
      </td>
    );
  };

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="main-body-section">
        <div className="flex">
          <div className="relative flex flex-col flex-1 "></div>
        </div>
        <div className="bg-white pl-8 pt-10 pb-12">
          <div className="grid grid-cols-12 gap-6 w-full items-center">
            <div className="2xl:col-span-6 lg:col-span-6 col-span-12 pr-7">
              <h2 className="text-xl theme-color">Date</h2>
              <div className="grid grid-cols-12 gap-6 items-center w-full">
                <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                  <input
                    id="default"
                    name="dateFrom"
                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                    type="date"
                    value={this.state.pagination.dateFrom}
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>
                <div className=" 2xl:col-span-2 lg:col-span-2 col-span-12 w-full text-center">
                  <h2 className="text-xl theme-color">To</h2>
                </div>
                <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                  <input
                    id="default"
                    name="dateTo"
                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                    type="date"
                    value={this.state.pagination.dateTo}
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
              <h2 className="pl-12 block text-xl font-medium  theme-color">
                Recipients
              </h2>
              <div className="pl-12 border-l-[1px] border-[#757575]">
                <DropdownSelect
                  drpIdentity="Recipient"
                  optionArray={this.state.recipientList}
                  setFilterParameters={this.onDropdown.bind(this)}
                  value={this.state.pagination.filterByRecipient}
                />
                {/* <Selector
                  options={this.state.recipientList}
                  isMulti
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  components={{
                    Option,
                  }}
                  onChange={this.handleChangeInDropdown}
                  allowSelectAll={true}
                  // value={this.state.emailDetails.sgTechTVCategoryId}
                  // value={this.state.categoryList.find(item => item.value === this.state.pagination.filterByCategory)}
                  drpIdentity="RecipientsList"
                /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-6 gap-4 pt-10 px-8">
          <h2 className="col-start-1 col-end-3 text-3xl font-bold text-[#757575]">
            {`Total:${this.state.emailHistoryList.length}`}
          </h2>
          <div className="col-end-7 col-span-2 grid grid-cols-3 items-center">
            <caption className="text-[#757575] text-xl">Search</caption>
            <input
              id="default"
              className="col-span-2 text-lg font-bold  h-[55px] w-full text-[#757575] border-white"
              type="text"
              onChange={(e) => this.searchByName(e.target.value)}
            />
          </div>
        </div>
        <main className="pb-10">
          <div className="w-full mx-auto">
            <div className="relative">
              <div>
                <div className=" mt-6">
                  <Table
                    columns={[
                      { name: "subject", title: "Subject", },
                      { name: "includeEmail", title: "Send to" },
                      { name: "status", title: "Status" },
                      { name: "createdAt", title: "Date" },
                      { name: "emailManagementId", title: "Action" },
                    ]}
                    pagination={this.state.pagination}
                    rows={this.state.emailHistoryList}
                    sortingColumns={["subject", "status", "date"]}
                    isLoading={this.state.isLoading}
                    totalResultes={this.state.emailHistoryList.length}
                    totalCount={this.state.totalResultes}
                    setPagination={this.setPagination.bind(this)}
                    customScope={[
                      {
                        column: "emailManagementId",
                        renderTableData: this.actions.bind(this),
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    )
  }
}