import {
    AddEditEngagementTracking,
    AddEditIndividualBasicInformation,
    AddEditIndividualCommittee,
    AddEditIndividualContribution,
    AddEditIndividualMemberSubscription,
    AddEditIndividualSIISInformation,
    GetAllDropdownsForIndividualMember,
    GetIndividualBasicInformation,
    GetIndividualCommittee,
    GetIndividualContributionList,
    GetIndividualEngagementTrackingById,
    GetIndividualEngagementTrackingList,
    GetIndividualMemberSubscription,
    GetIndividualSIISInformation,
    GetIndividualMemberList,
    ActiveInActiveIndividualMember,
    DeleteIndividualMember,
    DeleteCommittees,
    AddEditSGTechInterestProfile,
    AddEditCommunicationProfile,
    GetIndividualSGTechInterest,
    GetIndividualCommunication,
    GetAllIndividualProfileDetail,
    GetEmploymentInformation,
    AddEditEmploymentInformation,
    StoreBase64Image,
    GetChapterInformationById,
    GetCorporateMembershipSubscriptionById,
    GetEmailHistoryForIndividualMemberById,
    GetBookingListForIndividualMemberById,
    GetInvoiceReceiptPdfByBookingId,
    DeleteIndividualContribution
} from "../ApiEndPoints";
import { axiosGetAuthorize, axiosGetFileAuthorizeblob, axiosPostAuthorize, axiosPostWithoutEncryption } from "../AxiosRequests";

export default class IndividualMemberService {
    async getAllDropdownForIndividualMember() {
        return axiosGetAuthorize(GetAllDropdownsForIndividualMember);
    }
    async getEngagementTrackingList(request) {
        return axiosGetAuthorize(GetIndividualEngagementTrackingList, request);
    }
    // async addEditEngagementTracking(request){
    //     return axiosPostAuthorize(AddEditEngagementTracking,request)
    // }
    async getEngagmentDetailsById(request) {
        return axiosGetAuthorize(GetIndividualEngagementTrackingById, request)
    }
    async addEditIndividualBasicInformation(request) {
        return axiosPostAuthorize(AddEditIndividualBasicInformation, request);
    }
    async addEditSIISInformation(request) {
        return axiosPostAuthorize(AddEditIndividualSIISInformation, request);
    }
    async getBasicInformation(request) {
        return axiosGetAuthorize(GetIndividualBasicInformation, request)
    }
    async getSIISInformation(request) {
        return axiosGetAuthorize(GetIndividualSIISInformation, request)
    }
    async joinCommittee(request) {
        return axiosPostAuthorize(AddEditIndividualCommittee, request)
    }
    async getJoinedCommittee(request) {
        return axiosGetAuthorize(GetIndividualCommittee, request)
    }
    async addEditMemberSubscription(request) {
        return axiosPostAuthorize(AddEditIndividualMemberSubscription, request)
    }
    async getMembershipSubscription(request) {
        return axiosGetAuthorize(GetIndividualMemberSubscription, request);
    }
    async getIndividualContribution(request) {
        return axiosGetAuthorize(GetIndividualContributionList, request);
    }
    async saveContribution(request) {
        return axiosPostAuthorize(AddEditIndividualContribution, request);
    }

    async getIndividualMemberList(request) {
        return axiosPostAuthorize(GetIndividualMemberList, request);
    }
    async activeInActiveIndividualMember(request) {
        return axiosPostAuthorize(ActiveInActiveIndividualMember, request);
    }

    async deleteIndividualMember(request) {
        return axiosPostAuthorize(DeleteIndividualMember, request);
    }
    async deleteCommittees(request) {
        return axiosPostAuthorize(DeleteCommittees, request);
    }
    async deleteIndividualContribution(request) {
        return axiosPostAuthorize(DeleteIndividualContribution, request);
    }



    // new API endpoints

    async addEditIndividualSIISInformation(request) {
        return axiosPostAuthorize(AddEditIndividualSIISInformation, request);
    }
    async addEditEngagementTracking(request) {
        return axiosPostAuthorize(AddEditEngagementTracking, request);
    }
    async addEditIndividualCommittee(request) {
        return axiosPostAuthorize(AddEditIndividualCommittee, request)
    }
    async addEditSGTechInterestProfile(request) {
        return axiosPostAuthorize(AddEditSGTechInterestProfile, request);
    }
    async addEditCommunicationProfile(request) {
        return axiosPostAuthorize(AddEditCommunicationProfile, request);
    }
    async addEditIndividualContribution(request) {
        return axiosPostAuthorize(AddEditIndividualContribution, request);
    }
    async getIndividualCommittee(request) {
        return axiosGetAuthorize(GetIndividualCommittee, request);
    }
    async getIndividualContributionList(request) {
        return axiosGetAuthorize(GetIndividualContributionList, request);
    }
    async getIndividualSGTechInterest(request) {
        return axiosGetAuthorize(GetIndividualSGTechInterest, request);
    }
    async getIndividualCommunication(request) {
        return axiosGetAuthorize(GetIndividualCommunication, request);
    }
    async getIndividualEngagementTrackingById(request) {
        return axiosGetAuthorize(GetIndividualEngagementTrackingById, request);
    }
    async addEditIndividualProfile(request) {
        return axiosPostAuthorize(AddEditIndividualBasicInformation, request);
    }
    async getIndividualProfileDetails(request) {
        return axiosGetAuthorize(GetAllIndividualProfileDetail, request);
    }
    async getEmploymentInformation(request) {
        return axiosGetAuthorize(GetEmploymentInformation, request);
    }
    async addEditEmploymentInformation(request) {
        return axiosPostAuthorize(AddEditEmploymentInformation, request);
    }
    async getIndividualSIISInformation(request) {
        return axiosGetAuthorize(GetIndividualSIISInformation, request);
    }

    async storeBase64Image(request) {
        return axiosPostWithoutEncryption(StoreBase64Image, request, false);
    }

    async getChapterInformationById(request) {
        return axiosGetAuthorize(GetChapterInformationById, request)
    }
    async getCorporateMembershipSubscriptionById(request) {
        return axiosGetAuthorize(GetCorporateMembershipSubscriptionById, request)
    }
    async getEmailHistoryForIndividualMemberById(request) {
        return axiosGetAuthorize(GetEmailHistoryForIndividualMemberById, request)
    }
    async getBookingListForIndividualMemberById(request) {
        return axiosPostAuthorize(GetBookingListForIndividualMemberById, request);
    }

    async getInvoiceReceiptPdfByBookingId(request) {
        //return axiosGetMultiParams(GetInvoiceReceiptPdfByBookingId,request);
        return axiosGetFileAuthorizeblob(GetInvoiceReceiptPdfByBookingId, request);
    }





}