import React, { Component } from 'react'
import DropdownSelect from '../../components/dropdown/Dropdown'
import StrategicMemberTable from '../../components/table/StrategicMemberTable';
import PartnershipsServices from '../../services/axiosServices/apiServices/PartnershipsServices';
import SwalServices from '../../services/swalServices/SwalServices';
import { CommonValidationMessages } from '../../utils/Messages';
import { isValidForm, validate } from '../../utils/validation/CommonValidator';
import ValidationText from '../../utils/validation/ValidationText';

export default class AddEditStrategicMember extends Component {
    constructor(props) {
        super(props);
        this.partnershipServices = new PartnershipsServices();
        this.swalServices = new SwalServices();
        this.state = {
            totalResult: "",
            isLoading: false,
            redirect: null,
            agencyList: [],
            strategicMemberList: [
                {
                    updatedAt: "dfdsf",
                    email: "dsfsdf",
                    contactNo: "dssd",
                    designation: "csdc",
                    lastName: "xchbxv",
                    firstName: "csxccs",
                    companyName: "Company",

                }
            ],
            strategicMemberDetails: {
                corporateMemberId: 0,
                individualMemberId: 0,
                companyName: "",
                firstName: "",
                lastName: "",
                designation: "",
                contactNo: "",
                email: "",
            },
            companyForStrategicMember: [],
            companyOfficerByCompanyForStrategicMember: [],
            officerArr: [],
            validationRules: {
                corporateMemberId: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "company"),
                    }
                ],
                individualMemberId: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "officer"),
                    }
                ],
            },
            validState: {
                isValid: true,
                error: {},
            },
        }
    }

    componentDidMount() {
        // this.getAllStrategicMemberList();
        this.getAllCompanyForStrategicMember();
    }
   

    deleteGovernmentStrategicMember = (id) => {
        let request = {};
        request.id = id;
        request.deletedBy = 15;
        request.appId = 115;

        this.setState({ isLoading: true });
        this.partnershipServices.deleteGovernmentStrategicMember(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.swalServices.Success("Strategic member deleted successfully.");
                // this.getAllStrategicMemberList();
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    }
    addStrategicMember = () => {
        if (this.isValidStrategicMember()) {
            let request = this.state.strategicMemberDetails;

            let member = this.state.companyForStrategicMember.find(
                (x) => x.id === request.corporateMemberId);
            request.companyName = member.name;
            this.setState({ isLoading: true });
            this.partnershipServices.addStrategicMember(request).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                    this.swalServices.Success("Strategic member inserted successfully.");
                    // this.getAllStrategicMemberList();
                }
                else {
                    this.swalServices.Error(response.message);
                }
                this.setState({ isLoading: false });
            });
        }
    };
    // Get all agency officer list method
    getAllStrategicMemberList = () => {
        this.setState({ isLoading: true });
        this.partnershipServices.getAllStrategicMemberList().then((response) => {
            if (response != null) {
                this.setState({ strategicMemberList: response.responseItem.responseContent });
                // this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    };
    getAllCompanyForStrategicMember = () => {
        this.setState({ isLoading: true });
        this.partnershipServices.getAllCompanyForStrategicMember().then((response) => {
            if (response != null) {
                this.setState({ companyForStrategicMember: response.responseItem.responseContent });
                // this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    };
    getCompanyOfficerByCompanyForStrategicMember = (id) => {
        this.setState({ isLoading: true });
        this.partnershipServices.getCompanyOfficerByCompanyForStrategicMember(id).then((response) => {
            if (response != null) {
                this.setState({ companyOfficerByCompanyForStrategicMember: response.responseItem.responseContent });

                var tempOfficer = response.responseItem.responseContent;
                var officerArr = [];
                officerArr = tempOfficer.map((member) => ({
                    id: member.individualMemberId,
                    name: member.firstName + " " + member.lastName,
                }));
                this.setState({ officerArr: officerArr });
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    };


    setFilterParameters = (id, drpIdentity) => {
        let details = this.state.strategicMemberDetails;

        if (drpIdentity === "CompanyName") {
            details["corporateMemberId"] = id;
            this.validateStrategicMember("corporateMemberId");
            this.getCompanyOfficerByCompanyForStrategicMember(id);
            this.setState({ strategicMemberDetails: details });
        }
        else if (drpIdentity === "CompanyOfficerName") {
            details["individualMemberId"] = id;
            this.validateStrategicMember("individualMemberId");
            // let tempStrategicMember = this.state.strategicMemberDetails;
            this.setState({ strategicMemberDetails: details });

            let member = this.state.companyOfficerByCompanyForStrategicMember.find(
                (x) => x.individualMemberId === id);

            this.setState({ strategicMemberDetails: member });

        }

    };
    validateStrategicMember = (key) => {
        const returnValidState = validate(key, this.state.strategicMemberDetails, this.state.validationRules, this.state.validState);
        this.setState({ validState: returnValidState });
    }
    isValidStrategicMember = () => {
        const returnValidState = isValidForm(this.state.strategicMemberDetails, this.state.validationRules, this.state.validState);
        this.setState({ validState: returnValidState });
        return returnValidState.isValid;
    }

    render() {
        return (
            <div>
                <div className="main-body-section">
                    <div className="bg-white pb-9">
                        <div className=" heading-part px-7 py-4 border-b">
                            <h4 className="small-title font-bold theme-color">
                                Add/Edit Strategic Member
                            </h4>
                        </div>
                        <div className="filter-info grid grid-cols-12 gap-6 p-8">
                            <div className="filter-types xl:col-span-4 lg:col-span-4 col-span-12 pr-12">
                                <label
                                    className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                    htmlFor="default"
                                >
                                    Company Name <span className="text-[#c00000]">*</span>
                                </label>
                                <DropdownSelect
                                    drpIdentity={"CompanyName"}
                                    optionArray={this.state.companyForStrategicMember}
                                    setFilterParameters={this.setFilterParameters.bind(this)}
                                    value={this.state.strategicMemberDetails.corporateMemberId}
                                />
                                <ValidationText error={this.state.validState.error.corporateMemberId} />
                            </div>
                            <div className="filter-types xl:col-span-4 lg:col-span-4 col-span-12 pr-12">
                                <label
                                    className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                    htmlFor="default"
                                >
                                    Company Officer <span className="text-[#c00000]">*</span>
                                </label>
                                <DropdownSelect
                                    drpIdentity={"CompanyOfficerName"}
                                    optionArray={this.state.officerArr}
                                    setFilterParameters={this.setFilterParameters.bind(this)}
                                    value={this.state.strategicMemberDetails.individualMemberId}
                                />
                                <ValidationText error={this.state.validState.error.individualMemberId} />

                            </div>
                            <div className="xl:col-span-3 lg:col-span-3 col-span-12">
                                <div className="flex items-end justify-end h-full pb-1">
                                    {/* <Link to="/AddEditStrategicMember"> */}
                                    <button className="btn btn-red text-white font-semibold link mt-7"
                                        onClick={() => this.addStrategicMember()}
                                    >
                                        <span className="text-xl text-white font-semibold tracking-normal">
                                            Add Employee
                                        </span>
                                    </button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 gap-6">
                            <div className="xl:col-span-12 lg:col-span-12 sm:col-span-12 col-span-12">
                                <div className='table-sections-strategy mt-7 w-full'>
                                    {/* <EditFieldTable
                                        agencyOfficerDetails={this.state.strategicMemberList}
                                    /> */}
                                    <StrategicMemberTable
                                        strategicMemberList={this.state.strategicMemberList}
                                        delete={this.deleteGovernmentStrategicMember.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                        <div className="flex justify-between space-x-10 mt-12">
                            <button
                                to=""
                                className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white hover:text-white"
                            >
                                Back
                            </button>

                            {/* <button className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white">Back</button> */}
                            {/* {props.resource.isPage ? ( */}
                            <button
                                className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white"
                            // onClick={() => props.addEditResources()}
                            // disabled={props.isLoaderEnabled}
                            >
                                Save
                            </button>
                            {/* } */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}