import { AddEditNotification, DeleteNotification, GetNotificationsById, GetNotifiicationsList } from "../ApiEndPoints";
import { axiosGetAuthorize, axiosPostAuthorize } from "../AxiosRequests";

export default class NotificationServices {


    async getNotificationList(request) {
        return axiosPostAuthorize(GetNotifiicationsList, request);

    }
    async addEditNotification(request) {
        return axiosPostAuthorize(AddEditNotification, request);
    }
    async getNotificationsById(request) {
        return axiosGetAuthorize(GetNotificationsById, request);
    }
    async deleteNotification(request) {
        return axiosPostAuthorize(DeleteNotification, request);
    }
}
