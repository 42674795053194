import { GetAuthorityLimitList, AddEditAuthorityLimit, GetAllMembershipType, GetAllMembershipRevenue, GetAllMembershipCategory, GetAllMembershipFeeType, GetAuthorityLimitById, GetAllMembershipTenure, DeleteAuthorityLimit } from "../ApiEndPoints";
import { axiosGetAuthorize, axiosPostAuthorize } from "../AxiosRequests";


export default class AuthorityLimitServices {

    async getAuthorityLimitList(request) {
        return axiosPostAuthorize(GetAuthorityLimitList, request);
    }
    // async activeInActiveMembershipCampaign(request) { 
    //     return axiosPostAuthorize(ActiveInActiveCampaign, request);
    // }
    async addEditAuthorityLimit(request) {
        return axiosPostAuthorize(AddEditAuthorityLimit, request);
    }

    async getAllMembershipType(request) {
        return axiosGetAuthorize(GetAllMembershipType, request);
    }

    async getAllMembershipRevenue(request) {
        return axiosGetAuthorize(GetAllMembershipRevenue, request);
    }

    async getAllMembershipCategory(request) {
        return axiosGetAuthorize(GetAllMembershipCategory, request);
    }
    async getAllMembershipFeeType(request) {
        return axiosGetAuthorize(GetAllMembershipFeeType, request);
    }
    async getAllMembershipTenure(request) {
        return axiosGetAuthorize(GetAllMembershipTenure, request);
    }
    async getAuthorityLimitById(request) {
        return axiosGetAuthorize(GetAuthorityLimitById, request);
    }
    async deleteAuthorityLimit(request) {
        return axiosPostAuthorize(DeleteAuthorityLimit, request);
    }
    // async getAllMembershipTenure(request){
    //     return axiosGetAuthorize(GetAllMembershipTenure, request);
    // }  
}
