import React from "react";
import MultiSelectDropdown from "../../../dropdown/MultiSelectDropdown";
import Option from "../../../dropdown/multiSelectDropdownOption/MultiSelectOption";
import Validation from "../../../../utils/validation/ValidationText";
import ImageCropperWithUpload from "../../../ImageUpload/UploadImage";
import DropdownSelect from "../../../dropdown/Dropdown";
import TextInput from "../../../UIComponent/TextInput";
import DatePicker from "../../../datePicker/DatePicker";
import moment from "moment";
import Selector from "../../../dropdown/commonDropdown/Selector";
import ButtonLoader from "../../../common/buttonLoader/ButtonLoader";
import ImageUploadTest from "../../../ImageUpload3/ImageUploadTest";

// import ImageCropperWithUpload from "../../../ImageUpload/UploadImage";

function OrganisationProfile(props) {
  return (
    <div>
      <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
        <div className="grid grid-cols-12 gap-6 w-full ">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="grid grid-cols-12 gap-6 w-full ">
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Paid-Up Capital
                      <span className="text-[#c00000]">*</span>
                    </h2>
                    <div className="flex items-center">
                      <div className="w-full outline-none appearance-none">
                        <TextInput
                          placeholder=""
                          type="number"
                          name="paidUpCapital"
                          value={props.organizationProfile.paidUpCapital}
                          identity="OrganizationProfile"
                          handleChange={props.handleChange}
                        // onBlur={(e) =>
                        //   props.validate("paidUpCapital", props.organizationProfile)
                        // }
                        />
                      </div>
                    </div>
                    {/* <Validation
                      error={props.validState.error.paidUpCapital}
                    /> */}
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Annual Sales Turnover
                      <span className="text-[#c00000]">*</span>
                    </h2>
                    <div className="flex items-center">
                      <DropdownSelect
                        drpIdentity={"grossTurnOver"}
                        optionArray={props.corporateGrossTurnovers}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organizationProfile.orgGrossTurnoverId}
                      />
                    </div>
                    {/* <Validation
                      error={props.validState.error.orgGrossTurnoverId}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="grid grid-cols-12 gap-6 w-full ">
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6 ">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Employment size
                      <span className="text-[#c00000]">*</span>
                    </h2>
                    <div className="flex items-center">
                      <div className="w-full outline-none appearance-none">
                        <TextInput
                          placeholder=""
                          type="number"
                          name="staffStrength"
                          value={props.organizationProfile.staffStrength}
                          identity="OrganizationProfile"
                          handleChange={props.handleChange}
                        // onBlur={(e) =>
                        //   props.validate("staffStrength", props.organizationProfile)
                        // }
                        />
                      </div>
                    </div>
                    {/* <Validation
                      error={props.validState.error.staffStrength}
                    /> */}
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-6 mt-9">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      General Email Address
                      <span className="text-[#c00000]">*</span>
                    </h2>
                    <div className="flex items-center">
                      <TextInput
                        placeholder=""
                        type="email"
                        name="generalBusinessEmail"
                        value={props.organizationProfile.generalBusinessEmail}
                        identity="OrganizationProfile"
                        handleChange={props.handleChange}
                      // onBlur={(e) =>
                      //   props.validate(
                      //     "generalBusinessEmail",
                      //     props.organizationProfile
                      //   )
                      // }
                      />
                    </div>
                    {/* <Validation error={props.validState.error.generalBusinessEmail} /> */}
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-6 mt-9">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Organisation Website
                      {/* <span className="text-[#c00000]">*</span> */}
                    </h2>
                    <div className="flex items-center">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="organisationWebsite"
                        value={props.organizationProfile.organisationWebsite}
                        identity="OrganizationProfile"
                        handleChange={props.handleChange}
                      // onBlur={(e) =>
                      //   props.validate("organisationWebsite", props.organizationProfile)
                      // }
                      />
                    </div>
                    {/* <Validation error={props.validState.error.organisationWebsite} /> */}
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Country
                      <span className="text-[#c00000]">*</span>
                    </h2>

                    <Selector
                      drpIdentity="orgCountry"
                      options={props.countriesWithPresence}
                      // isMulti
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      // components={{ Option }}
                      onChange={props.handleChangeMultiDropdown}
                      allowSelectAll={false}
                      value={props.countriesWithPresence.find(
                        (country) =>
                          country.value === props.organizationProfile.countryId
                      )}
                    />
                    {/* <Validation error={props.validState.error.countryId} /> */}
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-6 mt-9">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      General Business Telephone
                      <span className="text-[#c00000]">*</span>
                    </h2>
                    <div className="flex items-center">
                      <TextInput
                        placeholder=""
                        type="email"
                        name="generalBusinessTelephone"
                        value={
                          props.organizationProfile.generalBusinessTelephone
                        }
                        identity="OrganizationProfile"
                        handleChange={props.handleChange}
                      // onBlur={(e) =>
                      //   props.validate(
                      //     "generalBusinessTelephone",
                      //     props.organizationProfile
                      //   )
                      // }
                      />
                    </div>
                    {/* <Validation
                      error={props.validState.error.generalBusinessTelephone}
                    /> */}
                  </div>
                </div>
              </div>

              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid-cols-12 gap-6 w-full mt-9">
                  <div className="2xl:col-span-12 lg:col-span-12 col-span-12 ml-auto">
                    {/* ACRA */}
                    <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
                      <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                        ACRA Business Profile
                        <span className="text-[#F93549]">*</span>&nbsp;
                        <span className="font-medium text-[#757575]">
                          (Please Upload in PDF Format Only)
                        </span>
                      </h2>
                      <div className="flex item-center flex-col">
                        <form className="custom-uploader custom-file-upload-btn flex">
                          <div className="btn btn-red border-[#f3f3f3] btn btn-blue cursor-pointer">
                            <label
                              htmlFor={`upload_File`}
                              className="text-uplod block lg:text-[18px] text-[16px] text-[#ffffff]"
                            >
                              Upload PDF File
                            </label>
                            <input
                              className="sr-only"
                              id={`upload_File`}
                              type="file"
                              onChange={(event) => props.onFileChangePDF(event)}
                            />
                          </div>
                        </form>
                        {props.organizationProfile.acraFilePath ? (
                          <div className="mt-5 file-uploaded-txt flex items-center">
                            <img
                              src="/checked-icon.svg"
                              className="w-[25px] mr-3"
                              alt=""
                            />
                            <span className="text-[#757575] big-bullet-points font-bold">
                              {props.organizationProfile.acraFilePath}
                            </span>
                            <button
                              className="2xl:px-7 lg:px-3 2xl:py-2 lg:py-0 w-50 px-7 py-3 text-xl text-[#C00000]"
                              onClick={() =>
                                props.getInvoiceReceiptPdfByBookingId(0, "ACRA")
                              }
                            >
                              <span className="text-xl text-[#c00000] font-semibold tracking-normal underline">
                                View ACRA PDF
                              </span>
                            </button>
                            <button
                              className="underline text-[#e74c3c] font-[500] ml-3"
                              onClick={() =>
                                props.deleteFile(
                                  props.organizationProfile.acraFilePath,
                                  "ACRAFile"
                                )
                              }
                            >
                              <img
                                src="/delete-icon.png"
                                className="w-[25px] mr-3"
                                alt=""
                              />
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6 w-full mt-9">
                  <div className="2xl:col-span-12 lg:col-span-12 col-span-12 ml-auto">
                    <div className="company-logo-cropper ml-auto mr-auto">
                      <div className="individual-profile-create-cropper">
                        <ImageUploadTest
                          key="Main"
                          height={400}
                          width={400}
                          isCheckValidation={true}
                          MaxFileSize={2097152}
                          MaxFileSizeMessage={"2MB"}
                          onSaveImage={props.saveMainImage}
                          aspactRatio={400 / 400}
                          imageIdentity="orgLogoFileName"
                          intitImage={props.organizationProfile.orgLogoFileName}
                          uploadBtn={"Upload Photo"}
                        // dimensionText={"(Dimension: 400px by 200px Maximum Size: 2MB)"}
                        />
                      </div>
                      <div className=" text-center">
                        {/* <Validation
                          error={props.validState.error.orgLogoFileName}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="xl:col-span-12 lg:col-span-12 col-span-12 input-sec-texts">
                  <div className="left-tiitle">
                    <h4 className="small-title 2xl:text-[22px] font-bold text-[#757575] ">
                      Registered Address (Based on ACRA)&nbsp;
                      <span className="text-[#F93549]">*</span> #
                    </h4>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts"></div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Block No & Street Name
                      <span className="text-[#c00000]">*</span>
                    </h2>
                    <div className="flex items-center">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="registeredStreetName"
                        value={props.organizationProfile.registeredStreetName}
                        maxLength={40}
                        identity="OrganizationProfile"
                        handleChange={props.handleChange}
                      // onBlur={(e) =>
                      //   props.validate(
                      //     "registeredStreetName",
                      //     props.organizationProfile
                      //   )
                      // }
                      />
                    </div>
                    {/* <Validation error={props.validState.error.registeredStreetName} /> */}
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-6 mt-9">
                  <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
                    <div className="flex items-start register-desc 2xl:mt-5 lg:mt-5 md:mt-5 sm:mt-5 mt-3">
                      <input
                        id=""
                        name="registeredNoUnitNumber"
                        type="checkbox"
                        className="cursor-pointer h-4 w-4 text-[#C00000] focus:ring-[#C00000] rounded check-box-custom"
                        value={
                          props.organizationProfile.registeredNoUnitNumber
                            ? props.organizationProfile.registeredNoUnitNumber
                            : false
                        }
                        onChange={(e) => props.handleChangeCheckBox(e)}
                        checked={
                          props.organizationProfile.registeredNoUnitNumber
                            ? props.organizationProfile.registeredNoUnitNumber
                            : false
                        }
                      />
                      <label
                        htmlFor="isRequireQuotation"
                        className="ml-5 block text-[#212529] text-justify inner-title-font-small-button mobile-14"
                      >
                        No Unit Number
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Unit Number & Building Name
                      <span className="text-[#c00000]">*</span>
                    </h2>
                    <div
                      className={`flex items-center ${props.organizationProfile.registeredNoUnitNumber
                        ? "disableVouchercode"
                        : ""
                        }`}
                    >
                      <TextInput
                        placeholder=""
                        type="text"
                        name="registeredBuildingName"
                        value={props.organizationProfile.registeredBuildingName}
                        maxLength={40}
                        identity="OrganizationProfile"
                        handleChange={props.handleChange}
                        // onBlur={(e) =>
                        //   props.validate(
                        //     "registeredBuildingName",
                        //     props.organizationProfile
                        //   )
                        // }
                        disabled={
                          props.organizationProfile.registeredNoUnitNumber
                        }
                      />
                    </div>
                    {/* {!props.organizationProfile.registeredNoUnitNumber && (
                      <Validation error={props.validState.error.registeredBuildingName} />
                    )} */}
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-6 mt-9">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Postal Code
                      <span className="text-[#c00000]">*</span>
                    </h2>
                    <div className="flex items-center">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="registeredPostalCode"
                        maxLength={20}
                        value={props.organizationProfile.registeredPostalCode}
                        identity="OrganizationProfile"
                        handleChange={props.handleChange}
                        // onBlur={(e) =>
                        //   props.validate(
                        //     "registeredPostalCode",
                        //     props.organizationProfile
                        //   )
                        // }
                        autoComplete={"off"}
                      />
                    </div>
                    {/* <Validation error={props.validState.error.registeredPostalCode} /> */}
                  </div>
                </div>
              </div>

              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="xl:col-span-12 lg:col-span-12 col-span-12 input-sec-texts">
                  <div className="left-tiitle">
                    <h4 className="small-title 2xl:text-[22px] font-bold text-[#757575] ">
                      Mailing Address
                    </h4>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts"></div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Block No & Street Name
                      <span className="text-[#c00000]">*</span>
                    </h2>
                    <div className="flex items-center">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="mailingStreetName"
                        value={props.organizationProfile.mailingStreetName}
                        maxLength={40}
                        identity="OrganizationProfile"
                        handleChange={props.handleChange}
                      // onBlur={(e) =>
                      //   props.validate(
                      //     "mailingStreetName",
                      //     props.organizationProfile
                      //   )
                      // }
                      />
                    </div>
                    {/* <Validation error={props.validState.error.mailingStreetName} /> */}
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-6 mt-9">
                  <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
                    <div className="flex items-start register-desc 2xl:mt-5 lg:mt-5 md:mt-5 sm:mt-5 mt-3">
                      <input
                        id=""
                        name="mailingNoUnitNumber"
                        type="checkbox"
                        className="cursor-pointer h-4 w-4 text-[#C00000] focus:ring-[#C00000] rounded check-box-custom"
                        value={
                          props.organizationProfile.mailingNoUnitNumber
                            ? props.organizationProfile.mailingNoUnitNumber
                            : false
                        }
                        onChange={(e) => props.handleChangeCheckBox(e)}
                        checked={
                          props.organizationProfile.mailingNoUnitNumber
                            ? props.organizationProfile.mailingNoUnitNumber
                            : false
                        }
                      />
                      <label
                        htmlFor="isRequireQuotation"
                        className="ml-5 block text-[#212529] text-justify inner-title-font-small-button mobile-14"
                      >
                        No Unit Number
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Unit Number & Building Name
                      <span className="text-[#c00000]">*</span>
                    </h2>
                    <div
                      className={`flex items-center ${props.organizationProfile.mailingNoUnitNumber
                        ? "disableVouchercode"
                        : ""
                        }`}
                    >
                      <TextInput
                        placeholder=""
                        type="text"
                        name="mailingBuildingName"
                        value={props.organizationProfile.mailingBuildingName}
                        maxLength={40}
                        identity="OrganizationProfile"
                        handleChange={props.handleChange}
                        // onBlur={(e) =>
                        //   props.validate(
                        //     "mailingBuildingName",
                        //     props.organizationProfile
                        //   )
                        // }
                        disabled={props.organizationProfile.mailingNoUnitNumber}
                      />
                    </div>
                    {/* {!props.organizationProfile.mailingNoUnitNumber && (
                      <Validation error={props.validState.error.mailingBuildingName} />
                    )} */}
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-6 mt-9">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Postal Code
                      <span className="text-[#c00000]">*</span>
                    </h2>
                    <div className="flex items-center">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="mailingPostalCode"
                        maxLength={20}
                        value={props.organizationProfile.mailingPostalCode}
                        identity="OrganizationProfile"
                        handleChange={props.handleChange}
                        // onBlur={(e) =>
                        //   props.validate(
                        //     "mailingPostalCode",
                        //     props.organizationProfile
                        //   )
                        // }
                        autoComplete={"off"}
                      />
                    </div>
                    {/* <Validation error={props.validState.error.mailingPostalCode} /> */}
                  </div>
                </div>
              </div>

              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Updated By
                    </h2>
                    <div className="disabled-input flex items-center">
                      <DropdownSelect
                        disabled={true}
                        drpIdentity={"updatedById"}
                        optionArray={props._By}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organizationProfile.updatedBy}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Last Updated Date
                    </h2>
                    <div className="disabled-input">
                      {props.organizationProfile.updatedAt === "" ||
                        props.organizationProfile.updatedAt === null ||
                        props.organizationProfile.updatedAt ===
                        "1900-01-01T00:00:00" ? (
                        <TextInput
                          disabled={true}
                          placeholder=""
                          type="text"
                          name="linkedIn"
                          value="None"
                          identity="BasicInformation"
                        />
                      ) : (
                        <DatePicker
                          isDisable={true}
                          name="updatedAt"
                          handleChange={props.handleChange}
                          identity="updatedAt"
                          value={moment(
                            props.organizationProfile.updatedAt
                          ).format("yyyy-MM-DD")}
                        />
                      )}
                    </div>
                    {/* <div className="disabled-input flex items-center">
                      <DatePicker
                        name="updatedAt"
                        isDisable={true}
                        handleChange={props.handleChange}
                        identity="OrganizationProfile"
                        value={moment(
                          props.organizationProfile.updatedAt
                        ).format("yyyy-MM-DD")}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Save Button */}
      <div className="grid grid-cols-12 gap-6 pb-10">
        <div className="xl:col-span-12 lg:col-span-12 col-span-12 px-10">
          <div className="flex items-center justify-end">
            {props && props.isOrganizationProfileLoading === true ? (
              <ButtonLoader />
            ) : (
              <button
                className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold"
                onClick={() => props.onSave()}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrganisationProfile;
