import React from "react";
import ModalBasic from "../../../pages/component/ModalBasic";
import moment from "moment";
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";

const RepresentativeDetailsModal = (props) => {
  const data = props.getMemberDirectoryData[props.councilIndex];

  return (
    <ModalBasic
      id="show-representative-details-modal"
      modalOpen={props.setRepresentativeDetailsModal}
      setModalOpen={props.setOpenModal}
      title={
        <div className="flex items-center justify-space-between">
          <h2 className="text-xl font-semibold">
            {data.organisationName || "-"}
          </h2>
        </div>
      }
    >
      <>
        <div className="p-6 bg-white rounded-lg shadow-lg">
          <div className="grid grid-cols-2 gap-4">
            {data.firstRepresentativeName !== null &&
            data.firstRepresentativeEmail !== null &&
            data.firstRepresentativeNumber !== null ? (
              <div className="bg-gray-100 p-4 rounded-lg">
                <h3 className="font-semibold text-[#292E8F] mb-2 text-2xl">
                  First Representative
                </h3>
                <p className="text-sm text-gray-700">
                  <b>Name:</b> {data.firstRepresentativeName || "-"}
                </p>
                <p className="text-sm text-gray-700">
                  <b>Email Address:</b> {data.firstRepresentativeEmail || "-"}
                </p>
                <p className="text-sm text-gray-700">
                  <b>Contact Number:</b> {data.firstRepresentativeNumber || "-"}
                </p>
                <p className="text-sm text-gray-700">
                  <b>Office DID:</b> {data.firstRepresentativeOfficeDID || "-"}
                </p>
              </div>
            ) : null}

            {data.secondRepresentativeName !== null &&
            data.secondRepresentativeEmail !== null &&
            data.secondRepresentativeNumber !== null ? (
              <div className="bg-gray-100 p-4 rounded-lg">
                <h3 className="font-semibold text-[#292E8F] mb-2 text-2xl">
                  Second Representative
                </h3>
                <p className="text-sm text-gray-700">
                  <b>Name:</b> {data.secondRepresentativeName || "-"}
                </p>
                <p className="text-sm text-gray-700">
                  <b>Email Address:</b> {data.secondRepresentativeEmail || "-"}
                </p>
                <p className="text-sm text-gray-700">
                  <b>Contact Number:</b>{" "}
                  {data.secondRepresentativeNumber || "-"}
                </p>
                <p className="text-sm text-gray-700">
                  <b>Office DID:</b> {data.secondRepresentativeOfficeDID || "-"}
                </p>
              </div>
            ) : null}

            {data.hrExecutiveName !== null &&
            data.hrExecutiveEmail !== null &&
            data.hrExecutiveNumber !== null ? (
              <div className="bg-gray-100 p-4 rounded-lg">
                <h3 className="font-semibold text-[#292E8F] mb-2 text-2xl">
                  HR Executive
                </h3>
                <p className="text-sm text-gray-700">
                  <b>Name:</b> {data.hrExecutiveName || "-"}
                </p>
                <p className="text-sm text-gray-700">
                  <b>Email Address:</b> {data.hrExecutiveEmail || "-"}
                </p>
                <p className="text-sm text-gray-700">
                  <b>Contact Number:</b> {data.hrExecutiveNumber || "-"}
                </p>
                <p className="text-sm text-gray-700">
                  <b>Office DID:</b> {data.hrExecutiveOfficeDID || "-"}
                </p>
              </div>
            ) : null}

            {data.financeExecutiveName !== null &&
            data.financeExecutiveEmail !== null &&
            data.financeExecutiveNumber !== null ? (
              <div className="bg-gray-100 p-4 rounded-lg">
                <h3 className="font-semibold text-[#292E8F] mb-2 text-2xl">
                  Finance Executive
                </h3>
                <p className="text-sm text-gray-700">
                  <b>Name:</b> {data.financeExecutiveName || "-"}
                </p>
                <p className="text-sm text-gray-700">
                  <b>Email Address:</b> {data.financeExecutiveEmail || "-"}
                </p>
                <p className="text-sm text-gray-700">
                  <b>Contact Number:</b> {data.financeExecutiveNumber || "-"}
                </p>
                <p className="text-sm text-gray-700">
                  <b>Office DID:</b> {data.financeExecutiveOfficeDID || "-"}
                </p>
              </div>
            ) : null}
            {/* {data.memberId !== null && data.memberId !== null && data.memberId !== null ?
                        <div className="flex space-x-4 justify-end">

                            <button
                                className="w-32 px-7 py-4 btn-sm btn-red text-xl text-white font-semibold h-[50px]"
                            onClick={() => props.cancelInvoiceAndRegenerateRenewalInvoice(data.memberId,data.taskId)}
                            >
                                Cancel and Regenerate
                            </button>

                        </div>
                  : null } */}
          </div>
         
                 
          {data.memberId !== null &&
          data.memberId !== null &&
          data.memberId !== null ? (
            <div className="flex space-x-4 justify-center">
               {props.isLoadingcancelInvoice ? (
                  <ButtonLoader className="buttonload cancel-btn group relative text-xl font-bold text-white btn bg-[#bf0d25]" />
                ) : (
              <button
                className=" btn-sm cancel-btn btn-red text-xl text-white font-semibold"
                onClick={() =>
                  props.cancelInvoiceAndRegenerateRenewalInvoice(
                    data.memberId,
                    data.taskId
                  )
                }
              >
                Cancel and Regenerate
              </button>)}
            </div>
          ) : null}
          
        </div>
      </>
    </ModalBasic>
  );
};

export default RepresentativeDetailsModal;
