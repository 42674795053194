import React, { Component } from 'react'
import DropdownSelect from '../../../components/dropdown/Dropdown'
import CorporateMemberServices from '../../../services/axiosServices/apiServices/CorporateMemberServices';
import SwalServices from '../../../services/swalServices/SwalServices';
import CommonServices from '../../../services/axiosServices/apiServices/CommonServices';
import ModalBasic from '../../component/ModalBasic'
import Table from "../../../components/table/Table"
import ActionContainer from '../../../components/UIComponent/ActionTableComponent/actionContainer';
import ActionEditButton from '../../../components/UIComponent/ActionTableComponent/ActionEditButton';
import ActionDeleteButton from '../../../components/UIComponent/ActionTableComponent/ActionDeleteButton';
import MyEditor from '../../../components/ckEditor/Editor';
// import ValidationText from '../../../utils/validation/ValidationText';

export default class CorporateMemberDirectory extends Component {

    constructor(props) {
        super(props)
        this.CorporateMemberServices = new CorporateMemberServices();
        this.CommonServices = new CommonServices();
        this.swalServices = new SwalServices();
        this.state = {

            corporateMembers: [],
            bussinessSectors: [],
            areaOfExpertise: [],
            countries: [],
            chapters: [],
            isLoading: false,
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "",
                orderFlag: 0,
                searchText: "",
                SearchByCompanyName: "",
                FilterByBusinessSector: 0,
                FilterByAreaOfExpertise: 0,
                FilterByCountry: 0,
                FilterByChapter: 0,
                IsCompaniesWithProfile: false
            },
            setAddeditModalOpen: false,
            setToggle2: false,
            setToggle3: false,
            curruntPage: 1,
            totalPages: 10,
            filter: "",

            corporateDetails: {
                companyId: "",
                companyName: "",
                companyUEN: "",
                website: "",
                businessSectorId: "",
                areaOfExpertiseId: "",
                chapterId: "",
                countryId: "",
                isCommunitySpotlight: "",
                businessSectorName: "",
            }
        }
    }

    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getAllCorporateMemberList();
    }


    getAllCorporateMemberList = (pagination = this.state.pagination) => {

        this.setState({ isLoading: true });
        this.CorporateMemberServices.getAllCorporateMemberList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ corporateMembers: response.responseItem.responseContent.itemList });
                this.setState({ corporateDetails: response.responseItem.responseContent });
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    getCorporateMembersById = (companyId) => {
        this.CorporateMemberServices.getCorporateMembersById(companyId).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                response.responseItem.responseContent.companyId = response.responseItem.responseContent.companyId.toString(10).replace(/\D/g, '0').split('').map(Number);

                this.setState({ corporateDetails: response.responseItem.responseContent })
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });

        })
    }

    resetCorporateDetails = () => {
        let resetDetails = {
            ...this.state.corporateDetails,
            companyId: "",
            companyName: "",
            companyUEN: "",
            website: "",
            businessSectorId: "",
            areaOfExpertiseId: "",
            chapterId: "",
            countryId: "",
            isCommunitySpotlight: "",
            businessSectorName: ""
        }

        this.setState({ corporateDetails: resetDetails });
    }

    getAllBusinessSectors = () => {
        this.CommonServices.getAllBusinessSectors().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ bussinessSectors: response.responseItem.responseContent });
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    getAllAreaOfExpertise = (pagination = this.state.pagination) => {
        this.CommonServices.getAllAreaOfExpertise(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ areaOfExpertise: response.responseItem.responseContent });
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    getAllCountries = (pagination = this.state.pagination) => {
        this.CommonServices.getAllCountries(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ countries: response.responseItem.responseContent });
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    getAllChapters = (pagination = this.state.pagination) => {
        this.CommonServices.getAllChapters(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ chapters: response.responseItem.responseContent });
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    setFilterParameters = (id, drpIdentity) => {
        let detail = this.state.pagination;
        if (drpIdentity === "BusinessSectors") {
            detail.FilterByBusinessSector = id;
        }
        else if (drpIdentity === "AreaOfExpertise") {
            detail.FilterByAreaOfExpertise = id;
        }
        else if (drpIdentity === "Countries") {
            detail.FilterByCountry = id;
        }
        else if (drpIdentity === "Chapters") {
            detail.FilterByChapter = id;
        }
        this.setState({ pagination: { ...detail } });
        this.getAllCorporateMemberList();
    }

    handleChangeEvent = (value) => {
        let detail = this.state.pagination;
        detail.searchText = "";
        if (value.length > 2) {
            detail.searchText = value;
            this.setState({ pagination: { ...detail } });

        }
        this.getAllCorporateMemberList();
    }

    filterByCompanyProfile = (value) => {
        let detail = this.state.pagination;
        this.setState({ setToggle2: value.target.checked })
        detail.IsCompaniesWithProfile = value.target.checked;
        this.setState({ pagination: { ...detail } });
        this.getAllCorporateMemberList();
    }

    onDelete = (corporateMemberId) => {
        let request = {
            id: corporateMemberId,
            deletedBy: 2,
            appId: 3
        }
        this.CorporateMemberServices.deleteCorporateMembers(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.swalServices.Success("Deleted");
                this.getAllCorporateMemberList();
            } else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }
    componentDidMount() {
        this.getAllCorporateMemberList();
        this.getAllBusinessSectors();
        this.getAllAreaOfExpertise();
        this.getAllCountries();
        this.getAllChapters();
    }


    actions = (element, index, value) => {
        return (element !== "companyId") ? null
            :
            <td>
            <ActionContainer>
                <ActionEditButton
                    value={value}
                    id={index}
                    index={index}
                    text="Edit"
                />
                <ActionDeleteButton
                    value={value}
                    id={index}
                    index={index}
                    onActionClick={this.onDelete}
                    text="Delete"
                />
            </ActionContainer>
            </td>

    }

    render() {
        return (
            <div className='main-body-section'>
                <div className="flex">
                    <div className="relative flex flex-col flex-1 w-full">
                        <main className='pb-11'>
                            <div className='custom-card shadow-lg mb-10'>
                                <div className='filter-info grid grid-cols-12 2xl:gap-10 lg:gap-5'>
                                    <div className='filter-types xl:col-span-4'>
                                        <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Search by Company Name</label>
                                        <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" type="text" onChange={(e) => this.handleChangeEvent(e.target.value)} />
                                    </div>
                                    <div className='filter-types xl:col-span-4'>
                                        <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Filter by Business Sector</label>
                                        <DropdownSelect
                                            drpIdentity={"BusinessSectors"}
                                            optionArray={this.state.bussinessSectors}
                                            setFilterParameters={this.setFilterParameters.bind(this)} />
                                    </div>
                                    <div className='filter-types xl:col-span-4'>
                                        <label className="block text-xl font-medium  mb-3 theme-color min-h-30" htmlFor="default">Filter by Area of Expertise</label>
                                        <DropdownSelect
                                            drpIdentity={"AreaOfExpertise"}
                                            optionArray={this.state.areaOfExpertise}
                                            setFilterParameters={this.setFilterParameters.bind(this)} />
                                    </div>
                                    <div className='filter-types xl:col-span-4'>
                                        <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Filter by Countries with Presence</label>
                                        <DropdownSelect
                                            drpIdentity={"Countries"}
                                            optionArray={this.state.countries}
                                            setFilterParameters={this.setFilterParameters.bind(this)} />
                                    </div>
                                    <div className='filter-types xl:col-span-4'>
                                        <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Filter by Chapter</label>
                                        <DropdownSelect
                                            drpIdentity={"Chapters"}
                                            optionArray={this.state.chapters}
                                            setFilterParameters={this.setFilterParameters.bind(this)} />
                                    </div>
                                    <div className='filter-types xl:col-span-4'>
                                        <label className="block font-22 font-medium  mb-3 theme-color min-h-30" htmlFor="default"> </label>
                                        <div className="w-full">
                                            <div className="flex items-center h-[60px]">
                                                <div className="form-switch">
                                                    <input type="checkbox" id="switch-2" className="sr-only" checked={this.state.setToggle2} onChange={this.filterByCompanyProfile.bind(this.state.setToggle2)} /*onChange={() => this.setState({ setToggle2: !this.state.setToggle2 })}*/ />
                                                    <label className="bg-[#757575]" htmlFor="switch-2">
                                                        <span className="bg-white shadow-sm" aria-hidden="true"></span>
                                                        <span className="sr-only">Switch label</span>
                                                    </label>
                                                </div>
                                                <div className=" font-medium font-22 theme-color ml-2">{this.state.setToggle2 ? 'Companies with Profile' : 'Companies with Profiles'}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full mx-auto">
                                <div className="sm:flex sm:justify-between sm:items-center mb-9">
                                    <div className="mb-4 sm:mb-0">
                                        <h1 className="table-title-txt theme-color font-bold">
                                            {this.state.corporateMembers.length} Companies
                                        </h1>
                                    </div>
                                    <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3">
                                        <button className="btn btn-red text-white" aria-controls="add-edit-modal" onClick={(e) => { e.stopPropagation(); this.setState({ setAddeditModalOpen: true }); this.resetCorporateDetails() }}>
                                            <span className="text-xl text-white font-semibold tracking-normal">Add New Member</span>
                                        </button>
                                    </div>
                                </div>

                                <div className="relative">
                                    <div>
                                        <div className="">
                                            <Table columns={[
                                                { name: 'companyName', title: 'Company Name' },
                                                { name: 'businessSectorName', title: 'Business Sector' },
                                                { name: 'areaOfExpertiseName', title: 'area Of Expertise' },
                                                { name: 'countryName', title: 'Country' },
                                                { name: 'chapterName', title: 'Chapter' },
                                                { name: 'companyId', title: 'Action' }
                                            ]}
                                                rows={this.state.corporateMembers}
                                                isLoading={this.state.isLoading}
                                                sortingColumns={["resourceName", "statusName"]}
                                                pagination={this.state.pagination}
                                                totalResultes={this.state.totalResultes}
                                                totalCount={this.state.totalResultes}
                                                setPagination={this.setPagination.bind(this)}
                                                customScope={[
                                                    {
                                                        column: 'companyId',
                                                        renderTableData: this.actions.bind(this)
                                                    },
                                                ]} />
                                        </div>

                                    </div>
                                </div>




                            </div>
                            <ModalBasic id="add-edit-modal" modalOpen={this.state.setAddeditModalOpen} setModalOpen={() => this.setState({ setAddeditModalOpen: false })} title="Add/Edit Member">
                                {/* Modal content */}
                                <div className="px-14 py-4">
                                    <div className='mb-10'>
                                        <div className='grid grid-cols-12 gap-6'>
                                            <div className='xl:col-span-6'>
                                                <div className='grid grid-cols-12 gap-6'>
                                                    <div className='filter-types xl:col-span-9'>
                                                        <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Company Name <span className='text-[#C00000]'>*</span></label>
                                                        <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" value={this.state.corporateDetails.companyName} type="text" />
                                                    </div>
                                                    <div className='filter-types xl:col-span-9'>
                                                        <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Company UEN <span className='text-[#C00000]'>*</span></label>
                                                        <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" value={this.state.corporateDetails.companyUEN} type="text" />
                                                    </div>
                                                    <div className='filter-types xl:col-span-9'>
                                                        <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Company Website <span className='text-[#C00000]'>*</span></label>
                                                        <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" value={this.state.corporateDetails.website} type="text" />
                                                    </div>
                                                    <div className='filter-types xl:col-span-9'>
                                                        <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Business Sector <span className='text-[#C00000]'>*</span></label>

                                                        <DropdownSelect
                                                            drpIdentity={"BusinessSectors"}
                                                            optionArray={this.state.bussinessSectors}
                                                            setFilterParameters={this.setFilterParameters.bind(this)}
                                                            value={this.state.corporateDetails.businessSectorId}
                                                        />
                                                    </div>
                                                    <div className='filter-types xl:col-span-9'>
                                                        <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Area of Expertise <span className='text-[#C00000]'>*</span></label>
                                                        <DropdownSelect
                                                            drpIdentity={"AreaOfExpertise"}
                                                            optionArray={this.state.areaOfExpertise}
                                                            setFilterParameters={this.setFilterParameters.bind(this)}
                                                            value={this.state.corporateDetails.areaOfExpertiseId}
                                                        />
                                                    </div>
                                                    <div className='filter-types xl:col-span-9'>
                                                        <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Countries with Presence</label>
                                                        <DropdownSelect
                                                            drpIdentity={"Countries"}
                                                            optionArray={this.state.countries}
                                                            setFilterParameters={this.setFilterParameters.bind(this)}
                                                            value={this.state.corporateDetails.countryId}
                                                        />
                                                    </div>
                                                    <div className='filter-types xl:col-span-9' >
                                                        <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Chapter <span className='text-[#C00000]'>*</span></label>
                                                        <DropdownSelect
                                                            drpIdentity={"Chapters"}
                                                            optionArray={this.state.chapters}
                                                            setFilterParameters={this.setFilterParameters.bind(this)}
                                                            value={this.state.corporateDetails.chapterId}
                                                        />
                                                    </div>

                                                    {/* Company Description Description */}
                                                    <div className="filter-types 2xl:col-span-12 lg:col-span-12 col-span-12">
                                                        <label
                                                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                            htmlFor="default"
                                                        >
                                                            Company Description <span className="text-[#C00000]">*</span>
                                                        </label>
                                                        <div className="ck-editor-sec">
                                                            <MyEditor
                                                            // key={index}
                                                            // onChange={(event, editor) => {
                                                            //     const data = editor.getData();
                                                            //     setDescription(data, index, value.resourceDescriptionImage);
                                                            // }}
                                                            // data={value.resourceDescription}setReadMoreDescription
                                                            // onChange={this.handleReadMoreEditorChange}
                                                            // data={this.categoryCampaignItem.readMoreDescription}
                                                            />
                                                        </div>
                                                        {/* {(!this.state.categoryCampaignItem.readMoreDescription) ?
                                                            <ValidationText
                                                                error={"Please enter read more description"}
                                                            />
                                                            :
                                                            null
                                                        } */}
                                                    </div>
                                                    {/* Company Description Description End*/}

                                                </div>
                                            </div>
                                            <div className='xl:col-span-6'>
                                                <div className='grid grid-cols-12 gap-6'>
                                                    <div className='xl:col-span-3'>
                                                    </div>
                                                    <div className='xl:col-span-9 '>
                                                        <div className='img-logo-upload text-center'>
                                                            <img src='./default-img.png' className='w-full h-[200px] object-cover object-center' alt=''></img>
                                                            <p className='mt-5 px-14 text-center font-normal text-2xl theme-color'>
                                                                (Dimension: 350px by 200px Maximum Size: 1MB)
                                                            </p>
                                                            <button className="px-10 mt-5 py-2 btn-red-border text-xl border text-[#C00000]">
                                                                Upload Feature Image
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                            </div>
                                            <div className='xl:col-span-12'>
                                                <div className='grid grid-cols-12 gap-6'>
                                                    <div className='filter-types xl:col-span-3'>
                                                        <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Company Logo <span className='text-[#C00000]'>*</span></label>
                                                        <button className="px-10 py-2 btn-red-border text-xl border text-[#C00000]">
                                                            Upload Logo
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='grid grid-cols-12 gap-6 mt-7'>
                                                    <div className='filter-types xl:col-span-3'>
                                                        <div className="flex items-center h-12">
                                                            <div className="text-xl font-medium theme-color mr-7">{this.state.setToggle3 ? 'Community Spotlight' : 'Community Spotlight'}</div>
                                                            <div className="form-switch">
                                                                <input type="checkbox" id="switch-3" className="sr-only" checked={this.state.corporateDetails.isCommunitySpotlight} onChange={() => this.setState({ setToggle3: !this.state.corporateDetails.isCommunitySpotlight })} />
                                                                <label className="theme-bg-color" htmlFor="switch-3">
                                                                    <span className="bg-white shadow-sm" aria-hidden="true"></span>
                                                                    <span className="sr-only">Switch label</span>
                                                                </label>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Modal footer Button*/}
                                    <div className="flex flex-wrap justify-end space-x-10 mb-7">
                                        <button className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white" onClick={(e) => { e.stopPropagation(); this.setState({ setAddeditModalOpen: false }) }}>Cancel</button>
                                        <button className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white">Save</button>
                                    </div>
                                </div>
                            </ModalBasic>
                        </main>
                    </div>
                </div>
            </div>
        )
    }
}