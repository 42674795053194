import React, { Component } from "react";

export default class UnAuthorized extends Component {
  render() {
    return (
      <div>
        <div className="flex items-center flex-col justify-center">
          <img src="../page_not_found.png" alt=""/>
          <h1 className="mt-14 text-4xl font-bold theme-color">
              <span className="theme-active-color">UnAuthorized</span> for this page.
          </h1>
        </div>
      </div>
    );
  }
}