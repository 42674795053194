import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import Table from "../../components/table/Table";
import ActionContainer from "../../components/UIComponent/ActionTableComponent/actionContainer";
import ActionEditButton from "../../components/UIComponent/ActionTableComponent/ActionEditButton";
import ActionToggleButton from "../../components/UIComponent/ActionTableComponent/ActionToggleButton";
import FinanceServices from "../../services/axiosServices/apiServices/FinanceServices";
import SwalServices from "../../services/swalServices/SwalServices";
import moment from "moment";
import { encryptAES } from "../../utils/Encryption";

export default class VoucherCodeManagement extends Component {
  constructor(props) {
    super(props);
    this.financeServices = new FinanceServices();
    this.swalServices = new SwalServices();
    this.state = {
      pagination: {
        voucherCodeId: 0,
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        status: 0,
      },
      isLoading: false,
      totalResultes: 0,
      redirect: null,
      voucherCodeListData: [],
      voucherCodeId: 0,
    };
  }
  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getAllVoucherCodeList();
  };

  onEdit = (value, index) => {
    this.setState({ redirect: '/createVoucherCode/' + encryptAES(value) });
  }
  onToggleChange = (index) => {
    let voucherData = this.state.voucherCodeListData;
    let request = this.state.voucherCodeListData[index];
    request.isActiveVoucherCode = voucherData[index].isActiveVoucherCode;
    if (request.isActiveVoucherCode === true) {
      request.isActiveVoucherCode = false;
    }
    else if (request.isActiveVoucherCode === false) {
      request.isActiveVoucherCode = true;
    }
    this.activeInActiveVoucherCode(request.voucherCodeId, request.isActiveVoucherCode);
  }
  actions = (element, index, value) => {
    return element !== "voucherCodeId" ? null : (
      <td>
        <ActionContainer>
          <ActionEditButton
            value={value}
            id={index}
            index={index}
            onActionClick={this.onEdit.bind(this)}
            text="Edit"
          />
          <ActionToggleButton
            className="mr-2"
            index={index}
            // toggle={this.state.toggle}
            data={this.state.voucherCodeListData}
            onToggleChage={this.onToggleChange.bind(this)}
          />

        </ActionContainer>
      </td>
    );
  };
  componentDidMount() {
    this.getAllVoucherCodeList();
  }
  getAllVoucherCodeList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });
    this.financeServices.getAllVoucherCodeList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent != null && response.responseItem.responseContent.itemList.length > 0) {

          let list = response.responseItem.responseContent.itemList;

          list.map((key, index) => {
            key.voucherCodeEndDate = moment(key.voucherCodeEndDate).format('DD MMM YYYY (ddd)');
            if (key.isActiveVoucherCode === false) {
              key.IsActive = false;
            }
            else if (key.isActiveVoucherCode === true) {
              key.IsActive = true;
            }
            if (key.voucherCodeUserLimit === null || key.voucherCodeUserLimit === "") {
              key.voucherCodeUserLimit = "Unlimited";
            }
            return key;
          });
          this.setState({
            voucherCodeListData: list,
            totalResultes: response.responseItem.responseContent.totalCount
          });
        }
        else {
          this.setState({ voucherCodeListData: [] });
        }
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }
  activeInActiveVoucherCode = (id, activateValue) => {
    let request = {};
    request.voucherCodeId = id;
    request.isActiveVoucherCode = activateValue;
    request.updatedBy = 1;

    if (request.voucherCodeId > 0 && request.isActiveVoucherCode != null) {
      this.financeServices.activeInActiveVoucherCode(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.hasError === false) {
            if (activateValue === true) {
              this.swalServices.SuccessWithAction("Voucher has been activated successfully", "OK").then((res) => {
                this.getAllVoucherCodeList();
              });
            }
            else {
              this.swalServices.SuccessWithAction("Voucher has been deactivated successfully", "OK").then((res) => {
                this.getAllVoucherCodeList();
              });
            }
          }
          else {
            this.swalServices.Error(response.responseItem.responseContent.errorMessage);
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
      });
    }


  }

  searchByName = (value) => {

    let detail = { ...this.state.pagination };
    detail.searchText = "";

    this.setState({ pagination: detail });
    if (value.length > 2) {
      detail.searchText = value;
      detail.pageNo = 1;
      detail.pageSize = 10;
      detail.orderByColumn = "";
      detail.orderFlag = 0;
      this.getAllVoucherCodeList(detail);
      this.setState({ pagination: detail });
    }
    this.getAllVoucherCodeList();

  }

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="main-body-section">
        <div className="px-6 pt-10 pb-12 bg-white">
          <div className="grid grid-cols-12 gap-6 w-full">
            <div className="2xl:col-span-4 lg:col-span-6 col-span-12">
              <h2 className="text-xl theme-color mb-1">
                Search by voucher code
              </h2>
              <input
                id="default"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                onChange={(e) => this.searchByName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <main className="pb-10">
          <div className="w-full mx-auto">
            <div className="relative">
              <div className="pt-10 flex justify-end">
                <button
                  className="btn btn-red text-xl text-white font-bold"
                  aria-controls="add-edit-modal"
                  onClick={() => this.setState({ redirect: "/CreateVoucherCode" })}
                >
                  Add New Voucher
                </button>
              </div>
              <div className=" mt-6">
                <Table
                  columns={[
                    { name: "voucherCode", title: "Voucher Code", },
                    { name: "voucherCodeType", title: "Amount", },
                    { name: "voucherCodeUserLimit", title: "UserLimit", },
                    { name: "voucherFor", title: "Voucher Used For" },
                    { name: "voucherCodeEndDate", title: "End Date", },
                    { name: "voucherCodeStatus", title: "Status", },
                    { name: "voucherCodeId", title: "Actions", },
                  ]}
                  pagination={this.state.pagination}
                  rows={this.state.voucherCodeListData}
                  sortingColumns={[

                  ]}
                  isLoading={this.state.isLoading}
                  totalResultes={this.state.totalResultes}
                  totalCount={this.state.totalResultes}
                  setPagination={this.setPagination.bind(this)}
                  customScope={[
                    {
                      column: "voucherCodeId",
                      renderTableData: this.actions.bind(this),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
