import React from "react";
import { NavLink } from "react-router-dom";
import { encryptAES } from "../../utils/Encryption";

function PendingApproval(props) {
  return (
    <div>
      <div className="grid gap-8 py-3 grid-cols-1">
        <div className="relative justify-center detailedBlock p-4  pt-8 rounded-lg">
          <div className="flex flex-row items-center justify-between title pl-24">
            <div className="icon">Pending Approval</div>
          </div>
          <div className="mb-14">
            {props.dashboardDetails.adminDashboardPendingApprovalList && props.dashboardDetails.adminDashboardPendingApprovalList.length > 0 ?
              <>
                {props.dashboardDetails.adminDashboardPendingApprovalList.map((event, key) => {
                  return (
                    <div className="grid gap-0 grid-cols-6 items-center text-center py-4 border-b border-[#606060] pl-24" key={key}>
                      <div className="col-span-2 text-left">
                        {event.eventName}
                      </div>
                      <div className="py-2 border-[#606060] flex justify-center align-center relative">
                        <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%]  translate-y-[-50%]"></div>
                        {event.eventTypeName}
                      </div>
                      <div className="py-2 border-[#606060] flex justify-center align-center relative">
                        <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
                        <div>
                          <div>{event.eventSessionCount} Session</div>
                        </div>
                      </div>
                      <div className="py-2 border-[#606060] flex justify-center align-center relative">
                        <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
                        <div>
                          <div>{event.eventStatusName}</div>
                        </div>
                      </div>
                      <div className="py-2 border-[#606060] flex justify-center align-center relative">
                        <div className="border-[#606060] h-[35px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
                        <div>
                          <div>
                            <NavLink className="btn btn-edit rounded-lg"
                              end
                              to={`/CategoryCampaignTagging/eventId?='${encryptAES(event.eventId)}`}
                            >
                              Edit tags
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}

              </> : null}

          </div>
          <div className="bottom-btns absolute right-0 bottom-0 m-3">
            <NavLink className="btn-gray bg-gray-600 text-white px-4 py-2 rounded-lg inline-flex items-center" to="/ViewEditEntries">
              View Details
              <span className="pl-2">&#62;</span>
            </NavLink>
          </div>

        </div>
      </div>
    </div>
  );
}

export default PendingApproval;
