import React from "react";
import DatePicker from "../../datePicker/DatePicker";
import DropdownSelect from "../../dropdown/Dropdown";
import TextInput from "../../UIComponent/TextInput";
import moment from "moment";
import MultiSelectDropdown from "../../dropdown/MultiSelectDropdown";
import Option from "../../dropdown/multiSelectDropdownOption/MultiSelectOption";

function SGTechInterest(props) {
    return (
        <div className="w-full">
            {/* SGTech Interest Start */}
            <div className="2xl:py-5 2xl:pt-5 lg:py-5 lg:pt-5">
                <div className="grid grid-cols-12 gap-6 w-full">
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                    Reasons to Join SLA
                                </h2>
                                <MultiSelectDropdown
                                    drpIdentity="reason"
                                    options={props.sgTech}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                        Option,
                                    }}
                                    onChange={props.handleChangeMultiDropdownInterest}
                                    allowSelectAll={true}
                                    // value={props.sgtechInterest.selectedReason}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                    Sponsorship
                                </h2>
                                <MultiSelectDropdown
                                    drpIdentity="sponsorship"
                                    options={props.sponsorship}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                        Option,
                                    }}
                                    onChange={props.handleChangeMultiDropdownInterest}
                                    allowSelectAll={true}
                                    // value={props.sgtechInterest.selectedSponsorship}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                                    Business Matching
                                </h2>
                                <MultiSelectDropdown
                                    drpIdentity="matching"
                                    options={props.matching}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                        Option,
                                    }}
                                    onChange={props.handleChangeMultiDropdownInterest}
                                    allowSelectAll={true}
                                    // value={props.sgtechInterest.selectedMatching}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                                    What kind of strategic programmes will you be interested in?
                                </h2>
                                <MultiSelectDropdown
                                    drpIdentity="program"
                                    options={props.programs}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                        Option,
                                    }}
                                    onChange={props.handleChangeMultiDropdownInterest}
                                    allowSelectAll={true}
                                    // value={props.sgtechInterest.selectedProgram}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="2xl:col-span-11 lg:col-span-12 col-span-12">
                                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                    Updated Date
                                    {/* <span className="text-[#c00000]">*</span> */}
                                </h2>


                                <div className="disabled-input">
                                    {/* {props.sgtechInterest.updatedAt === "" || props.sgtechInterest.updatedAt === null ? */}

                                        {/* <TextInput
                                            disabled={true}
                                            placeholder=""
                                            type="text"
                                            name="linkedIn"
                                            value="None"
                                            identity="BasicInformation"
                                        /> */}
                                        {/* : */}
                                        <DatePicker
                                            isDisable={true}
                                            name="updatedAt"
                                            handleChange={props.handleChange}
                                            identity="updatedAt"
                                            // value={moment(props.sgtechInterest.updatedAt).format("yyyy-MM-DD")}
                                        />
                                    {/* } */}
                                </div>
                                {/* <div className="disabled-input">
                                    <DatePicker
                                        name="updatedDate"
                                        isDisable={true}

                                        identity="SIISInformation"
                                        value={moment(props.sgtechInterest.updatedAt).format("yyyy-MM-DD")}

                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="2xl:col-span-11 lg:col-span-12 col-span-12">
                                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                    Updated By
                                    {/* <span className="text-[#c00000]">*</span> */}
                                </h2>
                                {/* <div className="disabled-input dropdwn-input-email flex items-center">
                                    <TextInput
                                        placeholder=""
                                        type="text"
                                        name="Updated"
                                    // value={
                                    //   props.corporateBasicInformation.areaofExpertise
                                    // }
                                    // identity="BasicInformation"
                                    // handleChange={props.handleChange}
                                    // onBlur={(e) =>
                                    //   props.validate(
                                    //     "areaofExpertise",
                                    //     props.corporateBasicInformation
                                    //   )
                                    // }

                                    // value={this.state.searchbyParent}
                                    // handleChange={(event) => {
                                    //   this.setState({ searchByName: event.target.value });
                                    // }}
                                    />

                                    <Validation error={props.validState.error.areaofExpertise} />
                                </div> */}
                                <DropdownSelect
                                    disabled={true}
                                    drpIdentity={"updatedById"}
                                    optionArray={props._By}
                                    setFilterParameters=""
                                    // value={props.sgtechInterest.updatedBy}
                                // onBlur={(e) => props.validate("updatedById", props.corporateSIISAccount)}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* Save Button */}
            <div className="grid grid-cols-12 gap-6 pb-10">
                <div className="xl:col-span-12 lg:col-span-12 col-span-12 px-10">
                    <a
                        className="flex items-center justify-end"
                    // href="/CreateCorporateMembers"
                    >
                        <button
                            className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold"
                            onClick={() => props.addEditSGTechInterestProfile()}
                        >
                            Save
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
}
export default SGTechInterest;