const ToggleButton = (props) => {
    return (
        <>
            <div className="">

                <div className="w-full toggle-btn-sec">
                    <div className="flex items-center space-x-6 toggle-switch-part">
                        <div className="block text-xl font-normal theme-color member-directory-top-boxes lg:flex lg:items-center">
                            {/* {props.setToggle ? props.label : props.label} */}
                            {props.label}

                            {/* <span className='text-[#C00000]'>*</span> */}
                        </div>
                        <div className="form-switch member-directory-top-boxes">
                            <input
                                type="checkbox"
                                // id="switch-2"
                                id={'switch' + props.index}
                                name="profile"
                                value={props.setToggle}
                                className="sr-only"
                                checked={props.setToggle}
                                onChange={(e) => props.onHandleToggle(props.setToggle, e, props.index, props.identity)}
                                disabled={props.disabled ? true : false}
                            />
                            <label className="bg-[#757575]"
                                htmlFor={'switch' + props.index}
                            // htmlFor="switch-2"
                            >
                                <span className="bg-white shadow-sm" aria-hidden="true"></span>
                                <span className="sr-only">Switch label</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ToggleButton;
