import React from "react";
import ModalBasic from "../../../pages/component/FormModal";
import ValidationText from "../../../utils/validation/ValidationText";
import DropdownSelect from "../../dropdown/Dropdown";
import TextInput from "../../UIComponent/TextInput";

const AddExcoModal = (props) => {
    return (
        <ModalBasic
            id="add-exco-member-modal"
            modalOpen={props.setEXCOMemberModal}
            setModalOpen={props.setOpenModal}
            title="Add EXCO Member"
        >
            <div className="px-14 py-4">
                <div className="grid grid-cols-2 gap-12 border-b-[1px] border-zinc-300 pb-10">
                    <div className="">
                        <h2 className="text-xl mb-1">Email Address<span className='text-[#C00000]'>*</span></h2>
                        <TextInput
                            placeholder=""
                            type="text"
                            name="email"
                            value={props.excoMemberData.email}
                            handleChange={props.handleChange}
                            onBlur={props.validateField}
                            identity="email"
                        />
                        <ValidationText error={props.validState.error.email} />
                    </div>
                    <div className="2xl:pt-7 lg:pt-7">
                        <button className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white font-bold"
                            onClick={() => props.searchExcoMember()}
                        >Search</button>
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-12 pt-12">
                    <div className="">
                        <h2 className="text-xl mb-1">First Name<span className='text-[#C00000]'>*</span></h2>
                        <div className="disabled-input">
                            <TextInput
                                placeholder=""
                                type="text"
                                name="firstName"
                                value={props.excoMemberData.firstName}
                                handleChange={props.handleChange}
                                onBlur={props.validateField}
                                identity="firstName"
                                disabled={true}
                            />
                        </div>
                        {/* <ValidationText error={props.validState.error.firstName}/>          */}

                    </div>
                    <div className="">
                        <h2 className="text-xl mb-1">Last Name<span className='text-[#C00000]'>*</span></h2>
                        <div className="disabled-input">
                            <TextInput
                                placeholder=""
                                type="text"
                                name="lastName"
                                value={props.excoMemberData.lastName}
                                handleChange={props.handleChange}
                                onBlur={props.validateField}
                                identity="lastName"
                                disabled={true}
                            />
                        </div>
                        {/* <ValidationText error={props.validState.error.lastName}/>                  */}

                    </div>
                    {props && props.excoMemberData.companyName === '' ?
                        <>
                            <div className="">
                                <h2 className="text-xl mb-1">Company<span className='text-[#C00000]'>*</span></h2>
                                {/* <TextInput
                            placeholder=""
                            type="text"
                            name="corporateMemberId"
                            value={props.excoMemberData.corporateMemberId}
                            handleChange={props.handleChange}
                        /> */}
                                <DropdownSelect
                                    disabled={true}
                                    drpIdentity={"CorporateID"}
                                    optionArray={props.organisationList}
                                    setFilterParameters={props.setFilterParameters}
                                    value={props.excoMemberData.corporateMemberId}
                                />
                                {/* <ValidationText error={props.validState.error.corporateMemberId}/>                  */}

                            </div>
                        </>
                        :
                        <>
                            <div className="">
                                <h2 className="text-xl mb-1">Company<span className='text-[#C00000]'>*</span></h2>
                                <div className="disabled-input">
                                    <TextInput
                                        placeholder=""
                                        type="text"
                                        name="companyName"
                                        value={props.excoMemberData.companyName}
                                        //handleChange={props.handleChange}
                                        //onBlur={props.validateField}
                                        identity="companyName"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </>
                    }

                    <div className="">
                        <h2 className="text-xl mb-1">EXCO Position<span className='text-[#C00000]'>*</span></h2>
                        <DropdownSelect
                            drpIdentity={"ExcoPosition"}
                            optionArray={props.excoPosition}
                            setFilterParameters={props.setFilterParameters}
                            value={props.excoMemberData.excoPositionId}
                        />
                        <ValidationText error={props.validState.error.excoPositionId} />

                    </div>
                    <div className="">
                        <h2 className="text-xl mb-1">Description<span className='text-[#C00000]'>*</span></h2>
                        {/* <TextInput
                            placeholder=""
                            type="text"
                            name="description"
                            value={props.excoMemberData.description}
                            handleChange={props.handleChange}
                            onBlur={props.validateField}
                            identity="description"
                        />
                        <ValidationText error={props.validState.error.description} /> */}
                        <textarea
                            id="description"
                            className="signup-text-area form-input rounded-none w-full shadow-red  text-lg"
                            name="description"
                            rows="3"
                            value={props.excoMemberData.description}
                            onChange={(event) =>
                                props.handleChange(event, "EngagementTracking")
                            }
                            onBlur={(e) =>
                                props.validateField(
                                    "description",
                                    props.excoMemberDetails
                                )
                            }
                        ></textarea>
                        <ValidationText error={props.validState.error.description} />
                    </div>
                    {/* <div className="">
                        <h2 className="text-xl mb-1">Ordering <span className='text-[#C00000]'>*</span></h2>
                        <DropdownSelect
                            drpIdentity={"Ordering"}
                            optionArray={props.ordering}
                            setFilterParameters={props.setFilterParameters}
                            value={props.excoMemberData.orderingId}
                        />
                        <ValidationText error={props.validState.error.orderingId} />

                    </div> */}
                </div>
                <div className="grid grid-cols-6 gap-4 px-6 py-16">
                    <button
                        className="col-start-1 col-end-3  w-32 px-7 py-2 bg-zinc-500 text-lg text-white font-bold"
                        onClick={(event) => props.onClose(event)}
                    >
                        Cancel
                    </button>
                    <button className="col-end-8 col-span-1  w-32 px-7 py-2 btn-sm btn-red text-lg text-white font-bold"
                        onClick={() => props.saveButtonClick()}
                    >Add</button>
                </div>
            </div >
        </ModalBasic>
    );
}
export default AddExcoModal;