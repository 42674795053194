import { AcceptTotalMTORegistryAmountInReconciliation, AddEditMTOTaskAndSendPaymentLink, GetAllMTOTasksList, GetMTOPricing, GetMTORegistryDetailsById, MTOTaskListSendClarificationMail, UpdateMTOPricingData } from '../ApiEndPoints';
import {axiosGetMultiParams, axiosGetAuthorize, axiosPostAuthorize } from '../AxiosRequests';

export default class MTOServices {

    async getAllMTOTasksList(request) {
        return axiosPostAuthorize(GetAllMTOTasksList, request)
    }

    async getMTORegistryDetailsById(request) {
        return axiosGetAuthorize(GetMTORegistryDetailsById, request)
    }

    async getMTOPricing(request) {
        return axiosGetAuthorize(GetMTOPricing, request)
    }

    async updateMTOPricingData(request) {
        return axiosPostAuthorize(UpdateMTOPricingData, request)
    }

    async addEditMTOTaskAndSendPaymentLink(request) {
        return axiosPostAuthorize(AddEditMTOTaskAndSendPaymentLink, request)
    }

    async mtoTaskListSendClarificationMail(request) {
        return axiosPostAuthorize(MTOTaskListSendClarificationMail, request)
    }

    async acceptTotalMTORegistryAmountInReconciliation(request) {
        return axiosPostAuthorize(AcceptTotalMTORegistryAmountInReconciliation, request);
    }

}