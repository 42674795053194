import React, { useState } from "react";
import SwalServices from '../../services/swalServices/SwalServices';
import { getAuthProps } from "../../utils/AuthenticationLibrary";
import { removeAllCookies } from "../../utils/cookies/CookieHandler";
import { ErrorMessage } from '../../utils/Messages';
import { isValidForm, validate } from '../../utils/validation/CommonValidator';
import ValidationText from '../../utils/validation/ValidationText';
import AuthenticationServices from "../../services/axiosServices/apiServices/AuthenticationServices";
import { Navigate, Routes } from "react-router-dom";
import { Component } from "react-image-crop";
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader"

export default class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.adminServices = new AuthenticationServices();
        this.swalServices = new SwalServices();
        this.state = {
            isLoading: false,
            redirect: null,
            changePasswordRequest: {
                adminId: null,
                isActive: false,
                newPassword: "",
                oldPassword: "",
                confirmPassword: "",
            },

            validationRules: {
                oldPassword: [
                    {
                        type: "require",
                        message: ErrorMessage.FieldRequired.replace(
                            "{0}",
                            "old password"
                        ),
                    },
                ],
                newPassword: [
                    {
                        type: "require",
                        message: ErrorMessage.FieldRequired.replace(
                            "{0}",
                            "new password"
                        ),
                    },
                    {
                        type: "isFormatPassword",
                        message: ErrorMessage.PasswordFormat,
                    },
                ],
                confirmPassword: [
                    {
                        type: "require",
                        message: ErrorMessage.FieldRequired.replace(
                            "{0}",
                            "confirm password"
                        ),
                    },
                    {
                        type: "compare",
                        compareEle: "newPassword",
                        message: ErrorMessage.ComparePassword,
                    },
                ],
            },
            validState: {
                isValid: true,
                error: {},
            },
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        let detail = { ...this.state.changePasswordRequest };
        detail[name] = value;
        this.setState({ changePasswordRequest: detail });
    };

    //validation for individual sign up every field
    validateField = (key) => {
        const newValidState = validate(
            key,
            this.state.changePasswordRequest,
            this.state.validationRules,
            this.state.validState
        );
        this.setState({ validState: newValidState });
    };

    // validation for individual sign up whole form
    isValidateAllFields = () => {
        const newValidState = isValidForm(
            this.state.changePasswordRequest,
            this.state.validationRules,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };

    changePassword = () => {
        if (this.isValidateAllFields()) {
            let cookie = getAuthProps();
            if (cookie) {
                let adminId = cookie.adminUserId
                if (adminId > 0) {
                    let request = {};
                    request.adminId = adminId;
                    request.oldPassword = this.state.changePasswordRequest.oldPassword;
                    request.newPassword = this.state.changePasswordRequest.newPassword;
                    request.isActive = cookie.isAuthenticated === true ? true : false;

                    this.setState({ isLoading: true })
                    this.adminServices.changePasswordForAdmin(request).then((response) => {
                        if (response.statusCode === 200 && response.responseItem != null) {
                            let authDetails = response.responseItem.responseContent;
                            authDetails.isAdmin = false;
                            removeAllCookies();
                            this.setState({ isLoading: false })
                            this.resetChangePassword();
                            this.swalServices.SuccessWithAction("You have changed your password Successfully. Please login again with your new password.", "Ok")
                                .then((response) => {
                                    this.setState({ route: "/Login" });
                                });
                        } else {
                            this.swalServices.Error(ErrorMessage.InvalidAuthorization);
                            this.setState({ isLoading: false })
                        }
                    });
                }
            }
        }
    };

    resetChangePassword = () => {
        const resetState = {
            adminId: null,
            isActive: false,
            newPassword: "",
            oldPassword: "",
            confirmPassword: "",
        };
        this.setState({ changePasswordRequest: resetState });
    };


    render() {
        if (this.state.route != null) {
            return <Navigate to={this.state.route} />;
        }
        return (
            <div>
                {/* <h1 className="text-3xl text-[#c00000] font-bold leading-loose pb-4 mobile-20">
                    Change Password
                </h1> */}
                <div className="bg-white border border-[#c9c9c9] md:mb-10">
                    <div className="w-full 2xl:px-8 lg:px-4 px-4 2xl:mt-0 lg:mt-0 mt-5">
                        <div className="grid grid-cols-12 gap-6 w-full mt-12">
                            <div className="col-span-4">
                                <div className="input-sec">
                                    <h2 className="text-xl text-[#757575] font-bold mb-3">
                                        Old Password
                                        <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <input
                                        className={`outline-none appearance-none w-full custom-input`}
                                        type="password"
                                        name="oldPassword"
                                        value={this.state.changePasswordRequest.oldPassword}
                                        onChange={(e) => this.handleChange(e)}
                                        onBlur={() => this.validateField("oldPassword")}
                                    />
                                    <ValidationText error={this.state.validState.error.oldPassword} />
                                </div>
                            </div>
                            <div className="col-span-4">
                                <div className="input-sec">
                                    <h2 className="text-xl text-[#757575] font-bold mb-3">
                                        New Password
                                        <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <input
                                        className={`outline-none appearance-none w-full custom-input`}
                                        type="password"
                                        name="newPassword"
                                        value={this.state.changePasswordRequest.newPassword}
                                        onChange={(e) => this.handleChange(e)}
                                        onBlur={() => this.validateField("newPassword")}
                                    />
                                    <ValidationText error={this.state.validState.error.newPassword} />
                                </div>
                            </div>
                            <div className="col-span-4">
                                <div className="input-sec">
                                    <h2 className="text-xl text-[#757575] font-bold mb-3">
                                        Confirm Password
                                        <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <input
                                        className={`outline-none appearance-none w-full custom-input`}
                                        type="password"
                                        name="confirmPassword"
                                        value={this.state.changePasswordRequest.confirmPassword}
                                        onChange={(e) => this.handleChange(e)}
                                        onBlur={() => this.validateField("confirmPassword")}
                                    />
                                    <ValidationText error={this.state.validState.error.confirmPassword} />
                                </div>

                            </div>
                            <div className="col-span-12">
                                <span className="mobile-14">
                                    Password must be at least 6 alphanumeric characters and contain at least one special character such as @#$%. Need to include both capital and small letters.
                                </span>
                            </div>
                        </div>

                        <div className="grid grid-cols-12 gap-6 pb-10 mt-10 flex items-end">
                            <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                                <a className="flex">
                                    {this.state.isLoading ?
                                        <ButtonLoader />
                                        :
                                        <button className="btn btn-red text-xl text-white font-semibold" type="submit" onClick={() => this.changePassword()} >
                                            Change Password
                                        </button>
                                    }
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
