import React, { Component } from 'react';
import Table from '../../components/table/Table';
import ActionContainer from '../../components/UIComponent/ActionTableComponent/actionContainer';
import GoGlobalContactRequestDetailsModal from '../../components/goGlobal/goGlobalContactRequestDetailsModal';
import moment from 'moment';

//import API services
import GoGlobalServices from '../../services/axiosServices/apiServices/GoGlobalService';
import SwalServices from '../../services/swalServices/SwalServices';

export default class GoGlobalContactRequest extends Component {
    constructor(props) {
        super(props);
        this.GoGlobalServices = new GoGlobalServices();
        this.SwalServices = new SwalServices();
        this.state = {
            redirect: null,
            pagination: {
                orderByColumn: "",
                orderFlag: 0,
                pageNo: 1,
                pageSize: 10,
                searchText: "",
                requestDate: "",
            },
            totalResultes: "",
            councilIndex: 0,
            isLoading: false,
            setGoGlobalContactRequestDetails: false,
            goGlobalContactRequestList: [],
            getMemebrDirectoryEnquiryById: {},
            setMembershipDirectoryEnquiry: false,
            contactRequestIndex: 0,
        }
    }

    //Method declarationsection
    componentDidMount() {
        this.getGoGlobalContactRequestList();
    }

    //API method declaration start

    // Get all membership directory enquiry list
    getGoGlobalContactRequestList = (pagination = this.state.pagination) => {
        this.setState({ isLoading: true });
        this.GoGlobalServices.getGoGlobalContactRequestList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let newGoGlobalContactRequestList = response.responseItem.responseContent.itemList;
                newGoGlobalContactRequestList.forEach((key, index) => {
                    key.requestDate = moment(key.requestDate).format("DD MMM YYYY, hh.mm");
                });
                this.setState({ goGlobalContactRequestList: newGoGlobalContactRequestList });
                this.setState({ totalResultes: response.responseItem.responseContent.totalCount })
            } else {
                this.SwalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    };

    //Internal method declaration section start
    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getGoGlobalContactRequestList();
    };

    showDirectoryDetail = (e, index, id) => {
        this.setState({ contactRequestIndex: index });
        e.stopPropagation();
        this.setState({ setGoGlobalContactRequestDetails: true });
    };

    searchByName = (value, identity) => {
        let detail = this.state.pagination;
        if (identity === "companyName") {
            detail.pageNo = 1;
            detail.pageSize = 10;
            detail.searchText = value;
            this.setState({ pagination: { ...detail } });
        }
    };

    // Handle change for request date
    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = this.state.pagination;
        detail[name] = value;
        this.setState({
            pagination: { ...detail }
        });
    }

    actions = (element, index, value) => {
        return element !== "goGlobalPartnerDirectoryEnquiryId" ? null : (
            <td>
                <ActionContainer >
                    <h2
                        className='underline text-[#757575] cursor-pointer'
                        onClick={(e) => this.showDirectoryDetail(e, index, value)}
                    >Details</h2>
                </ActionContainer >
            </td>
        );
    };

    render() {
        return (
            <div className="main-body-section">
                <div className="custom-card shadow-lg">
                    <div className="filter-info grid grid-cols-12 gap-6">
                        <div className="xl:col-span-12 lg:col-span-6 col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="filter-types xl:col-span-4 lg:col-span-5 col-span-12 pr-12">
                                    <label
                                        className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                        htmlFor="default"
                                    >
                                        Company Name
                                    </label>
                                    <input
                                        id="companyName"
                                        name="companyName"
                                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                        type="text"
                                        onChange={(event) => this.searchByName(event.target.value, "companyName")}
                                    />
                                </div>
                                <div className="filter-types xl:col-span-3 lg:col-span-5 col-span-12 pr-12">
                                    <label
                                        className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                        htmlFor="default"
                                    >
                                        Date
                                    </label>
                                    <input
                                        id="requestDate"
                                        name="requestDate"
                                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                        type="date"
                                        value={this.state.pagination.requestDate ? this.state.pagination.requestDate : ""}
                                        onChange={(event) => this.handleChange(event)}
                                    />
                                </div>
                                <div className="filter-types xl:col-span-2 lg:col-span-5 col-span-12 pr-12">
                                </div>
                                <div className="xl:col-span-3 lg:col-span-6 col-span-12">
                                    <div className=" patner-search flex items-center justify-between pt-11">
                                        <div className="search">
                                            <button
                                                className="btn btn-red text-xl text-white font-bold"
                                                onClick={() => {
                                                    this.getGoGlobalContactRequestList();
                                                }}
                                            >
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <main className="pb-10 pt-8">
                    <div className="w-full mx-auto">
                        <div className="relative">
                            <div>
                                <div className=" mt-6">
                                    <Table
                                        columns={[
                                            { name: "requestDate", title: "Date and Time", },
                                            { name: "requestCompanyName", title: "Request Company Name" },
                                            { name: "targetCompanyName", title: "Target Company Name" },
                                            { name: "requestCompanyContactName", title: "Request Company Contact Name" },
                                            { name: "requestCompanyContactEmail", title: "Request Company Contact Email" },
                                            { name: "goGlobalPartnerDirectoryEnquiryId", title: "Action" },
                                        ]}
                                        pagination={this.state.pagination}
                                        rows={this.state.goGlobalContactRequestList}
                                        sortingColumns={["requestDate"]}
                                        isLoading={this.state.isLoading}
                                        totalResultes={this.state.totalResultes}
                                        totalCount={this.state.totalResultes}
                                        setPagination={this.setPagination.bind(this)}
                                        customScope={[
                                            {
                                                column: "goGlobalPartnerDirectoryEnquiryId",
                                                renderTableData: this.actions.bind(this),
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <div className="membership-task-list-process-sec">
                    <GoGlobalContactRequestDetailsModal
                        contactRequestIndex={this.state.contactRequestIndex}
                        goGlobalContactRequestList={this.state.goGlobalContactRequestList}
                        goGlobalContactRequestModal={this.state.setGoGlobalContactRequestDetails}
                        setOpenModal={() => this.setState({ setGoGlobalContactRequestDetails: false })}
                        onClose={(e) => {
                            e.stopPropagation();
                            this.setState({ setGoGlobalContactRequestDetails: false });
                        }}
                    />
                </div>
            </div>
        )
    }
}