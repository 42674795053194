import { axiosGetAuthorize, axiosPostAuthorize } from '../AxiosRequests';
import { GetMembershipCorporateList, ActiveInActiveMembershipCorporate, GetAllCorporateStatus, DeleteMembershipCorporate } from '../ApiEndPoints'

export default class MembershipCorporateServices {

    async getMembershipCorporateList(request) {
        return axiosPostAuthorize(GetMembershipCorporateList, request)
    }

    async activeInActiveCorporateMember(request) {
        return axiosPostAuthorize(ActiveInActiveMembershipCorporate, request)
    }

    async deleteCorporateMember(request) {
        return axiosPostAuthorize(DeleteMembershipCorporate, request)
    }

    async getAllCorporateStatus() {
        return axiosGetAuthorize(GetAllCorporateStatus);
    }
}
