import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import CreateEntryTab from "../../components/entrymanagement/createEntry/CreateEntryTab";
import CommonServices from "../../services/axiosServices/apiServices/CommonServices";
import EntryManagementServices from "../../services/axiosServices/apiServices/EntryManagementServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { isValidForm } from "../../utils/validation/CommonValidator";
import { ValidationRulesForBasicInformation } from "../../utils/validation/event/EventValidations";
import { CommonValidationMessages } from "../../utils/Messages";
import { checkParameters } from "../../utils/Encryption";

// const initialState = {
//   eventId: 0,
//   route: "",
//   resFile: {
//     filename: "",
//   },

//   openTab: 1,
//   pageDescription: [
//     {
//       resourceDescriptionId: 1,
//       resourceDescription: "",
//       resourceDescriptionImage: "",
//       validate: {
//         description: false,
//         descriptionLength: false,
//         // resourceDescriptionImage: false
//       },
//     },
//   ],
//   entryTopics: [],
//   entryType: [],
//   trackNo: [
//     {
//       value: 1,
//       label: "Trake1",
//     },
//     {
//       value: 2,
//       label: "Trake2",
//     },
//     {
//       value: 3,
//       label: "Trake3",
//     },
//   ],
//   selectSpeaker: [
//     {
//       value: 1,
//       label: "Speaker1",
//     },
//     {
//       value: 2,
//       label: "Speaker2",
//     },
//     {
//       value: 3,
//       label: "Speaker3",
//     },
//   ],
//   creatEntry: {
//     selectedTopicIds: [],
//     selectedTypeIds: [],
//     selectedTrackNo: [],
//     selectedSpeaker: [],
//     SalesEndDateDay: [],
//     startupMemberIds: [],
//     maxGuestIds: [],
//   },
//   tempData: {
//     userTime: "",
//   },
//   startupMember: [
//     {
//       value: 4,
//       label: "Non Member",
//     },
//     {
//       value: 1,
//       label: "Ordinary Member",
//     },
//     {
//       value: 2,
//       label: "Associate Member",
//     },
//     {
//       value: 5,
//       label: "Alumni",
//     },
//     {
//       value: 3,
//       label: "Startup Member",
//     },
//     {
//       value: 6,
//       label: "Others",
//     },
//   ],
//   salesEndDateDays: [
//     {
//       value: 1,
//       label: "1",
//     },
//     {
//       value: 2,
//       label: "2",
//     },
//     {
//       value: 3,
//       label: "3",
//     },
//     {
//       value: 4,
//       label: "5",
//     },
//     {
//       value: 5,
//       label: "6",
//     },
//   ],
//   pricingDetailData: [{}, {}],
//   maxAttendees: [
//     {
//       value: 1,
//       label: "5",
//     },
//     {
//       value: 2,
//       label: "10",
//     },
//     {
//       value: 3,
//       label: "20",
//     },
//     {
//       value: 4,
//       label: "30",
//     },
//     {
//       value: 5,
//       label: "40",
//     },
//   ],
//   maxGuest: [
//     {
//       value: 0,
//       label: "0",
//     },
//     // {
//     //   value: 1,
//     //   label: "No Limit",
//     // },
//     {
//       value: 2,
//       label: "1",
//     },
//     {
//       value: 3,
//       label: "2",
//     },
//     {
//       value: 4,
//       label: "3",
//     },
//     {
//       value: 5,
//       label: "4",
//     },
//   ],
//   validState: {
//     isValid: true,
//     error: [],
//   },
//   validStateAdditionalDescription: {
//     isValid: true,
//     error: [],
//   },
//   validStateSpeaker: {
//     isValid: true,
//     error: [],
//   },
//   validStateModerator: {
//     isValid: true,
//     error: [],
//   },
//   validStatePrice: {
//     isValid: true,
//     error: [],
//   },
//   basicInformationDetails: {
//     eventId: 0,
//     eventName: "",
//     eventTypeId: 1,
//     eventTopicId: "",
//     eventImage: "",
//     eventRegistrationApproval: "false",
//     eventNotificationPeriod: "",
//     eventAbout: "",
//     outlineDescription: "",
//     speakerDescription: "",
//     readMoreDescription: "",
//     isDietaryRequirement: "",
//     selectedTopicIds: [],
//     additionalDescription: [],
//     additionalSpeaker: [],
//     additionalModerator: [],
//     speakerImage: "",
//     eventFormStartDate: "",
//     eventFormEndDate: "",
//     eventSalesEndDate: 0,

//   },
//   packageList: [],

//   feedbackQuestions: {
//     required: false,

//   },
//   additionalDescription: [],
//   additionalSpeaker: [],
//   additionalModerator: [],
// }

export default class CreateEntry extends Component {
  constructor(props) {
    super(props);

    this.entryServices = new EntryManagementServices();
    this.swalServices = new SwalServices();
    this.commonServices = new CommonServices();
    this.myRef = React.createRef();
    this.state = {
      eventId: 0,
      route: "",
      resFile: {
        filename: "",
      },

      openTab: 1,
      pageDescription: [
        {
          resourceDescriptionId: 1,
          resourceDescription: "",
          resourceDescriptionImage: "",
          validate: {
            description: false,
            descriptionLength: false,
            // resourceDescriptionImage: false
          },
        },
      ],
      entryTopics: [],
      entryType: [],
      trackNo: [
        {
          value: 1,
          label: "Trake1",
        },
        {
          value: 2,
          label: "Trake2",
        },
        {
          value: 3,
          label: "Trake3",
        },
      ],
      selectSpeaker: [
        {
          value: 1,
          label: "Speaker1",
        },
        {
          value: 2,
          label: "Speaker2",
        },
        {
          value: 3,
          label: "Speaker3",
        },
      ],
      creatEntry: {
        selectedTopicIds: [],
        selectedTypeIds: [],
        selectedTrackNo: [],
        selectedSpeaker: [],
        SalesEndDateDay: [],
        startupMemberIds: [],
        maxGuestIds: [],
      },
      tempData: {
        userTime: "",
      },
      startupMember: [
        {
          value: 4,
          label: "Non Member",
        },
        {
          value: 1,
          label: "Ordinary Member",
        },
        {
          value: 2,
          label: "Qualifying  Member",
        },
        {
          value: 3,
          label: "Associate  Member",
        },
        {
          value: 4,
          label: "Others",
        },
        // {
        //   value: 5,
        //   label: "Others",
        // },
        // {
        //   value: 6,
        //   label: "Affliate",
        // },
        // {
        //   value: 7,
        //   label: "DT-SME",
        // },
        // {
        //   value: 8,
        //   label: "Members",
        // },
      ],
      salesEndDateDays: [
        {
          value: 2,
          label: "1",
        },
        {
          value: 3,
          label: "2",
        },
        {
          value: 4,
          label: "3",
        },
        {
          value: 5,
          label: "4",
        },
        {
          value: 6,
          label: "5",
        },
      ],
      pricingDetailData: [{}, {}],
      maxAttendees: [
        {
          value: 1,
          label: "5",
        },
        {
          value: 2,
          label: "10",
        },
        {
          value: 3,
          label: "20",
        },
        {
          value: 4,
          label: "30",
        },
        {
          value: 5,
          label: "40",
        },
      ],
      maxGuest: [
        // {
        //   value: 0,
        //   label: "0",
        // },
        // {
        //   value: -1,
        //   label: "No Limit",
        // },
        {
          value: 2,
          label: "1",
        },
        {
          value: 3,
          label: "2",
        },
        {
          value: 4,
          label: "3",
        },
        {
          value: 5,
          label: "4",
        },
        {
          value: 6,
          label: "5",
        },
        {
          value: 100,
          label: "No limit",
        },
      ],
      validState: {
        isValid: true,
        error: [],
      },
      validStateAdditionalDescription: {
        isValid: true,
        error: [],
      },
      validStateSpeaker: {
        isValid: true,
        error: [],
      },
      validStateModerator: {
        isValid: true,
        error: [],
      },
      validStatePrice: {
        isValid: true,
        error: [],
      },
      basicInformationDetails: {
        eventId: 0,
        eventName: "",
        eventTypeId: 0,
        eventTopicId: "",
        eventImage: "",
        eventRegistrationApproval: "false",
        eventNotificationPeriod: "",
        eventAbout: "",
        outlineDescription: "",
        speakerDescription: "",
        readMoreDescription: "",
        isDietaryRequirement: "",
        selectedTopicIds: [],
        additionalDescription: [],
        additionalSpeaker: [],
        additionalModerator: [],
        speakerImage: "",
        eventFormStartDate: "",
        eventFormEndDate: "",
        eventSalesEndDate: 0,
        eventAbout1: "",
        eventAbout2: "",
        eventAbout3: "",
        isAttendanceFree: "false",
        eventFormEndTime: "",
        workshopFlagTag: "",
        isCreditAllow: false,
      },
      packageList: [],
      eventTimeSlotId: 0,
      feedbackQuestions: {
        required: false,

      },
      additionalDescription: [],
      additionalSpeaker: [],
      additionalModerator: [],
      sgTechAddress: {
        address: '',
        addressId: 0,
        addressName: '',
        howToGetThere: '',
        locationArea: '',
        postalCode: '',
        specialNote: ''
      },
      sgTechLocationAreas: [],
    };



  }
  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    //let test = this.state.eventId;
    let eventId = params.get("");
    let id = 0;
    if (eventId && (id = checkParameters(eventId, "Number"))) {
      if (id && id > 0) {
        this.setState({ eventId: id }, () => {
          // this.getEntryDetailsById(id);
          // this.getSGTechAddressesForEntries();
          this.getAllDropDownForEntryManagement(this.state.eventId);
        });
      }
      else {
        // this.getSGTechAddressesForEntries();
        this.getAllDropDownForEntryManagement(this.state.eventId);
      }
    } else {
      // this.getSGTechAddressesForEntries();
      this.getAllDropDownForEntryManagement(this.state.eventId);
    }

  }


  getAllDropDownForEntryManagement = (eventId) => {
    this.entryServices.getAllDropDownForEntryManagement(eventId).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {

        let entryType = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "EntryType"
        );
        entryType = entryType.map((p) => ({
          value: p.id,
          label: p.name,
        }));
        let entryCategory = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "EntryCategory"
        );
        entryCategory = entryCategory.map((p) => ({
          value: p.id,
          label: p.name,
        }));
        let sgTechLocationAreas = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "SGTechLocationAreas"
        );
        sgTechLocationAreas = sgTechLocationAreas.map((p) => ({
          value: p.id,
          label: p.name,
        }));

        this.setState({ entryType: entryType, entryTopics: entryCategory, sgTechLocationAreas: sgTechLocationAreas }, () => {
          this.getSGTechAddressesForEntries();

        });
      } else {
        this.getSGTechAddressesForEntries();
        this.swalServices.Error(response.message);
      }
    });
  }
  getEntryDetailsById = (id) => {
    this.entryServices.getEntryDetailsById(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let data = this.state.basicInformationDetails;

        data.eventId = response.responseItem.responseContent.eventId;
        data.eventName = response.responseItem.responseContent.eventName;
        data.eventTypeId = response.responseItem.responseContent.eventTypeId;
        data.eventTopicId = response.responseItem.responseContent.eventTopicId;
        data.eventImage = response.responseItem.responseContent.eventImage;
        data.eventRegistrationApproval = response.responseItem.responseContent.eventRegistrationApproval;
        data.eventNotificationPeriod = response.responseItem.responseContent.eventNotificationPeriod;
        data.eventAbout = response.responseItem.responseContent.eventAbout;
        data.outlineDescription = response.responseItem.responseContent.outlineDescription;
        data.speakerDescription = response.responseItem.responseContent.speakerDescription;
        data.readMoreDescription = response.responseItem.responseContent.readMoreDescription;
        data.speakerImage = response.responseItem.responseContent.speakerImage;
        data.eventFormStartDate = response.responseItem.responseContent.eventFormStartDate;
        data.eventFormEndDate = response.responseItem.responseContent.eventFormEndDate;
        data.eventSalesEndDate = response.responseItem.responseContent.eventSalesEndDate;
        data.eventFormEndTime = response.responseItem.responseContent.eventFormEndTime;
        data.isAttendanceFree = response.responseItem.responseContent.isAttendanceFree;
        data.isCreditAllow = response.responseItem.responseContent.isCreditAllow;
        data.workshopFlagTag = response.responseItem.responseContent.workshopFlagTag;
        let array = response.responseItem.responseContent.eventTopicId
          ? response.responseItem.responseContent.eventTopicId.split(
            ","
          )
          : [];
        let topics = [];
        array.forEach((topic) => {
          topics.push(
            this.state.entryTopics.find(
              (topics) => topics.value === Number(topic)
            )
          );
        });
        data.selectedTopicIds = topics;

        if (data.eventRegistrationApproval === true) {
          data.eventRegistrationApproval = "true";
        }
        else {
          data.eventRegistrationApproval = "false";
        }
        if (data.isAttendanceFree === true) {
          data.isAttendanceFree = "true";
        }
        else {
          data.isAttendanceFree = "false";
        }

        this.setState({
          basicInformationDetails: data,
        }, () => {
          this.setState({
            additionalDescription: response.responseItem.responseContent.additionalDescription === null ? [] : response.responseItem.responseContent.additionalDescription,
            additionalModerator: response.responseItem.responseContent.additionalModerator === null ? [] : response.responseItem.responseContent.additionalModerator,
            additionalSpeaker: response.responseItem.responseContent.additionalSpeaker === null ? [] : response.responseItem.responseContent.additionalSpeaker,
          }, () => {
            this.getPriceDetailsForFormByEventId(response.responseItem.responseContent.eventId);
          })
        });

      } else {
        this.swalServices.Error(response.message);
      }
    });
  }

  getPriceDetailsForFormByEventId = (eventId) => {

    this.entryServices.getPriceDetailsForFormByEventId(eventId).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent !== null) {
          //let packageArr = [...this.state.packageList];
          //packageArr = response.responseItem.responseContent;
          this.setState({ packageList: response.responseItem.responseContent.packageList, eventTimeSlotId: response.responseItem.responseContent.eventTimeSlotId });
        }
      }
      else {
        this.swalServices.Error(response.message);
      }
    });
  };

  addEditEntryDetails = (packageList, redirectToNext, isCreditAllow) => {
    let isValidDesc = true;
    let isValidSpeaker = true;
    let isValidModerator = true;
    let isValidPrice = true;
    let isValidBasic = this.isValidateAllBasicInformation();
    if (this.state.basicInformationDetails.eventTypeId === 2) {
      isValidDesc = this.validateAdditionalDescription(this.state.additionalDescription);
      isValidSpeaker = this.validateSpeaker(this.state.additionalSpeaker);
      isValidModerator = this.validateModerator(this.state.additionalModerator);
    }
    if (this.state.basicInformationDetails.eventTypeId === 3 || this.state.basicInformationDetails.eventTypeId === 7 ) {
      isValidPrice = this.validateFormPrice(packageList);
    }
    if (isValidBasic && isValidDesc && isValidSpeaker && isValidModerator && isValidPrice) {
      let request = this.state.basicInformationDetails;

      if (this.state.basicInformationDetails.eventRegistrationApproval === "true") {
        request.eventRegistrationApproval = true;
      }
      else {
        request.eventRegistrationApproval = false;
      }
      request.additionalDescription = this.state.additionalDescription;
      request.additionalSpeaker = this.state.additionalSpeaker;
      request.additionalModerator = this.state.additionalModerator;

      if (this.state.basicInformationDetails.selectedTopicIds.length > 0) {
        let arr1 = this.state.basicInformationDetails.selectedTopicIds.map(function (item) {
          delete item.bad;
          return item.value;
        });
        request.eventTopicId = arr1.join([',']);
      }
      if (Number(this.state.eventId) > 0) {
        request.eventId = this.state.eventId;
      }
      request.isCreditAllow = isCreditAllow;
      this.entryServices.addEditEntryDetails(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent != null && response.responseItem.responseContent.errorColumn === "EventName") {
            this.swalServices.Alert("Entry name already exists.");
          } else if (response.responseItem.responseContent.keyId > 0) {
            this.setState({ eventId: response.responseItem.responseContent.keyId }, () => {
              if (this.state.basicInformationDetails.eventTypeId === 3 || this.state.basicInformationDetails.eventTypeId === 7) {
                let formPriceRequest = {};
                formPriceRequest.eventId = response.responseItem.responseContent.keyId;
                formPriceRequest.eventTimeSlotId = this.state.eventTimeSlotId;
                formPriceRequest.entryPackage = packageList;
                // if (packageList.length > 0) {
                //   formPriceRequest.eventTimeSlotId = packageList[0].eventTimeSlotId;
                // }

                this.entryServices.addEditEventFormPriceList(formPriceRequest).then((response) => {
                  if (response.statusCode === 200 && response.responseItem != null) {
                    if (redirectToNext === 0) {
                      this.swalServices.Success("Entry details inserted successfully.");
                      this.setState({ route: '/ViewEditEntries' });
                    }
                    else {
                      this.nextToBasicInformation();
                    }
                  } else {
                    this.swalServices.Error(response.message);
                  }
                  this.setState({ isLoading: false });
                });
              }
              else {

                if (redirectToNext === 0) {
                  this.swalServices.Success("Entry details inserted successfully.");
                  this.setState({ route: '/ViewEditEntries' });
                }
                else {
                  this.nextToBasicInformation();
                }
              }
            });
          }
          else {
            this.swalServices.Error(response.message);
          }

        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
    }
    else {
      this.goToViolation('ParentDiv');
    }
  }


  getSGTechAddressesForEntries = () => {
    this.entryServices.getSGTechAddressesForEntries().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.length > 0) {
        let sgTech = response.responseItem.responseContent.find(x => x.addressName === 'SLA');
        if (sgTech) {
          this.setState({ sgTechAddress: sgTech });
        }
        if (this.state.eventId > 0) {
          this.getEntryDetailsById(this.state.eventId);

        }
        else {
          let basicDetails = { ...this.state.basicInformationDetails }
          basicDetails.eventTypeId = 1 || 4 || 5 || 6;
          basicDetails.eventAbout = "";
          basicDetails.outlineDescription = "";
          basicDetails.speakerDescription = "";
          basicDetails.readMoreDescription = "";
          this.setState({ basicInformationDetails: basicDetails });
        }
      } else {
        if (this.state.eventId > 0) {
          this.getEntryDetailsById(this.state.eventId);

        }
        else {
          let basicDetails = { ...this.state.basicInformationDetails }
          basicDetails.eventTypeId =  1 || 4 || 5 || 6;
          basicDetails.eventAbout = "";
          basicDetails.outlineDescription = "";
          basicDetails.speakerDescription = "";
          basicDetails.readMoreDescription = "";
          this.setState({ basicInformationDetails: basicDetails });
        }
        this.swalServices.Error(response.message);
      }
    });
  }

  // getLocationAreaByPostalCode = (postalCode) => {
  //   const id = postalCode.slice(0, 2);
  //   this.entryServices.getLocationAreaByPostalCode(id).then((response) => {
  //     if (response.statusCode === 200 && response.responseItem != null) {
  //       let locations = response.responseItem.responseContent;
  //       let locationArea = locations.map((p) => ({
  //         value: p.locationAreaId,
  //         label: p.locationAreaName,
  //       }));
  //       setLocationAreaArr(locationArea);
  //       return locationArea;
  //     } else {
  //       this.swalServices.Error(response.message);
  //       return null;
  //     }
  //   });
  // };

  executeScroll = () => this.myRef.current.scrollIntoView()
  // run this method to execute scrolling. 


  applyAllZoomId = () => {
    // applyAllZoomId
  }

  saveMainImage = (file, keyId, imageIdentity, index) => {

    if (imageIdentity === "\\Events\\AdditionalDescription") {
      file.storagePath = "Events\\AdditionalDescription";
      this.commonServices.storeBase64Image(file).then((response) => {
        if (response != null) {
          this.swalServices.Success("File Uploaded");
          if (file.storagePath === "Events\\AdditionalDescription") {
            let detail = this.state.additionalDescription;
            detail[index]["additionalImagePath"] = response.fileName;
            this.setState({ AdditionalDescription: [...detail] });
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
      })
    }
    else if (imageIdentity === "\\Events\\AdditionalSpeaker") {
      file.storagePath = "Events\\AdditionalSpeaker";
      this.commonServices.storeBase64Image(file).then((response) => {
        if (response != null) {
          this.swalServices.Success("File Uploaded");
          if (file.storagePath === "Events\\AdditionalSpeaker") {
            let detail = this.state.additionalSpeaker;
            detail[index]["eventSpeakerImagePath"] = response.fileName;
            this.setState({ additionalSpeaker: [...detail] });
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
      })
    }
    else if (imageIdentity === "\\Events\\AdditionalModerator") {
      file.storagePath = "Events\\AdditionalModerator";
      this.commonServices.storeBase64Image(file).then((response) => {
        if (response != null) {
          this.swalServices.Success("File Uploaded");
          if (file.storagePath === "Events\\AdditionalModerator") {
            let detail = this.state.additionalModerator;
            detail[index]["eventSpeakerImagePath"] = response.fileName;
            this.setState({ additionalModerator: [...detail] });
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
      })
    }
    else if (imageIdentity === "\\Events\\BasicEventInformation") {
      file.storagePath = "Events\\BasicEventInformation";
      this.commonServices.storeBase64Image(file).then((response) => {
        if (response != null) {
          this.swalServices.Success("File Uploaded");
          if (file.storagePath === "Events\\BasicEventInformation") {
            let detail = this.state.basicInformationDetails;
            if (keyId === "SpeakerImage") {
              detail["speakerImage"] = response.fileName;
            }
            else {
              detail["eventImage"] = response.fileName;
            }
            this.setState({ basicInformationDetails: { ...detail } });
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
      })
    }

  }

  addDescription = () => {

    let descriptionArr = [...this.state.additionalDescription];
    let additionalDescription = {
      eventAdditionDescriptionId: 0,
      eventId: 0,
      additionalDescription: "",
      additionalImagePath: "",
    };
    descriptionArr.push(additionalDescription);
    this.setState({ additionalDescription: descriptionArr });


  };

  addSpeaker = () => {
    let speakerArr = [...this.state.additionalSpeaker];
    let additionalSpeaker = {
      eventAdditionalSpeakerId: 0,
      eventId: 0,
      eventSpeakerName: "",
      eventSpeakerCompany: "",
      eventSpeakerDesignation: "",
      eventSpeakerDescription: "",
      eventSpeakerImagePath: "",
      isModerator: false,
    };
    speakerArr.push(additionalSpeaker);
    this.setState({ additionalSpeaker: speakerArr });
  };
  addModerator = () => {
    let moderatorArr = [...this.state.additionalModerator];
    let additionalModerator = {
      eventAdditionalSpeakerId: 0,
      eventId: 0,
      eventSpeakerName: "",
      eventSpeakerCompany: "",
      eventSpeakerDesignation: "",
      eventSpeakerDescription: "",
      eventSpeakerImagePath: "",
      isModerator: true,
    };
    moderatorArr.push(additionalModerator);
    this.setState({ additionalModerator: moderatorArr });
  };

  handleChangeInDropdown = (selected, drpIdentity) => {
    let entry = this.state.creatEntry;

    let basic = this.state.basicInformationDetails;


    if (drpIdentity === "EntryTopic") {
      basic.selectedTopicIds = selected;
    }
    else if (drpIdentity === "EntryType") {
      basic.eventTypeId = selected.value;
    }
    if (drpIdentity === "SalesEndDate") {
      basic.eventSalesEndDate = selected.value;
    }

    this.setState({ basicInformationDetails: basic }, () => {
      let resetValidState = {
        isValid: true,
        error: [],
      };

      this.setState({
        validStateAdditionalDescription: resetValidState,
        validState: resetValidState, validStateSpeaker: resetValidState,
        validStateModerator: resetValidState, validStatePrice: resetValidState
      });
    });

    // if (drpIdentity === "TrackNo") {
    //   entry.selectedTrackNo = selected;
    //   // this.validateField("roleId");
    // } else if (drpIdentity === "Speaker") {
    //   entry.selectedSpeaker = selected;
    //   // this.validateField("roleId");
    // } else if (drpIdentity === "startupMember") {
    //   entry.startupMemberIds = selected;
    //   // this.validateField("roleId");
    // }
    // else if (drpIdentity === "salesEndDateDays") {
    //   entry.salesEndDateDaysIds = selected;
    //   // this.validateField("roleId");
    // }
    // else if (drpIdentity === "maxAttendees") {
    //   entry.maxAttendeesIds = selected;
    //   // this.validateField("roleId");
    // } else if (drpIdentity === "maxGuest") {
    //   entry.maxGuestIds = selected;
    //   // this.validateField("roleId");
    // }

    this.setState({ creatEntry: entry });
  };
  handleRadioButtons(event) {
    const value = event.target.value;
    const id = event.target.id;
    const name = event.target.name;
    let detail = this.state.basicInformationDetails;
    detail[id] = value;
    detail[name] = value;
    this.setState({ basicInformationDetails: detail });

  }
  setEditorData = (data, componentName, identity) => {
    if (typeof data === 'string') {
      if (componentName === "eventAbout") {
        let detail = this.state.basicInformationDetails;
        detail["eventAbout"] = data;
        this.setState({ basicInformationDetails: detail });
      }
      else if (componentName === "outlineDescription") {
        let detail = this.state.basicInformationDetails;
        detail["outlineDescription"] = data;
        this.setState({ basicInformationDetails: detail });
      }
      else if (componentName === "speakerDescription") {
        let detail = this.state.basicInformationDetails;
        detail["speakerDescription"] = data;
        this.setState({ basicInformationDetails: detail });
      }
      else if (componentName === "readMoreDescription") {
        let detail = this.state.basicInformationDetails;
        detail["readMoreDescription"] = data;
        this.setState({ basicInformationDetails: detail });
      }
      else if (componentName === "additionalDescription") {
        let detail = this.state.additionalDescription;
        detail[identity]["additionalDescription"] = data;
        this.setState({ additionalDescription: detail });
      }
      else if (componentName === "AdditionalSpeaker") {
        let detail = this.state.additionalSpeaker;
        detail[identity]["eventSpeakerDescription"] = data;
        this.setState({ additionalSpeaker: detail });
      }
      else if (componentName === "AdditionalModerator") {
        let detail = this.state.additionalModerator;
        detail[identity]["eventSpeakerDescription"] = data;
        this.setState({ additionalModerator: detail });
      }
    }
  };

  setTime = (time) => {
    let detail = this.state.tempData;
    if (time.identity === "PublishTime") {
      detail["userTime"] = time.selectedTime;
    }
    this.setState({ tempData: { ...detail } });

    let details = this.state.basicInformationDetails;
    if (time.identity === "eventFormEndTime") {
      details["eventFormEndTime"] = time.selectedTime;
      this.setState({ basicInformationDetails: details })
    }
  };


  isValidateAllBasicInformation = () => {
    let newValidationRule = (ValidationRulesForBasicInformation.find(x => x.entryTypeId === this.state.basicInformationDetails.eventTypeId))
    if (this.state.basicInformationDetails.eventRegistrationApproval === 'false') {
      newValidationRule.validationRules['eventNotificationPeriod'] = [{}];
    } else {
      // newValidationRule.validationRules['eventNotificationPeriod'] = [
      //   { type: "require", message: CommonValidationMessages.FieldRequired.replace("{0}", "notification period"), },
      //   { type: "number", message: CommonValidationMessages.FieldRequired.replace("{0}", "only numbers in notification period"), },
      // ];
    }
    const returnValidState = isValidForm(this.state.basicInformationDetails, newValidationRule.validationRules, this.state.validState);
    this.setState({ validState: returnValidState });
    let errorArr = Object.values(returnValidState.error);
    let data = { ...this.state.validState };
    data.error = errorArr;
    this.setState({ validState: data });
    // this.validateAdditionalDescription(this.state.basicInformationDetails.additionalDescription);
    return returnValidState.isValid;
  };
  validateFormPrice = (priceList) => {
    let valError = [];

    let valState = { ...this.state.validStatePrice };
    valState.isValid = true;
    let message = "";
    let field = "";
    priceList.forEach((pack, packindex) => {
      if (pack.eventPrice === "") {
        message = "Please enter package price for " + (pack.eventPackageName);
        field = "eventPrice" + (packindex + 1);
        valError[field] = message;
        valState.isValid = false;
      }
      if (pack.eventCredit === "") {
        message = "Please enter package credit for " + (pack.eventPackageName);
        field = "eventCredit" + (packindex + 1);
        valError[field] = message;
        valState.isValid = false;
      }
      if (pack.entryPackagePrice.length > 0) {
        pack.entryPackagePrice.forEach((price, priceIndex) => {

          if (price.earlyBirdPrice !== 0) {

            // if (price.isSuperEarlyBirdPrice === true) {
            //   message = "Please enter super early bird  price for " + (pack.eventPackageName);
            //   field = "superEarlyBirdPrice[" + (packindex + 1) + "][" + (priceIndex + 1) + "]";
            // }
            // else {
            //   message = "Please enter early bird price for " + (pack.eventPackageName);
            //   field = "earlyBirdPrice[" + (packindex + 1) + "][" + (priceIndex + 1) + "]";
            // }

            // valError[field] = message;
            // valState.isValid = false;
            if (price.promotionStartDate === "" || price.promotionStartDate === null) {
              message = "Please select promotion Start Date " + (priceIndex + 1) + " for " + (pack.eventPackageName);
              field = "promotionStartDate[" + (packindex + 1) + "][" + (priceIndex + 1) + "]";
              valError[field] = message;
              valState.isValid = false;
            }
            if (price.promotionEndDate === "" || price.promotionEndDate === null) {
              message = "Please select promotion End Date " + (priceIndex + 1) + " for " + (pack.eventPackageName);
              field = "promotionEndDate[" + (packindex + 1) + "][" + (priceIndex + 1) + "]";
              valError[field] = message;
              valState.isValid = false;
            }
            if (price.maxQuantity === "" || price.maxQuantity === 0) {
              message = "Please enter max Quantity " + (priceIndex + 1) + " for " + (pack.eventPackageName);
              field = "maxQuantity[" + (packindex + 1) + "][" + (priceIndex + 1) + "]";
              valError[field] = message;
              valState.isValid = false;
            }
            if ((price.promotionStartDate && price.promotionStartDate !== '') && (price.promotionEndDate && price.promotionEndDate !== '')) {
              let startDate = new Date(price.promotionStartDate);
              const startDateVal = Date.parse(startDate);

              let endDate = new Date(price.promotionEndDate);
              const endDateVal = Date.parse(endDate);
              if (endDateVal < startDateVal) {
                message = `Please ensure that end date is not less then start date`;
                field = "eventStartDate";
                valError[field] = message;
                valState.isValid = false;

                // errorObj = {};
                // errorObj['field'] = field;
                // errorObj['message'] = message;
                // errors.push(errorObj);
              }
              // let curdate = moment(new Date()).format("yyyy-MM-DD");
              // const currentDateVal = Date.parse(curdate);
              // if (endDateVal < currentDateVal || startDateVal < currentDateVal) {
              //   message = `Please ensure that date is not less then today's date`;
              //   field = "lessThanTodayDate";
              //   valError[field] = message;
              //   valState.isValid = false;
              // }
            }
          }

        });
      }

    });
    valState.error = Object.values(valError);
    this.setState({ validStatePrice: valState });
    return valState.isValid;
  };

  validateSpeaker = (speaker) => {
    let valError = [];
    let valState = { ...this.state.validStateSpeaker };
    valState.isValid = true;
    let message = "";
    let field = "";
    speaker.forEach((value, key) => {
      if (value.eventSpeakerName === "") {
        message = "Please enter speaker name " + (key + 1);
        field = "eventSpeakerName" + (key + 1);
        valError[field] = message;
        valState.isValid = false;
      }
      if (value.eventSpeakerCompany === "") {
        message = "Please enter speaker company name " + (key + 1);
        field = "eventSpeakerCompany" + (key + 1);
        valError[field] = message;
        valState.isValid = false;
      }
      if (value.eventSpeakerDesignation === "") {
        message = "Please enter speaker designation " + (key + 1);
        field = "eventSpeakerDesignation" + (key + 1);
        valError[field] = message;
        valState.isValid = false;
      }
      if (value.eventSpeakerDescription === "") {
        message = "Please enter speaker description " + (key + 1);
        field = "eventSpeakerDescription" + (key + 1);
        valError[field] = message;
        valState.isValid = false;
      }
      if (value.eventSpeakerImagePath === "") {
        message = "Please select speaker image " + (key + 1);
        field = "eventSpeakerImagePath" + (key + 1);
        valError[field] = message;
        valState.isValid = false;
      }

    });
    valState.error = Object.values(valError);
    this.setState({ validStateSpeaker: valState });
    return valState.isValid;
  };
  validateAdditionalDescription = (description) => {
    let valError = [];
    let valState = { ...this.state.validStateAdditionalDescription };
    valState.isValid = true;
    let message = "";
    let field = "";
    description.forEach((value, key) => {
      if (value.additionalDescription === "") {
        message = "Please enter description " + (key + 1);
        field = "description" + (key + 1);
        valError[field] = message;
        valState.isValid = false;
      }
      if (value.additionalImagePath === "") {
        message = "Please select additional description image " + (key + 1);
        field = "descriptionImage" + (key + 1);
        valError[field] = message;
        valState.isValid = false;
      }
    });

    valState.error = Object.values(valError);
    this.setState({ validStateAdditionalDescription: valState });
    return valState.isValid;
  };
  validateModerator = (moderator) => {
    let valError = [];
    let valState = { ...this.state.validStateModerator };
    valState.isValid = true;
    let message = "";
    let field = "";
    moderator.forEach((value, key) => {
      if (value.eventSpeakerName === "") {
        message = "Please enter moderator name " + (key + 1);
        field = "eventSpeakerName" + (key + 1);
        valError[field] = message;
        valState.isValid = false;
      }
      if (value.eventSpeakerCompany === "") {
        message = "Please enter moderator company name " + (key + 1);
        field = "eventSpeakerCompany" + (key + 1);
        valError[field] = message;
        valState.isValid = false;
      }
      if (value.eventSpeakerDesignation === "") {
        message = "Please enter moderator designation " + (key + 1);
        field = "eventSpeakerDesignation" + (key + 1);
        valError[field] = message;
        valState.isValid = false;
      }
      if (value.eventSpeakerDescription === "") {
        message = "Please enter moderator description " + (key + 1);
        field = "eventSpeakerDescription" + (key + 1);
        valError[field] = message;
        valState.isValid = false;
      }
      if (value.eventSpeakerImagePath === "") {
        message = "Please select moderator image " + (key + 1);
        field = "eventSpeakerImagePath" + (key + 1);
        valError[field] = message;
        valState.isValid = false;
      }

    });
    valState.error = Object.values(valError);
    this.setState({ validStateModerator: valState });
    return valState.isValid;
  };
  validateScheduleAndCapacity = (capacity) => {
    let valError = [];
    let valState = { ...this.state.validStateSpeaker };
    valState.isValid = true;
    let message = "";
    let field = "";
    capacity.forEach((value, key) => {
      if (value.nameOfSpeaker === "") {
        message = "Please enter speaker name " + (key + 1);
        field = "nameOfSpeaker" + (key + 1);
        valError[field] = message;
        valState.isValid = false;
      }

      if (value.company === "") {
        message = "Please enter company name " + (key + 1);
        field = "company" + (key + 1);
        valError[field] = message;
        valState.isValid = false;
      }
      if (value.designation === "") {
        message = "Please enter designation " + (key + 1);
        field = "designation" + (key + 1);
        valError[field] = message;
        valState.isValid = false;
      }
      if (value.speakerDescription === "") {
        message = "Please enter description " + (key + 1);
        field = "speakerDescription" + (key + 1);
        valError[field] = message;
        valState.isValid = false;
      }

    });
    valState.error = Object.values(valError);
    this.setState({ validStateSpeaker: valState });
    return valState.isValid;
  }
  handleChangeForBasicInfo = (event, identity, index) => {
    let name = event.target.name;
    let value = event.target.value;
    let id = event.target.id;

    if (id === "isAttendanceFree") {
      let details = this.state.basicInformationDetails;
      details[id] = value;
      if (value === "true" || value === true) {
        this.setState({ packageList: [] });
      }
      this.setState({ basicInformationDetails: details })
    }


    if (identity === "BasicInformation") {
      let data = this.state.basicInformationDetails;
      data[name] = value;
      this.setState({ basicInformationDetails: data });
    }
    else if (identity === "AdditionalSpeaker") {
      let data = [...this.state.additionalSpeaker];
      data[index][name] = value;
      this.setState({ additionalSpeaker: data });
    }
    else if (identity === "AdditionalModerator") {
      let data = [...this.state.additionalModerator];
      data[index][name] = value;
      this.setState({ additionalModerator: data });
    }
  }

  filterByFeatured = (identity, value, e) => {
    let detail = this.state.feedbackQuestions;
    this.setState({ setToggle: e.target.checked });
    detail.required = e.target.checked;
    // this.swalServices.Warning("If featured type true then remove your previous featured type video into normal video.");
    this.setState({ feedbackQuestions: { ...detail } });
  };

  nextToBasicInformation = () => {
    // let isValidBasic = this.isValidateAllBasicInformation();
    // let isValidDesc = this.validateAdditionalDescription(this.state.additionalDescription);
    // let isValidSpeaker = this.validateSpeaker(this.state.additionalSpeaker);
    // let isValidModerator = this.validateModerator(this.state.additionalModerator);

    // if (isValidBasic && isValidDesc && isValidSpeaker && isValidModerator) {
    if (this.state.basicInformationDetails.eventTypeId === 3 || this.state.basicInformationDetails.eventTypeId === 7) {
      this.setState({ openTab: 6 });
    }
    else {
      this.setState({ openTab: 2 });
      // this.executeScroll();
    }
    // }
  }

  nextToAvailabilitySchedule = () => {
    if (this.state.basicInformationDetails.eventTypeId === 2) {
      this.setState({ openTab: 3 });
    }
    else { this.setState({ openTab: 4 }); }
  }

  nextToProgramme = () => {
    this.setState({ openTab: 4 });
  }

  nextToPricing = () => {
    this.setState({ openTab: 5 });
  }

  backFromAvailabilitySchedule = () => {
    this.setState({ openTab: 1 });
    if (this.state.eventId > 0) {
      this.getEntryDetailsById(this.state.eventId);
    }
  }

  backFromPricing = () => {
    if (this.state.basicInformationDetails.eventTypeId === 2) {
      this.setState({ openTab: 3 });
    }
    else { this.setState({ openTab: 2 }); }
  }

  backFromProgramme = () => {
    this.setState({ openTab: 2 });
  }

  backFromOthers = () => {
    this.setState({ openTab: 4 });
  }

  backFromQuestions = () => {
    if (this.state.basicInformationDetails.eventTypeId === 3 || this.state.basicInformationDetails.eventTypeId === 7) {
      this.setState({ openTab: 1 }, () => {
        if (this.state.eventId > 0) {
          this.getEntryDetailsById(this.state.eventId);
        }
      });
    }

  }
  // executeScroll = () => {
  //   scroller.scrollTo('tabbing-part', {
  //     duration: 1000,
  //     delay: 100,
  //     smooth: 'easeInOutQuart'
  //   })
  //   // this.myRef.current.scrollIntoView();
  //   window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  // }

  navigateTo = (routeTo) => {
    this.setState({ route: routeTo });
  }

  removeAdditionalDescription = (index) => {
    let descriptions = [...this.state.additionalDescription];
    if (index > -1) { // only splice array when item is found
      descriptions.splice(index, 1); // 2nd parameter means remove one item only
    }
    this.setState({ additionalDescription: descriptions });
  }

  removeAdditionalSpeaker = (index) => {
    let speakers = [...this.state.additionalSpeaker];
    if (index > -1) { // only splice array when item is found
      speakers.splice(index, 1); // 2nd parameter means remove one item only
    }
    this.setState({ additionalSpeaker: speakers });
  }

  deleteSpeakerById = (index) => {
    let speakers = [...this.state.additionalSpeaker];
    const eventAdditionalSpeakerId = isNaN(speakers[index].eventAdditionalSpeakerId) ? 0 : Number(speakers[index].eventAdditionalSpeakerId);
    if (eventAdditionalSpeakerId > 0) {
      this.entryServices.deleteSpeakerById(eventAdditionalSpeakerId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          speakers.splice(index, 1);
          this.setState({ additionalSpeaker: speakers });
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
    else {
      if (index > -1) { // only splice array when item is found
        speakers.splice(index, 1); // 2nd parameter means remove one item only
      }
      this.setState({ additionalSpeaker: speakers });
    }
  }

  deleteModeratorById = (index) => {
    let moderators = [...this.state.additionalModerator];
    const eventAdditionalSpeakerId = isNaN(moderators[index].eventAdditionalSpeakerId) ? 0 : Number(moderators[index].eventAdditionalSpeakerId);
    if (eventAdditionalSpeakerId > 0) {
      this.entryServices.deleteSpeakerById(eventAdditionalSpeakerId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          moderators.splice(index, 1);
          this.setState({ additionalSpeaker: moderators });
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
    else {
      if (index > -1) { // only splice array when item is found
        moderators.splice(index, 1); // 2nd parameter means remove one item only
      }
      this.setState({ additionalModerator: moderators });
    }
  }

  deleteAdditionalDescriptionById = (index) => {
    let descriptions = [...this.state.additionalDescription];
    const eventAdditionDescriptionId = isNaN(descriptions[index].eventAdditionDescriptionId) ? 0 : Number(descriptions[index].eventAdditionDescriptionId);
    if (eventAdditionDescriptionId > 0) {
      this.entryServices.deleteAdditionalDescriptionById(eventAdditionDescriptionId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          descriptions.splice(index, 1);
          this.setState({ additionalDescription: descriptions });
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
    else {
      if (index > -1) { // only splice array when item is found
        descriptions.splice(index, 1); // 2nd parameter means remove one item only
      }
      this.setState({ additionalDescription: descriptions });
    }
  }

  removeAdditionalModerator = (index) => {
    let moderators = [...this.state.additionalModerator];
    if (index > -1) { // only splice array when item is found
      moderators.splice(index, 1); // 2nd parameter means remove one item only
    }
    this.setState({ additionalModerator: moderators });
  }

  goToViolation = (id) => {
    // scroller.scrollTo(id, {
    //   duration: 1000,
    //   delay: 100,
    //   smooth: 'easeInOutQuart'
    // })
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth'
    });
    document.getElementById(id).classList.add("delay-1000");
  };

  render() {
    if (this.state.route !== "") {
      return <Navigate to={this.state.route} />;
    }
    return (
      <div className="main-body-section" ref={this.myRef} id='ParentDiv'>
        <div className="">
          <CreateEntryTab
            eventId={this.state.eventId}
            pageDescription={this.state.pageDescription}
            entryTopics={this.state.entryTopics}
            entryType={this.state.entryType}
            trackNo={this.state.trackNo}
            selectSpeaker={this.state.selectSpeaker}
            startupMember={this.state.startupMember}
            creatEntry={this.state.creatEntry}
            salesEndDateDays={this.state.salesEndDateDays}
            maxAttendees={this.state.maxAttendees}
            maxGuest={this.state.maxGuest}
            pricingDetail={this.state.pricingDetailData}
            handleChangeInDropdown={this.handleChangeInDropdown.bind(this)}
            setTime={this.setTime.bind(this)}
            tempData={this.state.tempData}
            myRef={this.myRef}
            basicInformationDetails={this.state.basicInformationDetails}
            handleChangeForBasicInfo={this.handleChangeForBasicInfo.bind(this)}
            validState={this.state.validState}
            applyAllZoomId={this.applyAllZoomId.bind(this)}

            //Additional Description
            validStateAdditionalDescription={this.state.validStateAdditionalDescription}

            //Speakers
            validStateSpeaker={this.state.validStateSpeaker}
            //moderator
            validStateModerator={this.state.validStateModerator}
            filterByFeatured={this.filterByFeatured.bind(this)}
            feedbackQuestions={this.state.feedbackQuestions}
            handleRadioButtons={this.handleRadioButtons.bind(this)}
            saveMainImage={this.saveMainImage.bind(this)}
            setEditorData={this.setEditorData.bind(this)}
            addDescription={this.addDescription.bind(this)}
            additionalDescription={this.state.additionalDescription}
            addSpeaker={this.addSpeaker.bind(this)}
            additionalSpeaker={this.state.additionalSpeaker}
            addEditEntryDetails={this.addEditEntryDetails.bind(this)}
            additionalModerator={this.state.additionalModerator}
            addModerator={this.addModerator.bind(this)}
            openTab={this.state.openTab}
            nextToBasicInformation={this.nextToBasicInformation.bind(this)}
            nextToAvailabilitySchedule={this.nextToAvailabilitySchedule.bind(this)}
            nextToProgramme={this.nextToProgramme.bind(this)}
            nextToPricing={this.nextToPricing.bind(this)}
            backFromAvailabilitySchedule={this.backFromAvailabilitySchedule.bind(this)}
            backFromPricing={this.backFromPricing.bind(this)}
            backFromProgramme={this.backFromProgramme.bind(this)}
            backFromOthers={this.backFromOthers.bind(this)}
            backFromQuestions={this.backFromQuestions.bind(this)}
            executeScroll={this.executeScroll.bind(this)}
            navigateTo={this.navigateTo.bind(this)}
            //eventId={this.state.eventId}
            packageList={this.state.packageList}
            validStatePrice={this.state.validStatePrice}
            sgTechAddress={this.state.sgTechAddress}
            sgTechLocationAreas={this.state.sgTechLocationAreas}
            removeAdditionalDescription={this.removeAdditionalDescription.bind(this)}
            removeAdditionalSpeaker={this.removeAdditionalSpeaker.bind(this)}
            removeAdditionalModerator={this.removeAdditionalModerator.bind(this)}
            goToViolation={this.goToViolation.bind(this)}
            deleteSpeakerById={this.deleteSpeakerById.bind(this)}
            deleteModeratorById={this.deleteModeratorById.bind(this)}
            deleteAdditionalDescriptionById={this.deleteAdditionalDescriptionById.bind(this)}
            eventTimeSlotId={this.state.eventTimeSlotId}
          />
        </div>
      </div>
    );
  }
}