import React from "react";
import ImageCropperWithUpload from "../ImageUpload/UploadImage";

function List(props) {
  return (
    <div key={`ListComponent_${props.identity}`}>
      <div className="List-text-icon">
        <div className="mt-10 mb-10 border border-[#757575]">
          <div className="filter-types col-span-12 font-bold border-b border-[#757575] bg-[#f0f0f0] px-7 py-3 relative">
            <h2 className="filter-lable  font-medium theme-color">Listing</h2>
            {props.pageTypeId === 3 ?
              <div className="close-icon-btn absolute top-[50%] right-[10px] translate-y-[-50%]">
                <button
                  className="h-7 w-7 flex items-center justify-center text-[#757575] hover:text-[#3a3a3a] rounded-full border-2 border-[#757575] close-button"
                  onClick={() => props.removeComponent()}
                >
                  <div className="sr-only">Close</div>
                  <svg className="w-4 h-4 fill-current">
                    <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
                  </svg>
                </button>
              </div>
              : null}
          </div>
          <div className="filter-types col-span-12 p-8">
            <div className="grid grid-cols-12 gap-6 w-full mt-5">
              <div className="xl:col-span-5 lg:col-span-5 sm:col-span-12 input-sec-texts">
                <div className="flex items-center">
                  <div className="w-[60%] mr-4 pt-1">
                    <h2 className="filter-lable  font-medium theme-color">
                      Component Unique Id :
                    </h2>
                  </div>
                  <div className="w-[40%]">
                    {/* <TextInput /> */}
                    <input
                      id="default"
                      name="componentId"
                      className="w-full outline-none appearance-none form-input rounded-none bg-zinc-100"
                      type="text"
                      value={props.componentDetails.componentId}
                      onChange={(event) =>
                        props.handleChange(event, props.identity)
                      }
                      disabled={true}
                    //onBlur={() => props.validateField("publishDate")}
                    />
                  </div>
                </div>
              </div>
              <div className="xl:col-span-7 lg:col-span-7 sm:col-span-12 input-sec-texts">
                <div className="flex items-center">
                  <div className="w-[40%] mr-4 pt-1">
                    <h2 className="filter-lable  font-medium theme-color">
                      Component Name :
                    </h2>
                  </div>
                  <div className="w-[60%]">
                    {/* <input
                      id="default"
                      name="componentName"
                      className="w-full outline-none appearance-none form-input rounded-none"
                      type="text"
                      value={props.componentDetails.componentName}
                      onChange={(event) =>
                        props.handleChange(event, props.identity)
                      }
                    /> */}
                    <input
                      id="default"
                      name="componentName"
                      className={`w-full outline-none appearance-none form-input rounded-none ${(props.componentDetails.componentName) ? 'bg-zinc-100' : ''}`}
                      type="text"
                      value={props.componentDetails.componentName}
                      onChange={(event) =>
                        props.handleChange(event, props.identity)
                      }
                      disabled={!!(props.componentDetails.componentName)}
                    />
                  </div>
                </div>
              </div>
              <div className="xl:col-span-12 lg:col-span-12 sm:col-span-12 ">
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-9 lg:col-span-9 sm:col-span-12">
                    {props?.componentDetails.componentContent && props.componentDetails.componentContent.length > 0 &&
                      props.componentDetails.componentContent.map(
                        (value, index) => {
                          return (
                            <React.Fragment key={index}>
                              <div className="xl:col-span-12 lg:col-span-12 sm:col-span-12">
                                <div className="mt-7 pb-10 relative border-b border-[#f2f2f2]">
                                  <div className="grid grid-cols-12 gap-6 pt-7">
                                    <div
                                      className="2xl:col-span-3 lg:col-span-3 col-span-12 hidden"
                                      key={
                                        "IconListCompImageSec_" + props.identity
                                      }>
                                      <div
                                        className="speaker-img-cropper listing-img-part"
                                        key={
                                          "IconListCompImageSecCropper_" +
                                          props.identity
                                        }
                                      >
                                        <ImageCropperWithUpload
                                          key={"ListCompImage_" + index}
                                          keyId={`ListCompImage${props.identity}_${index}`}
                                          height={200}
                                          width={200}
                                          isCheckValidation={true}
                                          MaxFileSize={2097152}
                                          MaxFileSizeMessage={"2MB"}
                                          onSaveImage={(
                                            file,
                                            id,
                                            imageIdentity
                                          ) =>
                                            props.saveMainImage(
                                              file,
                                              id,
                                              imageIdentity,
                                              props.identity
                                            )
                                          }
                                          imageIdentity={
                                            "\\PageManagement\\ListComponent"
                                          }
                                          intitImage={value.image}
                                          aspactRatio={200 / 200}
                                          uploadBtn={"Upload Image"}
                                          dimensionText={
                                            "(Ratio: 40 X 40 Maximum Size: 2MB)"
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="2xl:col-span-12 lg:col-span-12 col-span-12"
                                      key={"ListCompDetailsSec_" + index}
                                    >
                                      <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                                          <label
                                            className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                            htmlFor="default"
                                          >
                                            List name
                                          </label>
                                          <input
                                            id={`listName${props.identity}_${index}`}
                                            name="listName"
                                            className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                            type="text"
                                            onChange={(event) =>
                                              props.handleChangeInList(
                                                event,
                                                props.identity,
                                                index
                                              )
                                            }
                                            value={value.listName}
                                          />
                                        </div>
                                        <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                                          <label
                                            className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                            htmlFor="default"
                                          >
                                            List Url
                                          </label>
                                          <input
                                            id={`listUrl${props.identity}_${index}`}
                                            name="listUrl"
                                            className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                            type="text"
                                            onChange={(event) =>
                                              props.handleChangeInList(
                                                event,
                                                props.identity,
                                                index
                                              )
                                            }
                                            value={value.listUrl}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {index > 0 ? (
                                    <div className="close-icon-btn absolute right-0 top-0">
                                      <button
                                        className="h-7 w-7 flex items-center justify-center text-[#757575] hover:text-[#3a3a3a] rounded-full border-2 border-[#757575] close-button"
                                        onClick={() =>
                                          props.removeItemFromListComponent(
                                            index,
                                            props.identity
                                          )
                                        }
                                      >
                                        <div className="sr-only">Close</div>
                                        <svg className="w-4 h-4 fill-current">
                                          <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
                                        </svg>
                                      </button>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        }
                      )}
                  </div>
                  <div
                    className="2xl:col-span-3 lg:col-span-3 col-span-12 mt-7"
                    key={"IconListCompImageSec_" + props.identity}
                  >
                    <label
                      className="filter-lable block text-xl text-center mb-3 theme-color min-h-30"
                      htmlFor="default"
                    >
                      List Icon
                    </label>
                    <div
                      className="speaker-img-cropper listing-img-part"
                      key={"IconListCompImageSecCropper_" + props.identity}
                    >
                      <ImageCropperWithUpload
                        key={`ListCompImage_${props.identity}`}
                        keyId={`ListCompImage${props.identity}`}
                        height={200}
                        width={200}
                        isCheckValidation={true}
                        MaxFileSize={2097152}
                        MaxFileSizeMessage={"2MB"}
                        onSaveImage={(file, id, imageIdentity) =>
                          props.saveMainImage(
                            file,
                            id,
                            imageIdentity,
                            props.identity
                          )
                        }
                        imageIdentity={"\\PageManagement\\ListComponent"}
                        // intitImage={value.image}
                        intitImage={(props?.componentDetails && props.componentDetails.componentContent && props.componentDetails.componentContent.length > 0)
                          ? props.componentDetails.componentContent[0].image : ''}
                        aspactRatio={200 / 200}
                        uploadBtn={"Upload Image"}
                        dimensionText={
                          "(Dimension: 40px by 40px Maximum Size: 1MB)"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="2xl:col-span-12 lg:col-span-12 col-span-12 ">
                <div className="flex justify-end">
                  <button
                    className=" px-7 py-3 btn-red text-xl border text-white"
                    onClick={() => props.addNewListItem(props.identity)}
                  >
                    <div className="flex items-end font-semibold">
                      <span className="mr-3">
                        <img src="/plus-round-icon.svg" alt="" />
                      </span>
                      <span className="">Add List Name</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default List;
