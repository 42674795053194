import React, { useState, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import ModalBasic from "../../pages/component/ModalBasic";
import { APIURL, GetImage } from "../../services/axiosServices/ApiEndPoints";
// import ModalBasic from "../Model/ModalBasic";
// import { APIURL, getImage } from "../../services/axios/ApiEndPoints";
// import "./Demo.css";

export const ImageUploadTest = (props) => {

    const fileUploadName = "my_file_";

    const [image, setImage] = useState(null);
    const [cropData, setCropData] = useState(null);
    const [cropper, setCropper] = useState(null);
    const imageRef = useRef(null);
    const ref = useRef();


    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [isLoadingUoload, setIsLoadingUpload] = useState(false);
    // const [aspectRatio, setAspectRatio] = useState(props.aspactRatio);

    let initImage =
        APIURL +
        GetImage +
        "?type=" +
        props.imageIdentity +
        "&&fileName=" +
        props.intitImage;

    // useEffect(() => {
    //     getCropData();
    // });

    const generateIds = {
        cropimg: "cropimg_" + props.keyId,
    };

    const allowedFileTypes = `image/gif image/png, image/jpeg, image/x-png`;

    const onChange = (e) => {
        e.preventDefault();
        // let files;

        // if (e.dataTransfer) {
        //     files = e.dataTransfer.files;
        // } else if (e.target) {
        //     files = e.target.files;
        // }
        let files = e.target.files[0];
        if (files) {
            validateFileSize(
                files,
                function () {
                    const imageReader = new FileReader();
                    imageReader.readAsDataURL(files);
                    imageReader.onloadend = () => {
                        setImage(imageReader.result);
                    };
                    setImageModalOpen(true);
                    setIsLoadingUpload(false);
                },
                e
            );
        } else {
            e.target.value = null;
        }
        // const reader = new FileReader();
        // reader.onload = () => {
        //     setImage(reader.result as any);
        // };
        // reader.readAsDataURL(files[0]);

        // setImageModalOpen(true);
    };


    const getCropData = (index) => {
        if (typeof cropper !== "undefined") {
            setCropData(cropper.getCroppedCanvas().toDataURL());
            let file = {
                fileName: "",
                fileStr: cropper.getCroppedCanvas().toDataURL(),
                storagePath: props.imageIdentity,
            };
            props.onSaveImage(file, props.keyId, props.imageIdentity, index);
            setImageModalOpen(false)
        }
    };

    /// Validate the file Size
    const validateFileSize = (image, callBackFunction, e) => {
        if (props.isCheckValidation) {
            // set the size dymemic
            if (image.size > props.MaxFileSize) {
                // 2 MiB for bytes.
                alert("File size must under " + props.MaxFileSizeMessage + "!");
                return false;
            } else if (image) {
                let img = new Image();
                img.src = window.URL.createObjectURL(image);
                img.onload = function () {
                    window.URL.revokeObjectURL(img.src);
                    callBackFunction();
                };
            }
        } else {
            callBackFunction();
        }
    };

    // Cancle Cropping
    const onCancel = () => {
        setCropData(null);
        setImage(null)
        setImageModalOpen(false)
    }

    // Empty file input to allow same image
    const emptyFileInput = () => {
        ref.current.value = "";
    };

    return (
        <div>
            {/* <div style={{ width: "100%" }}>
                <input type="file" onChange={onChange} />
            </div> */}
            <div className="grid grid-cols-12 gap-0">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                    <div className="img-logo-upload text-center ">
                        {cropData ? (
                            <img
                                id={"defaultimage_" + props.keyId}
                                src={cropData}
                                className="border border-slate-300 w-full mb-7 bg-[#E2E2E2]"
                                alt="../default-img.png"
                            ></img>
                        ) : initImage ? (
                            <img
                                id={generateIds.cropimg}
                                src={
                                    APIURL +
                                    GetImage +
                                    "?type=" +
                                    props.imageIdentity +
                                    "&&fileName=" +
                                    props.intitImage
                                }
                                className="border border-slate-300 w-full mb-7 bg-[#E2E2E2]"
                                alt=""
                            ></img>
                        ) : (
                            <img
                                id={generateIds.cropimg}
                                src={cropData}
                                className="border border-slate-300 w-full mb-7 bg-[#E2E2E2]"
                                alt=""
                            ></img>
                        )}

                        <label
                            htmlFor={fileUploadName + props.keyId}
                            aria-controls="add-edit-modal"
                            className="2xl:px-7 2xl:mt-7 lg:px-5 lg:mt-3 btn-red-border 2xl:py-2 lg:py-2 py-3 cursor-pointer btn-red-border text-xl font-bold border text-[#c00000]"
                            onClick={emptyFileInput}
                        >
                            {props.uploadBtn}
                        </label>
                        <input
                            type="file"
                            id={fileUploadName + props.keyId}
                            style={{ display: "none" }}
                            src="../default-img.png"
                            accept={allowedFileTypes}
                            onChange={onChange}
                            ref={ref}
                        />
                        <p className="mt-5 2xl:px-14 lg:px-5 text-center font-normal 2xl:text-xl lg:text-xl text-base theme-color">
                            {props.dimensionText}
                        </p>
                    </div>
                </div>
            </div>

            <div className="cropper-model-sec">
                <ModalBasic
                    key={props.keyId}
                    id={"set-image-upload"}
                    modalOpen={imageModalOpen}
                    setModalOpen={() => setImageModalOpen(false)}
                    title="Upload Image"
                >
                    {/* Modal content */}
                    <div className="px-5 py-4">
                        <div className="mb-10">
                            <div className="grid grid-cols-12 gap-4">
                                <div className="2xl:col-span-7 lg:col-span-8 col-span-12" >
                                    <h2 className="pb-2">Crop Your Image Below</h2>
                                    <Cropper
                                        // key={props.keyId}
                                        style={{ height: 400, width: "80%" }}
                                        aspectRatio={props.aspactRatio}
                                        initialAspectRatio={props.aspectRatio}
                                        preview={`.img-preview`}
                                        guides={true}
                                        src={image}
                                        ref={imageRef}
                                        viewMode={0}
                                        // dragMode="move"
                                        minCropBoxHeight={10}
                                        minCropBoxWidth={10}
                                        background={true}
                                        responsive={true}
                                        checkOrientation={true}
                                        onInitialized={(instance) => {
                                            setCropper(instance);
                                        }}
                                    // aspactRatio={1739/522}
                                    // zoomable={false}
                                    // autoCropArea={1}
                                    />
                                </div>
                                <div className="2xl:col-span-5 lg:col-span-3 col-span-12">
                                    <div className="grid grid-cols-6 gap-4">
                                        <div className="col-start-2 col-span-4">
                                            <h2 className="pb-2">Preview</h2>
                                            <div
                                                className="box border-dashed border border-[#757575]"
                                                style={{
                                                    width: "50%",
                                                    display: "inline-block",
                                                    padding: "10px",
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                <div
                                                    className={`img-preview bg-[#E2E3E2]`}
                                                    style={{
                                                        width: "100%",
                                                        float: "left",
                                                        height: "300px",
                                                        overflow: "hidden",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-start-1 col-end-3">
                                            <button
                                                // id={"btnCancel" + props.keyId}
                                                className="btn bg-[#c00000] h-[40px] text-lg text-white"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onCancel();
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                        <div className="col-end-7 col-span-2 ...">
                                            {
                                                isLoadingUoload ?
                                                    (<button
                                                        className="btn bg-[#C00000] h-[40px] text-lg text-white"
                                                    // id={"btnSave" + props.keyId}
                                                    // onClick={(e) => {
                                                    //     e.stopPropagation();
                                                    //     getCropData(props.keyId);
                                                    //     setIsLoadingUpload(true);
                                                    // }}
                                                    >
                                                        Uploading...
                                                    </button>)
                                                    :
                                                    (<button
                                                        className="btn bg-[#0a5096] h-[40px] text-lg text-white"
                                                        // id={"btnSave" + props.keyId}
                                                        onClick={(e) => {
                                                            // e.stopPropagation();
                                                            getCropData(props.keyId);
                                                            // setIsLoadingUpload(true);
                                                        }}
                                                    >
                                                        Save
                                                    </button>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBasic>
            </div>
        </div>
    );
};
export default ImageUploadTest;