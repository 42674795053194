import React from "react";

function Header() {
  return (
    <div className="main-header-section">
      <div className="relative">
        <div className="w-full z-0">
          <div className="flex xl:justify-between sm:justify-between justify-around items-center l 2xl:pt-[60px] lg:pt-[40px] 2xl:pb-10 lg:pb-7 sm:pt-4 sm:pb-4 pb-4 pt-4 xl:px-0 px-0 w-full">
            <div className="-mr-2 -my-2 lg:hidden">
              <button
                type="button"
                className="p-2 inline-flex items-center justify-center text-[#757575] hover:text-[#5e5e5e] ring-0"
              >
                <span className="sr-only">Open menu</span>
                {/* Heroicon name: outline/menu */}
                <svg
                  className="h-8 w-8"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
            <div className=" lg:mr-10 md:mr-5 md:ml-0 ml-[0px] mr-[0px] xl:min-w-fit xl:max-w-fit lg:min-w-fit lg:max-w-fit min-w-[80px] max-w-[80px]">
              <a className="main-logo cursor-pointer">
                <img
                  className=" w-full main-logo-header"
                  src="/logo.png"
                  alt="Logo"
                />
              </a>
            </div>

            <div className="items-center lg:w-full sm:w-auto flex md:justify-center lg:justify-self-auto">
              <div className="header-searchbar-section border border-slate-300 bg-white w-full pl-5 pr-4 mr-16 xl:flex lg:flex items-center justify-between lg:block md:hidden hidden">
                <form
                  role="form"
                  className="relative flex z-50 justify-between bg-white w-full "
                >
                  <input
                    type="text"
                    placeholder=""
                    className="w-full focus:outline-none text-[#757575] border-0"
                  />
                  <button className="theme-color">
                    <img
                      className="transform rotate-[270deg] 2xl:w-7 lg:w-5"
                      src="/search-icon.png"
                      alt=""
                    ></img>
                  </button>
                </form>
              </div>
              <>
                <div className="flex items-center before-login-sec ">
                  <div className="flex items-center">
                    <button
                      href="#"
                      className="btn-red btn text-white font-bold whitespace-nowrap inner-title-font-small-button w-[195px] 2xl:h-[65px] lg:h-[45px] signup-btn"
                    >
                      Join SLA
                    </button>
                    <button className="btn-red-border btn sm:ml-10 ml-9 font-bold whitespace-nowrap inner-title-font-small-button w-[195px] login-btn 2xl:h-[65px] lg:h-[45px] inline-flex items-center justify-center px-4 py-2 ">
                      Login
                    </button>
                  </div>

                  <div className="flex items-center w-[100px] ml-12 after-login-icon-change">
                    <button
                      href="#"
                      className="home-icon-change text-2xl text-white rounded-top-icon lg:flex sm:hidden hidden items-center justify-center font-bold bg-[#8DA4B5] hover:bg-[#6d8496] duration-500 hover:duration-500 top-header-icon rounded-full"
                    >
                      <span className="">
                        <img className="w-[1rem]" src="/linkedin.png" alt=""></img>
                      </span>
                    </button>
                    <button
                      href="#"
                      className="home-icon-change text-2xl text-white rounded-top-icon lg:flex sm:hidden hidden items-center justify-center font-bold ml-5 bg-[#8DA4B5] hover:bg-[#6d8496] duration-500 hover:duration-500 top-header-icon rounded-full"
                    >
                      <span className="">
                        <img className="h-[1.25rem]" src="/fb.png" alt=""></img>
                      </span>
                    </button>
                  </div>
                </div>
              </>
            </div>
          </div>
          <div className="w-full flex justify-center items-center lg:pb-0 sm:pb-0">
            <div className="flex w-full">
              <nav className="2xl:flex lg:flex sm:hidden hidden items-center justify-between w-full  md:space-x-4 xl:space-x-14">
                <div className="border-top-menu">
                  <div>
                    <button
                      type="button"
                      className="menu-btn group rounded-md text-[#757575] flex items-center justify-between w-full 2xl:text-lg lg:text-base font-bold hover:text-[#C00000] focus:outline-none ring-0 focus:ring-0 2xl:pb-6 lg:pb-3 '"
                    >
                      <span className="font-['galvjibold']">About Us</span>

                      <svg
                        className="arrow-icon transform rotate-180 h-5 w-5 text-[#757575] group-hover:text-[#C00000] transition ease-out duration-200"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="border-top-menu">
                  <div>
                    <button
                      type="button"
                      className="menu-btn group rounded-md text-[#757575] flex items-center justify-between w-full 2xl:text-lg lg:text-base font-bold hover:text-[#C00000] focus:outline-none ring-0 focus:ring-0 2xl:pb-6 lg:pb-3 '"
                    >
                      <span className="font-['galvjibold']">Community</span>

                      <svg
                        className="arrow-icon transform rotate-180 h-5 w-5 text-[#757575] group-hover:text-[#C00000] transition ease-out duration-200"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="border-top-menu">
                  <div>
                    <button
                      type="button"
                      className="menu-btn group rounded-md text-[#757575] flex items-center justify-between w-full 2xl:text-lg lg:text-base font-bold hover:text-[#C00000] focus:outline-none ring-0 focus:ring-0 2xl:pb-6 lg:pb-3 '"
                    >
                      <span className="font-['galvjibold']">Membership</span>

                      <svg
                        className="arrow-icon transform rotate-180 h-5 w-5 text-[#757575] group-hover:text-[#C00000] transition ease-out duration-200"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="border-top-menu">
                  <div>
                    <button
                      type="button"
                      className="menu-btn group rounded-md text-[#757575] flex items-center justify-between w-full 2xl:text-lg lg:text-base font-bold hover:text-[#C00000] focus:outline-none ring-0 focus:ring-0 2xl:pb-6 lg:pb-3 '"
                    >
                      <span className="font-['galvjibold']">Programmes</span>

                      <svg
                        className="arrow-icon transform rotate-180 h-5 w-5 text-[#757575] group-hover:text-[#C00000] transition ease-out duration-200"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="border-top-menu ">
                  <div>
                    <button
                      type="button"
                      className="menu-btn group rounded-md text-[#757575] flex items-center justify-between w-full 2xl:text-lg lg:text-base font-bold hover:text-[#C00000] focus:outline-none ring-0 focus:ring-0 2xl:pb-6 lg:pb-3 '"
                    >
                      <span className="font-['galvjibold']">Resources</span>

                      <svg
                        className="arrow-icon transform rotate-180 h-5 w-5 text-[#757575] group-hover:text-[#C00000] transition ease-out duration-200"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="border-top-menu">
                  <a
                    type="button"
                    className="cursor-pointer menu-btn group rounded-md text-[#757575] inline-flex items-center 2xl:text-lg lg:text-base font-bold hover:text-[#C00000] focus:outline-none ring-0 focus:ring-0 2xl:pb-6 lg:pb-3 "
                  >
                    <span className="font-['galvjibold']">SLA TV</span>
                  </a>
                </div>
              </nav>
            </div>
          </div>
        </div>
        {/* Mobile menu Start */}
        <div className="block scale-100 absolute top-50 inset-x-0 transition transform origin-top-right w-full hidden z-50">
          <div className="bg-white divide-y-2 divide-gray-50">
            <div className="p-2 px-4 pb-0">
              <div className="absolute top-[-60px] left-[12px]">
                <button
                  type="button"
                  className="bg-white p-3 inline-flex items-center justify-center text-[#757575] hover:text-[#5e5e5e] ring-0 focus:outline-none"
                >
                  <span className="sr-only">Close menu</span>
                  {/* Heroicon name: outline/x */}
                  <svg
                    className="h-7 w-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="">
                <nav className="">
                  <div className="border-top-menu">
                    <button
                      type="button"
                      className="menu-btn group rounded-md text-[#757575] inline-flex w-full items-center justify-between text-xl font-bold hover:text-[#C00000] focus:outline-none ring-0 focus:ring-0 pb-8'"
                    >
                      <span>About Us</span>

                      <svg
                        className="transform rotate-180 ml-2 h-5 w-5 text-[#757575] group-hover:text-[#C00000] transition ease-out duration-200"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="border-top-menu">
                    <button
                      type="button"
                      className="menu-btn group rounded-md text-[#757575] inline-flex w-full items-center justify-between text-xl font-bold hover:text-[#C00000] focus:outline-none ring-0 focus:ring-0 pb-8'"
                    >
                      <span>Community</span>

                      <svg
                        className="transform rotate-180 ml-2 h-5 w-5 text-[#757575] group-hover:text-[#C00000] transition ease-out duration-200"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="border-top-menu">
                    <button
                      type="button"
                      className="menu-btn group rounded-md text-[#757575] inline-flex w-full items-center justify-between text-xl font-bold hover:text-[#C00000] focus:outline-none ring-0 focus:ring-0 pb-8'"
                    >
                      <span>Membership</span>

                      <svg
                        className="transform rotate-180 ml-2 h-5 w-5 text-[#757575] group-hover:text-[#C00000] transition ease-out duration-200"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="border-top-menu">
                    <button
                      type="button"
                      className="menu-btn group rounded-md text-[#757575] inline-flex w-full items-center justify-between text-xl font-bold hover:text-[#C00000] focus:outline-none ring-0 focus:ring-0 pb-8'"
                    >
                      <span>Programmes</span>

                      <svg
                        className="transform rotate-180 ml-2 h-5 w-5 text-[#757575] group-hover:text-[#C00000] transition ease-out duration-200"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="border-top-menu">
                    <button
                      type="button"
                      className="menu-btn group rounded-md text-[#757575] inline-flex w-full items-center justify-between text-xl font-bold hover:text-[#C00000] focus:outline-none ring-0 focus:ring-0 pb-8'"
                    >
                      <span>Resources</span>

                      <svg
                        className="transform rotate-180 ml-2 h-5 w-5 text-[#757575] group-hover:text-[#C00000] transition ease-out duration-200"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="border-top-menu">
                    <button
                      href="#"
                      className="cursor-pointer menu-btn group rounded-md text-[#757575] inline-flex w-full items-center justify-between text-xl font-bold hover:text-[#C00000] focus:outline-none ring-0 focus:ring-0 pb-8"
                    >
                      SLA TV
                    </button>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile menu End */}
      </div>
    </div>
  );
}

export default Header;
