import React, { Component } from "react";
import AccountInformation from "../../../components/membership/createIndividualMember/AccountInformation";
import IndividualBasicInformation from "../../../components/membership/createIndividualMember/IndividualBasicInformation";
import EngagementTrackingNotes from "../../../components/membership/createIndividualMember/EngagementTrackingNotes";
import CommitteeInformation from "../../../components/membership/createIndividualMember/CommitteeInformation";
import CorporateMembershipSubscription from "../../../components/membership/createIndividualMember/CorporateMembershipSubscription";
import CorporateChapterInformation from "../../../components/membership/createIndividualMember/CorporateChapterInformation";
import IndividualContribution from "../../../components/membership/createIndividualMember/IndividualContribution";
import SGTechInterest from "../../../components/membership/createIndividualMember/SGTechInterest";
import CommunicationCard from "../../../components/membership/createIndividualMember/CommunicationCard";
import IndividualMemberService from "../../../services/axiosServices/apiServices/IndividualMemberService";
import CorporateMemberServices from "../../../services/axiosServices/apiServices/CorporateMemberServices";
import SwalServices from "../../../services/swalServices/SwalServices";
import moment from "moment";
import { CommonSuccessMessages } from "../../../utils/Messages";
import {
  validate,
  isValidForm,
} from "../../../utils/validation/CommonValidator";
import {
  CommitteeCardValRules,
  EmploymentInformationValRules,
  EngagementTrackingValRules,
  IndividualContributionValRules,
  IndividualProfileValRules,
  SIISIndividualAccountValRules,
  SIISIndividualAccountValRulesMarkForDelete,
} from "./IndividualValidationRules";
import { saveAs } from "file-saver";
import { checkParameters } from "../../../utils/Encryption";
import { getAuthProps } from "../../../utils/AuthenticationLibrary";
import FinanceServices from "../../../services/axiosServices/apiServices/FinanceServices";
import { isNullString } from "../../../utils/Utils";
import {
  addEditCreditNoteValRules2,
  addEditCreditNoteValRules,
} from "../corporateMembers/CorporateValidations";
import { convertToDecimal } from "../../../utils/Utils";
import { monthDifference } from "../../../utils/DateUtils";
import GenerateCreditNoteForEventBooking from "../../../components/membership/createIndividualMember/GenrateCreditNoteForEventBooking";
import { SuccessMessage, ErrorMessage } from "../../../utils/Messages";

export default class CreateIndividualMembers extends Component {
  constructor(props) {
    super(props);
    this.corporateMemberServices = new CorporateMemberServices();
    this.financeServices = new FinanceServices();
    this.individualMemberServices = new IndividualMemberService();
    this.financeService = new FinanceServices();
    this.swalServices = new SwalServices();
    this.state = {
      adminId: 0,
      individualMemberId: 0,
      gstRate: 0,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        searchParentName: "",
        searchGroupName: "",
        searchGroupType: "",
        status: 0,
        filterByType: 0,
        filterByStatus: 0,
      },
      editDetails: 0,
      addNew: false,
      openSection: "SIISIndividualAccountInformation",
      mrMrs: [],
      roles: [],
      organisation: [],
      filteredOrganisation: [],
      status: [],
      _By: [],
      jobFunction: [],
      jobLevel: [],
      reason: [],
      sponsorship: [],
      matching: [],
      programs: [],
      engagementType: [],
      committeeList: [],
      recordType: [],
      billingCategory: [],
      membershipTypes: [],
      companyCategory: [],
      emailDomain: [],
      domainArr: [],
      individualStatus: [],
      selectedCommitteeList: [],
      parentList: [],
      chapterList: [],
      deletedChapterList: [],

      emailHistory: [],
      corporateMemberId: 0,

      deletionReasonList: [],
      siisIndividualAccountInfo: {
        individualSIISAccountId: 0,
        individualMemberId: 0,
        accountStatus: 0,
        individualJoinedDate: "",
        updatedBy: 0,
        updatedAt: "",
        markForDeleted: false,
        deletionReasonId: 0,
        deletedAt: "",
        deletionDate: "",
        engagementScore: 0,
        siisIndividualId: "",
      },
      engagementTrackingAndNotes: {
        individualMemberId: 0,
        individualEngagementId: 0,
        corporateEngagementTypeId: 0,
        engagementDate: "",
        engagementDescription: "",
        corporateEngagementRecordedById: 0,
        corporateEngagementFollowUpById: 0,
        updatedBy: 0,
        updatedAt: "",
      },
      committeeCard: {
        IndividualCommitteeMappingId: 0,
        individualMemberId: 0,
        workgroupMappingId: 0,
        groupName: "",
        updatedAt: "",
      },
      individualProfile: {
        individualMemberId: 0,
        mrMrsId: 0,
        firstName: "",
        lastName: "",
        title: "",
        contactNo: "",
        corporateMemberId: 0,
        email: "",
        companyAddress: "",
        companyAddress2: "",
        companyAddress3: "",
        businessContactNumber: "",
        individualStatusId: 0,
        linkedIn: "",
        mailingAddress1: "",
        mailingAddress2: "",
        mailingAddress3: "",
        postalCode: "",
        photo: "",
        emailText: "",
        emailDomainId: 0,
        emailString: "",
        companyName: "",
        isNonMember: false,
        designation: "",
        //selectedDesignation: [],
        employementTitle: "",
      },
      employmentInformation: {
        individualEmploymentId: 0,
        employmentCompanyName: "",
        employmentJobFunctionId: 0,
        employmentJobLevelId: 0,
        individualMemberId: 0,
        title: "",
      },
      individualContribution: {
        individualContributionId: 0,
        name: "",
        roleId: 0,
        startDate: "",
        endDate: "",
        individualMemberId: 0,
        workgroupMappingId: 0,
        chapterInvolvement: true,
        committeeInvolvement: true,
      },
      sgtechInterest: {
        individualMemberId: 0,
        corporateReasonId: "",
        corporateSponsorshipId: "",
        corporateMatchingId: "",
        corporateStrategicProgrammesId: "",
        updatedBy: 0,
        updatedAt: "",
        selectedReason: [],
        selectedSponsorship: [],
        selectedMatching: [],
        selectedProgram: [],
      },

      sgtechCommunication: {
        isAdHocActivityEvent: false,
        isMonthlySGTechEdition: true,
        isFortnightlyEvents: true,
        isFortnightlyGlobal: true,
        isBusinessOpportunities: true,
        individualMemberId: 0,
      },

      membershipSubscription: {
        corporateMemberSubscriptionId: 0,
        membershipTypeId: 0,
        billingCategoryId: 1,
        subscriptionStartDate: null,
        subcriptionEndDate: null,
        updatedBy: 1,
        updatedAt: null,
      },
      chapterInformation: {
        workgroupMappingId: 1,
        corporateMemberId: 0,
        availableChapters: 0,
        joinedChapters: 0,
        purchasedChapters: 0,
        updatedBy: 1,
        updatedAt: null,
      },
      bookingList: [],

      validStateSIIS: {
        isValid: true,
        error: {},
      },
      validStateEngagement: {
        isValid: true,
        error: {},
      },
      validStateCommittee: {
        isValid: true,
        error: {},
      },
      validCreditNote: {
        isValid: true,
        error: {},
      },
      validStateIndProfile: {
        isValid: true,
        error: {},
      },
      validStateEmpInfo: {
        isValid: true,
        error: {},
      },
      validStateIndContribution: {
        isValid: true,
        error: {},
      },
      validStateIndInterest: {
        isValid: true,
        error: {},
      },
      validCreditNote2: {
        isValid: true,
        error: {},
      },

      designationList: [],

      /// End Portion

      engagemmentTrackingList: [],
      individualFinanceInfo: [],
      individualCreditNotePaymentInfo: {},
      generateCreditNote: false,
      isInvoiceCancel: false,
      cancellationReason: [],
      selectedCancellationReason: [],
      isOtherCancellationReason: false,
      addEditCreditNote: {
        bookingId: 0,
        eventid: 0,
        eventName: "",
        discountedAmount: 0,
        gstAmount: 0,
        subTotalAmount: 0,
        totalAmount: 0,
        eventTimeSlotId: 0,
        subTotalRefund: 0,
        creditNoteMonth: "",
        discountRefund: 0,
        totalRefund: 0,
        bookingStatusId: 0,
        bookingStatus: 0,
        eventDate: "",
        gstRefund: 0,
        amountPaidRefund: 0,
        amountPaid: 0,
      },
      individualContributionList: [],
      validState: {
        isValid: true,
        error: {},
      },
    };
  }

  validateSIISIndividualAccount = (key) => {
    const returnValidState = validate(
      key,
      this.state.siisIndividualAccountInfo,
      SIISIndividualAccountValRules,
      this.state.validStateSIIS
    );
    this.setState({ validStateSIIS: returnValidState });
  };
  isValidSIIS = () => {
    const returnValidState = isValidForm(
      this.state.siisIndividualAccountInfo,
      SIISIndividualAccountValRules,
      this.state.validStateSIIS
    );
    this.setState({ validStateSIIS: returnValidState });
    return returnValidState.isValid;
  };

  isValidSIISMarkForDelete = () => {
    const returnValidState = isValidForm(
      this.state.siisIndividualAccountInfo,
      SIISIndividualAccountValRulesMarkForDelete,
      this.state.validStateSIIS
    );
    this.setState({ validStateSIIS: returnValidState });
    return returnValidState.isValid;
  };

  validateIndividualProfile = (key) => {
    const returnValidState = validate(
      key,
      this.state.individualProfile,
      IndividualProfileValRules,
      this.state.validStateIndProfile
    );
    this.setState({ validStateIndProfile: returnValidState });
  };
  isValidIndividualProfile = () => {
    const returnValidState = isValidForm(
      this.state.individualProfile,
      IndividualProfileValRules,
      this.state.validStateIndProfile
    );
    this.setState({ validStateIndProfile: returnValidState });
    return returnValidState.isValid;
  };

  validateIndividualEngagement = (key) => {
    const returnValidState = validate(
      key,
      this.state.engagementTrackingAndNotes,
      EngagementTrackingValRules,
      this.state.validStateEngagement
    );
    this.setState({ validStateEngagement: returnValidState });
  };
  isValidIndividualEngagement = () => {
    const returnValidState = isValidForm(
      this.state.engagementTrackingAndNotes,
      EngagementTrackingValRules,
      this.state.validStateEngagement
    );
    this.setState({ validStateEngagement: returnValidState });
    return returnValidState.isValid;
  };

  validateIndividualEmployment = (key) => {
    const returnValidState = validate(
      key,
      this.state.employmentInformation,
      EmploymentInformationValRules,
      this.state.validStateEmpInfo
    );
    this.setState({ validStateEmpInfo: returnValidState });
  };
  isValidIndividualEmployment = () => {
    const returnValidState = isValidForm(
      this.state.employmentInformation,
      EmploymentInformationValRules,
      this.state.validStateEmpInfo
    );
    this.setState({ validStateEmpInfo: returnValidState });
    return returnValidState.isValid;
  };

  validateIndividualContribution = (key) => {
    const returnValidState = validate(
      key,
      this.state.individualContribution,
      IndividualContributionValRules,
      this.state.validStateIndContribution
    );
    this.setState({ validStateIndContribution: returnValidState });
  };
  isValidIndividualContribution = () => {
    const returnValidState = isValidForm(
      this.state.individualContribution,
      IndividualContributionValRules,
      this.state.validStateIndContribution
    );
    this.setState({ validStateIndContribution: returnValidState });
    return returnValidState.isValid;
  };

  validateCommitteeCard = (key) => {
    const returnValidState = validate(
      key,
      this.state.committeeCard,
      CommitteeCardValRules,
      this.state.validStateCommittee
    );
    this.setState({ validStateCommittee: returnValidState });
  };
  isValidCommitteeCard = () => {
    const returnValidState = isValidForm(
      this.state.committeeCard,
      CommitteeCardValRules,
      this.state.validStateCommittee
    );
    this.setState({ validStateCommittee: returnValidState });
    return returnValidState.isValid;
  };

  ////////////////////////////////////////////

  /////////////////////////////////

  getInvoiceReceiptPdfByBookingId = (bookingId, type) => {
    let reqParams = [bookingId, type];
    //reqParams.isMember = 12;

    this.individualMemberServices
      .getInvoiceReceiptPdfByBookingId(reqParams)
      .then((response) => {
        if (response != null) {
          var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");

          var filename = "Booking";
          if (type === "Receipt") {
            filename = `Booking Receipt ${formattedDate}`;
          } else if (type === "Invoice") {
            filename = `Booking Invoice ${formattedDate}`;
          } else if (type === "CreditNote") {
            filename = `Booking Invoice ${formattedDate}`;
          }
          var file = new Blob([response.data], { type: "application/pdf" });

          // const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          //  const pdfWindow = window.open();
          //  pdfWindow.location.href = fileURL;

          saveAs(file, filename + ".pdf");
        } else {
          this.swalServices.Error(response.message);
        }
      });
  };
  getCancellationReasonForCreditNote = () => {
    this.financeServices
      .getCancellationReasonForCreditNote()
      .then((response) => {
        if (
          response.statusCode === 200 &&
          response.responseItem.responseContent != null &&
          response.responseItem.responseContent.length > 0
        ) {
          let cancelReason = response.responseItem.responseContent;
          this.setState({ cancellationReason: cancelReason });
        } else {
          this.swalServices.Error(response.message);
        }
      });
  };

  financeGetCreditNoteInvoice = (value) => {
    let request = [];
    request.push(value);
    if (isNullString(value) !== "") {
      this.financeService
        .financeGetCreditNoteInvoice(request)
        .then((response) => {
          if (response.data !== null && response.status === 200) {
            let formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");

            let filename = "";
            filename = `Credit-Note-${formattedDate}`;

            let file = new Blob([response.data], { type: "application/pdf" });

            URL.createObjectURL(file);
            window.open(URL.createObjectURL(file), "_blank");

            saveAs(file, filename + ".pdf");
          } else {
            this.swalServices.Error(response.message);
          }
        });
    } else {
      this.swalServices.Error("No Invoice is Available");
    }
  };
  validFinanceCreditNoteForOtherReason = (key, providedState) => {
    providedState = providedState
      ? providedState
      : this.state.addEditCreditNote;
    const newValidState = validate(
      key,
      providedState,
      addEditCreditNoteValRules2,
      this.state.validCreditNote2
    );
    this.setState({ validCreditNote2: newValidState });
  };

  getIndividualContribution = (id) => {
    this.individualMemberServices
      .getIndividualContribution(id)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.setState({
            individualContributionList: response.responseItem.responseContent,
          });
        } else {
          this.swalServices.Error(response.message);
        }
      });
  };

  getAllDropDown = () => {
    this.individualMemberServices
      .getAllDropdownForIndividualMember()
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          // var role = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "ContributionRoles");
          var role = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "ContributionRole"
          );
          var mrMrs = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "MrMrs"
          );
          var organisation = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Organisation"
          );
          var status = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "AccountStatus"
          );
          var _By = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Users"
          );
          var job = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "JobFunction"
          );
          var jobLevel = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "JobLevel"
          );
          var domain = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Domains"
          );
          var chapters = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Chapters"
          );
          var committee = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Committee"
          );
          var membershipTypes = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "MembershipTypes"
          );
          var deletionReasonList = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "DeletionReason"
          );
          var chapterXCommittees = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "ChapterXCommittees"
          );
          var steeringCommittee = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "SteeringCommittee"
          );

          var parent = [];

          //var groups = chapters.concat(committee);
          parent.push(...chapterXCommittees);
          // this.setState({ parentList: parent });

          // var sgtech = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "SGTech");
          // var sponsorship = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Sponsorship");
          // var matching = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Matching");
          // var program = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Programs");

          let domainArr = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Domains"
          );
          // let domainArr = response.responseItem.responseContent.filter(
          //   (drp) => drp.dropdownName === "Domains"
          // );
          domainArr.map(function (item) {
            delete item["dropdownName"];
            return item;
          });
          domainArr = domainArr.map((p) => ({
            id: p.id,
            name: "@" + p.name,
          }));
          this.setState({ domainArr: domainArr }, () =>
            this.setState({ emailDomain: domain })
          );

          var reason = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "SGTech"
          );
          reason.map(function (item) {
            delete item["dropdownName"];
            return item;
          });
          reason = reason.map((p) => ({ value: p.id, label: p.name }));

          var sponsorship = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Sponsorship"
          );
          sponsorship.map(function (item) {
            delete item["dropdownName"];
            return item;
          });
          sponsorship = sponsorship.map((p) => ({
            value: p.id,
            label: p.name,
          }));

          var matching = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Matching"
          );
          matching.map(function (item) {
            delete item["dropdownName"];
            return item;
          });
          matching = matching.map((p) => ({
            value: p.id,
            label: p.name,
          }));

          var program = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Programs"
          );
          program.map(function (item) {
            delete item["dropdownName"];
            return item;
          });
          program = program.map((p) => ({
            value: p.id,
            label: p.name,
          }));

          var designation = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Designation"
          );
          designation.map(function (item) {
            delete item["dropdownName"];
            return item;
          });
          designation = designation.map((p) => ({
            value: p.id,
            label: p.name,
          }));

          var engagement = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "EngagementType"
          );
          // var committee = response.responseItem.responseContent.filter(
          //   (drp) => drp.dropdownName === "Committee"
          // );
          var recordtype = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "RecordType"
          );
          var billingcategory = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "BillingCategory"
          );
          var Companycategory = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "CompanyCategory"
          );
          var indi_status = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "IndividualStatus"
          );
          var committee_steeringCommittee = committee.concat(steeringCommittee);
          this.setState(
            {
              roles: role,
              mrMrs: mrMrs,
              organisation: organisation,
              filteredOrganisation: organisation,
              status: status,
              _By: _By,
              jobFunction: job,
              jobLevel: jobLevel,
              reason: reason,
              sponsorship: sponsorship,
              matching: matching,
              programs: program,
              engagementType: engagement,
              committeeList: committee_steeringCommittee,
              recordType: recordtype,
              billingCategory: billingcategory,
              companyCategory: Companycategory,
              emailDomain: domain,
              individualStatus: indi_status,
              parentList: parent,
              membershipTypes: membershipTypes,
              chaptersList: chapters,
              deletionReasonList: deletionReasonList,
              designationList: designation,
            },
            () => {
              if (
                this.state.individualMemberId != null &&
                this.state.individualMemberId > 0
              ) {
                this.getIndividualSIISInformation(
                  this.state.individualMemberId
                );
              }
            }
          );
        } else {
          if (
            this.state.individualMemberId != null &&
            this.state.individualMemberId > 0
          ) {
            this.getIndividualSIISInformation(this.state.individualMemberId);
          }
          this.swalServices.Error(response.message);
        }
      });
  };

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let individualId = params.get("");
    let loginUser = getAuthProps();
    if (loginUser && loginUser.adminUserId && loginUser.adminUserId > 0) {
      this.setState({ adminId: loginUser.adminUserId });
    } else {
      this.setState({ adminId: 0 });
    }
    if (
      individualId &&
      (individualId = checkParameters(individualId, "Number"))
    ) {
      this.setState({ individualMemberId: individualId }, () => {
        this.getAllDropDown();
      });
    } else {
      this.getAllDropDown();
    }
  }

  addEditIndividualSIISInformation = () => {
    if (
      this.isValidSIIS() &&
      ((this.state.siisIndividualAccountInfo.markForDeleted === true &&
        this.isValidSIISMarkForDelete()) ||
        this.state.siisIndividualAccountInfo.markForDeleted === false)
    ) {
      let request = this.state.siisIndividualAccountInfo;
      request.individualMemberId = this.state.individualMemberId;

      this.individualMemberServices
        .addEditIndividualSIISInformation(request)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (
              response.responseItem.responseContent.keyId !==
              this.state.individualMemberId
            ) {
              this.setState(
                {
                  individualMemberId:
                    response.responseItem.responseContent.keyId,
                },
                () =>
                  this.getIndividualSIISInformation(
                    this.state.individualMemberId
                  )
              );
            }
            this.swalServices.Success(
              "SIIS information inserted successfully."
            );
          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };
  addEditEngagementTracking = () => {
    if (
      this.state.individualMemberId > 0 &&
      this.state.individualMemberId !== null
    ) {
      if (this.isValidIndividualEngagement()) {
        let request = this.state.engagementTrackingAndNotes;
        request.individualMemberId = this.state.individualMemberId;
        request.updatedBy = this.state.adminId ? this.state.adminId : 0;
        this.individualMemberServices
          .addEditEngagementTracking(request)
          .then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              this.swalServices.Success("Engagement inserted successfully.");
              this.getEngagementTrackingList(this.state.individualMemberId);
              this.resetEngagement();
            } else {
              this.swalServices.Error(response.message);
            }
          });
      }
    } else {
      this.swalServices.Error(
        "Please enter first SIIS individual account information."
      );
    }
  };
  addEditIndividualCommittee = () => {
    if (
      this.state.individualMemberId > 0 &&
      this.state.individualMemberId !== null
    ) {
      if (this.isValidCommitteeCard()) {
        let request = this.state.committeeCard;
        request.individualMemberId = this.state.individualMemberId;

        this.individualMemberServices
          .addEditIndividualCommittee(request)
          .then((response) => {
            if (
              response.statusCode === 200 &&
              response.responseItem != null &&
              response.responseItem.responseContent.errorColumn === "Committee"
            ) {
              this.swalServices.Error("Committee already joined.");
              this.getIndividualCommittee(this.state.individualMemberId);
            } else if (
              response.statusCode === 200 &&
              response.responseItem != null
            ) {
              this.swalServices.Success("Committee joined successfully.");
              this.getIndividualCommittee(this.state.individualMemberId);
            } else {
              this.swalServices.Error(response.message);
            }
          });
      }
    } else {
      this.swalServices.Error(
        "Please enter first SIIS individual account information."
      );
    }
  };
  addEditIndividualContribution = (id) => {
    if (
      this.state.individualMemberId > 0 &&
      this.state.individualMemberId !== null
    ) {
      if (this.isValidIndividualContribution()) {
        if (
          id !== 0 &&
          id === this.state.individualContribution.individualContributionId
        ) {
          let request = this.state.individualContribution;
          request.individualMemberId = this.state.individualMemberId;
          request.name =
            this.state.individualProfile.firstName +
            " " +
            this.state.individualProfile.lastName;

          this.individualMemberServices
            .addEditIndividualContribution(request)
            .then((response) => {
              if (
                response.statusCode === 200 &&
                response.responseItem != null &&
                response.responseItem.responseContent.errorColumn ===
                "ContributionSection"
              ) {
                this.swalServices.Success(
                  "Contribution details with same chapter or committee name already exist."
                );

                this.getIndividualContribution(this.state.individualMemberId);
                this.resetContributionDetails();
                this.setState({ editDetails: 0 });
                this.setState({ addNew: false });
              } else if (
                response.statusCode === 200 &&
                response.responseItem != null
              ) {
                this.swalServices.Success(
                  "Individual contribution updated successfully."
                );
                this.getIndividualContribution(this.state.individualMemberId);
                this.resetContributionDetails();

                this.setState({ editDetails: 0 });
                this.setState({ addNew: false });
              } else {
                this.swalServices.Error(response.message);
              }
            });
        } else if (id === 0) {
          let request = this.state.individualContribution;
          request.individualMemberId = this.state.individualMemberId;
          request.individualContributionId = 0;
          request.name =
            this.state.individualProfile.firstName +
            " " +
            this.state.individualProfile.lastName;

          this.individualMemberServices
            .addEditIndividualContribution(request)
            .then((response) => {
              if (
                response.statusCode === 200 &&
                response.responseItem != null &&
                response.responseItem.responseContent.errorColumn ===
                "ContributionSection"
              ) {
                this.swalServices.Success(
                  "Contribution details with same chapter or committee name already exist."
                );

                this.getIndividualContribution(this.state.individualMemberId);
                this.resetContributionDetails();
                this.setState({ editDetails: 0 });
                this.setState({ addNew: false });
              } else if (
                response.statusCode === 200 &&
                response.responseItem != null
              ) {
                this.swalServices.Success(
                  "Individual contribution inserted successfully."
                );
                this.getIndividualContribution(this.state.individualMemberId);
                this.resetContributionDetails();
                this.setState({ editDetails: 0 });
                this.setState({ addNew: false });
              } else {
                this.swalServices.Error(response.message);
              }
            });
        }
      }
    } else {
      this.swalServices.Error(
        "Please enter first SIIS individual account information."
      );
    }
  };
  addEditIndividualProfile = () => {
    // akshay
    if (
      this.state.individualMemberId > 0 &&
      this.state.individualMemberId !== null
    ) {
      //if (this.isValidIndividualProfile()) {
      let request = { ...this.state.individualProfile };
      request.individualMemberId = this.state.individualMemberId;
      //request.individualMemberId = this.state.individualMemberId;
      if (request.isNonMember === true) {
        request.email = request.emailText ? request.emailText : "";
        request.isNonMember = true;
        request.companyName = request.companyName ? request.companyName : "";
      } else {
        // let domain = this.state.emailDomain.find(x => x.id === request.emailDomainId);
        // request.email = `${request.emailText}@${domain.name}`;
        request.email = request.emailText;
        request.isNonMember = false;
        request.companyName = "";
      }
      this.individualMemberServices
        .addEditIndividualProfile(request)
        .then((response) => {
          if (
            response.statusCode === 200 &&
            response.responseItem != null &&
            response.responseItem.responseContent.errorColumn === "Email"
          ) {
            this.swalServices.Error("Email address already exists.");
          } else if (
            response.statusCode === 200 &&
            response.responseItem != null
          ) {
            this.swalServices.Success(
              "Individual profile details updated successfully"
            );
            this.getIndividualProfileDetails(this.state.individualMemberId);
          } else {
            this.swalServices.Error(response.message);
          }
        });
      //}
    } else {
      this.swalServices.Error(
        "Please enter first SIIS individual account information."
      );
    }
  };

  addEditEmploymentInformation = () => {
    if (
      this.state.individualMemberId > 0 &&
      this.state.individualMemberId !== null
    ) {
      if (this.isValidIndividualEmployment()) {
        let request = this.state.employmentInformation;
        request.individualMemberId = this.state.individualMemberId;
        if (request.employmentJobFunctionId > 0) {
          let found = this.state.jobFunction.find(
            (x) => x.id === request.employmentJobFunctionId
          );
          if (found) {
            request.jobFunctionName = found.name;
          } else {
            request.jobFunctionName = "";
          }
        } else {
          request.jobFunctionName = "";
        }

        if (request.employmentJobLevelId > 0) {
          let found = this.state.jobLevel.find(
            (x) => x.id === request.employmentJobLevelId
          );
          if (found) {
            request.JobLevelName = found.name;
          } else {
            request.JobLevelName = "";
          }
        } else {
          request.JobLevelName = "";
        }
        this.individualMemberServices
          .addEditEmploymentInformation(request)
          .then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              this.swalServices.Success(
                "employment information updated successfully."
              );
              this.getEmploymentInformation(this.state.individualMemberId);
              //let detail = this.state.employmentInformation;
              //detail.individualEmploymentId = response.responseItem.responseContent.keyId;
            } else {
              this.swalServices.Error(response.message);
            }
          });
      }
    } else {
      this.swalServices.Error(
        "Please enter first SIIS individual account information."
      );
    }
  };

  handleChangeCheckBoxIndividualProfile = (e) => {
    const { name, checked } = e.target;
    let det = this.state.individualProfile;
    if (name === 'mailingNoUnitNumber') {
      det.mailingBuildingName = ""
    }
    if (name === 'registeredNoUnitNumber') {
      det.registeredBuildingName = ""
    }
    det[name] = checked;

    this.setState({ individualProfile: { ...det } });
  };

  addEditSGTechInterestProfile = () => {
    if (
      this.state.individualMemberId > 0 &&
      this.state.individualMemberId !== null
    ) {
      let request = this.state.sgtechInterest;
      request.individualMemberId = this.state.individualMemberId;
      if (this.state.sgtechInterest.selectedReason.length > 0) {
        let arr1 = this.state.sgtechInterest.selectedReason.map(function (
          item
        ) {
          delete item.bad;
          return item.value;
        });
        request.corporateReasonId = arr1.join([","]);
      } else {
        request.corporateReasonId = " ";
      }

      if (this.state.sgtechInterest.selectedSponsorship.length > 0) {
        let arr1 = this.state.sgtechInterest.selectedSponsorship.map(function (
          item
        ) {
          delete item.bad;
          return item.value;
        });
        request.corporateSponsorshipId = arr1.join([","]);
      } else {
        request.corporateSponsorshipId = " ";
      }

      if (this.state.sgtechInterest.selectedMatching.length > 0) {
        let arr1 = this.state.sgtechInterest.selectedMatching.map(function (
          item
        ) {
          delete item.bad;
          return item.value;
        });
        request.corporateMatchingId = arr1.join([","]);
      } else {
        request.corporateMatchingId = " ";
      }

      if (this.state.sgtechInterest.selectedProgram.length > 0) {
        let arr1 = this.state.sgtechInterest.selectedProgram.map(function (
          item
        ) {
          delete item.bad;
          return item.value;
        });
        request.corporateStrategicProgrammesId = arr1.join([","]);
      } else {
        request.corporateStrategicProgrammesId = " ";
      }

      request.updatedBy = this.state.adminId ? this.state.adminId : 0;
      this.individualMemberServices
        .addEditSGTechInterestProfile(request)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.swalServices.Success(
              "Individual SGTech interest updated successfully."
            );
            this.getIndividualSGTechInterest(this.state.individualProfile);
          } else {
            this.swalServices.Error(response.message);
          }
        });
    } else {
      this.swalServices.Error(
        "Please enter first SIIS individual account information."
      );
    }
  };
  addEditCommunicationProfile = () => {
    if (
      this.state.individualMemberId > 0 &&
      this.state.individualMemberId !== null
    ) {
      let request = this.state.sgtechCommunication;
      request.individualMemberId = this.state.individualMemberId;

      this.individualMemberServices
        .addEditCommunicationProfile(request)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.swalServices.Success(
              "Individual communication profile updated successfully."
            );
            this.getIndividualCommunication(this.state.individualMemberId);
          } else {
            this.swalServices.Error(response.message);
          }
        });
    } else {
      this.swalServices.Error(
        "Please enter first SIIS individual account information."
      );
    }
  };
  // Get all segments
  getIndividualSIISInformation = (id) => {
    if (id > 0) {
      this.individualMemberServices
        .getIndividualSIISInformation(id)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.message == null)
              this.setState({
                siisIndividualAccountInfo:
                  response.responseItem.responseContent,
                corporateMemberId:
                  response.responseItem.responseContent.corporateMemberId,
              });
            let chapInfo = this.state.chapterInformation;
            chapInfo.availableChapters = response.responseItem.responseContent
              .availableChapter
              ? response.responseItem.responseContent.availableChapter
              : 0;
            chapInfo.joinedChapters = response.responseItem.responseContent
              .joinedChapter
              ? response.responseItem.responseContent.joinedChapter
              : 0;
            chapInfo.purchasedChapters = response.responseItem.responseContent
              .purchasedChapters
              ? response.responseItem.responseContent.purchasedChapters
              : 0;
            this.setState({ chapterInformation: chapInfo });

            let profile = this.state.individualProfile;
            profile.firstName = response.responseItem.responseContent.firstName;
            profile.lastName = response.responseItem.responseContent.lastName;
            this.setState({ individualProfile: profile });
          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };

  getIndividualCommittee = (id) => {
    if (id > 0) {
      this.individualMemberServices
        .getIndividualCommittee(id)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.setState(
              { selectedCommitteeList: response.responseItem.responseContent },
              () => {
                var date = new Date(
                  Math.max(
                    ...response.responseItem.responseContent.map(
                      (e) => new Date(e.joinedDate)
                    )
                  )
                );
                var date2 = new Date(
                  Math.max(
                    ...response.responseItem.responseContent.map(
                      (e) => new Date(e.deletedDate)
                    )
                  )
                );
                var committee = this.state.committeeCard;
                if (date > date2) {
                  committee.updatedAt = date;
                } else {
                  committee.updatedAt = date2;
                }
                this.setState({ committeeCard: committee });
              }
            );
          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };
  getIndividualContributionList = (id) => {
    if (id > 0) {
      this.individualMemberServices
        .getIndividualContributionList(id)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.setState({
              individualContributionList: response.responseItem.responseContent,
            });
          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };
  getIndividualSGTechInterest = (id) => {
    if (id > 0) {
      this.individualMemberServices
        .getIndividualSGTechInterest(id)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.setState({
              sgtechInterest: response.responseItem.responseContent,
            });

            // var array =
            //   response.responseItem.responseContent.corporateReasonId.split(
            //     ","
            //   );
            // var reason = [];
            // array.forEach((topic) => {
            //   reason.push(
            //     this.state.reason.find(
            //       (topics) => topics.value === Number(topic)
            //     )
            //   );
            // });

            // array = response.responseItem.responseContent.corporateMatchingId
            //   ? response.responseItem.responseContent.corporateMatchingId.split(
            //       ","
            //     )
            //   : [];
            // var matching = [];
            // array.forEach((topic) => {
            //   matching.push(
            //     this.state.matching.find(
            //       (topics) => topics.value === Number(topic)
            //     )
            //   );
            // });

            // array = response.responseItem.responseContent.corporateSponsorshipId
            //   ? response.responseItem.responseContent.corporateSponsorshipId.split(
            //       ","
            //     )
            //   : [];
            // var sponsor = [];
            // array.forEach((topic) => {
            //   sponsor.push(
            //     this.state.sponsorship.find(
            //       (topics) => topics.value === Number(topic)
            //     )
            //   );
            // });

            // array = response.responseItem.responseContent
            //   .corporateStrategicProgrammesId
            //   ? response.responseItem.responseContent.corporateStrategicProgrammesId.split(
            //       ","
            //     )
            //   : [];
            // var program = [];
            // array.forEach((topic) => {
            //   program.push(
            //     this.state.programs.find(
            //       (topics) => topics.value === Number(topic)
            //     )
            //   );
            // });

            // var profile = this.state.sgtechInterest;
            // profile = response.responseItem.responseContent;
            // profile.selectedMatching = matching;
            // profile.selectedReason = reason;
            // profile.selectedProgram = program;
            // profile.selectedSponsorship = sponsor;

            //this.setState({ sgtechInterest: profile });
          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };
  getIndividualCommunication = (id) => {
    if (id > 0) {
      this.individualMemberServices
        .getIndividualCommunication(id)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent != null) {
              this.setState({
                sgtechCommunication: response.responseItem.responseContent,
              });
            }
          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };
  getIndividualEngagementTrackingById = (id) => {
    this.individualMemberServices
      .getIndividualEngagementTrackingById(id)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.setState({
            engagementTrackingAndNotes: response.responseItem.responseContent,
          });
        } else {
          this.swalServices.Error(response.message);
        }
      });
  };
  getDomainByOrganisationId = (id) => {
    // var domainId = this.state.emailDomain.filter(domain => domain.id === id);
    // this.setState({ emailDomain: domainId });

    var domainId = this.state.emailDomain.find((domain) => domain.id === id);
    let setDomain = this.state.individualProfile;
    setDomain.emailDomainId = domainId.id;
    this.setState({ individualProfile: setDomain });
  };
  getEngagementTrackingList = (id) => {
    if (id > 0) {
      this.individualMemberServices
        .getEngagementTrackingList(id)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.setState({
              engagemmentTrackingList: response.responseItem.responseContent,
            });
          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };
  getIndividualProfileDetails = (id) => {
    if (id > 0) {
      this.individualMemberServices
        .getIndividualProfileDetails(id)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            let details = response.responseItem.responseContent;
            let organisationList = [...this.state.organisation];

            if (response.responseItem.responseContent.isNonMember != null && response.responseItem.responseContent.isNonMember === true) {
              details.emailText = response.responseItem.responseContent.email;
              details.email = response.responseItem.responseContent.email;
            } else {
              // var fields = details.email.split('@');
              let companyDetails = organisationList.find(
                (x) => x.id === details.corporateMemberId
              );
              if (companyDetails) {
                details.companyName = companyDetails.name;
              }
              // details.emailText = fields[0];
              details.emailText = details.email;
              details.emailDomainId = details.corporateMemberId;
              details.email = response.responseItem.responseContent.email;
            }
            this.getOrganisationProfileById(details.corporateMemberId);

            this.setState(
              { individualProfile: response.responseItem.responseContent },
              () => {
                let data = { ...this.state.individualProfile };
                data.employementTitle =
                  response.responseItem.responseContent.employmentInformationTitle;
                this.setState({ individualProfile: data });
              }
            );
            this.getEmailHistoryForIndividualMemberById(
              this.state.individualMemberId
            );
          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };

  getOrganisationProfileById = (corporateMemberId) => {
    if (corporateMemberId > 0 && corporateMemberId != null) {
      this.corporateMemberServices
        .getOrganisationProfileById(corporateMemberId)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            let res = { ...this.state.individualProfile };
            res.companyAddress =
              response.responseItem.responseContent.mailingAdress;
            res.companyAddress2 =
              response.responseItem.responseContent.mailingAdress2 === null
                ? " "
                : response.responseItem.responseContent.mailingAdress2;
            res.companyAddress3 =
              response.responseItem.responseContent.mailingAdress3 === null
                ? ""
                : response.responseItem.responseContent.mailingAdress3;
            this.setState({ individualProfile: res });
          } else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoading: false });
        });
    }
  };

  getEmploymentInformation = (id) => {
    if (id > 0) {
      this.individualMemberServices
        .getEmploymentInformation(id)
        .then((response) => {
          if (response.statusCode === 200 && response != null) {
            if (response.responseItem.responseContent != null) {
              this.setState({
                employmentInformation: response.responseItem.responseContent,
              });
            }
          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };

  getChapterInformationById = () => {
    if (
      this.state.corporateMemberId != null &&
      this.state.corporateMemberId > 0
    ) {
      this.individualMemberServices
        .getChapterInformationById(this.state.corporateMemberId)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent.length > 0) {
              const deletedChapterList =
                response.responseItem.responseContent.filter(
                  (chapter) => chapter.leftDate !== null
                );
              const joinedChapterList =
                response.responseItem.responseContent.filter(
                  (chapter) => chapter.leftDate === null
                );
              var date = new Date(
                Math.max(
                  ...response.responseItem.responseContent.map(
                    (e) => new Date(e.joinDate)
                  )
                )
              );
              var date2 = new Date(
                Math.max(
                  ...response.responseItem.responseContent.map(
                    (e) => new Date(e.leftDate)
                  )
                )
              );
              var chapterDetails = this.state.chapterInformation;
              if (date > date2) {
                chapterDetails.updatedAt = date;
              } else {
                chapterDetails.updatedAt = date2;
              }
              this.setState({ chapterInformation: chapterDetails }, () =>
                this.setState({
                  chapterList: joinedChapterList,
                  deletedChapterList: deletedChapterList,
                })
              );
            }
          } else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoading: false });
        });
    }
  };

  getCorporateMembershipSubscriptionById = () => {
    if (
      this.state.corporateMemberId != null &&
      this.state.corporateMemberId > 0
    ) {
      this.individualMemberServices
        .getCorporateMembershipSubscriptionById(this.state.corporateMemberId)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent != null) {
              let res = { ...this.state.membershipSubscription };
              res.corporateMemberSubscriptionId =
                response.responseItem.responseContent.corporateMemberSubscriptionId;
              res.membershipTypeId =
                response.responseItem.responseContent.membershipTypeId;
              res.billingCategoryId =
                response.responseItem.responseContent.billingCategoryId;
              res.subscriptionStartDate =
                response.responseItem.responseContent.subscriptionStartDate;
              res.subcriptionEndDate =
                response.responseItem.responseContent.subcriptionEndDate;
              res.updatedBy = response.responseItem.responseContent.updatedBy
                ? response.responseItem.responseContent.updatedBy
                : 1;
              res.updatedAt = response.responseItem.responseContent.updatedAt;
              this.setState({ membershipSubscription: res });
            }
          } else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoading: false });
        });
    }
    //  else {
    //   let res = { ...this.state.membershipSubscription };
    //   res.membershipTypeId = 0;
    //   this.setState({ membershipSubscription: res });
    // }
  };

  getEmailHistoryForIndividualMemberById = (id) => {
    if (id > 0) {
      this.individualMemberServices
        .getEmailHistoryForIndividualMemberById(id)
        .then((response) => {
          if (response.statusCode === 200 && response != null) {
            if (response.responseItem.responseContent != null) {
              this.setState({
                emailHistory: response.responseItem.responseContent,
              });
            }
          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };

  getBookingListForIndividualMemberById = (individualId) => {
    if (individualId > 0) {
      let request = {};
      request.MemberId = individualId;
      request.isCorporate = false;
      this.individualMemberServices
        .getBookingListForIndividualMemberById(request)
        .then((response) => {
          if (response.statusCode === 200 && response != null) {
            if (response.responseItem.responseContent != null) {
              if (response.responseItem.responseContent.length > 0) {
                let resData = response.responseItem.responseContent;

                let newArr = [];
                resData.map((x) => {
                  if (x.isGuest !== true) {
                    x.guestList = [];
                    newArr.push(x);
                  }
                  return x;
                });
                newArr.map((x) => {
                  let found = resData.filter(
                    (guest) =>
                      guest.bookingAttendeeInformationId !==
                      x.bookingAttendeeInformationId &&
                      guest.bookingId === x.bookingId &&
                      guest.isGuest === true
                  );
                  if (found && found.length > 0) {
                    found.map((f) => {
                      x.guestList.push(f);
                      return f;
                    });
                  }
                  return x;
                });

                this.setState({ bookingList: newArr });
              } else {
                this.setState({ bookingList: [] });
              }
            }
          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };

  // Delete Committees
  deleteCommittees = (id) => {
    let request = {
      deletedBy: 1,
      appId: 51,
      ids: id,
      individualMemberId: this.state.individualMemberId,
    };
    this.swalServices
      .Confirm(
        CommonSuccessMessages.Confirm_Question.replace("{0}", "Chapter"),
        CommonSuccessMessages.Confirm_Delete.replace("{0}", "Chapter"),
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      )
      .then((response) => {
        if (response) {
          this.individualMemberServices
            .deleteCommittees(request)
            .then((response) => {
              if (
                response.statusCode === 200 &&
                response.responseItem != null
              ) {
                this.getIndividualCommittee(this.state.individualMemberId);
              } else {
                this.swalServices.Error(response.message);
              }
            });
        }
      });
  };

  // Save image
  saveMainImage = (file, keyId, imageIdentity) => {
    file.storagePath = imageIdentity;

    this.individualMemberServices.storeBase64Image(file).then((response) => {
      if (response.fileName != null) {
        let request = this.state.individualProfile;
        request.photo = response.fileName;
        this.setState({ individualProfile: request });
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  // Edit enagagement Tracking list
  onEdit = (id) => {
    let reset = this.state.validStateEngagement;
    reset.error = {};
    this.setState({ validStateEngagement: reset });
    this.getIndividualEngagementTrackingById(id);
  };
  editContribution = (id, data) => {
    this.setState({ editDetails: id });
    this.setState({ addNew: false });

    let details = this.state.individualContribution;
    details.individualContributionId = data.individualContributionId;
    details.startDate = data.startDate;
    details.endDate = data.endDate;
    details.name = data.name;
    details.roleId = data.roleId;
    details.individualMemberId = data.individualMemberId;
    details.workgroupMappingId = data.workgroupMappingId;

    this.setState({ individualContribution: details });
  };

  // Delete Individual Contribution Method
  deleteIndividualContribution = (id) => {
    let request = {
      individualContributionId: id,
      deletedBy: 1,
      appId: 114,
      individualMemberId: this.state.individualMemberId,
    };
    this.individualMemberServices
      .deleteIndividualContribution(request)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success(
            "Individual contribution deleted successfully."
          );
          this.getIndividualContributionList(this.state.individualMemberId);
        } else {
          this.swalServices.Error(response.message);
        }
      });
  };

  // rest engagement
  resetEngagement() {
    let details = this.state.engagementTrackingAndNotes;
    details.corporateEngagementTypeId = 0;
    details.engagementDate = "";
    details.engagementDescription = "";
    details.corporateEngagementRecordedById = 0;
    details.corporateEngagementFollowUpById = 0;
    details.individualEngagementId = 0;
    details.updatedBy = this.state.adminId ? this.state.adminId : 0;
    this.setState({ engagementTrackingAndNotes: details });
  }

  resetContributionDetails = () => {
    let reset = this.state.individualContribution;
    reset.individualContributionId = "";
    reset.name = "";
    reset.roleId = "";
    reset.startDate = "";
    reset.endDate = "";
    reset.contributionSection = "";
    reset.chapterInvolvement = true;
    reset.committeeInvolvement = true;
    reset.workgroupMappingId = 0;

    this.setState({ contributionDetails: reset });
  };

  // call API with click of segments

  openSection = (section) => {
    this.setState({
      openSection: this.state.openSection === section ? "" : section,
    });

    if (section === "SIISIndividualAccountInformation") {
      this.getIndividualSIISInformation(this.state.individualMemberId);
    } else if (section === "EngagementTracking") {
      this.getEngagementTrackingList(this.state.individualMemberId);
    } else if (section === "CorporateMembershipSubscription") {
      this.getCorporateMembershipSubscriptionById(this.state.corporateMemberId);
    } else if (section === "CorporateAccountInformation") {
      this.getChapterInformationById(this.state.corporateMemberId);
    } else if (section === "CommitteeInformation") {
      this.getIndividualCommittee(this.state.individualMemberId);
    } else if (section === "IndividualBasicInformation") {
      this.getIndividualProfileDetails(this.state.individualMemberId);
      this.getEmailHistoryForIndividualMemberById(
        this.state.individualMemberId
      );
      // this.getOrganisationProfileById(this.state.individualProfile?.corporateMemberId);
    } else if (section === "EmploymentInformation") {
      this.getEmploymentInformation(this.state.individualMemberId);
    } else if (section === "IndividualContribution") {
      this.getIndividualContributionList(this.state.individualMemberId);
    } else if (section === "SGTechInterestCard") {
      this.getIndividualSGTechInterest(this.state.individualMemberId);
    } else if (section === "CommunicationCard") {
      this.getIndividualCommunication(this.state.individualMemberId);
    } else if (section === "EventsBookings") {
      this.getBookingListForIndividualMemberById(this.state.individualMemberId);
    }
  };
  isValidFinanceCreditNote = () => {
    const newValidState = isValidForm(
      this.state.individualCreditNotePaymentInfo,
      addEditCreditNoteValRules,
      this.state.validCreditNote
    );
    this.setState({ validCreditNote: newValidState });
    return newValidState.isValid;
  };
  isValidFinanceCreditNoteForOtherReason = () => {
    
    const newValidState = isValidForm(
      this.state.addEditCreditNote,
      addEditCreditNoteValRules2,
      this.state.validCreditNote2
    );
    this.setState({ validCreditNote2: newValidState });
    return newValidState.isValid;
  };
  addFinanceCreditNote = () => {
    // console.log("object", this.state.addEditCreditNote);
    if (this.isValidFinanceCreditNote()) {
      if (this.isValidFinanceCreditNoteForOtherReason()) {
        this.setState({ isLoading: true });
        let request = {
          bookingId: this.state.addEditCreditNote.bookingId,
          bookingStatus: this.state.addEditCreditNote.bookingStatus,
          corporateMemberId: this.state.individualMemberId,
          isEvent: true,
          eventid: this.state.addEditCreditNote.eventid,
          eventDate: this.state.addEditCreditNote.eventDate,
          eventTimeSlotId: this.state.addEditCreditNote.eventTimeSlotId,
          creditNoteMonth: moment(),
          creditNoteTotalRefund: this.state.addEditCreditNote.totalRefund,
          creditNoteStatusId: 1,
          creditNoteItem: [],
          creditNoteCancellationReason:
            this.state.addEditCreditNote.cancellationReason,
          CreatedAppId: 114,
          CreatedAt: moment(),
          CreatedBy: 1,
          creditSubTotalAmount: this.state.addEditCreditNote.subTotalRefund,
          creditAmountPain: this.state.addEditCreditNote.amountPaid,
          creditNoteGSTAmount: this.state.addEditCreditNote.gstRefund,
          creditNoteDiscountAmount: this.state.addEditCreditNote.discountedAmount
            ? this.state.addEditCreditNote.discountedAmount
            : 0,

        };
        let creditNoteItemList = [];


        // creditNoteItemList.push({ creditNoteItem: "Membership Subtotal Amount", creditNoteItemAmount: this.state.addEditCreditNote.subTotalRefund });
        // creditNoteItemList.push({ creditNoteItem: "Membership GST Amount", creditNoteItemAmount: this.state.addEditCreditNote.gstRefund });
        // creditNoteItemList.push({ creditNoteItem: "Membership Discounted Amount", creditNoteItemAmount: this.state.addEditCreditNote.discountRefund });
        // creditNoteItemList.push({ creditNoteItem: "Membership Total Amount", creditNoteItemAmount: this.state.addEditCreditNote.totalRefund });
        request.creditNoteItem = creditNoteItemList;
        this.financeServices.addFinanceCreditNote(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent.errorMessage === "") {
              this.swalServices.Success(
                SuccessMessage.Insert_Success.replace("{0}", "Credit Note")
              );
              // this.getCorporateFinancialInfo(this.state.corporateMemberId);
              this.getBookingListForIndividualMemberById(this.state.individualMemberId)
              //this.addCreditNoteInvoiceRecordsForBatchJob(response.responseItem.responseContent.keyId);
            } else {
              this.swalServices.Error(
                ErrorMessage.Already_Exist.replace("{0}", "Credit note")
              );
            }
          } else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoading: false });
        });
      }
    }
  };

  // Handle Change for multi select dropdown
  handleChangeMultiDropdownInterest(selected, identity) {
    var profile = this.state.sgtechInterest;
    if (identity === "reason") {
      profile.selectedReason = selected;
    } else if (identity === "sponsorship") {
      profile.selectedSponsorship = selected;
    } else if (identity === "matching") {
      profile.selectedMatching = selected;
    } else if (identity === "program") {
      profile.selectedProgram = selected;
    }
    this.setState({ sgtechInterest: profile });
  }

  // handle change for checkbox
  handleChangeCheckBox = (e) => {
    const { name, checked } = e.target;
    let det = this.state.sgtechCommunication;
    det[name] = checked;
    this.setState({ sgtechCommunication: { ...det } });
  };
  handleChangeCheckBoxContribution = (e) => {
    const { name, checked } = e.target;
    let det = this.state.individualContribution;
    det[name] = checked;
    this.setState({ individualContribution: { ...det } });
  };
  handleChangeCheckBoxSIIS = (e) => {
    const { name, checked } = e.target;
    let det = this.state.siisIndividualAccountInfo;
    det[name] = checked;
    this.setState({ siisIndividualAccountInfo: { ...det } });
  };

  // handle change for text input
  handleChangeEngagement(event, identity) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.engagementTrackingAndNotes;
    detail[name] = value;
    this.setState({ engagementTrackingAndNotes: { ...detail } });
  }
  handleChangeContribution(event, identity) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.individualContribution;
    detail[name] = value;
    this.setState({ individualContribution: { ...detail } });
  }
  handleChangeProfile(event, identity) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = { ...this.state.individualProfile };
    detail[name] = value;
    if (name === "emailText") {
      if (detail.isNonMember === true) {
        detail["emailString"] = value;
      } else {
        // detail["emailString"] = value + "@abc.com";
        detail["emailString"] = value;
      }
    }
    this.setState({ individualProfile: { ...detail } }, () => {
      this.validateIndividualProfile("emailString", detail);
    });
  }
  handleChangeEmployment(event, identity) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.employmentInformation;
    detail[name] = value;
    this.setState({ employmentInformation: { ...detail } });
  }
  handleChangeSIIS(event, identity) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.siisIndividualAccountInfo;
    detail[name] = value;
    this.setState({ siisIndividualAccountInfo: { ...detail } });
  }
  handleChange(event, identity) {
  
    const value = event.target.value;
    const name = event.target.name;
    if (identity === "CreditNote") {
      let detail = { ...this.state.addEditCreditNote };

      let val = value;
      let usedMonth = 0;
      let totalMonth = monthDifference(this.state.individualCreditNotePaymentInfo.subcriptionEndDate, this.state.individualCreditNotePaymentInfo.subscriptionStartDate, false);;
      //let data = { ...this.state.corporateCreditNotePaymentInfo };
      if (name === "creditNoteMonth") {
        if (detail.paymentStatus !== 'Pending') {

          detail[name] = val;
          usedMonth = monthDifference(detail.creditNoteMonth, this.state.individualCreditNotePaymentInfo.subscriptionStartDate, false);
          let paidMonth = ((totalMonth - usedMonth) / totalMonth)




          detail.subTotalRefund = parseFloat((parseFloat(detail.subTotalRefund)).toFixed(2));
          // detail.gstRefund = parseFloat((parseFloat(detail.subTotalRefund) * (this.state.gstRate / 100)).toFixed(2));
          detail.totalRefund = parseFloat((parseFloat(detail.subTotalRefund) + parseFloat(detail.gstRefund) - parseFloat(detail.discountRefund)).toFixed(2));

        }
      }
      else if ((val = convertToDecimal(value)) && val === 0) {
        // if(data.entranceFee < val ){
        detail[name] = val;
        this.validFinanceCreditNoteForOtherReason(name, detail);
        // }
        //detail[name] = val;
        // detail.discountRefund=parseFloat((parseFloat(detail.discountRefund)).toFixed(2))
        detail.subTotalRefund = parseFloat((parseFloat(detail.subTotalRefund)).toFixed(2));
        //  detail.gstRefund = parseFloat((parseFloat(detail.subTotalRefund) * (this.state.gstRate / 100)).toFixed(2));
        detail.totalRefund = parseFloat((parseFloat(detail.subTotalRefund) + parseFloat(detail.gstRefund) - parseFloat(detail.discountRefund)).toFixed(2));
        detail.cancellationReason = value;
      }
      this.setState({ addEditCreditNote: { ...detail } });
    }
  }


  // set filter parameter for single select dropdown
  setFilterParametersEngagement(id, drpIdentity) {
    let engagement = this.state.engagementTrackingAndNotes;
    if (drpIdentity === "EngagementType") {
      engagement["corporateEngagementTypeId"] = id;
      this.validateIndividualEngagement("corporateEngagementTypeId");
    } else if (drpIdentity === "RecordedBy") {
      engagement["corporateEngagementRecordedById"] = id;
    } else if (drpIdentity === "FollowUpBy") {
      engagement["corporateEngagementFollowUpById"] = id;
    }
    this.setState({ engagementTrackingAndNotes: engagement });
  }
  setFilterParametersContribution(id, drpIdentity) {
    let details = this.state.individualContribution;
    if (drpIdentity === "Role") {
      details["roleId"] = id;
      this.validateIndividualContribution("roleId");
    }
    if (drpIdentity === "Chapter/Commitee") {
      details["workgroupMappingId"] = id;
      this.validateIndividualContribution("workgroupMappingId");
    }
    this.setState({ individualContribution: details });
  }
  setFilterParametersCommittee(id, drpIdentity) {
    let details = this.state.committeeCard;
    if (drpIdentity === "workgroupMappingId") {
      details["workgroupMappingId"] = id;
      this.validateCommitteeCard("workgroupMappingId");
    }
    this.setState({ committeeCard: details });
  }
  setFilterParametersProfile(id, drpIdentity) {
    let engagement = this.state.individualProfile;
    if (drpIdentity === "mrMrsId") {
      engagement["mrMrsId"] = id;
      this.validateIndividualProfile("mrMrsId");
    }
    if (drpIdentity === "Organisation") {
      engagement["corporateMemberId"] = id;
      this.validateIndividualProfile("corporateMemberId");
      this.getDomainByOrganisationId(id);
    }
    // if (drpIdentity === "EmailDomain") {
    //   engagement["emailDomainId"] = id;
    // }
    if (drpIdentity === "IndividualStatus") {
      engagement["individualStatusId"] = id;
      this.validateIndividualProfile("individualStatusId");
    }

    this.setState({ individualProfile: engagement });
  }
  setFilterParametersEmployment(id, drpIdentity) {
    let details = this.state.employmentInformation;
    if (drpIdentity === "employmentJobFunctionId") {
      details["employmentJobFunctionId"] = id;
      this.validateIndividualEmployment("employmentJobFunctionId");
    }
    if (drpIdentity === "employmentJobLevelId") {
      details["employmentJobLevelId"] = id;
      this.validateIndividualEmployment("employmentJobLevelId");
    }
    this.setState({ employmentInformation: details });
  }

  setFilterParametersSIIS(id, drpIdentity) {
    let details = this.state.siisIndividualAccountInfo;
    if (drpIdentity === "accountStatus") {
      details["accountStatus"] = id;
      this.validateSIISIndividualAccount("accountStatus");
    }
    if (drpIdentity === "updatedBy") {
      details["updatedBy"] = id;
      this.validateSIISIndividualAccount("updatedBy");
    }
    if (drpIdentity === "DeletionReasonId") {
      details["deletionReasonId"] = id;
    }

    this.setState({ siisIndividualAccountInfo: details });
  }

  handleChangeForSelector = (e, identity) => {
    if (identity === "Organisation") {
      let individualDetails = { ...this.state.individualProfile };
      let organisationList = [...this.state.organisation];
      var domainObj = this.state.emailDomain.find(
        (domain) => domain.name === e.target.value
      );
      individualDetails.corporateMemberId =
        domainObj && domainObj.id ? domainObj.id : 0;
      individualDetails.emailDomainId =
        domainObj && domainObj.id ? domainObj.id : 0;
      individualDetails.isNonMember =
        individualDetails.corporateMemberId === 0 ? true : false;
      individualDetails.companyName = e.target.value;
      organisationList =
        individualDetails.companyName && individualDetails.companyName !== ""
          ? // organisationList.filter(x => x.name.includes(e.target.value))
          organisationList.filter(
            (x) =>
              x.name
                .toUpperCase()
                .indexOf(individualDetails.companyName.toUpperCase()) > -1
          )
          : organisationList;
      if (individualDetails.isNonMember === true) {
        individualDetails.emailText = individualDetails.email;
      } else {
        // var fields = individualDetails.emailText.split('@');
        // individualDetails.emailText = fields[0];
        individualDetails.emailText = individualDetails.emailText;
      }
      this.setState({
        individualProfile: individualDetails,
        filteredOrganisation: organisationList,
      });
    }
  };
  handleChangeMultiDropdown(selected, identity) {
    var profile = this.state.individualProfile;
    if (identity === "Designation") {
      profile.selectedDesignation = selected;

      this.setState({ individualProfile: profile }, () => {
        this.validateIndividualProfile("selectedDesignation");
      });
    }
  }
  validateFinanceCreditNote = (key, providedState) => {
    providedState = providedState ? providedState : this.state.corporateCreditNotePaymentInfo;
    const newValidState = validate(key, providedState, addEditCreditNoteValRules, this.state.validCreditNote);
    this.setState({ validCreditNote: newValidState });
  }
  setFilterParameters = (id, drpIdentity) => {
    let cancel_reason = this.state.cancellationReason;
    if (drpIdentity === "cancel_reason") {
      //let othersArr = cancel_reason.filter((x) => x.id == id).find(({ name }) => name === "Others");
      //this.setState({ isOtherCancellationReason: othersArr !== null && othersArr !== undefined ? true : false });
      let data = { ...this.state.addEditCreditNote };
      let details = { ...this.state.individualCreditNotePaymentInfo };
      let findData = cancel_reason.filter((x) => x.id === id);
      if (findData.length > 0) {
        details.cancellationReason = findData[0].id;
        if (findData[0].name === "Others") {
          data.cancellationReason = "";
          this.setState({ isOtherCancellationReason: true });
          this.validFinanceCreditNoteForOtherReason("cancellationReason", data);
        } else {
          data.cancellationReason = findData[0].name;
          this.setState({ isOtherCancellationReason: false });
        }
      } else {
        data.cancellationReason = "";
        details.cancellationReason = 0;
        this.setState({
          isOtherCancellationReason: false,
          individualCreditNotePaymentInfo: details,
        });
      }
      this.validateFinanceCreditNote("cancellationReason", details);
      this.setState({
        addEditCreditNote: data,
        individualCreditNotePaymentInfo: details,
      });
    }
  };

  setFilterParametersForSelector = (id, name, identity) => {
    if (identity === "Organisation") {
      let individualDetails = { ...this.state.individualProfile };
      let organisationList = [...this.state.organisation];
      individualDetails["corporateMemberId"] = id;
      this.setState({ corporateMemberId: id });
      var domainObj = this.state.emailDomain.find((domain) => domain.id === id);
      let companyObj = organisationList.find((company) => company.id === id);
      individualDetails.emailDomainId = domainObj.id;
      individualDetails.companyName = companyObj.name;
      individualDetails.isNonMember = companyObj ? false : true;
      if (individualDetails.isNonMember === true) {
        individualDetails.emailText = individualDetails.email;
      } else {
        // var fields = individualDetails.emailText.split('@');
        // individualDetails.emailText = fields[0];
        individualDetails.emailText = individualDetails.email;
      }
      this.setState({ individualProfile: individualDetails });
      // this.validateField2("corporateMemberId");
      this.getOrganisationProfileById(id);
    }
  };
  getCreditNoteInfoForEventBookingByBookingId = (bookingId, bookingStatus) => {
    if (bookingId > 0) {
      let request = [bookingId];
      this.financeServices
        .getCreditNoteInfoForEventBookingByBookingId(request)
        .then((response) => {
          if (
            response.statusCode === 200 &&
            response.responseItem.responseContent != null
          ) {
            this.setState({
              individualCreditNotePaymentInfo:
                response.responseItem.responseContent,
                addEditCreditNote:response.responseItem.responseContent
            });
            let creditNote = { ...this.state.addEditCreditNote };
            if (isNullString(bookingStatus) === "Pending Payment") {
              creditNote.eventid =
                response.responseItem.responseContent.eventId;
              creditNote.eventTimeSlotId =
                response.responseItem.responseContent.eventTimeSlotId;
              creditNote.eventDate =
                response.responseItem.responseContent.eventDate;
              // creditNote.eventid = 0;
              // creditNote.eventTimeSlotId = 0;
              // creditNote.eventDate = null;
              creditNote.discountedAmount = 0;
              creditNote.gstAmount = 0;
              creditNote.subTotalAmount = 0;
              creditNote.totalAmount = 0;
              creditNote.subTotalRefund = 0;
              creditNote.gstRefund = 0;
              creditNote.discountRefund = 0;
              creditNote.totalRefund = response.responseItem.responseContent.amountPaid ? response.responseItem.responseContent.amountPaid : 0;
              //creditNote.totalRefund = 0;
              // creditNote.compareEntranceRefund = 0;
              // creditNote.compareAnnualRefund = 0;
              // creditNote.compareChapterRefund = 0;
              creditNote.creditNoteMonth = response.responseItem.responseContent.subscriptionStartDate ? response.responseItem.responseContent.subscriptionStartDate : new Date();// moment();
            } else {
              creditNote.eventid =
                response.responseItem.responseContent.eventId;
              creditNote.eventTimeSlotId =
                response.responseItem.responseContent.eventTimeSlotId;
              creditNote.discountedAmount =
                response.responseItem.responseContent.discountedAmount;
              creditNote.gstAmount =
                response.responseItem.responseContent.gstAmount;
              creditNote.subTotalAmount =
                response.responseItem.responseContent.subTotalAmount;
              creditNote.totalAmount =
                response.responseItem.responseContent.totalAmount;
              creditNote.eventDate =
                response.responseItem.responseContent.eventDate;
              creditNote.annualRefund =
                response.responseItem.responseContent.annualFee;
              // creditNote.chapterRefund = response.responseItem.responseContent.addtionalChapterFee;
              creditNote.subTotalRefund =
                response.responseItem.responseContent.subTotalAmount;
              creditNote.gstRefund =
                response.responseItem.responseContent.gstAmount;
              // creditNote.compareEntranceRefund = response.responseItem.responseContent.entranceFee;
              // creditNote.compareAnnualRefund = response.responseItem.responseContent.annualFee;
              // creditNote.compareChapterRefund = response.responseItem.responseContent.addtionalChapterFee;
              creditNote.creditNoteMonth = response.responseItem.responseContent.subscriptionStartDate ? response.responseItem.responseContent.subscriptionStartDate : new Date();// moment();
            }
            creditNote.bookingStatusId =
              response.responseItem.responseContent.bookingStatusId;
            creditNote.bookingStatus =
              response.responseItem.responseContent.bookingStatus;
            this.setState({
              addEditCreditNote: creditNote,
              gstRate: response.responseItem.responseContent.gstRate,
            });
          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };

  render() {
    return (
      <div className="main-body-section">
        {/* Account Information Card Start*/}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="accountInformation"
              type="checkbox"
              name="corporate-d"
              value={"SIISIndividualAccountInformation"}
              checked={
                this.state.openSection === "SIISIndividualAccountInformation"
              }
              onChange={() => { }}
              onClick={() =>
                this.openSection("SIISIndividualAccountInformation")
              }
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="accountInformation"
            >
              <span className="small-title font-bold theme-color pl-5">
                SIIS Individual Account Information
              </span>
              <div className="flex items-left justify-between text-[18px] text-[#757575]">
                <p>
                  SIIS Individual ID:{" "}
                  {this.state.siisIndividualAccountInfo.siisIndividualId
                    ? this.state.siisIndividualAccountInfo.siisIndividualId
                    : "SIIS"}{" "}
                  | Engagement Score:{" "}
                  {this.state.siisIndividualAccountInfo.engagementScore}
                </p>
              </div>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-5">
              <AccountInformation
                status={this.state.status}
                _By={this.state._By}
                // new
                siisIndividualAccountInfo={this.state.siisIndividualAccountInfo}
                addEditIndividualSIISInformation={this.addEditIndividualSIISInformation.bind(
                  this
                )}
                handleChangeCheckBox={this.handleChangeCheckBoxSIIS.bind(this)}
                handleChange={this.handleChangeSIIS.bind(this)}
                setFilterParameters={this.setFilterParametersSIIS.bind(this)}
                validState={this.state.validStateSIIS}
                validate={this.validateSIISIndividualAccount.bind(this)}
                deletionReasonList={this.state.deletionReasonList}
              />
            </div>
          </div>
        </div>

        {/* Engagement Tracking and Notes Start */}

        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="engage"
              type="checkbox"
              name="corporate-d"
              value={"EngagementTracking"}
              checked={this.state.openSection === "EngagementTracking"}
              onChange={() => { }}
              onClick={() => this.openSection("EngagementTracking")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="engage"
            >
              <span className="small-title font-bold theme-color pl-5">
                Engagement Tracking and Notes
              </span>
            </label>

            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-5">
              <EngagementTrackingNotes
                engagementType={this.state.engagementType}
                _By={this.state._By}
                engagementList={this.state.engagemmentTrackingList}
                isCorporate={false}
                // new
                engagementTrackingAndNotes={
                  this.state.engagementTrackingAndNotes
                }
                onClick={this.addEditEngagementTracking.bind(this)}
                handleChange={this.handleChangeEngagement.bind(this)}
                onEdit={this.onEdit.bind(this)}
                setFilterParameters={this.setFilterParametersEngagement.bind(
                  this
                )}
                validState={this.state.validStateEngagement}
                validate={this.validateIndividualEngagement.bind()}
              />
            </div>
          </div>
        </div>

        {/* Corporate Membership Subscription Start */}

        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="coporatem-sub"
              type="checkbox"
              name="corporate-d"
              value={" CorporateMembershipSubscription"}
              checked={
                this.state.openSection === "CorporateMembershipSubscription"
              }
              onChange={() => { }}
              onClick={() =>
                this.openSection("CorporateMembershipSubscription")
              }
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="coporatem-sub"
            >
              <span className="small-title font-bold theme-color pl-5">
                Corporate Membership Subscription
              </span>
            </label>

            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-5">
              <CorporateMembershipSubscription
                _by={this.state._By}
                billingCategory={this.state.billingCategory}
                membershipTypes={this.state.membershipTypes}
                membershipSubscription={this.state.membershipSubscription}
                validState={this.state.validState}
              // onSave={this.addEditCorporateMembershipSubscription.bind(this)}
              // validate={this.corporateInformationValidate.bind()}
              // handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
              />
            </div>
          </div>
        </div>

        {/* SIIS Corporate Account Information  Start*/}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="chapterInforamtion"
              type="checkbox"
              name="corporate-d"
              value={" CorporateAccountInformation"}
              checked={this.state.openSection === "CorporateAccountInformation"}
              onChange={() => { }}
              onClick={() => this.openSection("CorporateAccountInformation")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="chapterInforamtion"
            >
              <span className="small-title font-bold theme-color pl-5">
                Corporate Chapter Information
              </span>
            </label>

            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-5">
              <CorporateChapterInformation
                chapterList={this.state.chapterList}
                deletedChapterList={this.state.deletedChapterList}
                chapterInformation={this.state.chapterInformation}
                availableChapters={this.state.availableChapters}
              // deletedChapterList={this.state.deletedChapterList}
              // deleteChapter={this.deleteChapter.bind()}
              //save={this.joinChapterById.bind(this)}
              />
            </div>
          </div>
        </div>

        {/* Committee Information Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="commitee"
              type="checkbox"
              name="corporate-d"
              value={" CommitteeInformation"}
              checked={this.state.openSection === "CommitteeInformation"}
              onChange={() => { }}
              onClick={() => this.openSection("CommitteeInformation")}
            />

            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="commitee"
            >
              <span className="small-title font-bold theme-color pl-5">
                Committee Card
              </span>
            </label>

            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-5">
              <CommitteeInformation
                committeeList={this.state.committeeList}
                selectedCommitteeList={this.state.selectedCommitteeList}
                deleteCommittees={this.deleteCommittees.bind(this)}
                // new
                committeeCard={this.state.committeeCard}
                joinCommitee={this.addEditIndividualCommittee.bind(this)}
                setFilterParameters={this.setFilterParametersCommittee.bind(
                  this
                )}
                validState={this.state.validStateCommittee}
              />
            </div>
          </div>
        </div>

        {/* Individual Basic Information Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="basicinfo"
              type="checkbox"
              name="corporate-d"
              value={"IndividualBasicInformation"}
              checked={this.state.openSection === "IndividualBasicInformation"}
              onChange={() => { }}
              onClick={() => this.openSection("IndividualBasicInformation")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="basicinfo"
            >
              <span className="small-title font-bold theme-color pl-5">
                Individual Profile
              </span>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-5">
              <IndividualBasicInformation
                mrMrs={this.state.mrMrs}
                organisation={this.state.organisation}
                emailDomain={this.state.emailDomain}
                domainArr={this.state.domainArr}
                individualStatus={this.state.individualStatus}
                emailHistory={this.state.emailHistory}
                individualProfile={this.state.individualProfile}
                validState={this.state.validStateIndProfile}
                filteredOrganisation={this.state.filteredOrganisation}
                handleChange={this.handleChangeProfile.bind(this)}
                onSave={this.addEditIndividualProfile.bind(this)}
                setFilterParameters={this.setFilterParametersProfile.bind(this)}
                validate={this.validateIndividualProfile.bind(this)}
                saveMainImage={this.saveMainImage.bind(this)}
                handleChangeCheckBox={this.handleChangeCheckBoxIndividualProfile.bind(this)}
                setFilterParametersForSelector={this.setFilterParametersForSelector.bind(
                  this
                )}
                handleChangeForSelector={this.handleChangeForSelector.bind(
                  this
                )}
                handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(
                  this
                )}
                designationList={this.state.designationList}
              />
            </div>
          </div>
        </div>

        {/* Employment Information Card Start*/}
        {/* <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="employmentInformation"
              type="checkbox"
              name="corporate-d"
              value={" EmploymentInformation"}
              checked={this.state.openSection === "EmploymentInformation"}
              onClick={() => this.openSection("EmploymentInformation")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="employmentInformation"
            >
              <span className="small-title font-bold theme-color pl-5">
                Employment Information
              </span>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-5">
              <EmploymentInformation

                // new 
                jobFunction={this.state.jobFunction}
                jobLevel={this.state.jobLevel}
                employmentInformation={this.state.employmentInformation}
                setFilterParameters={this.setFilterParametersEmployment.bind(this)}
                handleChange={this.handleChangeEmployment.bind(this)}
                addEditEmploymentInformation={this.addEditEmploymentInformation.bind(this)}
                validState={this.state.validStateEmpInfo}
                validate={this.validateIndividualEmployment.bind(this)}



              />
            </div>
          </div>
        </div> */}

        {/* Individual Contribution Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="individual-co"
              type="checkbox"
              name="corporate-d"
              value={"IndividualContribution"}
              checked={this.state.openSection === "IndividualContribution"}
              onChange={() => { }}
              onClick={() => this.openSection("IndividualContribution")}
            />

            <label
              className="heading-part px-7 py-2 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="individual-co"
            >
              <span className="small-title font-bold theme-color pl-5">
                Individual Contribution
              </span>
              <div className="flex items-center justify-between right-id-sec">
                <div className="flex items-center justify-end">
                  <button
                    className="2xl:px-10 lg:px-5 pt-3 py-2 btn-red-border text-xl border text-[#C00000]"
                    onClick={() =>
                      this.setState({ addNew: true, editDetails: false })
                    }
                  >
                    <span className="text-xl text-[#c00000] font-semibold tracking-normal">
                      Add New
                    </span>
                  </button>
                </div>
              </div>
            </label>

            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-5">
              <IndividualContribution
                individualContributionList={
                  this.state.individualContributionList
                }
                roles={this.state.roles}
                editDetails={this.state.editDetails}
                addNew={this.state.addNew}
                parentList={this.state.parentList}
                individualBasicInformation={
                  this.state.individualBasicInformation
                }
                //new
                saveContribution={this.addEditIndividualContribution.bind(this)}
                individualContribution={this.state.individualContribution}
                handleChangeCheckBoxContribution={this.handleChangeCheckBoxContribution.bind(
                  this
                )}
                handleChange={this.handleChangeContribution.bind(this)}
                setFilterParameters={this.setFilterParametersContribution.bind(
                  this
                )}
                individualProfile={this.state.individualProfile}
                validState={this.state.validStateIndContribution}
                validate={this.validateIndividualContribution.bind(this)}
                editContribution={this.editContribution.bind(this)}
                isValidIndividualContribution={this.isValidIndividualContribution.bind(
                  this
                )}
                deleteIndividualContribution={this.deleteIndividualContribution.bind(
                  this
                )}
              />
            </div>
          </div>
        </div>

        {/* SGTech Interest Card Start*/}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="sgtecgInterest"
              type="checkbox"
              name="corporate-d"
              value={"SGTechInterestCard"}
              checked={this.state.openSection === "SGTechInterestCard"}
              onChange={() => { }}
              onClick={() => this.openSection("SGTechInterestCard")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="sgtecgInterest"
            >
              <span className="small-title font-bold theme-color pl-5">
                SLA Interest
              </span>
            </label>

            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-5">
              <SGTechInterest
                _By={this.state._By}
                sgTech={this.state.reason}
                sponsorship={this.state.sponsorship}
                matching={this.state.matching}
                programs={this.state.programs}
                //new
                sgtechInterest={this.state.sgtechInterest}
                addEditSGTechInterestProfile={this.addEditSGTechInterestProfile.bind(
                  this
                )}
                handleChangeMultiDropdownInterest={this.handleChangeMultiDropdownInterest.bind(
                  this
                )}
              />
            </div>
          </div>
        </div>

        {/* Communication Card Start*/}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="communicationCard"
              type="checkbox"
              name="corporate-d"
              value={"CommunicationCard"}
              checked={this.state.openSection === "CommunicationCard"}
              onClick={() => this.openSection("CommunicationCard")}
              onChange={() => { }}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="communicationCard"
            >
              <span className="small-title font-bold theme-color pl-5">
                Communication
              </span>
            </label>

            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-5">
              <CommunicationCard
                // new
                sgtechCommunication={this.state.sgtechCommunication}
                addEditCommunicationProfile={this.addEditCommunicationProfile.bind(
                  this
                )}
                handleChangeCheckBox={this.handleChangeCheckBox.bind(this)}
              />
            </div>
          </div>
        </div>

        {/* Financials Record Start */}
        {/* <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="financialr"
              type="checkbox"
              name="corporate-d"
              value={"FinancialsRecord"}
              checked={this.state.openSection === "FinancialsRecord"}
              onClick={() => this.openSection("FinancialsRecord")}
            />

            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="financialr"
            >
              <span className="small-title font-bold theme-color pl-5">
                Financials
              </span>
            </label>

            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-5">
              <FinancialsRecord />
            </div>
          </div>
        </div> */}

        {/* Events Bookings Start */}

        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="eventb"
              type="checkbox"
              name="corporate-d"
              value={"EventsBookings"}
              checked={this.state.openSection === "EventsBookings"}
              onClick={() => this.openSection("EventsBookings")}
              onChange={() => { }}
            />

            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="eventb"
            >
              <span className="small-title font-bold theme-color pl-5">
                Event Booking
              </span>
            </label>
            <div className="event-booking-tabs committee-info-overflow tab-content overflow-hidden leading-normal">
              {/* <EventsBookings /> */}

              {this.state.bookingList && this.state.bookingList.length > 0 ? (
                <>
                  {this.state.bookingList.map((detail, key) => {
                    return (
                      <>
                        {detail.isGuest === true ? null : (
                          <div
                            className="custom-card workshop-shadow"
                            key={key}
                          >
                            <div className="bg-white mb-10 last:mb-0">
                              <div className="2xl:px-5 2xl:pb-5  lg:pl-5 ">
                                <div className="grid grid-cols-12 gap-6 w-full ">
                                  <div className="2xl:col-span-9 lg:col-span-9 col-span-12 2xl:pt-7 lg:mt-4 items-center">
                                    <div className="grid grid-cols-12 gap-6">
                                      <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                                        <div className="flex items-baseline justify-between">
                                          <div className="left-side w-[50%]">
                                            <h2 className="text-xl font-bold text-[#c00000] 2xl:mb-3">
                                              {detail.eventName}
                                            </h2>
                                            {/* <h2 className="text-xl text-[#757575] 2xl:mb-3">
                                                    Trainer Company Name
                                                  </h2> */}
                                          </div>

                                          {detail.lessonList &&
                                            detail.lessonList.length > 0 ? (
                                            <div className="right-side w-[50%]">
                                              <h2 className="text-xl text-[#757575] 2xl:mb-3">
                                                {/* 14 Oct 2022 (Fri), 12:00 PM - 3:00 PM */}
                                              </h2>
                                              <div className="custom-accordian">
                                                <div className="col">
                                                  <div className="tabs">
                                                    <div className="tab">
                                                      <input
                                                        type="checkbox"
                                                        id={`Acc_B/${key}_L/`}
                                                      />
                                                      <label
                                                        className="text-xl text-[#c00000] tab-label"
                                                        htmlFor={`Acc_B/${key}_L/`}
                                                      >
                                                        View session details
                                                      </label>
                                                      {detail.lessonList.map(
                                                        (les, lesIndex) => {
                                                          return (
                                                            <div
                                                              className="tab-content"
                                                              key={lesIndex}
                                                            >
                                                              <h3 className="text-[#757575] text-lg">
                                                                {/* 16 Nov 2022 (Wed), 12:00 PM - 2:00 PM */}
                                                                {moment(
                                                                  les.eventLessonDate
                                                                ).format(
                                                                  "DD MMM YYYY (ddd),"
                                                                )}
                                                                {
                                                                  les.eventStartTime
                                                                }{" "}
                                                                -{" "}
                                                                {
                                                                  les.eventEndTime
                                                                }
                                                              </h3>
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                      <div className="xl:col-span-12 lg:col-span-12 col-span-12"></div>
                                    </div>
                                  </div>
                                  <div className="2xl:col-span-3 lg:col-span-3 col-span-12 mb-5">
                                    <div className="flex justify-end flex-col items-end">
                                      <div className="right-status-label relative top-[9px] 2xl:left-[20px] lg:left-[0px]">
                                        {/* <span className="text-xl text-white font-semibold bg-[#7a9763] 2xl:px-7 2xl:py-3 lg:px-3 lg:py-3 md:px-3 md:py-3"> */}
                                        <span className={`text-xl text-white font-semibold  2xl:px-2 xl:px-2 lg:px-3 md:px-6 sm:px-4 py-3 ${detail.bookingStatus === "Booked" ? "bg-[#7a9763]" : "bg-[#ffae00]"}`}>
                                          {detail.bookingStatus}
                                        </span>
                                      </div>
                                      {/* <div className="relative top-[9px] left-0 mr-6 mt-[50px] ">
                                                <button className="btn btn-red-border text-xl">
                                                  Download Invoice
                                                </button>
                                              </div> */}
                                    </div>
                                  </div>
                                </div>
                                <hr className="mt-2 border-t border-t-[#c9c9c9]"></hr>
                                <div className="grid grid-cols-12 gap-6 w-full ">
                                  <div className="col-span-12 2xl:pt-7 lg:mt-4 items-center">
                                    <div className="grid grid-cols-12 gap-6">
                                      <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                                        <div className="grid grid-cols-12 gap-6">
                                          <div className="2xl:col-span-10 lg:col-span-9 col-span-12">
                                            <div className="grid grid-cols-12 gap-6">
                                              <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                                                <div className="flex items-center">
                                                  <h2 className="text-xl text-[#757575] w-[40%]">
                                                    Booking Ref:
                                                  </h2>
                                                  <h2 className="text-xl text-[#757575] w-[60%]">
                                                    {detail.bookingReferenceNo}
                                                  </h2>
                                                </div>
                                                <div className="flex items-center">
                                                  <h2 className="text-xl text-[#757575] w-[40%]">
                                                    Booking Date:
                                                  </h2>
                                                  <h2 className="text-xl text-[#757575] w-[60%]">
                                                    {moment(
                                                      detail.bookingDate
                                                    ).format("DD MMM YYYY")}
                                                  </h2>
                                                </div>
                                                <div className="flex items-center">
                                                  <h2 className="text-xl text-[#757575] w-[40%]">
                                                    Type:
                                                  </h2>
                                                  <h2 className="text-xl text-[#757575] w-[60%]">
                                                    {detail.eventTypeName}
                                                  </h2>
                                                </div>
                                                <div className="flex items-start">
                                                  <h2 className="text-xl text-[#757575] w-[40%]">
                                                    Add Ons:
                                                  </h2>

                                                  {detail.addOnList.length >
                                                    0 ? (
                                                    <div>
                                                      {detail.addOnList.map(
                                                        (addon, addonIndex) => {
                                                          return (
                                                            <div
                                                              className=" flex flex-col"
                                                              key={addonIndex}
                                                            >
                                                              <h2 className="text-xl text-[#757575] w-[60%] whitespace-nowrap">
                                                                {
                                                                  addon.eventAddOnType
                                                                }
                                                              </h2>
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  ) : (
                                                    "-"
                                                  )}
                                                </div>
                                                {/* <div className="flex items-center">
                                  <h2 className="text-xl text-[#757575] w-[40%] block">
                                    CPD Units:
                                  </h2>
                                  <h2 className="text-xl text-[#757575] w-[60%] block">
                                    3 Units
                                  </h2>
                                </div> */}
                                              </div>
                                              <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                                                <div className="flex items-center">
                                                  <h2 className="text-xl text-[#757575] w-[40%]">
                                                    Original Price:
                                                  </h2>

                                                  <h2 className="text-xl text-[#757575] w-[60%]">
                                                    {`$${parseFloat(
                                                      detail.totalPrice
                                                    ).toFixed(2)}`}
                                                  </h2>
                                                </div>

                                                <div className="flex items-center">
                                                  <h2 className="text-xl text-[#757575] w-[40%]">
                                                    AddOns Price:
                                                  </h2>

                                                  <h2 className="text-xl text-[#757575] w-[60%]">
                                                    {`$${parseFloat(
                                                      detail.addOnPrice
                                                    ).toFixed(2)}`}
                                                  </h2>
                                                </div>

                                                <div className="flex items-center">
                                                  <h2 className="text-xl text-[#757575] w-[40%]">
                                                    Discount:
                                                  </h2>
                                                  <h2 className="text-xl text-[#757575] w-[60%]">
                                                    {detail.discountPrice > 0
                                                      ? `$${parseFloat(
                                                        detail.discountPrice
                                                      ).toFixed(2)}`
                                                      : "N.A"}
                                                  </h2>
                                                </div>
                                                {/* <div className="flex items-center">
                                  <h2 className="text-xl text-[#757575] w-[50%]">
                                    eWallet:
                                  </h2>
                                  <h2 className="text-xl text-[#757575] w-[50%]">
                                    N.A.
                                  </h2>
                                </div> */}
                                                <div className="flex items-center">
                                                  <h2 className="text-xl text-[#757575] w-[40%]">
                                                    Price Paid:
                                                  </h2>
                                                  {/* <h2 className="text-xl text-[#757575] w-[60%]">
                                                    {`$${parseFloat(
                                                      (detail.addOnPrice + detail.totalPrice) -
                                                      detail.discountPrice
                                                    ).toFixed(2)}`}
                                                  </h2> */}
                                                  <h2 className="text-xl text-[#757575] w-[60%]">
                                                    {`$${parseFloat(
                                                      detail.paidPrice
                                                    ).toFixed(2)}`}
                                                  </h2>
                                                </div>
                                                <div className="flex items-center">
                                                  <h2 className="text-xl text-[#757575] w-[40%]">
                                                    Promo Code:
                                                  </h2>
                                                  <h2 className="text-xl text-[#757575] w-[60%]">
                                                    {detail.promoCode !== ""
                                                      ? detail.promoCode
                                                      : "N.A"}
                                                  </h2>
                                                </div>
                                                <div className="flex items-center">
                                                  <h2 className="text-xl text-[#757575] w-[40%]">
                                                    Attendee Name:
                                                  </h2>
                                                  <h2 className="text-xl text-[#757575] w-[60%]">
                                                    {detail.firstName}{" "}
                                                    {detail.lastName}
                                                  </h2>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="2xl:col-span-2 lg:col-span-3 col-span-12 2xl:p-[0px] lg:p-[20px] p-[0px]">
                                            <div className="justify-end flex w-full items-start">
                                              <div>
                                                <div className="right-btn w-full mt-5">
                                                  <div className="flex items-center justify-end mb-5">
                                                    <button
                                                      className="2xl:px-7 lg:px-3 2xl:py-2 lg:py-0 btn-red-border text-xl border text-[#C00000]"
                                                      onClick={() =>
                                                        this.getInvoiceReceiptPdfByBookingId(
                                                          detail.bookingId,
                                                          "Invoice"
                                                        )
                                                      }
                                                    >
                                                      <span className="text-xl text-[#c00000] font-semibold tracking-normal underline">
                                                        View Invoice
                                                      </span>
                                                    </button>
                                                  </div>
                                                  {detail.bookingStatusId ===
                                                    2 ? (
                                                    <div className="flex items-center justify-end">
                                                      <button
                                                        className="2xl:px-7 lg:px-3 2xl:py-2 lg:py-0 btn-red-border text-xl border text-[#C00000]"
                                                        onClick={() =>
                                                          this.getInvoiceReceiptPdfByBookingId(
                                                            detail.bookingId,
                                                            "Receipt"
                                                          )
                                                        }
                                                      >
                                                        <span className="text-xl text-[#c00000] font-semibold tracking-normal underline">
                                                          View Receipt
                                                        </span>
                                                      </button>
                                                    </div>
                                                  ) : null}

                                                  {isNullString(detail.creditNotePath) !== "" ? (
                                                    <>
                                                      <div className="flex items-center justify-end mb-5">
                                                        <button
                                                          className="2xl:px-7 lg:px-5 py-2 btn-red-border text-xl border text-[#C00000]"
                                                          onClick={() => {
                                                            //  this.getInvoiceReceiptPdfByBookingId(detail.bookingId, "CreditNote")
                                                            this.financeGetCreditNoteInvoice(
                                                              detail.creditNotePath
                                                            );
                                                          }}
                                                        >
                                                          <span className="text-xl text-[#c00000] font-semibold tracking-normal underline">
                                                            Download Credit Note
                                                          </span>
                                                        </button>
                                                      </div>
                                                    </>
                                                  ) :
                                                    null}
                                                </div>
                                                {/* {detail.eventTypeName ===
                                                  "Forms (Interest to Participate)" ? (
                                                  <div className="flex justify-center py-12">
                                                    <button
                                                      className="btn btn-red font-old text-lg text-white"
                                                      onClick={() =>
                                                        this.redirectToFormOverseas(
                                                          detail.bookingReferenceNo
                                                        )
                                                      }
                                                    >
                                                      Fill up form
                                                    </button>
                                                  </div>
                                                ) : null} */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* Bottom-section Hide Show on Conditionally Start */}
                                        {this.state.openTab === 1 &&
                                          detail.bookingStatusId === 2 ? (
                                          <div
                                            className="grid grid-cols-1 gap-6 items-center justify-end"
                                            key={`CancelSec_${key}`}
                                          >
                                            <div className="xl:col-span-3 lg:col-span-3 col-span-12 flex justify-end w-full">
                                              <button
                                                key={`CancelButton_${key}`}
                                                className="booking-request-btn btn btn-red text-lg text-white"
                                                onClick={(e) => {
                                                  this.setState(
                                                    {
                                                      bookingIdToCancel:
                                                        detail.bookingId,
                                                    },
                                                    () => {
                                                      //this.cancelBooking(detail);
                                                      e.stopPropagation();
                                                      this.setState({
                                                        setRequesttocancelModalOpen: true,
                                                      });
                                                    }
                                                  );
                                                }}
                                              >
                                                Request to Cancel
                                              </button>
                                            </div>
                                          </div>
                                        ) : null}
                                        {this.state.openTab === 1 &&
                                          detail.bookingStatusId === 5 ? (
                                          <div
                                            className="grid grid-cols-1 gap-6 items-center justify-end"
                                            key={`PendingCancelSec_${key}`}
                                          >
                                            <div className="xl:col-span-3 lg:col-span-3 col-span-12 flex justify-end w-full">
                                              <button className="booking-request-btn btn btn-light-gray text-lg text-white cursor-not-allowed">
                                                Pending Cancellation
                                              </button>
                                            </div>
                                          </div>
                                        ) : null}

                                        {detail.bookingStatusId === 2 ? (
                                          <>
                                            <div className="flex items-center justify-end">
                                              <button
                                                className="2xl:px-[15px] lg:px-[10px] py-2 btn btn-red text-white"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  this.setState({
                                                    generateCreditNote: true,
                                                  });
                                                  this.getCreditNoteInfoForEventBookingByBookingId(
                                                    detail.bookingId,
                                                    detail.bookingStatus
                                                  );
                                                  this.setState({
                                                    isInvoiceCancel: false,
                                                  });
                                                  this.getCancellationReasonForCreditNote();
                                                  // this.state.addEditCreditNote.bookingId =
                                                  //   detail.bookingId;
                                                  this.setState(prevState => ({
                                                    addEditCreditNote: {
                                                        ...prevState.addEditCreditNote,
                                                        bookingId: detail.bookingId
                                                    }
                                                }));
                                                }}
                                              >
                                                <span className="text-xl text-white font-semibold tracking-normal">
                                                  Generate Credit Note
                                                </span>
                                              </button>
                                            </div>
                                          </>
                                        ) : null
                                      
                                        }
                                        {detail.bookingStatusId ===7?
                                        
                                          <>
                                            <div className="flex items-center justify-end">
                                              <button
                                                className="2xl:px-[15px] lg:px-[10px] py-2 btn btn-red text-white"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  this.setState({
                                                    generateCreditNote: true,
                                                  });
                                                  this.setState({
                                                    isInvoiceCancel: true,
                                                  });
                                                  this.getCreditNoteInfoForEventBookingByBookingId(
                                                    detail.bookingId,
                                                    detail.bookingStatus
                                                  );
                                                  this.getCancellationReasonForCreditNote();
                                                  // this.state.addEditCreditNote.bookingId =
                                                  //   detail.bookingId;
                                                  this.setState(prevState => ({
                                                    addEditCreditNote: {
                                                        ...prevState.addEditCreditNote,
                                                        bookingId: detail.bookingId
                                                    }
                                                }));
                                                   
                                                }}
                                              >
                                                <span className="text-xl text-white font-semibold tracking-normal">
                                                  Cancel Invoice
                                                </span>
                                              </button>
                                            </div>
                                          </>
                                        :null}
                                        {/* Bottom-section Hide Show on Conditionally End */}
                                      </div>

                                      <div className="add-edite-profile-popup">
                                        <GenerateCreditNoteForEventBooking
                                          generateCreditNote={
                                            this.state.generateCreditNote
                                          }
                                          setOpenModal={() =>
                                            this.setState({
                                              generateCreditNote: false,
                                            })
                                          }
                                          individualCreditNotePaymentInfo={
                                            this.state
                                              .individualCreditNotePaymentInfo
                                          }
                                          cancellationReason={
                                            this.state.cancellationReason
                                          }
                                          setFilterParameters={
                                            this.setFilterParameters.bind(this)
                                          }
                                          isOtherCancellationReason={
                                            this.state.isOtherCancellationReason
                                          }
                                          addEditCreditNote={
                                            this.state.addEditCreditNote
                                          }
                                          handleChange={this.handleChange.bind(this)}
                                          addFinanceCreditNote={
                                            this.addFinanceCreditNote
                                          }
                                          validate={this.validateFinanceCreditNote.bind(this)}
                                          validCreditNote={this.state.validCreditNote}
                                          validate2={this.validFinanceCreditNoteForOtherReason.bind(this)}
                                          validCreditNote2={
                                            this.state.validCreditNote2
                                          }
                                          isInvoiceCancel={
                                            this.state.isInvoiceCancel
                                          }
                                          isLoading={this.state.isLoading}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {detail.guestList &&
                                detail.guestList.length > 0 ? (
                                <div className="w-full">
                                  <div className="custom-accordian bottom-acc">
                                    <div className="col">
                                      <div className="tabs">
                                        <div className="tab">
                                          <input
                                            type="checkbox"
                                            id={`Acc_?B/${key}_L`}
                                          />
                                          <label
                                            className="text-xl text-[#c00000] tab-label bg-[#f1f1f1] py-3 w-full block"
                                            htmlFor={`Acc_?B/${key}_L`}
                                          >
                                            Invited Guest(s)
                                          </label>
                                          <div className="tab-content">
                                            <div className="px-2 2xl:pb-5  pt-5">
                                              <h2 className="text-xl text-[#757575] 2xl:mb-3">
                                                My Invited Guest Information
                                              </h2>

                                              {detail.guestList.map(
                                                (guest, guestIndex) => {
                                                  return (
                                                    <div
                                                      className="border-b pb-4 last:border-b-0"
                                                      key={guestIndex}
                                                    >
                                                      <div className="grid grid-cols-12 gap-6 w-full ">
                                                        <div className="2xl:col-span-10 lg:col-span-9 col-span-12 2xl:pt-2 lg:mt-2 items-center">
                                                          <div className="grid grid-cols-12 gap-6">
                                                            <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                                                              <div className="flex items-baseline justify-between">
                                                                <div className="left-side w-[50%]">
                                                                  {/* <h2 className="text-xl text-[#757575] 2xl:mb-3">
                                                        My Invited Guest Information
                                                      </h2> */}
                                                                  <h2 className="text-xl text-[#757575] 2xl:mb-3 font-bold">
                                                                    Guest{" "}
                                                                    {guestIndex +
                                                                      1}
                                                                  </h2>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      {/* <hr className="mt-2 border-t border-t-[#c9c9c9]"></hr> */}
                                                      <div className="grid grid-cols-12 gap-6 w-full ">
                                                        <div className="col-span-12 lg:mt-4 items-center">
                                                          <div className="grid grid-cols-12 gap-6">
                                                            <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                                                              <div className="grid grid-cols-12 gap-6">
                                                                <div className="xl:col-span-10 lg:col-span-10 col-span-12">
                                                                  <div className="grid grid-cols-12 gap-6">
                                                                    <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                                                                      <div className="flex items-center">
                                                                        <h2 className="text-xl text-[#757575] w-[40%]">
                                                                          Booking
                                                                          Ref:
                                                                        </h2>
                                                                        <h2 className="text-xl text-[#757575] w-[60%]">
                                                                          {
                                                                            guest.bookingReferenceNo
                                                                          }
                                                                        </h2>
                                                                      </div>
                                                                      <div className="flex items-center">
                                                                        <h2 className="text-xl text-[#757575] w-[40%]">
                                                                          First
                                                                          Name:
                                                                        </h2>
                                                                        <h2 className="text-xl text-[#757575] w-[60%]">
                                                                          {
                                                                            guest.firstName
                                                                          }
                                                                        </h2>
                                                                      </div>
                                                                      <div className="flex items-center">
                                                                        <h2 className="text-xl text-[#757575] w-[40%]">
                                                                          Mobile:
                                                                        </h2>
                                                                        <h2 className="text-xl text-[#757575] w-[60%]">
                                                                          {
                                                                            guest.contactNo
                                                                          }
                                                                        </h2>
                                                                      </div>
                                                                      <div className="flex items-center">
                                                                        <h2 className="text-xl text-[#757575] w-[40%] block">
                                                                          Company:
                                                                        </h2>
                                                                        <h2 className="text-xl text-[#757575] w-[60%] block">
                                                                          {
                                                                            guest.companyName
                                                                          }
                                                                        </h2>
                                                                      </div>
                                                                    </div>
                                                                    <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                                                                      <div className="flex items-center">
                                                                        <h2 className="text-xl text-[#757575] w-[40%]">
                                                                          Last
                                                                          Name:
                                                                        </h2>

                                                                        <h2 className="text-xl text-[#757575] w-[60%]">
                                                                          {/* {`$${parseFloat(
                                                                        guest.totalPrice
                                                                      ).toFixed(
                                                                        2
                                                                      )}`} */}
                                                                          {
                                                                            guest.lastName
                                                                          }
                                                                        </h2>
                                                                      </div>
                                                                      <div className="flex items-center">
                                                                        <h2 className="text-xl text-[#757575] w-[40%]">
                                                                          Email:
                                                                        </h2>
                                                                        <h2 className="text-xl text-[#757575] w-[60%]">
                                                                          {/* {detail.discountPrice >
                                                                        0
                                                                        ? `$${parseFloat(
                                                                          detail.discountPrice
                                                                        ).toFixed(
                                                                          2
                                                                        )}`
                                                                        : "N.A"} */}
                                                                          {
                                                                            guest.email
                                                                          }
                                                                        </h2>
                                                                      </div>
                                                                      <div className="flex items-center">
                                                                        <h2 className="text-xl text-[#757575] w-[40%]">
                                                                          Job
                                                                          Title:
                                                                        </h2>
                                                                        <h2 className="text-xl text-[#757575] w-[60%]">
                                                                          N.A.
                                                                        </h2>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="xl:col-span-2 lg:col-span-2 col-span-12">
                                                                  <div className="justify-end flex w-full items-start">
                                                                    {/* <img
                                                                      className="w-[90px] h-[90px] object-cover"
                                                                      src={
                                                                        APIURL +
                                                                        GetImage +
                                                                        "?type=QRCode&&fileName=" +
                                                                        guest.qrCodePath
                                                                      }
                                                                      alt=""
                                                                    /> */}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      {/* <hr className="mt-2 border-t border-t-[#c9c9c9]"></hr> */}
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
