import moment from "moment";
import React from "react";
import ModalBasic from "../../pages/component/ModalBasic";
import ValidationText from "../../utils/validation/ValidationText";
import DatePicker from "../datePicker/DatePicker";
import DropdownSelect from "../dropdown/Dropdown";
import TextInput from "../UIComponent/TextInput";
function GenerateCreditNoteModal(props) {
  return (
    <>
      <ModalBasic
        id="generate-credit-note-modal"
        className="generate-credit-note-modal"
        modalOpen={props.setCreditNoteModel}
        setModalOpen={props.setModalOpen}
        title="Generate Credit Note"
      >
        {/* Modal content */}
        <div className="bg-white mb-10">
          <div className="">
            <div className="grid grid-cols-12 gap-6 w-full ">
              <div className="col-span-12 2xl:pt-7 lg:mt-4 items-center">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                        <div className="flex items-center">
                          <h3 className="small-title font-bold theme-color px-12">
                            Effective Date
                          </h3>
                        </div>
                        <div className="flex items-center px-12">
                          <DatePicker
                            name="creditNoteMonth"
                            handleChange={props.handleChangeForCreditNote}
                            identity="CreditNote"
                            value={props.adhocInvoiceDetail.creditNoteMonth === "" || props.adhocInvoiceDetail.creditNoteMonth === null ? moment().format("yyyy-MM-DD") : moment(props.adhocInvoiceDetail.creditNoteMonth).format("yyyy-MM-DD")}
                            minDate={moment().format("yyyy-MM-DD")}
                            disabled={props.adhocInvoiceDetail.paymentStatus === 'Pending' ? true : false}
                            isCurserAllowed={props.adhocInvoiceDetail.paymentStatus === 'Pending' ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 gap-6 w-full ">
              <div className="col-span-12 2xl:pt-5 lg:mt-4 items-center">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                        <div className="flex items-center">
                          <h3 className="small-title font-bold theme-color px-12">
                            Itemised item
                          </h3>
                        </div>
                      </div>
                      <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                        <div className="flex items-center">
                          <h3 className="small-title font-bold theme-color px-12">
                            Amount
                          </h3>
                        </div>
                      </div>
                      <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                        <div className="flex items-center">
                          <h3 className="small-title font-bold theme-color px-12">
                            Refund Amount
                          </h3>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            {props?.adhocInvoiceDetail?.adhokInvoiceItems && props.adhocInvoiceDetail.adhokInvoiceItems.map((item, keyId) => {
              return (
                <>
                  <div className="grid grid-cols-12 gap-6 w-" key={keyId}>
                    <div className="col-span-12 2xl:pt-7  lg:mt-4 items-center">
                      <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12">
                          <div className="grid grid-cols-12 gap-6">
                            <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                              <div className="flex item-center">
                                <h4 className="small-title  text-[24px] theme-color px-12">
                                  {item.adhokInvoiceDescription}
                                </h4>
                              </div>
                            </div>
                            <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                              <div className="flex items-center">
                                <h4 className="small-title text-[24px] theme-color px-12">
                                  {item.adhokAmount}
                                </h4>
                              </div>
                            </div>
                            <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                              <div className="flex  px-12">
                                <TextInput
                                  placeholder=""
                                  type="text"
                                  name={`refundAmount_${keyId}`}
                                  value={item.refundAmount}
                                  identity="CreditNoteItem"
                                  handleChange={props.handleChangeForCreditNote}
                                  onBlur={(e) => props.validateInvoiceItem(`refundAmount_${keyId}`, props.adhocInvoiceDetail, keyId)}
                                  disabled={props.adhocInvoiceDetail.paymentStatus === 'Pending' ? true : false}
                                  isCurserAllowed={props.adhocInvoiceDetail.paymentStatus === 'Pending' ? true : false}
                                />
                              </div>
                              <div className="flex  px-12">
                                <ValidationText
                                  error={props.validInvoiceItem.error[`refundAmount_${keyId}`]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-2 border-t border-t-[#c9c9c9] mx-12"></hr>
                </>
              );
            })}
            <div className="grid grid-cols-12 gap-6 w-">
              <div className="col-span-12 2xl:pt-7  lg:mt-4 items-center">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                        <div className="flex item-center">
                          <h4 className="small-title  text-[24px] theme-color px-12">
                            Sub total
                          </h4>
                        </div>
                      </div>
                      <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                        <div className="flex items-center">
                          <h4 className="small-title text-[24px] theme-color px-12">
                            {props.adhocInvoiceDetail.adhokSubtotalAmount}
                          </h4>
                        </div>
                      </div>
                      <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                        <div className="flex  px-12">
                          <h4 className="small-title text-[24px] theme-color px-12 font-bold text-left">
                            {props.adhocInvoiceDetail.refundSubTotalAmount}
                          </h4>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mt-2 border-t border-t-[#c9c9c9] mx-12"></hr>
            <div className="grid grid-cols-12 gap-6 w-">
              <div className="col-span-12 2xl:pt-7  lg:mt-4 items-center">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                        <div className="flex item-center">
                          <h4 className="small-title  text-[24px] theme-color px-12">
                            GST
                          </h4>
                        </div>
                      </div>
                      <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                        <div className="flex items-center">
                          <h4 className="small-title text-[24px] theme-color px-12">
                            {props.adhocInvoiceDetail.adhokGSTAmount}
                          </h4>
                        </div>
                      </div>
                      <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                        <div className="flex  px-12">
                          <h4 className="small-title text-[24px] theme-color px-12 font-bold">
                            {props.adhocInvoiceDetail.refundGSTAmount}
                          </h4>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <hr className="mt-2 border-t border-t-[#c9c9c9] mx-12"></hr>
            <div className="grid grid-cols-12 gap-6 w-">
              <div className="col-span-12 2xl:pt-7  lg:mt-4 items-center">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                        <div className="flex item-center">
                          <h4 className="small-title text-[24px] theme-color px-12">
                            Discount
                          </h4>
                        </div>
                      </div>
                      <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                        <div className="flex items-center">
                          <h4 className="small-title text-[24px] theme-color px-12">
                            -{props.corporateCreditNotePaymentInfo.discountedAmount}
                          </h4>
                        </div>
                      </div>
                      <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                        <div className="flex  px-12">
                          <h4 className="small-title text-[24px] theme-color px-12 font-bold">
                            -{props.addEditCreditNote.discountRefund}
                          </h4>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <hr className="mt-2 border-t border-t-[#c9c9c9] mx-12"></hr>
            <div className="grid grid-cols-12 gap-6 w-">
              <div className="col-span-12 2xl:pt-7  lg:mt-4 items-center">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                        <div className="flex item-center">
                          <h4 className="small-title  text-[24px] theme-color px-12 font-bold">
                            Total Refund Amount
                          </h4>
                        </div>
                      </div>
                      <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                        <div className="flex items-center">
                          <h4 className="small-title text-[24px] theme-color px-12">
                            {props.adhocInvoiceDetail.adhokTotalAmount}
                          </h4>
                        </div>
                      </div>
                      <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                        <div className="flex  px-12">
                          <h4 className="small-title text-[24px] theme-color px-12 font-bold">
                            {props.adhocInvoiceDetail.refundTotalAmount}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full ">
            <div className="col-span-12 2xl:pt-7 lg:mt-4 items-center">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                      <div className="flex items-center">
                        <h3 className="small-title font-bold theme-color px-12">
                          Cancellation Reason
                        </h3>
                      </div>
                      <div className="flex items-center w-full px-12" >
                        <DropdownSelect
                          disabled={false}
                          drpIdentity={"cancel_reason"}
                          optionArray={props.cancellationReason}
                          value={props.adhocInvoiceDetail.cancellationReasonId}
                          setFilterParameters={props.setFilterParameters}
                          onBlur={(e) => props.validate("cancellationReasonId", props.adhocInvoiceDetail)}
                        />
                      </div>
                      <div className="flex items-center w-full px-12">
                        <ValidationText
                          error={props.validCreditNote.error.cancellationReasonId}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {props.isOtherCancellationReason ?
            <>
              <div className="grid grid-cols-12 gap-6 w-full ">
                <div className="col-span-12 2xl:pt-7 lg:mt-4 items-center">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <div className="grid grid-cols-12 gap-6">
                        <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                          <div className="flex items-center">
                            <h3 className="small-title font-bold theme-color px-12">
                              Others
                            </h3>
                          </div>
                          <div className="flex items-center px-12">
                            <TextInput
                              placeholder=""
                              type="text"
                              name="cancellationReason"
                              value={props.adhocInvoiceDetail.cancellationReason}
                              identity="CreditNote"
                              handleChange={props.handleChangeForCreditNote}
                              onBlur={(e) => props.validate("cancellationReason", props.adhocInvoiceDetail)}
                            />
                          </div>
                          <div className="flex items-center px-12">
                            <ValidationText
                              error={props.validCreditNote.error.cancellationReason}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
            : null
          }

          {/* Delete and Save Button */}
          <div className="grid grid-cols-12 gap-6 w-full pt-16 px-10 ">
            <div className="xl:col-span-12 lg:col-span-12 col-span-12">
              <div className="flex items-center justify-center">
                <div className="savebtn">
                  <button className=" px-14 py-3 bg-[#C00000] text-xl text-white font-semibold"
                    onClick={() => props.addEdit()}
                  >
                    Generate
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBasic>
    </>
  );
}

export default GenerateCreditNoteModal;
