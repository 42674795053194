import React, { Component } from "react";
import SectionWithBorder from "../../../components/UIComponent/SectionBorder";
import ChapterSection from "../../../components/membership/membershipPricing/ChapterSection";
import Row3Input from "../../../components/membership/membershipPricing/Row3Input";
import MembershipPricingServices from "../../../services/axiosServices/apiServices/MembershipPricingServices";
import { NavLink } from "react-router-dom";
import TextInput from "../../../components/UIComponent/TextInput";
import SwalServices from "../../../services/swalServices/SwalServices";

import {
  isValidForm,
  validate,
} from "../../../utils/validation/CommonValidator";
import { CommonValidationMessages } from "../../../utils/Messages";
import { getAuthProps } from "../../../utils/AuthenticationLibrary";

export default class MembershipPricing extends Component {
  constructor(props) {
    super(props);
    this.membershipPricingServices = new MembershipPricingServices();
    this.swalServices = new SwalServices();

    this.state = {
      // membershipPricing: {
      //   membershipPricingId: 0,
      //   baseOrdinaryFee: 0,
      //   baseAssociateFee: 0,
      //   annualOrdinaryFee1M: 0,
      //   annualOrdinaryFee10M: 0,
      //   annualOrdinaryFee30M: 0,
      //   annualOrdinaryFee50M: 0,
      //   annualOrdinaryFeeMax: 0,
      //   annualAssociateFee1M: 0,
      //   annualAssociateFee10M: 0,
      //   annualAssociateFee30M: 0,
      //   annualAssociateFee50M: 0,
      //   annualAssociateFeeMax: 0,
      //   annualFreeChapter: 0,
      //   annualAdditionalChapterPrice: 0,
      //   startUpEntranceFee: 0,
      //   startUp1stYearFee: 0,
      //   startUp2ndYearFee: 0,
      //   startUp3rdYearFee: 0,
      //   startUp4thYearFee: 0,
      //   startUp5thYearFee: 0,
      //   startUpFreeChapter: 0,
      //   startUpAdditionalChapterPrice: 0,
      // },
      membershipPricing: {
        membershipPricingId: 0,
        entranceFee:0,
        subscriptionFee:0,
        ordinaryEntranceFee: 0,
        ordinarySubscriptionFee: 0,
        qualifyingEntranceFee: 0,
        qualifyingSubscriptionFee: 0,
        associateEntranceFee: 0,
        associateSubscriptionFee: 0,
        membershipPricingData:[],
        isTableShow:false,
      },
      membershipProRatedPricing: [],
      // membershipProRatedPricing: {
      //   membershipTypeId: 0,
      //   membershipMonth: 0,
      //   entranceFee: 0,
      //   subscriptionFee: 0,
      // },
      validationRule: {
        annualFreeChapter: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUpFreeChapter: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        ordinaryEntranceFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        qualifyingEntranceFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        associateEntranceFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        ordinarySubscriptionFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        qualifyingSubscriptionFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        associateSubscriptionFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualOrdinaryFee1M: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualOrdinaryFee10M: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualOrdinaryFee30M: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualOrdinaryFee50M: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualOrdinaryFeeMax: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualAssociateFee1M: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualAssociateFee10M: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualAssociateFee30M: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualAssociateFee50M: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualAssociateFeeMax: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualAdditionalChapterPrice: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUpEntranceFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUp1stYearFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUp2ndYearFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUp3rdYearFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUp4thYearFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUp5thYearFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUpAdditionalChapterPrice: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
      },
      validState: {
        isValid: true,
        error: {},
      },
      isLoading: false
    };
  }
  handleChange(event) {
    const value = event.target.value;
    var num = parseFloat(value).toFixed(0);
    const name = event.target.name;
    let detail = this.state.membershipPricing;
    detail[name] = num;
    this.setState({ membershipPricing: { ...detail } });
  }
  
  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.membershipPricing,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.membershipPricing,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  addMembershipPricing = () => {
    let isValid = this.isValidateAllFields();
    if (isValid) {
      this.membershipPricingServices
        .addEditMembershipPricing(this.state.membershipPricing)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.swalServices.Success(
              "Membership pricing updated successfully."
            );
          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };

  getMembershipPricing = () => {
    this.membershipPricingServices.getMembershipPricing().then((response) => {
      if (response.statusCode === 200 && response.responseItem.responseContent != null) {
        let details = response.responseItem.responseContent ;
        let membershipPricing = this.state.membershipPricing;
        membershipPricing.membershipPricingData = details;
        membershipPricing.ordinaryEntranceFee = details[0].entranceFee ;
        membershipPricing.qualifyingEntranceFee = details[1].entranceFee ;
        membershipPricing.associateEntranceFee = details[2].entranceFee ;
         membershipPricing.ordinarySubscriptionFee = details[0].subscriptionFee;
        membershipPricing.qualifyingSubscriptionFee=  details[1].subscriptionFee;
       membershipPricing.associateSubscriptionFee =  details[2].subscriptionFee;
        this.setState({
          membershipPricing:membershipPricing,
        });
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  updateMembershipPricingData = ()=>{
    let request = { ...this.state.membershipPricing };
    request.membershipPricingData[0].entranceFee = request.ordinaryEntranceFee;
    request.membershipPricingData[1].entranceFee = request.qualifyingEntranceFee;
    request.membershipPricingData[2].entranceFee = request.associateEntranceFee;
    request.membershipPricingData[0].subscriptionFee = request.ordinarySubscriptionFee;
    request.membershipPricingData[1].subscriptionFee = request.qualifyingSubscriptionFee;
    request.membershipPricingData[2].subscriptionFee = request.associateSubscriptionFee;
    this.membershipPricingServices.updateMembershipPricingData(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem.responseContent != null) {
        let details = response.responseItem.responseContent ;
        details.isTableShow = true;
        this.setState({
          membershipPricing:details,
        },()=>{
        
          this.getAllMembershipProRatedPricing();
        });
        this.getMembershipPricing();
       
      } 
      else {
        this.swalServices.Error(response.message);
      }
    })
  }



 

  getAllMembershipProRatedPricing = () => {
    this.membershipPricingServices.getAllMembershipProRatedPricing().then((response) => {
      if (response.statusCode === 200 && response.responseItem.responseContent != null) {
        this.setState({ membershipProRatedPricing: response.responseItem.responseContent });
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  componentDidMount() {
    let cookie = getAuthProps();
    const adminUserId = (cookie && cookie.adminUserId) ? Number(cookie.adminUserId) : 0;
    if (adminUserId > 0) {
        this.setState({ createdBy: adminUserId });
    }
    this.getMembershipPricing();
  }

  getMonthName = (monthNumber) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[monthNumber - 1];
  };

  filterByMembershipType = (typeId) => {
    return this.state.membershipProRatedPricing.filter(item => item.membershipTypeId === typeId);
  };

  renderRows = () => {
    const ordinaryData = this.filterByMembershipType(1); 
    const qualifyingData = this.filterByMembershipType(2);
    const associateData = this.filterByMembershipType(3); 

    return ordinaryData.map((month, index) => (
      <tr key={index} className="border-b border-gray-200">
        <td className="text-sm text-gray-600 px-4 py-2 text-center border-r border-gray-200">
          {this.getMonthName(month.membershipMonth)}
        </td>
        <td className="text-sm text-gray-600 px-2 py-2 text-center border-r border-gray-200">{`$${ordinaryData[index].entranceFee}`}</td>
        <td className="text-sm text-gray-600 px-2 py-2 text-center border-r border-gray-200">{`$${ordinaryData[index].subscriptionFee}`}</td>
        <td className="text-sm text-gray-600 px-2 py-2 text-center border-r border-gray-200">{`$${qualifyingData[index].entranceFee}`}</td>
        <td className="text-sm text-gray-600 px-2 py-2 text-center border-r border-gray-200">{`$${qualifyingData[index].subscriptionFee}`}</td>
        <td className="text-sm text-gray-600 px-2 py-2 text-center border-r border-gray-200">{`$${associateData[index].entranceFee}`}</td>
        <td className="text-sm text-gray-600 px-2 py-2 text-center border-r border-gray-200">{`$${associateData[index].subscriptionFee}`}</td>
      </tr>
    ));
  };

  render() {
    return (
      <>
        <div className="main-body-section">
          <div className="bg-white">
            <div className="heading-part pl-7 py-4 border-b">
              <div className="flex items-center justify-between">
                <h4 className="small-title font-bold theme-color">
                  Base Pricing Table
                </h4>
              </div>
            </div>

            <div className="custom-card shadow-lg mb-10">
              <div className="">
              <SectionWithBorder
                  column1="Fees"
                  column2="Ordinary Member"
                  column3="Qualifying Member"
                  column4="Associate Member"
                >
                  <div className="sign-icon-input px-7">
                    <div className="pr-24">
                      
                      <Row3Input
                        className="text-base text-[#757575]"
                        caption="Entrance Fee"
                        column1="Label 1"
                        column2="Label 2"
                        column3="Label 3"
                        col1value={this.state.membershipPricing.ordinaryEntranceFee}
                        col2value={this.state.membershipPricing.qualifyingEntranceFee}
                        col3value={this.state.membershipPricing.associateEntranceFee}
                        col1FieldName="ordinaryEntranceFee"
                        col2FieldName="qualifyingEntranceFee"
                        col3FieldName="associateEntranceFee"
                        col1InputType="Money"
                        col2InputType="Money"
                        col3InputType="Money"
                        handleChange={this.handleChange.bind(this)}
                        validateField={this.validateField.bind(this)}
                        validState={this.state.validState}
                        error1={this.state.validState.error.ordinaryEntranceFee}
                        error2={this.state.validState.error.qualifyingEntranceFee}
                        error3={this.state.validState.error.associateEntranceFee}
                      />
                     
                     


                    </div>
                  </div>
                  <div className="sign-icon-input px-7">
                    <div className="pr-24">
                      <Row3Input
                        className="text-base text-[#757575]"
                        caption="Subscription Fee"
                        column1="Label 1"
                        column2="Label 2"
                        column3="Label 3"
                        col1value={this.state.membershipPricing.ordinarySubscriptionFee}
                        col2value={this.state.membershipPricing.qualifyingSubscriptionFee}
                        col3value={this.state.membershipPricing.associateSubscriptionFee}
                        col1FieldName="ordinarySubscriptionFee"
                        col2FieldName="qualifyingSubscriptionFee"
                        col3FieldName="associateSubscriptionFee"
                        col1InputType="Money"
                        col2InputType="Money"
                        col3InputType="Money"
                        handleChange={this.handleChange.bind(this)}
                        validateField={this.validateField.bind(this)}
                        validState={this.state.validState}
                        error1={this.state.validState.error.ordinarySubscriptionFee}
                        error2={this.state.validState.error.qualifyingSubscriptionFee}
                        error3={this.state.validState.error.associateSubscriptionFee}
                      />
                    </div>
                  </div>
                  <div className="xl:col-span-2 md:col-span-6 col-span-12 mt-3">
                    <div className="col-span-12 flex justify-end">
                      {this.state.isLoading ? (
                        <div className="relative w-[150px]">
                          <button className={"text-xl font-bold text-white btn-blue-border w-full bg-blue-500 p-2 mx-4 rounded-md hover:bg-blue-600 focus:outline-none"}>
                            <span className="absolute left-[15px] top-1/2 transform -translate-y-1/2">
                              <i className="fa fa-spinner fa-spin text-white text-[17px]"></i>
                            </span>
                            <span className="ml-8">
                              Loading
                            </span>
                          </button>
                        </div>
                      ) : (
                        <button
                          className={`text-xl font-bold text-white btn-blue-border w-[150px] bg-blue-500 p-2 mx-4 rounded-md hover:bg-blue-600 focus:outline-none`}
                          onClick={() => this.updateMembershipPricingData()}
                        >
                          Update
                        </button>
                      )}
                    </div>
                  </div>

                </SectionWithBorder>
              </div>

              {/* Pricing Table */}
              {this.state.membershipPricing.isTableShow != true ? null :
              <>
              <div className="sign-icon-input w-full">
                <div className="">
                  <table className="w-full border border-gray-200">
                    <thead>
                      <tr className="bg-gray-100 border-b border-gray-200">
                        <th className="text-sm text-gray-600 px-4 py-2 border-r border-gray-200">Membership Month</th>
                        <th className="text-sm text-gray-600 px-2 py-2 border-r border-gray-200" colSpan="2">Ordinary member</th>
                        <th className="text-sm text-gray-600 px-2 py-2 border-r border-gray-200" colSpan="2">Qualifying member</th>
                        <th className="text-sm text-gray-600 px-2 py-2 border-r border-gray-200" colSpan="2">Associate member</th>
                      </tr>
                      <tr className="bg-gray-50 border-b border-gray-200">
                        <th className="text-sm text-gray-600 px-4 py-2 border-r border-gray-200"></th>
                        <th className="text-sm text-gray-600 px-2 py-2 border-r border-gray-200">Entrance Fee</th>
                        <th className="text-sm text-gray-600 px-2 py-2 border-r border-gray-200">Subscription Fee</th>
                        <th className="text-sm text-gray-600 px-2 py-2 border-r border-gray-200">Entrance Fee</th>
                        <th className="text-sm text-gray-600 px-2 py-2 border-r border-gray-200">Subscription Fee</th>
                        <th className="text-sm text-gray-600 px-2 py-2 border-r border-gray-200">Entrance Fee</th>
                        <th className="text-sm text-gray-600 px-2 py-2 border-r border-gray-200">Subscription Fee</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <tr className="border-b border-gray-200">
                        <td className="text-sm text-gray-600 px-4 py-2 text-center border-r border-gray-200">January</td>
                        <td className="text-sm text-gray-600 px-2 py-2 border-r border-gray-200">Entrance Fee (Ordinary)</td>
                        <td className="text-sm text-gray-600 px-2 py-2 border-r border-gray-200">Subscription Fee (Ordinary)</td>
                        <td className="text-sm text-gray-600 px-2 py-2 border-r border-gray-200">Entrance Fee (Qualifying)</td>
                        <td className="text-sm text-gray-600 px-2 py-2 border-r border-gray-200">Subscription Fee (Qualifying)</td>
                        <td className="text-sm text-gray-600 px-2 py-2 border-r border-gray-200">Entrance Fee (Associate)</td>
                        <td className="text-sm text-gray-600 px-2 py-2 border-r border-gray-200">Subscription Fee (Associate)</td>
                      </tr> */}
                      {this.renderRows()}
                    </tbody>
                  </table>
                </div>
              </div>
              </>
  }
            </div>
          </div>

          <div className="flex items-center justify-between">
            <NavLink to="/Dashboard" className="btn btn-gray text-xl text-white">
              {" "}
              Back
            </NavLink>
            {/* <button
              className="text-lg font-bold btn btn-red text-white"
              onClick={() => this.addMembershipPricing()}
            >
              Save
            </button> */}
          </div>
        </div>
      </>
    );
}
}
