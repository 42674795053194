import React, { Component } from "react";
import Table from "../../components/table/Table";
import DateRangePickerSelect from "../../components/TimePicker.js/DateRangePickerSelect";
import ActionContainer from "../../components/UIComponent/ActionTableComponent/actionContainer";
import DropdownAction from "../../components/dropdown/DropdownAction";
import { Link, Navigate } from "react-router-dom";
import GenerateCreditNoteModal from "../../components/financial/GenerateCreditNoteModal";
import FinanceServices from "../../services/axiosServices/apiServices/FinanceServices";
import SwalServices from "../../services/swalServices/SwalServices";
import DropdownSelect from "../../components/dropdown/Dropdown";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { convertToDecimal } from "../../utils/Utils";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import {
  CommonValidationMessages,
  ErrorMessage,
  SuccessMessage,
} from "../../utils/Messages";
import { encryptAES } from "../../utils/Encryption";

const moment = extendMoment(originalMoment);

export default class ExternalInvoices extends Component {
  constructor(props) {
    super(props);
    this.financeService = new FinanceServices();
    this.swalServices = new SwalServices();
    const today = moment();
    this.state = {
      statusList: [
        {
          id: 1,
          name: "Pending",
        },
        {
          id: 2,
          name: "Paid",
        },
      ],
      adhocInvoiceList: [],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        filterbyInvoiceNumber: "",
        // filterbyInvoiceDate: '',
        filterByInvoiceStartDate: "",
        filterByInvoiceEndDate: "",
        filterByTypeOfInvoice: 0,
        filterByCompanyName: "",
        filterByStatus: "",
      },
      value: moment.range(today.clone().subtract(7, "days"), today.clone()),
      statusId: 0,
      actionDropdownPending: ["Edit", "Cancel Invoice"],
      actionDropdownPaid: ["Edit", "Generate Credit Note"],
      route: "",
      totalResultes: 0,
      setCreditNoteModel: false,
      adhokInvoiceTypes: [],
      redirect: null,
      adhocInvoiceDetail: {},
      cancellationReason: [],
      gstRate: 0,
      isOtherCancellationReason: false,
      isLoading: false,
      adhocInvoiceDetailValidationRules: {
        cancellationReasonId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "cancellation reason"
            ),
          },
        ],
        cancellationReason: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "cancellation reason"
            ),
          },
        ],
      },
      validCreditNote: {
        isValid: true,
        error: {},
      },
      adhocInvoiceItemValidationRules: {
        refundAmount: [
          {
            type: "maxNumber",
            compareEle: "adhokAmount",
            message: CommonValidationMessages.MaxAmount.replace(
              "{0}",
              "amount"
            ),
          },
        ],
      },
      validInvoiceItem: {
        isValid: true,
        error: {},
      },

      isOpen: false,
    };
  }

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getFinanceAdhocInvoiceList();
  };

  getFinanceAdhocInvoiceList = () => {
    this.setState({ isLoading: true });
    this.financeService
      .getFinanceAdhocInvoiceList(this.state.pagination)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let data = response.responseItem.responseContent.itemList;
          data.map((x) => {
            x.invoiceDate = moment(x.invoiceDate).format("DD MMM YYYY");
            x.invoiceAmount = x.invoiceAmount.toFixed(2);
            return x;
          });
          this.setState({ adhocInvoiceList: data });
          this.setState({
            totalResultes: response.responseItem.responseContent.totalCount,
          });
          this.getCancellationReasonForCreditNote();
        } else {
          this.getCancellationReasonForCreditNote();
          this.swalServices.Error("Something went wrong.");
        }
        this.setState({ isLoading: false });
      });
  };

  getAllDropdownsForFinanceAdhokInvoices = () => {
    this.financeService
      .getAllDropdownsForFinanceAdhokInvoices()
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let adhokInvoiceTypesArry =
            response.responseItem.responseContent.filter(
              (drp) => drp.dropdownName === "AdhokInvoiceTypes"
            );

          // var gstPercentage = response.responseItem.responseContent.filter(
          //   (drp) => drp.dropdownName === "GSTPercentage"
          // );

          adhokInvoiceTypesArry = adhokInvoiceTypesArry.map((p) => ({
            id: p.id,
            name: p.name,
          }));
          //let percentage = Number(gstPercentage[0].name);
          this.setState(
            {
              adhokInvoiceTypes: adhokInvoiceTypesArry, //gstRate: percentage
            },
            () => {
              this.getFinanceAdhocInvoiceList();
            }
          );
        } else {
          this.getFinanceAdhocInvoiceList();
        }
      });
  };

  searchInvoice = () => {
    let pageData = this.state.pagination;
    if (this.state.statusId === 1) {
      pageData.filterByStatus = "Pending";
    } else if (this.state.statusId === 2) {
      pageData.filterByStatus = "Success";
    } else {
      pageData.filterByStatus = "";
    }
    pageData.filterbyInvoiceNumber = pageData.filterbyInvoiceNumber
      ? pageData.filterbyInvoiceNumber
      : "";
    pageData.pageNo = 1;
    this.setState({ pagination: pageData }, () => {
      this.getFinanceAdhocInvoiceList();
    });
  };

  getFinanceAdhocInvoiceDetail = (id) => {
    this.financeService.getFinanceAdhocInvoiceDetail(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let data = response.responseItem.responseContent;
        data.creditNoteMonth = "";
        data.cancellationReason = "";
        data.cancellationReasonId = 0;
        if (data.paymentStatus === "Pending") {
          data.refundSubTotalAmount = 0;
          data.refundGSTAmount = 0;
          data.refundTotalAmount = 0;
          data.adhokInvoiceItems.map((item) => {
            item.refundAmount = 0;
            return item;
          });
        } else {
          data.refundSubTotalAmount = data.adhokSubtotalAmount;
          data.refundGSTAmount = data.adhokGSTAmount;
          data.refundTotalAmount = data.adhokTotalAmount;
          data.adhokInvoiceItems.map((item) => {
            item.refundAmount = item.adhokAmount;
            return item;
          });
        }
        this.setState({
          adhocInvoiceDetail: data,
          setCreditNoteModel: true,
          gstRate: data.gstRate,
        });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  getCancellationReasonForCreditNote = () => {
    this.financeService
      .getCancellationReasonForCreditNote()
      .then((response) => {
        if (
          response.statusCode === 200 &&
          response.responseItem.responseContent != null &&
          response.responseItem.responseContent.length > 0
        ) {
          let cancelReason = response.responseItem.responseContent;
          this.setState({ cancellationReason: cancelReason });
        } else {
          this.swalServices.Error(response.message);
        }
      });
  };

  componentDidMount() {
    this.getAllDropdownsForFinanceAdhokInvoices();
  }

  addEditData = () => {
    if (this.isValidFinanceCreditNote()) {
      if (this.isValidInvoiceItem()) {
        let request = {
          financeAdhocInvoiceId:
            this.state.adhocInvoiceDetail.financeAdhocInvoiceId,
          bookingId: this.state.adhocInvoiceDetail.financeAdhocInvoiceId,
          isAdhocInvoice: true,
          creditNoteMonth: this.state.adhocInvoiceDetail.creditNoteMonth
            ? this.state.adhocInvoiceDetail.creditNoteMonth
            : moment().format("yyyy-MM-DD"),
          creditNoteTotalRefund:
            this.state.adhocInvoiceDetail.refundTotalAmount,
          creditNoteStatusId: 1,
          creditNoteItem: [],
          creditNoteCancellationReason:
            this.state.adhocInvoiceDetail.cancellationReason,
          CreatedAppId: 114,
          CreatedAt: moment(),
          CreatedBy: 1,
          creditSubTotalAmount:
            this.state.adhocInvoiceDetail.refundSubTotalAmount,
          creditNoteGSTAmount: this.state.adhocInvoiceDetail.refundGSTAmount,
          membershipPaymentTypeId: 5,
        };
        this.state.adhocInvoiceDetail.adhokInvoiceItems.forEach((value) => {
          let tempCreditNoteItem = {
            creditNoteItem: "",
            creditNoteItemAmount: 0.0,
          };
          tempCreditNoteItem.creditNoteItem = value.adhokInvoiceDescription;
          tempCreditNoteItem.creditNoteItemAmount = value.refundAmount;
          request.creditNoteItem.push(tempCreditNoteItem);
        });

        this.financeService.addFinanceCreditNote(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent.errorMessage === "") {
              this.swalServices.Success(
                SuccessMessage.Insert_Success.replace("{0}", "Credit Note")
              );
            } else {
              this.swalServices.Error(
                ErrorMessage.Already_Exist.replace("{0}", "Credit note")
              );
            }
          } else {
            this.swalServices.Error(ErrorMessage.DefaultMessage);
          }
          this.setState({ isLoading: false });
        });
      }
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    let detail = this.state.pagination;
    detail[name] = value;
    this.setState({ pagination: detail });
  };

  isValidFinanceCreditNote = () => {
    const newValidState = isValidForm(
      this.state.adhocInvoiceDetail,
      this.state.adhocInvoiceDetailValidationRules,
      this.state.validCreditNote
    );
    this.setState({ validCreditNote: newValidState });
    return newValidState.isValid;
  };

  validateFinanceCreditNote = (identity, providedState = this.state.adhocInvoiceDetail) => {
    // providedState = providedState
    //   ? providedState
    //   : this.state.adhocInvoiceDetail;
    const newValidState = validate(
      identity,
      providedState,
      this.state.adhocInvoiceDetailValidationRules,
      this.state.validCreditNote
    );
    this.setState({ validCreditNote: newValidState });
  };

  isValidInvoiceItem = () => {
    const newValidState = isValidForm(
      this.state.adhocInvoiceDetail,
      this.state.adhocInvoiceDetailValidationRules,
      this.state.validCreditNote
    );
    this.setState({ validInvoiceItem: newValidState });
    return newValidState.isValid;
  };

  validateInvoiceItem = (key, providedState, keyId) => {
    providedState = providedState[keyId];
    let keys = key.split("_");
    let newValidState = validate(
      keys[0],
      providedState,
      this.state.adhocInvoiceItemValidationRules,
      this.state.validInvoiceItem
    );
    let changeValue = {};
    changeValue.error = {} = newValidState.error;
    changeValue.error[key] = newValidState.error.refundAmount;
    changeValue.isValid = newValidState.isValid;
    delete changeValue.error["refundAmount"];
    this.setState({ validInvoiceItem: changeValue });
  };

  handleChangeForCreditNote = (event, identity) => {
    //let details = {...this.state.adhocInvoiceDetail};
    let { name, value } = event.target;
    let detail = { ...this.state.adhocInvoiceDetail };
    //detail.adhokInvoiceItems[name] = value;
    if (identity === "CreditNote") {
      detail[name] = value;
      this.setState({ adhocInvoiceDetail: { ...detail } });
    } else if (identity === "CreditNoteItem") {
      let array = name.split("_");
      if ((value = convertToDecimal(value))) {
        value = value;
      }
      detail.adhokInvoiceItems[Number(array[1])].refundAmount = value;
      this.validateInvoiceItem(
        `refundAmount_${Number(array[1])}`,
        detail.adhokInvoiceItems,
        Number(array[1])
      );
      let totalAmount = 0;
      detail.adhokInvoiceItems.map((item) => {
        totalAmount = totalAmount + item.refundAmount;
        return item;
      });
      detail.refundSubTotalAmount = parseFloat(
        parseFloat(totalAmount).toFixed(2)
      );
      detail.refundGSTAmount = parseFloat(
        (
          parseFloat(detail.refundSubTotalAmount) *
          (this.state.gstRate / 100)
        ).toFixed(2)
      );
      detail.refundTotalAmount = parseFloat(
        (
          parseFloat(detail.refundSubTotalAmount) +
          parseFloat(detail.refundGSTAmount)
        ).toFixed(2)
      );
    }
    this.setState({ adhocInvoiceDetail: detail });
  };

  setFilterParameters = (id, drpIdentity) => {
    let cancel_reason = this.state.cancellationReason;
    let data = { ...this.state.adhocInvoiceDetail };

    //let details = { ...this.state.corporateCreditNotePaymentInfo }
    let findData = cancel_reason.filter((x) => x.id === id);
    if (findData.length > 0) {
      data.cancellationReasonId = findData[0].id;
      if (findData[0].name === "Others") {
        data.cancellationReason = "";
        this.setState({ isOtherCancellationReason: true });
        //this.validateFinanceCreditNote("cancellationReasonId", data);
      } else {
        data.cancellationReason = findData[0].name;
        this.setState({ isOtherCancellationReason: false });
      }
    } else {
      data.cancellationReason = "";
      data.cancellationReasonId = 0;
      this.setState({ isOtherCancellationReason: false });
    }
    this.validateFinanceCreditNote("cancellationReasonId", data);
    this.setState({ adhocInvoiceDetail: data });
  };

  handleChangeInDropdown = (id, drpIdentity) => {
    let detail = this.state.pagination;
    if (drpIdentity === "AdhokInvoiceTypes") {
      detail.filterByTypeOfInvoice = id;
      this.setState({ pagination: detail });
    }
    if (drpIdentity === "Status") {
      this.setState({ statusId: id });
    }
  };

  actionClick = (index, value, option, event) => {
    if (option === "Generate Credit Note") {
      event.stopPropagation();
      //this.setState({ setCreditNoteModel: true }, () => { this.getFinanceAdhocInvoiceDetail(value) });
      this.getFinanceAdhocInvoiceDetail(value);
    }
    if (option === "Edit") {
      //event.stopPropagation();
      this.onEdit(value);
    }
    if (option === "Cancel Invoice") {
      event.stopPropagation();
      this.getFinanceAdhocInvoiceDetail(value);
    }
  };

  onEdit = (value) => {
    this.setState({
      redirect:
        "/financial/AddEditExternalInvoice/financeExternalInvoiceId?=" + encryptAES(value),
    });
  };

  changeIsOpenFalse = () => {
    // this.setState({ isOpen: false });
  };

  onToggle = () => {
    this.setState({ isOpen: true });
  };

  onSelect = (value, states) => {
    this.setState({ value, states });
    let startDate;
    let endDate;
    startDate = value.start.format("YYYY-MM-DD HH:MM:SS");
    endDate = value.end.format("YYYY-MM-DD HH:MM:SS");
    let pageObj = { ...this.state.pagination };
    pageObj.filterByInvoiceStartDate = startDate;
    pageObj.filterByInvoiceEndDate = endDate;
    this.setState({ pagination: pageObj }, () => {
      // this.getFinanceAdhocInvoiceList(this.state.pagination);
    });
    this.setState({ isOpen: !this.state.isOpen });
  };

  actions = (element, index, value) => {
    return element !== "financeAdhocInvoiceId" ? null : (
      <td>
        <div className="relative w-full credit-note-action-dropdown">
          <ActionContainer>
            <div className="mr-8">
              {this.state.adhocInvoiceList.find(
                (x) => x.financeAdhocInvoiceId === value
              ).status === "Paid" ? (
                <DropdownAction
                  key={index}
                  value={value}
                  id={index}
                  index={index}
                  options={this.state.actionDropdownPaid}
                  align="right"
                  onOptionClick={this.actionClick.bind(this)}
                />
              ) : (
                <DropdownAction
                  key={index}
                  value={value}
                  id={index}
                  index={index}
                  options={this.state.actionDropdownPending}
                  align="right"
                  onOptionClick={this.actionClick.bind(this)}
                />
              )}
            </div>
          </ActionContainer>
        </div>
      </td>
    );
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="main-body-section finance-body-sec">
        <div className="bg-white pb-2 mt-10">
          <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pt-10 pb-12">
            <div className=" 2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
              <h2 className="text-xl theme-color mb-1">
                Invoice Number
                <span className="text-[#C00000]">*</span>
              </h2>
              <input
                id="default"
                name="filterbyInvoiceNumber"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                onChange={(event) => this.handleChange(event)}
                value={this.state.pagination.filterbyInvoiceNumber}
              //   onChange={(e) => this.handleChange(e)}
              />
            </div>

            <div className=" 2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
              <h2 className="text-xl theme-color mb-1">
                Type of Invoice
                <span className="text-[#C00000]">*</span>
              </h2>
              {/* <Selector
                options={this.state.adhokInvoiceTypes}
                // isMulti
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                components={{
                  Option,
                }}
                // onChange={this.handleChangeInDropdown.bind(this)}
                allowSelectAll={false}
                // value={this.state.pagination.FilterByAgency}
                // value={this.state.agencyList.find(item => item.value === this.state.pagination.FilterByAgency.id)}
                drpIdentity="AgencyFilter"
              /> */}
              <DropdownSelect
                drpIdentity="AdhokInvoiceTypes"
                optionArray={this.state.adhokInvoiceTypes}
                setFilterParameters={this.handleChangeInDropdown.bind(this)}
                value={this.state.pagination.filterByTypeOfInvoice}
              />
            </div>
            {/* <div className=" 2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
              <h2 className="text-xl theme-color mb-1">Invoice Start Date</h2>
              <input
                id="default"
                name="filterbyInvoiceDate"
                className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                type="date"
                value={this.state.pagination.filterbyInvoiceDate}
                onChange={(event) => this.handleChange(event)}
              />
            </div> */}

            <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full small-tab-sec">
              <h2 className="text-xl theme-color mb-1">Invoice Date Range</h2>
              {/* Added by Akshay (Pankaj need to change UI ) */}
              <div className="flex items-center w-full date-multi-selector">
                <div className="date-selecter w-[45%]">
                  <input
                    id="default"
                    name="filterByInvoiceStartDate"
                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                    type="date"
                    value={moment(
                      this.state.pagination.filterByInvoiceStartDate
                    ).format("yyyy-MM-DD")}
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>
                <div className="text-center w-[10%]">
                  <h2 className="text-xl theme-color">To</h2>
                </div>
                <div className="date-selecter w-[45%]">
                  <input
                    id="default"
                    name="filterByInvoiceEndDate"
                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                    type="date"
                    value={moment(
                      this.state.pagination.filterByInvoiceEndDate
                    ).format("yyyy-MM-DD")}
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>
              </div>
              <div className="right-date-range hidden">
                {/* 1/1/2022 - 31/12/2022 */}
                {/* <div className="h-[45px] inline-block px-5 w-full">
                  <DateRangePickerSelect
                    pagination={this.state.pagination}
                    value={this.state.value}
                    // onSelect={this.onSelect.bind(this)}
                    isOpen={this.state.isOpen}
                    // onToggle={this.onToggle.bind(this)}
                    // changeIsOpenFalse={this.changeIsOpenFalse.bind(this)}
                  />
                </div> */}

                <button className="w-full h-[58px] border-solid border-[#757575] btn  py-3 text-lg text-[#757575] flex items-left date-rang-sec relative">
                  {/* <img className="mr-5 md:w-7 w-5" src="/calender.svg"></img> */}
                  {/* Date */}
                  <div className="right-date-range">
                    <div className="h-[45px] inline-block pt-2 w-full">
                      <DateRangePickerSelect
                        pagination={this.state.pagination}
                        value={this.state.value}
                        onSelect={this.onSelect.bind(this)}
                        isOpen={this.state.isOpen}
                        onToggle={this.onToggle.bind(this)}
                        changeIsOpenFalse={this.changeIsOpenFalse.bind(this)}
                      />
                    </div>
                  </div>
                </button>
              </div>
              {/* <input
                id="default"
                name="filterbyEndInvoiceDate"
                className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                type="date"
                value={this.state.pagination.filterbyEndInvoiceDate}
                onChange={(event) => this.handleChange(event)}
              /> */}
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full items-center px-8  pb-12">
            <div className=" 2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
              <h2 className="text-xl theme-color mb-1">
                Company Name
                <span className="text-[#C00000]">*</span>
              </h2>
              <input
                id="default"
                name="filterByCompanyName"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                value={this.state.pagination.filterByCompanyName}
                onChange={(event) => this.handleChange(event)}
              />
            </div>
            <div className=" 2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
              <h2 className="text-xl theme-color mb-1">Status</h2>
              <DropdownSelect
                drpIdentity="Status"
                optionArray={this.state.statusList}
                setFilterParameters={this.handleChangeInDropdown.bind(this)}
                value={this.state.statusId}
              />
            </div>
            <div className="2xl:col-span-6 pt-10 lg:col-span-6 col-span-12 w-full">
              <div className=" flex  items-center justify-end w-full">
                <button
                  className="btn  btn-red text-xl border text-white "
                  onClick={() => this.searchInvoice()}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-6">
          <div className="2xl:col-span-12 pt-10 lg:col-span-12 col-span-12 w-full">
            <div className=" flex  items-center justify-end w-full">
              <Link to="/financial/ExternalInvoices">
                <button
                  className="btn  btn-red text-xl border text-white "
                  onClick={() =>
                    this.setState({
                      redirect: "/financial/AddEditExternalInvoice",
                    })
                  }
                >
                  Add New
                </button>
              </Link>
            </div>
          </div>
        </div>
        {/* Table Section Start */}
        <div className="mt-10 external-invoice-tbl">
          <Table
            columns={[
              { name: "invoiceSequenceNumber", title: "Invoice Number" },
              { name: "invoiceDate", title: "Invoice Date" },
              { name: "companyName", title: "Company Name" },
              { name: "adhokInvoiceTypeName", title: "Invoice Type" },
              { name: "invoiceAmount", title: "Invoice Amount" },
              { name: "accountCode", title: "Account Code" },
              { name: "drawdown", title: "Drawdown" },
              { name: "status", title: "Status" },
              { name: "financeAdhocInvoiceId", title: "Actions" },
            ]}
            rows={this.state.adhocInvoiceList}
            isLoading={this.state.isLoading}
            sortingColumns={["", ""]}
            pagination={this.state.pagination}
            totalResultes={this.state.totalResultes}
            totalCount={this.state.totalResultes}
            setPagination={this.setPagination.bind(this)}
            customScope={[
              {
                column: "financeAdhocInvoiceId",
                renderTableData: this.actions.bind(this),
              },
              // {
              //     column: "selectRow",
              //     renderTableData: this.select.bind(this),
              //     valueColumnName: "financeAdhocInvoiceId",
              // },
            ]}
          />
        </div>
        {/*Table Section End */}
        <div className="generate-credit-note-popup">
          <GenerateCreditNoteModal
            setModalOpen={() => this.setState({ setCreditNoteModel: false })}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ setCreditNoteModel: false });
            }}
            closeModalOnBack={() => {
              this.setState({ setCreditNoteModel: false });
            }}
            setCreditNoteModel={this.state.setCreditNoteModel}
            adhocInvoiceDetail={this.state.adhocInvoiceDetail}
            addEdit={this.addEditData.bind(this)}
            cancellationReason={this.state.cancellationReason}
            setFilterParameters={this.setFilterParameters.bind(this)}
            //handleChangeForCreditNote={this.handleChangeForCreditNote.bind(this)}
            handleChangeForCreditNote={this.handleChangeForCreditNote.bind(
              this
            )}
            isOtherCancellationReason={this.state.isOtherCancellationReason}
            validate={this.validateFinanceCreditNote.bind(this)}
            validCreditNote={this.state.validCreditNote}
            validateInvoiceItem={this.validateInvoiceItem.bind(this)}
            validInvoiceItem={this.state.validInvoiceItem}
          />
        </div>
      </div>
    );
  }
}
