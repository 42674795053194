import React from "react";
import MoneyInput from "../../UIComponent/MoneyInput";
import NumberInput from "../../UIComponent/NumberInput";

const Row3Input = (props) => {
  return (
    <>
      <div className="grid 2xl:grid-cols-4 lg:grid-cols-3 gap-6 h-auto  w-full items-center my-8 lg:px-3">
        <h2 className={props.className}>{props.caption}</h2>

        {props.col1InputType === "Money" ? (
          <div className="sign-icon-input">
            <MoneyInput
              placeholder="0"
              name={props.col1FieldName}
              value={props.col1value}
              handleChange={props.handleChange}
              validateField={props.validateField}
              validState={props.validState}
              error={props.error1}
            />
          </div>
        ) : null}


        {props.col1InputType === "Number" ? (
          <NumberInput
            placeholder="0"
            name={props.col1FieldName}
            value={props.col1value}
            handleChange={props.handleChange}
            validateField={props.validateField}
            validState={props.validState}
            error={props.error1}
          />
        ) : null}



        {props.col2InputType === "Money" ? (
          <div className="sign-icon-input">
            <MoneyInput
              placeholder="0"
              name={props.col2FieldName}
              value={props.col2value}
              handleChange={props.handleChange}
              validateField={props.validateField}
              validState={props.validState}
              error={props.error2}
            />
          </div>
        ) : null}


        {props.col2InputType === "Number" ? (
          <NumberInput
            placeholder="0"
            name={props.col2FieldName}
            value={props.col2value}
            handleChange={props.handleChange}
            validateField={props.validateField}
            validState={props.validState}
            error={props.error2}
          />
        ) : null}


        {props.col3InputType === "Money" ? (
          <div className="sign-icon-input">
            <MoneyInput
              placeholder="0"
              name={props.col3FieldName}
              value={props.col3value}
              handleChange={props.handleChange}
              validateField={props.validateField}
              validState={props.validState}
              error={props.error3}
            />
          </div>
        ) : null}


        {props.col3InputType === "Number" ? (
          <NumberInput
            placeholder="0"
            name={props.col3FieldName}
            value={props.col3value}
            handleChange={props.handleChange}
            validateField={props.validateField}
            validState={props.validState}
            error={props.error3}
          />
        ) : null}
      </div>
    </>
  );
};
export default Row3Input;