import React, { Component } from 'react';
import TextInput from '../../components/UIComponent/TextInput';
import MyEditor from '../../components/ckEditor/Editor';
import Selector from '../../components/dropdown/commonDropdown/Selector';
import ValidationText from "../../utils/validation/ValidationText";
import { CommonValidationMessages } from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import Option from '../../components/dropdown/multiSelectDropdownOption/MultiSelectOption';
import { Navigate } from 'react-router-dom';
import withParams from '../../HOC/withParams';

class CreateEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailManagementId: 0,
      redirect: null,
      recipientList: [
        {
          value: 1,
          label: "Recipient-1"
        },
        {
          value: 2,
          label: "Recipient-2"
        },
        {
          value: 3,
          label: "Recipient-3"
        },
        {
          value: 4,
          label: "Recipient-4"
        },
        {
          value: 5,
          label: "Recipient-5"
        },
      ],
      emailDetails: {
        subject: "",
        additionalEmail: "",
        includedEmail: [],
        excludeEmail: "",
        emailDescription: "",
        selectedEmails: [],
      },
      validationRule: {
        subject: [
          {
            type: 'require',
            message: CommonValidationMessages.FieldRequired.replace('{0}', 'subject')
          }
        ],
        recipientName: [
          {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'recipient name')
          }
        ],
        emailDescription: [
          {
            type: 'require',
            message: CommonValidationMessages.FieldRequired.replace('{0}', 'email details')
          }
        ],
      },
      validState: {
        isValid: true,
        error: {}
      },
    }
  }

  componentDidMount() {
    let params = this.props.params;
    let id = isNaN(params.emailManagementId) ? 0 : Number(params.emailManagementId);
    if (id && id > 0) {
      this.setState({ emailManagementId: id });
    }
  }

  // Email management methods start
  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.emailDetails;
    detail[name] = value;
    //if(additional){
    // comma separated to Array
    // array.forEach(element => {
    //   if(validate email){
    //     incuded email = comma separated > Array
    //     merge first and last Array
    //     then conver to comma separated 

    //   }
    // });
    // }

    this.setState({ emailDetails: { ...detail } });
  };

  mergerAdditionalEmailToIncludedEmails = () => {
    // let detail = this.state.emailDetails;
    // let selectedEmails = [...this.state.emailDetails.selectedEmails]
    // var array = detail.additionalEmail.split(',');
    const options = [];
    let validEmail = [];
    options.forEach((value) => {
      if (this.validateField("additionalEmail")) {
        validEmail.push(value);
      }
    });
    // if (options.length > 0) {
    //   var arr1 = selectedEmails.map(function (item) {
    //     delete item.bad;
    //     return item.label;
    //   });
    //   const allEmails = arr1.concat(options);
    //   const includedEmailsFinal = allEmails.join([',']);
    // }
  }

  setEmailDescription = (data) => {
    let detail = this.state.emailDetails;
    const re = /<a href="([^"]+)"/g;
    let result = data.replace(re, "<a href='$1' target=\"_blank\" rel=\"noopener noreferrer\"");
    detail["emailDescription"] = result;
    this.setState({ emailDetails: { ...detail } });
  };

  // Handle Change for multi select dropdownSumm=
  handleChangeMultiDropdownInterest(selected, identity) {
    let profile = this.state.emailDetails;
    if (identity === "RecipientsList") {
      profile.selectedEmails = selected
    }
    if (profile.selectedEmails.length > 0) {
      let arr1 = profile.selectedEmails.map(function (item) {
        //delete item.label;
        return item.label;
      });
      profile.includedEmail = arr1.join([',']) + ',' + this.state.emailDetails.additionalEmail;
    }
    this.setState({ emailDetails: profile });
  }

  //validation methods 
  validateField = (key) => {
    const newValidState = validate(key, this.state.emailDetails, this.state.validationRule, this.state.validState);
    this.setState({ validState: newValidState });
  }

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="main-body-section">
        <div className="bg-white">
          <div className=" heading-part px-7 py-4 border-b">
            <h4 className="small-title font-bold theme-color">

            </h4>
            <button
              className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white"
            // onClick={() => {}}
            >
              Send
            </button>
          </div>
          <div className="px-6 pt-10">
            <div className="grid 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 sm:grid-cols-12 gap-6 w-full pb-8">
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl theme-color mb-1">
                  Subject<span className="text-[#C00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="subject"
                  value={this.state.emailDetails.subject}
                  handleChange={(event) => this.handleChange(event)}
                  onBlur={() => this.validateField("subject")}
                />
                <div>
                  <ValidationText error={this.state.validState.error.subject} />
                </div>
              </div>

              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl theme-color mb-1">
                  Recipients<span className="text-[#C00000]">*</span>
                </h2>
                <Selector
                  options={this.state.recipientList}
                  isMulti
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  // components={{
                  //   Option,
                  // }}
                  onChange={this.handleChangeMultiDropdownInterest.bind(this)}
                  allowSelectAll={true}
                  value={this.state.emailDetails.selectedEmails}
                  // value={this.state.categoryList.find(item => item.value === this.state.pagination.filterByCategory)}
                  drpIdentity="RecipientsList"
                />
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl theme-color mb-1">
                  Additional Email Address
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="additionalEmail"
                  value={this.state.emailDetails.additionalEmail}
                  handleChange={(event) => this.handleChange(event)}
                  onBlur={() => this.mergerAdditionalEmailToIncludedEmails()}
                />
                <p>
                  (Note:Add multiple emails, separated by semicolons. eg.
                  example@gmail.com;example@yahoo.com)
                </p>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl theme-color mb-1">
                  Summary of Included Email
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="additionalEmail"
                  value={this.state.emailDetails.includedEmail}
                  disabled={true}
                />
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl theme-color mb-1">
                  Exclude Email Address
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="excludeEmail"
                  value={this.state.emailDetails.selectedEmails.label}
                  handleChange={(event) => this.handleChange(event)}
                />
                <p>
                  (Note:Add multiple emails, separated by semicolons. eg.
                  example@gmail.com;example@yahoo.com)
                </p>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl theme-color mb-1">
                  Placeholders:
                  <strong>#FirstName#</strong> <strong>#CompanyName#</strong>{" "}
                  <strong>#ContactName#</strong>
                </h2>
              </div>
              <div className="xl:col-span-12 lg:col-span-12 sm:col-span-12">
                <h2 className="text-base theme-color font-bold mb-1">
                  Message<span className="text-[#C00000]">*</span>:
                </h2>
                <div className="ck-editor-sec">
                  <MyEditor
                    key={"fullPageCKEditor"}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.setEmailDescription(data);
                    }}
                    onBlur={() => this.validateField("emailDescription")}
                    data={this.state.emailDetails.emailDescription}
                  />
                  <div>
                    <ValidationText
                      error={this.state.validState.error.emailDescription}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap justify-between space-x-10 mt-12 pb-12">
              <button
                onClick={() => this.setState({ redirect: "/EmailHistory" })}
                className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white hover:text-white"
              >
                Back
              </button>
              <div>
                <button
                  className="w-40 px-5 mr-16 py-2 btn-sm btn-lighblue text-lg text-white"
                // onClick={() => this.addEditSGTechTv()}
                >
                  Save as Draft
                </button>
                <button
                  className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white"
                // onClick={() => {}}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="bg-white">
          <div className=" heading-part px-7 py-4 border-b">
            <h4 className="small-title font-bold theme-color">
              Email Template
            </h4>
          </div>
          <div className="px-6 pt-10">
            <div className="grid 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 sm:grid-cols-12 gap-6 w-full pb-8">
              <div className="xl:col-span-8 lg:col-span-8 sm:col-span-12">
                <h2 className="text-base theme-color font-bold mb-1">
                  Subject<span className="text-[#C00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="subject"
                  value={this.state.emailDetails.subject}
                  handleChange={(event) => this.handleChange(event)}
                  onBlur={() => this.validateField("subject")}
                />
                <div><ValidationText error={this.state.validState.error.subject} /></div>
              </div>

              <div className="xl:col-span-8 lg:col-span-8 sm:col-span-12">
                <h2 className="text-base theme-color font-bold mb-1">
                  Recipients<span className="text-[#C00000]">*</span>
                </h2>
                <Selector
                  options={this.state.recipientList}
                  isMulti
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  components={{
                    Option,
                  }}
                  onChange={this.handleChangeInDropdown}
                  allowSelectAll={true}
                  // value={this.state.emailDetails.sgTechTVCategoryId}
                  // value={this.state.categoryList.find(item => item.value === this.state.pagination.filterByCategory)}
                  drpIdentity="RecipientsList"
                />
              </div>
              <div className="xl:col-span-8 lg:col-span-8 sm:col-span-12">
                <h2 className="text-base theme-color font-bold mb-1">
                  Additional Email Address
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="additionalEmail"
                  value={this.state.emailDetails.additionalEmail}
                  handleChange={(event) => this.handleChange(event)}
                />
               
                <p>(Note:Add multiple emails, separated by semicolons. eg. example@gmail.com;example@yahoo.com)</p>
              </div>
              <div className="xl:col-span-8 lg:col-span-8 sm:col-span-12">
                <h2 className="text-base theme-color font-bold mb-1">
                  Summary of Included Email
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="additionalEmail"
                  value={this.state.emailDetails.recipientList}
                  disabled={true}
                />
              </div>
              <div className="xl:col-span-8 lg:col-span-8 sm:col-span-12">
                <h2 className="text-base theme-color font-bold mb-1">
                  Exclude Email Address
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="excludeEmail"
                  value={(this.state.emailDetails.selectedEmails.label)}
                  handleChange={(event) => this.handleChange(event)}
                />
                <p>(Note:Add multiple emails, separated by semicolons. eg. example@gmail.com;example@yahoo.com)</p>
              </div>
              <div className="xl:col-span-8 lg:col-span-8 sm:col-span-12">
                <h2>
                  Placeholders:
                  <strong>#FirstName#</strong>{" "}
                  <strong>#CompanyName#</strong>{" "}
                  <strong>#ContactName#</strong>
                </h2>
              </div>
              <div className="xl:col-span-8 lg:col-span-8 sm:col-span-12">
                <h2 className="text-base theme-color font-bold mb-1">
                  Message<span className="text-[#C00000]">*</span>:
                </h2>
                <div className="ck-editor-sec">
                  <MyEditor
                    key={"fullPageCKEditor"}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.setEmailDescription(data);
                    }}
                    onBlur={() => this.validateField("emailDescription")}
                    data={this.state.emailDetails.emailDescription}
                  />
                  <div><ValidationText error={this.state.validState.error.emailDescription} /></div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap justify-between space-x-10 mt-12 pb-12">
              <NavLink
                to="/ViewPages"
                className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white hover:text-white"
              >
                Back
              </NavLink>
              <div>
                <button
                  className="w-40 px-5 mr-16 py-2 btn-sm btn-lighblue text-lg text-white"
                // onClick={() => this.addEditSGTechTv()}
                >
                  Save as Draft
                </button>
                <button
                  className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white"
                  onClick={() => { }}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    )
  }
}
export default withParams(CreateEmail);