import React from "react";
import SearchBar from "./SearchBar";
import ToggleSwitch from "./ToggleSwitch";
import { components } from "react-select";
import MultiSelectDropdown from "../inputs/MultiSelectDropdown";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const SectionWithBorder = (props) => {
  return (
    <>
      <div className="flex items-center justify-end mb-5">
        <button
          className="2xl:px-7 lg:px-5 py-2 btn-red-border text-xl border text-[#C00000]"
          aria-controls="add-edit-modal"
          onClick={() => {
            props.downloadCorporateMemberDirectoryReport();
          }}
        >
          <span className="text-xl text-[#c00000] font-semibold tracking-normal">
            Download Member Directory Rport
          </span>
        </button>
      </div>
      <div className=" w-full ">
        <div
          className={`grid 2xl:grid-cols-4 lg:grid-cols-4 grid-cols-1 gap-3 w-full items-center 2xl:px-7 lg:px-5 pb-7 md:px-5 pl-4 pr-4 md:mb-0 mb-[7px] border-b-[1px] border-[#757575] `}
        >
          
          <div className="2xl:mt-4 lg:mt-4 mt-0">
            <h2 className="common-fonts-size mobile-14 text-[#757575] font-bold 2xl:mb-5 lg:mb-2 mb-0 member-directory-top-boxes lg:flex lg:items-center">
              Business Sector
            </h2>
            <MultiSelectDropdown
              options={props.businessSector}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option,
              }}
              onChange={props.handleChangeInDropdown}
              allowSelectAll={true}
              value={props.selectedOptions.selectedBusinessSector}
              drpIdentity="Business"
              isShowOnlySelectAllOption={true}
            />
          </div>
          <div className="2xl:mt-4 lg:mt-4 mt-0">
            <h2 className="common-fonts-size mobile-14 text-[#757575] font-bold 2xl:mb-5 lg:mb-2 mb-0 member-directory-top-boxes lg:flex lg:items-center">
              Area of Expertise
            </h2>
            <MultiSelectDropdown
              options={props.areaOfExperties}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option,
              }}
              onChange={props.handleChangeInDropdown}
              allowSelectAll={true}
              value={props.selectedOptions.selectedAreaOfExpertise}
              drpIdentity="AreaExperties"
              isShowOnlySelectAllOption={true}
            />
          </div>
          <div className="2xl:mt-4 lg:mt-4 mt-0">
            <h2 className="common-fonts-size mobile-14 text-[#757575] font-bold 2xl:mb-5 lg:mb-2 mb-0 member-directory-top-boxes lg:flex lg:items-center">
              Countries with Presence
            </h2>
            <MultiSelectDropdown
              options={props.countriesPresence}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option,
              }}
              onChange={props.handleChangeInDropdown}
              allowSelectAll={true}
              value={props.selectedOptions.selectedCountriesPresence}
              drpIdentity="CountriesPresence"
              isShowOnlySelectAllOption={true}
            />
          </div>
          <div className="2xl:mt-4 lg:mt-4 mt-0">
            <h2 className="common-fonts-size mobile-14 text-[#757575] font-bold 2xl:mb-5 lg:mb-2 mb-0 member-directory-top-boxes lg:flex lg:items-center">
              Chapters
            </h2>
            <MultiSelectDropdown
              options={props.chapters}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option,
              }}
              onChange={props.handleChangeInDropdown}
              allowSelectAll={true}
              value={props.selectedOptions.selectedChapters}
              drpIdentity="Chapters"
              isShowOnlySelectAllOption={true}
            />
          </div>
        </div>
        {props.children}
      </div>
    </>
  );
};
export default SectionWithBorder;
