import React from "react";

function CommunicationCard(props) {
    return (
        <div className="w-full">
            <div className="custom-checkbox-set">
                <div className="2xl:py-5 2xl:pt-5 lg:py-5 lg:pt-5">
                    <div className="grid grid-cols-12 gap-6 w-full">
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                                    <div className="flex items-baseline register-desc mb-5">
                                        <input
                                            id="isAdHocActivityEvent"
                                            name="isAdHocActivityEvent"
                                            type="checkbox"
                                            className="cursor-pointer h-4 w-4 text-[#C00000] focus:ring-[#C00000] rounded check-box-custom"
                                            onChange={(event) => props.handleChangeCheckBox(event)}
                                            checked={props.sgtechCommunication.isAdHocActivityEvent}
                                            value={props.sgtechCommunication.isAdHocActivityEvent}
                                        />
                                        <label className="communication-label ml-3 block text-[#757575] 2xl:text-[22px]">
                                            {/* Ad-hoc Activity/Event Announcements */}
                                            Events / Programmes
                                        </label>
                                    </div>

                                    <div className="flex items-baseline register-desc mb-5">
                                        <input
                                            id="isFortnightlyGlobal"
                                            name="isFortnightlyGlobal"
                                            type="checkbox"
                                            className="cursor-pointer h-4 w-4 text-[#C00000] focus:ring-[#C00000] rounded check-box-custom"
                                            onChange={(event) => props.handleChangeCheckBox(event)}
                                            checked={
                                                props.sgtechCommunication.isFortnightlyGlobal
                                            }
                                            value={
                                                props.sgtechCommunication.isFortnightlyGlobal
                                            }
                                        />
                                        <label className="communication-label ml-3 block text-[#757575] 2xl:text-[22px]">
                                            {/* SGTech Announcements and Surveys (eg. AGM) */}
                                            Fortnightly Go Global e-newsletter
                                        </label>
                                    </div>
                                    <div className="flex items-baseline register-desc">
                                        <input
                                            id="isBusinessOpportunities"
                                            name="isBusinessOpportunities"
                                            type="checkbox"
                                            className="cursor-pointer h-4 w-4 text-[#C00000] focus:ring-[#C00000] rounded check-box-custom"
                                            onChange={(event) => props.handleChangeCheckBox(event)}
                                            checked={
                                                props.sgtechCommunication.isBusinessOpportunities
                                            }
                                            value={
                                                props.sgtechCommunication.isBusinessOpportunities
                                            }
                                        />
                                        <label className="communication-label ml-3 block text-[#757575] 2xl:text-[22px]">
                                            Business opportunities (TechMatch, TechPartner and others)
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                                    <div className="flex items-baseline register-desc mb-5">
                                        <input
                                            id="isMonthlySGTechEdition"
                                            name="isMonthlySGTechEdition"
                                            type="checkbox"
                                            className="cursor-pointer h-4 w-4 text-[#C00000] focus:ring-[#C00000] rounded check-box-custom"
                                            onChange={(event) => props.handleChangeCheckBox(event)}
                                            checked={
                                                props.sgtechCommunication.isMonthlySGTechEdition
                                            }
                                            value={
                                                props.sgtechCommunication.isMonthlySGTechEdition
                                            }
                                        />
                                        <label className="communication-label ml-3 block text-[#757575] 2xl:text-[22px]">
                                            Monthly SLA Edition e-newsletter
                                        </label>
                                    </div>
                                    <div className="flex items-baseline register-desc mb-5">
                                        <input
                                            id="isFortnightlyEvents"
                                            name="isFortnightlyEvents"
                                            type="checkbox"
                                            className="cursor-pointer h-4 w-4 text-[#C00000] focus:ring-[#C00000] rounded check-box-custom"
                                            onChange={(event) => props.handleChangeCheckBox(event)}
                                            checked={
                                                props.sgtechCommunication.isFortnightlyEvents
                                            }
                                            value={props.sgtechCommunication.isFortnightlyEvents}
                                        />
                                        <label className="communication-label ml-3 block text-[#757575] 2xl:text-[22px]">
                                            Fortnightly Events e-newsletter
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Save Button */}
            <div className="grid grid-cols-12 gap-6 pb-10">
                <div className="xl:col-span-12 lg:col-span-12 col-span-12 px-10">
                    <a
                        className="flex items-center justify-end"
                    // href="/CreateCorporateMembers"
                    >
                        <button
                            className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold"
                            onClick={() => props.addEditCommunicationProfile()}
                        >
                            Save
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
}
export default CommunicationCard;