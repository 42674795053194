export const msalConfig = {
    auth: {
      clientId: "c03c6097-11f3-442f-a588-d7be9cf49495",
      authority: "https://login.microsoftonline.com/3b1ec63a-2288-42a2-8860-91f92d7c4b36", 
      redirectUri: "http://localhost:3000/",
      mainWindowRedirectUri: "http://localhost:3000/",
      postLogoutRedirectUri: "http://localhost:3000/",
    },
    cache: {
      cacheLocation: "sessionStorage", 
      storeAuthStateInCookie: false, 
    },
    system: {
      windowHashTimeout: 60000, // Applies just to popup calls - In milliseconds
      iframeHashTimeout: 6000, // Applies just to silent calls - In milliseconds
      loadFrameTimeout: 0 // Applies to both silent and popup calls - In milliseconds
    }
  };
  
 
  export const loginRequest = {
    scopes: ["User.Read.All"],
  };
  
 
  export const graphConfig = {
      graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me"
  };