import React from "react";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import { APIURL, GetImage } from "../../services/axiosServices/ApiEndPoints";

function BusinessOpportunitiesBannerPreview(props) {
  let settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    speed: 500,
    pauseOnHover: true,
  };

  return (
    <div>
      <div className="main-title ind-bg business-opportunities-bg">
        <div className="page-header">
          <h2 className="big-title-font font-bold text-white ">
            {/* Business Opportunities */}
            Banner Preview
          </h2>
        </div>
      </div>
      <div className="main-page-section">
        <div className="col-span-12 mb-10 mt-16">
          <div className="banner-management-banner">
            <Slider {...settings}>
              {props.bannerDetails.map((banner, key) => {
                return (
                  <>
                    {banner.bannerImageUrl === "" ? null : (
                      <img
                        className="w-full"
                        src={
                          APIURL +
                          GetImage +
                          "?type=banners&&fileName=" +
                          banner.bannerImageUrl
                        }
                        alt=""
                        key={key}
                      ></img>
                    )}
                  </>
                );
              })}
              {/* <img className="w-full"
                src={props.bannerDetails[0] ? APIURL + GetImage + "?type=banners&&fileName=" + props.bannerDetails[0].bannerImageUrl : null}
                alt=""></img>
              <img className="w-full"
                src={props.bannerDetails[1] ? APIURL + GetImage + "?type=banners&&fileName=" + props.bannerDetails[1].bannerImageUrl : "/ad-banner.jpg"}
                alt=""></img>
              <img className="w-full"
                src={props.bannerDetails[2] ? APIURL + GetImage + "?type=banners&&fileName=" + props.bannerDetails[2].bannerImageUrl : "/ad-banner.jpg"}
                alt=""></img> */}
            </Slider>

            <div className="flex flex-wrap justify-between space-x-10 mt-12">
              <div>
                <NavLink
                  to={"/bannerUpload/" + props.bannerPositionName}
                  className="w-32 px-7 py-2 btn-sm btn btn-gray text-lg text-white hover:text-white"
                >
                  {" "}
                  Back
                </NavLink>
              </div>
              <div>
                <button
                  className="w-40 px-5 py-2 btn-sm btn btn-lighblue text-lg text-white"
                  onClick={() => props.addEditBanners()}
                >
                  Publish
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BusinessOpportunitiesBannerPreview;