import React, { Component } from "react";

// import components and packages
import { CommonValidationMessages } from '../../utils/Messages';
import CategoryCampaignButtonGroup from "../../components/entrymanagement/CategoryCampaignButtonGroup";
import EditDetail from "../../components/entrymanagement/EditDetail";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import { Navigate } from "react-router-dom";

//import API services
import EntryManagementServices from "../../services/axiosServices/apiServices/EntryManagementServices";
import SwalServices from "../../services/swalServices/SwalServices";
import FinanceServices from "../../services/axiosServices/apiServices/FinanceServices";
import { checkParameters, encryptAES } from "../../utils/Encryption";


export default class CategoryCampaignTagging extends Component {
    constructor(props) {
        super(props);
        this.EntryManagementServices = new EntryManagementServices();
        this.financeService = new FinanceServices();
        this.SwalServices = new SwalServices();
        this.state = {

            route: "",
            carouselData: [
                {
                    id: 1,
                    img: "/default-img.png",
                    // clipName: "Meet-The-Members Session",
                    // clipdesc: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit,",
                },
                {
                    id: 2,
                    img: "/default-img.png",
                    // clipName: "Meet-The-Members Session",
                    // clipdesc: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit,",
                },
                {
                    id: 3,
                    img: "/default-img.png",
                    // clipName: "Meet-The-Members Session",
                    // clipdesc: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit,",
                },
            ],
            categoryEntry: [],
            campaignList: [],

            categoryCampaignItem: {
                eventId: 0,
                entryCategory: 0,
                campaign: 0,
                metaTitle: "",
                metaKeyword: "",
                metaDescription: "",
                isFeatured: true,
                isReminderEmail: false,
                visibilityId: 0,
                eventAbout: "",
                outlineDescription: "",
                speakerDescription: "",
                readMoreDescription: "",
                eventFormEndTime: "",
                statusId: 2,

                isPublic: true,
                isHidden: false,
                isPrivate: false,

                // entryDate: "6 March 2022",
                // entryTime: "1:30 PM - 4.30 PM",
                // entryHours: 4,
                // entryLocation: "Raffles Place",
                // nonMemberFees: 430.00,
                // ordinaryMemberFees: 320.00,
                // associateMemberFees: 534.00,
                // alumniFees: 388.00,
                // otherFees: 455.00,
                selectedCategoryEntryIds: [],
                selectedCampaignIds: [],
                selectedCampaignId: 0,
                selectedCategoryLabel: "",
                selectedCampaignLabel: "",
                financeAccountCodeId: 0,
                financeClassCodeId: 0,
                revenueAccountCodeId: 0,
                revenueClassCodeId: 0
            },
            entryPricingDetails: {
                eventId: 0,
                eventTimeSlotId: 0,
                eventLessonId: 0,
                locationId: 0,
                eventLessonDate: "",
                eventStartTime: "",
                eventEndTime: "",
                locationAreaName: "",
                totalHours: 0,
                eventPackageList: [],
            },
            selectionDisplay: [],
            eventId: 1,
            isLoading: false,
            financeClassCodes: [],
            financeAccountCodes: [],
            validationRule: {
                metaDescription: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'meta description')
                    }
                ],

                selectedCategoryEntryIds: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.SelectRequired.replace('{0}', 'category of entry')
                    }
                ],
                // selectedCampaignId: [
                //     {
                //         type: 'require',
                //         message: CommonValidationMessages.SelectRequired.replace('{0}', 'campaign')
                //     }
                // ],
                eventAbout: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'about description')
                    }
                ],
                financeAccountCodeId: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.SelectRequired.replace('{0}', 'account code')
                    }
                ],
                financeClassCodeId: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.SelectRequired.replace('{0}', 'class code')
                    }
                ],
                revenueAccountCodeId: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.SelectRequired.replace('{0}', 'revenue account code')
                    }
                ],
                revenueClassCodeId: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.SelectRequired.replace('{0}', 'revenue class code')
                    }
                ],
            },

            descriptionValidationRule: {
                outlineDescription: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'outline description')
                    }
                ],
                // speakerDescription: [
                //     {
                //         type: 'require',
                //         message: CommonValidationMessages.FieldRequired.replace('{0}', 'speaker description')
                //     }
                // ],
                readMoreDescription: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'read more description')
                    }
                ],
            },

            validStateDescription: {
                isValid: true,
                error: {}
            },

            validState: {
                isValid: true,
                error: {}
            },
        };
    }

    // Method declaration section start
    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let eventId = params.get("");
        if ((eventId = checkParameters(eventId, "Number"))) {
            if (eventId && eventId > 0) {
                this.setState({ eventId: eventId }, () => {
                    // this.getCampaignTaggingDetailsByEventId(id);
                    // this.getEntryTimeSlotDetailForTaggingByEventId(id);
                });
                this.getAllDropDownForCampaignTagging();

            }
        }


    }

    getEntryTimeSlotDetailForTaggingByEventId = (eventId) => {
        this.EntryManagementServices.getEntryTimeSlotDetailForTaggingByEventId(eventId).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                if (response.responseItem.responseContent !== null) {
                    let detail = response.responseItem.responseContent;
                    //Set entry topic dropdown

                    let startTimeArr = detail.eventStartTime.split(' ');
                    let endTimeArr = detail.eventEndTime.split(' ');

                    let hrMinStart = startTimeArr[0].split(":");
                    let hr1 = Number(hrMinStart[0]);
                    let mn1 = Number(hrMinStart[1]);
                    if ((Number(hrMinStart[0]) < 12) && (startTimeArr[1] === "PM" || startTimeArr[1] === "pm")) {
                        hr1 = Number(hrMinStart[0]) + 12;
                    }
                    let timeT1 = hr1 + (mn1 / 60);

                    let hrMinEnd = endTimeArr[0].split(":");
                    let hr2 = Number(hrMinEnd[0]);
                    let mn2 = Number(hrMinEnd[1]);
                    if ((Number(hrMinEnd[0]) < 12) && (endTimeArr[1] === "PM" || endTimeArr[1] === "pm")) {
                        hr2 = Number(hrMinEnd[0]) + 12;
                    }
                    let timeT2 = hr2 + (mn2 / 60);
                    let diff = timeT2 - timeT1;
                    diff = parseFloat(diff).toFixed(2);

                    let data = { ...this.state.entryPricingDetails }
                    data.eventId = detail.eventId;
                    data.eventTimeSlotId = detail.eventTimeSlotId;
                    data.eventLessonId = detail.eventLessonId;
                    data.locationId = detail.locationId;
                    data.eventLessonDate = detail.eventLessonDate;
                    data.eventStartTime = detail.eventStartTime;
                    data.eventEndTime = detail.eventEndTime;
                    data.locationAreaName = detail.locationAreaName;
                    data.totalHours = diff;
                    data.eventPackageList = detail.eventPackageList;
                    this.setState({ entryPricingDetails: data });
                }
            }

        });
    };

    editDetails = () => {
        this.setState({ route: '/CreateEntry/eventId?=' + encryptAES(this.state.eventId) });
    }

    // API method declaration start

    //Get all campaign tagging dropdown method start
    getAllDropDownForCampaignTagging = () => {
        // let request = this.state.dropdownDetails;
        // request.dropdownId = 115;
        this.EntryManagementServices.getAllDropDownForCampaignTagging().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {

                let entryCategory = response.responseItem.responseContent.filter(
                    (drp) => drp.dropdownName === "EntryCategory"
                );
                entryCategory = entryCategory.map((p) => ({
                    value: p.id,
                    label: p.name,
                }));

                let campaignType = response.responseItem.responseContent.filter(
                    (drp) => drp.dropdownName === "Campaign"
                );
                campaignType = campaignType.map((p) => ({
                    value: p.id,
                    label: p.name,
                }));
                this.setState({ campaignList: campaignType, categoryEntry: entryCategory }, () => {
                    this.getAllDropdownsForFinanceAdhokInvoices();

                });
            } else {
                this.getAllDropdownsForFinanceAdhokInvoices();
                this.SwalServices.Error(response.message);
            }
        });
    };
    // 

    getCampaignTaggingDetailsByEventId = (eventId) => {
        this.EntryManagementServices.getCampaignTaggingDetailsByEventId(eventId).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let detail = response.responseItem.responseContent;
                if (response.responseItem.responseContent !== null) {
                    //Set entry topic dropdown
                    let array = response.responseItem.responseContent.entryCategory
                        ? response.responseItem.responseContent.entryCategory.split(",") : [];
                        let topics = [];
                     array?.forEach((topic) => {
                        topics.push(
                            this.state.categoryEntry.find(
                                (topics) => topics.value === Number(topic)
                            )
                        );
                    });
                    detail.selectedCategoryEntryIds = topics;
                    let str1 = '';
                    // Set selection display data entry topics
                    if (detail.selectedCategoryEntryIds.length > 0) {
                        let arr3 = detail.selectedCategoryEntryIds.map(function (item) {
                            delete item.bad;
                            return item.label;
                        });
                        str1 = arr3.join([',']);
                    }
                    detail.selectedCategoryLabel = str1.split(",")
                    // this.setState({ categoryCampaignItem: detail });

                    //Set campaign dropdown
                    // var array2 = response.responseItem.responseContent.campaign
                    //     ? response.responseItem.responseContent.campaign.split(",") : [];
                    let campaignId = response.responseItem.responseContent.campaign ? Number(response.responseItem.responseContent.campaign) : 0;
                    let topics2 = {};
                    // array2.forEach((topic) => {
                    //     topics2.push(
                    //         this.state.campaignList.find(
                    //             (topics2) => topics2.value === Number(topic)
                    //         )
                    //     );
                    // });
                    detail.selectedCampaignId = campaignId;
                    if (campaignId > 0) {
                        topics2 = this.state.campaignList.find((topics2) => topics2.value === campaignId);
                        detail.selectedCampaignId = topics2.value;
                    }
                    // detail.selectedCampaignIds = topics2;

                    // Set selection display data campaign
                    if (topics2 && topics2 !== null) {
                        // var arr4 = detail.selectedCampaignIds.map(function (item) {
                        //     delete item.bad;
                        //     return item.label;
                        // });
                        let arr4 = [topics2];
                        let str1 = arr4.join([',']);
                        detail.selectedCampaignLabel = topics2.label;
                    }
                    // this.setState({ categoryCampaignItem: detail });
                    let allSelected = [];
                    if (!detail.selectedCategoryLabel) {
                        allSelected = detail.selectedCampaignLabel
                    }
                    else if (!detail.selectedCampaignLabel) {
                        allSelected = detail.selectedCategoryLabel;
                    }
                    else {
                        allSelected = detail.selectedCategoryLabel.concat(detail.selectedCampaignLabel);
                    }
                    this.setState({ selectionDisplay: allSelected }, () => {
                        if (detail.visibilityId === 1) {
                            detail.isPublic = true;
                            detail.isPrivate = false;
                            detail.isHidden = false;
                        }
                        else if (detail.visibilityId === 2) {
                            detail.isPrivate = true;
                            detail.isPublic = false;
                            detail.isHidden = false;
                        }
                        else if (detail.visibilityId === 3) {
                            detail.isHidden = true;
                            detail.isPublic = false;
                            detail.isPrivate = false;
                        }
                        this.setState({ categoryCampaignItem: detail }, () => this.getEntryTimeSlotDetailForTaggingByEventId(this.state.eventId));
                    });
                }
            } else {
                this.getEntryTimeSlotDetailForTaggingByEventId(this.state.eventId);
                SwalServices.Error(response.message);
            }
        });
    };

    // Add edit campaign tagging details method start
    addEditCampaignTaggingDetails = (id) => {

        let isValidAllTagging = this.isValidateAllFields();
        if (this.state.categoryCampaignItem.eventTypeId === 1 || this.state.categoryCampaignItem.eventTypeId === 4 || this.state.categoryCampaignItem.eventTypeId === 5 || this.state.categoryCampaignItem.eventTypeId === 6 ) {
            //let isValidDescription = this.isValidateAllFieldsDescription();
            //if (isValidAllTagging && isValidDescription) {
                let request = { ...this.state.categoryCampaignItem };
                request.statusId = id;
                // if (request['isPublic'] === true) {
                //     request['visibilityId'] = 1;
                // }
                if (request['isPrivate'] === true) {
                    request['visibilityId'] = 2;
                }
                else if (request['isHidden'] === true) {
                    request['visibilityId'] = 3;
                }
                else {
                    request['visibilityId'] = 1;
                }
                //request.entryCategory = request.selectedCategoryEntryIds.join([',']);
                request.campaign = request.selectedCampaignId;
                this.EntryManagementServices.addEditCampaignTaggingDetails(request).then((response) => {
                    if (response.statusCode === 200 && response.responseItem != null) {
                        this.SwalServices.Success("Entry details inserted successfully.");
                        this.setState({ route: '/ViewEditEntries' });
                    } else {
                        this.SwalServices.Error(response.message);
                    }
                    this.setState({ isLoading: false });
                });
            //}
        }
        else {
            if (isValidAllTagging) {
                let request = { ...this.state.categoryCampaignItem };
                request.statusId = id;
                // if (request['isPublic'] === true) {
                //     request['visibilityId'] = 1;
                // }
                if (request['isPrivate'] === true) {
                    request['visibilityId'] = 2;
                }
                else if (request['isHidden'] === true) {
                    request['visibilityId'] = 3;
                }
                else {
                    request['visibilityId'] = 1;
                }
                request.campaign = request.selectedCampaignId;
                this.EntryManagementServices.addEditCampaignTaggingDetails(request).then((response) => {
                    if (response.statusCode === 200 && response.responseItem != null) {
                        this.SwalServices.Success("Entry details inserted successfully.");
                        this.setState({ route: '/ViewEditEntries' });
                    } else {
                        this.SwalServices.Error(response.message);
                    }
                    this.setState({ isLoading: false });
                });
            }
        }
    };
    // Add edit campaign tagging details method end
    // API method declaration end

    // Category campaign internal methods start
    validateField = (key) => {
        const newValidState = validate(key, this.state.categoryCampaignItem, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
    };

    isValidateAllFields = () => {
        const newValidState = isValidForm(this.state.categoryCampaignItem, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };

    validateFieldDescription = (key) => {
        const newValidState = validate(key, this.state.categoryCampaignItem, this.state.descriptionValidationRule, this.state.validStateDescription);
        this.setState({ validStateDescription: newValidState });
    };

    isValidateAllFieldsDescription = () => {
        const newValidState = isValidForm(this.state.categoryCampaignItem, this.state.descriptionValidationRule, this.state.validStateDescription);
        this.setState({ validStateDescription: newValidState });
        return newValidState.isValid;
    };


    
    handleChangeInDropdown(selected, drpIdentity) {
        if (drpIdentity === "CategoryEntry") {
            let details = { ...this.state.categoryCampaignItem };
            details.selectedCategoryEntryIds = selected;

            if (details.selectedCategoryEntryIds.length > 0) {
                let arr1 = details.selectedCategoryEntryIds.map(function (item) {
                    delete item.bad;
                    return item.value;
                });
                details.entryCategory = arr1.join([',']);
                this.setState({ categoryCampaignItem: details }, () => {
                    // Set selection display data
                    let concatData = '';
                    if (details.selectedCategoryEntryIds.length > 0) {
                        let arr3 = details.selectedCategoryEntryIds.map(function (item) {
                            delete item.bad;
                            return item.label;
                        });
                        concatData = arr3.concat(details.selectedCampaignLabel);
                    }
                    this.setState({ selectionDisplay: concatData });
                });
            }
            // Set selection display data
            // if (details.selectedCategoryEntryIds.length > 0) {
            //     var arr3 = details.selectedCategoryEntryIds.map(function (item) {
            //         delete item.bad;
            //         return item.label;
            //     });
            //     let str1 = arr3.join([',']);
            //     details.selectedCategoryLabel = str1.split(",")
            // }
            // this.setState({ categoryCampaignItem: details });

            // //Combine selected item in dropdown
            // let allSelected = [...this.state.categoryCampaignItem.selectedCategoryLabel, ...this.state.categoryCampaignItem.selectedCampaignLabel];
            // this.setState({ selectionDisplay: allSelected });
            this.validateField("selectedCategoryEntryIds");
        }
        else if (drpIdentity === "CampaignName") {
            let details = { ...this.state.categoryCampaignItem };
            let campaignList = [...this.state.campaignList];
            if(selected.value > 0){
                details.selectedCampaignId = selected.value;
                this.setState({ categoryCampaignItem: details }, () => {
                    let concatData = [];
                    let test = [];
                    campaignList.map((x) => {
                        if (x.value === details.selectedCampaignId) 
                        { 
                            test.push(x);
                        }
                    })
                    concatData = details.selectedCategoryEntryIds.concat(test[0].label);
                    this.setState({ selectionDisplay: concatData })
                    //this.validateField("selectedCampaignId")
                });
            }else{
                details.selectedCampaignId = 0;
                this.setState({ categoryCampaignItem: details },()=>{
                    //this.validateField("selectedCampaignId")
                })
            }
        }   

        // else if (drpIdentity === "CampaignName") {
        //     var details2 = this.state.categoryCampaignItem
        //     details2.selectedCampaignIds = selected;

        //     if (this.state.categoryCampaignItem.selectedCampaignIds.length > 0) {
        //         var arr2 = this.state.categoryCampaignItem.selectedCampaignIds.map(function (item) {
        //             delete item.bad;
        //             return item.value;
        //         });
        //         details2.campaign = arr2.join([',']);
        //         this.setState({ categoryCampaignItem: details2 });
        //     }

        //     // Set selection display data
        //     if (details2.selectedCampaignIds.length > 0) {
        //         var arr4 = details2.selectedCampaignIds.map(function (item) {
        //             delete item.bad;
        //             return item.label;
        //         });
        //         let str2 = arr4.join([',']);
        //         details2.selectedCampaignLabel = str2.split(",")
        //     }
        //     this.setState({ categoryCampaignItem: details2 });

        //     //Combine selected item in dropdown
        //     let allSelected = [...this.state.categoryCampaignItem.selectedCategoryLabel, ...this.state.categoryCampaignItem.selectedCampaignLabel];
        //     this.setState({ selectionDisplay: allSelected });
        //     this.validateField("selectedCampaignIds");
        // }
    };

    handleRadioButtons(event) {
        const value = event.target.checked;
        const id = event.target.id;
        let detail = { ...this.state.categoryCampaignItem };
        detail['isHidden'] = false;
        detail['isPublic'] = false;
        detail['isPrivate'] = false;
        detail[id] = value;
        this.setState({ categoryCampaignItem: { ...detail } });
    };

    setAboutDescription = (data) => {
        let detail = { ...this.state.categoryCampaignItem };
        let re = /<a href="([^"]+)"/g;
        let result = data.replace(re, "<a href='$1' target=\"_blank\" rel=\"noopener noreferrer\"");
        detail["eventAbout"] = result;
        this.setState({ categoryCampaignItem: { ...detail } });
    };

    setOutLineDescription = (data) => {
        let detail = { ...this.state.categoryCampaignItem };
        let re = /<a href="([^"]+)"/g;
        let result = data.replace(re, "<a href='$1' target=\"_blank\" rel=\"noopener noreferrer\"");
        detail["outlineDescription"] = result;
        this.setState({ categoryCampaignItem: { ...detail } });
    };

    setSpeakerDescription = (data) => {
        let detail = { ...this.state.categoryCampaignItem };
        let re = /<a href="([^"]+)"/g;
        let result = data.replace(re, "<a href='$1' target=\"_blank\" rel=\"noopener noreferrer\"");
        detail["speakerDescription"] = result;
        this.setState({ categoryCampaignItem: { ...detail } });
    };

    setReadMoreDescription = (data) => {
        let detail = { ...this.state.categoryCampaignItem };
        let re = /<a href="([^"]+)"/g;
        let result = data.replace(re, "<a href='$1' target=\"_blank\" rel=\"noopener noreferrer\"");
        detail["readMoreDescription"] = result;
        this.setState({ categoryCampaignItem: { ...detail } });
    };

    handleAboutEditorChange(event, editor) {
        const data = editor.getData();
        this.setAboutDescription(data);
    };
    handleOutlineEditorChange(event, editor) {
        const data = editor.getData();
        this.setOutLineDescription(data);
    };
    handleSpeakerEditorChange(event, editor) {
        const data = editor.getData();
        this.setSpeakerDescription(data);
    };
    handleReadMoreEditorChange(event, editor) {
        const data = editor.getData();
        this.setReadMoreDescription(data);
    };

    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = { ...this.state.categoryCampaignItem };
        detail[name] = value;
        this.setState({ categoryCampaignItem: { ...detail } });
    };

    filterByToggle = (value, e, index, identity) => {
        if (identity === "Featured") {
            let detail = { ...this.state.categoryCampaignItem };
            this.setState({ setToggle: e.target.checked });
            detail.isFeatured = e.target.checked;
            this.setState({ categoryCampaignItem: { ...detail } });
        }
        else if (identity === "ReminderEmail") {
            let detail = { ...this.state.categoryCampaignItem };
            this.setState({ setToggle: e.target.checked });
            detail.isReminderEmail = e.target.checked;
            this.setState({ categoryCampaignItem: { ...detail } });
        }
    };

    redirectToViewEntries = () => {
        this.setState({ route: '/ViewEditEntries' });
    }

    getAllDropdownsForFinanceAdhokInvoices = () => {
        this.financeService.getAllDropdownsForFinanceAdhokInvoices().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                // var adhokInvoiceTypesArry = response.responseItem.responseContent.filter(
                //     (drp) => drp.dropdownName === "AdhokInvoiceTypes"
                // );
                let financeClassCodesArry = response.responseItem.responseContent.filter(
                    (drp) => drp.dropdownName === "FinanceClassCodes"
                );
                let financeAccountCodesArry = response.responseItem.responseContent.filter(
                    (drp) => drp.dropdownName === "FinanceAccountCodes"
                );
                // var gstPercentage = response.responseItem.responseContent.filter(
                //     (drp) => drp.dropdownName === "GSTPercentage"
                // );
                // adhokInvoiceTypesArry = adhokInvoiceTypesArry.map((p) => ({ id: p.id, name: p.name }));
                financeClassCodesArry = financeClassCodesArry.map((p) => ({ id: p.id, name: p.name }));
                financeAccountCodesArry = financeAccountCodesArry.map((p) => ({ id: p.id, name: p.name }));

                this.setState({
                    financeClassCodes: financeClassCodesArry, financeAccountCodes: financeAccountCodesArry
                }, () => {
                    if (this.state.eventId > 0) {
                        this.getCampaignTaggingDetailsByEventId(this.state.eventId);

                    }
                });
            } else {
                if (this.state.eventId > 0) {
                    this.getCampaignTaggingDetailsByEventId(this.state.eventId);

                }
            }
        })
    }

    handleChangeInCodesDropdown = (id, drpIdentity) => {
        let detail = { ...this.state.categoryCampaignItem };
        if (drpIdentity === "financeAccountCodes") {
            detail.financeAccountCodeId = id;
            this.setState({ categoryCampaignItem: detail }, () => {
                this.validateField("financeAccountCodeId");
            });
        }
        if (drpIdentity === "financeClassCodes") {
            detail.financeClassCodeId = id;
            this.setState({ categoryCampaignItem: detail }, () => {
                this.validateField("financeClassCodeId");
            });
        }
        if (drpIdentity === "revenueAccountCodes") {
            detail.revenueAccountCodeId = id;
            this.setState({ categoryCampaignItem: detail }, () => {
                this.validateField("revenueAccountCodeId");
            });
        }
        if (drpIdentity === "revenueClassCodes") {
            detail.revenueClassCodeId = id;
            this.setState({ categoryCampaignItem: detail }, () => {
                this.validateField("revenueClassCodeId");
            });
        }
    }


    render() {
        if (this.state.route !== "") {
            return <Navigate to={this.state.route} />;
        }
        return (
            <>
                <div className="main-body-section">
                    <div className="custom-card2 shadow-lg">
                        <CategoryCampaignButtonGroup
                            addEditCampaignTaggingDetails={this.addEditCampaignTaggingDetails.bind(this)}
                            editDetails={this.editDetails.bind(this)}
                            redirectToViewEntries={this.redirectToViewEntries.bind(this)}
                        />
                        <div className="py-8">
                            <EditDetail
                                carouselData={this.state.carouselData}
                                categoryEntry={this.state.categoryEntry}
                                campaignList={this.state.campaignList}
                                reminderEmail={this.state.reminderEmail}
                                categoryCampaignItem={this.state.categoryCampaignItem}
                                selectionDisplay={this.state.selectionDisplay}
                                handleChange={this.handleChange.bind(this)}
                                handleChangeInDropdown={this.handleChangeInDropdown.bind(this)}
                                filterByToggle={this.filterByToggle.bind(this)}
                                handleReadMoreEditorChange={this.handleReadMoreEditorChange.bind(this)}
                                handleAboutEditorChange={this.handleAboutEditorChange.bind(this)}
                                handleOutlineEditorChange={this.handleOutlineEditorChange.bind(this)}
                                handleSpeakerEditorChange={this.handleSpeakerEditorChange.bind(this)}
                                handleRadioButtons={this.handleRadioButtons.bind(this)}
                                filterByFeatured={this.filterByToggle.bind(this)}
                                validState={this.state.validState}
                                validateField={this.validateField.bind(this)}
                                validateFieldDescription={this.validateFieldDescription.bind(this)}
                                validStateDescription={this.state.validStateDescription}
                                entryPricingDetails={this.state.entryPricingDetails}
                                financeClassCodes={this.state.financeClassCodes}
                                financeAccountCodes={this.state.financeAccountCodes}
                                handleChangeInCodesDropdown={this.handleChangeInCodesDropdown.bind(this)}
                            />
                        </div>
                        <CategoryCampaignButtonGroup
                            addEditCampaignTaggingDetails={this.addEditCampaignTaggingDetails.bind(this)}
                            editDetails={this.editDetails.bind(this)}
                            redirectToViewEntries={this.redirectToViewEntries.bind(this)}
                        />
                    </div>
                </div>
            </>
        );
    }
}