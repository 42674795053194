import React, { Component } from "react";
import { Navigate, NavLink } from "react-router-dom";
import DropdownSelect from "../../components/dropdown/Dropdown";
import AddNewPageNameForSubModule from "../../components/pageManagement/AddNewPageNameForSubModule";
import Components from "../../components/pageManagement/Components";
import CommonServices from "../../services/axiosServices/apiServices/CommonServices";
import PagesManagementServices from "../../services/axiosServices/apiServices/PagesManagementServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { WebURLPath } from "../../utils/AppSetting";
import {
  CommonSuccessMessages,
  CommonValidationMessages,
  ErrorMessage,
  SuccessMessage,
} from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import { compare, required } from "../../utils/validation/ValidateField";
import ValidationText from "../../utils/validation/ValidationText";
import withParams from "./../../HOC/withParams";
import TinyMCEEditor from "../../components/ckEditor/TinyMCEEditor";
import CKClassicEditor from "../../components/ckEditor/CKClassicEditor";
import { checkParameters } from "../../utils/Encryption";

class EditPages extends Component {
  constructor(props) {
    super(props);
    this.PagesManagementServices = new PagesManagementServices();
    this.swalServices = new SwalServices();
    this.commonServices = new CommonServices();
    this.state = {
      bannerManagement: [{ id: 1, name: 'Chapters/Committee' }, { id: 2, name: 'Events' }, { id: 3, name: 'Business Opportunities' }, { id: 4, name: 'Articles' }],
      showBanners: false,
      update: false,
      mainModules: [],
      pageTypes: [
        { id: 1, name: "Partial Page" },
        { id: 2, name: "Full Page" },
        { id: 3, name: "Independent Page" },
      ],
      subModules: null,
      pageData: {
        pagesDetailsId: 0,
        pagesMainModuleId: 0,
        pagesSubModuleId: 0,
        pageTypeId: 1,
        pageDescription: "",
        metaTitle: "",
        metaDescription: "",
        metaKeywords: "",
        pagesTypes: 0,
        fullPageCKEditorkey: "fullPageEditorMount",
        pageName: "",
        pageNameId: 0,
        pageNameUrl: "",
        bannerPositionId: 0,
        selectedComponent: 0,
      },
      subModulePageDetails: {
        pageNameId: 0,
        pageName: "",
        pagesSubModuleId: 0,
        pagesMainModuleId: 0,
        pageUrl: "",
        isRequireAddNewPage: false,
      },
      isPrivilegePage: false,
      validationRule: {
        metaTitle: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "meta title"
            ),
          },
        ],
        metaDescription: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "meta description"
            ),
          },
        ],
        metaKeywords: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "meta keywords"
            ),
          },
        ],
      },
      validState: {
        isValid: true,
        error: {},
      },
      route: "",

      componentsArr: [],
      pageDetailsId: 0,
      partialComponentDetails: {
        partialPageId: 0,
        componentId: 0,
        componentName: "",
        componentTypeId: 0,
        componentContent: "",
        pagesDetailsId: 0,
        selectedComponent: 0,
      },

      components: [],
      pageName: "",
      listTypes: [
        { id: 1, name: "Icon List" },
        { id: 2, name: "Bullet List" },
      ],
      iconArray: [
        {
          id: 1,
          name: '<img className="menu-svg  2xl:mr-10 lg:ml-6  mr-7 w-[35px] h-[25px] fill-[#6e6e6e]" src="/bulletList_dot.svg" alt=""></img>',
        },
        {
          id: 2,
          name: '<img className="menu-svg  2xl:mr-10 lg:ml-6  mr-7 w-[35px] h-[25px] fill-[#6e6e6e]" src="/bulletList_square.svg" alt=""></img>',
        },
        {
          id: 3,
          name: '<img className="menu-svg  2xl:mr-10 lg:ml-6  mr-7 w-[35px] h-[25px] fill-[#6e6e6e]" src="/bulletList_triangle.svg" alt=""></img>',
        },
      ],
      currentURL: "",
      setAddPageNameModel: false,
      setDeletePageNameModel: false,
      subPageOfSubModule: [],
      deletePageValidation: false,
      validationRuleForAddPageName: {
        pageName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "page name"
            ),
          },
        ],
        pagesMainModuleId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "main module"
            ),
          },
        ],
        pagesSubModuleId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "sub modul"
            ),
          },
        ],
      },
      validStateAddPage: {
        isValid: true,
        error: {},
      },
      isCreatePage: false,
    };
  }

  getAllMainModules = () => {
    this.PagesManagementServices.GetPagesMainModulesList().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ mainModules: response.responseItem.responseContent });
        // this.setState({ mainModulesTest: response.responseItem.responseContent });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  GetSubModulesListByMainModuleId = (mainModuleId, page = null) => {
    this.PagesManagementServices.GetSubModulesListByMainModuleId(
      mainModuleId
    ).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (page) {
          this.setState(
            { subModules: response.responseItem.responseContent },
            () => {
              this.getSubPagesListForSubModuleById(page.pagesSubModuleId, page);
              // this.setState({ pageData: page }, () => {
              //   this.getAllDropdownsForPages();
              //   if (this.state.pageData.pagesDetailsId > 0) {
              //     this.getPartialPageComponentsById(this.state.pageData.pagesDetailsId);
              //   }
              // });
            }
          );
        } else {
          this.setState({ subModules: response.responseItem.responseContent });
        }
      } else {
        this.swalServices.Error(response.message);
        return "";
      }
      this.setState({ isLoading: false });
    });
  };

  setFilterParameters = (id, drpIdentity) => {
    let detail = this.state.pageData;
    if (drpIdentity === "MainMenu") {
      detail["pagesMainModuleId"] = id;
      detail.pageNameId = 0;
      if (true && id > 0) {
        this.GetSubModulesListByMainModuleId(id);
      }
      detail["pageTypeId"] = 1;
      detail["pagesSubModuleId"] = 0;
    }
    if (drpIdentity === "PageTypes") {
      detail["pageTypeId"] = id;
      // var validation = { ...this.state.validationRule };
      detail.pagesTypes = id;
    }
    if (drpIdentity === "SubMenu") {
      detail["pagesSubModuleId"] = id;
      detail.pageNameId = 0;
    }
    if (drpIdentity === "SubPage") {
      detail["pageNameId"] = id;
      if (id && id > 0) {
        let data = this.state.subPageOfSubModule.find((x) => x.id === id);
        let details = this.state.subModulePageDetails;
        details.pageUrl = data.name.toLowerCase().replace(/\s/g, "");
        this.setState({ subModulePageDetails: details });
      }
    }
    if (drpIdentity === "AddSubMenu") {
      let details = this.state.subModulePageDetails;
      details["pagesSubModuleId"] = id;
      this.validateFieldAddPage("pagesSubModuleId");
      this.setState({ subModulePageDetails: details });
    }
    if (drpIdentity === "AddMainMenu") {
      let details = this.state.subModulePageDetails;
      details["pagesMainModuleId"] = id;
      this.validateFieldAddPage("pagesMainModuleId");

      if (true && id > 0) {
        this.GetSubModulesListByMainModuleId(id);
      }
      this.setState({ subModulePageDetails: details });
    }
    if (drpIdentity === "AddSubPage") {
      let details = this.state.subModulePageDetails;
      details["pageNameId"] = id;
      this.setState({ subModulePageDetails: details });
    }
    this.setState({ pageData: { ...detail } });
  };

  validatePageDetails = () => {
    let isValid = true;
    let page = { ...this.state.pageData };
    if (page.pageTypeId === 2) {
      page.mainModuleValidation = true;
      if (page["pagesMainModuleId"] < 1) {
        page.mainModuleValidation = false;
        isValid = false;
        return isValid;
      }
      page.subModuleValidation = true;
      if (page["pagesSubModuleId"] < 1) {
        page.subModuleValidation = false;
        isValid = false;
        return isValid;
      }
      page.pageDescriptionValidation = true;
      if (!required(page["pageDescription"]) && page.pageTypeId === 2) {
        page.pageDescriptionValidation = false;
        isValid = false;
        return isValid;
      }
      page.pageDecLengthValidation = true;
    }
    else if (page.pageTypeId === 1) {
      page.mainModuleValidation = true;
      if (page["pagesMainModuleId"] < 1) {
        page.mainModuleValidation = false;
        isValid = false;

        return isValid;
      }
      page.subModuleValidation = true;
      if (page["pagesSubModuleId"] < 1) {
        page.subModuleValidation = false;
        isValid = false;
        return isValid;
      }
    }
    return isValid;
  };

  savePageDetails = () => {
    if (((this.state.pageData.pageTypeId === 1 || this.state.pageData.pageTypeId === 3) && this.state.components.length > 0) || (this.state.pageData.pageTypeId === 2)) {
      let isValidFullPage = true;
      let isValidInput = this.isValidateAllFields();
      isValidFullPage = this.validatePageDetails();
      //let isValidInput = true;
      if (isValidInput && isValidFullPage) {
        let request = { ...this.state.pageData };
        if (this.state.pageData.pageTypeId === 3) {
          // let name = this.state.subPageOfSubModule.find(x => x.id === request.pageNameId)
          // request.pageName = request.pageName.toLowerCase().replace(/\s/g, "");
          request.pageNameUrl = request.pageName.toLowerCase().replace(/\s/g, "");
        }
        else {
          request.pageNameUrl = null;
        }
        request.pagesMainModuleId = (request.pagesMainModuleId && request.pagesMainModuleId !== 0 && this.state.pageData.pageTypeId !== 3) ? request.pagesMainModuleId : null;
        request.pagesSubModuleId = (request.pagesSubModuleId && request.pagesSubModuleId !== 0 && this.state.pageData.pageTypeId !== 3) ? request.pagesSubModuleId : null;
        this.PagesManagementServices.savePageDetails(request).then(
          (response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              if (
                response.responseItem.responseContent.errorType === "EXISTS"
              ) {
                this.swalServices.Warning(ErrorMessage.Already_Exist.replace("{0}", "Page details"));
              }
              else {
                if ((this.state.pageData.pageTypeId === 1 || this.state.pageData.pageTypeId === 3 || this.state.isPrivilegePage) && response.responseItem.responseContent.keyId > 0) {
                  this.addEditPartialPage(response.responseItem.responseContent.keyId);
                }
                else {
                  this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Your page details"));
                  this.setState({ route: "/ViewPages" });
                }
              }
            }
            else {
              this.swalServices.Error(ErrorMessage.DefaultMessage);
            }
            this.setState({ isLoading: false });
          }
        );
      }
    }
    else {
      this.swalServices.Alert("Please add components.");
    }
  };

  getPageDetailByPagesDetailsId = async (id) => {
    // let reqPrm = [menuId, subMenuId, pageNameId];
    this.PagesManagementServices.getPageDetailByPagesDetailsId(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        var page = { ...this.state.pageData };
        page.pagesDetailsId =
          response.responseItem.responseContent.pagesDetailsId;
        page.pagesMainModuleId =
          response.responseItem.responseContent.pagesMainModuleId;
        page.pageTypeId = response.responseItem.responseContent.pageTypeId;
        page.pagesSubModuleId =
          response.responseItem.responseContent.pagesSubModuleId;
        page.pageDescription =
          response.responseItem.responseContent.pageDescription;
        page.metaTitle = response.responseItem.responseContent.metaTitle;
        page.metaDescription =
          response.responseItem.responseContent.metaDescription;
        page.metaKeywords = response.responseItem.responseContent.metaKeywords;
        page.pagesTypes = "";
        page.pageName = response.responseItem.responseContent.pageName;
        page.pageNameId = response.responseItem.responseContent.pageNameId;
        page.bannerPositionId = response.responseItem.responseContent.bannerPositionId;

        if (page.pageName === 'BenefitsAndFAQs') {
          this.setState({ isPrivilegePage: true })
        }

        if (page.pageName === 'AnnualReport') {
          this.setState({ isPrivilegePage: true })
        }

        if (page.bannerPositionId > 0) {
          this.setState({ showBanners: true });
        }
        if (page.pagesMainModuleId > 0 && page.pagesSubModuleId > 0) {
          if (page.pagesMainModuleId === 3 && page.pagesSubModuleId === 12) {
            this.setState({ isPrivilegePage: true });
          }
          this.GetSubModulesListByMainModuleId(
            response.responseItem.responseContent.pagesMainModuleId,
            page
          );
        }
        else if (page.pageTypeId === 3) {
          page.pageNameUrl = page.pageName && page.pageName !== '' ? page.pageName.toLowerCase().replace(/\s/g, "") : null;
          this.setState({ pageData: page }, () => {

            if (page.pagesDetailsId > 0) {
              this.getPartialPageComponentsById(
                this.state.pageData.pagesDetailsId
              );
            } else {
              this.getAllDropdownsForPages();
            }
          });
        }

        // this.GetSubModulesListByMainModuleId(
        //   response.responseItem.responseContent.pagesMainModuleId,
        //   page
        // );
        this.setState({ update: true });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  setPageData = (data, editor) => {
    if (typeof data === 'string') {
      let detail = this.state.pageData;
      // var re = new RegExp('<a href="([^"]+)"', "g");
      // var result = data.replace(
      //   re,
      //   '<a href=\'$1\' target="_blank" rel="noopener noreferrer"'
      // );
      // detail["pageDescription"] = result;
      // this.setState({ pageData: { ...detail } });
      detail["pageDescription"] = data;
      this.setState({ pageData: detail });
    }
  };

  handleChange = (event, identity) => {
    const value = event.target.value;
    const name = event.target.name;

    if (identity === "PageName") {
      let detail = this.state.subModulePageDetails;
      detail[name] = value;
      this.setState({ subModulePageDetails: { ...detail } });
    } else if (identity === "PageData") {
      let detail = { ...this.state.pageData };
      detail[name] = value;
      if (name === "pageName") {
        detail.pageNameUrl = value.toLowerCase().replace(/\s/g, "");
      }
      this.setState({ pageData: { ...detail } });
    }
  };

  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.pageData,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  isValidateAllFields = (validationRule = this.state.validationRule) => {
    const newValidState = isValidForm(
      this.state.pageData,
      validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  validateFieldAddPage = (key) => {
    const newValidState = validate(
      key,
      this.state.subModulePageDetails,
      this.state.validationRuleForAddPageName,
      this.state.validStateAddPage
    );
    this.setState({ validStateAddPage: newValidState });
  };

  isValidateAllFieldsAddPage = () => {
    const newValidState = isValidForm(
      this.state.subModulePageDetails,
      this.state.validationRuleForAddPageName,
      this.state.validStateAddPage
    );
    this.setState({ validStateAddPage: newValidState });
    return newValidState.isValid;
  };

  componentDidMount() {
    let idString = window.location.href;
    var str_array = idString.split('/');
    let id = str_array[str_array.length - 1];
    if (id !== 'CreatePages') {
      if ((id = checkParameters(id, "Number"))) {
        this.setState({ isCreatePage: false });
      }
    }
    else {
      this.setState({ isCreatePage: true });
    }

    this.getAllMainModules();
    let detail = this.state.pageData;
    detail["pageDescription"] = "";
    this.setState(
      { pageData: { ...detail }, currentURL: window.location.href },
      () => {
        if (id && id > 0) {
          this.getPageDetailByPagesDetailsId(id);
        } else {
          this.getAllDropdownsForPages();
        }
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentURL !== window.location.href) {
      this.resetPageState();
    }
  }

  resetPageState = () => {
    let idString = window.location.href;
    var str_array = idString.split('/');
    let id = str_array[str_array.length - 1];
    let isCreateFlag = false;
    if (id !== 'CreatePages') {
      id = isNaN(id) ? 0 : Number(id);
      isCreateFlag = false;
    }
    else {
      isCreateFlag = true;
    }
    this.setState({
      pageData: {},
      components: [],
      currentURL: window.location.href,
      isCreatePage: isCreateFlag
    });
  };

  getAllDropdownsForPages = () => {
    this.PagesManagementServices.getAllDropdownsForPages().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        var componentsArr = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "PageManagementComponents"
        );
        var subPageOfSubModule = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "PageNamesForSubModule"
        );
        componentsArr.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        this.setState({ componentsArr: componentsArr, subPageOfSubModule: subPageOfSubModule }, () => {
          let pagename = this.state.pageName
          let compArr = this.state.componentsArr
          let compArr2 = []
          if (pagename === 'AnnualReport') {
            compArr2 = compArr.filter(x => x.name === "Rich Text With Image")
          } else if (pagename === 'BenefitsAndFAQs') {
            compArr2 = compArr.filter(x => x.name === "Rich Text")
          }
          this.setState({ componentsArr : compArr2 })
        });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  addEditPartialPage = (pagesDetailsId) => {
    var request = {};
    request.pagesDetailsId = pagesDetailsId;
    request.componentList = [];
    let componentList = [...this.state.components];
    request.pagesDetailsIds = '';
    let pagesDetailsIdArr = [];
    let isImageComponentValid = 0;
    componentList.forEach((value, index) => {
      const data = { ...this.state.components[index] };
      if (value.componentTypeId === 1) {
        var re = new RegExp('<a href="([^"]+)"', "g");
        var result = data.componentContent.replace(
          re,
          '<a href=\'$1\' target="_blank" rel="noopener noreferrer"'
        );
        data.componentContent = result;
      }
      if (value.componentTypeId === 2) {
        var res = new RegExp('<a href="([^"]+)"', "g");
        var result1 = data.componentContent.data.replace(
          res,
          '<a href=\'$1\' target="_blank" rel="noopener noreferrer"'
        );
        data.componentContent.data = result1;
      }
      if (
        value.componentTypeId === 2 ||
        value.componentTypeId === 3 ||
        value.componentTypeId === 4 ||
        value.componentTypeId === 5
      ) {
        if (value.componentTypeId === 4) {
          value.componentContent.forEach((image, imageIndex) => {
            if (Number(image.width) > 1200) {
              isImageComponentValid++;
            }
          });
        }
        data.componentContent = JSON.stringify(data.componentContent);
      }
      pagesDetailsIdArr.push(value.partialPageId);
      request.componentList.push(data);
    });
    if (pagesDetailsIdArr.length > 0) {
      request.pagesDetailsIds = pagesDetailsIdArr.join([',']);
    }
    if (isImageComponentValid === 0) {
      this.PagesManagementServices.addEditPartialPage(request).then(
        (response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (
              response.responseItem.responseContent.errorType === "EXISTS"
            ) {
              this.swalServices.Warning(ErrorMessage.Already_Exist.replace("{0}", "Component name"));
            }
            else {
              this.swalServices.Success("Components inserted successfully.");
              this.setState({ route: "/ViewPages" });
            }
          } else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoading: false });
        }
      );
    }
    else {
      this.swalServices.Alert("Please enter image width less than 1200px.");
    }
  };

  getPartialPageComponentsById = (pageDetailsId) => {
    this.PagesManagementServices.getPartialPageComponentsById(
      pageDetailsId
    ).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent.length > 0) {
          response.responseItem.responseContent.forEach((value, index) => {
            if (
              value.componentTypeId === 2 ||
              value.componentTypeId === 3 ||
              value.componentTypeId === 4 ||
              value.componentTypeId === 5
            ) {
              value.componentContent = JSON.parse(value.componentContent);
            }
            if (this.state.pageData.pageTypeId === 3) {
              value.componentName = this.state.pageData.pageName + '_' + value.partialPageId;
            }
          });
          if (response.responseItem.responseContent[0].pageName) {
            var pageTitle =
              response.responseItem.responseContent[0].pageName;
          }
          this.setState({ pageName: pageTitle });
          this.setState(
            { components: response.responseItem.responseContent },
            () => {
              this.getAllDropdownsForPages();
              this.setState({
                components: response.responseItem.responseContent,
              });
            }
          );
        }
        else {
          this.getAllDropdownsForPages();
        }

      } else {
        this.getAllDropdownsForPages();
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  saveMainImage = (file, listIndex, imageIdentity, componentIndex) => {
    this.setState({ isLoaderEnabled: true });
    file.storagePath = imageIdentity;
    this.commonServices.storeBase64Image(file).then((response) => {
      if (response != null) {
        this.swalServices.Success("File Uploaded");
        let detail = this.state.components;
        if (file.storagePath === "\\PageManagement\\ListComponent") {
          // var arr = listIndex.split("_");
          // if (arr.length === 2) {
          detail[componentIndex].componentContent[0].image =
            response.fileName;
          // }
        } else if (file.storagePath === "\\PageManagement\\ImageComponent") {
          var arr = listIndex.split("_");
          if (arr.length === 2) {
            detail[componentIndex].componentContent[arr[arr.length - 1]].image =
              response.fileName;
          }
        } else if (file.storagePath === "\\PageManagement\\ObjectComponent") {
          var arr2 = listIndex.split("_");
          if (arr2.length === 2) {
            detail[componentIndex].componentContent[arr2[arr2.length - 1]].value =
              response.fileName;
          }
        } else if (file.storagePath === "\\PageManagement\\RichTextWithImage") {
          detail[componentIndex].componentContent.image = response.fileName;
        }
        this.setState({ components: detail }, () =>
          this.setState({ isLoaderEnabled: false })
        );
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  addComponent = () => {
    if ((this.state.pageData.pagesMainModuleId > 0 && this.state.pageData.pagesSubModuleId > 0) || this.state.pageData.pageTypeId === 3) {
      var componentArr = [...this.state.components];
      if (this.state.pageData.selectedComponent > 0) {
        let componentType = "";
        if (this.state.pageData.selectedComponent === 2) {
          componentType = { image: "", data: "", imageOnLeft: true };
        } else if (this.state.pageData.selectedComponent === 3) {
          componentType = [{ image: "", listName: "", listUrl: '' }];
        } else if (this.state.pageData.selectedComponent === 4) {
          componentType = [{ image: "", altTag: "", width: 100, height: 100 }];
        } else if (this.state.pageData.selectedComponent === 5) {
          componentType = [{ fieldName: "", fieldType: "Text", value: "" }];
        }
        let pageName = ""
        let uniqueId = '';
        if (this.state.pageData.pageTypeId === 1) {
          pageName = this.state.subModules
            .find((x) => x.id === this.state.pageData.pagesSubModuleId)
            .name.replace(/\s/g, "");
          if (this.state.isPrivilegePage === true) {
            if (componentArr.length > 0) {
              let ids = componentArr[(componentArr.length - 1)].componentName.split('_')
              if (!Number(ids[ids.length - 1])) {
                ids = componentArr[(componentArr.length - 2)].componentName.split('_');
              }
              uniqueId = Number(ids[ids.length - 1]) + 1;
            }
            else {
              uniqueId = 1;
            }
          }
        }
        else if (this.state.pageData.pageTypeId === 3) {
          if (componentArr.length > 0) {
            let ids = componentArr[(componentArr.length - 1)].componentName.split('_')
            uniqueId = Number(ids[ids.length - 1]) + 1;
          }
          else {
            uniqueId = 1;
          }
          pageName = this.state.pageData.pageName.replace(/\s/g, "");
        }

        var component = {
          partialPageId: 0,
          componentId: 0,
          componentName: `${pageName}_${uniqueId}`,
          componentTypeId: this.state.pageData.selectedComponent,
          componentContent: componentType,
          pagesDetailsId: this.state.pageDetailsId,
        };
        componentArr.push(component);
        this.setState({ components: componentArr });
      }
    }
    else {
      this.swalServices.Alert('Please select sub module.');
    }
  };

  validateComponents = () => {
    // let isValid = true;
    let componentArr = [...this.state.components];
    let validArr = [];
    componentArr.forEach((value, index) => {
      if (!required(value.componentName)) {
        var obj = {
          componentIndex: index,
          fieldName: "componentName",
          errorMessage: "Please enter component name",
        };
        validArr.push(obj);
      }
      this.setState({ components: componentArr });
      if (!required(value.componentContent)) {
        var obj2 = {
          componentIndex: index,
          fieldName: "componentContent",
          errorMessage: "Please enter component content",
        };
        validArr.push(obj2);
      }
    });
    if (validArr.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  setFilterParametersPartialPage = (id, drpIdentity) => {
    let detail = this.state.pageData;
    if (drpIdentity === "Components") {
      detail["selectedComponent"] = id;
    }
    this.setState({ pageData: { ...detail } });
  };

  handleChangePartialPage = (event, identity) => {
    let value = event.target.value;
    value = value.replace(/\s/g, "");
    const name = event.target.name;
    let detail = this.state.components;
    if (name === "componentName") {
      let pageName = '';
      if (this.state.pageData.pageTypeId === 1) {
        pageName = this.state.subModules
          .find((x) => x.id === this.state.pageData.pagesSubModuleId)
          .name.replace(/\s/g, "");
      }
      else {
        pageName = this.state.pageData.pageName;
      }
      if (value.includes(pageName)) {
        detail[identity][name] = value;
      } else {
        detail[identity][name] = `${pageName}_${value}`;
      }
    } else {
      detail[identity][name] = value;
    }
    this.setState({ components: detail });
  };

  handleChangeInList = (event, identity, listIndex) => {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.components;
    detail[identity].componentContent[listIndex][name] = value;
    this.setState({ components: detail });
  };

  handleChangeInImageComp = (event, identity, imageIndex) => {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.components;
    detail[identity].componentContent[imageIndex][name] = value;
    this.setState({ components: detail });
  };

  setEditorData = (data, index, componentName, editor) => {
    if (typeof data === 'string') {
      if (componentName === "RichText") {
        let detail = this.state.components;
        // var re = new RegExp('<a href="([^"]+)"', "g");
        // var result = data.replace(
        //   re,
        //   '<a href=\'$1\' target="_blank" rel="noopener noreferrer"'
        // );
        detail[index].componentContent = data;
        this.setState({ components: detail });
      } else if (componentName === "RichTextWithImage") {
        let detail = this.state.components;
        // var re = new RegExp('<a href="([^"]+)"', "g");
        // var result = data.replace(
        //   re,
        //   '<a href=\'$1\' target="_blank" rel="noopener noreferrer"'
        // );
        detail[index].componentContent.data = data;
        this.setState({ components: detail });
      }
    }
  };

  addNewListItem = (componentIndex) => {
    var componentArr = [...this.state.components];
    var newListItem = { image: "", listName: "" };
    componentArr[componentIndex].componentContent.push(newListItem);
    this.setState({ components: componentArr });
  };

  addNewImage = (componentIndex) => {
    var componentArr = [...this.state.components];
    var newImageObj = { image: "", altTag: "", width: 100, height: 100 };
    componentArr[componentIndex].componentContent.push(newImageObj);
    this.setState({ components: componentArr });
  };

  addNewFieldInObject = (listIndex) => {
    var componentArr = [...this.state.components];
    var newField = { fieldName: "", fieldType: "Text", value: "" };
    componentArr[listIndex].componentContent.push(newField);
    this.setState({ components: componentArr });
  };

  removeFieldInObject = (fieldIndex, listIndex) => {
    var componentArr = [...this.state.components];
    componentArr[listIndex].componentContent.splice(fieldIndex, 1);
    this.setState({ components: componentArr });
  };

  handleChangeInObject = (name, value, fieldIndex, listIndex) => {
    var componentArr = [...this.state.components];
    componentArr[listIndex].componentContent[fieldIndex][name] = value;
    this.setState({ components: componentArr });
  };


  addPageNameForSubModule = () => {
    if (this.isValidateAllFieldsAddPage()) {
      this.PagesManagementServices.addPageNameForSubModule(
        this.state.subModulePageDetails
      ).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success("Page name inserted successfully.");
          this.setState({ setAddPageNameModel: false });
          // this.GetSubModulesListByMainModuleId(
          //   this.state.subModulePageDetails.pagesSubModuleId
          // );
          let res = {
            pageNameId: 0,
            pagesSubModuleId: 0,
            pageName: "",
            pagesMainModuleId: 0,
          };
          let reset = {
            pagesMainModuleId: 0,
            pagesSubModuleId: 0,
          };
          this.setState({ subModulePageDetails: res, pageData: reset, subModules: [], subPageOfSubModule: [] });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
    }
  };
  getSubPagesListForSubModuleById = (subMenuId, page = null) => {
    this.PagesManagementServices.getSubPagesListForSubModuleById(
      subMenuId
    ).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (page) {
          this.setState(
            { subPageOfSubModule: response.responseItem.responseContent },
            () => {

              if (page && page.pageName != null) {

                let data = this.state.subPageOfSubModule.find(
                  (x) => x.name.toLowerCase().replace(/\s/g, "") === page.pageName
                );
                if (data) {
                  let details = this.state.subModulePageDetails;
                  details.pageUrl = data.name.toLowerCase().replace(/\s/g, "");
                  details.isRequireAddNewPage = true;
                  this.setState({ subModulePageDetails: details });

                  let detail = page;
                  detail.pageNameId = data.id;
                  page = detail;

                }
              }
              this.setState({ pageData: page }, () => {
                this.getAllDropdownsForPages();
                if (this.state.pageData.pagesDetailsId > 0) {
                  this.getPartialPageComponentsById(
                    this.state.pageData.pagesDetailsId
                  );
                }
              });
            }
          );
        } else {
          this.setState({
            subPageOfSubModule: response.responseItem.responseContent,
          });
        }
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };
  deletePageNamesById = (value) => {
    if (this.state.pageData.pageNameId > 0) {
      let request = {
        id: this.state.pageData.pageNameId,
        deletedBy: 115,
        appId: 15,
      };
      this.swalServices
        .Confirm(
          CommonSuccessMessages.Confirm_Question.replace("{0}", "Sub Page"),
          "Do you want to delete this sub page?",
          CommonSuccessMessages.Yes_Text,
          CommonSuccessMessages.No_Text
        )
        .then((response) => {
          if (response) {
            this.PagesManagementServices.deletePageNamesById(request).then(
              (response) => {
                if (response.statusCode === 200) {
                  this.swalServices.Success("Sub page deleted successfully.");
                  this.setState({ subPageOfSubModule: [] });
                  this.setState({ route: "/ViewPages" });
                } else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isLoading: false });
              }
            );
          }
        });
    }
  };

  handleChangeCheckBox = (e) => {
    const { checked } = e.target;

    let details = this.state.subModulePageDetails;
    if (checked) {
      details.isRequireAddNewPage = true;
    } else {
      details.isRequireAddNewPage = false;
    }
    this.setState({ subModulePageDetails: details });
  };

  swapSection = (index) => {
    let componentList = this.state.components;
    componentList[index].componentContent.imageOnLeft =
      !componentList[index].componentContent.imageOnLeft;
    this.setState({ components: componentList });
  };

  removeImageFromImagesComponent = (imageIndex, ComponentIndex) => {
    var components = [...this.state.components];
    if (imageIndex > 0) {
      components[ComponentIndex].componentContent.splice(imageIndex, 1);
      this.setState({ components: components });
    }
  }

  removeItemFromListComponent = (itemIndex, ComponentIndex) => {
    var components = [...this.state.components];
    if (itemIndex > 0) {
      components[ComponentIndex].componentContent.splice(itemIndex, 1);
      this.setState({ components: components });
    }
  }

  removeComponent = (componentIndex) => {
    var components = [...this.state.components];
    components.splice(componentIndex, 1);
    this.setState({ components: components });
  }

  showBanners = () => {
    this.setState({ showBanners: !this.state.showBanners }, () => {
      let page = this.state.pageData;
      if (this.state.showBanners === true) {
        page.bannerPositionId = 1
      }
      else {
        page.bannerPositionId = null;
      }
      this.setState({ pageData: page });
    });
  }

  render() {
    if (this.state.route != null && this.state.route !== "") {
      return <Navigate to={this.state.route} />;
    }
    return (
      <>
        <div className="main-body-section">
          <div className="bg-white">
            <div className=" heading-part px-7 py-4 border-b">
              <h4 className="small-title font-bold theme-color">
                Basic Information 
              </h4>
            </div>
            <div className="px-6 pt-10">
              <div className="grid grid-cols-12 gap-6 w-full">
                {this.state.pageData.pageTypeId === 3 ?
                  <>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                      <h2 className="text-xl theme-color mb-1">
                        Main Menu
                        <span className="text-[#C00000]">*</span>
                      </h2>
                      <DropdownSelect
                        drpIdentity={"MainMenu"}
                        optionArray={this.state.mainModules}
                        setFilterParameters={this.setFilterParameters}
                        value={this.state.pageData.pagesMainModuleId}
                        disabled={true}
                      />
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                      <h2 className="text-xl theme-color mb-1 ">
                        Sub Menu
                        <span className="text-[#C00000]">*</span>
                      </h2>
                      <DropdownSelect
                        drpIdentity={"SubMenu"}
                        optionArray={[]}
                        setFilterParameters={this.setFilterParameters}
                        value={0}
                        disabled={true}
                      />
                    </div>
                  </>
                  :
                  <>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                      <h2 className="text-xl theme-color mb-1">
                        Main Menu
                        <span className="text-[#C00000]">*</span>
                      </h2>
                      <DropdownSelect
                        drpIdentity={"MainMenu"}
                        optionArray={this.state.mainModules}
                        setFilterParameters={this.setFilterParameters}
                        value={this.state.pageData.pagesMainModuleId}
                        disabled={true}
                      />
                      <div>
                        {!this.state.pageData.pagesMainModuleId ? (
                          <ValidationText error={"Please select main module"} />
                        ) : null}
                      </div>
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                      {this.state.subModules ? (
                        <>
                          <h2 className="text-xl theme-color mb-1 ">
                            Sub Menu
                            <span className="text-[#C00000]">*</span>
                          </h2>
                          <DropdownSelect
                            drpIdentity={"SubMenu"}
                            optionArray={this.state.subModules}
                            setFilterParameters={this.setFilterParameters}
                            value={this.state.pageData.pagesSubModuleId}
                            disabled={true}
                          />
                          <div>
                            {!this.state.pageData.pagesSubModuleId ? (
                              <ValidationText error={"Please select sub module"} />
                            ) : null}
                          </div>
                        </>
                      ) : null}
                    </div>
                  </>
                }

              </div>
              {/* sub page Start */}
              <div className="grid grid-cols-12 gap-6 w-full mt-6 pb-6">
                <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 input-sec-texts">
                  <h2 className="text-xl theme-color mb-1">
                    Page Type
                  </h2>

                  <DropdownSelect
                    drpIdentity={"PageTypes"}
                    optionArray={this.state.pageTypes}
                    setFilterParameters={this.setFilterParameters}
                    value={this.state.pageData.pageTypeId}
                    disabled={true}
                  />
                </div>


                <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 input-sec-texts">
                  <h2 className="text-xl theme-color mb-1">
                    Meta Title
                    <span className="text-[#C00000]">*</span>
                  </h2>
                  <input
                    className="w-full"
                    placeholder="Meta Title"
                    type="text"
                    id="metaTitle"
                    name="metaTitle"
                    onChange={(event) => this.handleChange(event, "PageData")}
                    onBlur={() => this.validateField("metaTitle")}
                    value={this.state.pageData.metaTitle}
                  />
                  <div>
                    <ValidationText
                      error={this.state.validState.error.metaTitle}
                    />
                  </div>
                </div>

                <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 input-sec-texts">
                  <h2 className="text-xl theme-color mb-1">
                    Meta Keywords
                    <span className="text-[#C00000]">*</span>
                  </h2>
                  <input
                    className="w-full"
                    placeholder="Meta Keywords"
                    type="text"
                    id="meta_keywords"
                    name="metaKeywords"
                    onChange={(event) => this.handleChange(event, "PageData")}
                    onBlur={() => this.validateField("metaKeywords")}
                    value={this.state.pageData.metaKeywords}
                  />
                  <div>
                    {" "}
                    <ValidationText
                      error={this.state.validState.error.metaKeywords}
                    />{" "}
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 input-sec-texts">
                  <h2 className="text-xl theme-color mb-1">
                    Meta Description
                    <span className="text-[#C00000]">*</span>
                  </h2>
                  <textarea
                    className="w-full text-area-space"
                    placeholder="Meta Description"
                    id="meta_description"
                    name="metaDescription"
                    rows="4"
                    onChange={(event) => this.handleChange(event, "PageData")}
                    onBlur={() => this.validateField("metaDescription")}
                    value={this.state.pageData.metaDescription}
                  />
                  <div>
                    {" "}
                    <ValidationText
                      error={this.state.validState.error.metaDescription}
                    />{" "}
                  </div>
                </div>
              </div>
              {this.state.pageData.pageTypeId === 3 ?
                <>
                  <div className="grid grid-cols-12 gap-6 w-full">
                    <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 input-sec-texts">
                      <h2 className="text-xl theme-color mb-1">
                        Page Title
                        {/* <span className="text-[#C00000]">*</span> */}
                      </h2>
                      <input
                        className="w-full"
                        type="text"
                        id="pageName"
                        name="pageName"
                        onChange={(event) => this.handleChange(event, "PageData")}
                        // onBlur={() => this.validateField("metaTitle")}
                        value={this.state.pageData.pageName}
                      />
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 input-sec-texts">
                      <h2 className="text-xl theme-color mb-1">
                        Page URL

                      </h2>
                      <input
                        className="w-full cursor-not-allowed bg-zinc-100"
                        type="text"
                        id="pageName"
                        name="pageName"
                        disabled={true}
                        // onChange={(event) => this.handleChange(event, "PageName")}
                        // onBlur={() => this.validateField("metaTitle")}
                        // value={this.state.subModulePageDetails.pageUrl}
                        value={
                          this.state.pageData.pageNameUrl
                            ? WebURLPath +
                            this.state.pageData.pageNameUrl
                            : WebURLPath
                        }
                      />
                      <div>
                        <ValidationText
                          error={this.state.validState.error.pageName}
                        />
                      </div>
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 input-sec-texts mt-3">
                      <div className="check-box-sec flex">
                        <input
                          id="showBanners"
                          name="showBanners"
                          type="checkbox"
                          className="form-checkbox cursor-pointer "
                          onChange={() => this.showBanners()}
                          checked={this.state.showBanners === true}
                          value={this.state.showBanners}
                        />
                        <h2 className="text-xl theme-color ml-4">
                          {/* Show Banners */}
                          Show Chapters/Committee Banner
                        </h2>

                      </div>
                    </div>
                  </div>

                </>
                : null}


              {/* Sub Page end */}
              <div className="grid grid-cols-12 gap-6 w-full">
              </div>
            </div>
            <div className="px-6 mt-4 pb-7">
              {this.state.pageData.pageTypeId === 1 || this.state.pageData.pageTypeId === 3 ? null : (
                <div className="grid 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-2 sm:grid-cols-1 gap-6">
                  <div
                    className="col-span-12 post-details ck-content page-management-editor"
                    key={"fullPageEditor"}
                  >
                    <h2 className="text-xl theme-color mb-1">
                      Descriptions
                    </h2>
                    <CKClassicEditor
                      key={"fullPageEditor"}
                      onChange={(data, editor) => { this.setPageData(data, editor); }}
                      data={this.state.pageData.pageDescription}
                    />
                    <div>
                      {!this.state.pageData.pageDescription ? (
                        <ValidationText
                          error={"Please enter page description"}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
              {this.state.pageData.pageTypeId === 1 || this.state.pageData.pageTypeId === 3 || this.state.isPrivilegePage === true ? (
                <>
                  <div className=" heading-part py-4 border-b">
                    <h4 className="small-title font-bold theme-color">
                      Add Components
                    </h4>
                  </div>
                  <div className=" pt-5">
                    <div className="grid grid-cols-12 gap-6 w-full">
                      {(this.state.pageData.pageTypeId === 3) || (this.state.pageData.pageTypeId === 1 && this.state.isCreatePage) || this.state.isPrivilegePage === true ?
                        <>
                          <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 input-sec-texts">
                            <h2 className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30">
                              Select Component Type
                            </h2>
                            <DropdownSelect
                              drpIdentity={"Components"}
                              optionArray={this.state.componentsArr}
                              setFilterParameters={
                                this.setFilterParametersPartialPage
                              }
                              value={this.state.pageData.selectedComponent}
                            />
                          </div>
                          <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 pt-12 input-sec-texts">
                            <div className="flex justify-end">
                              <button
                                className=" px-7 py-3 btn-red text-xl border text-white"
                                onClick={() => this.addComponent()}
                              >
                                <div className="flex items-center font-semibold">
                                  <span className="mr-3">
                                    <img src="/plus-round-icon.svg" alt="" />
                                  </span>
                                  <span className="">Add Component</span>
                                </div>
                              </button>
                            </div>
                          </div>
                        </>
                        : null
                      }

                      <div className="col-span-12">
                        <Components
                          componentsArray={this.state.components}
                          iconArray={this.state.iconArray}
                          listTypes={this.state.listTypes}
                          pageData={this.state.pageData}
                          handleChangePartialPage={this.handleChangePartialPage.bind(this)}
                          setEditorData={this.setEditorData.bind(this)}
                          handleChangeInList={this.handleChangeInList.bind(this)}
                          addNewListItem={this.addNewListItem.bind(this)}
                          saveMainImage={this.saveMainImage.bind(this)}
                          handleChangeInImageComp={this.handleChangeInImageComp.bind(this)}
                          addNewImage={this.addNewImage.bind(this)}
                          addNewFieldInObject={this.addNewFieldInObject.bind(this)}
                          handleChangeInObject={this.handleChangeInObject.bind(this)}
                          removeFieldInObject={this.removeFieldInObject.bind(this)}
                          swapSection={this.swapSection.bind(this)}
                          removeImageFromImagesComponent={this.removeImageFromImagesComponent.bind(this)}
                          removeItemFromListComponent={this.removeItemFromListComponent.bind(this)}
                          removeComponent={this.removeComponent.bind(this)}
                          isPrivilegePage={this.state.isPrivilegePage && this.state.isPrivilegePage === true ? true : false}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div className="flex flex-wrap justify-between space-x-10 mt-12">
            <NavLink
              to="/viewpages"
              className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white hover:text-white"
            >
              {" "}
              Back
            </NavLink>
            <button
              className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white"
              onClick={() => this.savePageDetails()}
            >
              Save
            </button>
            {/* } */}
          </div>
          <div className="membership-task-list-process-sec add-page-model-sec">
            <AddNewPageNameForSubModule
              setAddPageNameModel={this.state.setAddPageNameModel}
              setOpenModal={() => {
                this.setState({ setAddPageNameModel: false });
                let req = [];
                this.setState({ subModules: req });
              }}
              subModulePageDetails={this.state.subModulePageDetails}
              mainModules={this.state.mainModules}
              subModules={this.state.subModules}
              handleChange={this.handleChange.bind(this)}
              setFilterParameters={this.setFilterParameters.bind(this)}
              validateField={this.validateFieldAddPage.bind(this)}
              validState={this.state.validStateAddPage}
              addPageNameForSubModule={this.addPageNameForSubModule.bind(this)}
              onClose={(e) => {
                e.stopPropagation();
                this.setState({ setAddPageNameModel: false });
              }}
            />

          </div>
        </div>
      </>
    );
  }
}
export default withParams(EditPages);
