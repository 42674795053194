import React, { Component } from "react";
import Table from "../../../components/table/Table";
import { Link, Navigate } from "react-router-dom";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import IndividualMemberService from "../../../services/axiosServices/apiServices/IndividualMemberService";
import ActionContainer from "../../../components/UIComponent/ActionTableComponent/actionContainer";
import DropdownAction from "../../../components/dropdown/DropdownAction";
import MembershipCorporateServices from "../../../services/axiosServices/apiServices/MembershipCorporateServices";
import SwalServices from "../../../services/swalServices/SwalServices";
import { CommonSuccessMessages } from "../../../utils/Messages";
import { encryptAES } from "../../../utils/Encryption";
//import CommonServices from "../../../services/axiosServices/apiServices/CommonServices";


export default class IndividualMembers extends Component {
  constructor(props) {
    super(props);
    this.individualMemberService = new IndividualMemberService();
    this.membershipCorporateServices = new MembershipCorporateServices();
    this.swalServices = new SwalServices();
    //this.CommonServices = new CommonServices();
    this.state = {
      setDropdownOpen: false,
      setDropdownIndex: 0,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        searchByEmailAddress: "",
        IndividualStatusId: 0,
      },
      totalResultes: "",
      individualMemberList: [],
      actionDropdown: ['Activate', 'Deactivate', 'Update', 'Delete'],

      isLoading: false,
      redirect: null,
      searchByName: "",
      searchByEmailAddress: "",
      statuses: [],
    };
    this.wrapperRef = React.createRef();
    //this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  setFilterParameters = (id) => {
    let detail = this.state.pagination;
    detail.pageNo = 1;
    detail.IndividualStatusId = id;
    this.setState({ pagination: { ...detail } });
    this.getIndividualMemberList();
  }

  getIndividualMemberList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });

    this.individualMemberService.getIndividualMemberList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ individualMemberList: response.responseItem.responseContent.itemList });
        this.setState({ totalResultes: response.responseItem.responseContent.totalCount })
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getIndividualMemberList();
  };

  componentDidMount() {
    this.getIndividualMemberList();
    this.getAllCorporateStatus();
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  redirectToCreateResources = (value) => {
    this.setState({ route: "/EditResource/ResourceId?=" + value });
  };

  redirectToApproveResources = (value) => {
    this.setState({ route: "/PostApproval/resourceId?=" + value });
  };

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ setDropdownOpen: false });
    }
  }

  onDropDownChange = (index) => {
    this.setState({ setDropdownOpen: true });
    this.setState({ setDropdownIndex: index });
  };

  onClose = () => {
    this.setState({ setDropdownOpen: false });
  };

  getAllCorporateStatus = () => {
    this.membershipCorporateServices.getAllCorporateStatus().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ statuses: response.responseItem.responseContent });
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }

  actionClick = (index, value, option) => {
    if (value && value > 0 && option === 'Deactivate') {
      this.deActiveIndividualMember(value);
    }
    if (value && value > 0 && option === 'Activate') {
      this.activeInActiveIndividualMember(value);
    }
    if (value && value > 0 && option === 'Update') {
      this.updateIndividualMember(value);
    }
    if (value && value > 0 && option === 'Delete') {
      this.deleteIndividualMember(value);
    }
  }
  deActiveIndividualMember = (value) => {
    let request = {
      id: value,
      statusId: 7
    }

    this.individualMemberService.activeInActiveIndividualMember(request).then((response) => {
      if (response.statusCode === 200) {

        this.swalServices.Success("Individual member deactivated successfully.");
        this.getIndividualMemberList();
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  }
  activeInActiveIndividualMember = (value) => {

    let request = {
      id: value,
      statusId: 8
    }

    this.individualMemberService.activeInActiveIndividualMember(request).then((response) => {
      if (response.statusCode === 200) {

        this.swalServices.Success("Individual member activated successfully.");
        this.getIndividualMemberList();
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }

  updateIndividualMember = (value) => {
    if (value && value > 0) {
      this.setState({ route: '/CreateIndividualMembers/individualMemberId?=' + encryptAES(value) });
    }
  }

  deleteIndividualMember = (value) => {
    let request = {
      id: value,
      deletedBy: 115,
      appId: 15
    }
    this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Member"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "Member"),
      CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
        if (response) {
          this.individualMemberService.deleteIndividualMember(request).then((response) => {
            if (response.statusCode === 200) {
              this.swalServices.Success("Individual member deleted successfully.");
              this.getIndividualMemberList();
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
          });
        }
      });
  }

  searchByName = (value, identity) => {
    let detail = this.state.pagination;
    if (identity === "searchByName") {
      detail.pageNo = 1;
      detail.pageSize = 10;
      detail.searchText = value;
      this.setState({ pagination: { ...detail } });
    }
    else if (identity === "searchByEmailAddress") {
      detail.pageNo = 1;
      detail.pageSize = 10;
      detail.searchByEmailAddress = value;
      this.setState({ pagination: { ...detail } });
    }
    this.getIndividualMemberList();
  }

  actions = (element, index, value) => {
    return element !== "individualMemberId" ? null : (
      <td>

        <ActionContainer>
          <DropdownAction
            key={index}
            index={index}
            value={value}
            options={this.state.actionDropdown}
            align="right"
            onOptionClick={this.actionClick.bind(this)}
          />
        </ActionContainer>
      </td>
    );
  };

  render() {
    if (this.state.route != null) {
      return <Navigate to={this.state.route} />;
    }
    return (
      <>
        <div className="main-body-section">
          <div className="custom-card shadow-lg mb-10">
            <div className="grid grid-cols-3 gap-6 w-full ">
              <div className="">
                <h2 className="text-xl text-[#757575]">Search by Name</h2>
                <input
                  id="default"
                  className="form-input rounded-none w-full shadow-red py-3 text-lg"
                  type="text"
                  onChange={(e) => this.searchByName(e.target.value, 'searchByName')}
                />
              </div>
              <div className=" ">
                <h2 className="text-xl text-[#757575]">Search by Email Address</h2>
                <input
                  id="default"
                  className="form-input rounded-none w-full shadow-red py-3 text-lg"
                  type="text"
                  onChange={(e) => this.searchByName(e.target.value, "searchByEmailAddress")}
                />
              </div>
              <div className="">
                <h2 className="pl-6  text-xl theme-color">Status</h2>
                <div className="pl-6  border-l-[1px] border-[#757575]">
                  <DropdownSelect
                    //drpIdentity={"MembershipType"}
                    optionArray={this.state.statuses}
                    setFilterParameters={this.setFilterParameters}
                    value={this.state.pagination.IndividualStatusId}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="sm:flex sm:justify-between sm:items-center mb-8 mt-8">
            <div className="mb-4 sm:mb-0">
              <h1 className="table-title-txt theme-color font-bold">
                {`Total:${this.state.totalResultes}`}
              </h1>
            </div>
            <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3">
              <Link to="/CreateIndividualMembers">
                <button className="btn btn-red text-white font-semibold">
                  <span className="text-xl text-white font-semibold tracking-normal">
                    Create New
                  </span>
                </button>
              </Link>
            </div>
          </div>
          <main className="pb-10">
            <div className="w-full mx-auto">
              <div className="relative">
                <div>
                  <div className=" mt-6" onMouseLeave={() => this.onClose()}>
                    <Table
                      columns={[
                        { name: "name", title: "Name" },
                        { name: "email", title: "Email Address" },
                        { name: "statusName", title: "Status" },
                        { name: "individualMemberId", title: "Action" },
                      ]}
                      pagination={this.state.pagination}
                      rows={this.state.individualMemberList}
                      sortingColumns={["name", "statusName"]}
                      isLoading={this.state.isLoading}
                      totalResultes={this.state.totalResultes}
                      totalCount={this.state.totalResultes}
                      setPagination={this.setPagination.bind(this)}
                      customScope={[
                        {
                          column: "individualMemberId",
                          renderTableData: this.actions.bind(this),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>

        {/* <div className="sm:flex sm:justify-between sm:items-center mb-8 mt-8">
          <div className="mb-4 sm:mb-0">
            <h1 className="table-title-txt theme-color font-bold">
              {`Total:${this.state.totalResultes}`}
            </h1>
          </div>
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3">
            <Link to="/CreateIndividualMembers">
              <button className="btn btn-red text-white">
                <span className="text-xl text-white font-semibold tracking-normal">
                  Create New
                </span>
              </button>
            </Link>
          </div>
        </div> */}


      </>
    );
  }
}