import React, { useEffect } from "react";
import ModalBasic from "../../pages/component/ModalBasic";
import ValidationText from "../../utils/validation/ValidationText";
import Selector from "../dropdown/commonDropdown/Selector";
import Option from "../dropdown/multiSelectDropdownOption/MultiSelectOption";

const AddEditStrategicMemberModal = (props) => {

    useEffect(() => {
       
      }, [props.strategicMemberDetails]);
    
    return (
        <ModalBasic
            id="add-edit-strategic-member-modal"
            modalOpen={props.setAddEditStrategicMember}
            setModalOpen={props.setOpenModal}
            title=""
            noCloseIcon={false}
        >
            <>
                <div className="bg-white pb-9">
                    <div className=" heading-part px-7 pb-4 border-b">
                        <h4 className="small-title font-bold text-[#c00000]">
                            Add/Edit Strategic Member
                        </h4>
                    </div>
                    <div className="filter-info grid grid-cols-12 gap-6 p-8">
                        <div className="filter-types xl:col-span-6 lg:col-span-6 col-span-12 pr-12">
                            <label
                                className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                htmlFor="default"
                            >
                                Company Name <span className="text-[#c00000]">*</span>
                            </label>
                            {/* <DropdownSelect
                                drpIdentity={"CompanyName"}
                                optionArray={props.companyForStrategicMember}
                                setFilterParameters={props.setFilterParameters}
                                value={props.strategicMemberDetails.corporateMemberId}
                            /> */}
                            <Selector
                                drpIdentity="CompanyName"
                                options={props.companyForStrategicMember}
                                // isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                // components={{ Option }}
                                onChange={props.setFilterParameters}
                                allowSelectAll={false}
                                value={props.companyForStrategicMember.filter(country => country.value === props.strategicMemberDetails.corporateMemberId)}
                            />
                            <ValidationText error={props.validState.error.corporateMemberId} />
                        </div>
                        {props.projectId === null ?
                            null
                            :
                            <>
                                <div className="filter-types xl:col-span-6 lg:col-span-6 col-span-12 pr-12">
                                    <label
                                        className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                        htmlFor="default"
                                    >
                                        Company Officer <span className="text-[#c00000]">*</span>
                                    </label>
                                    {/* <DropdownSelect
                                    drpIdentity={"CompanyOfficerName"}
                                    optionArray={props.officerArr}
                                    setFilterParameters={props.setFilterParameters}
                                    value={props.strategicMemberDetails.individualMemberId}
                                /> */}
                                    <Selector
                                        drpIdentity="CompanyOfficerName"
                                        options={props.officerArr}
                                        // isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        components={{ Option }}
                                        onChange={props.setFilterParameters}
                                        allowSelectAll={false}
                                        value={props.officerArr.find(country => country.value === props.strategicMemberDetails.individualMemberId)}
                                    />
                                    <ValidationText error={props.validState.error.individualMemberId} />

                                </div>
                            </>
                        }
                        {/* <div className="filter-types xl:col-span-4 lg:col-span-4 col-span-12 pr-12">
                            <label
                                className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                htmlFor="default"
                            >
                                Company Officer <span className="text-[#c00000]">*</span>
                            </label>
                            <DropdownSelect
                                drpIdentity={"CompanyOfficerName"}
                                optionArray={props.officerArr}
                                setFilterParameters={props.setFilterParameters}
                                value={props.strategicMemberDetails.individualMemberId}
                            />
                            <ValidationText error={props.validState.error.individualMemberId} />

                        </div> */}
                        <div className="xl:col-span-3 lg:col-span-3 col-span-12">
                            <div className="flex items-center justify-center h-full ">
                                {/* <Link to="/AddEditStrategicMember"> */}
                                <button className="btn btn-red text-white font-semibold link mt-7"
                                    onClick={() => props.addStrategicMember()}
                                >
                                    <span className="text-xl text-white font-semibold tracking-normal">
                                        Add member
                                    </span>
                                </button>
                                {/* </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </ModalBasic>
    );
}
export default AddEditStrategicMemberModal;