import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import MyEditor from "../../components/ckEditor/Editor";
import SwalServices from "../../services/swalServices/SwalServices";

class TestEditorPage extends Component {
    constructor(props) {
        super(props);
        this.swalServices = new SwalServices();
        this.state = {
            bannerManagement: [{ id: 1, name: 'Chapters/Committee' }, { id: 2, name: 'Events' }, { id: 3, name: 'Business Opportunities' }, { id: 4, name: 'Articles' }],
            pageData: {
                pagesDetailsId: 0,
                pagesMainModuleId: 0,
                pagesSubModuleId: 0,
                pageTypeId: 3,
                pageDescription: "",
                metaTitle: "",
                metaDescription: "",
                metaKeywords: "",
                pagesTypes: 0,
                fullPageCKEditorkey: "fullPageEditorMount",
                pageName: "",
                pageNameId: 0,
                pageNameUrl: "",
                bannerPositionId: 0
            },
            route: "",
        };
    }

    componentDidMount() {
        let detail = this.state.pageData;
        detail["pageDescription"] = '';
        this.setState({ pageData: detail });
    }

    setPageData = (data) => {
        let detail = this.state.pageData;
        detail["pageDescription"] = data;
        this.setState({ pageData: detail });
    };

    render() {
        if (this.state.route != null && this.state.route !== "") {
            return <Navigate to={this.state.route} />;
        }
        return (
            <>
                <div className="main-body-section">
                    <div className="bg-white">
                        <div className=" heading-part px-7 py-4 border-b">
                            <h4 className="small-title font-bold theme-color">
                                Basic Information
                            </h4>
                        </div>
                        <div className="px-6 mt-4 pb-7">
                                <div className="grid 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-2 sm:grid-cols-1 gap-6">
                                    <div
                                        className="xl:col-span-12 post-details ck-content page-management-editor"
                                        key={"fullPageEditor"}
                                    >
                                        <h2 className="text-xl theme-color mb-1">
                                            Descriptions
                                        </h2>
                                        <MyEditor
                                            key={"fullPageCKEditor"}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                this.setPageData(data);
                                            }}
                                            data={this.state.pageData.pageDescription}
                                        />
                                    </div>
                                </div>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}
export default TestEditorPage;
