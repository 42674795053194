import moment from "moment/moment";
import React, { useState } from "react";

function PastApplication(props) {

    return (
        <div className="w-full">
            <React.Fragment>
                <div className="px-5 2xl:py-5 lg:py-5 2xl:pt-0 lg:pt-0 bg-white border-b border-[#f3f3f3]">
                    <div className="grid grid-cols-12 gap-6 w-full">
                        <div className="col-span-12 flex justify-left mt-4 ml-8">
                            <button onClick={() => props.getAllDetailsForGeneratePDF(props.memberId)} className="text-xxl text-[#c00000] tracking-normal underline font-bold cursor-pointer" >
                                Generate PDF
                            </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </div>
    );
}

export default PastApplication; 
