import React, { Component } from "react";
import SectionWithBorder from "../../../components/UIComponent/SectionBorder";
import Row3Input from "../../../components/membership/membershipPricing/Row3Input";
import MembershipPricingServices from "../../../services/axiosServices/apiServices/MembershipPricingServices";
import { NavLink } from "react-router-dom";
import TextInput from "../../../components/UIComponent/TextInput";
import SwalServices from "../../../services/swalServices/SwalServices";

import {
  isValidForm,
  validate,
} from "../../../utils/validation/CommonValidator";
import { CommonValidationMessages } from "../../../utils/Messages";
import { getAuthProps } from "../../../utils/AuthenticationLibrary";
import MTOServices from "../../../services/axiosServices/apiServices/MTOServices";

export default class MTOPricing extends Component {
  constructor(props) {
    super(props);
    this.membershipPricingServices = new MembershipPricingServices();
    this.mtoServices = new MTOServices();
    this.swalServices = new SwalServices();

    this.state = {
      mtoPricing: {
        mtoPricingId: 0,
        mtoTier: '',
        membershipTypeId: 0,
        ordinarySubscriptionPrice: 0,
        qualifyingSubscriptionPrice: 0,
        mtoPricingData: [],
      },
      validationRule: {
        ordinarySubscriptionPrice: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        qualifyingSubscriptionPrice: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ]
      },
      validState: {
        isValid: true,
        error: {},
      },
      isLoading: false
    };
  }
  handleChange(event) {
    const value = event.target.value;
    var num = parseFloat(value).toFixed(0);
    const name = event.target.name;
    let detail = this.state.mtoPricing;
    detail[name] = num;
    this.setState({ mtoPricing: { ...detail } });
  }

  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.mtoPricing,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.mtoPricing,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  // addMembershipPricing = () => {
  //   let isValid = this.isValidateAllFields();
  //   if (isValid) {
  //     this.membershipPricingServices
  //       .addEditMembershipPricing(this.state.mtoPricing)
  //       .then((response) => {
  //         if (response.statusCode === 200 && response.responseItem != null) {
  //           this.swalServices.Success(
  //             "Membership pricing updated successfully."
  //           );
  //         } else {
  //           this.swalServices.Error(response.message);
  //         }
  //       });
  //   }
  // };

  getMTOPricing = () => {
    this.mtoServices.getMTOPricing().then((response) => {
      if (response.statusCode === 200 && response.responseItem.responseContent != null) {
        let details = response.responseItem.responseContent;
        let mtoPricing = this.state.mtoPricing;
        mtoPricing.mtoPricingData = details;
        mtoPricing.ordinarySubscriptionPrice = details[0].subscriptionPrice;
        mtoPricing.qualifyingSubscriptionPrice = details[1].subscriptionPrice;
        this.setState({ mtoPricing: mtoPricing });
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  updateMTOPricingData = () => {
    let request = { ...this.state.mtoPricing };
    request.mtoPricingData[0].subscriptionPrice = request.ordinarySubscriptionPrice;
    request.mtoPricingData[1].subscriptionPrice = request.qualifyingSubscriptionPrice;
    this.mtoServices.updateMTOPricingData(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem.responseContent != null) {
        let details = response.responseItem.responseContent;
        this.setState({ mtoPricing: details })
        this.swalServices.Success("MTO Pricing Updated Successfully!");
        this.getMTOPricing();
      }
      else {
        this.swalServices.Error(response.message);
      }
    })
  }

  componentDidMount() {
    let cookie = getAuthProps();
    const adminUserId = (cookie && cookie.adminUserId) ? Number(cookie.adminUserId) : 0;
    if (adminUserId > 0) {
      this.setState({ createdBy: adminUserId });
    }
    this.getMTOPricing();
  }

  render() {
    return (
      <>
        <div className="main-body-section">
          <div className="bg-white">
            <div className="heading-part pl-7 py-4 border-b">
              <div className="flex items-center justify-between">
                <h4 className="small-title font-bold theme-color">
                  MTO Pricing Table
                </h4>
              </div>
            </div>

            <div className="custom-card shadow-lg mb-10">
              <div className="">
                <SectionWithBorder
                  column1="MTO Tier"
                  column2="Subscription Fee"
                >
                  <div className="sign-icon-input px-7">
                    <div className="pr-24">
                      <Row3Input
                        className="text-base text-[#757575]"
                        caption="Ordinary Member"
                        column1="Label 1"
                        col1value={this.state.mtoPricing.ordinarySubscriptionPrice}
                        col1FieldName="ordinarySubscriptionPrice"
                        col1InputType="Money"
                        handleChange={this.handleChange.bind(this)}
                        validateField={this.validateField.bind(this)}
                        validState={this.state.validState}
                        error1={this.state.validState.error.ordinarySubscriptionPrice}
                      />
                    </div>
                  </div>
                  <div className="sign-icon-input px-7">
                    <div className="pr-24">
                      <Row3Input
                        className="text-base text-[#757575]"
                        caption="Qualifying Member"
                        column1="Label 1"
                        col1value={this.state.mtoPricing.qualifyingSubscriptionPrice}
                        col1FieldName="qualifyingSubscriptionPrice"
                        col1InputType="Money"
                        handleChange={this.handleChange.bind(this)}
                        validateField={this.validateField.bind(this)}
                        validState={this.state.validState}
                        error1={this.state.validState.error.qualifyingSubscriptionPrice}
                      />
                    </div>
                  </div>
                  <div className="xl:col-span-2 md:col-span-6 col-span-12 mt-3">
                    <div className="col-span-12 flex justify-end">
                      {this.state.isLoading ? (
                        <div className="relative w-[150px]">
                          <button className={"text-xl font-bold text-white btn-blue-border w-full bg-blue-500 p-2 mx-4 rounded-md hover:bg-blue-600 focus:outline-none"}>
                            <span className="absolute left-[15px] top-1/2 transform -translate-y-1/2">
                              <i className="fa fa-spinner fa-spin text-white text-[17px]"></i>
                            </span>
                            <span className="ml-8">
                              Loading
                            </span>
                          </button>
                        </div>
                      ) : (
                        <button
                          className={`text-xl font-bold text-white btn-blue-border w-[150px] bg-blue-500 p-2 mx-4 rounded-md hover:bg-blue-600 focus:outline-none`}
                          onClick={() => this.updateMTOPricingData()}
                        >
                          Update
                        </button>
                      )}
                    </div>
                  </div>

                </SectionWithBorder>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <NavLink to="/Dashboard" className="btn btn-gray text-xl text-white">
              {" "}
              Back
            </NavLink>
            {/* <button
              className="text-lg font-bold btn btn-red text-white"
              onClick={() => this.addMembershipPricing()}
            >
              Save
            </button> */}
          </div>
        </div>
      </>
    );
  }
}
