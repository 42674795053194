import React from "react";
import ModalBasic from "../../component/ModalBasic";

import InputText from "../../../components/inputs/InputText";

function SuperUserLoginModal(props) {
  return (
      <>
      <ModalBasic
        id="show-super-profile-modal"
        modalOpen={props.setLoginToCorporateModal}
        setModalOpen={props.setOpenModal}
        title="Super login"
        >
        <div className="2xl:m-12 lg:m-5 m-0 ">
          <div className="">
            <label className="text-[#757575] xl:text-xl font-bold inline-block pb-4">
              Organisation UEN
              <span className="text-[#C00000]">*</span>
            </label>
            <InputText
              disabled={true}
              placeholder=""
              type="text"
              name="companyUEN"
              value={props.organizationBasicInformation.companyUEN}
              handleChange={props.handleChangeForLoginToCorporateModal}
            // validateField={() => props.validateField("name", props.contributionDetails)}
            />
            {/* <ValidationText error={props.validState.error.name} /> */}
          </div>
          <div className="pt-8">
            <label className="text-[#757575] xl:text-xl font-bold inline-block pb-4">
              Password
              <span className="text-[#C00000]">*</span>
            </label>

            <input
              className="w-full custom-input"
              type="password"
              name="corporateModalPassword"
              value={props.passwordForCorporateModal}
              onChange={(e) => props.handleChangeForLoginToCorporateModal(e, props.identity)}
            // validateField={() => props.validateField("name", props.contributionDetails)
            ></input>
            {/* <ValidationText error={props.validState.error.name} /> */}
          </div>

          <div className="flex justify-center pt-8">
            <button
              className="py-5 btn-red text-white text-xl font bold w-full"
            onClick={() => props.loginToIndividualMemberForCorporateMember()}
            >
              Login
            </button>
          </div>
        </div>
      </ModalBasic>
    </>
  );
}

export default SuperUserLoginModal;
