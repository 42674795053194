import React from 'react'
import EditFieldTable from '../table/EditFieldTable';
function AddEditAgencyOfficer(props) {
  return (
    <div>
      <div className="bg-white pb-7">
        <div className=" heading-part px-7 py-4 border-b">
          <h4 className="small-title font-bold theme-color">
            Add/Edit Agency Officer
          </h4>
        </div>
        <div className="px-6 pt-10">
          <div className="flex justify-end">
            <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3">
              {props.agencyId > 0 ?
                <button
                  className="btn btn-red text-white font-semibold link"
                  onClick={() => { props.onAddEditAgencyOfficer2(); props.resetFields(); props.resetValidState() }}
                >
                  <span className="text-xl text-white font-semibold tracking-normal">
                    Add Agent
                  </span>
                </button>
                : <button
                  disabled={true}
                  className="btn btn-red text-white font-semibold link cursor-not-allowed"
                  onClick={() => {props.onAddEditAgencyOfficer();props.resetValidState()}}
                >
                  <span className="text-xl text-white font-semibold tracking-normal">
                    Add Agent
                  </span>
                </button>}
            </div>
          </div>
          <div className='edit-table-sec mt-7'>
            <EditFieldTable
              agencyOfficerDetails={props.agencyOfficerDetails}
              deleteAgencyOfficer={props.deleteAgencyOfficer}
              addEditOfficer={props.addEditOfficer}
              addNewOfficer={props.addNewOfficer}
              addEditAgencyOfficerDetails={props.addEditAgencyOfficerDetails}
              handleChange={props.handleChange}
              addEditAgencyOfficer={props.addEditAgencyOfficer}
              getAgencyOfficerById={props.getAgencyOfficerById}
              onAddEditAgencyOfficer={props.onAddEditAgencyOfficer}
              onAddEditAgencyOfficer2={props.onAddEditAgencyOfficer2}
              cancelAddEditOfficer={props.cancelAddEditOfficer}
              validState={props.validState}
              validateField={props.validateField}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default AddEditAgencyOfficer