import React from "react";
import moment from "moment";
import { APIURL } from "../../services/axiosServices/ApiEndPoints";

function PreviewResources(props) {

  return (

    <div className="main-body-section ">

    
      <div className="bg-white">
        <div className="px-7 py-4">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <div className="title-text-part">
                <h3 className="title-text font-bold mt-5">
                  {props.resource.resourceName}
                </h3>
              </div>
              <div className='className="resource-details mt-3 ck-editor__main"'>
                <div>
                  <p className="theme-color font-semibold mb-4">


                    <p className=" mt-5">
                      Published {moment(props.resource.PublishDate).format("MMMM YYYY")}
                    </p>
                  </p>
                </div>
              </div>
              <div className="resource-details mt-3 ck-editor__main">

                {props.pageDescription.map((value, key) => {
                  return (
                    <>
                      <p className="theme-active-color font-bold mb-4" key={key}>
                      </p>
                      {value.resourceDescriptionImage ? (
                        <img className="md:px-12 px-0 w-full mb-10" src={APIURL + "Common/GetImage?type=resourcedescription&&fileName=" + value.resourceDescriptionImage} alt=""/>
                      )
                        :
                        null}
                      
                      <p className="theme-color mb-4 text-justify">
                        <div dangerouslySetInnerHTML={{ __html: value.resourceDescription }}></div>
                      </p>
                    </>
                  )
                }
                )}
              </div>
            </div>
           
          </div>
        </div>
      </div>
      <div className="heading-part pt-5 pb-3 px-0 border-b">
        <div className="flex items-center justify-between">
          <div className="left-top-btn">
            <button className="px-7 py-2 btn-sm btn-gray text-lg text-white"
              onClick={() => props.onClosePreview()}
            >
              Back to Edit
            </button>
          </div>
          <div className="right-top-btn">
            <button className="px-7 py-2 btn-sm btn-red text-lg text-white"
              onClick={() => props.addEditResources()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
// }

export default PreviewResources;
