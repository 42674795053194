import React from "react";
import ValidationText from "../../utils/validation/ValidationText";

const MoneyInput = (props) => {
    return (
        <div className="relative dollar-input mb-[10px]">
            <input
                className="w-full outline-none appearance-none"
                // placeholder={props.placeholder}
                id={props.id}
                type="number"
                name={props.name}
                value={props.value}
                onChange={(event) => props.handleChange(event)}
                onBlur={() => props.validateField(props.name)}
                onWheel={(e) => e.target.blur()}
            />
            <div className="absolute top-[92%]">
                <ValidationText error={props.error} />
            </div>
        </div>
    );
}
export default MoneyInput;