import React, { Component } from 'react'
import SwalServices from '../../../services/swalServices/SwalServices';
import LeadershipPositionsServices from '../../../services/axiosServices/apiServices/LeadershipPositionsServices';
import moment from 'moment';
import { Navigate } from 'react-router-dom';
import ValidationText from '../../../utils/validation/ValidationText';
import { isValidForm, validate } from "../../../utils/validation/CommonValidator";
import { CommonValidationMessages } from '../../../utils/Messages';
import MultiSelectDropdown from '../../../components/dropdown/MultiSelectDropdown';
import Option from '../../../components/dropdown/multiSelectDropdownOption/MultiSelectOption';



export default class AddEditLeadershipPosition extends Component {
    constructor(props) {
        super(props)
        this.LeadershipPositionsServices = new LeadershipPositionsServices();
        this.swalServices = new SwalServices();
        this.state = {
            leadershipChapters: [],
            leadershipCommittee: [],
            redirect: null,
            leadershipPosition: {
                leadershipPositionId: 0,
                leadershipName: "",
                sgTechDesignation: "",
                jobDesignation: "",
                companyName: "",
                isBoardOfGovernors: false,
                isCouncil: false,
                isChapters: true,
                isCommittees: false,
                isSteeringCommittees: false,
                periodFrom: "",
                periodTo: "",
                description: "",
                linkedURL: "",
                leadershipImageURL: "",
                statusId: 0,
                chapterIds: "",
                committeesIds: "",
                steeringCommitteesIds: "",
                updatedBy: 0,
                updatedAppId: 0,
                createdBy: 0,
                createdAppId: 0,
                leadershipChapters:[],
                leadershipCommittee : [],
                leadershipSteeringCommitte :[],
            },
            validationRule: {
                leadershipName: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'leadership name'),
                    },
                ],
                sgTechDesignation: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'designation')
                    }
                ],
                jobDesignation: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'job designation')
                    }
                ],
                companyName: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'company name')
                    }
                ],
                periodFrom: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'period from')
                    }
                ],
                periodTo: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'period to')
                    }
                ],
                description: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'description')
                    }
                ],
                linkedURL: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'linked URL')
                    }
                ],
            },
            validState: {
                isValid: true,
                error: {}
            },
        }
    }
    
    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = this.state.leadershipPosition;
        detail[name] = value;
        this.setState({ leadershipPosition: { ...detail } });
    }
    handleChangeInDropdown = (selected,drpIdentity) => {
        var details = this.state.leadershipPosition;

        if(drpIdentity === "Chapters")
        {
            details.leadershipChapters = selected;
            if (details.leadershipChapters.length > 0) {
                let arr1 = details.leadershipChapters.map(function (item) {
                    delete item.bad;
                    return item.value;
                });
                details.chapterIds = arr1.join([',']);
            }
        }
        if(drpIdentity === "Committees")
        {
            details.leadershipCommittee = selected;
            if (details.leadershipCommittee.length > 0) {
                let arr1 = details.leadershipCommittee.map(function (item) {
                    delete item.bad;
                    return item.value;
                });
                details.committeesIds = arr1.join([',']);
            }
        }
        if(drpIdentity === "SteeringCommittee")
        {
            details.leadershipSteeringCommitte = selected;
            if (details.leadershipSteeringCommitte.length > 0) {
                var arr1 = details.leadershipSteeringCommitte.map(function (item) {
                    delete item.bad;
                    return item.value;
                });
                details.steeringCommitteesIds = arr1.join([',']);
            }
        }
        this.setState({ leadershipPosition: details });
    };
    back = () => {
        this.setState({ redirect: "/ManageLeadershipPosition" });
    }

    save = (value) => {
        let detail = this.state.leadershipPosition;
        detail.statusId = value;
        detail.isActive = false;
        detail.updatedBy = 115;
        this.setState({ leadershipPosition: { ...detail } });
        this.updateLeadershipPositions();
        this.setState({ redirect: "/ManageLeadershipPosition" });
    }

    publish = (value) => {
        let detail = this.state.leadershipPosition;
        if (this.isValidateAllFields()) {
            detail.statusId = value;
            detail.isActive = true;
            detail.updatedBy = 115;
            detail.updatedAppId = 15;
            this.setState({ leadershipPosition: { ...detail } });

            this.updateLeadershipPositions();
            this.setState({ redirect: "/ManageLeadershipPosition" });
        }
    }

    addNew = (value) => {
        let detail = this.state.leadershipPosition;
        if (this.isValidateAllFields()) {
            detail.isActive = false;
            detail.statusId = value;
            detail.createdBy = 115;
            detail.createdAppId = 15;
            this.setState({ leadershipPosition: { ...detail } });
            this.addLeadershipPositions();
            this.setState({ redirect: "/ManageLeadershipPosition" });
        }
    }

    addLeadershipPositions = () => {
        this.LeadershipPositionsServices.addLeadershipPositions(this.state.leadershipPosition).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.swalServices.Success("Leadership position inserted successfully.");
            } else {
                this.swalServices.Error(response.message);
            }
        })
    }

    updateLeadershipPositions = () => {
        this.LeadershipPositionsServices.updateLeadershipPositions(this.state.leadershipPosition).then((response) => {
            
            if (response.statusCode === 200 && response.responseItem != null) {
                this.swalServices.Success("Leadership position updated successfully");
            } else {
                this.swalServices.Error(response.message);
            }
        })
    }

    getLeadershipChapters = () => {
        this.LeadershipPositionsServices.getLeadershipChapters().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ leadershipChapters: response.responseItem.responseContent });
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }
    
    getLeadershipCommittee = () => {
        this.LeadershipPositionsServices.getLeadershipCommittee().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ leadershipCommittee: response.responseItem.responseContent });
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }
    getLeadershipPositionById = (id) => {
        this.LeadershipPositionsServices.getLeadershipPositionsById(id).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ leadershipPosition: response.responseItem.responseContent });

            } else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    validateField = (key) => {
        const newValidState = validate(key, this.state.leadershipPosition, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
    }

    isValidateAllFields = () => {
        const newValidState = isValidForm(this.state.leadershipPosition, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    }

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let leadershipPositionId = params.get("");
        if (leadershipPositionId > 0) {
            this.getLeadershipPositionById(leadershipPositionId)
        }

        this.getLeadershipChapters();
        this.getLeadershipCommittee();
    }

    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <div className='main-body-section'>
                <div className='bg-white'>
                    <div className='heading-part px-7 py-4 border-b'>
                        <h4 className='small-title font-bold theme-color'>Member Information</h4>
                    </div>
                    <div className="px-14 py-4">
                        <div className='my-10'>
                            <div className='grid grid-cols-12 gap-6'>
                                <div className='xl:col-span-6'>
                                    <div className='grid grid-cols-12 gap-6'>
                                        <div className=' xl:col-span-9'>
                                            <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="leadershipName">Name <span className='text-[#C00000]'>*</span></label>
                                            <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" type="text" name="leadershipName" onChange={(event) => this.handleChange(event)} value={this.state.leadershipPosition.leadershipName} onBlur={() => this.validateField("leadershipName")} />
                                            <div><ValidationText error={this.state.validState.error.leadershipName} /></div>
                                        </div>
                                        <div className=' xl:col-span-9'>
                                            <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">SLA Designation <span className='text-[#C00000]'>*</span></label>
                                            <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" type="text" name="sgTechDesignation" onChange={(event) => this.handleChange(event)} value={this.state.leadershipPosition.sgTechDesignation} onBlur={() => this.validateField("sgTechDesignation")} />
                                            <div><ValidationText error={this.state.validState.error.sgTechDesignation} /></div>
                                        </div>
                                        <div className=' xl:col-span-9'>
                                            <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Job Designation <span className='text-[#C00000]'>*</span></label>
                                            <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" type="text" name="jobDesignation" onChange={(event) => this.handleChange(event)} value={this.state.leadershipPosition.jobDesignation} onBlur={() => this.validateField("jobDesignation")} />
                                            <div><ValidationText error={this.state.validState.error.jobDesignation} /></div>
                                        </div>
                                        <div className=' xl:col-span-9'>
                                            <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Company <span className='text-[#C00000]'>*</span></label>
                                            <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" type="text" name="companyName" onChange={(event) => this.handleChange(event)} value={this.state.leadershipPosition.companyName} onBlur={() => this.validateField("companyName")} />
                                            <div><ValidationText error={this.state.validState.error.companyName} /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='xl:col-span-6'>
                                    <div className='grid grid-cols-12 gap-6'>
                                        <div className='xl:col-span-3'>
                                        </div>
                                        <div className='xl:col-span-9 '>
                                            <div className='img-logo-upload text-center'>
                                                <img src='/default-img.png' className='w-[291px] h-[291px] object-center object-cover border border-slate-300 mx-auto my-0' alt=''></img>
                                                <button className="px-10 mt-5 py-2 btn-red-border text-xl border text-[#C00000]">
                                                    Upload Image
                                                </button>
                                                <p className='mt-5 px-14 text-center font-normal text-2xl theme-color'>
                                                    (Dimension: 600px by 600px Maximum Size: 1MB)
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='xl:col-span-12'>
                                    <div className='grid grid-cols-12 gap-6 mb-10'>
                                        <div className=' xl:col-span-6'>
                                            <div className=' xl:col-span-6'>
                                                <div className="m-3">
                                                    {/* Start */}
                                                    <label className="flex items-center">
                                                        <input type="checkbox" className="form-checkbox"
                                                            name="isBoardOfGovernors"
                                                            onChange={(event) => this.handleChange(event)}
                                                            value={this.state.leadershipPosition.isBoardOfGovernors} />
                                                        <span className="text-xl theme-color ml-4">Board of Governors</span>
                                                    </label>
                                                    {/* End */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className=' xl:col-span-6'>
                                            <div className='xl:col-span-12'>
                                                <div className='grid grid-cols-12 gap-6'>
                                                    <div className=' xl:col-span-8'>
                                                        <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Business Sector <span className='text-[#C00000]'>*</span></label>
                                                        <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" placeholder='1' type="number" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='grid grid-cols-12 gap-6 mt-14 mb-10'>
                                        <div className=' xl:col-span-6'>
                                            <div className=' xl:col-span-6'>
                                                <div className="m-3">
                                                    {/* Start */}
                                                    <label className="flex items-center">
                                                        <input type="checkbox" className="form-checkbox"
                                                            name="isCouncil"
                                                            onChange={(event) => this.handleChange(event)}
                                                            value={this.state.leadershipPosition.isCouncil} />
                                                        <span className="text-xl theme-color ml-4">Council</span>
                                                    </label>
                                                    {/* End */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className=' xl:col-span-6'>
                                            <div className='xl:col-span-12'>
                                                <div className='grid grid-cols-12 gap-6'>
                                                    <div className=' xl:col-span-8'>
                                                        <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Ordering<span className='text-[#C00000]'>*</span></label>
                                                        <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" placeholder='1' type="number" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='grid grid-cols-12 gap-6 mb-0'>
                                        <div className=' xl:col-span-6'>
                                            <div className="m-3">
                                                {/* Start */}
                                                <label className="flex items-center">
                                                    <input type="checkbox" className=" form-checkbox"
                                                        name="isChapters"
                                                        onChange={(event) => this.handleChange(event)}
                                                        value={this.state.leadershipPosition.isChapters}
                                                    />
                                                    <span className="text-xl theme-color ml-4">Chapters</span>
                                                </label>
                                                {/* End */}
                                            </div>
                                            <div className='xl:col-span-12 pl-12'>
                                                <div className='grid grid-cols-12 gap-6'>
                                                    <div className=' xl:col-span-11'>
                                                        <MultiSelectDropdown
                                                            options={this.state.leadershipChapters}
                                                            isMulti
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            components={{
                                                                Option,
                                                            }}
                                                            onChange={this.handleChangeInDropdown}
                                                            allowSelectAll={true}
                                                            value={this.state.leadershipPosition.leadershipChapters}
                                                            drpIdentity="Chapters"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className=' xl:col-span-6'>
                                            <div className='xl:col-span-12'>
                                                <div className='grid grid-cols-12 gap-6'>
                                                    <div className=' xl:col-span-8'>
                                                        <label className="block text-xl font-medium mb-6 theme-color min-h-30" htmlFor="default">Ordering of AI & HPC Chapter <span className='text-[#C00000]'>*</span></label>
                                                        <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" placeholder='1' type="number" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='xl:col-span-12 mt-10'>
                                                <div className='grid grid-cols-12 gap-6'>
                                                    <div className=' xl:col-span-8'>
                                                        <label className="block text-xl font-medium mb-5 theme-color min-h-30" htmlFor="default">Ordering of Cloud & Data Chapter <span className='text-[#C00000]'>*</span></label>
                                                        <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" placeholder='3' type="number" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-12 gap-6 mb-10'>
                                        <div className=' xl:col-span-6'>
                                            <div className="m-3">
                                                {/* Start */}
                                                <label className="flex items-center">
                                                    <input type="checkbox" className="form-checkbox"
                                                        name="isCommittees"
                                                        onChange={(event) => this.handleChange(event)}
                                                        value={this.state.leadershipPosition.isCommittees}
                                                    />
                                                    <span className="text-xl theme-color ml-4">Committees</span>
                                                </label>
                                                {/* End */}
                                            </div>
                                            <div className='xl:col-span-12 pl-12'>
                                                <div className='grid grid-cols-12 gap-6'>
                                                    <div className=' xl:col-span-11'>
                                                        
                                                        <MultiSelectDropdown
                                                            options={this.state.leadershipCommittee}
                                                            isMulti
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            components={{
                                                                Option,
                                                            }}
                                                            onChange={this.handleChangeInDropdown}
                                                            allowSelectAll={true}
                                                            value={this.state.leadershipPosition.leadershipCommittee}
                                                            drpIdentity="Committees"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-12 gap-6 mb-10'>
                                        <div className=' xl:col-span-6'>
                                            <div className="m-3">
                                                {/* Start */}
                                                <label className="flex items-center">
                                                    <input type="checkbox" className="form-checkbox"
                                                        name="isSteeringCommittees"
                                                        onChange={(event) => this.handleChange(event)}
                                                        value={this.state.leadershipPosition.isSteeringCommittees}
                                                    />
                                                    <span className="text-xl theme-color ml-4">Steering Committees</span>
                                                </label>
                                                {/* End */}
                                            </div>
                                            <div className='xl:col-span-12 pl-12'>
                                                <div className='grid grid-cols-12 gap-6'>
                                                    <div className=' xl:col-span-11'>
                                                           <MultiSelectDropdown
                                                            options={this.state.leadershipCommittee}
                                                            isMulti
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            components={{
                                                                Option,
                                                            }}
                                                            onChange={this.handleChangeInDropdown}
                                                            allowSelectAll={true}
                                                            value={this.state.leadershipPosition.leadershipSteeringCommitte}
                                                            drpIdentity="SteeringCommittee"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-12 gap-6'>
                                        <div className='xl:col-span-12'>
                                            <div className='grid grid-cols-12 gap-6'>
                                                <div className=' xl:col-span-6'>
                                                    <label className="block text-xl font-medium mb-6 theme-color min-h-30" htmlFor="default">Period from <span className='text-[#C00000]'>*</span></label>

                                                    <div className='grid grid-cols-12 gap-6 items-center w-full'>
                                                        <div className=' xl:col-span-5 w-full'>
                                                            <input id="default" className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg" type="date"
                                                                name="periodFrom"
                                                                onChange={(event) => this.handleChange(event)} value={moment(this.state.leadershipPosition.periodFrom).format("yyyy-MM-DD")}
                                                                onBlur={() => this.validateField("periodFrom")}
                                                            />
                                                            <div><ValidationText error={this.state.validState.error.periodFrom} /></div>
                                                        </div>
                                                        <div className=' xl:col-span-2 text-center'>
                                                            <span className='text-2xl theme-color'>to</span>
                                                        </div>
                                                        <div className=' xl:col-span-5 w-full '>
                                                            <input id="default" className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg" type="date"
                                                                name="periodTo"
                                                                onChange={(event) => this.handleChange(event)} value={moment(this.state.leadershipPosition.periodTo).format("yyyy-MM-DD")}
                                                                onBlur={() => this.validateField("periodTo")}
                                                            />
                                                            <div><ValidationText error={this.state.validState.error.periodTo} /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='xl:col-span-12'>
                                    <div className='grid grid-cols-12 gap-6 mb-8'>
                                        <div className=' xl:col-span-8'>
                                            <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Description <span className='text-[#C00000]'>*</span></label>
                                            <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" placeholder='Add Summer Note Hear' type="text"
                                                name="description"
                                                onChange={(event) => this.handleChange(event)} value={this.state.leadershipPosition.description}
                                                onBlur={() => this.validateField("description")}
                                            />
                                            <div><ValidationText error={this.state.validState.error.description} /></div>
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-12 gap-6'>
                                        <div className=' xl:col-span-5'>
                                            <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">LinkedIn URL</label>
                                            <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" placeholder='' type="text"
                                                name="linkedURL"
                                                onChange={(event) => this.handleChange(event)} value={this.state.leadershipPosition.linkedURL}
                                                onBlur={() => this.validateField("linkedURL")}
                                            />
                                            <div><ValidationText error={this.state.validState.error.linkedURL} /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="flex flex-wrap justify-between space-x-10 mt-12">
                    <div>
                        <button className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white" onClick={() => this.back()}>Back</button>

                    </div>
                    {this.state.leadershipPosition.leadershipPositionId ?
                        <div>
                            <button className="w-32 px-7 mr-16 py-2 btn-sm btn-lighblue text-lg text-white" onClick={() => this.save(5)}>Save</button>
                            <button className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white" onClick={() => this.publish(6)}>Publish</button>
                        </div>
                        :
                        <div>

                            <button className="w-32 px-7 mr-16 py-2 btn-sm btn-lighblue text-lg text-white" onClick={() => this.addNew(5)}>Save</button>
                            <button className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white" onClick={() => this.addNew(6)}>Publish</button>
                        </div>
                    }
                </div>
            </div>
        )
    }
}
