import React from "react";
import ModalBasic from "../../component/ModalBasic";

const EscalateModal = (props) => {

    return (
        <ModalBasic
            id="send-invoice-modal"
            modalOpen={props.setSendInvoiceModal}
            setModalOpen={props.setOpenModal}
            title=""
            noCloseIcon={true}
        >
            <div className="text-center pb-2">
                <h1 className="text-4xl text-[#81a3b8] font-bold leading-loose py-4">
                    Clarification Sent
                </h1>
                <h1 className="text-xl text-[#757575] font-normal leading-loose p-8 py-4">
                    Membership Application has been Send For Clarification..
                </h1>
                <div className="flex justify-center">
                    <button
                        className="btn-lighblue text-base text-[#fff] font-bold px-12 py-2 my-4"
                        onClick={props.onClose}
                    >
                        Ok
                    </button>
                </div>

            </div >
        </ModalBasic>
    );
}
export default EscalateModal;