import React, { useState } from "react";
import moment from "moment";
import ActionContainer from "../UIComponent/ActionTableComponent/actionContainer";
import ActionTagButton from "../UIComponent/ActionTableComponent/ActionTagButton";
import ActionDeleteButton from "../UIComponent/ActionTableComponent/ActionDeleteButton";
import DropdownAction from "../dropdown/DropdownAction";

const EventPopup = ({
  event,
  onSave,
  onClose,
  onTagging,
  onDelete,
  actionClick,
  actionDropdown,
  actionDropdown2,
  actionDropdown3,
}) => {
  return (
    <>
      {event ? (
        <div className="event-view">
          <div className="event-tile-date">
            <h5 className="event-title">
              <span className="fa fa-square"></span>
              <div>
                <span>{event.eventName}</span>
                <p className="date-time">{event.eventDate}</p>
              </div>
            </h5>
            <div className="right-action">
              <div className="left-part">
                <ActionTagButton
                  value={event.eventId}
                  id={event.eventId}
                  index={event.eventId}
                  onActionClick={onTagging}
                />
                <ActionDeleteButton
                  value={event.eventId}
                  value2={event.eventTimeSlotId}
                  id={event.eventId}
                  index={event.eventId}
                  onActionClick={onDelete}
                />
              </div>
              <div className="right-part">
                {new Date(event.eventDate).getTime() >= new Date().getTime() ? (
                  <DropdownAction
                    key={event.eventId}
                    value={event.eventId}
                    id={event.eventId}
                    index={event.eventId}
                    options={actionDropdown}
                    align="right"
                    onOptionClick={actionClick}
                  />
                ) : event.status === "Approved " ? (
                  <DropdownAction
                    key={event.eventId}
                    value={event.eventId}
                    id={event.eventId}
                    index={event.eventId}
                    options={actionDropdown3}
                    align="right"
                    onOptionClick={actionClick}
                  />
                ) : (
                  <DropdownAction
                    key={event.eventId}
                    value={event.eventId}
                    id={event.eventId}
                    index={event.eventId}
                    options={actionDropdown2}
                    align="right"
                    onOptionClick={actionClick}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="event-desc">
            <div className="icon-desc">
              <i className="fa fa-list-alt"></i>
              <div className="details">
                <p className="desc">
                  <span>Type :</span> {event.eventTypeName}
                </p>
                <p className="desc">
                  <span>Booking :</span> {event.booking}
                </p>
                <p className="desc">
                  <span>Status :</span> {event.status}
                </p>
                <p className="desc">
                  <span>Hidden :</span> {event.isHidden}
                </p>
              </div>
            </div>
            <div className="action-btn-sec">
              <ActionContainer></ActionContainer>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default EventPopup;
