import {  axiosGetAuthorize,  axiosPostAuthorize } from '../AxiosRequests';
import { GetSGTechTVList, GetAllSGTechTVCategory, GetSGTechTVById, AddEditSGTechTv, DeleteSGTechTV, GetStatusForSGtechTv, SGTechTVFeaturedCheck} from '../ApiEndPoints';

export default class SGTechTVServices {

    async getSGTechTVList(request) {
        return axiosPostAuthorize(GetSGTechTVList, request);
    }
    async getAllSGTechTVCategory() {
        return axiosGetAuthorize(GetAllSGTechTVCategory);
    }
    async getSGTechTVById(request) {
        return axiosGetAuthorize(GetSGTechTVById, request);
    }
    async addEditSGTechTv(request) {
        return axiosPostAuthorize(AddEditSGTechTv, request);
    }
    async deleteSGTechTV(request) {
        return axiosPostAuthorize(DeleteSGTechTV, request);
    }
    async getAllStatus() {
        return axiosGetAuthorize(GetStatusForSGtechTv);
    }
    async sgTechTVFeaturedCheck() {
        return axiosGetAuthorize(SGTechTVFeaturedCheck);
    }
    
}
