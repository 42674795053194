import React from "react";
import { Link } from "react-router-dom";
import DropdownSelect from "../../dropdown/Dropdown";
import DatePicker from "../../datePicker/DatePicker";
import HistoryTable from "../../table/HistoryTable";
import moment from "moment";
import Validation from "../../../utils/validation/ValidationText";
import TextInput from "../../UIComponent/TextInput";
function EngagementTrackingNotes(props) {
  return (
    <div>
      <div className="2xl:py-10 2xl:pt-5 lg:py-7 lg:pt-5">
        <div className="grid grid-cols-12 gap-6 w-full ">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="history-table">
              <HistoryTable
                engagementList={props.engagementList}
                onEdit={props.onEdit}
                isCorporate={props.isCorporate}
              />
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="2xl:col-span-11 lg:col-span-12 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Engagement Type <span className="text-[#c00000]">*</span>
                </h2>

                <DropdownSelect
                  drpIdentity={"EngagementType"}
                  optionArray={props.engagementType}
                  setFilterParameters={props.setFilterParameters}
                  value={
                    props.engagementTrackingAndNotes.corporateEngagementTypeId
                  }

                //value={props.engagementType.find(item => item.id === props.engagementTrackingDetails.corporateEngagementTypeId)}
                />
                <Validation
                  error={props.validState.error.corporateEngagementTypeId}
                />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="2xl:col-span-11 lg:col-span-12 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Engagement Date <span className="text-[#c00000]">*</span>
                </h2>
                <DatePicker
                  name="engagementDate"
                  //value={props.engagementTrackingDetails.engagementDate}
                  handleChange={props.handleChange}
                  identity="EngagementTracking"
                  value={moment(props.engagementTrackingAndNotes.engagementDate).format("yyyy-MM-DD")}
                  onBlur={(e) => props.validate("engagementDate", props.engagementTrackingAndNotes)}
                />
                <Validation error={props.validState.error.engagementDate} />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="2xl:col-span-11 lg:col-span-12 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Recorded by
                </h2>

                <DropdownSelect
                  drpIdentity={"RecordedBy"}
                  optionArray={props._By}
                  setFilterParameters={props.setFilterParameters}
                  value={props.engagementTrackingAndNotes.corporateEngagementRecordedById}

                />

              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="2xl:col-span-11 lg:col-span-12 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Follow Up
                </h2>

                <DropdownSelect
                  drpIdentity={"FollowUpBy"}
                  optionArray={props._By}
                  setFilterParameters={props.setFilterParameters}
                  value={props.engagementTrackingAndNotes.corporateEngagementFollowUpById}

                />
                <Validation
                  error={props.validState.error.corporateEngagementFollowUpById}
                />
              </div>
            </div>
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="2xl:col-span-11 lg:col-span-12 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Description <span className="text-[#c00000]">*</span>
                </h2>

                <textarea
                  id="default"
                  className="signup-text-area form-input rounded-none w-full shadow-red  text-lg"
                  name="engagementDescription"
                  rows="5"
                  value={props.engagementTrackingAndNotes.engagementDescription}
                  onChange={(event) => props.handleChange(event, "EngagementTracking")}
                  onBlur={(e) => props.validate("engagementDescription", props.engagementTrackingAndNotes)}

                ></textarea>

                <Validation
                  error={props.validState.error.engagementDescription}
                />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="xl:col-span-6 lg:col-span-6 col-span-12">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-11 lg:col-span-12 col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Last Updated Date
                  </h2>

                  <div className="disabled-input">
                    {props.engagementTrackingAndNotes.updatedAt === "" || props.engagementTrackingAndNotes.updatedAt === null?

                      <TextInput
                        disabled={true}
                        placeholder=""
                        type="text"
                        name="linkedIn"
                        value="None"
                        identity="BasicInformation"
                      />
                      :
                      <DatePicker
                        isDisable={true}
                        name="updatedAt"
                        handleChange={props.handleChange}
                        identity="updatedAt"
                        value={moment(props.engagementTrackingAndNotes.updatedAt).format("yyyy-MM-DD")}
                      />

                    }
                  </div>

                  {/* <div className="disabled-input">

                    <DatePicker
                      isDisable={true}
                      name="updatedAt"
                      //value={props.engagementTrackingDetails.engagementDate}
                      handleChange={props.handleChange}
                      identity="EngagementTracking"
                      value={moment(props.engagementTrackingAndNotes.updatedAt).format("yyyy-MM-DD")}
                    // onBlur={(e) => props.validate("updatedAt", props.engagementTrackingAndNotes)}
                    />
                  </div> */}
                  {/* <Validation error={props.validState.error.updatedAt} /> */}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 gap-6 mt-[1.5rem]">
              <div className="2xl:col-span-11 lg:col-span-12 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Updated By
                </h2>
                <div className="disabled-input">
                  <DropdownSelect
                    disabled={true}
                    drpIdentity={"updatedBy"}
                    optionArray={props._By}
                    setFilterParameters={props.setFilterParameters}
                    value={props.engagementTrackingAndNotes.updatedBy}

                  />
                  
                </div>
                <Validation
                  error={props.validState.error.corporateEngagementFollowUpById}
                />
              </div>
            </div>
          </div>
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-12 lg:col-span-12 col-span-12 px-14 mt-5">
                <Link to="" className="flex items-center justify-end">
                  <button
                    className="btn btn-red text-white py-3"
                    onClick={() => props.onClick()}
                  >
                    <span className="text-2xl text-white font-semibold mr-2 rounded-full border-2 border-white h-7 w-7 flex items-center justify-center">
                      +
                    </span>
                    <span className="text-xl text-white font-semibold tracking-normal">
                      Add/Save
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default EngagementTrackingNotes;
