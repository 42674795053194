import React from "react";
import SwalServices from "../../services/swalServices/SwalServices";
import ActionContainer from "../../components/UIComponent/ActionTableComponent/actionContainer";
import ActionEditButton from "../../components/UIComponent/ActionTableComponent/ActionEditButton";
import Table from "../../components/table/Table";
import NotificationServices from "../../services/axiosServices/apiServices/NotificationServices";
import moment from "moment";
import ActionDeleteButton from "../../components/UIComponent/ActionTableComponent/ActionDeleteButton";
import { CommonValidationMessages } from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import ValidationText from "../../utils/validation/ValidationText";

export default class NotificationAnnouncemnet extends React.Component {
  constructor(props) {
    super(props);
    this.notificationServices = new NotificationServices();
    this.swalServices = new SwalServices();
    this.state = {
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        status: 0,
        filterByType: 0,
        filterByStatus: 0,
      },
      totalResultes: "",
      getNotificationAnnouncemnetList: [],
      notificationDetails: {
        startDate: "",
        endDate: "",
        message: "",
        notificationAnnouncementId: 0,
      },
      isLoading: false,
      validationRule: {
        message: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "announcement message"
            ),
          },
          {
            type: "maxLength",
            maxLength: 4000,
            message: CommonValidationMessages.MaxChar.replace(
              "{0}",
              "Announcement message"
            ),
          },
        ],
        startDate: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "start date"
            ),
          },
        ],
        endDate: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "end date"
            ),
          },
        ],
      },
      validState: {
        isValid: true,
        error: {},
      },
    };
  }

  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.notificationDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };
  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.notificationDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.notificationDetails;
    detail[name] = value;
    this.setState({ notificationDetails: { ...detail } });
  }
  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getPagesDetailsList();
  };
  resetFileds() {
    let reset = this.state.notificationDetails;
    reset.startDate = "";
    reset.endDate = "";
    reset.message = "";
    reset.notificationAnnouncementId = 0;
    this.setState({ notificationDetails: reset });
  }

  addEditNotification = () => {
    let isValidInput = this.isValidateAllFields();
    if (isValidInput) {
      this.notificationServices
        .addEditNotification(this.state.notificationDetails)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.swalServices.Success("Announcement inserted successfully.");
            this.resetFileds();
            this.getNotificationList();
          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };

  getNotificationsById = (id) => {
    this.notificationServices.getNotificationsById(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({
          notificationDetails: response.responseItem.responseContent,
        });
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  getNotificationList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });
    this.notificationServices
      .getNotificationList(pagination)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let newGetNotificationList =
            response.responseItem.responseContent.itemList;
          newGetNotificationList.map((key, index) => {
            key.startDate = moment(key.startDate).format("DD MMM YYYY");
            key.endDate = moment(key.endDate).format("DD MMM YYYY");
          });
          this.setState({
            getNotificationAnnouncemnetList: newGetNotificationList,
          });
          this.setState({
            totalResultes: response.responseItem.responseContent.totalCount,
          });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };
  onDelete = (value) => {
    let request = {
      id: value,
      deletedBy: 115,
      appId: 15,
    };
    this.notificationServices.deleteNotification(request).then((response) => {
      if (response.statusCode === 200) {
        this.swalServices.Success("Announcement deleted successfully.");
        this.getNotificationList();
        this.resetFileds();
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };
  onEdit = (value, index) => {
    let editNotification = [...this.state.getNotificationAnnouncemnetList];
    this.getNotificationsById(
      editNotification[index].notificationAnnouncementId
    );
  };

  actions = (element, index, value) => {
    return element !== "notificationAnnouncementId" ? null : (
      <td>
        <ActionContainer>
          <ActionEditButton
            value={value}
            id={index}
            index={index}
            onActionClick={this.onEdit}
            text="Edit"
          />
          <ActionDeleteButton
            value={value}
            id={index}
            index={index}
            onActionClick={this.onDelete}
            text="Delete"
          />
        </ActionContainer>
      </td>
    );
  };

  componentDidMount() {
    this.getNotificationList();
  }
  render() {
    return (
      <>
        <div className="main-body-section">
          <div className="bg-white">
            <div className="heading-part py-3 border-b">
              <div className="flex items-center justify-between pl-8">
                <h4 className="small-title font-bold theme-color ">
                  Create Notification Announcement
                </h4>
              </div>
            </div>
            <div className="px-8 pt-10 pb-12">
              <div className="grid grid-cols-12 gap-6 w-full">
                <div className="col-span-4">
                  <h2 className="text-xl theme-color mb-1">
                    Start Date<span className="text-[#C00000]">*</span>
                  </h2>
                  <input
                    id="startDate"
                    name="startDate"
                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                    type="date"
                    value={moment(
                      this.state.notificationDetails.startDate
                    ).format("yyyy-MM-DD")}
                    onChange={(event) => this.handleChange(event)}
                    onBlur={() => this.validateField("startDate")}
                  />
                  <div>
                    <ValidationText
                      error={this.state.validState.error.startDate}
                    />
                  </div>
                </div>
                <div className="col-span-4">
                  <h2 className="text-xl theme-color mb-1">
                    End Date<span className="text-[#C00000]">*</span>
                  </h2>
                  <input
                    id="endDate"
                    name="endDate"
                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                    type="date"
                    value={moment(
                      this.state.notificationDetails.endDate
                    ).format("yyyy-MM-DD")}
                    onChange={(event) => this.handleChange(event)}
                    onBlur={() => this.validateField("endDate")}
                  />
                  <div>
                    <ValidationText
                      error={this.state.validState.error.endDate}
                    />
                  </div>
                </div>

              </div>
              <div className="grid 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-2 sm:grid-cols-1 gap-0 pt-8">
                <div className="xl:col-span-8 post-details ck-content">
                  <h2 className="text-xl theme-color mb-1">
                    Announcement Message
                    <span className="text-[#C00000]">*</span>
                  </h2>
                  <textarea
                    className="w-full h-[120px]"
                    placeholder=""
                    id="meta_description"
                    name="message"
                    rows="4"
                    cols="4"
                    value={this.state.notificationDetails.message}
                    onChange={(event) => this.handleChange(event)}
                    onBlur={() => this.validateField("message")}
                  />
                  <div>
                    <ValidationText
                      error={this.state.validState.error.message}
                    />
                  </div>
                </div>
                <div className="xl:col-span-4 pt-24 pr-8">
                  <div className="flex items-center justify-end">
                    {this.state.notificationDetails.notificationAnnouncementId === 0 ?
                      <button
                        className="btn btn-red text-white"
                        onClick={() => this.addEditNotification()}
                      >
                        <span className="text-xl text-white font-semibold tracking-normal">
                          {" "}
                          Create{" "}
                        </span>
                      </button>
                      :
                      <button
                        className="btn btn-red text-white"
                        onClick={() => this.addEditNotification()}
                      >
                        <span className="text-xl text-white font-semibold tracking-normal">
                          {" "}
                          Update{" "}
                        </span>
                      </button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <main className="pb-10">
            <div className="w-full mx-auto">
              <div className="relative">
                <div>
                  <div className=" mt-6">
                    <Table
                      columns={[
                        { name: "startDate", title: "Start Date" },
                        { name: "endDate", title: "End Date" },
                        { name: "message", title: "Message" },
                        { name: "notificationAnnouncementId", title: "Action" },
                      ]}
                      pagination={this.state.pagination}
                      rows={this.state.getNotificationAnnouncemnetList}
                      sortingColumns={["pagesMainModulesName"]}
                      isLoading={this.state.isLoading}
                      totalResultes={this.state.totalResultes}
                      totalCount={this.state.totalResultes}
                      setPagination={this.setPagination.bind(this)}
                      customScope={[
                        {
                          column: "notificationAnnouncementId",
                          renderTableData: this.actions.bind(this),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </main>
          {/* <div className="flex items-center justify-between pt-8 mx-4">
            <button className="text-lg font-bold btn btn-gray text-white">
              Back
            </button>
            <button className="text-lg font-bold btn btn-red text-white">
              Save
            </button>
          </div> */}
        </div>
      </>
    );
  }
}
