import React, { useEffect, useState } from "react";
import ValidationText from "../../utils/validation/ValidationText";
import Selector from "../dropdown/commonDropdown/Selector";
import EntryManagementServices from "../../services/axiosServices/apiServices/EntryManagementServices";
import SwalServices from "../../services/swalServices/SwalServices";
import TimePicker from "../TimePicker.js/TimePicker";
import moment from "moment";
import { DeleteButtonIcon } from "../SVGConstants";

const entryServices = new EntryManagementServices();
const swalServices = new SwalServices();

const eventProgrammTracksObj = {
  eventProgrammeTrackId: 0,
  eventProgrammeId: 0,
  header: "",
  description: "",
  type: "",
  eventProgrammeTrackSpeakers: [],
  eventSpeakerId: 0,
};

const eventProgrammeTrackSpeakersObj = {
  eventProgrammeTrackSpeakerId: 0,
  eventProgrammeTrackId: 0,
  eventSpeakerId: 0,
};

const trackNo = [
  {
    value: 1,
    label: "1 track",
  },
  {
    value: 2,
    label: "2 tracks",
  },
  {
    value: 3,
    label: "3 tracks",
  },
];
const speakerTypeArray = [
  {
    value: 1,
    label: "Single",
  },
  {
    value: 2,
    label: "Panel",
  },
];
const validStateObj = {
  isValid: true,
  error: [],
};

function Programme(props) {
  const [lessions, setLessions] = useState([]);
  const [speaker, setSpeaker] = useState([]);
  const [moderator, setModerator] = useState([]);
  const [validStateProgram, setValidStateProgram] = useState(validStateObj);

  useEffect(() => {
    getAllDropDownForEntryManagement(props.eventId);
    // getEntryProgrammeDetailById(1);
    if (props.eventId && props.eventId > 0) {
      getEntryProgrammeDetailById(props.eventId);
    }
  }, []);

  const getEntryProgrammeDetailById = (eventId) => {
    entryServices.getEntryProgrammeDetailById(eventId).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        setLessions(response.responseItem.responseContent);
      } else {
        swalServices.Error(response.message);
      }
    });
  };

  const getAllDropDownForEntryManagement = (eventId) => {
    entryServices.getAllDropDownForEntryManagement(eventId).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        var speakers = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Speakers"
        );
        var moderator = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Moderator"
        );
        speakers = speakers.map((p) => ({
          value: p.id,
          label: p.name,
        }));
        moderator = moderator.map((p) => ({
          value: p.id,
          label: p.name,
        }));
        setSpeaker(speakers);
        setModerator(moderator);
      } else {
        swalServices.Error(response.message);
      }
    });
  };

  const separateSpeakers = (selectedSpeakerArr) => {
    let returnArr = [];

    if (selectedSpeakerArr && selectedSpeakerArr.length > 0) {
      // returnArr = selectedSpeakerArr.map((p) => ({

      //   value: p.eventSpeakerId,
      //   label: speaker.find((x) =>  x.value === p.eventSpeakerId).label,
      // }));
      selectedSpeakerArr.forEach((value, index) => {
        let obj = {};
        obj.value = value.eventSpeakerId;
        const spkObj = speaker.find((x) => x.value === value.eventSpeakerId);
        if (spkObj && spkObj.label) {
          obj.label = spkObj.label;
          returnArr.push(obj);
        }
      });
    }
    return returnArr;
  };

  const handleChange = (e, type, lessionIndex, programIndex, trackIndex) => {
    const value = e.target.value;
    const name = e.target.name;

    let lesArr = [...lessions];
    let les = lesArr[lessionIndex];

    if (type === "EventProgram") {
      let programArr = les.eventProgrammes;
      let program = programArr[programIndex];

      if (name === 'zoomMeetingId') {
        program = { ...program, [name]: value.split(" ").join("") };
      }
      else {
        program = { ...program, [name]: value };
      }
      programArr[programIndex] = program;

      les.eventProgrammes = programArr;
    } else if (type === "ProgramTracks") {
      let programArr = les.eventProgrammes;
      let program = programArr[programIndex];

      let trackArr = program.entryProgrammTracks;
      let track = trackArr[trackIndex];

      track = { ...track, [name]: value };
      trackArr[trackIndex] = track;

      program.entryProgrammTracks = trackArr;
      programArr[programIndex] = program;

      les.eventProgrammes = programArr;
    }
    lesArr[lessionIndex] = les;
    setLessions(lesArr);
  };

  const handleChangeInDropdown = (
    selected,
    drpIdentity,
    lesIndex,
    programIndex,
    trackIndex
  ) => {
    let lesArr = [...lessions];
    if (drpIdentity === "Tracks") {
      let programArr = [...lesArr[lesIndex].eventProgrammes];
      let program = programArr[programIndex];
      program = { ...program, noOfTrack: selected.value };
      // program.entryProgrammTracks = [];

      let track = program.entryProgrammTracks;
      track = [];
      for (let i = 0; i < selected.value; i++) {
        track.push(eventProgrammTracksObj);
      }
      program = { ...program, entryProgrammTracks: track };

      programArr[programIndex] = program;
      lesArr[lesIndex].eventProgrammes = programArr;
    } else if (drpIdentity === "SpeakerType") {
      let programArr = [...lesArr[lesIndex].eventProgrammes];
      let program = programArr[programIndex];

      let trackArr = program.entryProgrammTracks;
      let track = trackArr[trackIndex];
      track = { ...track, type: selected.label };
      trackArr[trackIndex] = track;

      program = { ...program, entryProgrammTracks: trackArr };

      programArr[programIndex] = program;
      lesArr[lesIndex].eventProgrammes = programArr;
    } else if (drpIdentity === "Moderator") {
      let programArr = [...lesArr[lesIndex].eventProgrammes];
      let program = programArr[programIndex];

      let trackArr = program.entryProgrammTracks;
      let track = trackArr[trackIndex];
      track = { ...track, eventSpeakerId: selected.value };
      trackArr[trackIndex] = track;

      program = { ...program, entryProgrammTracks: trackArr };

      programArr[programIndex] = program;
      lesArr[lesIndex].eventProgrammes = programArr;
    } else if (drpIdentity === "Speaker") {
      let programArr = [...lesArr[lesIndex].eventProgrammes];
      let program = programArr[programIndex];

      let trackArr = program.entryProgrammTracks;
      let track = trackArr[trackIndex];

      let speakerArr = track.eventProgrammeTrackSpeakers;
      speakerArr = [];

      if (track.type === "Panel") {
        for (let i = 0; i < selected.length; i++) {
          speakerArr.push(eventProgrammeTrackSpeakersObj);
        }

        speakerArr = selected.map((p) => ({
          eventSpeakerId: p.value,
          eventProgrammeTrackSpeakerId: 0,
          eventProgrammeTrackId: 0,
        }));

      } else {
        speakerArr.push(eventProgrammeTrackSpeakersObj);
        let speaker = speakerArr[0];
        speaker = { ...speaker, eventSpeakerId: selected.value };
        speakerArr[0] = speaker;
      }

      track = { ...track, eventProgrammeTrackSpeakers: speakerArr };
      trackArr[trackIndex] = track;

      program = { ...program, entryProgrammTracks: trackArr };

      programArr[programIndex] = program;
      lesArr[lesIndex].eventProgrammes = programArr;
    }

    setLessions(lesArr);
  };

  const setTime = (time, lessionIndex, programIndex) => {
    let lesArr = [...lessions];
    let les = lesArr[lessionIndex];
    let programArr = les.eventProgrammes;
    let program = programArr[programIndex];

    program = { ...program, [time.identity]: time.selectedTime };
    programArr[programIndex] = program;

    les.eventProgrammes = programArr;

    lesArr[lessionIndex] = les;
    setLessions(lesArr);
  };

  const addEditEntryProgrammeDetail = (redirectToNext) => {
    let request = [...lessions];
    entryServices.addEditEntryProgrammeDetail(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (redirectToNext === 0) {
          swalServices.Success("Program details inserted successfully.");
        } else {
          props.nextToProgramme();
          props.executeScroll();
        }
      } else {
        swalServices.Error(response.message);
      }
    });
  };

  const validateProgrammes = (redirectToNext) => {
    var message = "";
    var field = "";
    var valState = { ...validStateProgram };
    valState.isValid = true;
    var valError = [];

    let validArr = [];
    let isValidAll;
    lessions.forEach((lesson, lesIndex) => {
      if (lesson.eventProgrammes.length > 0) {
        lesson.eventProgrammes.forEach((program, programKey) => {
          if (lesson.isHybrid) {
            if (program.address === "") {
              message =
                "Please enter location for program " +
                (programKey + 1) +
                " of lesson " +
                (lesIndex + 1);
              field =
                "address[" + (lesIndex + 1) + "][" + (programKey + 1) + "]";
              valError[field] = message;
              valState.isValid = false;
            }
            if (
              program.zoomMeetingId === "" ||
              program.zoomMeetingId === null
            ) {
              message =
                "Please enter zoom meeting id for program " +
                (programKey + 1) +
                " of lesson " +
                (lesIndex + 1);
              field =
                "zoomMeetingId[" +
                (lesIndex + 1) +
                "][" +
                (programKey + 1) +
                "]";
              valError[field] = message;
              valState.isValid = false;
            }
          }
          if (lesson.isPhysical) {
            if (program.address === "") {
              message =
                "Please enter location for program " +
                (programKey + 1) +
                " of lesson " +
                (lesIndex + 1);
              field =
                "address[" + (lesIndex + 1) + "][" + (programKey + 1) + "]";
              valError[field] = message;
              valState.isValid = false;
            }
          }
          if (lesson.isOnline) {
            if (
              program.zoomMeetingId === "" ||
              program.zoomMeetingId === null
            ) {
              message =
                "Please enter zoom meeting id for program " +
                (programKey + 1) +
                " of lesson " +
                (lesIndex + 1);
              field =
                "zoomMeetingId[" +
                (lesIndex + 1) +
                "][" +
                (programKey + 1) +
                "]";
              valError[field] = message;
              valState.isValid = false;
            }
          }
          if (program.eventProgrammeStartTime === "") {
            message =
              "Please select start time for program " +
              (programKey + 1) +
              " of lesson " +
              (lesIndex + 1);
            field =
              "eventProgrammeStartTime[" +
              (lesIndex + 1) +
              "][" +
              (programKey + 1) +
              "]";
            valError[field] = message;
            valState.isValid = false;
          }
          if (program.eventProgrammeEndTime === "") {
            message =
              "Please select end time for program " +
              (programKey + 1) +
              " of lesson " +
              (lesIndex + 1);
            field =
              "eventProgrammeEndTime[" +
              (lesIndex + 1) +
              "][" +
              (programKey + 1) +
              "]";
            valError[field] = message;
            valState.isValid = false;
          }
          if (program.noOfTrack > 0) {
            //valState.isValid = validateProgramTracks(value.entryProgrammTracks, lesIndex, key);

            program.entryProgrammTracks.forEach((track, trackKey) => {
              // if (track.type === "") {
              //   message = "Please select type for track " + (trackKey + 1) + " of program " + (programKey + 1) + " of lesson " + (lesIndex + 1);
              //   field = "type[" + (lesIndex + 1) + "][" + (programKey + 1) + "][" + (trackKey + 1) + "]";
              //   valError[field] = message;
              //   valState.isValid = false;
              // }
              // if (track.eventSpeakerId === 0) {
              //   message = "Please select moderator for track " + (trackKey + 1) + " of program " + (programKey + 1) + " of lesson " + (lesIndex + 1);
              //   field = "eventSpeakerId[" + (lesIndex + 1) + "][" + (programKey + 1) + "][" + (trackKey + 1) + "]";
              //   valError[field] = message;
              //   valState.isValid = false;
              // }
              if (track.header === "") {
                message =
                  "Please enter header for track " +
                  (trackKey + 1) +
                  " of program " +
                  (programKey + 1) +
                  " of lesson " +
                  (lesIndex + 1);
                field =
                  "header[" +
                  (lesIndex + 1) +
                  "][" +
                  (programKey + 1) +
                  "][" +
                  (trackKey + 1) +
                  "]";
                valError[field] = message;
                valState.isValid = false;
              }
              // if (track.eventProgrammeTrackSpeakers < 1) {
              //   message = "Please select speaker for track " + (trackKey + 1) + " of program " + (programKey + 1) + " of lesson " + (lesIndex + 1);
              //   field = "speaker[" + (lesIndex + 1) + "][" + (programKey + 1) + "][" + (trackKey + 1) + "]";
              //   valError[field] = message;
              //   valState.isValid = false;
              // }
            });
          }
        });
        valState.error = Object.values(valError);
        setValidStateProgram(valState);
        validArr.push(valState.isValid);
        //return valState.isValid;
      } else {
        validArr.push(true);
      }
    });

    isValidAll = validArr.find((x) => x === false);

    if (isValidAll !== false) {
      addEditEntryProgrammeDetail(redirectToNext);
    }
  };

  const addPrograms = (lesIndex, lessonId) => {
    let lesArr = [...lessions];
    let program = [...lesArr[lesIndex].eventProgrammes];
    let programobj = {
      eventProgrammeId: 0,
      eventLessonId: lessonId,
      address: lesArr[lesIndex].address,
      zoomMeetingId: "",
      zoomWebinarId: "",
      eventProgrammeStartTime: "",
      eventProgrammeEndTime: "",
      noOfTrack: 0,
      entryProgrammTracks: [],
    };
    program.push(programobj);
    lesArr[lesIndex] = { ...lesArr[lesIndex], eventProgrammes: program };
    setLessions(lesArr);
  };

  const deleteProgrammeTime = (lessonIndex, programmeIndex, lessonId) => {
    let lesArr = [...lessions];
    let program = [...lesArr[lessonIndex].eventProgrammes];
    if (Number(program[programmeIndex].eventProgrammeId) > 0) {
      entryServices
        .deleteProgrammeTimeById(
          Number(program[programmeIndex].eventProgrammeId)
        )
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            // program.splice(programmeIndex, 1);
            // lesArr[lessonIndex].eventProgrammes = [...program];
            // setLessions(lesArr);
            swalServices.Success("Programme time deleted successfully");
            getEntryProgrammeDetailById(props.eventId);
          } else {
            swalServices.Error(response.message);
          }
        });
    } else {
      program.splice(programmeIndex, 1);
      lesArr[lessonIndex].eventProgrammes = [...program];
      setLessions(lesArr);
    }
  };

  return (
    <div>
      {validStateProgram.error.length > 0 ? (
        <div className="bg-white" id="ErrorList">
          <div className="heading-part pl-7 py-4 border-b mb-5">
            <div className="flex items-center justify-between">
              <h4 className="small-title font-bold theme-color">
                The following field(s) are required:
              </h4>
            </div>
            <ul>
              {validStateProgram.error.map((x, key) => {
                return <li key={key} className=" text-[#c00000]">{x}</li>;
              })}
            </ul>
          </div>
        </div>
      ) : null}

      {/* Programme Start */}
      <div className="bg-white">
        <div className="heading-part pl-7 py-4 border-b">
          <div className="flex items-center justify-between">
            <h4 className="small-title font-bold theme-color">Programme</h4>
          </div>
        </div>
        <div className="px-8 py-4 pt-10">
          <div className="mb-0">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <div className="grid grid-cols-12 gap-6">
                  {lessions.map((les, lesKey) => {
                    return (
                      <>
                        <div
                          className="2xl:col-span-12 lg:col-span-12 col-span-12 p-7 lession-section"
                          key={lesKey}
                        >
                          <div className="grid grid-cols-12 gap-6 mb-6">
                            <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                              <div className="flex items-center tble-th-txt text-left font-normal text-xl">
                                <h3 className="small-title font-bold theme-color">
                                  {lesKey + 1}.{" "}
                                  {moment(les.eventLessonDate).format(
                                    "dddd, D  MMMM  YYYY"
                                  )}
                                </h3>
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-12 gap-6 mb-6">
                            <div className="col-span-12">
                              {les.eventProgrammes.map((pro, proKey) => {
                                return (
                                  <>
                                    {/* Add Timeing */}
                                    <div className="tacking-section mb-10 pb-10 border-dashed border-b-2 last:border-b-0 last:mb-0 last:pb-0">
                                      <div className="relative">
                                        <div className="absolute right-0 top-[-20px]">
                                          <button
                                            className="action-btn right-icon"
                                            key={`DeleteBtn_${lesKey}_${proKey}`}
                                            onClick={() =>
                                              deleteProgrammeTime(
                                                lesKey,
                                                proKey,
                                                les.eventLessonId
                                              )
                                            }
                                          >
                                            <DeleteButtonIcon />
                                          </button>
                                        </div>
                                      </div>
                                      <div className="tack-main-section">
                                        <div className="grid grid-cols-12 gap-6 mb-8 custom-checkbox-set">
                                          <div className="2xl:col-span-8 lg:col-span-8 col-span-12">
                                            <div className="flex items-center tble-th-txt text-left font-normal text-xl mb-3 disabled-checkbox">
                                              <input
                                                id="isPhysical"
                                                name="isPhysical"
                                                type="checkbox"
                                                className="cursor-pointer h-4 w-4 ring-[#757575] text-[#C00000] bg-white rounded check-box-custom"
                                                checked={
                                                  les.isPhysical || les.isHybrid
                                                    ? true
                                                    : false
                                                }
                                                //value={les.isPhysical}
                                                disabled
                                              />
                                              <label className="ml-5 text-[#757575]">
                                                Location
                                              </label>
                                            </div>
                                            <div
                                              className={`${les.isOnline === true
                                                ? "disabled-input"
                                                : null
                                                }`}
                                            >
                                              <input
                                                id="default"
                                                className={`form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]`}
                                                type="text"
                                                name="address"
                                                placeholder=""
                                                disabled={
                                                  les.isOnline === true
                                                    ? true
                                                    : false
                                                }
                                                value={pro.address}
                                                onChange={(event) =>
                                                  handleChange(
                                                    event,
                                                    "EventProgram",
                                                    lesKey,
                                                    proKey
                                                  )
                                                }
                                              />
                                            </div>
                                            <div>
                                              {" "}
                                              <ValidationText
                                              // error={props.validState.error.resourceName}
                                              />{" "}
                                            </div>
                                          </div>
                                          <div className="2xl:col-span-4 lg:col-span-4 col-span-12 ">
                                            <div className="flex items-center tble-th-txt text-left font-normal text-xl mb-3 disabled-checkbox">
                                              <input
                                                id="isOnline"
                                                name="isOnline"
                                                type="checkbox"
                                                className="cursor-pointer h-4 w-4 ring-[#757575] text-[#C00000] bg-white rounded check-box-custom"
                                                checked={
                                                  les.isOnline || les.isHybrid
                                                    ? true
                                                    : false
                                                }
                                                //value={les.isOnline}
                                                disabled
                                              />
                                              <label className="ml-5 text-[#757575]">
                                                Zoom ID
                                              </label>
                                            </div>
                                            <div
                                              className={`${les.isPhysical === true
                                                ? "disabled-input"
                                                : null
                                                }`}
                                            >
                                              <input
                                                id="default"
                                                className={`form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575] `}
                                                type="text"
                                                name="zoomMeetingId"
                                                // placeholder="769 133 591"
                                                disabled={
                                                  les.isPhysical === true
                                                    ? true
                                                    : false
                                                }
                                                value={pro.zoomMeetingId}
                                                onChange={(event) =>
                                                  handleChange(
                                                    event,
                                                    "EventProgram",
                                                    lesKey,
                                                    proKey
                                                  )
                                                }
                                              />
                                            </div>
                                            <div>
                                              {" "}
                                              <ValidationText
                                              // error={props.validState.error.resourceName}
                                              />{" "}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="grid grid-cols-12 gap-6 mb-4">
                                          <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                                            <div className="text-left font-normal text-xl">
                                              <div className="mb-1">
                                                <label className="text-xl theme-color ">
                                                  Starts at
                                                </label>
                                              </div>
                                              {/* <TimePicker
                                                id="default"
                                                className="form-input rounded-none w-full shadow-red py-3  mt-2 text-lg text-[#757575]"
                                                type="time"
                                                name="eventProgrammeStartTime"
                                                placeholder="09:00 AM"
                                                value={pro.eventProgrammeStartTime}
                                                onChange={(event) => handleChange(event, "EventProgram", lesKey, proKey)}
                                              /> */}
                                              <div>
                                                <TimePicker
                                                  identity="eventProgrammeStartTime"
                                                  setTime={(time) =>
                                                    setTime(
                                                      time,
                                                      lesKey,
                                                      proKey
                                                    )
                                                  }
                                                  value={
                                                    pro.eventProgrammeStartTime
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div>
                                              {" "}
                                              <ValidationText
                                              // error={props.validState.error.resourceName}
                                              />{" "}
                                            </div>
                                          </div>
                                          <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                                            <div className="text-left font-normal text-xl">
                                              <div className="mb-1">
                                                <label className="mr-2 text-[#757575]">
                                                  Ends at
                                                </label>
                                              </div>
                                              {/* <input
                                                id="default"
                                                className="form-input rounded-none w-full shadow-red py-3  mt-2 text-lg text-[#757575]"
                                                type="text"
                                                name="eventProgrammeEndTime"
                                                placeholder="10:00 AM"
                                                value={pro.eventProgrammeEndTime}
                                                onChange={(event) => handleChange(event, "EventProgram", lesKey, proKey)}
                                              /> */}
                                              <div>
                                                <TimePicker
                                                  identity="eventProgrammeEndTime"
                                                  setTime={(time) =>
                                                    setTime(
                                                      time,
                                                      lesKey,
                                                      proKey
                                                    )
                                                  }
                                                  value={
                                                    pro.eventProgrammeEndTime
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div>
                                              {" "}
                                              <ValidationText
                                              // error={props.validState.error.resourceName}
                                              />{" "}
                                            </div>
                                          </div>
                                          <div className="2xl:col-span-4 lg:col-span-4 col-span-12 ">
                                            <div className="text-left font-normal text-xl">
                                              <div className="mb-1">
                                                <label className="text-[#757575]">
                                                  No. of Track
                                                </label>
                                              </div>
                                              <div className="w-full text-[#757575]">
                                                <Selector
                                                  options={trackNo}
                                                  // isMulti
                                                  closeMenuOnSelect={true}
                                                  hideSelectedOptions={false}
                                                  // components={{
                                                  //   Option,
                                                  // }}
                                                  onChange={(
                                                    selected,
                                                    drpIdentity
                                                  ) =>
                                                    handleChangeInDropdown(
                                                      selected,
                                                      drpIdentity,
                                                      lesKey,
                                                      proKey
                                                    )
                                                  }
                                                  allowSelectAll={false}
                                                  value={trackNo.filter(
                                                    (x) =>
                                                      x.value === pro.noOfTrack
                                                  )}
                                                  drpIdentity="Tracks"
                                                />
                                              </div>
                                            </div>

                                            <div>
                                              {" "}
                                              <ValidationText
                                              // error={props.validState.error.resourceName}
                                              />{" "}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="mt-10">
                                          {pro.entryProgrammTracks.map(
                                            (track, trackKey) => {
                                              return (
                                                <>
                                                  {/* Track start */}
                                                  <div className="grid grid-cols-12 gap-6 mb-6 ">
                                                    <div className="2xl:col-span-1 lg:col-span-2 col-span-12">
                                                      <span className="text-left font-normal track-number-part">
                                                        {trackKey + 1} {trackKey > 0 ? "Tracks" : "Track"}
                                                      </span>
                                                    </div>
                                                    <div className="2xl:col-span-11 lg:col-span-10 col-span-12 bg-[#f7f7f7] p-4">
                                                      <div className="grid grid-cols-12 gap-6 mb-6">
                                                        <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                                                          <label
                                                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                            htmlFor="default"
                                                          >
                                                            Select Type
                                                          </label>
                                                          <div className="w-full text-[#757575]">
                                                            <Selector
                                                              options={
                                                                speakerTypeArray
                                                              }
                                                              // isMulti
                                                              closeMenuOnSelect={
                                                                true
                                                              }
                                                              hideSelectedOptions={
                                                                false
                                                              }
                                                              // components={{
                                                              //   Option,
                                                              // }}
                                                              onChange={(
                                                                selected,
                                                                drpIdentity
                                                              ) =>
                                                                handleChangeInDropdown(
                                                                  selected,
                                                                  drpIdentity,
                                                                  lesKey,
                                                                  proKey,
                                                                  trackKey
                                                                )
                                                              }
                                                              allowSelectAll={
                                                                false
                                                              }
                                                              value={speakerTypeArray.filter(
                                                                (x) =>
                                                                  x.label ===
                                                                  track.type
                                                              )}
                                                              drpIdentity="SpeakerType"
                                                            />
                                                          </div>

                                                          <div>
                                                            {" "}
                                                            <ValidationText
                                                            // error={props.validState.error.resourceName}
                                                            />{" "}
                                                          </div>
                                                        </div>
                                                        <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                                                          <label
                                                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                            htmlFor="default"
                                                          >
                                                            Select Speaker
                                                          </label>
                                                          {track.type ===
                                                            "Panel" ? (
                                                            <div className="w-full text-[#757575]">
                                                              <Selector
                                                                options={
                                                                  speaker
                                                                }
                                                                isMulti
                                                                closeMenuOnSelect={
                                                                  false
                                                                }
                                                                hideSelectedOptions={
                                                                  false
                                                                }
                                                                // components={{
                                                                //   Option,
                                                                // }}
                                                                onChange={(
                                                                  selected,
                                                                  drpIdentity
                                                                ) =>
                                                                  handleChangeInDropdown(
                                                                    selected,
                                                                    drpIdentity,
                                                                    lesKey,
                                                                    proKey,
                                                                    trackKey
                                                                  )
                                                                }
                                                                allowSelectAll={
                                                                  false
                                                                }
                                                                value={separateSpeakers(
                                                                  track.eventProgrammeTrackSpeakers,
                                                                )}
                                                                drpIdentity="Speaker"
                                                              />
                                                            </div>
                                                          ) : (
                                                            <div className="w-full text-[#757575]">
                                                              <Selector
                                                                options={
                                                                  speaker
                                                                }
                                                                // isMulti
                                                                closeMenuOnSelect={
                                                                  true
                                                                }
                                                                hideSelectedOptions={
                                                                  false
                                                                }
                                                                // components={{
                                                                //   Option,
                                                                // }}
                                                                onChange={(
                                                                  selected,
                                                                  drpIdentity
                                                                ) =>
                                                                  handleChangeInDropdown(
                                                                    selected,
                                                                    drpIdentity,
                                                                    lesKey,
                                                                    proKey,
                                                                    trackKey
                                                                  )
                                                                }
                                                                allowSelectAll={
                                                                  false
                                                                }
                                                                value={separateSpeakers(
                                                                  track.eventProgrammeTrackSpeakers,
                                                                )}
                                                                drpIdentity="Speaker"
                                                              />
                                                            </div>
                                                          )}

                                                          <div>
                                                            {" "}
                                                            <ValidationText
                                                            // error={props.validState.error.resourceName}
                                                            />{" "}
                                                          </div>
                                                        </div>
                                                        <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                                                          <label
                                                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                            htmlFor="default"
                                                          >
                                                            Select Moderator
                                                          </label>
                                                          <div className="w-full text-[#757575]">
                                                            <Selector
                                                              options={
                                                                moderator
                                                              }
                                                              // isMulti
                                                              closeMenuOnSelect={
                                                                true
                                                              }
                                                              hideSelectedOptions={
                                                                false
                                                              }
                                                              // components={{
                                                              //   Option,
                                                              // }}
                                                              onChange={(
                                                                selected,
                                                                drpIdentity
                                                              ) =>
                                                                handleChangeInDropdown(
                                                                  selected,
                                                                  drpIdentity,
                                                                  lesKey,
                                                                  proKey,
                                                                  trackKey
                                                                )
                                                              }
                                                              allowSelectAll={
                                                                false
                                                              }
                                                              value={moderator.filter(
                                                                (x) =>
                                                                  x.value ===
                                                                  track.eventSpeakerId
                                                              )}
                                                              drpIdentity="Moderator"
                                                            />
                                                          </div>

                                                          <div>
                                                            {" "}
                                                            <ValidationText
                                                            // error={props.validState.error.resourceName}
                                                            />{" "}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="grid grid-cols-12 gap-6 mb-6">
                                                        <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                                                          <label
                                                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                            htmlFor="default"
                                                          >
                                                            Header{" "}<span className="text-[#C00000]">*</span>
                                                          </label>
                                                          <input
                                                            id="default"
                                                            className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                                            type="text"
                                                            name="header"
                                                            value={track.header}
                                                            onChange={(event) =>
                                                              handleChange(
                                                                event,
                                                                "ProgramTracks",
                                                                lesKey,
                                                                proKey,
                                                                trackKey
                                                              )
                                                            }
                                                          />
                                                          <div>
                                                            <ValidationText
                                                            // error={props.validState.error.resourceTypeId}
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="2xl:col-span-8 lg:col-span-8 col-span-12">
                                                          <label
                                                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                            htmlFor="default"
                                                          >
                                                            Description
                                                          </label>
                                                          <input
                                                            id="default"
                                                            className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                                            type="text"
                                                            name="description"
                                                            value={
                                                              track.description
                                                            }
                                                            onChange={(event) =>
                                                              handleChange(
                                                                event,
                                                                "ProgramTracks",
                                                                lesKey,
                                                                proKey,
                                                                trackKey
                                                              )
                                                            }
                                                          />
                                                          <div>
                                                            <ValidationText
                                                            // error={props.validState.error.resourceTypeId}
                                                            />
                                                          </div>
                                                        </div>

                                                        {/* <div className="2xl:col-span-1 lg:col-span-1 col-span-12 justify-center">
                                                          <label
                                                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30 opacity-0"
                                                            htmlFor="default"
                                                          >
                                                            Deletebtn
                                                          </label>
                                                          <div className="pt-3 program-deletebtn flex justify-end">
                                                            <ActionDeleteButton
                                                              // value={value}
                                                              // id={index}
                                                              // index={index}
                                                              // onActionClick={this.onDelete}
                                                              text="Delete"
                                                            />
                                                          </div>
                                                        </div> */}
                                                      </div>
                                                    </div>
                                                  </div>

                                                  {/* Track end */}
                                                </>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>

                          {/* Add timing Button */}
                          <div className="grid grid-cols-12 gap-6 mb-5">
                            <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                              <div className="flex items-center justify-end mt-3">
                                <button
                                  className=" px-10 mt-0 mb-3 py-3 btn btn-red text-xl border text-white"
                                  onClick={() =>
                                    addPrograms(lesKey, les.eventLessonId)
                                  }
                                >
                                  <div className="flex items-center font-semibold">
                                    <span className="mr-3">
                                      <img
                                        src="../plus-round-icon.svg"
                                        alt=""
                                      ></img>
                                    </span>
                                    <span className="">Add Timing</span>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Programme End */}
      {/* footer Button*/}
      <div className="grid grid-cols-12 gap-6">
        <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
          <div className="flex flex-wrap justify-between space-x-10 mt-12">
            <div>
              <button
                className="w-32 px-7 py-2 btn-sm btn btn-gray text-lg text-white hover:text-white"
                onClick={() => {
                  props.backFromProgramme();
                  props.executeScroll();
                }}
              >
                {" "}
                Back
              </button>
              {/* <button className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white">Back</button> */}
            </div>
            <div>
              <button
                className="w-32 px-7 py-2 mr-12 btn-sm btn btn-red text-lg text-white"
                onClick={() => {
                  validateProgrammes(0);
                }}
              >
                Save
              </button>

              <button
                className="w-32 px-7  py-2 btn-sm btn btn-lighblue text-lg text-white"
                onClick={() => {
                  validateProgrammes(1);
                  props.executeScroll()
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Programme;
