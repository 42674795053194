import { axiosGetAuthorize, axiosPostAuthorize } from '../AxiosRequests';
import {
    GetGoGlobalAccountApprovalList,
    GetGoGlobalContactRequestList,
    GetGoGlobalMemberDetailsById,
    GetAllDropDownsForGoGlobalMembers,
    ApproveGoGlobalMemberDetails,
    GetAllGoGlobalClickAnalyticsList,
    AddEditGoGlobalDetails,
    GetCorporateDetailForGoGLobal,
    GetDropdownDataByName,
} from '../ApiEndPoints';

export default class GoGlobalServices {

    async getGoGlobalContactRequestList(request) {
        return axiosPostAuthorize(GetGoGlobalContactRequestList, request);
    }
    async getGoGlobalAccountApprovalList(request) {
        return axiosPostAuthorize(GetGoGlobalAccountApprovalList, request);
    }
    async getGoGlobalMemberDetailsById(request) {
        return axiosGetAuthorize(GetGoGlobalMemberDetailsById, request);
    }
    async getAllDropDownsForGoGlobalMembers(request) {
        return axiosGetAuthorize(GetAllDropDownsForGoGlobalMembers, request)
    }
    async approveGoGlobalMemberDetails(request) {
        return axiosPostAuthorize(ApproveGoGlobalMemberDetails, request);
    }
    async getAllGoGlobalClickAnalyticsList(request) {
        return axiosPostAuthorize(GetAllGoGlobalClickAnalyticsList, request);
    }
    async addEditGoGlobalDetails(request) {
        return axiosPostAuthorize(AddEditGoGlobalDetails, request);
    }
    async getCorporateDetailForGoGLobal(request) {
        return axiosGetAuthorize(GetCorporateDetailForGoGLobal, request);
    }

    async getDropdownDataByName(request) {
        return axiosPostAuthorize(GetDropdownDataByName, request);
    }
}