import { axiosGetAuthorize, axiosPostAuthorize } from '../AxiosRequests';
import { AddEditCommunitySpotlight, GetCommunitySpotlight } from '../ApiEndPoints';

export default class CommunitySpotlightServices {

    async addEditCommunitySpotlight(request) {
        return axiosPostAuthorize(AddEditCommunitySpotlight, request)
    }

    async getCommunitySpotlight() {
        return axiosGetAuthorize(GetCommunitySpotlight)
    }
}