import React from "react";

const ButtonLoader = (props) => {
  return (
    // <button className="buttonload group relative flex items-center justify-center btn btn-red px-[20px] text-white focus:outline-none">
    <button className={props.className}>
      <span className="absolute left-[20px] inset-y-0 flex items-center mx-[15px]">
        <i className="fa fa-spinner fa-spin text-white text-[17px]"></i>
      </span>
      <span className="ml-10 text-lg mobile-14">
        Loading
      </span>
    </button>
  );
};

export default ButtonLoader;
