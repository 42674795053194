import React from "react";
import DropdownSelect from "../../dropdown/Dropdown";
import ActionDeleteButton from "../../UIComponent/ActionTableComponent/ActionDeleteButton";
import Validation from "../../../utils/validation/ValidationText";
import DatePicker from "../../datePicker/DatePicker";
import TextInput from "../../UIComponent/TextInput";
import moment from "moment";



function CommitteeInformation(props) {
  return (
    <div className="w-full">
      <div className="pt-5 pb-6">
        <div className="grid grid-cols-12 gap-6 w-full items-center">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            {props.selectedCommitteeList.map((committee, key) => (
              <>
                {(committee.displayJoinedDate === "" || (committee.displayJoinedDate !== "" && committee.leftDate !== "")) ? null :
                  <div key={key} className="slected-chapter flex items-start justify-between table-bg p-5 lg:py-3 mb-2">
                    <div className="left-name font-bold text-white text-lg text-left w-[50%]">
                      <div className="flex">
                        <span className="w-[180px]">
                          Selected Committee:
                        </span>
                        <span className="flex break-all w-[70%] pr-3">
                          {committee.groupName}
                        </span>
                      </div>
                    </div>
                    <div className="font-bold text-lg text-white w-[20%]">
                      <div className="flex flex-col justify-start">
                        <span>
                          Join Date
                        </span>
                        <span>
                          {committee.displayJoinedDate}
                        </span>
                      </div>
                    </div>
                    <div className="font-bold text-lg text-white w-[20%]">
                      {/* <div className="flex flex-col justify-start">
                        <span>
                          <h2>Left Date</h2>
                        </span>
                        <span>
                          {committee.leftDate}
                        </span>
                      </div> */}
                    </div>
                    <div className="right-action w-[10%]">
                      <div className="flex justify-center items-center pt-2">
                        <ActionDeleteButton
                          value=""
                          id=""
                          index=""
                          // onActionClick={this.onDelete}
                          onActionClick={(() => props.deleteCommittees(committee.individualCommitteeMappingId))}
                          text="Delete"
                        />
                      </div>
                    </div>
                  </div>
                }
              </>

            ))}

          </div>
        </div>
      </div>
      <div className="pb-10">
        <div className="grid grid-cols-12 gap-6 w-full items-center">
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Join Committee
                </h2>
                <DropdownSelect
                  drpIdentity={"workgroupMappingId"}
                  optionArray={props.committeeList}
                  setFilterParameters={props.setFilterParameters}
                  value={props.committeeCard.workgroupMappingId}
                />
                <Validation error={props.validState.error.workgroupMappingId} />
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="pb-10">
        <div className="grid grid-cols-12 gap-4 w-full items-center">
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-11 lg:col-span-11 col-span-12 disabled-input">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Updated Date
                </h2>

                <div className="disabled-input">
                  {props.committeeCard.updatedAt === "" || props.committeeCard.updatedAt === null || moment(props.committeeCard.updatedAt).format("yyyy-MM-DD") === "1970-01-01" ?
                    <TextInput
                      disabled={true}
                      placeholder=""
                      type="text"
                      name="linkedIn"
                      value="None"
                      identity="BasicInformation"
                    />
                    :
                    <DatePicker
                      isDisable={true}
                      name="updatedAt"
                      handleChange={props.handleChange}
                      identity="updatedAt"
                      value={moment(props.committeeCard.updatedAt).format("yyyy-MM-DD")}
                    />
                  }
                </div>
                {/* <DatePicker
                  name="sIISAccountUpdatedDate"
                  handleChange={props.handleChange}
                  identity="SIISInformation"
                  isDisable={true}
                  value={moment(props.committeeCard.updatedAt).format("yyyy-MM-DD")}

                /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-12 lg:col-span-12 col-span-12 px-14 mt-5 pt-6">
                <div className="flex items-center justify-end">
                  <button
                    className="btn btn-red text-white pt-3 px-12"
                    onClick={() => props.joinCommitee()}
                  >
                    <span className="text-xl text-white font-semibold tracking-normal">
                      Join
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
        {`History of previously joined committees : ${props.selectedCommitteeList[0]?.leftDate === "" ? '-' : ''}`}
      </h2>
      <div className="pt-5 pb-6">
        <div className="grid grid-cols-12 gap-6 w-full items-center">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            {props.selectedCommitteeList.map((committee, key) => (

              <>
                {committee.leftDate === "" ? null :
                  <div key={key} className="slected-chapter flex items-start justify-between table-bg p-5 lg:py-3 mb-2">
                    <div className="left-name font-bold text-white text-lg text-left w-[50%]">
                      <div className="flex">
                        <span className="w-[180px]">
                          Selected Committee:
                        </span>

                        <span className="flex break-all w-[70%] pr-3">
                          {committee.groupName}
                        </span>
                      </div>
                    </div>
                    <div className="font-bold text-lg text-white w-[20%]">
                      <div className="flex flex-col justify-start">
                        <span>
                          Join Date
                        </span>
                        <span>
                          {committee.displayJoinedDate}
                        </span>
                      </div>
                    </div>
                    <div className="font-bold text-lg text-white w-[20%]">
                      <div className="flex flex-col justify-start">
                        <span>
                          <h2>Left Date</h2>
                        </span>
                        <span>
                          {committee.leftDate}
                        </span>
                      </div>
                    </div>
                    <div className="right-action w-[10%]">
                      <div className="flex justify-center items-center pt-2">
                        {/* <ActionDeleteButton
                          value=""
                          id=""
                          index=""
                          // onActionClick={this.onDelete}
                          onActionClick={(() => props.deleteCommittees(committee.individualCommitteeMappingId))}
                          text="Delete"
                        /> */}
                      </div>
                    </div>

                  </div>
                }
              </>

            ))}

          </div>
        </div>
      </div>
    </div>
  );
}
export default CommitteeInformation;