import React from 'react'
import ModalBasic from '../../../pages/component/ModalBasic'

const MembershipDirectoryEnquiryModal = (props) => {
    return (
        <ModalBasic
            id="membership-directory-enquiry-modal"
            modalOpen={props.paymentLinkModal}
            setModalOpen={props.setOpenModal}
            title="Preview"
        >
            <div className="pb-2 border-t border-[#757575] ">
                <div className='pl-10 border-b border-[#757575] mx-8'>
                    <h1 className=" text-xl text-[#757575] font-bold leading-loose py-4">
                        Receiver Details
                    </h1>
                    <div className="text-[20px] text-[#757575] xl:flex xl:items-start xl:flex-row  lg:flex lg:flex-col  ">
                        <div className="inline-block left-side xl:w-[80%] lg:w-[100%] space-y-4 pb-4">
                            <div className="w-full flex items-center">
                                <span className=" inner-title-font-small-button">
                                    Date:
                                </span>{" "}
                                <span className="pl-28 inner-title-font-small-button">{props.getMemebrDirectoryEnquiryById.createdAt}</span>
                            </div>
                            <div className="w-full flex items-center">
                                <span className="text-right inner-title-font-small-button">
                                    To:
                                </span>{" "}
                                <span className="pl-[135px]  inner-title-font-small-button">{props.getMemebrDirectoryEnquiryById.receiverCorporateName}</span>
                            </div>
                            <div className="w-full flex">
                                <span className="text-right inner-title-font-small-button">
                                    Message:
                                </span>
                                <span className="pl-20 inner-title-font-small-button">{props.getMemebrDirectoryEnquiryById.message}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='pl-16 pb-10'>
                    <h1 className=" text-xl text-[#757575] font-bold leading-loose py-4">
                        Sender Details
                    </h1>
                    <div className="text-[20px] text-[#757575] xl:flex xl:items-start xl:flex-row  lg:flex lg:flex-col">
                        <div className="inline-block left-side xl:w-[80%] lg:w-[100%] space-y-4 pb-4">
                            <div className="w-full flex items-center">
                                <span className=" inner-title-font-small-button">
                                    Name:
                                </span>{" "}
                                <span className="pl-28 inner-title-font-small-button">{props.getMemebrDirectoryEnquiryById.senderIndividualName}</span>
                            </div>
                            <div className="w-full flex items-center">
                                <span className="text-right inner-title-font-small-button">
                                    Company:
                                </span>{" "}
                                <span className="pl-20  inner-title-font-small-button">{props.getMemebrDirectoryEnquiryById.senderCorporateName}</span>
                            </div>
                            <div className="w-full flex">
                                <span className="text-right inner-title-font-small-button">
                                    Contact Number:
                                </span>
                                <span className="pl-4 inner-title-font-small-button">{props.getMemebrDirectoryEnquiryById.senderContactNo}</span>
                            </div>
                            <div className="w-full flex">
                                <span className="text-right inner-title-font-small-button">
                                    Email:
                                </span>
                                <span className="pl-28 inner-title-font-small-button">{props.getMemebrDirectoryEnquiryById.senderEmail}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </ModalBasic>
    )
}
export default MembershipDirectoryEnquiryModal;