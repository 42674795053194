import React from "react";
import TextInput from "../../UIComponent/TextInput";
import Validation from "../../../utils/validation/ValidationText";

function EmploymentInformation(props) {
    return (
        <div className="w-full">
            {/* Employment Information Start */}
            <div className="2xl:py-5 2xl:pt-5 lg:py-5 lg:pt-5">
                <div className="grid grid-cols-12 gap-6 w-full items-center">
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                    Company Name
                                </h2>
                                <TextInput
                                    placeholder=""
                                    type="text"
                                    name="employmentCompanyName"
                                    className={"bg-[#e9e9ea]"}
                                    value={props.employmentInformation.employmentCompanyName}
                                    identity="SIISInformation"
                                    handleChange={props.handleChange}
                                    onBlur={(e) => props.validate("employmentCompanyName", props.employmentInformation)}
                                    disabled={true}
                                />
                                <Validation
                                    error={props.validState.error.employmentCompanyName}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                    Title
                                </h2>
                                <TextInput
                                    placeholder=""
                                    type="text"
                                    name="title"
                                    value={props.employmentInformation.title}
                                    identity="SIISInformation"
                                    handleChange={props.handleChange}
                                    onBlur={(e) => props.validate("title", props.employmentInformation)}

                                />
                                <Validation
                                    error={props.validState.error.title}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                    Job Function
                                </h2>

                                <DropdownSelect
                                    drpIdentity={"employmentJobFunctionId"}
                                    optionArray={props.jobFunction}
                                    setFilterParameters={props.setFilterParameters}
                                    value={props.employmentInformation.employmentJobFunctionId}

                                />
                                <Validation
                                    error={props.validState.error.employmentJobFunctionId}
                                />
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="xl:col-span-11 lg:col-span-11 col-span-12">
                                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                                    Job Level
                                </h2>
                                <DropdownSelect
                                    drpIdentity={"employmentJobLevelId"}
                                    optionArray={props.jobLevel}
                                    setFilterParameters={props.setFilterParameters}
                                    value={props.employmentInformation.employmentJobLevelId}

                                />
                                <Validation
                                    error={props.validState.error.employmentJobLevelId}
                                />
                            </div>
                        </div>
                    </div> */}
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                        <div className="grid grid-cols-12 gap-6">
                            {/* <div className="xl:col-span-11 lg:col-span-11 col-span-12 pt-5">
                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                  Role Played:
                </h2>
                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                  Official Rep
                </h2>
              </div> */}
                        </div>
                    </div>
                </div>
            </div>


            {/* Save Button */}
            <div className="grid grid-cols-12 gap-6 pb-10">
                <div className="xl:col-span-12 lg:col-span-12 col-span-12 px-10">
                    <a
                        className="flex items-center justify-end"
                    // href="/CreateCorporateMembers"
                    >
                        <button
                            className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold"
                            onClick={() => props.addEditEmploymentInformation()}
                        >
                            Save
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default EmploymentInformation;