import moment from 'moment/moment';
import React from 'react'
import ModalBasic from '../../../pages/component/ModalBasic'
import DatePicker from '../../datePicker/DatePicker';
import DropdownSelect from '../../dropdown/Dropdown'
import TextInput from '../../UIComponent/TextInput';
import ValidationText from '../../../utils/validation/ValidationText';
import ButtonLoader from '../../common/buttonLoader/ButtonLoader';

export default function GenerateCreditNote(props) {
    const invoiceCancel = props.isInvoiceCancel === true;
    return (
        <ModalBasic
            id="show-individual-profile-modal"
            modalOpen={props.generateCreditNote}
            setModalOpen={props.setOpenModal}
        >
            <div className="bg-white mb-10">
                <div className="">
                    <div className="grid grid-cols-12 gap-6 w-full ">
                        <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                            <h1 className="text-4xl text-[#757575] font-bold leading-loose pl-12">
                                Generate Credit Note
                            </h1>
                        </div>
                    </div>
                    <hr className="mt-2 border-t border-t-[#c9c9c9]"></hr>
                    <div className="grid grid-cols-12 gap-6 w-full ">
                        <div className="col-span-12 2xl:pt-7 lg:mt-4 items-center">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                                            <div className="flex items-center">
                                                <h3 className="small-title font-bold theme-color px-12">
                                                    {/* Month */}
                                                    Effective Date
                                                </h3>
                                            </div>
                                            <div className="flex items-center px-12">
                                                <DatePicker
                                                    name="creditNoteMonth"
                                                    handleChange={props.handleChange}
                                                    identity="CreditNote"
                                                    value={props.addEditCreditNote.creditNoteMonth === "" || props.addEditCreditNote.creditNoteMonth === null ? moment(props.corporateCreditNotePaymentInfo.subscriptionStartDate).format("DD MMM YYYY") : moment(props.addEditCreditNote.creditNoteMonth).format("yyyy-MM-DD")}
                                                    minDate={moment(props.corporateCreditNotePaymentInfo.subscriptionStartDate ? props.corporateCreditNotePaymentInfo.subscriptionStartDate : new Date()).format("yyyy-MM-DD")}
                                                    maxDate={moment(props.corporateCreditNotePaymentInfo.subcriptionEndDate).format("yyyy-MM-DD")}
                                                // disabled={invoiceCancel}
                                                // isCurserAllowed={invoiceCancel}
                                                />
                                            </div>
                                            <div className="flex items-center px-12">
                                                <ValidationText
                                                    error={props.validCreditNote2.error.creditNoteMonth}
                                                />
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex items-center">
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12 ">
                                            <div className="px-12">
                                                <div className="">
                                                    <h3 className="small-title font-bold theme-color">
                                                        Paid Amount
                                                    </h3>
                                                    <h4 className="small-title text-[24px] theme-color">
                                                        <TextInput
                                                            placeholder=""
                                                            type="text"
                                                            name="paidTotalAmount"
                                                            value={props.addEditCreditNote.paidTotalAmount ? parseFloat(props.addEditCreditNote.paidTotalAmount).toFixed(2) : "0.00"}
                                                            identity="CreditNote"
                                                            isCurserAllowed={true}
                                                            disabled={true}
                                                        />
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6 w-full ">
                        <div className="col-span-12 2xl:pt-5 lg:mt-4 items-center">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                                            <div className="flex items-center">
                                                <h3 className="small-title font-bold theme-color px-12">
                                                    Itemised item
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex items-center">
                                                <h3 className="small-title font-bold theme-color px-12">
                                                    Invoice Amount
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex items-center">
                                                <h3 className="small-title font-bold theme-color px-12">
                                                    Refund Amount
                                                </h3>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {props.corporateCreditNotePaymentInfo.isMTO ?
                        null :
                        <>
                            <div className="grid grid-cols-12 gap-6 w-">
                                <div className="col-span-12 2xl:pt-7  lg:mt-4 items-center">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="col-span-12">
                                            <div className="grid grid-cols-12 gap-6">
                                                <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                                                    <div className="flex item-center">
                                                        <h4 className="small-title  text-[24px] theme-color px-12">
                                                            Membership Entrance Fee - One Time
                                                        </h4>
                                                    </div>
                                                </div>
                                                <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                                    <div className="flex items-center">
                                                        <h4 className="small-title text-[24px] theme-color px-12">
                                                            {props.corporateCreditNotePaymentInfo.entranceFee ? parseFloat(props.corporateCreditNotePaymentInfo.entranceFee).toFixed(2) : "0.00"}
                                                        </h4>
                                                    </div>
                                                </div>
                                                <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                                    <div className="flex  px-12">
                                                        <TextInput
                                                            placeholder=""
                                                            type="text"
                                                            name="entranceRefund"
                                                            value={props.addEditCreditNote.entranceRefund}
                                                            identity="CreditNote"
                                                            handleChange={props.handleChange}
                                                            onBlur={(e) => props.validate2("entranceRefund", props.addEditCreditNote)}
                                                            disabled={props.addEditCreditNote.creditNoteMonth === "" ? true : false}
                                                            isCurserAllowed={props.addEditCreditNote.creditNoteMonth === "" ? true : false}

                                                        />
                                                    </div>
                                                    <div className="flex  px-12">
                                                        <ValidationText
                                                            error={props.validCreditNote2.error.entranceRefund}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="mt-2 border-t border-t-[#c9c9c9] mx-12"></hr>
                        </>
                    }
                    <div className="grid grid-cols-12 gap-6 w-">
                        <div className="col-span-12 2xl:pt-7  lg:mt-4 items-center">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                                            <div className="flex item-center">
                                                <h4 className="small-title  text-[24px] theme-color px-12">
                                                    {props.corporateCreditNotePaymentInfo.isMTO ?
                                                        <> MTO Subscription for period {moment(props.corporateCreditNotePaymentInfo.subscriptionStartDate).format("DD MMM YYYY")} to {moment(props.corporateCreditNotePaymentInfo.subcriptionEndDate).format("DD MMM YYYY")}</>
                                                        :
                                                        <> {props.corporateCreditNotePaymentInfo.membershipTypeName} for period {moment(props.corporateCreditNotePaymentInfo.subscriptionStartDate).format("DD MMM YYYY")} to {moment(props.corporateCreditNotePaymentInfo.subcriptionEndDate).format("DD MMM YYYY")}</>
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex items-center">
                                                <h4 className="small-title text-[24px] theme-color px-12">
                                                    {props.corporateCreditNotePaymentInfo.annualFee ? parseFloat(props.corporateCreditNotePaymentInfo.annualFee).toFixed(2) : "0.00"}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex  px-12">
                                                <TextInput
                                                    placeholder=""
                                                    type="text"
                                                    name="annualRefund"
                                                    value={props.addEditCreditNote.annualRefund}
                                                    identity="CreditNote"
                                                    handleChange={props.handleChange}
                                                    onBlur={(e) => props.validate2("annualRefund", props.addEditCreditNote)}
                                                    disabled={props.addEditCreditNote.creditNoteMonth === "" ? true : false}
                                                    isCurserAllowed={props.addEditCreditNote.creditNoteMonth === "" ? true : false}
                                                />
                                            </div>
                                            <div className="flex  px-12">
                                                <ValidationText
                                                    error={props.validCreditNote2.error.annualRefund}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <hr className="mt-2 border-t border-t-[#c9c9c9] mx-12"></hr>
                    <div className="grid grid-cols-12 gap-6 w-">
                        <div className="col-span-12 2xl:pt-7  lg:mt-4 items-center">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                                            <div className="flex item-center">
                                                <h4 className="small-title  text-[24px] theme-color px-12">
                                                    Additional Chapter Fee for period {moment(props.corporateCreditNotePaymentInfo.subscriptionStartDate).format("DD MMM YYYY")} through {moment(props.corporateCreditNotePaymentInfo.subcriptionEndDate).format("DD MMM YYYY")}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex items-center">
                                                <h4 className="small-title text-[24px] theme-color px-12">
                                                    {props.corporateCreditNotePaymentInfo.addtionalChapterFee ? parseFloat(props.corporateCreditNotePaymentInfo.addtionalChapterFee).toFixed(2) : "0.00"}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex  px-12">
                                                <TextInput
                                                    placeholder=""
                                                    type="text"
                                                    name="chapterRefund"
                                                    value={props.addEditCreditNote.chapterRefund}
                                                    identity="CreditNote"
                                                    handleChange={props.handleChange}
                                                    onBlur={(e) => props.validate2("chapterRefund", props.addEditCreditNote)}
                                                    disabled={props.addEditCreditNote.creditNoteMonth === "" ? true : false}
                                                    isCurserAllowed={props.addEditCreditNote.creditNoteMonth === "" ? true : false}
                                                />
                                            </div>
                                            <div className="flex  px-12">
                                                <ValidationText
                                                    error={props.validCreditNote2.error.chapterRefund}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <hr className="mt-2 border-t border-t-[#c9c9c9] mx-12"></hr>
                    <div className="grid grid-cols-12 gap-6 w-">
                        <div className="col-span-12 2xl:pt-7  lg:mt-4 items-center">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                                            <div className="flex item-center">
                                                <h4 className="small-title  text-[24px] theme-color px-12">
                                                    Sub total
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex items-center">
                                                <h4 className="small-title text-[24px] theme-color px-12">
                                                    {/* {props.corporateCreditNotePaymentInfo.subTotalAmount} */}
                                                    {props.corporateCreditNotePaymentInfo.subTotalAmount ?
                                                        parseFloat(props.corporateCreditNotePaymentInfo.subTotalAmount).toFixed(2) : "0.00"}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex  px-12">
                                                <h4 className="small-title text-[24px] theme-color px-12 font-bold text-left">
                                                    {props.addEditCreditNote.subTotalRefund ? parseFloat(props.addEditCreditNote.subTotalRefund).toFixed(2) : "0.00"}
                                                </h4>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="mt-2 border-t border-t-[#c9c9c9] mx-12"></hr>
                    <div className="grid grid-cols-12 gap-6 w-">
                        <div className="col-span-12 2xl:pt-7  lg:mt-4 items-center">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                                            <div className="flex item-center">
                                                <h4 className="small-title  text-[24px] theme-color px-12">
                                                    GST
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex items-center">
                                                <h4 className="small-title text-[24px] theme-color px-12">
                                                    {props.corporateCreditNotePaymentInfo.gstAmount ? parseFloat(props.corporateCreditNotePaymentInfo.gstAmount).toFixed(2) : "0.00"}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex  px-12">
                                                <h4 className="small-title text-[24px] theme-color px-12 font-bold">
                                                    {props.addEditCreditNote.gstRefund ? parseFloat(props.addEditCreditNote.gstRefund).toFixed(2) : "0.00"}
                                                </h4>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="mt-2 border-t border-t-[#c9c9c9] mx-12"></hr>
                    <div className="grid grid-cols-12 gap-6 w-">
                        <div className="col-span-12 2xl:pt-7  lg:mt-4 items-center">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                                            <div className="flex item-center">
                                                <h4 className="small-title text-[24px] theme-color px-12">
                                                    Discount
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex items-center">
                                                <h4 className="small-title text-[24px] theme-color px-12">
                                                    -{props.corporateCreditNotePaymentInfo.discountedAmount ? parseFloat(props.corporateCreditNotePaymentInfo.discountedAmount).toFixed(2) : "0.00"}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex  px-12">
                                                <h4 className="small-title text-[24px] theme-color px-12 font-bold">
                                                    -{props.addEditCreditNote.discountRefund ? parseFloat(props.addEditCreditNote.discountRefund).toFixed(2) : "0.00"}
                                                </h4>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="mt-2 border-t border-t-[#c9c9c9] mx-12"></hr>
                    <div className="grid grid-cols-12 gap-6 w-">
                        <div className="col-span-12 2xl:pt-7  lg:mt-4 items-center">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                                            <div className="flex item-center">
                                                <h4 className="small-title  text-[24px] theme-color px-12 font-bold">
                                                    Total Refund Amount
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex items-center">
                                                <h4 className="small-title text-[24px] theme-color px-12">
                                                    {props.corporateCreditNotePaymentInfo.totalAmount ? parseFloat(props.corporateCreditNotePaymentInfo.totalAmount).toFixed(2) : "0.00"}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex  px-12">
                                                <h4 className="small-title text-[24px] theme-color px-12 font-bold">
                                                    {props.addEditCreditNote.totalRefund ? parseFloat(props.addEditCreditNote.totalRefund).toFixed(2) : "0.00"}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-6 w-full ">
                    <div className="col-span-12 2xl:pt-7 lg:mt-4 items-center">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <div className="grid grid-cols-12 gap-6">
                                    <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                                        <div className="flex items-center">
                                            <h3 className="small-title font-bold theme-color px-12">
                                                Cancellation Reason
                                            </h3>
                                        </div>
                                        <div className="flex items-center w-full px-12" >
                                            <DropdownSelect
                                                disabled={false}
                                                drpIdentity={"cancel_reason"}
                                                optionArray={props.cancellationReason}
                                                value={props.corporateCreditNotePaymentInfo.cancellationReason ? props.corporateCreditNotePaymentInfo.cancellationReason : []}
                                                setFilterParameters={props.setFilterParameters}
                                                onBlur={(e) => props.validate("cancellationReason", props.corporateCreditNotePaymentInfo)}
                                            />
                                        </div>
                                        <div className="flex items-center w-full px-12">
                                            <ValidationText
                                                error={props.validCreditNote.error.cancellationReason}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {props.isOtherCancellationReason ?
                    <>
                        <div className="grid grid-cols-12 gap-6 w-full ">
                            <div className="col-span-12 2xl:pt-7 lg:mt-4 items-center">
                                <div className="grid grid-cols-12 gap-6">
                                    <div className="col-span-12">
                                        <div className="grid grid-cols-12 gap-6">
                                            <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                                                <div className="flex items-center">
                                                    <h3 className="small-title font-bold theme-color px-12">
                                                        Others
                                                    </h3>
                                                </div>
                                                <div className="flex items-center px-12">
                                                    <TextInput
                                                        placeholder=""
                                                        type="text"
                                                        name="cancellationReason"
                                                        value={props.addEditCreditNote.cancellationReason}
                                                        identity="CreditNote"
                                                        handleChange={props.handleChange}
                                                        onBlur={(e) => props.validate2("cancellationReason", props.addEditCreditNote)}
                                                    />
                                                </div>
                                                <div className="flex items-center px-12">
                                                    <ValidationText
                                                        error={props.validCreditNote2.error.cancellationReason}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    : null
                }

                {/* Delete and Save Button */}
                <div className="grid grid-cols-12 gap-6 w-full pt-16 px-10 ">
                    <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                        <div className="flex items-center justify-center">
                            <div className="savebtn">
                                {props.isLoading ?
                                    <ButtonLoader />
                                    :
                                    <button className=" px-14 py-3 bg-[#C00000] text-xl text-white font-semibold"
                                        onClick={() => props.addFinanceCreditNote()}
                                    >
                                        Generate
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalBasic>
    )
}