import React, { useEffect, useState } from "react";
import ModalBasic from "../../../pages/component/ModalBasic";
import Table from "../../table/Table";
import ActionEditButton from "../../UIComponent/ActionTableComponent/ActionEditButton";



// const onEdit = (value, index) => {
//     let editDetails = [...this.state.getAuthorityLimitList];
//     this.state.editId = editDetails[index].authorityLimitId;
//     this.getAuthorityLimitById(this.state.editId);
// }
const SelectExcoMemberModal = (props) => {

    // const setPagination = (newPagination) => {
    //     this.setState({ pagination: newPagination });
    // }

    // const actions = (element) => {
    //     return (element !== "pagesDetailsId") ? null
    //         : <ActionContainer>
    //             <ActionEditButton
    //                 text="Edit"
    //             />
    //         </ActionContainer>
    // }
    // useEffect(() => {
    //     if (props.selectExcoMemberChecked === true || props.selectExcoMemberChecked === "true") {
    //         selectedRows = [];
    //     }
    // },[props]);
    const [selectedRows, setSelectedRows] = useState([]);

    // var selectedRows = new Array();
    const handleCheckbox = (event, row, index) => {
        let tempArr = [...selectedRows];
        if (event.target.checked === true) {
            tempArr.push(row);
            setSelectedRows(tempArr);
            props.setHandleCheckboxForRemoveExco(row, index, event.target.checked);
        } else {
            // var index = tempArr.indexOf(row);
            if (index > -1) {
                tempArr.splice(index, 1);
                setSelectedRows(tempArr);
                props.setHandleCheckboxForRemoveExco(row, index, event.target.checked);
            }
        }
    }
    const onEdit = (value, index, e) => {
        let editDetails = [...props.rows];
        let editId = editDetails[index].excoMemberId;
        props.getExcoMemberById(editId, e);
    }

    const actions = (element, index, value) => {
        return (element !== "excoMemberId") ? null
            : <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap pointer">
                <div className="flex items-center relative">

                    <ActionEditButton
                        value={value}
                        id={index}
                        index={index}
                        onActionClick={onEdit.bind(this)}
                        text="Edit"
                    />

                </div>
            </td>
    }


    const select = (element, index, value) => {
        return (element !== "selectRow") ? null
            :
            <div className="p-4">
                <input
                    id="isRequireQuotation"
                    name="isRequireQuotation"
                    type="checkbox"
                    className="cursor-pointer h-6 w-6 text-[#C00000] focus:ring-[#C00000] rounded check-box-custom"
                    onChange={(event) => handleCheckbox(event, value, index)}
                    checked={props.rows[index].checkedValue}
                />

            </div>
    }
    return (
        <>
            <ModalBasic
                id="select-exco-member-modal"
                modalOpen={props.setSelectExcoMemberModal}
                setModalOpen={props.setOpenModal}
                title="Select Individual Record"
            >
                <div className="px-14 py-4">
                    {/* <label className="flex justify-end text-[#cb0000] text-lg underline">Select Existing Account</label> */}
                    <main className="pb-10">
                        <div className="w-full mx-auto">
                            <div className="relative">
                                <div>
                                    <div className=" mt-6">
                                        <Table columns={props.columns}
                                            pagination={props.pagination}
                                            rows={props.rows}
                                            sortingColumns={props.sortingColumns}
                                            isLoading={props.isLoading}
                                            totalResultes={props.totalResultes}
                                            totalCount={props.totalCount}
                                            setPagination={props.setPagination}
                                            // customScope={props.customScope}
                                            tableIdentity={props.tableIdentity}
                                            customScope={[
                                                {
                                                    column: 'excoMemberId',
                                                    renderTableData: actions.bind(this)
                                                },
                                                {
                                                    column: 'selectRow',
                                                    renderTableData: select.bind(this),
                                                    valueColumnName: "excoMemberId"
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>

                    <div className="flex items-center justify-between px-6 py-6 pt-0">
                        <button
                            className="btn btn-gray text-lg text-white font-bold"
                            onClick={(event) => props.onClose(event)}
                        >
                            Cancel
                        </button>
                        {selectedRows.length > 0 ?
                            <button
                                className="btn btn-red text-lg text-white font-bold"
                                onClick={() => props.removeExcoMember(selectedRows)}
                            >
                                Remove
                            </button>
                            : null}
                    </div>
                </div >
            </ModalBasic>
        </>
    );
}
export default SelectExcoMemberModal;

// export default class SelectExcoMemberModal extends Component {
//     constructor(props) {
//         super(props)
//         this.chapterXCommitteeWorkgroupServices = new PagesManagementServices();
//         this.swalServices = new SwalServices();
//         this.state = {
//             pagination: {
//                 pageNo: 1,
//                 pageSize: 10,
//                 orderByColumn: "",
//                 orderFlag: 0,
//                 searchText: "",
//                 status: 0,
//                 filterByType: 0,
//                 filterByStatus: 0
//             },
//             totalResultes: "",
//             getXCommitteeWorkgroupList: [
//                 {
//                     email: "johnl@abctech.com",
//                     firstName: "John",
//                     lastName: "Low",
//                     comapany: "ABC Technologies",
//                     excoPosition: "Vice Chair",
//                     joinDate: "20 Jan 2021",
//                 },
//                 {
//                     email: "johnl@abctech.com",
//                     firstName: "John",
//                     lastName: "Low",
//                     comapany: "ABC Technologies",
//                     excoPosition: "Vice Chair",
//                     joinDate: "20 Jan 2021",
//                 },
//             ],
//             isLoading: false,
//             redirect: null,
//         }
//     }

//     setPagination = (newPagination) => {
//         this.setState({ pagination: newPagination });
//     }

//     onEdit = (value, index) => {
//         let editResourcePages = [...this.state.getXCommitteeWorkgroupList];
//     }

//     actions = (element, index, value) => {
//         return (element !== "pagesDetailsId") ? null
//             : <ActionContainer>
//                 <ActionEditButton
//                     text="Edit"
//                 />
//             </ActionContainer>
//     }

//     select = (element) => {
//         return (element !== "selectRow") ? null
//             :
//             <div className="p-4">
//                 <input type="checkbox" className="h-6 w-6 " />
//             </div>
//     }
//     render() {
//         if (this.state.redirect != null) {
//             return <Navigate to={this.state.redirect} />;
//         }
//         return (
//             <ModalBasic
//                 id="select-exco-member-modal"
//                 modalOpen={this.props.setSelectExcoMemberModal}
//                 setModalOpen={this.props.setOpenModal}
//                 title="Select EXCO Member"
//             >
//                 <div className="px-14 py-4">
//                     <main className="pb-10">
//                         <div className="w-full mx-auto">
//                             <div className="relative">
//                                 <div>
//                                     <div className=" mt-6">
//                                         <Table columns={[{ name: 'selectRow', title: '' }, { name: 'email', title: 'Email' }, { name: 'firstName', title: 'First Name' }, { name: 'lastName', title: 'Last Name' }, { name: 'comapany', title: 'Company' }, { name: 'excoPosition', title: 'EXCO Position' }, { name: 'joinDate', title: 'Join Date' }, { name: 'pagesDetailsId', title: 'Action' }]}
//                                             pagination={this.state.pagination}
//                                             rows={this.state.getXCommitteeWorkgroupList}
//                                             sortingColumns={[""]}
//                                             // isLoading={this.state.isLoading}
//                                             // totalResultes={this.state.totalResultes}
//                                             // totalCount={this.state.totalResultes}
//                                             setPagination={this.setPagination.bind(this)}
//                                             customScope={[
//                                                 {
//                                                     column: 'pagesDetailsId',
//                                                     renderTableData: this.actions.bind(this)
//                                                 },
//                                                 {
//                                                     column: 'selectRow',
//                                                     renderTableData: this.select.bind(this)
//                                                 },
//                                             ]}
//                                         />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </main>

//                     <div className="grid grid-cols-6 gap-4 px-6 py-16">
//                         <button
//                             className="col-start-1 col-end-3  w-32 px-7 py-2 bg-zinc-500 text-lg text-white font-bold"
//                             onClick={(event) => this.props.onClose(event)}
//                         >
//                             Cancel
//                         </button>
//                         <button
//                             className="col-end-7 col-span-2 w-full py-2 btn-sm btn-red text-lg text-white font-bold"
//                         >
//                             Remove EXCO Member
//                         </button>
//                     </div>
//                 </div >
//             </ModalBasic>
//         );
//     }
// }