import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import DropdownAction from "../../components/dropdown/DropdownAction";
import Table from "../../components/table/Table";
import ActionContainer from "../../components/UIComponent/ActionTableComponent/actionContainer";
import BookingServices from "../../services/axiosServices/apiServices/BookingServices";
import moment from "moment";
import SwalServices from "../../services/swalServices/SwalServices";
import { encryptAES } from "../../utils/Encryption";

export default class ViewNewBookings extends Component {
  constructor(props) {
    super(props);
    this.BookingServices = new BookingServices();
    this.swalServices = new SwalServices();
    this.state = {
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        status: 0,
        dateFrom: "",
        dateTo: "",
        selectedEvent: [],
      },
      actionDropdown: ["Detail"],
      route: "",
      totalResultes: "",
      bookingList: [],
      isLoading: false,
      bookingDetails: {
        bookingAttendeeInformationId: 0,
        bookingId: 0,
        bookingReferenceNo: 0,
        bookingDate: "",
        eventTypeName: "",
        attendeeName: "",
        subTotalPrice: "",
        discountPrice: "",
        totalPrice: ""
      },
      eventNameList: [],
      isDataGetByDropdown: false,
      isDownloadingExcel: false,
    }
  }

  //BookedEventName

  // handleChangeMultiDropdown(selected, identity) {
  //   let data = this.state.pagination;
  //   if (identity === "EventName") {
  //     data.selectedEvent = selected;
  //     data.searchText = ""
  //     let found = this.state.eventNameList.find(x => x.label === selected.label);
  //     if (found) {
  //       data.pageNo = 1;
  //       data.pageSize = 10;
  //       data.searchText = found.label;
  //     }
  //     this.setState({ pagination: data }, () => {
  //       this.getbookingsList(this.state.pagination);
  //     });
  //   }
  // }

  // downloadBookingSheetByEventId = () => {
  //   this.setState({ isDownloadingExcel: true });
  //   if (this.state.pagination.selectedEvent?.value && this.state.pagination.selectedEvent.value > 0) {
  //     let request = [];
  //     request.push(this.state.pagination.selectedEvent.value);
  //     this.BookingServices.downloadBookingSheetByEventId(request).then((response) => {
  //       if (response.status === 200 && response.data != null) {
  //         // var filename = `${detail}${formattedDate}`;
  //         let blob = new Blob([response.data])
  //         saveAs(blob, `${this.state.pagination.selectedEvent.label}_Bookings` + ".xlsx");
  //       }
  //       else {
  //         this.swalServices.Error(response.message);
  //       }
  //       this.setState({ isDownloadingExcel: false });
  //     });
  //   }

  // }

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getbookingsList(newPagination);
  };
  getAllDropdownsForIndividualBooking = () => {
    this.BookingServices.getAllDropdownsForIndividualBooking().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let eventArr = response.responseItem.responseContent.filter(drp => drp.dropdownName === 'BookedEventName');

        eventArr.map(function (item) { delete item['dropdownName']; return item;});
        eventArr = eventArr.map(p => ({
          value: p.id, label: p.name
        }));
        this.setState({ eventNameList: eventArr });
      } else {
        this.SwalServices.Error(response.message);
      }
    });
  }
  getbookingsList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });
    this.BookingServices.getbookingsList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let details = response.responseItem.responseContent.itemList;
        details.map((key, index) => {
          key.bookingDate = moment(key.bookingDate).format(
            "DD MMM YYYY"
          );
          return key;
        });
        this.setState({ bookingList: details }, () => {
          let pagData = { ...this.state.pagination }
          //pagData.selectedEvent = [];
          if (pagination?.selectedEvent != null && pagination.selectedEvent.label && pagination.selectedEvent.value > 0) {
            this.setState({ isDataGetByDropdown: true, pagination: pagData });
          } else {
            this.setState({ isDataGetByDropdown: false, pagination: pagData });
          }
        });
        this.setState({
          totalResultes: response.responseItem.responseContent.totalCount,
        });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  actionClick = (index, value, option, event) => {
    if (option === "Detail") {
      if (value && value > 0) {
        let details = this.state.bookingList;
        let bookingDetails = details.find((x) => x.bookingAttendeeInformationId === value)
        if (bookingDetails) {
          this.setState({
            route: "/financial/ViewBookingDetail/" + encryptAES(value) + "/" + encryptAES(bookingDetails.bookingId)
          });
        }
      }
    }
  };

  actions = (element, index, value) => {
    return element !== "BookingAttendeeInformationId" ? null : (
      <td>
        <div className="relative w-full credit-note-action-dropdown">
          <ActionContainer>
            <div className="mr-8">
              <DropdownAction
                key={index}
                value={value}
                id={index}
                index={index}
                options={this.state.actionDropdown}
                align="right"
                onOptionClick={this.actionClick.bind(this)}
              />
            </div>
          </ActionContainer>
        </div>
      </td>
    );
  };

  searchByName = (value) => {
    let detail = this.state.pagination;
    detail.searchText = "";
    this.setState({ pagination: { ...detail } });
    if (value.length > 2) {
      detail.pageNo = 1;
      detail.pageSize = 10;
      detail.searchText = value;
      this.setState({ pagination: { ...detail } });
    }
    this.getbookingsList();
  };

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.pagination;
    let fulldate = (detail[name] = value === "" ? null : value);
    let date = new Date(fulldate); //1/1/1753 12:00:00 AM and 12/31/9999 11:59:59 PM
    let startLimit = new Date("1/1/1753 12:00:00 AM");
    let endLimit = new Date("12/31/9999 11:59:59 PM");
    detail[name] = value;
    this.setState({ pagination: { ...detail } }, () => {
      if (startLimit < date && date < endLimit) {
        this.getbookingsList();
      } else if (fulldate === null) {
        this.getbookingsList();
      }
    });
  }

  componentDidMount() {
    this.getAllDropdownsForIndividualBooking();
    this.getbookingsList();
  }
  render() {
    if (this.state.route !== "") {
      return <Navigate to={this.state.route} />;
    }
    return (

      <div className="main-body-section View-booking-sec">
        <div className="top-filter-sec">
          <div className="grid grid-cols-12 gap-6">
            <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
              <div className="grid grid-cols-12 gap-6 items-center w-full">
                <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                  <input
                    id="default"
                    name="dateFrom"
                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                    type="date"
                    value={this.state.pagination.dateFrom}
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>
                <div className=" 2xl:col-span-2 lg:col-span-2 col-span-12 w-full text-center">
                  <h2 className="text-xl theme-color">To</h2>
                </div>
                <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                  <input
                    id="default"
                    name="dateTo"
                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                    type="date"
                    value={this.state.pagination.dateTo}
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="2xl:col-span-6 lg:col-span-6 col-span-12 justify-end">
              <div className="right-search flex items-center">
                <div className="text-[#757575] text-xl pr-5">Search</div>
                {/* <input className="col-span-2 text-lg font-bold  h-[40px] w-full text-[#757575]"/> */}
                <input
                  id="default"
                  className="col-span-2 text-lg font-bold  h-[55px] w-full text-[#757575] border-white"
                  type="text"
                  onChange={(e) => this.searchByName(e.target.value)}
                />
              </div>
            </div>
          </div>
          {/* <div className="grid grid-cols-12 gap-6 mt-5">
            <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
              <h2 className="text-xl theme-color mb-1">
                Event Name
              </h2>

              <div className="dropdwn-input flex items-center">
                <div className="w-full">
                  <Selector
                    drpIdentity="EventName"
                    options={this.state.eventNameList}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    // components={{ Option }}
                    onChange={this.handleChangeMultiDropdown.bind(this)}
                    allowSelectAll={false}
                    value={
                      this.state.pagination.selectedEvent
                    }
                  />
                </div>
              </div>
            </div>
            {this.state.isDataGetByDropdown === true ?
              <>{this.state.isDownloadingExcel === true ? <>
                <div className="2xl:col-span-6 lg:col-span-6 col-span-12 flex justify-end mt-7">
                  <ButtonLoader />
                </div>
              </> :
                <div className="2xl:col-span-6 lg:col-span-6 col-span-12 flex justify-end">
                  <button className="btn btn-red text-xl border text-white mt-7 w-[200px]"
                    onClick={() => this.downloadBookingSheetByEventId()}
                  >
                    Download
                  </button>
                </div>
              }

              </>

              : null
            }

          </div> */}
        </div>
        <div className="bottom-result-table">
          {/* Table Section Start */}
          <div className="mt-10 credit-note-approval-table">
            <Table
              columns={[
                { name: "BookingReferenceNo", title: "Booking Ref. No" },
                { name: "ClassName", title: "Class Name" },
                { name: "BookingStatus", title: "Status" },
                { name: "AttendeeName", title: "Attendee Name" },
                { name: "AttendeeEmail", title: "Attendee Email" },
                { name: "BookingDate", title: "Booking Date" },
                { name: "BookingAttendeeInformationId", title: "Actions" },
              ]}
              rows={this.state.bookingList}
              isLoading={this.state.isLoading}
              sortingColumns={["BookingReferenceNo"]}
              pagination={this.state.pagination}
              totalResultes={this.state.totalResultes}
              totalCount={this.state.totalResultes}
              setPagination={this.setPagination.bind(this)}
              customScope={[
                {
                  column: "BookingAttendeeInformationId",
                  renderTableData: this.actions.bind(this),
                },
                // {
                //     column: "selectRow",
                //     renderTableData: this.select.bind(this),
                //     valueColumnName: "chartsOfAccountsId",
                // },
              ]}
            />
          </div>
          {/*Table Section End */}
        </div>
      </div>
    );
  }
}
