import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { CommonValidationMessages, CommonSuccessMessages } from "../../utils/Messages";
import Table from "../../components/table/Table";
import DropdownSelect from "../../components/dropdown/Dropdown";
import ActionEditButton from "../../components/UIComponent/ActionTableComponent/ActionEditButton";
import ActionDeleteButton from "../../components/UIComponent/ActionTableComponent/ActionDeleteButton";

//import API services
import FieldManagementServices from '../../services/axiosServices/apiServices/FieldManagementServices';
import SwalServices from "../../services/swalServices/SwalServices";
import { getAuthProps } from "../../utils/AuthenticationLibrary";
import { encryptAES } from "../../utils/Encryption";

export default class EmailManagement extends Component {
  constructor(props) {
    super(props);
    this.FieldManagementServices = new FieldManagementServices();
    this.swalServices = new SwalServices();
    this.state = {
      totalResultes: 0,
      isLoading: false,
      redirect: null,
      emailTemplateList: [],
      emailHistoryList: [
        {
          emailId: 1,
          emailName: "What is Lorem Ipsum?",
          emailSubject: "Lorem Ipsum is simply dummy text of the printing",
          emailProject: "Lorem Ipsum",
          emailUsed: "1500s, when an unknown printer took.",
        },
        {
          emailId: 2,
          emailName: "What is Lorem Ipsum?",
          emailSubject: "Lorem Ipsum is simply dummy text of the printing",
          emailProject: "Lorem Ipsum",
          emailUsed: "1500s, when an unknown printer took.",
        },
      ],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        ProjectTypeId: 0,
      },
      projectDropdownList: [],
      emailDetails: {
        subject: "",
        recipientName: "",
        additionalEmail: "",
        excludeEmail: "",
        emailDescription: "",
        selectedEmails: [],
      },
      validationRule: {
        subject: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "subject"
            ),
          },
        ],
        recipientName: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "recipient name"
            ),
          },
        ],
        emailDescription: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "email details"
            ),
          },
        ],
      },
      validState: {
        isValid: true,
        error: {},
      },
      createdBy: 0,
    };
  }

  //Method declaration start

  componentDidMount() {
    // For Set Created by based on admin login
    let cookie = getAuthProps();
    const adminUserId = ( cookie?.adminUserId) ? Number(cookie.adminUserId) : 0;
    if (adminUserId > 0) {
      this.setState({ createdBy: adminUserId });
    }
    // END
    this.getAllProjectTypeDropdown();
  }

  // API methods start

  //Get all email template list
  getEmailTemplateList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });
    this.FieldManagementServices.getEmailTemplateList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ emailTemplateList: response.responseItem.responseContent.itemList });
        this.setState({ totalResultes: response.responseItem.responseContent.totalCount })
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  //Delete strategic member 
  deleteEmailTemplate = (value) => {
    let request = {
      id: value,
      deletedBy: this.state.createdBy,
      appId: 114
    }
    this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Email Template"), "Do you want to delete email template?",
      CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
        if (response) {
          this.FieldManagementServices.deleteEmailTemplate(request).then((response) => {
            this.swalServices.Success("Email template delete successfully.");
            this.getEmailTemplateList();
            this.setState({ isLoading: false });
          });
        }
      });
  };

  //project dropdown method
  getAllProjectTypeDropdown = () => {
    this.setState({ isLoading: true });
    this.FieldManagementServices.getAllProjectTypeDropdown().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ projectDropdownList: response.responseItem.responseContent }, () => this.getEmailTemplateList());
      } else {
        this.getEmailTemplateList();
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  //Api method section end

  // Internal handling method start   

  onDropdown = (id, drpIdentity) => {
    let details = this.state.pagination
    if (drpIdentity === 'ProjectName') {
      details["ProjectTypeId"] = id;
    }
    this.setState({ pagination: { ...details } });
    this.getEmailTemplateList();
  };

  onEditEmailTemplate = (value, index) => {
    let editEmailTemplate = [...this.state.emailTemplateList];
    this.setState({ redirect: '/CreateEmailTemplate/emailTemplateId?=' + encryptAES(editEmailTemplate[index].emailTemplateId) });
  }

  searchByName = (value, identity) => {
    let detail = this.state.pagination;
    if (identity === "searchByEmailName") {
      detail.pageNo = 1;
      detail.pageSize = 10;
      detail.searchText = value;
      this.setState({ pagination: { ...detail } });
    }
    this.getEmailTemplateList();
  }

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getEmailTemplateList();
  };

  //table action method
  actions = (element, index, value) => {
    return element !== "emailTemplateId" ? null : (
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap pointer">
        <div className="flex items-center relative">
          <ActionEditButton
            value={value}
            id={index}
            index={index}
            onActionClick={this.onEditEmailTemplate.bind(this)}
            text="Edit"
          />
          <ActionDeleteButton
            value={value}
            id={index}
            index={index}
            onActionClick={this.deleteEmailTemplate}
            text="Delete"
          />
        </div>
      </td>
    );
  };
  // Method declaration section end

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="main-body-section">
        <div className="bg-white">
          <div className="grid 2xl:grid-cols-3 lg:grid-cols-2 gap-12 w-full">
            <div className="p-8">
              <h2 className="text-xl text-zinc-500">Project:</h2>
              <DropdownSelect
                drpIdentity="ProjectName"
                optionArray={this.state.projectDropdownList}
                setFilterParameters={this.onDropdown.bind(this)}
                value={this.state.pagination.ProjectTypeId}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-6 gap-4 pt-10">
          <Link to="/CreateEmailTemplate">
            <button className="btn btn-red text-xl border text-white ">
              <div className="flex items-center">
                <span className="mr-3">
                  <img src="../plus-round-icon.svg" alt="" />
                </span>
                <span className="" >Add New</span>
              </div>
            </button>
          </Link>
          <div className="col-end-7 col-span-2 grid grid-cols-3 items-center">
            <div className="text-[#757575] text-xl">Search:</div>
            <input
              id="default"
              className="col-span-2 text-lg h-[55px] w-full text-[#757575] border-white"
              type="text"
              onChange={(e) => this.searchByName(e.target.value, "searchByEmailName")}
            />
          </div>
        </div>
        <div className="bottom-list-display">
          <main className="pb-10">
            <div className="w-full mx-auto">
              <div className="relative">
                <div className="email-template-manage-sec">
                  <div className=" mt-6">
                    <Table
                      columns={[
                        { name: "emailName", title: "Email Name" },
                        { name: "subject", title: "Email Subject" },
                        { name: "projectName", title: "Email Project" },
                        { name: "whereUsed", title: "Email Where Used" },
                        { name: "emailTemplateId", title: "Action" },
                      ]}
                      pagination={this.state.pagination}
                      rows={this.state.emailTemplateList}
                      sortingColumns={[
                        "emailName",
                        "subject",
                        "projectName",
                        "whereUsed",
                      ]}
                      isLoading={this.state.isLoading}
                      totalResultes={this.state.totalResultes}
                      totalCount={this.state.totalResultes}
                      setPagination={this.setPagination.bind(this)}
                      customScope={[
                        {
                          column: "emailTemplateId",
                          renderTableData: this.actions.bind(this),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}